import React from "react";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import { List, Divider } from "antd";
import { Col, Row } from "antd";
import api from "../../utils/api";
import { useFetch } from '../../utils/useFetch'

import { checkControl } from "../../utils/access_control";

const LinksExternal = () => {
  const signin = useSelector((state) => state.signin);
  const { data: linksGroups } = useFetch(getLinksGroupQuery)

  async function getLinksGroupQuery() {
    const { data } = await api.get("/linksgroups?active=true");
    return data
  }

  const accessRules = {
    "manager-panel": "access_manager_panel",
  }
  return (
    <>
      <Divider style={{margin: "4rem 0"}} orientation="center"></Divider>
      <Row gutter={[50, 10]}>
      {
        linksGroups?.sort((a, b) => a.sequence - b.sequence).map((linksGroup, index) => (
          <Col
          key={linksGroup.id}
          xs={24}
          sm={12}
          md={8}
          // style={checkControl(signin.user.profiles, "access_manager_panel")}
          style={checkControl(signin.user.profiles, linksGroup.profile_code)}
        >
          <List
            size="Large"
            itemLayout="horizontal"
            header={
              <div>
                <b>{linksGroup.title}</b>
              </div>
            }
            dataSource={linksGroup.links}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={<a href="/"></a>}
                  description={
                    <a href={item.href} target="_blank">
                      {item.title}
                    </a>
                  }
                />
              </List.Item>
            )}
          />
        </Col>
        ))
      }
      </Row>
    </>
  );
};

export default LinksExternal;
