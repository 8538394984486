import React from "react";
import {
  Tag,
  Tooltip,
} from 'antd';

import {
  SyncOutlined
} from "@ant-design/icons";

const StatusComponent = (props) => {
  return (
    <>
      {
        props.loading ?
          <Tag icon={<SyncOutlined spin />} color="processing">Carregando</Tag>
          :
          <Tooltip title={props.description ? props.description : ''}>
            <Tag style={{ minWidth: "74px", textAlign: "center", color: props.color === "#ffffff" ? "#000000" : "#FF", borderColor: props.color === "#ffffff" ? "#000000" : "#FF" }} color={props.color ? props.color : '#000'}>{`${props.title ? props.title : 'Status não localizado'}`}</Tag>
          </Tooltip>
      }
    </>
  );
}

export default StatusComponent;