import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const TemplateDocuments = {
  listTemplates: async (limit, offset, active = true, param = "") =>
    await getRequest(
      `/template?limit=${limit}&offset=${offset}&active=${active}${param}`,
      {}
    ),

  create: async (body) => await postRequest("/template", body),

  show: async (id) => await putRequest("/template/" + id, {}),

  update: async (body) => await putRequest("/template/" + body.id, body),

  delete: async (id) => await deleteRequest("/template/" + id, {}),
};
