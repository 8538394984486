import React, { useEffect, useState } from "react";
import "./styles.css";

import Main from "../../../Layout/Main";
import Home from "./components/Home";
import Loading from "../../../../components/Loading";
import { UnifiedQueryContext } from "../../../../Context/unifiedQueryContext";
import {
  buttons,
  columnsAttendanceComplete,
  dataAPI,
  columnsSepulComplete,
} from "./mocks";

import { Layout, Row } from "antd";

import useWindowDimensions from "../../../../components/DimensionsScreen";
import SearchComponent from "./components/SearchComponent";
import Contracts from "./components/Contracts";
import TableSmall from "./components/TableSmall";
import DrawerData from "../../../../components/Drawer";
import HelpText from "./components/HelpText";
import MenuComponent from "../../../../components/MenuComponent";
import { itensMenu } from "../itens-menu";

const { Content } = Layout;

function UnifiedQuery(props) {
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [data, setData] = useState({});
  const [componentLoading, setComponentLoading] = useState(false);

  const { width } = useWindowDimensions();

  const path = props.modulePath;

  useEffect(() => {
    getDataScreen();
  }, []);

  // Função para buscar os dados na API e gravar no state de cada tela
  const getDataScreen = async () => {
    setLoading(true);

    setData(dataAPI);

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const breadcrumb = [
    {
      title: "Central de Atendimento ao Cliente",
      path: props.modulePath,
    },
    {
      title: "Consulta unificada",
      path: props.modulePath + "",
    },
  ];

  const screens = [
    <Home />,
    <Contracts />,
    <Row className="content-contract-unified-query-margin-top">
      <TableSmall
        title="Atendimentos Funerários"
        descriptionEmpty="Nenhum atendimento funerário encontrado!"
        columnsTable={columnsAttendanceComplete}
        table="attendance"
        current={buttons.find((value) => value.key === "attendance").current}
      />
    </Row>,
    <Row className="content-contract-unified-query-margin-top">
      <TableSmall
        title="Sepultados"
        descriptionEmpty="Nenhum sepultado encontrado!"
        columnsTable={columnsSepulComplete}
        table="sepul"
        current={buttons.find((value) => value.key === "sepul").current}
      />
    </Row>,
  ];

  return (
    <Main
      moduleName={props.moduleName}
      collapsed={true}
      moduleMenu={
        <MenuComponent
          itensMenu={itensMenu}
          modulePath={props.modulePath}
          selectedMenu="3"
        />
      }
      breadcrumb={breadcrumb}
    >
      <UnifiedQueryContext.Provider
        value={{
          current,
          setCurrent,
          data,
          setData,
          path,
          componentLoading,
          setComponentLoading,
        }}
      >
        {loading ? (
          <Loading title="Carregando dados da tela..." />
        ) : (
          <>
            <Content>
              <SearchComponent width={width} getDataScreen={getDataScreen} />
            </Content>
            {componentLoading ? (
              <Loading title="Carregando dados da tela..." />
            ) : (
              screens[current]
            )}
          </>
        )}
      </UnifiedQueryContext.Provider>
      <DrawerData
        position="right"
        title="AJUDA DA CONSULTA UNIFICADA"
        render={<HelpText />}
        width={520}
      />
    </Main>
  );
}

export default UnifiedQuery;
