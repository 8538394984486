import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Spin,
  Form,
  Button,
  DatePicker,
  Input,
  Select,
  message,
} from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';

import api from '../../../../../../utils/api';
import moment from 'moment';

const FormPaymentLink = (props) => {
  const [loading, setLoading] = useState(false);
  const [valuePayment, setValuePayment] = useState(0.00);

  const signin = useSelector((state) => state.signin);

  const [paymentLinkForm] = Form.useForm();

  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      const result = await api.post('/payment-link/create', {
        ...values,
        companyId: signin.user.currentCompany,
        currentCompany: signin.user.tenantId,
        userId: signin.user.id
      });

      props.setResult(result.data.result);

      props.setCurrent();

    } catch (error) {
      message.error("Não foi possível criar o link, certifique-se que a empresa logada é a correta!");
    } finally {
      setLoading(false);
    }

  }

  return (
    <Spin tip="Gerando link de pagamento..." spinning={loading}>
      <Form
        form={paymentLinkForm}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          code: "",
          note: ""
        }}
      >
        <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
          <Col xs={24} sm={24} lg={24}>
            <Form.Item
              label="Vencimento"
              name={["dueDate"]}
              rules={[
                {
                  required: true,
                  message:
                    "Campo Vencimento é obrigatório!",
                },
                {
                  validator: (rule, value, callback) => {
                    if (!value)
                      return callback();

                    if (value.startOf('day').valueOf() < moment().startOf('day').valueOf()) {
                      return callback("A data não pode ser menor que a data atual!");
                    } else if (value.startOf('day').valueOf() > moment(new Date()).add(15, 'days').startOf('day').valueOf()) {
                      return callback("A data não pode ser superior a 15 dias da data atual!");
                    } else {
                      return callback();
                    }
                  },
                }
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Selecione uma Data"
                format="DD/MM/YYYY"
                locale={locale}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
          <Col xs={24} sm={12} lg={12}>
            <Form.Item
              label="Valor do Link"
              name={["value"]}
              rules={[
                {
                  required: true,
                  message:
                    "Campo Valor do Link é obrigatório!",
                },
              ]}
            >
              <Input
                style={{ width: "100%" }}
                prefix="R$"
                className="class-input"
                type="number"
                placeholder='0,00'
                onChange={value => setValuePayment(value.target.value)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <Form.Item
              label="Parcelamento"
              name={["installment"]}
              rules={[
                {
                  required: true,
                  message:
                    "Campo Parcelamento é obrigatório!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Selecione o Parcelamento"
                disabled={valuePayment === 0.0 ? true : false}
              >
                {
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(number => {
                    return (
                      <Select.Option value={number}>
                        <Row justify='space-between'>
                          <Col>
                            {`${number}x`}
                          </Col>
                          <Col>
                            {(valuePayment / number).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                          </Col>
                        </Row>
                      </Select.Option>
                    );
                  })
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
          <Col xs={24} sm={12} lg={12}>
            <Form.Item
              label="Tipo do Código"
              name={["typeCode"]}
              rules={[
                {
                  required: true,
                  message:
                    "Campo Tipo do Código é obrigatório!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Selecione o Tipo"
              >
                <Select.Option value={1}>Contrato Morada Essencial</Select.Option>
                <Select.Option value={2}>Contrato Jazigo</Select.Option>
                <Select.Option value={3}>Contrato Cremação</Select.Option>
                <Select.Option value={4}>Crédito Funeral Futuro</Select.Option>
                <Select.Option value={5}>Contrato Plano Prever</Select.Option>
                <Select.Option value={6}>Contrato Cremação Pet</Select.Option>
                <Select.Option value={7}>Funerais/Cemitériais de Venda</Select.Option>
                <Select.Option value={8}>Negociação</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <Form.Item
              label="Código"
              name={["code"]}
            >
              <Input
                style={{ width: "100%" }}
                min={6}
                max={9}
                className="class-input"
                placeholder='000000000'
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
          <Col xs={24} sm={24} lg={24}>
            <Form.Item
              label="Observação interna"
              name={["note"]}
              rules={[]}
            >
              <Input.TextArea
                showCount maxLength={100}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Button
              type="primary"
              htmlType="submit"
            //style={{ margin: "0 8px" }}
            >
              Confirmar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}

export default FormPaymentLink;