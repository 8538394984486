import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const listAlternativeApi = {
  listAlternative: (limit, offset, active = true) =>
    getRequest(
      "/alternative?limit=" + limit + "&offset=" + offset + "&active=" + active,
      {}
    ),
};

export const addAlternativeApi = {
  addAlternative: (body) => postRequest("/alternative", body),
};

export const updateAlternativeApi = {
  updateAlternative: (body) => putRequest("/alternative/" + body.id, body),
};

export const deleteAlternativeApi = {
  deleteAlternative: (id) => deleteRequest("/alternative/" + id, {}),
};
