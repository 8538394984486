import React from "react";
import {
  Row,
  Col,
  Button,
  Typography
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

export function SelectDepen(props) {
  const { Title } = Typography;

  let auxiliar = { ...props.dependece }


  return (<Row>
    <Col span={6}>
      <Title style={{ textAlign: "start" }} level={5}>{props.dependece[props.chave].title}</Title>
    </Col>
    {console.log("active", props.active)}
    {console.log("maior que zero", props.dependece[props.chave].quantity != 0)}
    <Col span={3}>
      <Button type="primary" danger disabled={props.active && props.dependece[props.chave].quantity != 0 ? false : true} shape="circle" icon={<MinusOutlined />} onClick={() => {
        if (props.dependece[props.chave].quantity != 0) {
          auxiliar[props.chave].quantity -= 1
          props.setDependece(auxiliar)
          props.calcuTotal()
        }
      }}
      />
    </Col>
    <Col span={6} >
      <Title level={5}>{props.dependece[props.chave].quantity}</Title>
    </Col>
    <Col span={3}>
      <Button type="primary" disabled={!props.active} shape="circle" icon={<PlusOutlined />} onClick={() => {
        auxiliar[props.chave].quantity += 1
        props.setDependece(auxiliar)
        props.calcuTotal()

      }} />
    </Col>
    <Col span={6}>
      <Title style={{ textAlign: "end" }} level={5}>{(auxiliar[props.chave].quantity * auxiliar[props.chave].amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Title>
    </Col>
  </Row>)
}