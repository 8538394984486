import React from "react";
import { Empty } from "antd";

function Message({ title, text }) {
  return (
    <Empty
      description={
        <div>
          <h1>{title}</h1>
          <p>{text}</p>
        </div>
      }
    />
  );
}

export default Message;
