import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Spin,
  Form,
  message,
  Select,
  Row,
  Col,
  Switch,
  Button,
} from "antd";

import { showFormApi, listFormApi } from "../../../../../../../services/forms";

const validateMessages = {
  required: "${label} é obrigatório!",
};

const { Option } = Select;

const ModalForm = (props) => {
  const [loading, setLoading] = useState(false);

  const [formSelected, setFormSelected] = useState("");
  const [formMomentSelected, setFormMomentSelected] = useState("");
  const [pageSelectForm, setPageSelectForm] = useState(1);
  const [rowsPerPageSelectForm, setRowsPerPageSelectForm] = useState(20);
  const [likeTitleForm, setLikeTitleForm] = useState("");
  const [formsList, setFormsList] = useState({ rows: [], count: 0 });

  const [form] = Form.useForm();

  const signin = useSelector((state) => state.signin);

  useEffect(() => {
    getForms();
  }, []);

  const getForms = () => {
    const offset =
      pageSelectForm !== 1 ? rowsPerPageSelectForm * (pageSelectForm - 1) : 0;

    listFormApi
      .listForm(
        rowsPerPageSelectForm,
        offset,
        true,
        signin.user.currentCompany,
        likeTitleForm
      )
      .then((res) => setFormsList(res));
  };

  const handleSubmit = (values) => {
    if (!formSelected)
      return message.info("É necessário selecionar um formulário!");

    if (!formMomentSelected)
      return message.info("É necessário selecionar um momento do formulário!");

    if (props.forms.rows.find((form) => form.id === formSelected))
      return message.info("Formulário já existente!");

    // if (
    //   props.forms.rows.find((form) => form.fill_moment === values.fill_moment)
    // )
    //   return message.info("Já existe formulário para o momento desejado!");

    // if (props.forms.rows.length >= 2)
    //   return message.info("Só são permitido até 2 formulários!");

    showFormApi
      .showForm(formSelected)
      .then((res) => {
        const formsForm = [...props.forms.rows];
        formsForm.push({
          ...res,
          fill_moment: formMomentSelected,
          is_required: values.is_required,
        });
        props.setForms({ rows: formsForm, count: formsForm.length });

        props.setOpenModal(false);
      })
      .catch((error) =>
        message.error("Não foi possível localizar o formulário")
      );
  };

  return (
    <Modal
      title="Incluir Formulário"
      centered
      visible={props.openModal}
      width={600}
      onCancel={() => props.setOpenModal(false)}
      footer={false}
    >
      <Spin tip="Loading..." spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          name="form"
          onFinish={handleSubmit}
          validateMessages={validateMessages}
          initialValues={{
            ["id_form"]: "",
            ["fill_moment"]: "",
            ["is_required"]: false,
          }}
        >
          <Row>
            <Col lg={24}>
              <Form.Item
                name="type"
                label="Tipo"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: 500 }}
                  placeholder="Selecione um Formulário"
                  optionFilterProp="children"
                  onChange={(value) => setFormSelected(value)}
                  onSearch={(value) => setLikeTitleForm(value)}
                >
                  {formsList.rows.map((form) => {
                    return (
                      <Option key={form.id} value={form.id}>
                        {form.title}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={24}>
              <Form.Item
                name="fill_moment"
                label="Fase de preenchimento"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  style={{ width: 500 }}
                  placeholder="Selecione um Momento"
                  optionFilterProp="children"
                  onChange={(value) => setFormMomentSelected(value)}
                >
                  <Option key={1} value={1}>
                    Início
                  </Option>
                  <Option key={2} value={2}>
                    Final
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={24}>
              <Form.Item
                name="is_required"
                label="Obrigatório"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Switch
                  checkedChildren="É Obrigatório"
                  unCheckedChildren="Não é Obrigatório"
                  defaultChecked={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  marginLeft: "8px",
                }}
              >
                Adicionar
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalForm;
