import { useState, useEffect } from "react";

import {
  Switch,
  Button,
  message,
  Tooltip,
  Row,
  Col,
  Tag,
  Drawer,
  Table,
} from "antd";

import {
  PlusOutlined,
  EditOutlined,
  FilterFilled,
  FilterOutlined,
} from "@ant-design/icons";

import { useSelector } from "react-redux";

import Main from "../../../Layout/Main";
import MenuComponent from "../../../../components/MenuComponent";
import { itensDrawer } from "../itens-menu";
import { checkControl } from "../../../../utils/access_control";
import api from "../../../../utils/apiNotification";
import FormFilter from "./components/FormFilter";
import Form from "./components/Form";

const Notification = (props) => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(true);
  const [notifications, setNotifications] = useState({ count: 0, rows: [] });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isFiltered, setIsFiltered] = useState(false);
  const [showDrawerFilter, setShowDrawerFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const [titleDrawer, setTitleDrawer] = useState("");
  const [showDrawer, setShowDrawer] = useState("");
  const [notification, setNotification] = useState({});
  const [reloadIndex, setReloadIndex] = useState(false);

  const signin = useSelector((state) => state.signin);

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Central de Notificações",
      path: props.modulePath + "/notifications",
    },
  ];

  useEffect(() => {
    getData();
  }, [active, rowsPerPage, page, filters, reloadIndex]);

  const getData = async () => {
    try {
      setLoading(true);

      const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;

      const result = await api.get("/notifications", {
        params: {
          active,
          limit: rowsPerPage,
          offset,
          ...filters,
        },
      });
      
      setNotifications(result.data);
    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlerFilter = async (values = {}) => {
    setIsFiltered(false);
    Object.keys(values).forEach((item) => {
      if (values[item] !== "" && values[item] !== undefined) {
        setIsFiltered(true);
      }
    });

    setPage(1);
    setFilters(values);
    setShowDrawerFilter(false);
  };

  let columns = [
    {
      title: "Título",
      dataIndex: "title",
      key: 'title',
    },
    {
      title: "Aplicação",
      dataIndex: "application_code",
    },
    {
      title: "Situação",
      dataIndex: "active",
      render: (active) => {
        let color = "";
        if (active === true) {
          color = "green";
        } else {
          color = "volcano";
        }
        return <Tag color={color}>{active ? "Enviado" : "Cancelado"}</Tag>;
      },
    },
    {
      title: "Ações",
      render: (object) => {
        return (
          <Row justify="space-between">
            <Col xs={12}>
              <Tooltip title="Alterar notificação">
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => {
                    setShowDrawer(true);
                    setNotification(object);
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Main
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          itensMenu={itensDrawer}
          modulePath={props.modulePath}
          selectedMenu="12"
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"flex"}
      buttonHeader={
        <Row gutter={[8, 8]} justify="space-between">
          <Col>
            <span
              style={checkControl(signin.user.profiles, "products_inactivate")}
            >
              <Switch
                checkedChildren="Inativos"
                unCheckedChildren="Ativos"
                onChange={() => setActive(!active)}
                style={{ marginRight: "5px", marginTop: "5px" }}
              />
            </span>
          </Col>
          <Col>
            <Tooltip placement="left" title={"Filtrar os objetos na tabela."}>
              <Button
                type="secondary"
                icon={
                  isFiltered ? <FilterFilled /> : <FilterOutlined />
                }
                onClick={() => setShowDrawerFilter(true)}
              />
            </Tooltip>
          </Col>
          <Col>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => {
                setNotification({
                  id: null,
                  application_code: "",
                  notification_type_id: null,
                  title: "",
                  body: "",
                  action: "",
                  active: true,
                  deleted_at: null
                });

                setTitleDrawer('Incluir notificação');
                setShowDrawer(true);
              }}
              style={{
                ...checkControl(signin.user.profiles, "save_and_update_products"),
                marginLeft: "20px",
              }}
            >
              Nova notificação
            </Button>
          </Col>
        </Row>
      }
    >
      <Table
        size="small"
        columns={columns}
        dataSource={notifications.rows}
        rowKey={(record) => record.sequence}
        loading={loading}
        pagination={{
          current: page,
          pageSize: rowsPerPage,
          total: notifications.count,
          showSizeChanger: true,
          onShowSizeChange: function (current, size) {
            setRowsPerPage(size);
          },
          pageSizeOptions: [5, 10, 50, 100],
        }}
        onChange={(pagination) =>
          setPage(pagination.current)
        }
      />

      <Drawer
        title="Filtrar objetos"
        width="30%"
        onClose={() => setShowDrawerFilter(false)}
        visible={showDrawerFilter}
        getContainer={false}
      >
        <FormFilter
          handlerFilter={handlerFilter}
        />
      </Drawer>

      <Drawer
        title={titleDrawer}
        width="70%"
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
        getContainer={false}
      >
        <Form
          key="formNotification"
          notification={notification}
          setShowDrawer={setShowDrawer}
          setReloadIndex={setReloadIndex}
        />
      </Drawer>

    </Main>
  );
};

export default Notification;
