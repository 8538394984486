import {Col, Table } from 'antd';


function TableDependents(props) {
  const columns = [
    {
      title: 'Item',
      dataIndex: 'sequence',
      key: 'sequence',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'CPF',
      dataIndex: 'cpf',
      key: 'cpf',
    },
    {
      title: 'Sexo',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'Data de nascimento',
      dataIndex: 'birth_date',
      key: 'birth_date',
    },
    //fiz aqui
    {
      title: 'Parentesco',
      dataIndex: 'kinship',
      key: 'customer_name',
    },
    {
      title: 'Data de inclusão',
      dataIndex: 'added_at',
      key: 'added_at',
    },
    {
      title: 'Data de Óbito',
      dataIndex: 'death_date',
      key: 'death_date',
    },
    {
      title: 'Fim da carência',
      dataIndex: 'grace_period',
      key: 'grace_period',
    },

  ];
  return (
      <Col span={24}>
        <Table
          dataSource={props.data ?? [{}]}
          columns={columns}
          scroll={{ x: 1100 }}
        />
      </Col>
  )
}

export default TableDependents;