import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
} from "antd";
import moment from "moment";
import { useEffect } from "react";
import {
  createCemiteryReserveMutation,
  updateCemiteryReserveMutation,
} from "../../../../services/cemeteryReserveService";
import { useMutate } from "../../../../utils/useMutate";

export const CemeteryReserveModal = ({
  onClose,
  onSuccess,
  baseService,
  open,
  setOpen,
}) => {
  const existingReserve = baseService?.cemetery_reserve || {};

  const { mutate: createCemiteryReserve } = useMutate(
    createCemiteryReserveMutation,
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        message.success("Reserva cadastrada com sucesso!");
        setOpen(false);
      },
      onError: () => message.error("Erro ao cadastrar reserva!"),
    }
  );

  const { mutate: updateCemiteryReserve } = useMutate(
    updateCemiteryReserveMutation,
    {
      onSuccess: (resp) => {
        onSuccess && onSuccess();
        message.success("Reserva atualizada com sucesso!");
        setOpen(false);
      },
      onError: (err) => message.error("Erro ao atualizar reserva!"),
    }
  );

  const [form] = Form.useForm();

  const handleSubimit = (data) => {
    if (existingReserve.id) {
      updateCemiteryReserve({
        ...data,
        id: existingReserve.id,
      });
    } else {
      createCemiteryReserve({
        ...data,
        base_service_id: baseService.id,
      });
    }
  };

  useEffect(() => {
    if (existingReserve.id) {
      form.setFieldsValue({
        localization: existingReserve.localization,
        type: existingReserve.type,
        reserve_date: existingReserve.reserve_date
          ? moment(existingReserve.reserve_date)
          : null,
      });
    } else {
      form.resetFields();
    }
  }, [baseService]);

  if (!baseService) {
    return null;
  }

  return (
    <Modal
      title="Reserva Cemitério Externo"
      open={open}
      destroyOnClose={true}
      onCancel={() => {
        onClose && onClose();
        setOpen(false);
      }}
      footer={null}
    >
      <Form
        form={form}
        name="reserval-modal"
        onFinish={handleSubimit}
        className="reserval-modal"
        layout="vertical"
      >
        <Form.Item name="localization" label="Nome Cemitério">
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Forma Sepultamento">
          <Input />
        </Form.Item>
        <Form.Item name="reserve_date" label="Data e hora da reserva">
          <DatePicker
            style={{ width: "100%" }}
            placeholder=""
            format={"DD/MM/YYYY HH:mm"}
          />
        </Form.Item>
        <Row gutter={8}>
          <Col style={{ marginTop: "4%", marginLeft: "80%" }}>
            <Button type="primary" shape="round" htmlType="submit">
              {existingReserve.id ? "Atualizar" : "Salvar"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
