import { Col, Row, Timeline, Tooltip } from "antd";
import { UAParser } from "ua-parser-js";
import { GlobalOutlined, EnvironmentOutlined } from "@ant-design/icons";
import DynamicIcon from "../../../../../../utils/dynamicIcon";

export const LogsInfo = (props) => {
  const uaParser = new UAParser(props.device || "");
  const { os, device } = uaParser.getResult();

  const deviceIcon = {
    Android: "AndroidOutlined",
    iOS: "AppleOutlined",
    Windows: "WindowsOutlined",
  };

  return (
    <Timeline style={{ marginLeft: ".5rem" }}>
      {props.address_ip && (
        <Timeline.Item dot={<GlobalOutlined />}>
          <Row>
            <Col
              xs={{
                span: 10,
              }}
              md={{
                span: 6,
              }}
            >
              <b>Endereço IP:</b>
            </Col>
            <Col
              xs={{
                span: 14,
              }}
              md={{
                span: 18,
              }}
            >
              {props.address_ip}
            </Col>
          </Row>
        </Timeline.Item>
      )}
      {os.name && (
        <Timeline.Item dot={<DynamicIcon name={deviceIcon[os.name] || null} />}>
          <Row>
            <Col
              xs={{
                span: 10,
              }}
              md={{
                span: 6,
              }}
            >
              <b>Dispositivo:</b>
            </Col>
            <Col
              xs={{
                span: 14,
              }}
              md={{
                span: 18,
              }}
            >
              <Tooltip title={props.device}>
                {os.name} {device.vendor} {device.model}
              </Tooltip>
            </Col>
          </Row>
        </Timeline.Item>
      )}
      {props.location && (
        <Timeline.Item dot={<EnvironmentOutlined />}>
          <Row>
            <Col
              xs={{
                span: 10,
              }}
              md={{
                span: 6,
              }}
            >
              <b>Localização:</b>
            </Col>
            <Col
              xs={{
                span: 14,
              }}
              md={{
                span: 18,
              }}
            >
              {props.location}
            </Col>
          </Row>
        </Timeline.Item>
      )}
    </Timeline>
  );
};
