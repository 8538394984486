import { api, getRequest, postRequest } from "../utils/apiProtheus";

export const duplicateBilletApi = {

  listBol: (contract, headers, birthday) => {
    let url = `/all/CONTRATO/get-contract-by-customer?busca=${contract}`
    if(birthday) url += "&date=" + birthday;
    return getRequest(
      url,
      headers
    )
  },


  getBillet: (contract, typeContrat, headers) =>
    getRequest(
      `all/PARCELAS/contrato?Contrato=${contract}&TipoContrt=${typeContrat}`,
      headers
    ),

  // reprogramaBoleto: (body, headers) =>
  //   postRequest("all/PARCELAS/reprogramar", body, headers),

  gerarParcela: (body, headers) =>
    postRequest("all/PARCELAS/gerarparcela?behavior=base64", body, headers),

  generateHistorico: (body, headers) =>
    postRequest("all/HISTORICO/gerar", body, headers),

  gerarNegociacao: (body, headers) =>
    api.post("all/NEGOCIACAO/gerarnegociacao", body, headers),

  cancelNegociation: ({ body, headers }) => {
   return api.delete("all/NEGOCIACAO/cancelNegotiation", { data: body, headers })
  }
    
};
