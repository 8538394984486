import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Form, Alert, Button, Select, Spin } from "antd";

import { setCurrentCompany, setTenantId } from "../../../../store/ducks/signin";

import { CompanyApi } from "../../../../services/companies";
import { useHistory } from "react-router";

const { Option } = Select;

const SelectCompany = () => {
  const [formItemDisabled, setFormItemDisabled] = useState({});
  const [errorAlertVisibility, setErrorAlertVisibility] = useState("none");

  const signin = useSelector((state) => state.signin);

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = async (values) => {
    setErrorAlertVisibility("none");

    try {
      const company = await CompanyApi.get(values.company);
      dispatch(
        setTenantId(`${company.Hierarquia.code_erp},${company.code_erp}`)
      );

      dispatch(setCurrentCompany(values.company));
      return history.push("/");
    } catch (error) {
      setErrorAlertVisibility("block");
    }
  };

  return (
    <>
      <strong>Olá, {signin.user.name}!</strong>
      <div className="login-page-padding-form">
        <Form
          layout="vertical"
          form={form}
          name="signin-form"
          onFinish={onFinish}
          initialValues={{
            company: signin.user.currentCompany,
          }}
        >
          <Form.Item
            label="Empresa"
            name="company"
            rules={[
              {
                required: true,
                message: "Selecione a empresa que deseja logar!",
              },
            ]}
          >
            <Select placeholder="Selecione a Empresa">
              {signin.user.userCompanies.map((company) => (
                <Option
                  key={company.id}
                  value={company.id}
                >
                  {company.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              shape="round"
              block
              {...formItemDisabled}
            >
              Entrar
            </Button>
          </Form.Item>
        </Form>

        <Alert
          message="Não foi possível logar!"
          description="Por favor, revise os dados e tente novamente."
          type="error"
          showIcon
          style={{ marginTop: "10px", display: errorAlertVisibility }}
        />
      </div>
    </>
  );
};

export default SelectCompany;
