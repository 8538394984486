import { getRequest, postRequest } from "../utils/apiProtheus";

export const getProductsProtheus = {
  listProducts: (headers) =>
    getRequest(`/all/restprodutos/produtosvenda`, headers),
};

/**
 * Retorna as propostas do consultor
 * @param {string} cpf - CPF do consultor
 */
export const getConsultantProposalsQuery = async ({params, headers}) => {
  return getRequest(`/all/VENDEDORES/api/v1/getPropostas?${new URLSearchParams(params)}`, {
    headers
  })
}

/**
 * Retorna o status do registro através do código de proposta
 * - Puxa somente propostas que não estão associadas a nenhum PIX
 * - Puxa nos status de enviado ao vendedor e entregue a vendedor
 */
export const getProposalStatusQuery = ({numProposal}) => {
  return getRequest(`/03/03/RestPixItau/api/v1/getStatus?proposta=${numProposal}`)
}

/**
 * Busca o cliente pelo CPF
 * @param {string} cpf - CPF do cliente
 */
export const getClientByCpfQuery = async (cpf) => {
  return getRequest(`/all/CLIENTES/buscarCpf/${cpf}`)
}

export const getContractsByCpfQuery = async (cpf) => {
  return getRequest(`/all/CONTRATO/get-contract-by-customer?busca=${cpf}`)
}


/**
 * Gera o boleto de uma parcela, podendo retornar a string base64
 * @param {object} payload - Objeto com os dados da parcela
 * @param {string} payload.contrato - Número do contrato
 * @param {string} payload.numero - Identificador da parcela
 * @param {string} payload.parcela - Número da parcela
 * @param {string} payload.prefixo - Prefixo da parcela
 * @param {string} payload.tipo - Tipo da parcela
 * @param {string} payload.tipoContrt - Tipo do contrato
 * @param {string} behavior - Comportamento da requisição: 'base64' (retorna string base64)
 * @param {string} tennantId - Empresa,Filial
 * @returns {Promise}
 */

export const generateBillet = async (payload, behavior, tennantId) => {
  if (!behavior || behavior !== 'base64') {
    throw new Error('Parâmetro behavior inválido.')
  }

  return postRequest(`all/PARCELAS/gerarparcela?behavior=${behavior}`, payload, {
    headers: {
      tenantId: tennantId
    }
  })
}


/**
 * Retorna os dados do vendedor
 * @param {string} key - Identificador do vendedor (CPF ou código)
 */
export const getVendedor = async (key) => {
  return getRequest(`/all/VENDEDORES?valor=${key}`)
}