import api from "../utils/apiNotification";

export const getUserNotificationsQuery = async (params) => {
  const response = await api.get(
    `/notification-receivers?${new URLSearchParams({ ...params, limit: 20, offset: 0 })}`
  );
  return response.data;
};

export const getManyNotificationsQuery = async (params) => {
  const response = await api.get(
    `/notifications?${new URLSearchParams(params)}`
  );
  return response.data;
};

export const updateNotificationReceiversMutation = async (payload) => {
  const response = await api.put(`/notification-receivers/update`, payload);
  return response.data;
};

export const createApplicationMutation = async (payload) => {
  const response = await api.post(`/applications/create`, payload);
  return response.data;
};

export const getApplicationsQuery = async (params) => {
  const response = await api.get(
    `/applications?${new URLSearchParams(params)}`
  );
  return response.data;
};

export const updateApplicationMutation = async (payload) => {
  const response = await api.put(`/applications/update`, payload);
  return response.data;
};

export const deleteApplicationMutation = async (payload) => {
  const response = await api.delete(`/applications/delete`, {
    data: payload,
  });
  return response.data;
};

export const restoreApplicationMutation = async (payload) => {
  const response = await api.put(`/applications/restore`, payload);
  return response.data;
};
