import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.css";

import {
  Form,
  Input,
  Button,
  Select,
  message,
  Popconfirm,
  Spin,
  Switch,
} from "antd";

import MaskedInput from "antd-mask-input";

import {
  addCompany,
  alterCompany,
  updateCompany,
  deleteCompany,
} from "../../../../../../store/ducks/companies";
import { closeModal } from "../../../../../../store/ducks/modalGlobal";

import {
  listCompanyApi,
  addCompanyApi,
  updateCompanyApi,
  inactivateCompanyApi,
} from "../../../../../../services/companies";

import { checkControl } from "../../../../../../utils/access_control";
import { checkCNPJ } from "../../../../../../utils/validations";

const { Option } = Select;

const validateMessages = {
  required: "${label} é obrigatório!",
};

function FormApp() {
  const [loading, setLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [companiesList, setCompaniesList] = useState([]);

  const signin = useSelector((state) => state.signin);
  const company = useSelector((state) => state.companies.company);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [company]);

  useEffect(() => {
    getCompanies();
  }, [page]);

  const getCompanies = () => {
    setLoading(true);

    const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
    listCompanyApi.listCompany(rowsPerPage, offset, true).then((response) => {
      if (page !== 1) {
        response.rows.map((res) => {
          companiesList.rows.push(res);
        });
      } else {
        setCompaniesList(response);
      }
      setLoading(false);
    });
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    if (!company.id) {
      addCompanyApi
        .addCompany(values)
        .then((res) => {
          dispatch(addCompany(res));
          dispatch(alterCompany({}));
          dispatch(closeModal(false));
          setLoading(false);
          message.success("Empresa criada com sucesso!");
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    } else {
      const body = {
        ...company,
        ...values,
      };
      body.cnpj = body.cnpj.replace(/\D/g, "");
      body.phone = body.phone.replace(/\D/g, "");
      updateCompanyApi
        .updateCompany(body, body.id)
        .then((res) => {
          dispatch(updateCompany(res));
          dispatch(alterCompany({}));
          dispatch(closeModal(false));
          setLoading(false);
          message.success("Empresa alterada com sucesso!");
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    }
  };

  const inactivate = async () => {
    setLoading(true);

    await inactivateCompanyApi
      .inactivateCompany(company.id)
      .then((res) => {
        dispatch(deleteCompany(res));
        dispatch(alterCompany({}));
        dispatch(closeModal(false));
        setLoading(false);
        message.success("Empresa inativada com sucesso!");
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };

  const onPopupScroll = (e) => {
    e.persist();
    let target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      const newPage = page + 1;
      setpage(newPage);
      setRowsPerPage(10);
    }
  };

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Form
        layout="vertical"
        form={form}
        name="form-companies"
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        initialValues={{
          ["name"]: company.name,
          ["cnpj"]: company.cnpj,
          ["phone"]: company.phone,
          ["code_erp"]: company.code_erp,
          ["id_company"]: company.id_company,
        }}
      >
        <Form.Item
          name="name"
          label="Nome"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="cnpj"
          label="CNPJ"
          rules={[
            {
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (checkCNPJ(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Informe um CNPJ válido!");
                }
              },
            }),
          ]}
        >
          <MaskedInput
            mask="00.000.000/0000-00"
            placeholder="00.000.000/0000-00"
            allowClear={true}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Contato"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <MaskedInput
            mask="(00) 00000-0000"
            placeholder="(00) 00000-0000"
            allowClear={true}
          />
        </Form.Item>
        <Form.Item
          name="code_erp"
          label="Código ERP"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item name="id_company" label="Matriz">
          <Select
            placeholder="Selecione uma opção"
            allowClear
            onPopupScroll={onPopupScroll}
          >
            {companiesList.length !== 0
              ? companiesList.rows.map((company) => (
                <Option key={company.id} value={company.id}>
                  {company.name}
                </Option>
              ))
              : null}
          </Select>
        </Form.Item>

        <div className="buttonsClass">
          {company.id && !company.active ? (
            <Form.Item name="active" label="Ativar" valuePropName="checked">
              <Switch checkedChildren="Ativo" unCheckedChildren="Inativo" />
            </Form.Item>
          ) : null}

          <Form.Item>
            {company.active ? (
              <Popconfirm
                title="Deseja inativar a empresa?"
                onConfirm={inactivate}
                okText="Sim"
                cancelText="Não"
              >
                <Button
                  type="danger"
                  style={checkControl(
                    signin.user.profiles,
                    "inactivate_company"
                  )}
                >
                  Inativar
                </Button>
              </Popconfirm>
            ) : null}

            <Button
              type="primary"
              htmlType="submit"
              style={{
                ...checkControl(
                  signin.user.profiles,
                  "save_and_update_company"
                ),
                marginLeft: "8px",
              }}
            >
              Salvar
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
}

export default FormApp;
