import { Input, Row, Col, Form, Select, DatePicker } from 'antd';
import { MaskedInput } from 'antd-mask-input';



function FieldsNatimorto(props) {

  return (
    <div>
      <Form.Item
        label="Nome do Natimorto:"
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          },
        ]}
        name="name_natimorto">
        <Input />
      </Form.Item>

      <Form.Item
        label={"Selecione o Nome do Pai/Mãe"}
        name={"parents"}
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          }
        ]}
      >
        <Select>
          {!props.hasOwnProperty("contract") ? null :
            <Select.Option key={props.contract.id ?? ""} value={props.contract.customer_name ?? ""}>
              {props.contract.customer_name ?? ""}
            </Select.Option>}
          {!props.hasOwnProperty("dependents") ? null : props.dependents.map(
            option => (
              <Select.Option key={option.id} value={option.name ?? ""}>
                {option.name ?? ""}
              </Select.Option>
            )
          )}
        </Select>
      </Form.Item>

      <Row justify={"space-between"}>
        <Form.Item
          label="Data de Nascimento"
          name="date_birth">
          <DatePicker />
        </Form.Item>

        <Form.Item
          label="Data de Falecimento"
          name="date_death">
          <DatePicker />
        </Form.Item>
      </Row>

    </div>
  )
}


export default FieldsNatimorto;