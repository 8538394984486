export const itensMenu = [
  {
    key: 0,
    permission: "access_menu_home",
    url: "/",
    title: "Início",
    icon: "HomeOutlined",
  },
  {
    key: 1,
    permission: "access_menu_users",
    url: "/users",
    title: "Usuários",
    icon: "TeamOutlined",
  },
  {
    key: 2,
    permission: "access_menu_profiles",
    url: "/profiles",
    title: "Perfis",
    icon: "ProfileOutlined",
  },
  {
    key: 3,
    permission: "access_menu_template_mails",
    url: "/template-mail",
    title: "Modelos de E-mail",
    icon: "MailOutlined",
  },
  {
    key: 4,
    permission: "access_menu_forms",
    url: "/forms",
    title: "Formulários",
    icon: "FormOutlined",
  },
  {
    key: 5,
    permission: "access_menu_template_docs",
    url: "/template/documents",
    title: "Modelos de Documentos",
    icon: "FileTextOutlined",
  },
  {
    key: 6,
    permission: "access_menu_goal",
    url: "/administrative/goal",
    title: "Metas",
    icon: "RiseOutlined",
  },
  {
    key: 7,
    permission: "access_menu_setting_services",
    url: "/service-management/setting-services",
    title: "Configuração de Serviços",
    icon: "ControlOutlined",
  },
  {
    key: 8,
    permission: "access_menu_sectors",
    url: "/service_management/setors",
    title: "Setores",
    icon: "PartitionOutlined",
  },
  {
    key: 9,
    permission: "access_menu_products",
    url: "/products",
    title: "Produtos",
    icon: "BarcodeOutlined",
  },
  {
    key: 10,
    permission: "access_menu_companys",
    url: "/companies",
    title: "Empresas",
    icon: "BankOutlined",
  },
  {
    key: 11,
    permission: "access_menu_resources",
    url: "/resources",
    title: "Recursos",
    icon: "ProfileOutlined",
  },
  {
    key: 12,
    permission: "access_menu_module",
    url: "/modules",
    title: "Módulos",
    icon: "AppstoreOutlined",
  },
  {
    key: 13,
    permission: "access_menu_reports_os",
    url: "/reports",
    title: "Relatórios",
    icon: "FundOutlined",
  },
  {
    key: 14,
    permission: "access_menu_module",
    url: "/external-links",
    title: "Links Externos",
    icon: "LinkOutlined",
  },
  {
    key: 15,
    permission: "access_menu_notification",
    url: "/notifications",
    title: "Notificações",
    icon: "NotificationOutlined",
  },
];

export const itensDrawer = [
  {
    key: 0,
    permission: "access_menu_home",
    url: "/",
    title: "Início",
    icon: "HomeOutlined",
  },
  {
    key: 1,
    icon: "UserOutlined",
    title: "Gestão de Usuários",
    subMenu: [
      {
        key: 2,
        icon: "TeamOutlined",
        permission: "access_menu_users",
        url: "/users",
        title: "Usuários",
      },
      {
        key: 3,
        icon: "ProfileOutlined",
        permission: "access_menu_profiles",
        url: "/profiles",
        title: "Perfis",
      },
      {
        key: 4,
        icon: "UsergroupAddOutlined",
        permission: "access_menu_user_groups",
        url: "/user-groups",
        title: "Grupos de Usuários",
      },
    ],
  },
  {
    key: 4,
    icon: "EditOutlined",
    title: "Cadastro de Modelos",
    subMenu: [
      {
        key: 5,
        icon: "MailOutlined",
        permission: "access_menu_template_mails",
        url: "/template-mail",
        title: "E-mail",
      },
      {
        key: 6,
        icon: "FormOutlined",
        permission: "access_menu_forms",
        url: "/forms",
        title: "Formulários",
      },
      {
        key: 7,
        icon: "FileTextOutlined",
        permission: "access_menu_template_docs",
        url: "/template/documents",
        title: "Documentos",
      },
      {
        key: 8,
        icon: "RiseOutlined",
        permission: "access_menu_goal",
        url: "/administrative/goal",
        title: "Metas",
      },
    ],
  },
  {
    key: 9,
    icon: "SlidersOutlined",
    title: "Gestão de Serviços",
    subMenu: [
      {
        key: 10,
        icon: "ControlOutlined",
        permission: "access_menu_setting_services",
        url: "/service-management/setting-services",
        title: "Configuração de Serviços",
      },
      {
        key: 11,
        icon: "PartitionOutlined",
        permission: "access_menu_sectors",
        url: "/service_management/setors",
        title: "Setores",
      },
      {
        key: 12,
        icon: "BarcodeOutlined",
        permission: "access_menu_products",
        url: "/products",
        title: "Produtos",
      },
      {
        key: 13,
        icon: "FundOutlined",
        permission: "access_menu_reports_os",
        url: "/reports",
        title: "Relatórios",
      },
      {
        key: 20,
        icon: "CheckSquareOutlined",
        permission: "access_menu_field_rules",
        url: "/service-management/setting-services/field-rules",
        title: "Regras de Campo",
      }
    ],
  },
  {
    key: 14,
    icon: "ApartmentOutlined",
    title: "Cadastros Gerais",
    subMenu: [
      {
        key: 15,
        icon: "BankOutlined",
        permission: "access_menu_companys",
        url: "/companies",
        title: "Empresas",
      },
      {
        key: 16,
        icon: "ProfileOutlined",
        permission: "access_menu_resources",
        url: "/resources",
        title: "Recursos",
      },
      {
        key: 17,
        icon: "AppstoreOutlined",
        permission: "access_menu_module",
        url: "/modules",
        title: "Módulos",
      },
    ],
  },
  {
    key: 18,
    icon: "NotificationOutlined",
    title: "Central de Notificações",
    subMenu: [
      {
        key: 15,
        permission: "access_menu_notification",
        url: "/notifications",
        title: "Notificações",
        icon: "NotificationOutlined",
      },
      {
        key: 16,
        permission: "access_menu_notification",
        url: "/notifications-application",
        title: "Aplicações",
        icon: "DesktopOutlined",
      },
    ],
  },
];
