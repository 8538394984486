import { Button, Row, Space } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { useReports } from "../../../../../../Context/reportsContext";
import { GenerateCSVLink } from "../../../../../../utils/csv";

export const ReportResults = () => {
  const { reportData, handleFinish, reportType,  intervalDate,  } = useReports();

  const [startInterval, endInterval] = intervalDate;

  const signin = useSelector((state) => state.signin);
  const currentCompanyName = useSelector(
    (state) =>
      state.signin.user.userCompanies.find(
        (company) => company.id === signin.user.currentCompany
      ).name
  );

  return (
    <Space
      style={{
        width: "100%",
        rowGap: "26px",
      }}
      direction="vertical"
    >
      {reportType === "attendance_and_os" && reportData.dataAttendanceCSV &&  (
        <>
          <Row>
            <Button
              type="primary"
              style={{ background: "#017f3e", borderColor: "#017f3e" }}
              size="large"
              block
            >
              <GenerateCSVLink
                data={reportData.dataAttendanceCSV}
                name="Baixar CSV de Atendimentos"
                filename={`ATENDIMENTOS GS - ${currentCompanyName} - ${moment(
                  startInterval
                ).format("DD-MM-YYYY")} A ${moment(endInterval).format(
                  "DD-MM-YYYY"
                )}`}
              />
            </Button>
          </Row>
          <Row>
            <Button
              type="primary"
              style={{ background: "#017f3e", borderColor: "#017f3e" }}
              size="large"
              block
            >
              <GenerateCSVLink
                data={reportData.dataOsCSV}
                name="Baixar CSV de OS's"
                filename={`OSs GS - ${currentCompanyName} - ${moment(
                  startInterval
                ).format("DD-MM-YYYY")} A ${moment(endInterval).format(
                  "DD-MM-YYYY"
                )}`}
              />
            </Button>
          </Row>
        </>
      )}
      {reportType === "checklist" && reportData.dataChecklistCSV && (
        <Row>
          <Button
            type="primary"
            style={{ background: "#017f3e", borderColor: "#017f3e" }}
            size="large"
            block
          >
            <CSVLink
              headers={reportData.dataChecklistCSV.headers}
              data={reportData.dataChecklistCSV.rows}
              name="Baixar CSV de Checklist"
              filename={`CHECKLIST GS - ${currentCompanyName} - ${moment(
                startInterval
              ).format("DD-MM-YYYY")} A ${moment(endInterval).format(
                "DD-MM-YYYY"
              )}`}
            >
              Baixar CSV de Checklist
            </CSVLink>
          </Button>
        </Row>
      )}

      <Row>
        <Button type="danger" onClick={handleFinish} size="large" block>
          Finalizar
        </Button>
      </Row>
    </Space>
  );
};
