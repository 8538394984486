
import { getRequest, postRequest, putRequest } from "../utils/firebase";

export const firebaseApi = {

  Agendar: async (headers) => await postRequest(`/task`, headers),
  Finalizar: async (headers, appointment_id) => await putRequest(`/task/${appointment_id}`, headers),
  Liberar: async (headers) => await postRequest(`/task`, headers),
  RemoverDaFilaDeAtendimento: async (appointment_id) => await postRequest(`/task/${appointment_id}?removeFromQueue=1`),
  GetTask: async (appointment_id) => await getRequest(`task/${appointment_id}`)
};
