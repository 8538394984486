/* eslint-disable no-template-curly-in-string */
import { useState, useContext } from "react";

import {
    Spin,
    Row,
    Col,
    DatePicker,
    TimePicker,
    Form,
    Button,
    Input,
    message,
    InputNumber,
    Select,
    Divider,
} from "antd";
import locale from 'antd/es/date-picker/locale/pt_BR';

import MaskedInput from "antd-mask-input";
import InputMask from "react-input-mask";
import moment from 'moment';

import { RemovalAnticipationFormContext } from "../../../../../../Context/removal-anticipation-form-context";
import { checkCPF } from "../../../../../../utils/validations";
import SelectStaticProtheusData from "../../../../../../components/SelectStaticProtheusData";

const DeceasedData = () => {
    const [loading, setLoading] = useState(false);

    const removalAnticipationFormContext = useContext(
        RemovalAnticipationFormContext
    );

    const [removalAnticipationDeceasedDataForm] = Form.useForm();

    const removeType =
        removalAnticipationFormContext.formResult.contract.removeType;

    const serviceTypeOptions = [
        {
            label: "Cremação",
            value: "1",
        },
        {
            label: "Sepultamento",
            value: "2",
        },
    ];

    const handleReturn = () => {
        removalAnticipationFormContext.setFormResult({
            ...removalAnticipationFormContext.formResult,
            ...removalAnticipationDeceasedDataForm.getFieldsValue(),
        });
        removalAnticipationFormContext.setCurrent(
            removalAnticipationFormContext.current - 1
        );
    };
    
    const [isCpfRequired, setIsCpfRequired] = useState(false);

    const [natimortoValue, setNatimortoValue] = useState(false); 
  
    const handleNatimortoChange = (value) => {
      
      if (isCpfRequired) {
          setNatimortoValue(false);
      } else {
          setNatimortoValue(value);
      }
    };

    const handleSubmit = (values) => {
        try {
            removalAnticipationFormContext.setFormResult({
                ...removalAnticipationFormContext.formResult,
                deceased: values.deceased,
            });
            removalAnticipationFormContext.setCurrent(
                removalAnticipationFormContext.current + 1
            );
        } catch (error) {
            message.error({ content: error.message });
        }
    };

    return (
        <Spin tip="Carregando..." spinning={loading}>
            <Form
                form={removalAnticipationDeceasedDataForm}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                    deceased: {
                        ...removalAnticipationFormContext.formResult.deceased,
                    },
                }}
            >
                <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                    {removeType === "humano" && (
                        <>
                            <Col xs={24} sm={12} md={4}>
                              <Form.Item
                                  name={["deceased","natimorto"]}
                                  label="Natimorto"
                                  initialValue={"Não"}
                              >
                                  <Select
                                      options={[
                                          { label: "Não", value: false },
                                          { label: "Sim", value: true  },
                                      ]}
                                      onChange={handleNatimortoChange}
                                  />
                              </Form.Item>
                            </Col>
                            
                            <Col xs={24} sm={12} md={8}>
                                <Form.Item
                                    label="CPF do Falecido (A)"
                                    name={["deceased", "cpf"]}
                                    rules={[
                                        {
                                            required:natimortoValue !== true || isCpfRequired,
                                            message:
                                                "Campo CPF do Falecido (A) é obrigatório!",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value) {
                                                    return Promise.resolve();
                                                }

                                                if (checkCPF(value)) {
                                                    return Promise.resolve();
                                                } else {
                                                    return Promise.reject(
                                                        "Informe um CPF válido!"
                                                    );
                                                }
                                            },
                                        }),
                                    ]}
                                >
                                    <MaskedInput
                                        mask="000.000.000-00"
                                        placeholder="000.000.000-00"
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={
                                removeType === "pet"
                                    ? "Nome do PET"
                                    : "Nome do Falecido (A)"
                            }
                            name={["deceased", "fullName"]}
                            rules={[
                                {
                                    required: true,
                                    message: "Campo ${label} é obrigatório!",
                                },
                            ]}
                        >
                            <Input allowClear />
                        </Form.Item>
                    </Col>

                    {removeType === "pet" && (
                        <Col xs={8} sm={6}>
                            <Form.Item
                                name={["deceased", "breed"]}
                                label="Raça"
                            >
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                <Row gutter={{ xs: 8, sm: 24 }}>
                    <Col xs={24} sm={8}>
                        <Form.Item
                            label="Data de Nascimento"
                            name={["deceased", "birthDate"]}
                            rules={[
                              {
                                required: true,
                                message: "Campo ${label} é obrigatório!",
                              },
                              {
                                required: false,
                                validator: (_, value) => {
                                    if (!value) {
                                        return Promise.resolve();
                                    }
                                    const [day,month,year] = value.split("/")
                                    if (day?.length!=2 || month?.length!=2 || year?.length!=4){
                                      return Promise.reject()
                                    }
                                    value = moment(value, "DD/MM/YYYY")
                                    if(!value.isSameOrBefore(moment())){
                                      return Promise.reject("Data inválida!")
                                    }

                                    // Calcular a diferença entre a data de nascimento e a data atual em dias
                                    const diffDays = moment().diff(value, 'days');
                                    // Definir se o CPF é obrigatório com base na condição de mais de 30 dias
                                    setIsCpfRequired(diffDays > 30);

                                    if(isCpfRequired  && natimortoValue ){
                                      return Promise.reject("Insira uma data menor que 30 dias")
                                    }
                          
                                    return Promise.resolve(); 
                                  },
                              },
                          ]}
                        >
                            <InputMask
                              mask="99/99/9999"
                              maskChar={null}
                              placeholder="Insira uma Data"
                              type="text"
                            >
                                {(inputProps) => <Input {...inputProps} />}
                            </InputMask>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <SelectStaticProtheusData
                            type="gender"
                            label="Gênero"
                            name={["deceased", "gender"]}
                        />
                    </Col>
                    {
                      removeType === "humano" && (
                        <Col xs={24} sm={8}>
                          <SelectStaticProtheusData
                              rules={[
                                {
                                  required: true,
                                  message: "Campo ${label} é obrigatório!",
                                }
                              ]}
                              type="religion"
                              label="Religião"
                              name={["deceased", "religion"]}                           
                          />
                        </Col>
                            )
                          }
                </Row>
                <Row gutter={{ xs: 8, sm: 24 }}>
                    {removeType === "humano" ? (
                        <>
                            <Col xs={12} sm={6}>
                                <Form.Item
                                    name={["deceased", "weight"]}
                                    label="Peso"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Campo ${label} é obrigatório!",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        placeholder="kg"
                                        max={999}
                                        precision={2}
                                        step={0.01}
                                        type="number"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Form.Item
                                    name={["deceased", "height"]}
                                    label="Altura"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Campo ${label} é obrigatório!",
                                        },
                                    ]}
                                    normalize={(value) => {
                                        if (value) {
                                            return parseFloat(
                                                value.replace(",", ".")
                                            );
                                        }
                                        return value;
                                    }}
                                >
                                    <InputMask
                                        mask="9,99"
                                        maskChar={null}
                                        placeholder="metros"
                                        type="text"
                                    >
                                        {(inputProps) => (
                                            <Input {...inputProps} />
                                        )}
                                    </InputMask>
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={6}>
                                <SelectStaticProtheusData
                                  type="maritalStatus"
                                  label="Estado Civil"
                                  name={["deceased", "maritalStatus"]}
                                />
                            </Col>                           
                      </>
                    ) : (
                        <>
                            <Col xs={8} sm={6}>
                                <Form.Item
                                    name={["deceased", "size"]}
                                    label="Porte"
                                >
                                    <Select allowClear>
                                        <Select.Option value="1">
                                            Pequeno (até 15kg)
                                        </Select.Option>
                                        <Select.Option value="2">
                                            Médio (16kg a 50kg)
                                        </Select.Option>
                                        <Select.Option value="3">
                                            Grande (51kg a 100kg)
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={8}>
                                <Form.Item
                                    name={["deceased", "species"]}
                                    label="Espécie"
                                >
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Row>
                <Divider orientation="left" />
                <Row justify="start" gutter={{ xs: 8, sm: 24 }}>
                    <Col xs={12} sm={6}>
                        <SelectStaticProtheusData
                            defaultValue="020"
                            type="causeOfDeath"
                            label="Causa da morte"
                            name={["deceased", "causeOfDeath"]}
                            rules={[
                              {
                                required: true,
                                message: "Campo ${label} é obrigatório!",
                              }
                            ]}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Item
                            label="Data do óbito"
                            name={["deceased", "deathDate"]}
                            rules={[
                              {
                                required: false,
                                validator: (_, value) => {
                                    if (!value) {
                                        return Promise.resolve();
                                    }
                                    const [day,month,year] = value.split("/")
                                    if (day?.length!=2 || month?.length!=2 || year?.length!=4){
                                      return Promise.reject()
                                    }
                                    value = moment(value, "DD/MM/YYYY")
                                    if(!value.isSameOrBefore(moment())){
                                      return Promise.reject("Data inválida!")
                                    }
                                  return Promise.resolve();
                                  },
                              },
                          ]}
                        >
                            <InputMask
                                mask="99/99/9999"
                                maskChar={null}
                                placeholder="Insira uma Data"
                                type="text"
                            >
                                {(inputProps) => <Input {...inputProps} />}
                            </InputMask>
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Item
                            label="Hora do óbito"
                            name={["deceased", "deathTime"]}
                            rules={[
                              {
                                required: false,
                                validator: (_, value) => {
                                    if (!value) {
                                        return Promise.resolve();
                                    }
                                    const [hour,minute] = value.split(":")

                                    if(hour?.length!=2 || minute?.length!=2){
                                      return Promise.reject()
                                    }
                                    if(hour > 23 || minute > 59){
                                      return Promise.reject("Hora inválida")
                                    }
                                  return Promise.resolve();
                                  },
                              },
                          ]}

                        >
                            <InputMask
                                mask="99:99"
                                maskChar={null}
                                placeholder="hh:mm"
                                type="text"
                            >
                                {(inputProps) => <Input {...inputProps} />}
                            </InputMask>
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Form.Item
                            label="Disposição final"
                            name={["deceased", "serviceType"]}
                        >
                            <Select options={serviceTypeOptions} allowClear />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end">
                    <Col>
                        <Button
                            style={{ margin: "0 8px" }}
                            onClick={handleReturn}
                        >
                            Voltar
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" htmlType="submit">
                            Avançar
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default DeceasedData;
