import { parseInt } from "lodash";
import moment from "moment";
import { appointmentsApiProtheus } from "../services/appointmentsProtheus";
import { userServices } from "../services/users";

// Gera o array de dados para ser manipulado na geração de cada CSV
export const generateArrayCSV = async (data) => {
  let newArray = [];

  if (data.length === 0 || !data) return newArray;

  return Promise.all(
    data.map((value) => {
      if (newArray.length === 0) {
        let obj = {
          attendance: value.Order.BaseService,
          os: [
            {
              sector: value.Order.Sector.name,
              setting_service: value.SettingService.name,
              order: value.Order.id,
              os: value.id,
              active: value.active,
              name_user: value.User.name,
              num_attendance_erp: value.Order.BaseService.num_attendance_erp,
              fields: value.FieldValues,
              fields_setting_service: value.SettingService.SettingServiceFields,
              record_movements: value.RecordMovementOs,
            },
          ],
        };

        newArray.push(obj);
      } else {
        let insert = newArray.filter(
          (el) => el.attendance.id === value.Order.BaseService.id
        );

        // Caso o atendimento não exista ele insere um novo
        if (insert.length === 0) {
          let obj = {
            attendance: value.Order.BaseService,
            os: [
              {
                sector: value.Order.Sector.name,
                setting_service: value.SettingService.name,
                order: value.Order.id,
                os: value.id,
                active: value.active,
                name_user: value.User.name,
                num_attendance_erp: value.Order.BaseService.num_attendance_erp,
                fields: value.FieldValues,
                fields_setting_service: value.SettingService.SettingServiceFields,
                record_movements: value.RecordMovementOs,
              },
            ],
          };

          newArray.push(obj);

          // Caso o atendimento exista ele altera o atendimento existente
        } else {
          let change_obj = {
            ...insert[0],
          };

          change_obj.os.push({
            sector: value.Order.Sector.name,
            setting_service: value.SettingService.name,
            order: value.Order.id,
            os: value.id,
            active: value.active,
            name_user: value.User.name,
            num_attendance_erp: value.Order.BaseService.num_attendance_erp,
            fields: value.FieldValues,
            fields_setting_service: value.SettingService.SettingServiceFields,
            record_movements: value.RecordMovementOs,
          });

          newArray = [
            ...newArray.filter(
              (el) => el.attendance.id !== value.Order.BaseService.id
            ),
          ];

          newArray.push(change_obj);
        }
      }
    })
  )
    .then(() => newArray)
    .catch(() => newArray);
};

// Gera os dados dos atendimentos para o CSV
export const generateDataAttendanceCSV = async (data, signin) => {
  let dataCSVFile = [];
  let headersCSVFile = [];

  // Ordena por atendimento em ordem crescente
  data = data.sort(
    (a, b) => a.attendance.num_attendance_erp - b.attendance.num_attendance_erp
  );

  // Insere os cabeçalhos do atendimento funerário
  // ATENDIMENTO
  let headerAttendance = {
    label: "Nº do Atendimento",
    key: "num_attendance_erp",
  };
  headersCSVFile.push(headerAttendance);

  let headerTypeAttendance = {
    label: "Tipo do Atendimento",
    key: "desc_type_attendance",
  };
  headersCSVFile.push(headerTypeAttendance);

  let headerCreatedAtService = {
    label: "Data de Criação do Atendimento",
    key: "created_at_attendance",
  };
  headersCSVFile.push(headerCreatedAtService);

  let headerAttendanceStatus = {
    label: "Situação do Atendimento",
    key: "is_blocked_attendance",
  };
  headersCSVFile.push(headerAttendanceStatus);

  let headerAttendanceUnlockdeDate = {
    label: "Data de Liberação do Atendimento",
    key: "date_register_liberation",
  };
  headersCSVFile.push(headerAttendanceUnlockdeDate);

  // FALECIDO
  let headerNameDeceased = {
    label: "Nome do Falecido",
    key: "name_deceased",
  };
  headersCSVFile.push(headerNameDeceased);

  let headerBirthdayDeceased = {
    label: "Data de Nascimento do Falecido",
    key: "birthday_deceased",
  };
  headersCSVFile.push(headerBirthdayDeceased);

  let headerReligionDeceased = {
    label: "Religião do Falecido",
    key: "religion_deceased",
  };
  headersCSVFile.push(headerReligionDeceased);

  let headerDeathDateDeceased = {
    label: "Data do Óbito do Falecido",
    key: "death_date_deceased",
  };
  headersCSVFile.push(headerDeathDateDeceased);

  let headerDeathHourDeceased = {
    label: "Hora do Óbito do Falecido",
    key: "death_hour_deceased",
  };
  headersCSVFile.push(headerDeathHourDeceased);

  let headerDeathCauseDeceased = {
    label: "Causa da Morte do Falecido",
    key: "death_cause_deceased",
  };
  headersCSVFile.push(headerDeathCauseDeceased);

  let headerWeightDeceased = {
    label: "Peso do Falecido",
    key: "weight_deceased",
  };
  headersCSVFile.push(headerWeightDeceased);

  let headerHeightDeceased = {
    label: "Altura do Falecido",
    key: "height_deceased",
  };
  headersCSVFile.push(headerHeightDeceased);

  let headerZipCodeDeath = {
    label: "CEP do Óbito",
    key: "zip_code_death",
  };
  headersCSVFile.push(headerZipCodeDeath);

  let headerLocationDeath = {
    label: "Local do Óbito",
    key: "location_death",
  };
  headersCSVFile.push(headerLocationDeath);

  let headerAddressDeath = {
    label: "Logradouro do Óbito",
    key: "address_death",
  };
  headersCSVFile.push(headerAddressDeath);

  let headerAddressNumberDeath = {
    label: "Número do Logradouro Óbito",
    key: "address_number_death",
  };
  headersCSVFile.push(headerAddressNumberDeath);

  let headerNeighborhoodDeath = {
    label: "Bairro do Óbito",
    key: "neighborhood_death",
  };
  headersCSVFile.push(headerNeighborhoodDeath);

  let headerCityDeath = {
    label: "Cidade do Óbito",
    key: "city_death",
  };
  headersCSVFile.push(headerCityDeath);

  let headerStateDeath = {
    label: "UF do Óbito",
    key: "state_death",
  };
  headersCSVFile.push(headerStateDeath);

  // DECLARANTE
  let headerNameClient = {
    label: "Nome do Declarante",
    key: "name_client",
  };
  headersCSVFile.push(headerNameClient);

  let headerCelphoneNumberClient = {
    label: "Celular do Declarante",
    key: "cellphone_client",
  };
  headersCSVFile.push(headerCelphoneNumberClient);

  let headerPhoneNumberClient = {
    label: "Telefone do Declarante",
    key: "phone_client",
  };
  headersCSVFile.push(headerPhoneNumberClient);

  let headerMailClient = {
    label: "E-mail do Declarante",
    key: "mail_client",
  };
  headersCSVFile.push(headerMailClient);

  // REMOÇÃO PRIMÁRIA
  let headerZipCodeOriginRemoval = {
    label: "CEP da Origem da Remoção",
    key: "zip_code_origin_removal",
  };
  headersCSVFile.push(headerZipCodeOriginRemoval);

  let headerLocationOriginRemoval = {
    label: "Local da Origem da Remoção",
    key: "location_origin_removal",
  };
  headersCSVFile.push(headerLocationOriginRemoval);

  let headerAddressOriginRemoval = {
    label: "Logradouro da Origem da Remoção",
    key: "address_origin_removal",
  };
  headersCSVFile.push(headerAddressOriginRemoval);

  let headerAddressNumberOriginRemoval = {
    label: "Número do Logradouro da Origem da Remoção",
    key: "address_number_origin_removal",
  };
  headersCSVFile.push(headerAddressNumberOriginRemoval);

  let headerNeighborhoodOriginRemoval = {
    label: "Bairro da Origem da Remoção",
    key: "neighborhood_origin_removal",
  };
  headersCSVFile.push(headerNeighborhoodOriginRemoval);

  let headerCityOriginRemoval = {
    label: "Cidade da Origem da Remoção",
    key: "city_origin_removal",
  };
  headersCSVFile.push(headerCityOriginRemoval);

  let headerStateOriginRemoval = {
    label: "UF da Origem da Remoção",
    key: "state_origin_removal",
  };
  headersCSVFile.push(headerStateOriginRemoval);

  let headerZipCodeDestinationRemoval = {
    label: "CEP do Destino da Remoção",
    key: "zip_code_destination_removal",
  };
  headersCSVFile.push(headerZipCodeDestinationRemoval);

  let headerLocationDestinationRemoval = {
    label: "Local do Destino da Remoção",
    key: "location_destination_removal",
  };
  headersCSVFile.push(headerLocationDestinationRemoval);

  let headerAddressDestinationRemoval = {
    label: "Logradouro do Destino da Remoção",
    key: "address_destination_removal",
  };
  headersCSVFile.push(headerAddressDestinationRemoval);

  let headerAddressNumberDestinationRemoval = {
    label: "Número do Logradouro do Destino da Remoção",
    key: "address_number_destination_removal",
  };
  headersCSVFile.push(headerAddressNumberDestinationRemoval);

  let headerNeighborhoodDestinationRemoval = {
    label: "Bairro do Destino da Remoção",
    key: "neighborhood_destination_removal",
  };
  headersCSVFile.push(headerNeighborhoodDestinationRemoval);

  let headerCityDestinationRemoval = {
    label: "Cidade do Destino da Remoção",
    key: "city_destination_removal",
  };
  headersCSVFile.push(headerCityDestinationRemoval);

  let headerStateDestinationRemoval = {
    label: "UF do Destino da Remoção",
    key: "state_destination_removal",
  };
  headersCSVFile.push(headerStateDestinationRemoval);

  let headerDateFamilySchedule = {
    label: "Data do Agendamento com a Família",
    key: "date_family_schedule",
  };
  headersCSVFile.push(headerDateFamilySchedule);

  let headerHourFamilySchedule = {
    label: "Hora do Agendamento com a Família",
    key: "hour_family_schedule",
  };
  headersCSVFile.push(headerHourFamilySchedule);

  // VELÓRIO
  let headerFuneral1StartDate = {
    label: "Data de Início do Velório 1",
    key: "funeral1_start_date",
  };
  headersCSVFile.push(headerFuneral1StartDate);

  let headerFuneral1StartHour = {
    label: "Hora de Início do Velório 1",
    key: "funeral1_start_hour",
  };
  headersCSVFile.push(headerFuneral1StartHour);

  let headerFuneral1EndDate = {
    label: "Data de Fim do Velório 1",
    key: "funeral1_end_date",
  };
  headersCSVFile.push(headerFuneral1EndDate);

  let headerFuneral1EndHour = {
    label: "Hora de Fim do Velório 1",
    key: "funeral1_end_hour",
  };
  headersCSVFile.push(headerFuneral1EndHour);

  let headerFuneral1Location = {
    label: "Local do Velório 1",
    key: "funeral1_location",
  };
  headersCSVFile.push(headerFuneral1Location);

  let headerFuneral1Chapel = {
    label: "Capela do Velório 1",
    key: "funeral1_chapel",
  };
  headersCSVFile.push(headerFuneral1Chapel);

  let headerFuneral1ZipCode = {
    label: "CEP do Velório 1",
    key: "funeral1_zip_code",
  };
  headersCSVFile.push(headerFuneral1ZipCode);

  let headerFuneral1Address = {
    label: "Logradouro do Velório 1",
    key: "funeral1_address",
  };
  headersCSVFile.push(headerFuneral1Address);

  let headerFuneral1AddressNumber = {
    label: "Número do Logradouro do Velório 1",
    key: "funeral1_address_number",
  };
  headersCSVFile.push(headerFuneral1AddressNumber);

  let headerFuneral1Neighborhood = {
    label: "Bairro do Velório 1",
    key: "funeral1_neighborhood",
  };
  headersCSVFile.push(headerFuneral1Neighborhood);

  let headerFuneral1City = {
    label: "Cidade do Velório 1",
    key: "funeral1_city",
  };
  headersCSVFile.push(headerFuneral1City);

  let headerFuneral1State = {
    label: "UF do Velório 1",
    key: "funeral1_state",
  };
  headersCSVFile.push(headerFuneral1State);

  let headerCeremonyDate = {
    label: "Data da Cerimônia",
    key: "ceremony_date",
  };
  headersCSVFile.push(headerCeremonyDate);

  let headerCeremonyHour = {
    label: "Hora da Cerimônia",
    key: "ceremony_hour",
  };
  headersCSVFile.push(headerCeremonyHour);

  let headerFuneral2StartDate = {
    label: "Data de Início do Velório 2",
    key: "funeral2_start_date",
  };
  headersCSVFile.push(headerFuneral2StartDate);

  let headerFuneral2StartHour = {
    label: "Hora de Início do Velório 2",
    key: "funeral2_start_hour",
  };
  headersCSVFile.push(headerFuneral2StartHour);

  let headerFuneral2EndDate = {
    label: "Data de Fim do Velório 2",
    key: "funeral2_end_date",
  };
  headersCSVFile.push(headerFuneral2EndDate);

  let headerFuneral2EndHour = {
    label: "Hora de Fim do Velório 2",
    key: "funeral2_end_hour",
  };
  headersCSVFile.push(headerFuneral2EndHour);

  let headerFuneral2Location = {
    label: "Local do Velório 2",
    key: "funeral2_location",
  };
  headersCSVFile.push(headerFuneral2Location);

  let headerFuneral2Chapel = {
    label: "Capela do Velório 2",
    key: "funeral2_chapel",
  };
  headersCSVFile.push(headerFuneral2Chapel);

  let headerFuneral2ZipCode = {
    label: "CEP do Velório 2",
    key: "funeral2_zip_code",
  };
  headersCSVFile.push(headerFuneral2ZipCode);

  let headerFuneral2Address = {
    label: "Logradouro do Velório 2",
    key: "funeral2_address",
  };
  headersCSVFile.push(headerFuneral2Address);

  let headerFuneral2AddressNumber = {
    label: "Número do Logradouro do Velório 2",
    key: "funeral2_address_number",
  };
  headersCSVFile.push(headerFuneral2AddressNumber);

  let headerFuneral2Neighborhood = {
    label: "Bairro do Velório 2",
    key: "funeral2_neighborhood",
  };
  headersCSVFile.push(headerFuneral2Neighborhood);

  let headerFuneral2City = {
    label: "Cidade do Velório 2",
    key: "funeral2_city",
  };
  headersCSVFile.push(headerFuneral2City);

  let headerFuneral2State = {
    label: "UF do Velório 2",
    key: "funeral2_state",
  };
  headersCSVFile.push(headerFuneral2State);

  // CORTEJO
  let headerCortegeDate = {
    label: "Data do Cortejo",
    key: "cortege_date",
  };
  headersCSVFile.push(headerCortegeDate);

  let headerCortegeHour = {
    label: "Hora do Cortejo",
    key: "cortege_hour",
  };
  headersCSVFile.push(headerCortegeHour);

  let headerZipCodeOriginCortege = {
    label: "CEP da Origem do Cortejo",
    key: "zip_code_origin_cortege",
  };
  headersCSVFile.push(headerZipCodeOriginCortege);

  let headerLocationOriginCortege = {
    label: "Local da Origem do Cortejo",
    key: "location_origin_cortege",
  };
  headersCSVFile.push(headerLocationOriginCortege);

  let headerAddressOriginCortege = {
    label: "Logradouro da Origem do Cortejo",
    key: "address_origin_cortege",
  };
  headersCSVFile.push(headerAddressOriginCortege);

  let headerAddressNumberOriginCortege = {
    label: "Número do Logradouro da Origem do Cortejo",
    key: "address_number_origin_cortege",
  };
  headersCSVFile.push(headerAddressNumberOriginCortege);

  let headerNeighborhoodOriginCortege = {
    label: "Bairro da Origem do Cortejo",
    key: "neighborhood_origin_cortege",
  };
  headersCSVFile.push(headerNeighborhoodOriginCortege);

  let headerCityOriginCortege = {
    label: "Cidade da Origem do Cortejo",
    key: "city_origin_cortege",
  };
  headersCSVFile.push(headerCityOriginCortege);

  let headerStateOriginCortege = {
    label: "UF da Origem do Cortejo",
    key: "state_origin_cortege",
  };
  headersCSVFile.push(headerStateOriginCortege);

  let headerZipCodeDestinationCortege = {
    label: "CEP do Destino do Cortejo",
    key: "zip_code_destination_cortege",
  };
  headersCSVFile.push(headerZipCodeDestinationCortege);

  let headerLocationDestinationCortege = {
    label: "Local do Destino do Cortejo",
    key: "location_destination_cortege",
  };
  headersCSVFile.push(headerLocationDestinationCortege);

  let headerAddressDestinationCortege = {
    label: "Logradouro do Destino do Cortejo",
    key: "address_destination_cortege",
  };
  headersCSVFile.push(headerAddressDestinationCortege);

  let headerAddressNumberDestinationCortege = {
    label: "Número do Logradouro do Destino do Cortejo",
    key: "address_number_destination_cortege",
  };
  headersCSVFile.push(headerAddressNumberDestinationCortege);

  let headerNeighborhoodDestinationCortege = {
    label: "Bairro do Destino do Cortejo",
    key: "neighborhood_destination_cortege",
  };
  headersCSVFile.push(headerNeighborhoodDestinationCortege);

  let headerCityDestinationCortege = {
    label: "Cidade do Destino do Cortejo",
    key: "city_destination_cortege",
  };
  headersCSVFile.push(headerCityDestinationCortege);

  let headerStateDestinationCortege = {
    label: "UF do Destino do Cortejo",
    key: "state_destination_cortege",
  };
  headersCSVFile.push(headerStateDestinationCortege);

  // SEPULTAMENTO
  let headerBurialDate = {
    label: "Data do Sepultamento",
    key: "burial_date",
  };
  headersCSVFile.push(headerBurialDate);

  let headerBurialHour = {
    label: "Hora do Sepultamento",
    key: "burial_hour",
  };
  headersCSVFile.push(headerBurialHour);

  let headerBurialPlace = {
    label: "Local do Sepultamento",
    key: "burial_place",
  };
  headersCSVFile.push(headerBurialPlace);

  // TRASLADO
  let headerTransferDate = {
    label: "Data do Traslado",
    key: "transfer_date",
  };
  headersCSVFile.push(headerTransferDate);

  let headerTransferHour = {
    label: "Hora do Traslado",
    key: "transfer_hour",
  };
  headersCSVFile.push(headerTransferHour);

  let headerZipCodeOriginTransfer = {
    label: "CEP da Origem do Traslado",
    key: "zip_code_origin_transfer",
  };
  headersCSVFile.push(headerZipCodeOriginTransfer);

  let headerLocationOriginTransfer = {
    label: "Local da Origem do Traslado",
    key: "location_origin_transfer",
  };
  headersCSVFile.push(headerLocationOriginTransfer);

  let headerAddressOriginTransfer = {
    label: "Logradouro da Origem do Traslado",
    key: "address_origin_transfer",
  };
  headersCSVFile.push(headerAddressOriginTransfer);

  let headerAddressNumberOriginTransfer = {
    label: "Número do Logradouro da Origem do Traslado",
    key: "address_number_origin_transfer",
  };
  headersCSVFile.push(headerAddressNumberOriginTransfer);

  let headerNeighborhoodOriginTransfer = {
    label: "Bairro da Origem do Traslado",
    key: "neighborhood_origin_transfer",
  };
  headersCSVFile.push(headerNeighborhoodOriginTransfer);

  let headerCityOriginTransfer = {
    label: "Cidade da Origem do Traslado",
    key: "city_origin_transfer",
  };
  headersCSVFile.push(headerCityOriginTransfer);

  let headerStateOriginTransfer = {
    label: "UF da Origem do Traslado",
    key: "state_origin_transfer",
  };
  headersCSVFile.push(headerStateOriginTransfer);

  let headerZipCodeDestinationTransfer = {
    label: "CEP do Destino do Traslado",
    key: "zip_code_destination_transfer",
  };
  headersCSVFile.push(headerZipCodeDestinationTransfer);

  let headerLocationDestinationTransfer = {
    label: "Local do Destino do Traslado",
    key: "location_destination_transfer",
  };
  headersCSVFile.push(headerLocationDestinationTransfer);

  let headerAddressDestinationTransfer = {
    label: "Logradouro do Destino do Traslado",
    key: "address_destination_transfer",
  };
  headersCSVFile.push(headerAddressDestinationTransfer);

  let headerAddressNumberDestinationTransfer = {
    label: "Número do Logradouro do Destino do Traslado",
    key: "address_number_destination_transfer",
  };
  headersCSVFile.push(headerAddressNumberDestinationTransfer);

  let headerNeighborhoodDestinationTransfer = {
    label: "Bairro do Destino do Traslado",
    key: "neighborhood_destination_transfer",
  };
  headersCSVFile.push(headerNeighborhoodDestinationTransfer);

  let headerCityDestinationTransfer = {
    label: "Cidade do Destino do Traslado",
    key: "city_destination_transfer",
  };
  headersCSVFile.push(headerCityDestinationTransfer);

  let headerStateDestinationTransfer = {
    label: "UF do Destino do Traslado",
    key: "state_destination_transfer",
  };
  headersCSVFile.push(headerStateDestinationTransfer);

  // CREMAÇÃO
  let headerCremationStartDate = {
    label: "Data de Início da Cremação",
    key: "cremation_start_date",
  };
  headersCSVFile.push(headerCremationStartDate);

  let headerCremationStartHour = {
    label: "Hora de Início da Cremação",
    key: "cremation_start_hour",
  };
  headersCSVFile.push(headerCremationStartHour);

  let headerCremationEndDate = {
    label: "Data de Fim da Cremação",
    key: "cremation_end_date",
  };
  headersCSVFile.push(headerCremationEndDate);

  let headerCremationEndHour = {
    label: "Hora de Fim da Cremação",
    key: "cremation_end_hour",
  };
  headersCSVFile.push(headerCremationEndHour);

  let headerCremationCeremonyDate = {
    label: "Data da Cerimônia de Cremação",
    key: "cremation_ceremony_date",
  };
  headersCSVFile.push(headerCremationCeremonyDate);

  let headerCremationCeremonyHour = {
    label: "Hora da Cerimônia de Cremação",
    key: "cremation_ceremony_hour",
  };
  headersCSVFile.push(headerCremationCeremonyHour);

  let headerCremationCeremonialist = {
    label: "Cerimonialista da Cremação",
    key: "cremation_ceremonialist",
  };
  headersCSVFile.push(headerCremationCeremonialist);

  let headerZipCodeCremation = {
    label: "CEP da Cremação",
    key: "zip_code_cremation",
  };
  headersCSVFile.push(headerZipCodeCremation);

  let headerLocationCremation = {
    label: "Local da Cremação",
    key: "location_cremation",
  };
  headersCSVFile.push(headerLocationCremation);

  let headerAddressCremation = {
    label: "Logradouro da Cremação",
    key: "address_cremation",
  };
  headersCSVFile.push(headerAddressCremation);

  let headerAddressNumberCremation = {
    label: "Número do Logradouro da Cremação",
    key: "address_number_cremation",
  };
  headersCSVFile.push(headerAddressNumberCremation);

  let headerNeighborhoodCremation = {
    label: "Bairro da Cremação",
    key: "neighborhood_cremation",
  };
  headersCSVFile.push(headerNeighborhoodCremation);

  let headerCityCremation = {
    label: "Cidade da Cremação",
    key: "city_cremation",
  };
  headersCSVFile.push(headerCityCremation);

  let headerStateCremation = {
    label: "UF da Cremação",
    key: "state_cremation",
  };
  headersCSVFile.push(headerStateCremation);

  let headerCremationResponsible = {
    label: "Responsável pelas Cinzas",
    key: "cremation_responsible",
  };
  headersCSVFile.push(headerCremationResponsible);

  let headerCremationRGResponsible = {
    label: "RG do Responsável pelas Cinzas",
    key: "cremation_rg_responsible",
  };
  headersCSVFile.push(headerCremationRGResponsible);

  let headerCremationCPFResponsible = {
    label: "CPF do Responsável pelas Cinzas",
    key: "cremation_cpf_responsible",
  };
  headersCSVFile.push(headerCremationCPFResponsible);

  let headerCremationDateAshes = {
    label: "Data de Entrega das Cinzas",
    key: "cremation_date_ashes",
  };
  headersCSVFile.push(headerCremationDateAshes);

  let headerCremationHourAshes = {
    label: "Hora de Entrega das Cinzas",
    key: "cremation_hour_ashes",
  };
  headersCSVFile.push(headerCremationHourAshes);

  // OUTROS
  let headerAttendanceObs = {
    label: "Observações do Atendimento",
    key: "attendance_obs",
  };
  headersCSVFile.push(headerAttendanceObs);

  // Empresa e filial para buscar atendimentos no protheus
  const { code_erp: subsidiary_erp, Hierarquia } = signin.user.userCompanies.find(
    (company) => company.id === signin.user.currentCompany
  );

  // Insere os dados dos atendimentos funerários
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      let e = data[key];
      let dataCSV = {};
      
      try {
        // Busca detalhes do atendimento funerário no Protheus
        let dataAttendanceProtheus = await appointmentsApiProtheus
        .getDetailsAttendanceProtheus(
          e.attendance.num_attendance_erp,
          {
            headers: {
              tenantId: `${Hierarquia.code_erp},${subsidiary_erp}`,
            },
          }
        );

        // Insere os dados no CSV
        // ATENDIMENTO
        dataCSV[headerAttendance.key] = dataAttendanceProtheus.ATENDIMENTO.numero.trim();
        dataCSV[headerTypeAttendance.key] = dataAttendanceProtheus.ATENDIMENTO.tipo.trim();

        dataCSV[headerCreatedAtService.key] = moment(
          e.attendance.createdAt
        ).format("DD/MM/YYYY HH:mm");

        if (e.attendance.is_blocked_attendance === "L") {
          dataCSV[headerAttendanceStatus.key] = "Liberado";
        } else if (e.attendance.is_blocked_attendance === "A") {
          dataCSV[headerAttendanceStatus.key] = "Agendado";
        } else {
          dataCSV[headerAttendanceStatus.key] = "Pendente";
        }

        if (e.attendance.unlocked_date) {
          dataCSV[headerAttendanceUnlockdeDate.key] = moment(
            e.attendance.unlocked_date
          ).format("DD/MM/YYYY HH:mm");
        }

        // FALECIDO
        dataCSV[headerNameDeceased.key] = dataAttendanceProtheus.FALECIDO.nome.trim();
        dataCSV[headerBirthdayDeceased.key] = dataAttendanceProtheus.FALECIDO.data_nascimento.trim();
        dataCSV[headerReligionDeceased.key] = dataAttendanceProtheus.FALECIDO.religiao.trim();
        dataCSV[headerDeathDateDeceased.key] = dataAttendanceProtheus.FALECIDO.data_obito.trim();
        dataCSV[headerDeathHourDeceased.key] = dataAttendanceProtheus.FALECIDO.hora_obito.trim();
        dataCSV[headerDeathCauseDeceased.key] = dataAttendanceProtheus.FALECIDO.causa_morte.trim();
        dataCSV[headerWeightDeceased.key] = dataAttendanceProtheus.FALECIDO.peso.trim();
        dataCSV[headerHeightDeceased.key] = dataAttendanceProtheus.FALECIDO.altura.trim();

        dataCSV[headerZipCodeDeath.key] = dataAttendanceProtheus.FALECIDO.cep_obito.trim();
        dataCSV[headerLocationDeath.key] = dataAttendanceProtheus.FALECIDO.local_obito.trim();
        dataCSV[headerAddressDeath.key] = dataAttendanceProtheus.FALECIDO.endereco_obito.trim();
        dataCSV[headerAddressNumberDeath.key] = dataAttendanceProtheus.FALECIDO.numero_end_obito.trim();
        dataCSV[headerNeighborhoodDeath.key] = dataAttendanceProtheus.FALECIDO.bairro_obito.trim();
        dataCSV[headerCityDeath.key] = dataAttendanceProtheus.FALECIDO.cidade_obito.trim();
        dataCSV[headerStateDeath.key] = dataAttendanceProtheus.FALECIDO.estado_obito.trim();

        // DECLARANTE
        dataCSV[headerNameClient.key] = dataAttendanceProtheus.DECLARANTE.nome.trim();
        dataCSV[headerCelphoneNumberClient.key] = dataAttendanceProtheus.DECLARANTE.telcel.trim();
        dataCSV[headerPhoneNumberClient.key] = dataAttendanceProtheus.DECLARANTE.telfixo.trim();
        dataCSV[headerMailClient.key] = dataAttendanceProtheus.DECLARANTE.email.trim();
        
        // REMOÇÃO PRIMÁRIA
        dataCSV[headerZipCodeOriginRemoval.key] = dataAttendanceProtheus.REMOCAO.cep_origem.trim();
        dataCSV[headerLocationOriginRemoval.key] = dataAttendanceProtheus.REMOCAO.local_origem.trim();
        dataCSV[headerAddressOriginRemoval.key] = dataAttendanceProtheus.REMOCAO.endereco_origem.trim();
        dataCSV[headerAddressNumberOriginRemoval.key] = dataAttendanceProtheus.REMOCAO.numero_end_origem.trim();
        dataCSV[headerNeighborhoodOriginRemoval.key] = dataAttendanceProtheus.REMOCAO.bairro_origem.trim();
        dataCSV[headerCityOriginRemoval.key] = dataAttendanceProtheus.REMOCAO.cidade_origem.trim();
        dataCSV[headerStateOriginRemoval.key] = dataAttendanceProtheus.REMOCAO.estado_origem.trim();

        dataCSV[headerZipCodeDestinationRemoval.key] = dataAttendanceProtheus.REMOCAO.cep_destino.trim();
        dataCSV[headerLocationDestinationRemoval.key] = dataAttendanceProtheus.REMOCAO.local_destino.trim();
        dataCSV[headerAddressDestinationRemoval.key] = dataAttendanceProtheus.REMOCAO.endereco_destino.trim();
        dataCSV[headerAddressNumberDestinationRemoval.key] = dataAttendanceProtheus.REMOCAO.numero_end_destino.trim();
        dataCSV[headerNeighborhoodDestinationRemoval.key] = dataAttendanceProtheus.REMOCAO.bairro_destino.trim();
        dataCSV[headerCityDestinationRemoval.key] = dataAttendanceProtheus.REMOCAO.cidade_destino.trim();
        dataCSV[headerStateDestinationRemoval.key] = dataAttendanceProtheus.REMOCAO.estado_destino.trim();

        dataCSV[headerDateFamilySchedule.key] = dataAttendanceProtheus.REMOCAO.data_encontro_familia.trim();
        dataCSV[headerHourFamilySchedule.key] = dataAttendanceProtheus.REMOCAO.hora_encontro_familia.trim();
        
        // VELÓRIO
        dataCSV[headerFuneral1StartDate.key] = dataAttendanceProtheus.VELORIO.data_inicio.trim();
        dataCSV[headerFuneral1StartHour.key] = dataAttendanceProtheus.VELORIO.hora_inicio.trim();
        dataCSV[headerFuneral1EndDate.key] = dataAttendanceProtheus.VELORIO.data_fim.trim();
        dataCSV[headerFuneral1EndHour.key] = dataAttendanceProtheus.VELORIO.hora_fim.trim();
        dataCSV[headerFuneral1Location.key] = dataAttendanceProtheus.VELORIO.local_velorio1.trim();
        dataCSV[headerFuneral1Chapel.key] = dataAttendanceProtheus.VELORIO.capela_velorio1.trim();
        dataCSV[headerFuneral1ZipCode.key] = dataAttendanceProtheus.VELORIO.cep_velorio1.trim();
        dataCSV[headerFuneral1Address.key] = dataAttendanceProtheus.VELORIO.endereco_velorio1.trim();
        dataCSV[headerFuneral1AddressNumber.key] = dataAttendanceProtheus.VELORIO.numero_end_velorio1.trim();
        dataCSV[headerFuneral1Neighborhood.key] = dataAttendanceProtheus.VELORIO.bairro_velorio1.trim();
        dataCSV[headerFuneral1City.key] = dataAttendanceProtheus.VELORIO.cidade_velorio1.trim();
        dataCSV[headerFuneral1State.key] = dataAttendanceProtheus.VELORIO.estado_velorio1.trim();

        dataCSV[headerCeremonyDate.key] = dataAttendanceProtheus.VELORIO.data_cerimonia.trim();
        dataCSV[headerCeremonyHour.key] = dataAttendanceProtheus.VELORIO.hora_cerimonia.trim();

        dataCSV[headerFuneral2StartDate.key] = dataAttendanceProtheus.VELORIO.data_inicio_velorio2.trim();
        dataCSV[headerFuneral2StartHour.key] = dataAttendanceProtheus.VELORIO.hora_inicio_velorio2.trim();
        dataCSV[headerFuneral2EndDate.key] = dataAttendanceProtheus.VELORIO.data_fim_velorio2.trim();
        dataCSV[headerFuneral2EndHour.key] = dataAttendanceProtheus.VELORIO.hora_fim_velorio2.trim();
        dataCSV[headerFuneral2Location.key] = dataAttendanceProtheus.VELORIO.local_velorio2.trim();
        dataCSV[headerFuneral2Chapel.key] = dataAttendanceProtheus.VELORIO.capela_velorio2.trim();
        dataCSV[headerFuneral2ZipCode.key] = dataAttendanceProtheus.VELORIO.cep_velorio2.trim();
        dataCSV[headerFuneral2Address.key] = dataAttendanceProtheus.VELORIO.endereco_velorio2.trim();
        dataCSV[headerFuneral2AddressNumber.key] = dataAttendanceProtheus.VELORIO.numero_end_velorio2.trim();
        dataCSV[headerFuneral2Neighborhood.key] = dataAttendanceProtheus.VELORIO.bairro_velorio2.trim();
        dataCSV[headerFuneral2City.key] = dataAttendanceProtheus.VELORIO.cidade_velorio2.trim();
        dataCSV[headerFuneral2State.key] = dataAttendanceProtheus.VELORIO.estado_velorio2.trim();

        // CORTEJO
        dataCSV[headerCortegeDate.key] = dataAttendanceProtheus.CORTEJO.data.trim();
        dataCSV[headerCortegeHour.key] = dataAttendanceProtheus.CORTEJO.hora.trim();

        dataCSV[headerZipCodeOriginCortege.key] = dataAttendanceProtheus.CORTEJO.cep_origem.trim();
        dataCSV[headerLocationOriginCortege.key] = dataAttendanceProtheus.CORTEJO.local_origem.trim();
        dataCSV[headerAddressOriginCortege.key] = dataAttendanceProtheus.CORTEJO.endereco_origem.trim();
        dataCSV[headerAddressNumberOriginCortege.key] = dataAttendanceProtheus.CORTEJO.numero_end_origem.trim();
        dataCSV[headerNeighborhoodOriginCortege.key] = dataAttendanceProtheus.CORTEJO.bairro_origem.trim();
        dataCSV[headerCityOriginCortege.key] = dataAttendanceProtheus.CORTEJO.cidade_origem.trim();
        dataCSV[headerStateOriginCortege.key] = dataAttendanceProtheus.CORTEJO.estado_origem.trim();

        dataCSV[headerZipCodeDestinationCortege.key] = dataAttendanceProtheus.CORTEJO.cep_destino.trim();
        dataCSV[headerLocationDestinationCortege.key] = dataAttendanceProtheus.CORTEJO.local_destino.trim();
        dataCSV[headerAddressDestinationCortege.key] = dataAttendanceProtheus.CORTEJO.endereco_destino.trim();
        dataCSV[headerAddressNumberDestinationCortege.key] = dataAttendanceProtheus.CORTEJO.numero_end_destino.trim();
        dataCSV[headerNeighborhoodDestinationCortege.key] = dataAttendanceProtheus.CORTEJO.bairro_destino.trim();
        dataCSV[headerCityDestinationCortege.key] = dataAttendanceProtheus.CORTEJO.cidade_destino.trim();
        dataCSV[headerStateDestinationCortege.key] = dataAttendanceProtheus.CORTEJO.estado_destino.trim();

        // SEPULTAMENTO
        dataCSV[headerBurialDate.key] = dataAttendanceProtheus.SEPULTAMENTO.data.trim();
        dataCSV[headerBurialHour.key] = dataAttendanceProtheus.SEPULTAMENTO.hora.trim();
        dataCSV[headerBurialPlace.key] = dataAttendanceProtheus.SEPULTAMENTO.local;

        // TRASLADO
        dataCSV[headerTransferDate.key] = dataAttendanceProtheus.TRANSLADO.data.trim();
        dataCSV[headerTransferHour.key] = dataAttendanceProtheus.TRANSLADO.hora.trim();

        dataCSV[headerZipCodeOriginTransfer.key] = dataAttendanceProtheus.TRANSLADO.cep_origem.trim();
        dataCSV[headerLocationOriginTransfer.key] = dataAttendanceProtheus.TRANSLADO.local_origem.trim();
        dataCSV[headerAddressOriginTransfer.key] = dataAttendanceProtheus.TRANSLADO.endereco_origem.trim();
        dataCSV[headerAddressNumberOriginTransfer.key] = dataAttendanceProtheus.TRANSLADO.numero_end_origem.trim();
        dataCSV[headerNeighborhoodOriginTransfer.key] = dataAttendanceProtheus.TRANSLADO.bairro_origem.trim();
        dataCSV[headerCityOriginTransfer.key] = dataAttendanceProtheus.TRANSLADO.cidade_origem.trim();
        dataCSV[headerStateOriginTransfer.key] = dataAttendanceProtheus.TRANSLADO.estado_origem.trim();

        dataCSV[headerZipCodeDestinationTransfer.key] = dataAttendanceProtheus.TRANSLADO.cep_destino.trim();
        dataCSV[headerLocationDestinationTransfer.key] = dataAttendanceProtheus.TRANSLADO.local_destino.trim();
        dataCSV[headerAddressDestinationTransfer.key] = dataAttendanceProtheus.TRANSLADO.endereco_destino.trim();
        dataCSV[headerAddressNumberDestinationTransfer.key] = dataAttendanceProtheus.TRANSLADO.numero_end_destino.trim();
        dataCSV[headerNeighborhoodDestinationTransfer.key] = dataAttendanceProtheus.TRANSLADO.bairro_destino.trim();
        dataCSV[headerCityDestinationTransfer.key] = dataAttendanceProtheus.TRANSLADO.cidade_destino.trim();
        dataCSV[headerStateDestinationTransfer.key] = dataAttendanceProtheus.TRANSLADO.estado_destino.trim();

        // CREMAÇÃO
        dataCSV[headerCremationStartDate.key] = dataAttendanceProtheus.CREMACAO.data_inicio.trim();
        dataCSV[headerCremationStartHour.key] = dataAttendanceProtheus.CREMACAO.hora_inicio.trim();
        dataCSV[headerCremationEndDate.key] = dataAttendanceProtheus.CREMACAO.data_fim.trim();
        dataCSV[headerCremationEndHour.key] = dataAttendanceProtheus.CREMACAO.hora_fim.trim();
        dataCSV[headerCremationCeremonyDate.key] = dataAttendanceProtheus.CREMACAO.data_cerimonia.trim();
        dataCSV[headerCremationCeremonyHour.key] = dataAttendanceProtheus.CREMACAO.hora_cerimonia.trim();
        dataCSV[headerCremationCeremonialist.key] = dataAttendanceProtheus.CREMACAO.cerimonialista.trim();

        dataCSV[headerZipCodeCremation.key] = dataAttendanceProtheus.CREMACAO.cep.trim();
        dataCSV[headerLocationCremation.key] = dataAttendanceProtheus.CREMACAO.local.trim();
        dataCSV[headerAddressCremation.key] = dataAttendanceProtheus.CREMACAO.endereco.trim();
        dataCSV[headerAddressNumberCremation.key] = dataAttendanceProtheus.CREMACAO.numero.trim();
        dataCSV[headerNeighborhoodCremation.key] = dataAttendanceProtheus.CREMACAO.bairro.trim();
        dataCSV[headerCityCremation.key] = dataAttendanceProtheus.CREMACAO.cidade.trim();
        dataCSV[headerStateCremation.key] = dataAttendanceProtheus.CREMACAO.estado.trim();

        dataCSV[headerCremationResponsible.key] = dataAttendanceProtheus.CREMACAO.nome_resp_cinzas.trim();
        dataCSV[headerCremationRGResponsible.key] = dataAttendanceProtheus.CREMACAO.rg_resp_cinzas.trim();
        dataCSV[headerCremationCPFResponsible.key] = dataAttendanceProtheus.CREMACAO.cpf_resp_cinzas.trim();
        dataCSV[headerCremationDateAshes.key] = dataAttendanceProtheus.CREMACAO.data_entrega_cinzas.trim();
        dataCSV[headerCremationHourAshes.key] = dataAttendanceProtheus.CREMACAO.hora_entrega_cinza.trim();

        // OUTROS
        dataCSV[headerAttendanceObs.key] = dataAttendanceProtheus.ATENDIMENTO.observacoes;

        dataCSVFile.push(dataCSV);
      } catch (error) {
        // Ação quando não conseguir buscar um atendimento no protheus
      }
    }
  }

  return {
    headers: headersCSVFile,
    data: dataCSVFile,
  };
};

// Gera os dados das OS's para o CSV
export const generateDataOsCSV = async (data) => {
  let dataCSVFile = [];
  let headersCSVFile = [];

  // Ordena por atendimento em ordem crescente
  data = data.sort(
    (a, b) => a.attendance.num_attendance_erp - b.attendance.num_attendance_erp
  );

  // Insere os cabeçalhos comuns de todas as OS's
  let headerAttendance = {
    label: "Atendimento",
    key: "attendance",
  };
  headersCSVFile.push(headerAttendance);

  let headerOs = {
    label: "OS",
    key: "os",
  };
  headersCSVFile.push(headerOs);

  let headerActive = {
    label: "OS Ativa?",
    key: "active",
  };
  headersCSVFile.push(headerActive);

  let headerUserName = {
    label: "Usuário Responsável",
    key: "name_user",
  };
  headersCSVFile.push(headerUserName);

  let headerSector = {
    label: "Setor",
    key: "sector",
  };
  headersCSVFile.push(headerSector);

  let headerService = {
    label: "Serviço Realizado",
    key: "service_os",
  };
  headersCSVFile.push(headerService);

  let headerOpenedAt = {
    label: "Aberta em",
    key: "opened_at",
  };
  headersCSVFile.push(headerOpenedAt);

  let headerStartedAt = {
    label: "Iniciada em",
    key: "started_at",
  };
  headersCSVFile.push(headerStartedAt);

  let headerFinishedAt = {
    label: "Finalizada em",
    key: "finished_at",
  };
  headersCSVFile.push(headerFinishedAt);

  let headerBookedAt = {
    label: "Agendada em",
    key: "booked_at",
  };
  headersCSVFile.push(headerBookedAt);

  let headerCanceledAt = {
    label: "Cancelada em",
    key: "canceled_at",
  };
  headersCSVFile.push(headerCanceledAt);

  const userGroups = [];
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      let e = data[key];

      // Percorre os dados das OS's de cada atendimento funerário
      for (const i in e.os) {
        if (Object.hasOwnProperty.call(e.os, i)) {
          let el = e.os[i];
          let dataCSV = {};

          // Insere os dados padrões de todas as OS's
          dataCSV[headerAttendance.key] = e.attendance.num_attendance_erp;
          dataCSV[headerOs.key] = el.os;
          dataCSV[headerActive.key] = el.active ? "SIM" : "NÃO";
          dataCSV[headerUserName.key] = el.name_user;
          dataCSV[headerSector.key] = el.sector;
          dataCSV[headerService.key] = el.setting_service;

          // Insere os dados das movimentações da OS
          if (el.record_movements.length !== 0) {
            for (const r in el.record_movements) {
              if (Object.hasOwnProperty.call(el.record_movements, r)) {
                let movement = el.record_movements[r];

                switch (movement.GeneralStatus.id) {
                  case 1:
                    dataCSV[headerOpenedAt.key] = moment(
                      movement.createdAt
                    ).format("DD/MM/YYYY HH:mm");
                    break;
                  case 2:
                    dataCSV[headerStartedAt.key] = moment(
                      movement.createdAt
                    ).format("DD/MM/YYYY HH:mm");
                    break;
                  case 3:
                    dataCSV[headerFinishedAt.key] = moment(
                      movement.createdAt
                    ).format("DD/MM/YYYY HH:mm");
                    break;
                  case 4:
                    dataCSV[headerCanceledAt.key] = moment(
                      movement.createdAt
                    ).format("DD/MM/YYYY HH:mm");
                    break;
                  case 5:
                    dataCSV[headerBookedAt.key] = moment(
                      movement.createdAt
                    ).format("DD/MM/YYYY HH:mm");
                    break;
                  default:
                    break;
                }
              }
            }
          }

          // Ordena os campos personalizados por sequência em ordem crescente
          el.fields_setting_service = el.fields_setting_service.sort(
            (a, b) => a.sequence - b.sequence
          );

          // Insere os headers dos campos da OS
          if (el.fields_setting_service.length !== 0) {
            for (const j in el.fields_setting_service) {
              if (Object.hasOwnProperty.call(el.fields_setting_service, j)) {
                let element = el.fields_setting_service[j];
                let headerCSV = {
                  label: `(${el.setting_service}) ${element.name}`,
                  key: `${element.tag_name}`,
                };

                // Vefifica se o campo é um grupo de usuários
                if (!isNaN(parseInt(element.tag_function))) {
                  // Se o grupo de usuários não estiver na lista, insere
                  if (!userGroups.find((e) => e.id === parseInt(element.tag_function))) {
                    const userGroup = await userServices.getUserGroup({
                      groupId: element.tag_function,
                    });
                    if (userGroup) userGroups.push(userGroup);
                  }
                }

                let insertHeaderField = headersCSVFile.filter(
                  (e) => e.key === headerCSV.key
                );
                if (insertHeaderField.length === 0)
                  headersCSVFile.push(headerCSV);
              }
            }
          }

          // Insere os dados dos campos da OS
          if (el.fields.length !== 0) {
            for (const j in el.fields) {
              if (Object.hasOwnProperty.call(el.fields, j)) {
                let element = el.fields[j];
                let headerCSV = {
                  label: `(${el.setting_service}) ${element.SettingServiceField.name}`,
                  key: `${element.SettingServiceField.tag_name}`,
                };

                let value = element.value;
                if (element.SettingServiceField.id_type === 3) {
                  value = moment(element.value).format("DD/MM/YYYY HH:mm");
                } else if (element.SettingServiceField.id_type === 6) {
                  // Verifica se o valor do campo é um ID de usuário
                  if (!isNaN(parseInt(element.value))) {
                    try {
                      const userGroup = userGroups.find(
                        (group) =>
                          group.id ===
                          parseInt(element.SettingServiceField.tag_function)
                      );
                      if (!userGroup)
                        throw new Error("Grupo de usuários não encontrado");
                      const user = userGroup.users.find(
                        (user) => user.id === parseInt(element.value)
                      );
                      if (!user) throw new Error("Usuário não encontrado");
                      value = user.name;
                    } catch {
                      value = element.value;
                    }
                  }
                }
                dataCSV[headerCSV.key] = value;
              }
            }
          }

          dataCSVFile.push(dataCSV);
        }
      }
    }
  }

  // Orderna por serviço
  dataCSVFile = dataCSVFile.sort((a, b) => {
    if (a.service_os < b.service_os) {
      return -1;
    }
    if (a.service_os > b.service_os) {
      return 1;
    }
    return 0;
  });

  return {
    headers: headersCSVFile,
    data: dataCSVFile,
  };
};