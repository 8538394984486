import { Comment, Tooltip } from "antd";
import moment from "moment";
import { useHover } from "../../utils/useHover";
import styles from "./styles.module.css";

const Title = ({ title }) => {
  return <span className={styles.title}>{title}</span>;
};

export const NotificationItem = ({ item, onMarkAsRead }) => {
  const [hoverRef, isHovered] = useHover();

  return (
    <div ref={hoverRef}>
      <Comment
        className={styles.notificationItem}
        author={
          <a target="__blanck" href={item.action}>
            <Title title={item.title} />
          </a>
        }
        datetime={
          <Tooltip
            title={moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
          >
            <span className={styles.dateTime}>
              {moment(item.created_at).fromNow()}
            </span>
          </Tooltip>
        }
        content={
          <a target="__blanck" href={item.action}>
            <p onClick={() => onMarkAsRead(item.id)} className={styles.content}>
              {item.body}
            </p>
          </a>
        }
      >
        {!item.reading_date && (
          <BadgeButton
            onClick={() => onMarkAsRead(item.id)}
            hovered={isHovered}
            checked
          />
        )}
      </Comment>
    </div>
  );
};

const BadgeButton = ({ hovered, onClick, checked }) => {
  return (
    <button
      title="Marcar como lido"
      onClick={onClick}
      className={styles.badgeButton}
      style={{
        borderStyle: hovered ? "solid" : "unset",
      }}
    >
      {checked && <span className={styles.innerButton}></span>}
    </button>
  );
};
