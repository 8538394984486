import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Dropdown,
  Modal,
  Menu,
  Collapse,
  Descriptions,
  message,
  Input,
  Divider
} from "antd";
import _ from "lodash";
import moment from "moment";

import {
  DownOutlined,
  AuditOutlined,
  LinkOutlined,
  CopyOutlined,
  DesktopOutlined,
} from '@ant-design/icons';

import api from '../../../../../../../../utils/api';
import StatusComponent from "../../../../../../../../components/StatusComponent";
import colorsStatusCielo from "../../../../../../../../utils/colorsStatusCielo";

const { Panel } = Collapse;

const { TextArea } = Input;

const Acoes = (props) => {
  const [openModalHistory, setOpenModalHistory] = useState(false);
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState({
    code: "",
    status: "",
    description: "",
    color: "",
    orders: []
  });

  useEffect(() => {
    getStatusPayment()
  }, [props.installment.id]);

  const getStatusPayment = async () => {
    setLoading(true);

    try {
      const result = await api.get(`/payment-link/verify-status-payment?id=${props.installment.id}`)

      setResult(result.data);

    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  }

  const handleCopyLink = () => {
    if (result.status === 'Pago') {
      message.info('O Link não encontra-se mais acessível pois já foi pago!')
    } else {
      if (!props.installment.paymentLink)
        return message.info("Oooops, O campo de link encontra-se em branco, entre em contato com o TI!");

      navigator.clipboard.writeText(
        props.installment.paymentLink
      );

      message.success("Copiado para área de transferência");
    }
  }

  const handleCopyIdCielo = () => {
    if (!props.installment.paymentId)
      return message.info("Oooops, O campo de ID Cielo encontra-se em branco, entre em contato com o TI!");

    navigator.clipboard.writeText(
      props.installment.paymentId
    );

    message.success("Copiado para área de transferência");
  }

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<LinkOutlined />} onClick={() => handleCopyLink()}>
        Copiar Link
      </Menu.Item>
      <Menu.Item key="2" icon={<CopyOutlined />} onClick={() => handleCopyIdCielo()}>
        Copiar Id Cielo
      </Menu.Item>
      <Menu.Item key="3" icon={<AuditOutlined />} onClick={() => setOpenModalHistory(true)}>
        Ver Mais
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <a onClick={() => getStatusPayment()}>
        <StatusComponent title={result.status} color={result.color} description={result.description} loading={loading} />
      </a>
      <Dropdown overlay={menu}>
        <a className="ant-dropdown-link" style={{ fontSize: 14 }}>
          Ações <DownOutlined />
        </a>
      </Dropdown>
      <Modal
        title="Ver Mais"
        centered
        visible={openModalHistory}
        onOk={() => setOpenModalHistory(false)}
        onCancel={() => setOpenModalHistory(false)}
        footer={false}
      >
        <Row >
          <Col xs={24}>
            <h3>Usuário de Inclusão:</h3>
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col xs={24}>
            <Input
              disabled
              value={props.installment.User ? props.installment.User.name : ''}
            />
          </Col>
        </Row>
        <Row >
          <Col xs={24}>
            <h3>Observação:</h3>
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col xs={24}>
            <TextArea
              rows={4}
              disabled
              value={props.installment.note}
            />
          </Col>
        </Row>
        <Divider orientation="center" />
        <Row>
          <Col xs={24}>
            <h3>Histórico:</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Collapse defaultActiveKey={['1']}>
              {
                _.orderBy(result.orders, ["createdDate"], ["desc"]).map(order => {

                  const statusResult = colorsStatusCielo[order.payment.status]

                  const HeaderPanel = () => {
                    return (
                      <Row justify="space-between">
                        <Col>
                          {moment(order.createdDate).format("DD/MM/YYYY h:mm:ss a")}
                        </Col>
                        <Col>
                          <StatusComponent title={statusResult.status} color={statusResult.color} description={statusResult.description} loading={false} />
                        </Col>
                      </Row>
                    );
                  }

                  return (
                    <Panel header={<HeaderPanel />} key={order.$id}>
                      <Descriptions
                        bordered
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                      >
                        <Descriptions.Item label="Data/Hora">{moment(order.createdDate).format("DD/MM/YYYY h:mm:ss a")}</Descriptions.Item>
                        <Descriptions.Item label="Valor Pago">{(order.payment.price / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Descriptions.Item>
                        <Descriptions.Item label="Qtd Parcelas">{order.payment.numberOfPayments}</Descriptions.Item>
                      </Descriptions>
                    </Panel>
                  );
                })
              }
            </Collapse>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default Acoes;