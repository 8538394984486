import "moment/locale/pt-br";
import moment from "moment";

// lista com os nomes dos números ordinais
const sequenceNumbers = {
  1: "primeiro",
  2: "segundo",
  3: "terceiro",
  4: "quarto",
  5: "quinto",
  6: "sexto",
  7: "sétimo",
  8: "oitavo",
  9: "nono",
  10: "décimo",
  11: "décimo primeiro",
  12: "décimo segundo",
  13: "décimo terceiro",
  14: "décimo quarto",
  15: "décimo quinto",
  16: "décimo sexto",
  17: "décimo sétimo",
  18: "décimo oitavo",
  19: "décimo nono",
  20: "vigésimo",
  21: "vigésimo primeiro",
  22: "vigésimo segundo",
  23: "vigésimo terceiro",
  24: "vigésimo quarto",
  25: "vigésimo quinto",
  26: "vigésimo sexto",
  27: "vigésimo sétimo",
  28: "vigésimo oitavo",
  29: "vigésimo nono",
  30: "trigésimo",
  31: "trigésimo primeiro",
};

const getNamePositionName = () => {
  try {
    return sequenceNumbers[
      Object.keys(sequenceNumbers).find(
        (value) => value === moment().format("DD")
      )
    ];
  } catch (e) {
    return "";
  }
};

export const getTagInCode = (tag, currentCompany) => {
  const tags = [
    {
      tag: "SISTEMA/empresa",
      value: currentCompany,
    },
    {
      tag: "SISTEMA/data",
      value: moment().format("DD/MM/YYYY"),
    },
    {
      tag: "SISTEMA/hora",
      value: moment().format("HH:mm"),
    },
    {
      tag: "SISTEMA/posicao",
      value: getNamePositionName(),
    },
    {
      tag: "SISTEMA/ano",
      value: moment().format("YYYY"),
    },
    {
      tag: "SISTEMA/mes",
      value: moment().format("MMMM"),
    },
  ];

  return tags.find((value) => value.tag === tag) || null;
};
