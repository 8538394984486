import React from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import "./styles.css";

import Main from "../../../../../../pages/Layout/Main";
import itensMenu from "../../../itens-menu";
import moment from "moment";
import { Button, Col, Layout, Row, Tag, Table, Empty } from "antd";
import {
  MdGrade,
  MdList,
  MdPlaylistAddCheck,
  MdCreate,
  MdKeyboardArrowLeft,
  MdAttachMoney,
} from "react-icons/md";
import MenuComponent from "../../../../../../components/MenuComponent";

const { Content } = Layout;

function ContractScreen(props) {
  const contract = useSelector((state) => state.unifiedQuery.contract);
  const history = useHistory();

  const breadcrumb = [
    {
      title: "Central de atendimento ao cliente",
      path: props.modulePath,
    },
    {
      title: "Consulta unificada",
      path: props.modulePath + "/unified/query",
    },
    {
      title: "Consulta de contrato",
      path: props.modulePath + "/unified/query/contract",
    },
  ];

  const buttonsOption = [
    {
      label: "Mensalidades",
      icon: <MdAttachMoney size={16} />,
    },
    {
      label: "Benefícios",
      icon: <MdGrade size={16} />,
    },
    {
      label: "Histórico",
      icon: <MdList size={16} />,
    },
    {
      label: "Gerar Autorização",
      icon: <MdPlaylistAddCheck size={16} />,
    },
    {
      label: "Registrar Ocorrência",
      icon: <MdCreate size={16} />,
    },
  ];

  const textsContract = [
    {
      textHeader: "N° do Contrato",
      textBody: contract.contract,
    },
    {
      textHeader: "Tipo de Contrato",
      textBody: contract.contract_type,
    },
    {
      textHeader: "Produto",
      textBody: contract.product,
    },
    {
      textHeader: "Empresa/Filial",
      textBody: contract.company,
    },
    {
      textHeader: "Data de Adesão",
      textBody: moment(contract.accession_date).format("DD/MM/YYYY"),
    },
    {
      textHeader: "Forma de Pagamento",
      textBody: contract.payment,
    },
    {
      textHeader: "Vendedor",
      textBody: contract.seller,
    },
  ];

  const textsContractClient = [
    {
      textHeader: "N° do Cliente",
      textBody: contract.client_number,
    },
    {
      textHeader: "Nome do Cliente",
      textBody: contract.client_name,
    },
    {
      textHeader: "CPF",
      textBody: contract.cpf,
    },
    {
      textHeader: "Data de Nascimento",
      textBody: moment(contract.birth_date).format("DD/MM/YYYY"),
    },
    {
      textHeader: "E-mail",
      textBody: contract.mail,
    },
    {
      textHeader: "Recebe Email?",
      textBody: contract.send_mail ? "Sim" : "Não",
    },
    {
      textHeader: "Telefone",
      textBody: contract.phone,
    },
    {
      textHeader: "Celular",
      textBody: contract.cell_phone,
    },
    {
      textHeader: "CEP",
      textBody: contract.cep,
    },
    {
      textHeader: "Endereço",
      textBody: contract.address,
    },
    {
      textHeader: "Bairro",
      textBody: contract.neighborhood,
    },
    {
      textHeader: "Municipio",
      textBody: contract.city,
    },
    {
      textHeader: "Estado",
      textBody: contract.state,
    },
  ];

  const columnsTable = [
    {
      title: "Ordem",
      dataIndex: "order",
    },
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "Relação",
      dataIndex: "relationship",
    },
    {
      title: "CPF",
      dataIndex: "cpf",
    },
    {
      title: "Sexo",
      dataIndex: "sex",
    },
    {
      title: "Data de Aniversário",
      dataIndex: "birth_date",
      render: (date) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "Data de Inclusão",
      dataIndex: "accession_date",
      render: (date) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "Data de Carência",
      dataIndex: "lack_date",
      render: (date) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "Em Carência",
      dataIndex: "lack",
    },
  ];

  const ComponentStatus = ({ value }) => {
    let color = "";
    let status = value ? value : "INDEFINIDO";

    if (value === "ATIVO") {
      color = "green";
    } else if (value === "ENCERRADO") {
      color = "red";
    } else if (value === "SUSPENSO") {
      color = "cyan";
    } else if (value === "AÇÃO ADICIONAL") {
      color = "orange";
    } else {
      color = "purple";
    }

    return <Tag color={color}>{status}</Tag>;
  };

  return (
    <Main
      moduleName={props.moduleName}
      collapsed={true}
      moduleMenu={
        <MenuComponent
          intensMenu={itensMenu}
          modulePath={props.modulePath}
          selectedMenu="3"
        />
      }
      breadcrumb={breadcrumb}
    >
      <Content>
        <Row justify="start" gutter={[8, 8]}>
          <Col>
            <Button
              icon={<MdKeyboardArrowLeft size={26} />}
              onClick={() => history.goBack()}
            />
          </Col>
          {buttonsOption.map((value) => (
            <Col>
              <Button type="primary">
                <Row align="middle">
                  {value.icon}&nbsp; {value.label}
                </Row>
              </Button>
            </Col>
          ))}
        </Row>

        <Row>
          <Col span={24}>
            <Row
              gutter={[16, 16]}
              align="middle"
              className="unified-query-contract-text-spacing-top"
            >
              <Col>
                <h1>DADOS DO CONTRATO</h1>
              </Col>
              <Col>
                <ComponentStatus value={contract.status} />
              </Col>
            </Row>

            <Row gutter={[32, 16]} align="middle">
              {textsContract.map((value) => (
                <Col>
                  <Row>
                    <span className="unified-query-contract-text-data">
                      {value.textHeader}
                    </span>
                  </Row>
                  <Row>{value.textBody}</Row>
                </Col>
              ))}
            </Row>

            <Row
              gutter={[16, 16]}
              align="middle"
              className="unified-query-contract-text-spacing-top"
            >
              <Col>
                <h1>DADOS DO TITULAR</h1>
              </Col>
            </Row>

            <Row gutter={[32, 16]} align="middle">
              {textsContractClient.map((value) => (
                <Col>
                  <Row>
                    <span className="unified-query-contract-text-data">
                      {value.textHeader}
                    </span>
                  </Row>
                  <Row>{value.textBody}</Row>
                </Col>
              ))}
            </Row>

            <Row
              gutter={[16, 16]}
              align="middle"
              className="unified-query-contract-text-spacing-top"
            >
              <Col>
                <h1>DEPENDENTES</h1>
              </Col>
            </Row>

            <Row gutter={[32, 16]} align="middle">
              <Col span={24}>
                {contract.dependents.length === 0 ? (
                  <Empty description="Esse contrato não possuí dependentes" />
                ) : (
                  <Table
                    columns={columnsTable}
                    dataSource={contract.dependents}
                    size="small"
                    pagination={false}
                    className="content-contract-unified-query"
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Main>
  );
}

export default ContractScreen;
