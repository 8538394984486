import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const SectorsService = {
  getSectors: async (limit = 10, offset = 0, active = true) => {
    return await getRequest(
      `/sectors?limit=${limit}&offset=${offset}&active=${active}`,
      {}
    );
  },
  create: async (data) => {
    return await postRequest(`/sector`, data);
  },
  update: async (data) => {
    return await putRequest(`/sector?id=${data.id}`, data);
  },
  delete: async (id) => {
    return await deleteRequest(`/sector?id=${id}`, {});
  },
};
