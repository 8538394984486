import React, { useEffect, useState } from "react";
import "./styles.css";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import {
  Input,
  Row,
  Col,
  InputNumber,
  Form,
  DatePicker,
  Divider,
  Skeleton,
  Radio,
  Checkbox,
  Select,
  Button,
  message,
} from "antd";

import "moment/locale/pt-br";
import locale from "antd/es/date-picker/locale/pt_BR";
import moment from "moment";

import { FrownOutlined } from "@ant-design/icons";

import { submission } from "../../services/submissionForm";
import { submissionQuestion } from "../../services/submissionQuestion";
import { submissionAnswer } from "../../services/submissionAnswer";

import { openDrawer } from "../../store/ducks/drawer";
import { responseFormComponent } from "../../store/ducks/formComponent";
import { addFormApi } from "../../services/forms";
import { getSectorId } from "../AppointmentsWithParams/functions";

const { TextArea } = Input;
const { Option } = Select;

// ordena os campos por sequencia
const sortData = (data) => {
  return data.sort((a, b) => {
    return parseInt(a.sequence) - parseInt(b.sequence);
  });
};

function FormComponent({ readonly, resetIsForm, titleForm }) {
  const signin = useSelector((state) => state.signin);
  const formComponentData = useSelector((state) => state.formComponent);
  const appointment = useSelector((state) => state.appointment.appointment);
  const drawer = useSelector((state) => state.drawer.open_drawer);

  const [loading, setLoading] = useState(false);
  const [statusSubmissionForm, setStatusSubmissionForm] = useState(1);
  const [checkSubmission, setCheckSubmission] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  let initialValuesForm = {};

  useEffect(() => {
    checkSubmissionAfter();
  }, []);

  useEffect(() => {
    if (drawer) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [drawer]);

  const checkSubmissionAfter = () => {
    if (
      formComponentData.formComponent &&
      formComponentData.formComponent.f &&
      formComponentData.formComponent.f.length !== 0 &&
      formComponentData.formComponent.f[0].status_id
    ) {
      setCheckSubmission(formComponentData.formComponent.f[0].status_id);
      setStatusSubmissionForm(formComponentData.formComponent.f[0].status_id);
    }
  };

  /**
   * Função que pega as respostas de todos os campos
   */
  const onFinish = async (values) => {
    setLoading(true);

    let sendSubmission;
    let sendQuestion;
    const data = {
      ...values,
    };

    // Salva o envio do formulário
    try {
      sendSubmission = await submission.create({
        id_user: signin.user.id,
        services_id: appointment.id,
        sector_id: getSectorId(window.location.pathname, signin?.user?.currentModule?.id_sector),
        form_id: formComponentData.formComponent.id,
        status_id: statusSubmissionForm,
      });
    } catch (e) {
      message.error("Oops... Ocorreu um erro ao enviar o formulário.");
      return false;
    }

    // Salva a pergunta do formulário e as respostas do formulário
    try {
      let id_question = 0;
      let title_question = "";

      Object.keys(data).map(async (key) => {
        if (key.includes("-id-question")) {
          id_question = data[key];
          title_question = key.replace("-id-question", "");
        } else {
          // Salva a pergunta do formulário
          const form = formComponentData.formComponent.Items.find((element) => element.title == key)
          sendQuestion = await submissionQuestion.create({
            id_submission: sendSubmission.id,
            title_question: key,
            sequence: form.sequence ? form.sequence : null,
            id_question: key === title_question ? id_question : 0,
          });

          // salvar as respostas do formulário
          if (typeof data[key] !== "undefined") {
            if (
              typeof data[key] === "object" &&
              data[key].hasOwnProperty("_d")
            ) {
              submissionAnswer.create({
                id_subm_quest: sendQuestion.id,
                form_submis_id: sendSubmission.id,
                answer: moment(data[key]._d).format("YYYY-MM-DD HH:mm:ss"),
              });
            } else if (typeof data[key] === "string") {
              if (data[key].includes("-combo-option-")) {
                let splitAnswer = data[key].split("-combo-option-");
                submissionAnswer.create({
                  id_subm_quest: sendQuestion.id,
                  form_submis_id: sendSubmission.id,
                  answer: splitAnswer[1],
                  desc_alternat: splitAnswer[1],
                  id_alternative: splitAnswer[0],
                });
              } else if (data[key].includes("-radio-option-")) {
                let splitAnswer = data[key].split("-radio-option-");
                submissionAnswer.create({
                  id_subm_quest: sendQuestion.id,
                  form_submis_id: sendSubmission.id,
                  answer: splitAnswer[1],
                  desc_alternat: splitAnswer[1],
                  id_alternative: splitAnswer[0],
                });
              } else {
                submissionAnswer.create({
                  id_subm_quest: sendQuestion.id,
                  form_submis_id: sendSubmission.id,
                  answer: data[key],
                });
              }
            } else if (Array.isArray(data[key])) {
              data[key].forEach((item_arr) => {
                let splitAnswer = item_arr.split("-checkbox-option-");
                submissionAnswer.create({
                  id_subm_quest: sendQuestion.id,
                  form_submis_id: sendSubmission.id,
                  answer: splitAnswer[1],
                  desc_alternat: splitAnswer[1],
                  id_alternative: splitAnswer[0],
                });
              });
            } else {
              submissionAnswer.create({
                id_subm_quest: sendQuestion.id,
                form_submis_id: sendSubmission.id,
                answer: data[key],
              });
            }

            /* envia um e-mail em caso de resposta do formulário de registro de ocorrencia
             da tanatopraxia */
            if (titleForm) {
              addFormApi.sendMailForm({
                name: signin.user.name,
                email: signin.user.email,
              });
            }
          }
        }
      });
    } catch (e) {
      message.error("Oops... Ocorreu um erro ao salvar o formulário.");
      return false;
    }

    setLoading(false);
    message.success("Formulário salvo com sucesso.");
    dispatch(openDrawer(false));
    dispatch(responseFormComponent(true));

    if (titleForm) return false;

    resetIsForm(true);
  };

  /**
   * Função que monta o formulário e define o tipo de field que será apresentado
   */
  const mounted = ({ formComponent: data }) => {
    let formId = data.id;
    let formSubmission;

    if (
      appointment &&
      appointment.SettingService &&
      data.submission &&
      data.submission.f.length !== 0
    ) {
      data.responseForms.ServiceForms.forEach((serviceForm) => {
        if (serviceForm.id_form === formId) {
          if (serviceForm.Form.hasOwnProperty("f")) {
            formSubmission = serviceForm.Form.f.reverse();
          }
        }
      });
    } else if (data.submission && data.submission.f.length !== 0 && titleForm) {
      data.responseForms.ServiceForms.forEach((serviceForm) => {
        if (serviceForm.id_form === formId) {
          if (serviceForm.Form.hasOwnProperty("f")) {
            formSubmission = serviceForm.Form.f.reverse();
          }
        }
      });
    }

    if (!titleForm) {
      if (formSubmission && formSubmission.length !== 0) {
        formSubmission = formSubmission.filter(
          (e) => e.services_id === appointment.id
        );
      }
    }

    if (typeof formSubmission !== "undefined") {
      formSubmission.sort((a, b) => {
        return b.id - a.id;
      });
    }

    let html;

    if (data && data.Items && data.Items.length > 0) {
      sortData(data.Items).forEach((e) => {
        html = [
          html,
          <Form.Item
            name={e.title + "-id-question"}
            label={e.title + "-id-question"}
            hidden="true"
            initialValue={e.id}
          >
            <Input />
          </Form.Item>,
        ];

        if (e.id_type === 1) {
          let answerQuestion = null;
          if (typeof formSubmission !== "undefined") {
            if (formSubmission.length > 0) {
              formSubmission[0].q.forEach((question) => {
                if (e.id === question.id_question && question.s.length > 0) {
                  answerQuestion = question.s[0].answer;
                }
              });
            }
          }

          if (answerQuestion !== null) {
            let titleGroup = e.title;

            initialValuesForm = {
              ...initialValuesForm,
              [titleGroup]: answerQuestion,
            };
          }

          // Campo de texto normal
          html = [
            html,
            <h4>{e.title}</h4>,
            <Row className="display">
              <Col className="col-spacing" span={24}>
                <Form.Item
                  name={e.title}
                  rules={[
                    {
                      required: e.required,
                    },
                  ]}
                >
                  <Input disabled={readonly || statusSubmissionForm === 2} />
                </Form.Item>
              </Col>
            </Row>,
          ];
        } else if (e.id_type === 2) {
          let answerQuestion = null;
          if (typeof formSubmission !== "undefined") {
            if (formSubmission.length > 0) {
              formSubmission[0].q.forEach((question) => {
                if (e.id === question.id_question && question.s.length > 0) {
                  answerQuestion = question.s[0].answer;
                }
              });
            }
          }

          if (answerQuestion !== null) {
            let titleGroup = e.title;

            initialValuesForm = {
              ...initialValuesForm,
              [titleGroup]: answerQuestion,
            };
          }

          // Campo de número
          html = [
            html,
            <h4>{e.title}</h4>,
            <Row className="display">
              <Col className="col-spacing" span={24}>
                <Form.Item
                  name={e.title}
                  rules={[
                    {
                      required: e.required,
                    },
                  ]}
                >
                  <InputNumber
                    disabled={readonly || statusSubmissionForm === 2}
                    className="input-number"
                  />
                </Form.Item>
              </Col>
            </Row>,
          ];
        } else if (e.id_type === 3) {
          let answerQuestion = null;
          if (typeof formSubmission !== "undefined") {
            if (formSubmission.length > 0) {
              formSubmission[0].q.forEach((question) => {
                if (e.id === question.id_question && question.s.length > 0) {
                  answerQuestion = question.s[0].answer;
                }
              });
            }
          }

          if (answerQuestion !== null) {
            let titleGroup = e.title;

            initialValuesForm = {
              ...initialValuesForm,
              [titleGroup]: moment(answerQuestion),
            };
          }

          // Campo de data
          html = [
            html,
            <h4>{e.title}</h4>,
            <Row className="display">
              <Col className="col-spacing" span={24}>
                <Form.Item
                  name={e.title}
                  rules={[
                    {
                      required: e.required,
                    },
                  ]}
                >
                  <DatePicker
                    locale={locale}
                    className="input-number"
                    disabled={readonly || statusSubmissionForm === 2}
                    showTime={{ format: "HH:mm" }}
                    format="DD/MM/YYYY HH:mm"
                  />
                </Form.Item>
              </Col>
            </Row>,
          ];
        } else if (e.id_type === 4) {
          // Campo de título de seção
          html = [
            html,
            <Row className="display">
              <Col span={24}>
                <Divider />
                <h2>{e.title}</h2>
              </Col>
            </Row>,
          ];
        } else if (e.id_type === 5) {
          let answerQuestion = null;
          if (typeof formSubmission !== "undefined") {
            if (formSubmission.length > 0) {
              formSubmission[0].q.forEach((question) => {
                if (e.id === question.id_question && question.s.length > 0) {
                  answerQuestion =
                    question.s[0].id_alternative +
                    "-radio-option-" +
                    question.s[0].desc_alternat;
                }
              });
            }
          }

          if (answerQuestion !== null) {
            let titleGroup = e.title;

            initialValuesForm = {
              ...initialValuesForm,
              [titleGroup]: answerQuestion,
            };
          }

          // Campo de radio button
          let htmlRadioButtons;

          let radioAlternatives = e.Alternatives;
          if (radioAlternatives.length > 0) {
            radioAlternatives.sort((a, b) => {
              return a.sequence - b.sequence;
            });
          }

          radioAlternatives.forEach((element) => {
            htmlRadioButtons = [
              htmlRadioButtons,
              <Row className="display">
                <Col span={24}>
                  <Radio
                    key={element.id}
                    value={element.id + "-radio-option-" + element.description}
                  >
                    {element.description}
                  </Radio>
                </Col>
              </Row>,
            ];
          });

          html = [
            html,
            <h4>{e.title}</h4>,
            <Form.Item
              name={e.title}
              rules={[
                {
                  required: e.required,
                },
              ]}
            >
              <Radio.Group disabled={readonly || statusSubmissionForm === 2}>
                {htmlRadioButtons}
              </Radio.Group>
            </Form.Item>,
          ];
        } else if (e.id_type === 6) {
          let answerQuestion = null;
          if (typeof formSubmission !== "undefined") {
            if (formSubmission.length > 0) {
              formSubmission[0].q.forEach((question) => {
                if (e.id === question.id_question && question.s.length > 0) {
                  answerQuestion =
                    question.s[0].id_alternative +
                    "-combo-option-" +
                    question.s[0].desc_alternat;
                }
              });
            }
          }

          if (answerQuestion !== null) {
            let titleGroup = e.title;

            initialValuesForm = {
              ...initialValuesForm,
              [titleGroup]: answerQuestion,
            };
          }

          let comboboxAlternatives = e.Alternatives;
          if (comboboxAlternatives.length > 0) {
            comboboxAlternatives.sort((a, b) => {
              return a.sequence - b.sequence;
            });
          }

          // Campo de combobox
          html = [
            html,
            <h4>{e.title}</h4>,
            <Row className="display">
              <Col span={24}>
                <Form.Item
                  name={e.title}
                  rules={[
                    {
                      required: e.required,
                    },
                  ]}
                >
                  <Select
                    style={{ width: 435 }}
                    disabled={readonly || statusSubmissionForm === 2}
                  >
                    {comboboxAlternatives.map((element) => (
                      <Option
                        key={element.id}
                        value={
                          element.id + "-combo-option-" + element.description
                        }
                      >
                        {element.description}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>,
          ];
        } else if (e.id_type === 7) {
          let answerQuestion = null;
          if (typeof formSubmission !== "undefined") {
            if (formSubmission.length > 0) {
              formSubmission[0].q.forEach((question) => {
                if (e.id === question.id_question && question.s.length > 0) {
                  answerQuestion = question.s;
                }
              });
            }
          }

          // Campo de checkboxes
          let htmlCheckboxes;
          let alternatives = [];

          let checkboxAlternatives = e.Alternatives;
          if (checkboxAlternatives.length > 0) {
            checkboxAlternatives.sort((a, b) => {
              return a.sequence - b.sequence;
            });
          }

          checkboxAlternatives.forEach((element) => {
            if (answerQuestion !== null) {
              answerQuestion.forEach((ans) => {
                if (ans.id_alternative === element.id) {
                  alternatives.push(
                    element.id + "-checkbox-option-" + element.description
                  );
                }
              });
            }

            htmlCheckboxes = [
              htmlCheckboxes,
              <Row className="display">
                <Col span={24}>
                  <Checkbox
                    key={element.id}
                    value={
                      element.id + "-checkbox-option-" + element.description
                    }
                    disabled={readonly || statusSubmissionForm === 2}
                  >
                    {element.description}
                  </Checkbox>
                </Col>
              </Row>,
            ];
          });

          if (answerQuestion !== null && alternatives.length > 0) {
            let titleGroup = e.title;

            initialValuesForm = {
              ...initialValuesForm,
              [titleGroup]: alternatives,
            };
          }

          html = [
            html,
            <h4>{e.title}</h4>,
            <Form.Item
              name={e.title}
              rules={[
                {
                  required: e.required,
                },
              ]}
            >
              <Checkbox.Group>{htmlCheckboxes}</Checkbox.Group>
            </Form.Item>,
          ];
        } else if (e.id_type === 8) {
          let answerQuestion = null;
          if (typeof formSubmission !== "undefined") {
            if (formSubmission.length > 0) {
              formSubmission[0].q.forEach((question) => {
                if (e.id === question.id_question && question.s.length > 0) {
                  answerQuestion = question.s[0].answer;
                }
              });
            }
          }

          if (answerQuestion !== null) {
            let titleGroup = e.title;

            initialValuesForm = {
              ...initialValuesForm,
              [titleGroup]: answerQuestion,
            };
          }

          // Campo de textarea
          html = [
            html,
            <h4>{e.title}</h4>,
            <Row className="display">
              <Col span={24}>
                <Form.Item
                  name={e.title}
                  rules={[
                    {
                      required: e.required,
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    disabled={readonly || statusSubmissionForm === 2}
                  />
                </Form.Item>
              </Col>
            </Row>,
          ];
        }
      });
    } else {
      html = (
        <h2 className="text-error text-center">
          O formulário não possui itens para serem exibidos. <FrownOutlined />
        </h2>
      );
    }

    return (
      <Form
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialValuesForm}
      >
        {html}
        <div>
          <p>
            {formComponentData.formComponent.f &&
              formComponentData.formComponent.f.length !== 0 ? (
              <>
                <strong>Última alteração feita por: </strong>
                {formComponentData.formComponent.f[0].User.name}
              </>
            ) : (
              false
            )}
          </p>
        </div>
        <div>
          {!readonly ? (
            <Form.Item>
              {!checkSubmission || checkSubmission === 1 ? (
                <Button
                  type="primary"
                  size="middle"
                  shape="round"
                  htmlType="submit"
                  style={{ marginRight: 10 }}
                  onClick={() => setStatusSubmissionForm(1)}
                >
                  Salvar
                </Button>
              ) : null}

              {!checkSubmission || checkSubmission === 1 ? (
                <Button
                  type="primary"
                  size="middle"
                  shape="round"
                  htmlType="submit"
                  style={{
                    marginRight: 10,
                    background: "green",
                    borderColor: "green",
                  }}
                  onClick={() => setStatusSubmissionForm(2)}
                >
                  Finalizar
                </Button>
              ) : null}

              {checkSubmission === 2 ? (
                <Button
                  size="middle"
                  shape="round"
                  htmlType="submit"
                  onClick={() => setStatusSubmissionForm(1)}
                >
                  Reabrir
                </Button>
              ) : null}
            </Form.Item>
          ) : null}
        </div>
      </Form>
    );
  };

  return (
    <div>{loading ? <Skeleton active /> : mounted(formComponentData)}</div>
  );
}

export default FormComponent;
