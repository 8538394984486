import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const listItemTypeApi = {
  listItemType: ({limit, offset, active = true}) =>
    getRequest(
      `/item/type?${new URLSearchParams({limit, offset, active})}`,
      {}
    ),
};

export const addItemTypeApi = {
  addItemType: (body) => postRequest("/item/type", body),
};

export const updateItemTypeApi = {
  updateItemType: (body) => putRequest("/item/type/" + body.id, body),
};

export const deleteItemTypeApi = {
  deleteItemType: (id) => deleteRequest("/item/type/" + id, {}),
};

export const showitemTypeApi = {
  show: (id) => getRequest(`/item/type/${id}`)
}