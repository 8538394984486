import {
  Card,
  Skeleton,
} from 'antd';
import './style.css';

const CardTotal = (props) => {
  return (
    <Card className='card-amount' style={{ backgroundColor: props.color, width: "100%", height: "100%" }} size='small'>
      <h3 style={{ color: "white", textAlign: "center", fontSize: "14px" }}>{props.title}</h3>
      {
        (props.loading)
          ?
          <div style={{ textAlign: 'center' }}>
            <Skeleton.Input size={42} style={{ width: 150 }} />
          </div>
          :
          <h4 style={{ color: "white", textAlign: "center", fontSize: "26px" }}>{props.value}</h4>
      }
    </Card>
  );
}

export default CardTotal;