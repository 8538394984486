/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from "react-helmet";

import { itensDrawer } from "../itens-menu";
import Main from "../../../Layout/Main";
import MenuComponent from "../../../../components/MenuComponent";
import {
  Button,
  Col,
  notification,
  Popconfirm,
  Row,
  Table,
  Typography,
} from "antd";
import { useFetch } from "../../../../utils/useFetch";
import { ruleValidationServices } from "../../../../services/ruleValidationServices";
import { FormModal } from "./components/FormModal";
import { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useMutate } from "../../../../utils/useMutate";

const FieldRules = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentRule, setCurrentRule] = useState({});
  const [offset, setOffset] = useState(0);
  const { data: rules, refetch: refetchRules } = useFetch(
    ruleValidationServices.getAll,
    {
      initialParams: {
        limit: 10,
        offset: 0,
      },
      initialState: [],
    }
  );

  const { mutate: deleteRule } = useMutate(ruleValidationServices.delete, {
    onSuccess: () => {
      refetchRules();
      notification.warn({
        message: "Sucesso",
        description: "Regra excluída com sucesso",
      });
    },
  });

  const handleSuccess = () => {
    refetchRules();
  };

  const handleClose = () => {
    setOpenModal(false);
    setCurrentRule({});
  };

  useEffect(() => {
    refetchRules({ limit: 10, offset });
  }, [offset]);

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Regras de campos",
      path:
        props.modulePath + "/service-management/setting-services/field-rules",
    },
  ];

  const columns = [
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Expressão",
      dataIndex: "expression",
      key: "expression",
      render(text) {
        return <Typography.Text code>{text}</Typography.Text>;
      },
    },
    {
      title: "Mensagem",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      render(text, record) {
        return (
          <Row gutter={8}>
            <Col>
              <Button
                onClick={() => {
                  setOpenModal(true);
                  setCurrentRule(record);
                }}
                title="Editar"
                shape="circle"
                type="ghost"
                icon={<EditOutlined />}
              />
            </Col>
            <Col>
              <Popconfirm
                placement="left"
                onConfirm={() => {
                  deleteRule(record.id);
                }}
                title="Tem certeza que deseja excluir?"
                okText="Sim"
                cancelText="Não"
              >
                <Button
                  title="Excluir"
                  shape="circle"
                  danger
                  type="ghost"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Regras de campos</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="12"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <Button onClick={() => setOpenModal(true)} type="primary">
            Criar
          </Button>
        }
      >
        <Table
          pagination={{
            total: rules.count,
            pageSize: 10,
            showSizeChanger: false,
            onChange: (page, pageSize) => {
              setOffset(pageSize * (page - 1));
            },
          }}
          dataSource={rules.rows}
          columns={columns}
        />
        <FormModal
          open={openModal}
          data={currentRule}
          onClose={handleClose}
          onSuccess={handleSuccess}
        />
      </Main>
    </>
  );
};

export default FieldRules;
