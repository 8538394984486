import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Menu, Typography, Drawer, message, Row, Col, Button } from "antd";
import "./styles.css";

import { AppstoreOutlined, CloudFilled, QuestionCircleOutlined } from "@ant-design/icons";
import { listModules } from "../../store/ducks/modules";
import { setCurrentModule } from "../../store/ducks/signin";
import { ModulesService } from "../../services/modules";
import { checkControl } from "../../utils/access_control";

import DynamicIcon from "../../utils/dynamicIcon";
import DimensionsScreen from "../../components/DimensionsScreen";
import { position } from "@chakra-ui/react";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";

function SideBar(props) {
  const { Title } = Typography;
  const { Sider } = Layout;

  const [collapsed, setCollapsed] = useState(true);
  const [logoWidth, setLogoWidth] = useState("50px");
  const [displayLogoName, setDisplayLogoName] = useState("none");
  const [showDrawer, setShowDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const { width } = DimensionsScreen();

  const modules = useSelector((state) => state.modules);
  const signin = useSelector((state) => state.signin);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getModules();
  }, []);

  useEffect(() => {
    if (width <= 1000) {
      onCollapse(true);
    } else {
      if (props.collapsed !== undefined) onCollapse(props.collapsed);
    }
  }, [props.collapsed]);

  function getModules() {
    try {
      setLoading(true);

      ModulesService.getModules().then((res) => {
        dispatch(listModules(res));
      });
    } catch (error) {
      message.error("Ops! Aconteceu um erro ao carregar os dados.");
    } finally {
      setLoading(false);
    }
  }

  function onCollapse(collapsed) {
    if (collapsed === true) {
      setCollapsed(true);
      setLogoWidth("50px");
      setDisplayLogoName("none");
    } else {
      setCollapsed(false);
      setLogoWidth("200px");
      setDisplayLogoName("block");
    }
  }

  function showDrawerFunction() {
    setShowDrawer(true);
  }

  function onClose() {
    setShowDrawer(false);
  }

  function handleModule(module) {
    dispatch(
      setCurrentModule({ module: module.name, id_sector: module.id_sector })
    );

    if (history.location.path !== `/${module.path}`) {
      history.push(`/${module.path}`);
    }
  }

  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        width="230"
        className="menu-sidebar"
      >
        <Row
          style={{
            height: "100%",
          }}
          justify="space-between"
        >
          <Col>
            <div className="logo">
              <Link
                to={""}
                style={{
                  width: logoWidth,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <CloudFilled style={{ fontSize: "30px", color: "#fff" }} />
                <Title
                  level={4}
                  style={{
                    color: "#fff",
                    paddingLeft: "10px",
                    display: displayLogoName,
                  }}
                >
                  webGM
                </Title>
              </Link>
            </div>
            {props.moduleMenu}
          </Col>
          <Col
            style={{
              marginTop: "auto",
              maxHeight: "min-content",

              top: "auto",
            }}
          >
            <Menu theme="dark" mode="inline" selectable={false}>


              <Menu.Item
                key="1"
                icon={<AppstoreOutlined />}
                onClick={showDrawerFunction}
              >
                Meus Módulos
              </Menu.Item>

              {/* <Menu.Item
                key="0"
                icon={<QuestionCircleOutlined />}
              >
                <a target="__blank" rel="noreferrer" href="https://drive.google.com/drive/u/0/folders/1-y_6EKwy9ow6_UQHxW_vNuLCwBt9oqKz">
                  Ajuda
                </a>
              </Menu.Item> */}
            </Menu>
          </Col>
        </Row>
      </Sider>

      <Drawer
        title="Meus Módulos"
        placement="left"
        closable={false}
        onClose={onClose}
        visible={showDrawer}
        key="left"
        width="400"
      >
        <Menu mode="vertical" id="userMenu">
          {modules.modules.map((module) => {
            return (
              <Menu.Item
                key={module.id}
                onClick={() => handleModule(module)}
                style={checkControl(
                  signin.user.profiles,
                  module.Resource.class_tag
                )}
                icon={<DynamicIcon name={module.icon} />}
              >
                {module.name}
              </Menu.Item>
            );
          })}
        </Menu>
      </Drawer>
    </>
  );
}

export default SideBar;
