import moment from "moment";

export function checkLate(serviceName, unlocked_date, start, is_blocked_attendance, general_status_id, id_sector, blocked, product) {

  const dateNow = new Date(Date.now());
  const sectorId = id_sector;

  let isLate = false;
  let dateLimit = dateNow;
  let minutesAdd = 0;

  if (general_status_id == 1 && !blocked) {
    if ([1,4].includes(sectorId) && product.toUpperCase().trim().includes('TANATO')) {
      dateLimit = new Date(unlocked_date) 
      minutesAdd += 10
    }
    if (sectorId == 3 && is_blocked_attendance == 'L' && serviceName.toUpperCase().trim().includes('REMO')) {
      dateLimit = new Date((start))
      minutesAdd += 15
    }
  }
  
  dateLimit.setMinutes(dateLimit.getMinutes() + minutesAdd)
  const timeLate = moment(dateLimit).from(dateNow);
  
  return {
    isLate : dateNow > dateLimit,
    timeLate
  }
}
