import React, { useEffect } from "react";
import { Row, Col, Form, Input, Button, message, Popconfirm } from "antd";
import { useMutate } from "../../../../../utils/useMutate";
import {
  createApplicationMutation,
  restoreApplicationMutation,
  updateApplicationMutation,
} from "../../../../../services/notificationService";
import { isJson } from "../../../../../utils/validations";

const ApplicatinoForm = ({ onFinish, currentApplication }) => {
  const [form] = Form.useForm();

  const { loading: createGroupLoading, mutate: createApplication } = useMutate(
    createApplicationMutation,
    {
      onSuccess: () => {
        onFinish && onFinish();
        form.resetFields();
        message.success(
          `Aplicação ${
            currentApplication?.id ? "atualizada" : "criada "
          } com sucesso!`
        );
      },
      onError: (err) => {
        message.error(
          `Erro ao ${
            currentApplication?.id ? "atualizar" : "criar "
          } aplicação!\n${err.response?.data.message}`
        );
      },
    }
  );

  const { loading: updateApplicationLoading, mutate: updateApplication } =
    useMutate(updateApplicationMutation, {
      onSuccess: () => {
        onFinish && onFinish();
        form.resetFields();
        message.success("Aplicação atualizada com sucesso!");
      },
      onError: (e) => {
        message.error("Erro ao atualizar aplicação!" + e);
      },
    });

  const { loading: restoreApplicationLoading, mutate: restoreApplication } =
    useMutate(restoreApplicationMutation, {
      onSuccess: () => {
        onFinish && onFinish();
        form.resetFields();
        message.success("Aplicação restaurada com sucesso!");
      },
      onError: (e) => {
        message.error("Erro ao restaurar aplicação!" + e);
      },
    });

  const handleSubmit = (values) => {
    if (currentApplication?.id) {
      updateApplication({
        id: currentApplication?.id,
        data: {
          ...values,
          ...(values.credentials && {credentials:  JSON.parse(values.credentials)}),
        },
      });
    } else {
      createApplication(values);
    }
  };

  const handleRestore = () => {
    restoreApplication({ id: currentApplication?.id });
  };

  useEffect(() => {
    form.resetFields();
  }, [currentApplication]);

  return (
    <Form
      layout="vertical"
      form={form}
      name="form-group"
      onFinish={handleSubmit}
      initialValues={{
        name: currentApplication?.name,
        code: currentApplication?.code,
        active: currentApplication?.active ?? true,
        credentials: currentApplication?.credentials
          ? JSON.stringify(currentApplication?.credentials)
          : "",
      }}
    >
      <Row gutter={8}>
        <Col
          span={24}
          md={{
            span: 12,
          }}
        >
          <Form.Item
            name="code"
            label="Code"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Digite o código do aplicação" />
          </Form.Item>
        </Col>
        <Col
          span={24}
          md={{
            span: 12,
          }}
        >
          <Form.Item
            name="name"
            label="Nome"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Digite o nome do aplicação" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={24} lg={24}>
          <Form.Item
            name="credentials"
            label="Credenciais"
            help={"Devem ser informadas no formato JSON"}
            rules={[
              {
                message: "Formato inválidofff",
                validator: (_, value) => {
                  const isValidJson = isJson(value) || value === "";
                  return isValidJson ? Promise.resolve() : Promise.reject();
                },
              },
            ]}
          >
            <Input.TextArea
              rows={5}
              placeholder="Digite as credenciais do aplicação"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="end" align="bottom">
        {currentApplication?.deleted_at && (
          <Col>
            <Popconfirm
              title="Tem certeza que deseja deletar este aplicação?"
              onConfirm={handleRestore}
              okText="Sim"
              cancelText="Cancelar"
            >
              <Button
                loading={restoreApplicationLoading}
                danger
                type="primary"
                style={{
                  marginLeft: "8px",
                }}
              >
                Reativar
              </Button>
            </Popconfirm>
          </Col>
        )}
        <Col>
          <Button
            loading={createGroupLoading || updateApplicationLoading}
            type="primary"
            htmlType="submit"
            style={{
              marginLeft: "8px",
            }}
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ApplicatinoForm;
