import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Select, message, Table, Empty } from "antd";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";

import arrayMove from "array-move";

import Main from "../../../../../Layout/Main";
import FormItens from "../FormItens";

import { ModalApp } from "../../../../../../components/Modal/index";
import loadingSVG from "../../../../../../assets/images/loading.svg";

import {
  openModal,
  closeModal,
} from "../../../../../../store/ducks/modalGlobal";

import { addItem, alterItem } from "../../../../../../store/ducks/formItens";

import {
  addForm,
  alterForm,
  updateForm,
} from "../../../../../../store/ducks/forms";

import { addFormApi, updateFormApi } from "../../../../../../services/forms";
import { SectorsService } from "../../../../../../services/sectors";

import { listItemTypeApi } from "../../../../../../services/itemType";
import { listItemType } from "../../../../../../store/ducks/intemTypes";

import {
  FormOutlined,
  MenuOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { addItemApi } from "../../../../../../services/formItem";

import { addAlternativeApi } from "../../../../../../services/alternative";
import { GoalService } from "../../../../../../services/indicatorGoals";

import moment from "moment";
import { itensDrawer } from "../../../itens-menu";
import MenuComponent from "../../../../../../components/MenuComponent";

function FormAdd(props) {
  const signin = useSelector((state) => state.signin);
  const dataForm = useSelector((state) => state.forms.form);
  const modal = useSelector((state) => state.modalGlobal.open_modal);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [resourceData, setResourceData] = useState(
    dataForm.Items && dataForm.Items.length !== 0 ? dataForm.Items : []
  );
  const [sequenceNewItem, setSequenceNewItem] = useState(
    dataForm.Items && dataForm.Items.length !== 0 ? dataForm.Items.length : 0
  );
  const [sectors, setSectors] = useState([]);
  const [isUpdateSequence, setIsUpdateSequence] = useState(false);
  const [newQuantityItems, setNewQuantityItems] = useState(0);
  const [newItemInForm, setNewItemInForm] = useState([]);

  const [pageSelectGoal, setPageSelectGoal] = useState(1);
  const [rowsPerPageSelectGoal, setRowsPerPageSelectGoal] = useState(10);
  const [listGoal, setListGoal] = useState([]);
  const [likeGoal, setLikeGoal] = useState("");
  const [goalSelected, setGoalSelected] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getSectors();
    getGoals();
    getGoalSettinsService();
  }, []);

  useEffect(() => {
    if (modal) {
      list(true);
    }
  }, [modal]);

  useEffect(() => {
    setSequenceNewItem(sequenceNewItem + 1);
  }, [newQuantityItems]);

  useEffect(() => {
    setResourceData(dataForm.Items);
  }, [dataForm.Items]);

  const [form] = Form.useForm();

  const { Option } = Select;

  const validateMessages = {
    required: "${label} é obrigatório!",
  };

  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
  ));

  const columns = [
    {
      render: () => <DragHandle />,
    },
    {
      title: "Sequência",
      dataIndex: "sequence",
    },
    {
      title: "Item",
      dataIndex: "title",
    },
    {
      title: "Tipo",
      dataIndex: ["ItemType", "description"],
    },
    {
      render: (data) => (
        <Button
          type="primary"
          shape="circle"
          ghost
          size="small"
          onClick={() => handleOpenModal(data)}
        >
          <EditOutlined />
        </Button>
      ),
    },
    {
      render: (data) => {
        if (!dataForm.id) {
          return (
            <Button
              type="danger"
              shape="circle"
              ghost
              size="small"
              onClick={() => handleRemoveNewItensStore(data)}
            >
              <DeleteOutlined />
            </Button>
          );
        } else {
          return null;
        }
      },
    },
  ];

  /**
   * Busca as metas
   */
  const getGoals = () => {
    const offset =
      pageSelectGoal !== 1 ? rowsPerPageSelectGoal * (pageSelectGoal - 1) : 0;

    GoalService.index(
      rowsPerPageSelectGoal,
      offset,
      signin.user.currentCompany,
      likeGoal
    )
      .then((res) => {
        return Promise.all(
          res.rows.map((value) => {
            if (!value.goal) {
              return {
                ...value,
                goal: `${moment(value.start_date).format(
                  "DD/MM/YYYY"
                )} - ${moment(value.end_date).format("DD/MM/YYYY")}`,
              };
            } else if (value.goal.length <= 3) {
              return {
                ...value,
                goal: `${value.goal}%`,
              };
            } else {
              return {
                ...value,
                goal: moment(value.goal).format("HH:mm:ss"),
              };
            }
          })
        ).then((response) => setListGoal(response));
      })
      .catch(() =>
        message.error(
          "Ops. não encontramos as metas, verifique se elas estam cadastrados."
        )
      );
  };

  /**
   * Busca a meta desse formulário
   */
  const getGoalSettinsService = () => {
    setLoading(true);

    GoalService.showForm(dataForm.id)
      .then((response) => setGoalSelected(response))
      .catch(() => message.error("Ops. ocorreu um erro ao carregar a meta."))
      .finally(() => setLoading(false));
  };

  async function list(active) {
    try {
      listItemTypeApi.listItemType({ limit: 10, offset:0, active: active }).then((response) => {
        dispatch(listItemType(response));
      });
    } catch (error) {
      message.error("Erro ao carregar dados!");
    }
  }

  const getSectors = () => {
    setLoading(true);

    SectorsService.getSectors()
      .then((response) => setSectors(response.rows))
      .catch((err) => {
        message.error(err.message);
      });

    setLoading(false);
  };

  const openMessage = (values) => {
    setLoading(true);
    dispatch(
      alterForm({
        ...dataForm,
        itens: values,
      })
    );
    const key = "updatable";
    message.loading({ content: "Salvando...", key });
    setTimeout(() => {
      message.success({ content: "Salvo!", key, duration: 2 });
    }, 1000);
    setLoading(false);
  };

  const itensForm = (data) => {
    const items =
      resourceData && resourceData.length !== 0 ? [...resourceData] : [];
    items.push(data);
    setNewQuantityItems(newQuantityItems + 1);
    setResourceData(items);
  };

  const onFinish = (values) => {
    setLoading(true);

    if (!dataForm.id) {
      addFormApi
        .addForm({
          ...values,
          company_id: signin.user.currentCompany,
        })
        .then((res) => {
          handleSubmitNewItems(res.id);
          dispatch(addForm(res));
          dispatch(alterForm({}));
          dispatch(closeModal(false));
          setLoading(false);
          message.success("Formulário criado com sucesso!");
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    } else {
      updateFormApi
        .updateForm({
          ...dataForm,
          ...values,
          newSequence: isUpdateSequence,
        })
        .then((response) => {
          dispatch(updateForm(response));
          dispatch(alterForm({}));
          dispatch(closeModal(false));
          setLoading(false);
          message.success("Formulário atualizado com sucesso!");
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    }

    setSequenceNewItem(0);
    setIsUpdateSequence(false);
    form.resetFields();
    history.push("/" + props.modulePath + "/forms");
  };

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(10);
  }

  const handleOpenModal = (data) => {
    if (!dataForm.id && !data.sequence) {
      dispatch(openModal(true));
    } else {
      dispatch(openModal(true));
      if (data.sequence) {
        dispatch(
          alterItem({
            ...data,
          })
        );
      }
    }
  };

  // novo item que chega caso o formulário ainda não exista para ser salvo no array de estado
  const newItemForm = (value) => {
    const type = value.description_type;
    delete value.description_type;

    itensForm({
      ...value,
      ItemType: {
        description: type,
      },
    });

    if (newItemInForm.length === 0) {
      setNewItemInForm([value]);
    } else {
      const items = [...newItemInForm];
      items.push(value);
      setNewItemInForm(items);
    }
  };

  // pega todos os itens do array do novo formulário e cria novos itens após criar o formulário
  const handleSubmitNewItems = (id_form) => {
    newItemInForm.map((item) => {
      let alternatives = [...item.alternatives];
      delete item.alternatives;

      addItemApi
        .addItem({
          ...item,
          id_form,
        })
        .then((response) => {
          if (!response.title || !response.sequence) {
            throw {
              message:
                "Desculpe! ocorreu um erro ao criar o item, favor tente novamente",
            };
          }

          dispatch(addItem(response));
          if (alternatives && alternatives.length !== 0) {
            onSalveAlternatives(response.id, alternatives);
          }
        })
        .catch((err) => {
          message.error(err.message);
        });
    });
  };

  /*
   * cria as alternativas após a criação dos itens,
   * caso o novo item do novo formulário tenha alternativas
   */
  const onSalveAlternatives = async (id_item, data) => {
    data.map((item) => {
      if (item) {
        addAlternativeApi
          .addAlternative({
            ...item,
            id_item,
          })
          .then((res) => {
            return res;
          })
          .catch((err) => {
            message.error(err);
          });
      }
    });
  };

  // caso seja um novo item que não esteja salvo no banco de dados só no store, remove do store
  const handleRemoveNewItensStore = (data) => {
    setLoading(true);
    const newFields = newItemInForm.filter(
      (field) => field.sequence !== data.sequence
    );

    setResourceData(newFields);
    setNewItemInForm(newFields);
    setLoading(false);
  };

  const sortData = (data) => {
    return data.sort((a, b) => {
      return parseInt(a.sequence) - parseInt(b.sequence);
    });
  };

  // ordena as linhas da tabela
  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainerData = sortableContainer((props) => (
    <tbody {...props} />
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(resourceData),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      const data = newData.map((item, index) => {
        return {
          ...item,
          sequence: index + 1,
        };
      });

      openMessage(data);
      setResourceData(data);
      setIsUpdateSequence(true);
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = resourceData.findIndex(
      (x) => x.sequence === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const DraggableContainer = (props) => (
    <SortableContainerData
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Formulários",
      path: props.modulePath + "/forms",
    },
    {
      title: "Adicionar",
      path: props.modulePath + "/add/form",
    },
  ];

  return (
    <Main
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          itensMenu={itensDrawer}
          modulePath={props.modulePath}
          selectedMenu="7"
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"flex"}
      buttonHeader={
        <Button
          type="primary"
          htmlType="submit"
          form="forms"
          style={{ marginLeft: 10 }}
        >
          Salvar
        </Button>
      }
    >
      {loading ? (
        <>
          <div className="loading-centar">
            <img src={loadingSVG} alt="loading" height={80} />
          </div>
          <h2 className="text-loading">Carregando...</h2>
        </>
      ) : (
        <Form
          layout="vertical"
          id="forms"
          form={form}
          name="forms"
          onFinish={onFinish}
          validateMessages={validateMessages}
          initialValues={{
            "title": dataForm.title,
            "description": dataForm.description,
            "id_sector": dataForm.id_sector,
          }}
        >
          <Form.Item
            name="title"
            label="Título"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Digite um título" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Descrição"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Checklist de entrega" />
          </Form.Item>
          <Form.Item
            name="id_sector"
            label="Setor"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Selecione um setor" allowClear>
              {sectors && sectors.length !== 0
                ? sectors.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.description}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>

          <Form.Item name="goal_id" label="Meta">
            <Select
              showSearch
              style={{ width: 500 }}
              defaultValue={
                goalSelected && goalSelected.goal
                  ? `${goalSelected.goal}%`
                  : null
              }
              optionFilterProp="children"
              onChange={(value) => setGoalSelected(value)}
              onSearch={(value) => setLikeGoal(value)}
            >
              <Option key={0} value={0}>
                Nenhuma meta cadastrada nesse serviço
              </Option>
              {listGoal && listGoal.length !== 0
                ? listGoal.map((goal) => {
                    return (
                      <Option key={goal.id} value={goal.id}>
                        {goal.goal_type}: {goal.goal}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>

          <Form.Item label="Itens">
            <Button
              type="primary"
              style={{ marginBottom: 10, marginTop: 5 }}
              shape="circle"
              icon={<FormOutlined />}
              onClick={handleOpenModal}
            ></Button>

            {/* Tabela drag and drop */}

            {resourceData && resourceData.length !== 0 ? (
              <Table
                columns={columns}
                dataSource={sortData(resourceData)}
                rowKey={(record) => record.sequence}
                onChange={handleTableChange}
                sort
                size="small"
                loading={loading}
                pagination={false}
                components={{
                  body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                  },
                }}
              />
            ) : (
              <Empty description={false} />
            )}
          </Form.Item>
        </Form>
      )}
      <ModalApp title="Itens" width={1000} footer={null} close={true}>
        <FormItens
          sequence={sequenceNewItem}
          itensForm={itensForm}
          newItemForm={newItemForm}
        />
      </ModalApp>
    </Main>
  );
}

export default FormAdd;
