import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Row,
  Col,
  Empty,
  Modal,
  Tabs,
  Divider,
  Tooltip,
  Table,
  Button,
} from "antd";
import { BorderOutlined, CheckSquareOutlined } from "@ant-design/icons";
import { SiGooglemaps } from "react-icons/si";

import loadingSVG from "../../assets/images/loading.svg";
import mapIcon from "../../assets/images/maps_icon.png";

import { modalProtheus } from "../../store/ducks/modalGlobal";

import "./styles.css";
import moment from "moment";
import { useFetch } from "../../utils/useFetch";
import { getCemeteryReserveQuery } from "../../services/cemeteryReserveService";
import { setBaseService } from "../../store/ducks/modalProtheusData";
import { useEffect } from "react";


const { TabPane } = Tabs;

const checkIcon = (value) => {
  if (
    value === "N" ||
    value === "n" ||
    value === "Não" ||
    value === "não" ||
    value === "NÃO" ||
    value === "nao" ||
    value === "NAO" ||
    value === "Nao" ||
    value === ".F." ||
    value === ".f." ||
    value === false ||
    value === " " ||
    value === "" ||
    !value
  ) {
    return <BorderOutlined />;
  } else if (
    value === "S" ||
    value === "s" ||
    value === "Sim" ||
    value === "sim" ||
    value === "SIM" ||
    value === ".T." ||
    value === ".t." ||
    value === true
  ) {
    return <CheckSquareOutlined />;
  } else {
    return value;
  }
};

function ModalProtheusData(props) {
  const appointment = useSelector((state) => state.appointment.appointment);
  const detailsProtehusData = useSelector(
    (state) => state.modalProtheusData.data
  );
  const dataProtheusLoading = useSelector(
    (state) => state.modalProtheusData.loading
  );
  const modalDataProtehus = useSelector(
    (state) => state.modalGlobal.open_modal_protheus_data
  );

  const baseService = useSelector((state) => state.modalProtheusData.baseService);
  const cemeteryReserve = baseService?.cemetery_reserve;
  const dispatch = useDispatch();

  // abre o mapa com a rota
  const openMaps = (value, direction) => {
    const origin = encodeURI(
      `${value.local_origem},${value.endereco_origem},${value.bairro_origem},${value.cidade_origem},${value.estado_origem}`
    );
    const destination = encodeURI(
      `${value.local_destino},${value.endereco_destino},${value.bairro_destino},${value.cidade_destino},${value.estado_destino}`
    );

    let urlMap = `https://www.google.com/maps/dir/?api=1&`;

    if (direction === 1) {
      urlMap = urlMap + `origin=${origin}&destination=${destination}`;
    } else {
      urlMap = urlMap + `origin=${destination}&destination=${origin}`;
    }

    window.open(urlMap);
  };

  const openMapsSeacrh = (value) => {
    const local = encodeURI(
      `${value.local},${value.logradouro},${value.bairro},${value.cidade},${value.estado}`
    );
    const urlMap = `https://www.google.com/maps/search/?api=1&query=${local}`;
    window.open(urlMap);
  };


  return (
    <Modal
      title="Detalhes Gerais"
      open={modalDataProtehus}
      onCancel={() => {
        dispatch(modalProtheus(false))
        dispatch(setBaseService(null))
    }}
      footer={null}
      width={1300}
    >
      {dataProtheusLoading ? (
        <>
          <div className="loading-centar">
            <img src={loadingSVG} alt="loading" height={80} />
          </div>
          <h2 className="text-loading">Carregando...</h2>
        </>
      ) : detailsProtehusData.FALECIDO ? (
        <Tabs type="card" defaultActiveKey={"DECLARANTE"}>
          <TabPane tab="Declarante" key="DECLARANTE">
            <Row>
              <Col span={5}>
                <p>
                  <strong>Nome: </strong>
                </p>
                <p>
                  <strong>E-mail: </strong>
                </p>
                <p>
                  <strong>Telefone: </strong>
                </p>
                <p>
                  <strong>Celular: </strong>
                </p>
              </Col>
              <Col>
                <p>{detailsProtehusData.DECLARANTE.nome}</p>
                <p>{detailsProtehusData.DECLARANTE.email}</p>
                <p>
                  {detailsProtehusData.DECLARANTE.ddd}{" "}
                  {detailsProtehusData.DECLARANTE.telfixo}
                </p>
                <p>
                  {detailsProtehusData.DECLARANTE.ddd}{" "}
                  {detailsProtehusData.DECLARANTE.telcel}
                </p>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Remoção" key="REMOCAO">
            <Row>
              <Col span={6}>
                <h2>Origem: </h2>
              </Col>
              <Col span={6} offset={2}>
                <h2>Destino: </h2>
              </Col>
              <Col span={6} offset={2}>
                <h2>Agendamento com a família: </h2>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <p>
                  <strong>CEP:</strong> {detailsProtehusData.REMOCAO.cep_origem}
                </p>
                <p>
                  <strong>Local:</strong>{" "}
                  {detailsProtehusData.REMOCAO.local_origem}
                </p>
                <p>
                  <strong>Endereço:</strong>{" "}
                  {detailsProtehusData.REMOCAO.tipo_endereco_origem}{" "}
                  {detailsProtehusData.REMOCAO.endereco_origem}{" "}
                  <strong>N°</strong>{" "}
                  {detailsProtehusData.REMOCAO.numero_end_origem}
                </p>
                <p>
                  <strong>Referência:</strong>{" "}
                  {detailsProtehusData.REMOCAO.referencia_origem}
                </p>
                <p>
                  <strong>Bairro:</strong>{" "}
                  {detailsProtehusData.REMOCAO.bairro_origem}
                </p>
                <p>
                  <strong>Cidade:</strong>
                  {detailsProtehusData.REMOCAO.cidade_origem
                    ? `${detailsProtehusData.REMOCAO.cidade_origem}/${detailsProtehusData.REMOCAO.estado_origem}`
                    : null}
                </p>
              </Col>
              <Col span={6} offset={2}>
                <p>
                  <strong>CEP:</strong>{" "}
                  {detailsProtehusData.REMOCAO.cep_destino}
                </p>
                <p>
                  <strong>Local:</strong>{" "}
                  {detailsProtehusData.REMOCAO.local_destino}
                </p>
                <p>
                  <strong>Endereço:</strong>{" "}
                  {detailsProtehusData.REMOCAO.tipo_endereco_destino}{" "}
                  {detailsProtehusData.REMOCAO.endereco_destino}{" "}
                  <strong>N°</strong>{" "}
                  {detailsProtehusData.REMOCAO.numero_end_destino}
                </p>
                <p>
                  <strong>Referência:</strong>{" "}
                  {detailsProtehusData.REMOCAO.referencia_destino}
                </p>
                <p>
                  <strong>Bairro:</strong>{" "}
                  {detailsProtehusData.REMOCAO.bairro_destino}
                </p>
                <p>
                  <strong>Cidade:</strong>{" "}
                  {detailsProtehusData.REMOCAO.cidade_destino
                    ? `${detailsProtehusData.REMOCAO.cidade_destino}/
                  ${detailsProtehusData.REMOCAO.estado_destino}`
                    : null}
                </p>
              </Col>
              <Col span={6} offset={2}>
                <p>
                  <strong>Status: </strong>
                  <span>
                    {appointment.hasOwnProperty("Order")
                      ? appointment.Order.BaseService.is_blocked_attendance ===
                        "L"
                        ? "Liberado"
                        : appointment.Order.BaseService
                          .is_blocked_attendance === "A"
                          ? "Agendado"
                          : "Pendente"
                      : null}
                  </span>
                </p>
                <p>
                  <Tooltip
                    title="Data e hora que o atendiento acordou com a familia para a
                  remoção primária."
                  >
                    <strong>Data e Hora: </strong>
                  </Tooltip>
                  {appointment.hasOwnProperty("Order")
                    ? appointment.Order.BaseService.unlocked_date
                      ? moment(
                        appointment.Order.BaseService.unlocked_date
                      ).format("DD/MM/YYYY HH:mm")
                      : null
                    : null}
                </p>
                <p>
                  <strong>Rotas de ida e volta:</strong>
                </p>
                <div className="maps">
                  <Button
                    className="buttons"
                    shape="round"
                    type="primary"
                    onClick={() => openMaps(detailsProtehusData.REMOCAO, 1)}
                    icon={<SiGooglemaps />}
                  >
                    Ida
                  </Button>
                  <Button
                    className="buttons"
                    shape="round"
                    type="primary"
                    onClick={() => openMaps(detailsProtehusData.REMOCAO, 2)}
                    icon={<SiGooglemaps />}
                  >
                    Volta
                  </Button>
                </div>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={6}>
                <h3>Remoções Secundárias</h3>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {detailsProtehusData.REMOCOES_EXTRA &&
                  detailsProtehusData.REMOCOES_EXTRA.length !== 0 ? (
                  <Table
                    columns={[
                      {
                        title: "Percurso",
                        dataIndex: "orgdes",
                        render: (value) =>
                          value === "O" ? "Origem" : "Destino",
                      },
                      {
                        title: "Local",
                        dataIndex: "local",
                      },
                      {
                        title: "Cep",
                        dataIndex: "cep",
                      },
                      {
                        title: "Rua",
                        dataIndex: "logradouro",
                      },
                      {
                        title: "N°",
                        dataIndex: "numero",
                      },
                      {
                        title: "Bairro",
                        dataIndex: "bairro",
                      },
                      {
                        title: "Cidade",
                        dataIndex: "cidade",
                      },
                      {
                        title: "Estado",
                        dataIndex: "estado",
                      },
                      {
                        title: "Ações",
                        dataIndex: "",
                        render: (value) => (
                          <img
                            src={mapIcon}
                            className="maps-table"
                            alt="map"
                            height={30}
                            width={40}
                            onClick={() => openMapsSeacrh(value)}
                          />
                        ),
                      },
                    ]}
                    dataSource={detailsProtehusData.REMOCOES_EXTRA.sort(
                      (a, b) => a.seq - b.seq
                    )}
                    size="middle"
                  />
                ) : (
                  <Empty description="Não existem remoções extras para esse atendiemnto." />
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Preparação de corpo" key="PREPARACAO_CORPO">
            <Row>
              <Col span={6}>
                <h2>Estetica</h2>
              </Col>
              <Col span={6} offset={2}>
                <h2>Pertences</h2>
              </Col>
              <Col span={6} offset={2}>
                <h2>Implantes</h2>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                {detailsProtehusData.PREPARCAO_CORPO.ESTETICA.batom ? (
                  <p>
                    <strong>Batom:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.ESTETICA.batom
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.ESTETICA.blush ? (
                  <p>
                    <strong>Blush:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.ESTETICA.blush
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.ESTETICA.po ? (
                  <p>
                    <strong>Pó:</strong>{" "}
                    {checkIcon(detailsProtehusData.PREPARCAO_CORPO.ESTETICA.po)}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.ESTETICA.base ? (
                  <p>
                    <strong>Base:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.ESTETICA.base
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.ESTETICA.sombra ? (
                  <p>
                    <strong>Sombra:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.ESTETICA.sombra
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.ESTETICA
                  .restauracao_facial ? (
                  <p>
                    <strong>Restauracão facial:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.ESTETICA
                        .restauracao_facial
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.ESTETICA.maquiagem ? (
                  <p>
                    <strong>Maquiagem:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.ESTETICA.maquiagem
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.ESTETICA.barba ? (
                  <p>
                    <strong>Barba:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.ESTETICA.barba
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.ESTETICA.bigode ? (
                  <p>
                    <strong>Bigode:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.ESTETICA.bigode
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.ESTETICA.unha ? (
                  <p>
                    <strong>Unhas:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.ESTETICA.unha
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.ESTETICA.cabelo ? (
                  <p>
                    <strong>Cabelo:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.ESTETICA.cabelo
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.ESTETICA.vestimenta ? (
                  <p>
                    <strong>Vestimenta:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.ESTETICA.vestimenta
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.ESTETICA.ornamentacao ? (
                  <p>
                    <strong>Ornamentacão:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.ESTETICA.ornamentacao
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.ESTETICA.observacoes ? (
                  <p>
                    <strong>Observacões:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.ESTETICA.observacoes
                    )}
                  </p>
                ) : null}
              </Col>
              <Col span={6} offset={2}>
                {detailsProtehusData.PREPARCAO_CORPO.PERTENCES.entregar ? (
                  <p>
                    <strong>Entregar:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.PERTENCES.entregar
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.PERTENCES.paleto ? (
                  <p>
                    <strong>Paletó:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.PERTENCES.paleto
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.PERTENCES.camisa ? (
                  <p>
                    <strong>Camisa:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.PERTENCES.camisa
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.PERTENCES.joias ? (
                  <p>
                    <strong>Joias:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.PERTENCES.joias
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.PERTENCES.Pcs_intimas ? (
                  <p>
                    <strong>Peças íntimas:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.PERTENCES.Pcs_intimas
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.PERTENCES.calca ? (
                  <p>
                    <strong>Calça:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.PERTENCES.calca
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.PERTENCES.vestido ? (
                  <p>
                    <strong>Vestido:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.PERTENCES.vestido
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.PERTENCES.blusa ? (
                  <p>
                    <strong>Blusa:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.PERTENCES.blusa
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.PERTENCES.cinto ? (
                  <p>
                    <strong>Cinto:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.PERTENCES.cinto
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.PERTENCES.sapato ? (
                  <p>
                    <strong>Sapatos:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.PERTENCES.sapato
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.PERTENCES.sandalia ? (
                  <p>
                    <strong>Sandálias:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.PERTENCES.sandalia
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.PERTENCES.meia ? (
                  <p>
                    <strong>Meias:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.PERTENCES.meia
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.PERTENCES.gravata ? (
                  <p>
                    <strong>Gravata:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.PERTENCES.gravata
                    )}
                  </p>
                ) : null}
                {detailsProtehusData.PREPARCAO_CORPO.PERTENCES.outros ? (
                  <p>
                    <strong>Outros:</strong>{" "}
                    {checkIcon(
                      detailsProtehusData.PREPARCAO_CORPO.PERTENCES.outros
                    )}
                  </p>
                ) : null}
              </Col>
              <Col span={6} offset={2}>
                <p>
                  <strong>Marcapasso:</strong>{" "}
                  {checkIcon(
                    detailsProtehusData.PREPARCAO_CORPO.IMPLANTES.marcapasso
                  )}
                </p>

                <p>
                  <strong>Próteses:</strong>{" "}
                  {checkIcon(
                    detailsProtehusData.PREPARCAO_CORPO.IMPLANTES.protese
                  )}
                </p>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Velorio" key="VELORIO">
            <Row>
              <Col span={8}>
                <h2>Velório 01</h2>
              </Col>
              <Col span={8} offset={3}>
                <h2>Velório 02</h2>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <p>
                  <strong>Religião:</strong>{" "}
                  {detailsProtehusData.FALECIDO.religiao}
                </p>
                <p>
                  <strong>Data início:</strong>{" "}
                  {detailsProtehusData.VELORIO.data_inicio
                    ? `${detailsProtehusData.VELORIO.data_inicio} -
                  ${detailsProtehusData.VELORIO.hora_inicio}`
                    : null}
                </p>
                <p>
                  <strong>Data término:</strong>{" "}
                  {detailsProtehusData.VELORIO.data_fim
                    ? `${detailsProtehusData.VELORIO.data_fim} -
                  ${detailsProtehusData.VELORIO.hora_fim}`
                    : null}
                </p>
                <p>
                  <strong>Data cerimônia:</strong>{" "}
                  {detailsProtehusData.VELORIO.data_cerimonia
                    ? `${detailsProtehusData.VELORIO.data_cerimonia} -
                  ${detailsProtehusData.VELORIO.hora_cerimonia}`
                    : null}
                </p>
                <p>
                  <strong>Velório virtual:</strong>{" "}
                  {detailsProtehusData.VELORIO.velorio_virtual}
                </p>
                <p>
                  <strong>CEP :</strong>{" "}
                  {detailsProtehusData.VELORIO.cep_velorio1}
                </p>
                <p>
                  <strong>Local:</strong>{" "}
                  {detailsProtehusData.VELORIO.local_velorio1}
                </p>
                <p>
                  <strong>Endereço:</strong>{" "}
                  {detailsProtehusData.VELORIO.tipo_endereco_velorio1}{" "}
                  {detailsProtehusData.VELORIO.endereco_velorio1}{" "}
                  <strong>N°</strong>{" "}
                  {detailsProtehusData.VELORIO.numero_end_velorio1}
                </p>
                <p>
                  <strong>Referência:</strong>{" "}
                  {detailsProtehusData.VELORIO.referencia_velorio1}
                </p>
                <p>
                  <strong>Bairro:</strong>{" "}
                  {detailsProtehusData.VELORIO.bairro_velorio1}
                </p>
                <p>
                  <strong>Cidade:</strong>{" "}
                  {detailsProtehusData.VELORIO.cidade_velorio1
                    ? `${detailsProtehusData.VELORIO.cidade_velorio1}/
                  ${detailsProtehusData.VELORIO.estado_velorio1}`
                    : null}
                </p>
                <p>
                  <strong>Capela:</strong>{" "}
                  {detailsProtehusData.VELORIO.capela_velorio1}
                </p>
              </Col>
              <Col span={8} offset={3}>
                <p>
                  <strong> Data início:</strong>{" "}
                  {detailsProtehusData.VELORIO.data_inicio2
                    ? `${detailsProtehusData.VELORIO.data_inicio2} -
                  ${detailsProtehusData.VELORIO.hora_inicio2}`
                    : null}
                </p>
                <p>
                  <strong>Data término:</strong>{" "}
                  {detailsProtehusData.VELORIO.data_fim2
                    ? `${detailsProtehusData.VELORIO.data_fim2} -
                  ${detailsProtehusData.VELORIO.hora_fim2}`
                    : null}
                </p>
                <p>
                  <strong>Data cerimônia:</strong>{" "}
                  {detailsProtehusData.VELORIO.data_cerimonia2
                    ? `${detailsProtehusData.VELORIO.data_cerimonia2} -
                  ${detailsProtehusData.VELORIO.hora_cerimonia2}`
                    : null}
                </p>
                <p>
                  <strong>Velório virtual:</strong>{" "}
                  {detailsProtehusData.VELORIO.velorio_virtual2}
                </p>
                <p>
                  <strong>CEP :</strong>{" "}
                  {detailsProtehusData.VELORIO.cep_velorio2}
                </p>
                <p>
                  <strong>Local:</strong>{" "}
                  {detailsProtehusData.VELORIO.local_velorio2}
                </p>
                <p>
                  <strong>Endereço:</strong>{" "}
                  {detailsProtehusData.VELORIO.tipo_endereco_velorio2}{" "}
                  {detailsProtehusData.VELORIO.endereco_velorio2}{" "}
                  <strong>N°</strong>{" "}
                  {detailsProtehusData.VELORIO.numero_end_velorio2}
                </p>
                <p>
                  <strong>Referência:</strong>{" "}
                  {detailsProtehusData.VELORIO.referencia_velorio2}
                </p>
                <p>
                  <strong>Bairro:</strong>{" "}
                  {detailsProtehusData.VELORIO.bairro_velorio2}
                </p>
                <p>
                  <strong>Cidade:</strong>{" "}
                  {detailsProtehusData.VELORIO.cidade_velorio2
                    ? `${detailsProtehusData.VELORIO.cidade_velorio2}/
                  ${detailsProtehusData.VELORIO.estado_velorio2}`
                    : null}
                </p>
                <p>
                  <strong>Capela:</strong>{" "}
                  {detailsProtehusData.VELORIO.capela_velorio2}
                </p>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Cortejo" key="CORTEJO">
            <Row>
              <Col span={8}>
                <h2>Origem: </h2>
              </Col>
              <Col span={8} offset={3}>
                <h2>Destino: </h2>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <p>
                  <strong>Data:</strong>{" "}
                  {detailsProtehusData.CORTEJO.data
                    ? `${detailsProtehusData.CORTEJO.data} -
                  ${detailsProtehusData.CORTEJO.hora}`
                    : null}
                </p>
                <p>
                  <strong>CEP:</strong> {detailsProtehusData.CORTEJO.cep_origem}
                </p>
                <p>
                  <strong>Local:</strong>{" "}
                  {detailsProtehusData.CORTEJO.local_origem}
                </p>
                <p>
                  <strong>Endereço:</strong>{" "}
                  {detailsProtehusData.CORTEJO.tipo_endereco_origem}{" "}
                  {detailsProtehusData.CORTEJO.endereco_origem}{" "}
                  <strong>N°</strong>{" "}
                  {detailsProtehusData.CORTEJO.numero_end_origem}
                </p>
                <p>
                  <strong>Referência:</strong>{" "}
                  {detailsProtehusData.CORTEJO.referencia_origem}
                </p>
                <p>
                  <strong>Bairro:</strong>{" "}
                  {detailsProtehusData.CORTEJO.bairro_origem}
                </p>
                <p>
                  <strong>Cidade:</strong>{" "}
                  {detailsProtehusData.CORTEJO.cidade_origem
                    ? `${detailsProtehusData.CORTEJO.cidade_origem}/
                  ${detailsProtehusData.CORTEJO.estado_origem}`
                    : null}
                </p>
              </Col>
              <Col span={8} offset={3}>
                <p>
                  <strong>CEP:</strong>{" "}
                  {detailsProtehusData.CORTEJO.cep_destino}
                </p>
                <p>
                  <strong>Local:</strong>{" "}
                  {detailsProtehusData.CORTEJO.local_destino}
                </p>
                <p>
                  <strong>Endereço:</strong>{" "}
                  {detailsProtehusData.CORTEJO.tipo_endereco_destino}{" "}
                  {detailsProtehusData.CORTEJO.endereco_destino}{" "}
                  <strong>N°</strong>{" "}
                  {detailsProtehusData.CORTEJO.numero_end_destino}
                </p>
                <p>
                  <strong>Referência:</strong>{" "}
                  {detailsProtehusData.CORTEJO.referencia_destino}
                </p>
                <p>
                  <strong>Bairro:</strong>{" "}
                  {detailsProtehusData.CORTEJO.bairro_destino}
                </p>
                <p>
                  <strong>Cidade:</strong>{" "}
                  {detailsProtehusData.CORTEJO.cidade_destino
                    ? `${detailsProtehusData.CORTEJO.cidade_destino}/
                  ${detailsProtehusData.CORTEJO.estado_destino}`
                    : null}
                </p>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Translado" key="TRANSLADO">
            <Row>
              <Col span={8}>
                <h2>Origem: </h2>
              </Col>
              <Col span={8} offset={3}>
                <h2>Destino: </h2>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <p>
                  <strong>Data:</strong>{" "}
                  {detailsProtehusData.TRANSLADO.data
                    ? `${detailsProtehusData.TRANSLADO.data}
                  - ${detailsProtehusData.TRANSLADO.hora}`
                    : null}
                </p>
                <p>
                  <strong>CEP:</strong>{" "}
                  {detailsProtehusData.TRANSLADO.cep_origem}
                </p>
                <p>
                  <strong>Local:</strong>{" "}
                  {detailsProtehusData.TRANSLADO.local_origem}
                </p>
                <p>
                  <strong>Endereço:</strong>{" "}
                  {detailsProtehusData.TRANSLADO.tipo_endereco_origem}{" "}
                  {detailsProtehusData.TRANSLADO.endereco_origem}{" "}
                  <strong>N°</strong>{" "}
                  {detailsProtehusData.TRANSLADO.numero_end_origem}
                </p>
                <p>
                  <strong>Referência:</strong>{" "}
                  {detailsProtehusData.TRANSLADO.referencia_origem}
                </p>
                <p>
                  <strong>Bairro:</strong>{" "}
                  {detailsProtehusData.TRANSLADO.bairro_origem}
                </p>
                <p>
                  <strong>Cidade:</strong>{" "}
                  {detailsProtehusData.TRANSLADO.cidade_origem
                    ? `${detailsProtehusData.TRANSLADO.cidade_origem}/
                  ${detailsProtehusData.TRANSLADO.estado_origem}`
                    : null}
                </p>
              </Col>
              <Col span={8} offset={3}>
                <p>
                  <strong>CEP:</strong>{" "}
                  {detailsProtehusData.TRANSLADO.cep_destino}
                </p>
                <p>
                  <strong>Local:</strong>{" "}
                  {detailsProtehusData.TRANSLADO.local_destino}
                </p>
                <p>
                  <strong>Endereço:</strong>{" "}
                  {detailsProtehusData.TRANSLADO.tipo_endereco_destino}{" "}
                  {detailsProtehusData.TRANSLADO.endereco_destino}{" "}
                  <strong>N°</strong>{" "}
                  {detailsProtehusData.TRANSLADO.numero_end_destino}
                </p>
                <p>
                  <strong>Bairro:</strong>{" "}
                  {detailsProtehusData.TRANSLADO.bairro_destino}
                </p>
                <p>
                  <strong>Cidade:</strong>{" "}
                  {detailsProtehusData.TRANSLADO.cidade_destino
                    ? `${detailsProtehusData.TRANSLADO.cidade_destino}/
                  ${detailsProtehusData.TRANSLADO.estado_destino}`
                    : null}
                </p>
                <p>
                  <strong>Referência:</strong>{" "}
                  {detailsProtehusData.TRANSLADO.referencia_destino}
                </p>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Sepultamento/Cremação" key="SEPULTAMENTO">
            <Row>
              <Col>
                <h2>Sepultamento:</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>Data e hora:</strong>
              </Col>
              <Col offset={1}>
                {detailsProtehusData.SEPULTAMENTO.data
                  ? `${detailsProtehusData.SEPULTAMENTO.data} -
                ${detailsProtehusData.SEPULTAMENTO.hora}`
                  : null}
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col>
                <h2>Cremação:</h2>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <p>
                  <strong>Data início:</strong>{" "}
                  {detailsProtehusData.CREMACAO.data_inicio}
                  {detailsProtehusData.CREMACAO.hora_inicio}
                </p>
                <p>
                  <strong>Data término:</strong>{" "}
                  {detailsProtehusData.CREMACAO.data_fim}{" "}
                  {detailsProtehusData.CREMACAO.hora_fim}
                </p>
                <p>
                  <strong>Data cerimônial:</strong>{" "}
                  {detailsProtehusData.CREMACAO.data_cerimonia}{" "}
                  {detailsProtehusData.CREMACAO.hora_fim}
                </p>
                <p>
                  <strong>Cerimonialista:</strong>{" "}
                  {detailsProtehusData.CREMACAO.cerimonialista}
                </p>
              </Col>
              <Col span={6} offset={2}>
                <p>
                  <strong>CEP:</strong> {detailsProtehusData.CREMACAO.cep}
                </p>
                <p>
                  <strong>Local:</strong> {detailsProtehusData.CREMACAO.local}
                </p>
                <p>
                  <strong>Endereço:</strong> {detailsProtehusData.CREMACAO.tipo}{" "}
                  {detailsProtehusData.CREMACAO.endereco} <strong>N°</strong>{" "}
                  {detailsProtehusData.CREMACAO.numero}
                </p>
                <p>
                  <strong>Referência:</strong>{" "}
                  {detailsProtehusData.CREMACAO.referencia}
                </p>
                <p>
                  <strong>Bairro:</strong> {detailsProtehusData.CREMACAO.bairro}
                </p>
                <p>
                  <strong>Cidade:</strong>{" "}
                  {detailsProtehusData.CREMACAO.cidade
                    ? `${detailsProtehusData.CREMACAO.cidade}
                  /${detailsProtehusData.CREMACAO.estado}`
                    : null}
                </p>
              </Col>
              <Col span={6} offset={2}>
                <p>
                  <strong>Nome responsável:</strong>{" "}
                  {detailsProtehusData.CREMACAO.nome_resp_cinzas}
                </p>
                <p>
                  <strong>RG responsável:</strong>{" "}
                  {detailsProtehusData.CREMACAO.rg_resp_cinzas}
                </p>
                <p>
                  <strong>CPF responsável:</strong>{" "}
                  {detailsProtehusData.CREMACAO.cpf_resp_cinzas}
                </p>
                <p>
                  <strong>Entrega das cinzas:</strong>{" "}
                  {detailsProtehusData.CREMACAO.data_entrega_cinzas}{" "}
                  {detailsProtehusData.CREMACAO.hora_entrega_cinza}
                </p>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Observações" key="OBS">
            <Row>
              <Col span={5}>
                <p>
                  <strong>Observacões: </strong>{" "}
                </p>
              </Col>
              <Col>
                <p>{detailsProtehusData.ATENDIMENTO.observacoes ? detailsProtehusData.ATENDIMENTO.observacoes : "Sem observações."}</p>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Reserva Cemitério Externo" key="RESERVA">
                { cemeteryReserve?
                <Row>
                  <Col span={5}>
                  <p>
                    <strong>Cemitério: </strong>{cemeteryReserve?.localization}
                  </p>
                  <p>
                    <strong>Sepultamento em: </strong>{cemeteryReserve?.type}
                  </p>
                  <p>
                  <strong>Data e Hora: </strong>{
                    moment(
                      cemeteryReserve?.reserve_date
                      ).format("DD/MM/YYYY HH:mm")}
                  </p>
                  </Col>
                </Row>:
                <Row justify="center">
                  <Empty  description="Não há Reservas"/>
                </Row>
                }
          </TabPane>
        </Tabs>
      ) : (
        <Empty description="Dados não localizados" />
      )}
    </Modal>
  );
}

export default ModalProtheusData;
