import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

import JoditEditor from "jodit-react";

/**
 * Exemplos interessantes para o editor nesse link
 * https://xdsoft.net/jodit/play.html?uploader%5BinsertImageAsBase64URI%5D=true&showCharsCounter=false&showWordsCounter=false&showXPathInStatusbar=false&currentTab=Buttons
 */

function Editor(props) {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const documents = useSelector((state) => state.templateDocuments);

  const config = {
    readonly: documents.readonly,
    height: 500,
    enter: "br",
    language: "pt_br",
    buttons:
      "bold,strikethrough,underline,italic,eraser,superscript,subscript,ul,ol,outdent,indent,font,fontsize,brush,paragraph,image,file,video,table,link,align,undo,redo,br,selectall,cut,copy,paste,copyformat,hr,symbol,fullsize,print,preview,find",
  };

  if (props.minimalButtons) {
    config.buttons =
      "bold,underline,italic,ul,ol,outdent,indent,font,fontsize,image,link,br";
  }

  useEffect(() => {
    if (props.html && props.html.html) {
      setContent(props.html.html.replaceAll("\n", "<br>"));
    }
  }, []);

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      tabIndex={1}
      onChange={(newContent) =>
        newContent ? props.handleHtml(newContent) : false
      }
    />
  );
}

export default Editor;
