import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.css";

import { Form, Input, Button, message, Popconfirm, Spin, Switch } from "antd";
import {
  CloseOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import TableForm from "../../../../../../components/TableForm";

import {
  addProfile,
  alterProfile,
  updateProfile,
  deleteProfile,
} from "../../../../../../store/ducks/profiles";

import {
  openModalSecondary,
  closeModalSecondary,
} from "../../../../../../store/ducks/modalGlobal";
import { closeModal } from "../../../../../../store/ducks/modalGlobal";

import {
  addProfileApi,
  updateProfileApi,
  deleteProfileApi,
} from "../../../../../../services/profiles";

import {
  listResourcesApi,
  listResourcesFilterApi,
} from "../../../../../../services/resources";

import { checkControl } from "../../../../../../utils/access_control";

import { SecondaryModalApp } from "../../../../../../components/SecondaryModal";

const validateMessages = {
  required: "${label} é obrigatório!",
};

function FormApp() {
  const signin = useSelector((state) => state.signin);
  const profile = useSelector((state) => state.profiles.profile);
  const modal = useSelector((state) => state.modalGlobal.open_modal);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [page, setPage] = useState(1);
  const [pageTableTwo, setPageTableTwo] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [newDataResource, setNewDataResource] = useState([]);
  const [addButton, setAddButton] = useState(true);
  const [valueInputSearch, setValueInputSearch] = useState("");
  const [count, setCount] = useState(0);
  const [firstTableOne, setFirstTableOne] = useState(
    profile.resources && profile.resources.length !== 0
      ? [...profile.resources]
      : []
  );

  const [form] = Form.useForm();

  useEffect(() => {
    setPage(1);
    setPageTableTwo(1);
    setAddButton(true);
    setFirstTableOne(
      profile.resources && profile.resources.length !== 0
        ? [...profile.resources]
        : []
    );
  }, [profile]);

  useEffect(() => {
    getResources("page");
  }, [page, profile]);

  useEffect(() => {
    getResources("pageTableTwo");
  }, [pageTableTwo]);

  useEffect(() => {
    form.resetFields();
  }, [modal]);

  const getResources = (value) => {
    setLoading(true);

    let offset = 0;

    if (value === "page") {
      offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
    } else {
      offset = pageTableTwo !== 1 ? rowsPerPage * (pageTableTwo - 1) : 0;
    }

    listResourcesApi
      .listResources(rowsPerPage, offset, true)
      .then((response) => {
        if (page !== 1) {
          setResources(response.rows);
          setCount(response.count);
        } else {
          setResources(response.rows);
          setCount(response.count);
        }
        setLoading(false);
      });
  };

  const handleSubmit = (values) => {
    setLoading(true);

    if (!profile.id) {
      addProfileApi
        .addProfile({
          ...values,
          id_resource:
            firstTableOne.length !== 0
              ? firstTableOne.map((item) => item.id)
              : [],
        })
        .then((res) => {
          dispatch(addProfile(res));
          dispatch(alterProfile({}));
          dispatch(closeModal(false));
          setLoading(false);
          message.success("Perfil criado com sucesso!");
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    } else {
      const body = {
        ...profile,
        ...values,
        id_resource: profile.resources.map((item) => item.id),
      };
      updateProfileApi
        .updateProfile(body)
        .then((res) => {
          dispatch(updateProfile(res));
          dispatch(alterProfile({}));
          dispatch(closeModal(false));
          setLoading(false);
          message.success("Perfil alterado com sucesso!");
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    }
  };

  const remove = () => {
    setLoading(true);

    deleteProfileApi
      .deleteProfile(profile.id)
      .then((res) => {
        dispatch(deleteProfile(res));
        dispatch(alterProfile({}));
        dispatch(closeModal(false));
        setLoading(false);
        message.success("Perfil inativado com sucesso!");
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(5);
  }

  async function handleTableTwoChange(pagination) {
    setPageTableTwo(pagination.current);
    setRowsPerPage(5);
  }

  const handleRemoveResource = (data) => {
    const newResources = profile.resources.filter(
      (resource) => resource.id !== data.id
    );

    setFirstTableOne(newResources);
    dispatch(
      alterProfile({
        ...profile,
        resources: [...newResources],
      })
    );
  };

  const handleSetResource = async (values) => {
    if (newDataResource.length === 0) {
      setNewDataResource(values);
    } else {
      const data = [...newDataResource];
      values.map((item) => {
        const isExist = data.some((el) => el.id === item.id);
        if (!isExist) {
          data.push(item);
        }
      });

      setNewDataResource(data);
    }
    setAddButton(false);
  };

  const handleAddResource = async () => {
    const newResourcesProfiles =
      profile.resources && profile.resources.length > 0
        ? [...profile.resources]
        : [];

    newDataResource.map((resource) => {
      const isCheck = newResourcesProfiles.some((el) => el.id === resource.id);
      if (!isCheck) {
        newResourcesProfiles.push(resource);
      }
    });

    setFirstTableOne(newResourcesProfiles);
    dispatch(
      alterProfile({
        ...profile,
        resources: newResourcesProfiles,
      })
    );
    dispatch(closeModalSecondary(false));
    setAddButton(true);
  };

  const openSecondaryModal = () => {
    dispatch(openModalSecondary(true));
  };

  // start filter function

  // buscar os recursos no store do frontend
  const getColumnSearchProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
      <div className="divSearch">
        <Input
          style={{ marginBottom: 8 }}
          placeholder="Digite o recurso..."
          value={valueInputSearch}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setValueInputSearch(e.target.value ? [e.target.value] : "");
          }}
        />

        <div>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys)}
            icon={<SearchOutlined />}
            className="buttonSearch"
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleRemoveSearch()}
            icon={<DeleteOutlined />}
            className="buttonSearch"
          >
            Remover Filtro
          </Button>
        </div>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const handleSearch = (selectedKeys) => {
    if (selectedKeys.length <= 0) {
      return message.error("Digite um recurso para ser filtrado.");
    }

    const index = selectedKeys[0].toLowerCase();

    const filter = profile.resources.filter((item) =>
      item.description.includes(index)
    );

    setFirstTableOne(filter);
  };

  const handleRemoveSearch = () => {
    setValueInputSearch("");
    setFirstTableOne([...profile.resources]);
  };

  // busca os recursos no backend
  const getColumnSearchApi = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
      <div className="divSearch">
        <Input
          style={{ marginBottom: 8 }}
          placeholder="Digite o recurso..."
          value={valueInputSearch}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setValueInputSearch(e.target.value ? [e.target.value] : "");
          }}
        />

        <div>
          <Button
            type="primary"
            onClick={() => handleSearchApi(selectedKeys)}
            icon={<SearchOutlined />}
            className="buttonSearch"
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleRemoveSearchApi()}
            icon={<DeleteOutlined />}
            className="buttonSearch"
          >
            Remover Filtro
          </Button>
        </div>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const handleSearchApi = (value) => {
    if (value.length <= 0) {
      return message.error("Digite um recurso para ser filtrado.");
    }

    const index = value[0].toLowerCase();

    listResourcesFilterApi
      .listResourcesFilter(index)
      .then((response) => {
        if (response.length <= 0) {
          throw {
            message: "Ops... erro ao filtrar recursos, favor tente novamente.",
          };
        }

        setResources(response);
        setCount(response.length);
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const handleRemoveSearchApi = () => {
    getResources("pageTableTwo");
  };

  // end filter function

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Form
        layout="vertical"
        form={form}
        name="form-profiles"
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        initialValues={{
          ["name"]: profile.name,
          ["description"]: profile.description,
          ["id_resource"]: profile.resources
            ? profile.resources.map((resource) => resource.id)
            : [],
        }}
      >
        <Form.Item
          name="name"
          label="Nome"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Descrição"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            size="small"
            style={{ marginBottom: 15 }}
            onClick={openSecondaryModal}
          >
            Adicionar recursos
          </Button>

          <TableForm
            data={firstTableOne}
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            removeData={handleRemoveResource}
            rowSelec={false}
            selectData={null}
            countPages={
              profile.resources && profile.resources.length > 0
                ? profile.resources.length
                : 0
            }
            handleTableChange={handleTableChange}
            columns={[
              {
                title: "Recursos",
                dataIndex: "description",
                ...getColumnSearchProps("description"),
              },
              {
                render: () => (
                  <Button type="text" size="small">
                    <CloseOutlined />
                  </Button>
                ),
              },
            ]}
            resources={true}
          />
        </Form.Item>

        <div className="buttonsClass">
          {profile.id && !profile.active ? (
            <Form.Item name="active" valuePropName="checked">
              <Switch checkedChildren="Ativo" unCheckedChildren="Inativo" />
            </Form.Item>
          ) : null}

          <Form.Item>
            <Fragment>
              {profile.active ? (
                <Popconfirm
                  title="Deseja inativar o perfil?"
                  onConfirm={remove}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Button
                    type="danger"
                    style={checkControl(
                      signin.user.profiles,
                      "inactivate_profile"
                    )}
                  >
                    Inativar
                  </Button>
                </Popconfirm>
              ) : null}
            </Fragment>

            <Button
              type="primary"
              htmlType="submit"
              style={{
                ...checkControl(
                  signin.user.profiles,
                  "save_and_update_profile"
                ),
                marginLeft: 10,
              }}
            >
              Salvar
            </Button>
          </Form.Item>
        </div>
      </Form>

      <SecondaryModalApp
        title="Escolha os recursos"
        width={600}
        footer={null}
        bodyStyle={true}
        height={370}
      >
        <div id="tableForm">
          <TableForm
            data={resources}
            loading={loading}
            dataDefault={profile.resources}
            rowSelec={true}
            page={pageTableTwo}
            rowsPerPage={rowsPerPage}
            removeData={null}
            selectData={handleSetResource}
            countPages={count}
            handleTableChange={handleTableTwoChange}
            columns={[
              {
                title: "Selecionar todos",
                dataIndex: "description",
                ...getColumnSearchApi("description"),
              },
            ]}
          />
        </div>

        <Button
          onClick={handleAddResource}
          type="primary"
          disabled={addButton}
          style={{ float: "right" }}
        >
          Adicionar
        </Button>
      </SecondaryModalApp>
    </Spin>
  );
}

export default FormApp;
