import { Col, Row } from "antd";
import { useSelector } from "react-redux";

import Main from "../../pages/Layout/Main";
import { checkControl } from "../../utils/access_control";
import Cards from "../card/card";
import MenuComponent from "../MenuComponent";

// Configs add que podem ser colocadas : itensDrawer?, selectedMenu?, breadcrumb,

function MenuCard(props) {

  const sortData = (data) => {
    return data.sort((a, b) => {
      if(a.title == "Início" || b.title == "Início"){
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      if (a.title < b.title) {
        return -1;
      }
      return 0;
    });
  };

  const itens = sortData(props.itensMenu)

  console.log(itens)

  const signin = useSelector((state) => state.signin);

  return (
    <Main
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          modulePath={props.modulePath}
          itensMenu={props.itensDrawer ? props.itensDrawer : props.itensMenu}
          selectedMenu={props.selectedMenu ? props.selectedMenu : "0"}
        />
      }
      breadcrumb={props.breadcrumb}
      displayMenuButtons={"none"}
    >
      <Row gutter={[25, 25]}>
        {itens.map((itemMenu, index) => {
          return (
            <Col
              style={{
                ...checkControl(signin.user.profiles, itemMenu.permission),
              }}
            >
              {Cards(itemMenu, props.modulePath)}
            </Col>
          );
        })}
      </Row>
    </Main>
  );
}
export default MenuCard;
