import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const SettingServicesService = {
  getSettingServiceFilterParams: async (params = {}) => {
    return await getRequest(`/setting_service_by_sector`, {
      params,
    });
  },
  getSettingServices: async (limit, offset, active, company_id, param = "") => {
    return await getRequest(
      `/setting_services?limit=${limit}&offset=${offset}&active=${active}&company_id=${company_id}${param}`,
      {}
    );
  },
  postSettingServices: async (data) => {
    return await postRequest(`/setting_services`, data);
  },
  putSettingServices: async (data) => {
    return await putRequest(`/setting_services?id=${data.id}`, data);
  },
  deleteSettingServices: async (id, active) => {
    return await deleteRequest(
      `/setting_services?id=${id}&active=${active}`,
      {}
    );
  },

  getSettingServiceById: async (id) => {
    return await getRequest(`/setting/service/${id}`, {});
  },
};
