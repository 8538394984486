import {
  useState,
  useEffect,
} from 'react';
import {
  Spin,
  Form,
  Row,
  Col,
  Button,
  Select,
  Input,
  message,
  Table,
  Tag,
  Popconfirm,
  Divider,
} from "antd";
import api from '../../../../../../utils/apiNotification';

import {
  DeleteOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import ImportReceiver from './components/ImportReceiver';

const FormNotification = (props) => {
  const [loading, setLoading] = useState(false);
  const [applicationSelected, setApplicationSelected] = useState(null);
  const [applications, setApplications] = useState({count: 0, rows: []});
  const [limitApplications, setLimitApplications] = useState(10);
  const [pageApplications, setPageApplications] = useState(1);
  const [receivers, setReceivers] = useState([]);
  const [pageReceivers, setPageReceivers] = useState(1);
  const [limitReceivers, setLimitReceivers] = useState(5);
  const [loadingReceivers, setLoadingReceivers] = useState(false);
  const [userSelected, setUserSelected] = useState("");
  const [users, setUsers] = useState([]);

  const [formNotification] = Form.useForm();

  useEffect(() => {
    getApplications()
  }, []);

  useEffect(() => {
    formNotification.setFieldsValue(props.notification);

    if (props.notification.receivers) {
      setReceivers(props.notification.receivers);
    }
  }, [props.notification]);

  const getApplications = async () => {
    try {

      const offset = pageApplications !== 1 ? limitApplications * (pageApplications - 1) : 0;

      const result = await api.get("/applications", {
        params: {
          limit: limitApplications,
          offset
        }
      })

      setApplications(result.data);
    } catch (error) {

      message.error("Não foi possível buscar as aplicações.")  
    }
  }

  const onFinish = async (values) => {
    try {
      
      //Caso for alteração entra no if, se não, entra no else para cadastrar.
      if (props.notification?.id) {
        const data = {
          id: props.notification?.id,
          data: {
            ...values,
            receivers,
          }
        }
        
        const result = await api.put("/notifications/update", data);
      } else {
        const data = {
          data: {
            ...values,
          },
          receivers,
        }
        
        const result = await api.post("/notifications/create", data);
      }

      props.setShowDrawer(false);
      props.setReloadIndex(true);
      message.success(`Notificação ${props.notificaiton?.id ? 'alterada' : 'cadatrada'} com sucesso!`);
    } catch (error) {
      message.error(`Erro ao ${props.notificaiton?.id ? 'alterar' : 'registrar'} a notificação!`);
    }
  }

  const columns = [
    {
      title: "Código",
      dataIndex: "user_code",
      key: "user_code",
    },
    {
      title: "Nome",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "E-mail",
      dataIndex: "user_email",
      key: "user_email",
    },
    {
      title: "Situação",
      render: (data) => (
        <Popconfirm
          title="Deseja alterar o status do usuário?"
          onConfirm={() => handlerActive(data)}
          okText="Sim"
          cancelText="Não"
        >
          <Button type="text" shape="circle" ghost size="small">
            <Tag color={data.active === true ? "green" : "volcano"}>{data.active ? "Ativo" : "Inativo"}</Tag>
          </Button>
        </Popconfirm>
      )
    },
    /*{
      title: "Ações",
      render: (data) => (
        <Popconfirm
          title="Deseja remover o campo?"
          onConfirm={() => handleRemoveReceiver(data)}
          okText="Sim"
          cancelText="Não"
        >
          <Button type="danger" shape="circle" ghost size="small">
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
      align: "right",
    },*/
  ];

  const handlerActive = (data) => {
    const dataReceivers = receivers.map(receiver => {
      if (receiver.user_code === data.user_code) {
        receiver.active = !receiver.active;
      }

      return receiver;
    });

    setReceivers(dataReceivers);
  }

  const handleRemoveReceiver = (data) => {
    try {
      setLoadingReceivers(true);
      setReceivers(receivers.filter(receiver => receiver.user_code !== data.user_code));

    } catch (error) {
      message.error(error);
    } finally {
      setLoadingReceivers(false);
    }
  }

  return (
    <Spin spinning={loading} tip="Registrando notificação...">
      <Form
        name="form-notification"
        form={formNotification}
        onFinish={onFinish}
        layout="vertical"
        wrapperCol={{
          span: 24,
        }}
      >
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <Form.Item
              name="title"
              label="Título da notificação:">
              <Input
                placeholder="Digite o título da notificação"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <Form.Item
              name="body"
              label="Descrição da notificação:">
              <Input.TextArea
                rows={4}
                placeholder="Digite a descrição da notificação"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={12}>
            <Form.Item
              name="application_code"
              label="Aplicação:">
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Selecione o usuário"
                value={applicationSelected}
                onChange={(value) => setApplicationSelected(value)}
                style={{ width: "100%" }}
              >
                {
                  applications.rows.map(application => {
                    return <Select.Option key={application.id} value={application.code}>{application.name}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="action"
              label="Ação ao clicar na notificação:">
              <Input
                placeholder="Digite a ação ao clicar na notificação"
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider prefixCls='Teste' style={{ marginTop: "5px", marginBottom: "5px" }} />
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <ImportReceiver 
              receivers={receivers}
              setReceivers={setReceivers}
              loading={loadingReceivers}
              setLoading={setLoadingReceivers}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <Table
              size="small"
              columns={columns}
              dataSource={receivers}
              rowKey={(record) => record.sequence}
              loading={loading}
              pagination={{
                current: pageReceivers,
                pageSize: limitReceivers,
                total: receivers.length,
                showSizeChanger: true,
                onShowSizeChange: function (current, size) {
                  limitReceivers(size);
                },
                pageSizeOptions: [5, 10, 50, 100],
              }}
              onChange={(pagination) =>
                setPageReceivers(pagination.current)
              }
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]} justify="end">
          <Col>
            <Form.Item
              label=" "
              colon={false}
            >
              <Button type="primary" htmlType="submit">
                {(props.notification?.id) ? 'Alterar' : 'Cadastrar'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  )
}

export default FormNotification;