import { isObjectEmpty } from '../../utils/text';

const Types = {
  GET_TEMPLATES: "GET_TEMPLATES",
  ADD_TEMPLATE_EMAIL: "ADD_TEMPLATE_EMAIL",
  SET_TEMPLATE_MAIL: "SET_TEMPLATE_MAIL",
  UPDATE: "UPDATE",
  DELETE_TEMPLATE_MAIL: "DELETE_TEMPLATE_MAIL",
  SET_CONTENT: "SET_CONTENT"
};

const INITIAL_STATE = {
  list: [],
  count: 0,
  templateMail: {
    id: 0,
    name: "",
    identifier: "",
    content: ""
  }
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_TEMPLATES:
      return {
        ...state,
        list: action.payload.rows,
        count: action.payload.count,
      }
    case Types.SET_TEMPLATE_MAIL:
      return {
        ...state,
        templateMail: {
          ...action.payload,
        },
      }
    case Types.ADD_TEMPLATE_EMAIL:
      return {
        ...state,
        list: action.payload.rows,
        count: action.payload.count,
      }
    case Types.REMOVE:
      return {
        ...state,
        list: action.payload.rows,
        count: action.payload.count,
      };
    case Types.UPDATE:
      return {
        ...state,
        list: action.payload.rows,
        count: action.payload.count,
      };
    case Types.SET_CONTENT:
      state.templateMail.content = action.payload
      return {
        ...state,
      }
    default:
      return state;
  }
}

export function setContent(data) {
  return {
    type: Types.SET_CONTENT,
    payload: data
  }
}

export function updateTemplateMail(data) {
  return {
    type: Types.UPDATE,
    payload: data
  }
}

export function list(data) {
  return {
    type: Types.GET_TEMPLATES,
    payload: data,
  };
}

export function addTemplateEmail(data) {
  return {
    type: Types.ADD_TEMPLATE_EMAIL,
    payload: data,
  };
}

export function alterTemplateMail(data) {
  return {
    type: Types.SET_TEMPLATE_MAIL,
    payload: isObjectEmpty(data) ? INITIAL_STATE.templateMail : data
  }
}

export function deleteTemplateMail(data) {
  return {
    type: Types.DELETE_TEMPLATE_MAIL,
    payload: data,
  };
}