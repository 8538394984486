import {
  useState,
  useEffect,
  useContext,
} from 'react';

import {
  Row,
  Col,
  Select,
  Table,
  Button,
  message,
  Space,
} from 'antd';

import {
  DeleteOutlined
} from '@ant-design/icons';

import { listCompanyApi } from "../../../../../../../../services/companies";
import { UserContext } from "../../../../../../../../Context/userContext";

const Company = (props) => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [limit, setLimit] = useState(3);
  const [offset, setOffset] = useState(1);
  const [selectCompany, setSelectCompany] = useState(null);

  const userContext = useContext(UserContext);

  useEffect(() => {
    getCompanies();
  }, []);

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
    },
    {
      title: 'Descrição',
      dataIndex: 'name',
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'right',
      render: (data) => (
        <Space size="middle">
          <Button
            type="text"
            onClick={() => deleteUserCompany(data.id)}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Button>
        </Space>
      ),
    },
  ]

  const getCompanies = async () => {
    setLoading(true);

    try {
      const result = await listCompanyApi
        .listCompany(true)

      const temp = result.rows.reduce(
        function (acumulador, valorAtual) {
          return acumulador.concat(valorAtual.children)
        },
        []
      );

      setCompanies(temp);

    } catch (error) {
      message.error("Não foi possível buscar as empresas");
    } finally {
      setLoading(false);
    }
  }

  const handleTableChange = (pagination) => {
    setOffset(pagination.current);
  }

  const deleteUserCompany = async (id) => {
    const filtered = await userContext.userCompanies.filter(function (el) { return el.id !== id; });

    userContext.setUserCompanies(filtered);
  }

  const handleAddCompany = async () => {
    try {
      if (!selectCompany)
        throw "É necessário selecionar a empresa que deseja incluir."

      const companyFiltered = await companies.find(company => company.id === selectCompany)

      const findCompanyInUserCompanies = await userContext.userCompanies.filter(userCompany => userCompany.id === selectCompany);

      if (findCompanyInUserCompanies.length > 0)
        throw "Perfil já cadastrado";

      await userContext.userCompanies.push(companyFiltered);

      setSelectCompany(null)
      userContext.setUserCompanies([...userContext.userCompanies])
    } catch (error) {
      message.info(error)
    }

  }

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={20}>
          <Select
            allowClear
            //showSearch
            value={selectCompany}
            style={{ width: "100%" }}
            placeholder="Selecione a empresa"
            optionFilterProp="children"
            loading={loading}
            showArrow={true}
            onChange={(e) => setSelectCompany(e)}
          >
            {
              companies.length !== 0 ?
                companies.map(company => {
                  return <Select.Option key={company.id} value={company.id}>{company.name}</Select.Option>
                })
                :
                <></>
            }
          </Select>
        </Col>
        <Col span={4}>
          <Button
            style={{ width: "100%" }}
            onClick={handleAddCompany}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ marginTop: "10px" }}>
        <Col span={24}>
          {
            <Table
              columns={columns}
              dataSource={userContext.userCompanies}
              pagination={{
                current: offset,
                pageSize: limit,
                total: userContext.userCompanies ? userContext.userCompanies?.length : 0,
              }}
              size="small"
              onChange={handleTableChange}
            />
          }
        </Col>
      </Row>
    </>
  );
}

export default Company;