import React, { useState, useEffect } from "react";
import "./styles.css";

import { useSelector, useDispatch } from "react-redux";
import { message, Tag, Switch, Button, Input } from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet";

import { listProfile } from "../../../../store/ducks/profiles";
import {
  listProfileApi,
  filterProfileApi,
} from "../../../../services/profiles";

import { openModal } from "../../../../store/ducks/modalGlobal";
import { alterProfile } from "../../../../store/ducks/profiles";

import Main from "../../../Layout/Main";
import MainCrud from "../../../../components/MainCrud";
import FormApp from "./components/Form";
import { ModalApp } from "../../../../components/Modal";

import { checkControl } from "../../../../utils/access_control";
import { isOwner } from "../../../../utils/owner";
import MenuComponent from "../../../../components/MenuComponent";
import { itensDrawer } from "../itens-menu";

function Profiles(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [valueInputSearch, setValueInputSearch] = useState("");

  const signin = useSelector((state) => state.signin);
  const profiles = useSelector((state) => state.profiles);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!checked) {
      list(true);
    } else {
      list(false);
    }
  }, [page, rowsPerPage, profiles.profile]);

  useEffect(() => {
    setPage(1);
  }, [checked]);

  async function list(active) {
    try {
      setLoading(true);

      const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
      listProfileApi
        .listProfile(rowsPerPage, offset, active)
        .then((response) => {
          dispatch(listProfile(response));
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      message.error("Erro ao carregar dados!");
    }
  }

  // filtrar os usuários no backend
  const getColumnSearchApi = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
      <div className="divSearch">
        <Input
          style={{ marginBottom: 8 }}
          placeholder="Pesquisar..."
          value={valueInputSearch}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setValueInputSearch(e.target.value ? [e.target.value] : "");
          }}
        />

        <div>
          <Button
            type="primary"
            onClick={() => handleSearchApi(selectedKeys)}
            icon={<SearchOutlined />}
            className="buttonSearch"
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              setValueInputSearch("");
              list(true);
            }}
            icon={<DeleteOutlined />}
            className="buttonSearch"
          >
            Remover Filtro
          </Button>
        </div>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const handleSearchApi = (value) => {
    setLoading(true);

    filterProfileApi
      .filterProfile(value)
      .then((response) => {
        dispatch(
          listProfile({
            rows: response,
            count: response.length,
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const activeSwitch = (checked) => {
    if (checked) {
      list(false);
      setChecked(true);
      message.info("Exibindo perfis inativos!");
    } else {
      list(true);
      setChecked(false);
      message.info("Exibindo perfis ativos!");
    }
  };

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(10);
  }

  const handleOpenModal = (data) => {
    if (data && data.name && data.name.includes("Owner")) {
      if (!isOwner(signin.user.profiles)) {
        return false;
      }
    }

    dispatch(openModal(true));
    dispatch(
      alterProfile({
        ...data,
      })
    );
  };

  const cleanStoreComponent = (value) => {
    dispatch(alterProfile(value));
  };

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Perfis",
      path: props.modulePath + "/profiles",
    },
  ];

  let columns = [
    {
      title: "Nome",
      dataIndex: "name",
      ...getColumnSearchApi(),
    },
    {
      title: "Descrição",
      dataIndex: "description",
    },
    {
      title: "Situação",
      dataIndex: "active",
      render: (active) => {
        let color = "";
        if (active === true) {
          color = "green";
        } else {
          color = "volcano";
        }
        return <Tag color={color}>{active ? "Ativo" : "Inativo"}</Tag>;
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Perfis - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="3"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <>
            <span
              style={checkControl(
                signin.user.profiles,
                "check_profiles_inactivate"
              )}
            >
              <Switch
                checkedChildren="Inativos"
                unCheckedChildren="Ativos"
                onChange={activeSwitch}
                style={{ marginRight: "5px", marginTop: "5px" }}
              />
            </span>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={handleOpenModal}
              style={{
                ...checkControl(signin.user.profiles, "insert_profile"),
                marginLeft: "20px",
              }}
            >
              Novo
            </Button>
          </>
        }
      >
        <MainCrud
          columnsTable={columns}
          dataSource={profiles.profileList}
          loading={loading}
          page={page}
          rowsPerPage={rowsPerPage}
          countPages={profiles.profileListCount}
          handleTableChange={handleTableChange}
          handleOpenModal={handleOpenModal}
          profiles={signin.user.profiles}
          displayMainCrud="none"
          classTag={[]}
        />

        <ModalApp
          title={profiles.profile.id ? profiles.profile.name : "Novo perfil"}
          width={1000}
          footer={null}
          cleanStoreComponent={cleanStoreComponent}
        >
          <FormApp />
        </ModalApp>
      </Main>
    </>
  );
}

export default Profiles;
