import axios from "axios";

export const treeProducts = [
  {
    value: '0',
    label: 'Morada da Paz',
    children: [
      {
        value: '0-1',
        label: 'Funerária',
        children: [
          {
            value: 'funeral',
            label: 'Funeral de Uso Futuro',
          },
          {
            value: 'atendimento_imediato',
            label: 'Atendimento Imediato',
          },
          {
            value: 'alugsalavelmorada',
            label: 'Aluguel da Sala de Velório',
          },
          {
            value: 'traslnacintmorada',
            label: 'Traslado Nacional/Internacional',
          },
        ],
      },
      {
        value: '0-2',
        label: 'Cemitério',
        children: [
          {
            value: 'jazigo',
            label: 'Jazigo',
          },
          {
            value: 'modulo',
            label: 'Módulo Individual',
          },
          {
            value: 'nicho',
            label: 'Nicho',
          },
          {
            value: 'columbario_familiar',
            label: 'Columbário Familiar',
          },
          {
            value: 'columbario_individual',
            label: 'Columbário Individual',
          },
          {
            value: 'cersepmorada',
            label: 'Cerimônia de Sepultamento',
          },
          {
            value: 'exummorada',
            label: 'Exumação',
          },
        ],
      },
      {
        value: '0-3',
        label: 'Cremação',
        children: [
          {
            value: 'cremacao',
            label: 'Cremação de Corpo',
          },
          {
            value: 'restos',
            label: 'Cremação de Restos',
          },
        ],
      },
    ],
  },
  {
    value: '1',
    label: 'Morada Essencial',
    children: [
      {
        value: '1-1',
        label: 'Plano',
        children: [
          {
            value: 'essencial1-2022',
            label: 'Morada Essencial 01',
          },
          {
            value: 'essencial2-2022',
            label: 'Morada Essencial 02',
          },
          {
            value: 'essencial3-2022',
            label: 'Morada Essencial 03',
          },
          {
            value: 'essencial4-2022',
            label: 'Morada Essencial 04',
          },
          {
            value: 'essencial5-2022',
            label: 'Morada Essencial 05',
          },
        ],
      },
      {
        value: '1-2',
        label: 'Funerária',
        children: [
          {
            value: 'alugsalavelessencial',
            label: 'Aluguel da Sala de Velório',
          },
          {
            value: 'traslnacintessencial',
            label: 'Traslado Nacional/Internacional',
          },
        ],
      },
      {
        value: '1-3',
        label: 'Cemitério',
        children: [
          {
            value: 'loc_modulo_imediato',
            label: 'Locação de Módulo Individual - Imediato',
          },
          {
            value: 'cersepessencial',
            label: 'Cerimônia de Sepultamento',
          },
          {
            value: 'exumessencial',
            label: 'Exumação',
          },
          {
            value: 'nicho1',
            label: 'Nicho',
          },
        ],
      },
    ],
  },
  {
    value: '2',
    label: 'Morada Pet',
    children: [
      {
        value: '2-1',
        label: 'Cremação',
        children: [
          {
            value: 'petfu',
            label: 'Cremação Pet - Uso Futuro',
          },
          {
            value: 'petim',
            label: 'Cremação Pet - Uso Imediato',
          },
        ],
      },
      {
        value: '2-2',
        label: 'Pingentes',
        children: [
          {
            value: 'petac1',
            label: 'Pingente patinha com corrente',
          },
          {
            value: 'petac2',
            label: 'Pingente corações com corrente',
          },
          {
            value: 'petac3',
            label: 'Pingente elos com corrente',
          },
          {
            value: 'petac4',
            label: 'Pingente cachorro com corrente',
          },
          {
            value: 'petac5',
            label: 'Pingente gato com corrente',
          },
        ],
      },
    ],
  },
];

export const treeCanal = [
  {
    title: "Online",
    value: "0",
    disabled: true,
    children: [
      {
        title: "Internet / Instagram",
        value: "181719",
      },
      {
        title: "Internet / Facebook",
        value: "181716",
      },
      {
        title: "Internet / Google Meu Negócio",
        value: "181718",
      },
      {
        title: "Internet / E-mail",
        value: "181715",
      },
      {
        title: "Internet / WhatsApp",
        value: "181722",
      },
      {
        title: "Internet / Formulário do Site",
        value: "181713",
      },
      {
        title: "Internet / Simulador Morada",
        value: "181726",
      },
      {
        title: "Internet / Funerária Digital",
        value: "181717",
      },
      {
        title: "Internet / RD Station",
        value: "225124",
      },
      {
        title: "Internet / E-commerce",
        value: "181714",
      },
      {
        title: "Internet / Campanha Promocional",
        value: "235329",
      },
      {
        title: "Remarketing / Base ativa",
        value: "266131",
      },
    ],
  },
  {
    title: "Offline",
    value: "1",
    disabled: true,
    children: [
      {
        title: "Telefone",
        value: "181727",
      },
      {
        title: "Telefone / Já é cliente",
        value: "181732",
      },
      {
        title: "Receptivo / Presencial",
        value: "203703",
      },
      {
        title: "Prospecção / Ação off-line",
        value: "203721",
      },
      {
        title: "Prospecção / Convênio",
        value: "203723",
      },
      {
        title: "Prospecção / Indicação",
        value: "203724",
      },
      {
        title: "Prospecção / Já é cliente",
        value: "203725",
      },
      {
        title: "Prospecção / Plantão",
        value: "203726",
      },
      {
        title: "Prospecção / Venda direta",
        value: "203727",
      },
      {
        title: "Banco Clientes Ativos",
        value: "207976",
      },
      {
        title: "Telefone / Campanha Promocional",
        value: "235330",
      },
    ],
  },
];

export const getCompanies = (value) => {
  var options = [];

  switch (value) {
    case "prever":
      options = [
        {
          id: "3707922",
          company: "Morada Emaús",
        },
        {
          id: "3707915",
          company: "Morada João Pessoa",
        },
        {
          id: "3707921",
          company: "Morada Paulista",
        },
      ];
      break;
    case "nicho":
      options = [
        {
          id: "3707921",
          company: "Morada Paulista",
        },
        {
          id: "3707931",
          company: "Morada Zona Norte",
        },
        {
          id: "3707923",
          company: "Morada Mipibu",
        },
      ];
      break;
    case "nicho1":
      options = [
        {
          id: "3707931",
          company: "Morada Zona Norte",
        },
        {
          id: "3828631",
          company: "Essencial Guarabira",
        },
      ];
      break;
    case "modulo":
      options = [
        {
          id: "3707921",
          company: "Morada Paulista",
        },
        {
          id: "3707931",
          company: "Morada Zona Norte",
        },
        {
          id: "3707923",
          company: "Morada Mipibu",
        },
      ];
      break;
    case "cremacao":
      options = [
        {
          id: "3707922",
          company: "Morada Emaús",
        },
        {
          id: "3707915",
          company: "Morada João Pessoa",
        },
        {
          id: "3707921",
          company: "Morada Paulista",
        },
      ];
      break;
    case "restos":
      options = [
        {
          id: "3707922",
          company: "Morada Emaús",
        },
        {
          id: "3707915",
          company: "Morada João Pessoa",
        },
        {
          id: "3707921",
          company: "Morada Paulista",
        },
      ];
      break;
    case "jazigo":
      options = [
        {
          id: "3707922",
          company: "Morada Emaús",
        },
        {
          id: "3707931",
          company: "Morada Zona Norte",
        },
        {
          id: "3707923",
          company: "Morada Mipibu",
        },
        {
          id: "3707921",
          company: "Morada Paulista",
        },
        {
          id: "3828631",
          company: "Essencial Guarabira"

        },
      ];
      break;
    case "funeral":
      options = [
        {
          id: "3707922",
          company: "Morada Emaús",
        },
        {
          id: "3707915",
          company: "Morada João Pessoa",
        },
        {
          id: "3707921",
          company: "Morada Paulista",
        },
      ];
      break;
    case "atendimento_imediato":
      options = [
        {
          id: "3707922",
          company: "Morada Emaús",
        },
        {
          id: "3707923",
          company: "Morada Mipibu",
        },
        {
          id: "3707915",
          company: "Morada João Pessoa",
        },
        {
          id: "3707921",
          company: "Morada Paulista",
        },
        {
          id: "3828631",
          company: "Essencial Guarabira",
        },
      ];
      break;
    case "columbario_familiar":
      options = [
        {
          id: "3707922",
          company: "Morada Emaús",
        },
        {
          id: "3707921",
          company: "Morada Paulista",
        },
      ];
      break;
    case "columbario_individual":
      options = [
        {
          id: "3707922",
          company: "Morada Emaús",
        },
        {
          id: "3707921",
          company: "Morada Paulista",
        },
      ];
      break;
    case "alugsalavelmorada":
      options = [
        {
          id: "3707922",
          company: "Morada Emaús",
        },
        {
          id: "3707931",
          company: "Morada Zona Norte",
        },
        {
          id: "3707923",
          company: "Morada Mipibu",
        },
        {
          id: "3707921",
          company: "Morada Paulista",
        },
        {
          id: "3707915",
          company: "Morada João Pessoa",
        },
      ];
      break;
    case "traslnacintmorada":
      options = [
        {
          id: "3707922",
          company: "Morada Emaús",
        },
        {
          id: "3707921",
          company: "Morada Paulista",
        },
        {
          id: "3707915",
          company: "Morada João Pessoa",
        },
      ];
      break;
    case "cersepmorada":
      options = [
        {
          id: "3707922",
          company: "Morada Emaús",
        },
        {
          id: "3707931",
          company: "Morada Zona Norte",
        },
        {
          id: "3707923",
          company: "Morada Mipibu",
        },
        {
          id: "3707921",
          company: "Morada Paulista",
        },
        {
          id: "3707915",
          company: "Morada João Pessoa",
        },
      ];
      break;
    case "exummorada":
      options = [
        {
          id: "3707922",
          company: "Morada Emaús",
        },
        {
          id: "3707931",
          company: "Morada Zona Norte",
        },
        {
          id: "3707923",
          company: "Morada Mipibu",
        },
        {
          id: "3707921",
          company: "Morada Paulista",
        },
        {
          id: "3707915",
          company: "Morada João Pessoa",
        },
      ];
      break;
    case "petfu":
      options = [
        {
          id: "3707921",
          company: "Morada Paulista",
        },
      ];
      break;
    case "petim":
      options = [
        {
          id: "3707921",
          company: "Morada Paulista",
        },
      ];
      break;
    case "petac1":
      options = [
        {
          id: "3707921",
          company: "Morada Paulista",
        },
        {
          id: "3707922",
          company: "Morada Emaús",
        },
      ];
      break;
    case "petac2":
      options = [
        {
          id: "3707921",
          company: "Morada Paulista",
        },
        {
          id: "3707922",
          company: "Morada Emaús",
        },
      ];
      break;
    case "petac3":
      options = [
        {
          id: "3707921",
          company: "Morada Paulista",
        },
        {
          id: "3707922",
          company: "Morada Emaús",
        },
      ];
      break;
    case "petac4":
      options = [
        {
          id: "3707921",
          company: "Morada Paulista",
        },
        {
          id: "3707922",
          company: "Morada Emaús",
        },
      ];
      break;
    case "petac5":
      options = [
        {
          id: "3707921",
          company: "Morada Paulista",
        },
        {
          id: "3707922",
          company: "Morada Emaús",
        },
      ];
      break;     
    case "essencial1-2022":
      options = [
        {
          id: "3826600",
          company: "Essencial Recife",
        },
        {
          id: "3826595",
          company: "Essencial João Pessoa",
        },
        {
          id: "3828631",
          company: "Essencial Guarabira",
        },
        {
          id: "4247499",
          company: "Essencial Natal",
        },
      ];
      break;
    case "essencial2-2022":
      options = [
        {
          id: "3826600",
          company: "Essencial Recife",
        },
        {
          id: "3826595",
          company: "Essencial João Pessoa",
        },
        {
          id: "3828631",
          company: "Essencial Guarabira",
        },
        {
          id: "4247499",
          company: "Essencial Natal",
        },
      ];
      break;
    case "essencial3-2022":
      options = [
        {
          id: "3826600",
          company: "Essencial Recife",
        },
        {
          id: "3826595",
          company: "Essencial João Pessoa",
        },
        {
          id: "3828631",
          company: "Essencial Guarabira",
        },
        {
          id: "4247499",
          company: "Essencial Natal",
        },
      ];
      break;
    case "essencial4-2022":
      options = [
        {
          id: "3826600",
          company: "Essencial Recife",
        },
        {
          id: "3826595",
          company: "Essencial João Pessoa",
        },
        {
          id: "3828631",
          company: "Essencial Guarabira",
        },
        {
          id: "4247499",
          company: "Essencial Natal",
        },
      ];
      break;
    case "essencial5-2022":
      options = [
        {
          id: "3826600",
          company: "Essencial Recife",
        },
        {
          id: "3826595",
          company: "Essencial João Pessoa",
        },
        {
          id: "3828631",
          company: "Essencial Guarabira",
        },
        {
          id: "4247499",
          company: "Essencial Natal",
        },
      ];
      break;
    case "loc_modulo_imediato":
      options = [
        {
          id: "3828631",
          company: "Essencial Guarabira",
        },
      ];
      break;
    case "alugsalavelessencial":
      options = [
        {
          id: "3828631",
          company: "Essencial Guarabira",
        },
      ];
      break;
    case "traslnacintessencial":
      options = [
        {
          id: "3828631",
          company: "Essencial Guarabira",
        },
      ];
      break;
    case "cersepessencial":
      options = [
        {
          id: "3828631",
          company: "Essencial Guarabira",
        },
      ];
      break;
    case "exumessencial":
      options = [
        {
          id: "3828631",
          company: "Essencial Guarabira",
        },
      ];
      break;
    default:
      options = {};
  }

  return options;
};

export const submitMoskit = async (values, user) => {
  const product = retornaProdutoMoskit(values.product, values.company);

  const body = {
    contact: {
      name: values.name,
    },
    deal: {
      product: product,
      company: parseInt(values.company),
      channel: parseInt(values.canal),
    },
    source: 1,
    webgmUser: user,
  };

  if (values.observacao) {
    body.deal.notes = values.observacao;
  }

  if (!values.phone && !values.mail)
    throw new Error("É necessário informar pelo menos 1 meio de contato!");

  if (values.phone) {
    body.contact.phone = values.phone;
  }

  if (values.mail) {
    body.contact.email = values.mail.trim();
  }

  if (values.vendedor) {
    body.responsible = values.vendedor;
  }

  try {
    const response = await axios.post(
      "https://apiweb.moradadapaz.com.br/morada/Leads/createLead?access_token=jmZfMsxXfTEOwRuAi3O7S0zP7wHJ0tYXW1uA6MC66wnG0uEwkjrgiENGhaF8VRY3",
      body
    );

    return response;
  } catch (error) {
    return error;
  }
};

const retornaProdutoMoskit = (produto, empresa) => {
  var input_product = 0;

  switch (produto) {
    case "petfu":
      if (empresa === "3707921") {
        input_product = 301897;
      } else {
        input_product = 0;
      }
      break;
    case "petim":
      if (empresa === "3707921") {
        input_product = 115404;
      } else {
        input_product = 0;
      }
      break;
    case "petac1":
      if (empresa === "3707921") {
        input_product = 223788;
      } else if (empresa === "3707922") {
        input_product = 223788;
      } else {
        input_product = 0;
      }
      break;
    case "petac2":
      if (empresa === "3707921") {
        input_product = 223789;
      } else if (empresa === "3707922") {
        input_product = 223789;
      } else {
        input_product = 0;
      }
      break;
    case "petac3":
      if (empresa === "3707921") {
        input_product = 223790;
      } else if (empresa === "3707922") {
        input_product = 223790;
      } else {
        input_product = 0;
      }
      break;                  
    case "petac4":
      if (empresa === "3707921") {
        input_product = 223791;
      } else if (empresa === "3707922") {
        input_product = 223791;
      } else {
        input_product = 0;
      }
      break;            
    case "petac5":
      if (empresa === "3707921") {
        input_product = 223792;
      } else if (empresa === "3707922") {
        input_product = 223792;
      } else {
        input_product = 0;
      }
      break;        
    case "prever":
      if (empresa === "3707922") {
        input_product = 67498;
      } else if (empresa === "3707915") {
        input_product = 67495;
      } else if (empresa === "3707921") {
        input_product = 67497;
      } else {
        input_product = 0;
      }
      break;
    case "funeral":
      if (empresa === "3707922") {
        input_product = 301882;
      } else if (empresa === "3707915") {
        input_product = 309509;
      } else if (empresa === "3707921") {
        input_product = 301893;
      } else {
        input_product = 0;
      }
      break;
    case "atendimento_imediato":
      if (empresa === "3707922") {
        input_product = 301882;
      } else if (empresa === "3707923") {
        input_product = 301882;
      } else if (empresa === "3707915") {
        input_product = 309509;
      } else if (empresa === "3707921") {
        input_product = 301893;
      } else if (empresa === "3828631") {
        input_product = 309509;
      } else {
        input_product = 0;
      }
      break;
    case "nicho":
      if (empresa === "3707921") {
        input_product = 309507;
      } else if (empresa === "3707931") {
        input_product = 309527;
      } else if (empresa === "3707923") {
        input_product = 301889;
      } else {
        input_product = 0;
      }
      break;
    case "nicho1":
      if (empresa === "3707931") {
        input_product = 309527;
      } else if (empresa === "3828631"){
        input_product = 301932;
      }
      break;
    case "modulo":
      if (empresa === "3707921") {
        input_product = 301895;
      } else if (empresa === "3707931") {
        input_product = 301891;
      } else if (empresa === "3707923") {
        input_product = 301888;
      } else {
        input_product = 0;
      }
      break;
    case "cremacao":
      if (empresa === "3707922") {
        input_product = 301885;
      } else if (empresa === "3707915") {
        input_product = 309510;
      } else if (empresa === "3707921") {
        input_product = 309505;
      } else {
        input_product = 0;
      }
      break;
    case "jazigo":
      if (empresa === "3707922") {
        input_product = 301883;
      } else if (empresa === "3707931") {
        input_product = 307597;
      } else if (empresa === "3707923") {
        input_product = 301887;
      } else if (empresa === "3707921") {
        input_product = 301894;
      } else if (empresa === "3828631") {
        input_product = 301892;
      } else {
        input_product = 0;
      }
      break;
    case "restos":
      if (empresa === "3707922") {
        input_product = 301886;
      } else if (empresa === "3707915") {
        input_product = 309512;
      } else if (empresa === "3707921") {
        input_product = 309506;
      } else {
        input_product = 0;
      }
      break;
    case "columbario_familiar":
      if (empresa === "3707922") {
        input_product = 301884;
      } else if (empresa === "3707921") {
        input_product = 301896;
      } else {
        input_product = 0;
      }
      break;
    case "columbario_individual":
      if (empresa === "3707922") {
        input_product = 301884;
      } else if (empresa === "3707921") {
        input_product = 301896;
      } else {
        input_product = 0;
      }
      break;
    case "alugsalavelmorada":
      if (empresa === "3707922") {
        input_product = 233026;
      } else if (empresa === "3707931") {
        input_product = 233026;
      } else if (empresa === "3707923") {
        input_product = 233026;
      } else if (empresa === "3707921") {
        input_product = 233026;
      } else if (empresa === "3707915") {
        input_product = 233026;
      } else {
        input_product = 0;
      }
      break;
    case "traslnacintmorada":
      if (empresa === "3707922") {
        input_product = 233028;
      } else if (empresa === "3707921") {
        input_product = 233028;
      } else if (empresa === "3707915") {
        input_product = 233028;
      } else {
        input_product = 0;
      }
      break;
    case "cersepmorada":
      if (empresa === "3707922") {
        input_product = 233029;
      } else if (empresa === "3707931") {
        input_product = 233029;
      } else if (empresa === "3707923") {
        input_product = 233029;
      } else if (empresa === "3707921") {
        input_product = 233029;
      } else if (empresa === "3707915") {
        input_product = 233029;
      } else {
        input_product = 0;
      }
      break;
    case "exummorada":
      if (empresa === "3707922") {
        input_product = 233030;
      } else if (empresa === "3707931") {
        input_product = 233030;
      } else if (empresa === "3707923") {
        input_product = 233030;
      } else if (empresa === "3707921") {
        input_product = 233030;
      } else if (empresa === "3707915") {
        input_product = 233030;
      } else {
        input_product = 0;
      }
      break;
    case "essencial1-2022":
      if (empresa === "4247499") {
        input_product = 309503;
      } else if (empresa === "3826595") {
        input_product = 214694;
      } else if (empresa === "3828631") {
        input_product = 214699;
      } else if (empresa === "3826600") {
        input_product = 69815;
      } else {
        input_product = 0;
      }
      break;
    case "essencial2-2022":
      if (empresa === "4247499") {
        input_product = 309501;
      } else if (empresa === "3826595") {
        input_product = 214695;
      } else if (empresa === "3828631") {
        input_product = 214700;
      } else if (empresa === "3826600") {
        input_product = 214705;
      } else {
        input_product = 0;
      }
      break;
    case "essencial3-2022":
      if (empresa === "4247499") {
        input_product = 309502;
      } else if (empresa === "3826595") {
        input_product = 214696;
      } else if (empresa === "3828631") {
        input_product = 214701;
      } else if (empresa === "3826600") {
        input_product = 214706;
      } else {
        input_product = 0;
      }
      break;
    case "essencial4-2022":
      if (empresa === "4247499") {
        input_product = 309503;
      } else if (empresa === "3826595") {
        input_product = 214697;
      } else if (empresa === "3828631") {
        input_product = 214702;
      } else if (empresa === "3826600") {
        input_product = 214707;
      } else {
        input_product = 0;
      }
      break;
    case "essencial5-2022":
      if (empresa === "4247499") {
        input_product = 309504;
      } else if (empresa === "3826595") {
        input_product = 214698;
      } else if (empresa === "3828631") {
        input_product = 214703;
      } else if (empresa === "3826600") {
        input_product = 214708;
      } else {
        input_product = 0;
      }
      break;
    case "loc_modulo_imediato":
      if (empresa === "3828631") {
        input_product = 226782;
      } else {
        input_product = 0;
      }
      break;
    case "alugsalavelessencial":
      if (empresa === "3828631") {
        input_product = 233450;
      } else {
        input_product = 0;
      }
      break;
    case "traslnacintessencial":
      if (empresa === "3828631") {
        input_product = 233451;
      } else {
        input_product = 0;
      }
      break;
    case "cersepessencial":
      if (empresa === "3828631") {
        input_product = 233452;
      } else {
        input_product = 0;
      }
      break;
    case "exumessencial":
      if (empresa === "3828631") {
        input_product = 233453;
      } else {
        input_product = 0;
      }
      break;
    default:
      input_product = 0;
      break;
  }

  return input_product;
};
