import React from "react";
import { useHistory } from "react-router-dom";

import { Result, Button } from 'antd';

function NoRoute(props) {
  const history = useHistory();

  return (
    <Result
      status={props.status}
      title={props.title}
      subTitle={props.message}
      extra={<Button type="primary" onClick={() => history.push("/")} >Voltar</Button>}
    />
  )
}

export default NoRoute;