export const Types = {
  ADD: "ADD",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
  GET: "GET",
  SET_RESOURCE: "SET_RESOURCE",
};

const INITIAL_STATE = {
  resourceList: [],
  resourceListCount: 0,
  resource: {
    id: null,
    active: false,
    name: "",
    description: "",
    id_resource: "",
    class_tag: "",
  },
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        resourceList: action.payload.rows,
        resourceListCount: action.payload.count,
      };

    case Types.ADD:
      return {
        ...state,
        resourceList: action.payload.rows,
        resourceListCount: action.payload.count,
      };

    case Types.UPDATE:
      return {
        ...state,
        resourceList: action.payload.rows,
        resourceListCount: action.payload.count,
      };

    case Types.REMOVE:
      return {
        ...state,
        resourceList: action.payload.rows,
        resourceListCount: action.payload.count,
      };

    case Types.SET_RESOURCE:
      return {
        ...state,
        resource: {
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

// creators
export function listResources(data) {
  return {
    type: Types.GET,
    payload: data,
  };
}

export function addResources(data) {
  return {
    type: Types.ADD,
    payload: data,
  };
}

export function alterResources(data) {
  return {
    type: Types.SET_RESOURCE,
    payload: data,
  };
}

export function updateResources(data) {
  return {
    type: Types.UPDATE,
    payload: data,
  };
}

export function deleteResources(data) {
  return {
    type: Types.REMOVE,
    payload: data,
  };
}
