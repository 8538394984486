import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const TemplateMailService = {
  listTemplates: async (limit, offset, active = true) => {
    const dados = await getRequest(
        "/templates-mail?limit=" + limit + "&offset=" + offset + "&active=" + active,
        {}
    )
    return dados;
  },
  create: async (body) => {
    const dados = await postRequest("/template-mail", body)
    return dados;
  },
  delete: async (id) => { 
    const dados = await deleteRequest(`/template-mail?id=${id}`, {}) 
    return dados;
  },
  update: async (body) => {
    const dados = await putRequest(`/template-mail?id=${body.id}`, body)
    return dados;
  },
};