/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Empty,
  Card,
  Statistic,
  Divider,
  Tag,
  Alert,
  Tooltip,
  Button,
  notification,
  Popover,
  Menu,
} from "antd";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  SyncOutlined,
  FieldTimeOutlined
} from '@ant-design/icons';
import moment from "moment";

import Main from "../../../Layout/Main";
import { itensMenu } from "../itens-menu";
import MenuComponent from "../../../../components/MenuComponent";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { getListSchedules, getResumo } from "../../../../services/scheduleMonitorservice";
import { capitalizeFirstLetter } from "../../../../utils/text";

import "./style.css";

function ScheduleMonitor(props) {

  const [schedules, setSchedules] = useState({});
  const [resumo, setResumo] = useState({});
  const [loadingSchedules, setLoadingSchedules] = useState(false);
  const [loadingResumo, setLoadingResumo] = useState(false);
  const [refreshAuto, setRefreshAuto] = useState(false);
  const [automaticTime, setAutomaticTime] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [pageSize, setPageSize] = useState(5);
  const [hoveredTime, setHoveredTime] = useState(false);

  useEffect(() => {
    updateMethod();
  }, []);

  useEffect(() => {
    handleSchedules();;
  }, [filters, pageSize, currentPage]);

  useEffect(() => {
  
    if (refreshAuto) {
      const intervalId = setInterval(updateMethod, automaticTime * 60 * 1000); // minuto * segundos * milissegundos

      return () => clearInterval(intervalId);
    }

  }, [refreshAuto]);

  const handleSchedules = async () => {
    setLoadingSchedules(true);
    try {
      const result = await getListSchedules({ offset: currentPage, limit: pageSize, filters });

      setSchedules(result);
      setLastUpdated(new Date());
    } catch (error) {
      notification.error({ message: "Não foi possível buscar os registros de schedules!" });
    } finally {
      setLoadingSchedules(false);
    }
  }
  const updateMethod = () => {
    handleSchedules();
    handleResumo();
  };

  const handleResumo = async () => {
    setLoadingResumo(true);
    try {
      const result = await getResumo();

      setResumo(result);
    } catch (error) {
      notification.error({ message: "Não foi possível buscar o resumo de schedules!" });
    } finally {
      setLoadingResumo(false);
    }
  }

  const breadcrumb = [
    {
      title: "Financeiro",
      path: props.modulePath,
    },
    {
      title: "Monitor de Schedules Protheus",
      path: props.modulePath + "/schedule-monitor",
    },
  ];

  const columnsTableMobile = [
    
    {
      title: "Descrição",
      key: "description",
      dataIndex: "descri"
    }
  ];

  const columnsTable = [
    
    {
      title: "Descrição",
      width: "20%",
      render: (data) => <Tooltip title={data.rotina}>{data.descri}</Tooltip>
    },
    {
      title: "Próxima execução",
      dataIndex: "proximaExecucao",
    },
    {
      title: "Emaus",
      render: (data) => {
        const execucoes = data.emaus ?? {status: "Desabilitados", ultimaHora: "", ultimoDia: "  /  /    "}
        const status = execucoes?.status === "habilitados" || execucoes?.status === "executados" ? true : false;
        
        return <Tooltip title={`Última execução: ${execucoes?.ultimaHora} ${execucoes?.ultimoDia}`}><Tag color={status ? "green" : "red"} icon={status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>{capitalizeFirstLetter(execucoes?.status)}</Tag></Tooltip>
      }
    },
    {
      title: "SGA",
      render: (data) => {
        const execucoes = data.saoGoncalo ?? {status: "Desabilitados", ultimaHora: "", ultimoDia: "  /  /    "}
        const status = execucoes?.status === "habilitados" || execucoes?.status === "executados" ? true : false;
        
        return <Tooltip title={`Última execução: ${execucoes?.ultimaHora} ${execucoes?.ultimoDia}`}><Tag color={status ? "green" : "red"} icon={status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>{capitalizeFirstLetter(execucoes?.status)}</Tag></Tooltip>
      }
    },
    {
      title: "Mipibu",
      render: (data) => {
        const execucoes = data.mipibu ?? {status: "Desabilitados", ultimaHora: "", ultimoDia: "  /  /    "}
        const status = execucoes?.status === "habilitados" || execucoes?.status === "executados" ? true : false;
        
        return <Tooltip title={`Última execução: ${execucoes?.ultimaHora} ${execucoes?.ultimoDia}`}><Tag color={status ? "green" : "red"} icon={status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>{capitalizeFirstLetter(execucoes?.status)}</Tag></Tooltip>
      }
    },
    {
      title: "Serpos",
      render: (data) => {
        const execucoes = data.serpos ?? {status: "Desabilitados", ultimaHora: "", ultimoDia: "  /  /    "}
        const status = execucoes?.status === "habilitados" || execucoes?.status === "executados" ? true : false;
        
        return <Tooltip title={`Última execução: ${execucoes?.ultimaHora} ${execucoes?.ultimoDia}`}><Tag color={status ? "green" : "red"} icon={status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>{capitalizeFirstLetter(execucoes?.status)}</Tag></Tooltip>
      }
    },
    {
      title: "SFC",
      render: (data) => {
        const execucoes = data.joaoPessoa ?? {status: "Desabilitados", ultimaHora: "", ultimoDia: "  /  /    "}
        const status = execucoes?.status === "habilitados" || execucoes?.status === "executados" ? true : false;
        
        return <Tooltip title={`Última execução: ${execucoes?.ultimaHora} ${execucoes?.ultimoDia}`}><Tag color={status ? "green" : "red"} icon={status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>{capitalizeFirstLetter(execucoes?.status)}</Tag></Tooltip>
      }
    },
    {
      title: "GBAPL",
      render: (data) => {
        const execucoes = data.guarabiraPlano ?? {status: "Desabilitados", ultimaHora: "", ultimoDia: "  /  /    "}
        const status = execucoes?.status === "habilitados" || execucoes?.status === "executados" ? true : false;
        
        return <Tooltip title={`Última execução: ${execucoes?.ultimaHora} ${execucoes?.ultimoDia}`}><Tag color={status ? "green" : "red"} icon={status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>{capitalizeFirstLetter(execucoes?.status)}</Tag></Tooltip>
      }
    },
    {
      title: "GBACEM",
      render: (data) => {
        const execucoes = data.guarabiraCemiterio ?? {status: "Desabilitados", ultimaHora: "", ultimoDia: "  /  /    "}
        const status = execucoes?.status === "habilitados" || execucoes?.status === "executados" ? true : false;
        
        return <Tooltip title={`Última execução: ${execucoes?.ultimaHora} ${execucoes?.ultimoDia}`}><Tag color={status ? "green" : "red"} icon={status ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>{capitalizeFirstLetter(execucoes?.status)}</Tag></Tooltip>
      }
    },
  ];

  const handleClose = (removedFiltro) => {
    const newFilters = filters.filter((filtro) => filtro !== removedFiltro);

    setFilters(newFilters);
  };

  const forFilters = (filtro) => (
    <span
      key={filtro}
      style={{
        display: 'inline-block',
      }}
    >
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          if (loadingSchedules) return 
          handleClose(filtro);
        }}
      >
        {filtro}
      </Tag>
    </span>
  );

  const addFilter = (filtro) => {
    if (loadingSchedules) return

    if (!filters.includes(filtro)) {
      setFilters([...filters, filtro]);
    }
  }

  const tagChild = filters.map(forFilters);

  const setRefresh = (time) => {
    setAutomaticTime(time);
    setRefreshAuto(true);
    setHoveredTime(false);
  }

  const content = (
    <Menu mode="vertical" id="userMenu" selectable={false}>
      {refreshAuto ? <Menu.Item icon={<FieldTimeOutlined />} onClick={() => { setRefreshAuto(false); setHoveredTime(false) }} >Parar atualização automatica</Menu.Item> : <></>}
      <Menu.Item icon={<FieldTimeOutlined />} onClick={() => setRefresh(10)} 
      >
        Atualizar a cada 10 minutos
      </Menu.Item>
      <Menu.Item icon={<FieldTimeOutlined />} onClick={() => setRefresh(20)} 
      >
        Atualizar a cada 20 minutos
      </Menu.Item>
      <Menu.Item icon={<FieldTimeOutlined />} onClick={() => setRefresh(30)} 
      >
        Atualizar a cada 30 minutos
      </Menu.Item>
      <Menu.Item icon={<FieldTimeOutlined />} onClick={() => setRefresh(40)} 
      >
        Atualizar a cada 40 minutos
      </Menu.Item>
    </Menu>
  );

  return (
    <Main
      key={props.moduleName}
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          modulePath={props.modulePath}
          selectedMenu="5"
          itensMenu={itensMenu}
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"flex"}
      buttonHeader={
        <Row justify="space-between" gutter={[12, 8]} align="middle">
          {
            refreshAuto ?
            <Col>
              <Alert
                style={{ minWidth: "174px" }}
                message={`Atualiza a cada ${automaticTime} minutos`}
                type={loadingSchedules ? "info" : "success"}
                showIcon
                icon={<FieldTimeOutlined />}
                />
            </Col>
            :
            null
          }
          <Col>
            <Alert
              style={{ minWidth: "174px" }}
              message={
                loadingSchedules
                  ? "Atualizando..."
                  : `Atualizado às ${moment(lastUpdated).format("LTS")}`
              }
              type={loadingSchedules ? "info" : "success"}
              showIcon
            />
          </Col>
          <Col>
            <Popover
              placement="bottomRight"
              content={content}
              trigger="click"
              open={hoveredTime}
              onOpenChange={() => setHoveredTime(true)}
            >
              <Tooltip placement="left" title={`Atualização ${refreshAuto ? 'automatica' : 'manual'}`}>
                <Button
                  className="header-button"
                  type="secondary"
                  disabled={loadingSchedules}
                  icon={<SyncOutlined spin={refreshAuto} />}
                />
              </Tooltip>
              </Popover>
          </Col>
        </Row>
      }
    >
      <Helmet>
        <title>Monitor de Schedules Protheus - webGM</title>
      </Helmet>
      <Row justify={"space-around"} gutter={[16,16]}>
        <Col xs={24} sm={12} lg={6}>
          <Card className={loadingSchedules || filters.includes("habilitados") ? "" : "cardResumoPointer"} loading={loadingResumo} onClick={() => addFilter("habilitados")}>
            <Card.Meta
              style={{ alignItems: "center", justifyContent: "center" }}
              avatar={<CheckCircleOutlined style={{ color: "#3f8600", fontSize: "6vh" }} />}
              title="Ativos"
              description={
                <Statistic
                  value={resumo?.resumo?.habilitados ?? 0}
                  precision={0}
                  valueStyle={{ color: '#3f8600' }}
                />
              }
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card className={loadingSchedules || filters.includes("desativados") ? "" : "cardResumoPointer"} loading={loadingResumo} onClick={() => addFilter("desativados")}>
            <Card.Meta
              style={{ alignItems: "center", justifyContent: "center" }}
              avatar={<CloseCircleOutlined style={{ color: "#cf1322", fontSize: "6vh" }} />}
              title="Desativados"
              description={
                <Statistic
                  value={resumo?.resumo?.desativados ?? 0}
                  precision={0}
                  valueStyle={{ color: '#cf1322' }}
                />
              }
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card className={loadingSchedules || filters.includes("executados") ? "" : "cardResumoPointer"} loading={loadingResumo} onClick={() => addFilter("executados")}>
            <Card.Meta
              style={{ alignItems: "center", justifyContent: "center" }}
              avatar={<CheckCircleOutlined style={{ color: "#3f8600", fontSize: "6vh" }} />}
              title="Executados"
              description={
                <Statistic
                  value={resumo?.resumo?.executados ?? 0}
                  precision={0}
                  valueStyle={{ color: '#3f8600' }}
                />
              }
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <Card className={loadingSchedules || filters.includes("atrasados") ? "" : "cardResumoPointer"} loading={loadingResumo} onClick={() => addFilter("atrasados")}>
            <Card.Meta
              style={{ alignItems: "center", justifyContent: "center" }}
              avatar={<WarningOutlined style={{ color: "#ffcc00", fontSize: "6vh" }} />}
              title="Atrasados"
              description={
                <Statistic
                  value={resumo?.resumo?.atrasados ?? 0}
                  precision={0}
                  valueStyle={{ color: '#ffcc00' }}
                />
              }
            />
          </Card>
        </Col>
      </Row>

      <Divider />

      {
        filters.length > 0 ?
        <>
          <Row gutter={[16,16]}>
            <Col xs={24}>
              {tagChild}
            </Col>
          </Row>
          <Divider />
        </>
        :
        <></>
      }
      
      <Row gutter={[16,16]}>
        <Col xs={0} sm={24}>
          <Table
            className="schedule-monitor-table"
            locale={{
              emptyText: <Empty description="Nenhum schedule encontrado" />,
            }}
            loading={{
              spinning: loadingSchedules,
              tip: "Buscando schedules...",
            }}
            scroll={{ x: 800 }}
            columns={columnsTable}
            dataSource={schedules.data?.sort((a, b) => {
              return moment(b.createdAt).diff(moment(a.createdAt));
            })}

            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: schedules.count,
              showSizeChanger: true,
              onShowSizeChange: function (current, size) {
                setPageSize(size);
              },
              pageSizeOptions: [3, 5, 10, 50, 100],
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
            rowKey={(record) => record.id}
          />
        </Col>

        <Col xs={24} sm={0}>
          <Table
            className="schedule-monitor-table"
            locale={{
              emptyText: <Empty description="Nenhum schedule encontrado" />,
            }}
            loading={{
              spinning: loadingSchedules,
              tip: "Buscando schedules...",
            }}
            scroll={{ x: 800 }}
            columns={columnsTableMobile}
            dataSource={schedules.data?.sort((a, b) => {
              return moment(b.createdAt).diff(moment(a.createdAt));
            })}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <Row justify={"space-around"} gutter={[8,8]}>
                    <Col xs={24}>
                      <p style={{ margin: 0 }}>Próxima execução: {record.proximaExecucao}</p>
                    </Col>
                    <Col xs={24}>
                      <p style={{ margin: 0 }}>Emaus: <Tooltip title={`Última execução: ${record.emaus.ultimaHora} ${record.emaus.ultimoDia}`}><Tag color={record.emaus.status === "habilitados" || record.emaus.status === "executados" ? "green" : "red"} icon={record.emaus.status === "habilitados" || record.emaus.status === "executados" ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>{capitalizeFirstLetter(record.emaus.status)}</Tag></Tooltip></p>
                    </Col>
                    <Col xs={24}>
                      <p style={{ margin: 0 }}>São Gonçalo: <Tooltip title={`Última execução: ${record.saoGoncalo.ultimaHora} ${record.saoGoncalo.ultimoDia}`}><Tag color={record.saoGoncalo.status === "habilitados" || record.saoGoncalo.status === "executados" ? "green" : "red"} icon={record.saoGoncalo.status === "habilitados" || record.saoGoncalo.status === "executados" ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>{capitalizeFirstLetter(record.saoGoncalo.status)}</Tag></Tooltip></p>
                    </Col>
                    <Col xs={24}>
                      <p style={{ margin: 0 }}>Mipibu: <Tooltip title={`Última execução: ${record.mipibu.ultimaHora} ${record.mipibu.ultimoDia}`}><Tag color={record.mipibu.status === "habilitados" || record.mipibu.status === "executados" ? "green" : "red"} icon={record.mipibu.status === "habilitados" || record.mipibu.status === "executados" ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>{capitalizeFirstLetter(record.mipibu.status)}</Tag></Tooltip></p>
                    </Col>
                    <Col xs={24}>
                      <p style={{ margin: 0 }}>Serpos: <Tooltip title={`Última execução: ${record.serpos.ultimaHora} ${record.serpos.ultimoDia}`}><Tag color={record.serpos.status === "habilitados" || record.serpos.status === "executados" ? "green" : "red"} icon={record.serpos.status === "habilitados" || record.serpos.status === "executados" ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>{capitalizeFirstLetter(record.serpos.status)}</Tag></Tooltip></p>
                    </Col>
                    <Col xs={24}>
                      <p style={{ margin: 0 }}>João Pessoa: <Tooltip title={`Última execução: ${record.joaoPessoa.ultimaHora} ${record.joaoPessoa.ultimoDia}`}><Tag color={record.joaoPessoa.status === "habilitados" || record.joaoPessoa.status === "executados" ? "green" : "red"} icon={record.joaoPessoa.status === "habilitados" || record.joaoPessoa.status === "executados" ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>{capitalizeFirstLetter(record.joaoPessoa.status)}</Tag></Tooltip></p>
                    </Col>
                    <Col xs={24}>
                      <p style={{ margin: 0 }}>Guarabira Plano: <Tooltip title={`Última execução: ${record.guarabiraPlano.ultimaHora} ${record.guarabiraPlano.ultimoDia}`}><Tag color={record.guarabiraPlano.status === "habilitados" || record.guarabiraPlano.status === "executados" ? "green" : "red"} icon={record.guarabiraPlano.status === "habilitados" || record.guarabiraPlano.status === "executados" ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>{capitalizeFirstLetter(record.guarabiraPlano.status)}</Tag></Tooltip></p>
                    </Col>
                    <Col xs={24}>
                      <p style={{ margin: 0 }}>Guarabira Cemitério: <Tooltip title={`Última execução: ${record.guarabiraCemiterio.ultimaHora} ${record.guarabiraCemiterio.ultimoDia}`}><Tag color={record.guarabiraCemiterio.status === "habilitados" || record.guarabiraCemiterio.status === "executados" ? "green" : "red"} icon={record.guarabiraCemiterio.status === "habilitados" || record.guarabiraCemiterio.status === "executados" ? <CheckCircleOutlined /> : <CloseCircleOutlined />}>{capitalizeFirstLetter(record.guarabiraCemiterio.status)}</Tag></Tooltip></p>
                    </Col>
                  </Row>
                )
              },
              rowExpandable: (record) => record.name !== 'Not Expandable',
            }}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: schedules.count,
              showSizeChanger: true,
              onShowSizeChange: function (current, size) {
                setPageSize(size);
              },
              pageSizeOptions: [3, 5, 10, 50, 100],
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
            rowKey={(record) => record.key}
          />
        </Col>
        
      </Row>
    </Main>
  );
}

export const useCompany = () => {
  const signin = useSelector((state) => state.signin);
  const { subsidiary_erp, company_erp } = useMemo(() => {
    const { code_erp: subsidiary_erp, Hierarquia } =
      signin.user.userCompanies.find(
        (company) => company.id === signin.user.currentCompany
      );

    return { subsidiary_erp, company_erp: Hierarquia.code_erp };
  }, [signin]);

  return { subsidiary_erp, company_erp };
};

export default ScheduleMonitor;