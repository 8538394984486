import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.css";

import { Form, Input, message, Spin, Upload, Row, Col, Button } from "antd";

import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

import { awsApi } from "../../services/aws";

import { updateUserApi } from "../../services/users";

import { alterUser, updateUser } from "../../store/ducks/users";

import { closeModalSecondary } from "../../store/ducks/modalGlobal";
import { useUploadFile } from "../../utils/useUploadFile";
import { useSession } from "../../Context/SessionProvider";

const validateMessages = {
  required: "${label} é obrigatório!",
};

function FormApp() {
  const {
    session: { user },
    updateCurrentUser,
  } = useSession();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const {
    data: uploadData,
    loading: uploadLoading,
    uploadFile,
  } = useUploadFile({
    onError: () => {
      message.error("Erro ao enviar imagem!");
    },
  });

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    form.resetFields();
    if (user.photo) {
      (async () => {
        const url = await awsApi.fetchFile(user.photo);

        setImageUrl(url);
      })();
    }
  }, [user]);

  const handleSubmit = async (values) => {
    try {
      const updatedUser = await updateUserApi.updateUser({
        ...user,
        ...values,
        photo: uploadData?.Key || user.photo,
      });

      if (uploadData && user.photo !== uploadData?.Key) {
        await awsApi.deleteFile(user.photo);
      }

      updateCurrentUser(updatedUser);
      dispatch(alterUser(updatedUser));
      dispatch(updateUser(updatedUser));
      dispatch(closeModalSecondary(false));
      setLoading(false);
      message.success("Usuário alterado com sucesso!");
    } catch {
      message.error("Erro ao alterar o usuário");
    }
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Apenas imagens dos tipos JPG/PNG são aceitas!");
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      message.error("O tamanho da imagem excede 4MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  return (
    <Spin tip="Loading..." spinning={uploadLoading}>
      <Form
        layout="vertical"
        form={form}
        name="form-users"
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        initialValues={{
          ["photo"]: user.photo,
          ["name"]: user.name,
          ["email"]: user.email,
        }}
      >
        <Row>
          <Col span={4}>
            <Form.Item name="photo" label="Foto">
              <ImgCrop rotate modalTitle="Editar imagem" modalCancel="Cancelar">
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={(file) => {
                    setImageUrl(URL.createObjectURL(file));
                    uploadFile(file, {
                      folder: "users",
                      format: "jpeg",
                      prefixname: "profile_picture",
                      quality: 95,
                      width: 200,
                    });
                    return false;
                  }}
                  beforeUpload={beforeUpload}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      id="user_photo"
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <div>
                      {loading ? <LoadingOutlined /> : <PlusOutlined />}
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Col>
          <Col span={17} offset={3}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Nome"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="email"
                  label="E-mail"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="buttonsClass">
          <Button disabled={uploadLoading} type="primary" htmlType="submit">
            Salvar
          </Button>
        </div>
      </Form>
    </Spin>
  );
}

export default FormApp;
