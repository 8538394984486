import React, { useState, useEffect } from "react";
import { Button, Popconfirm, Select, Table } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import "../styles.css";
import { openModal } from "../../../../../store/ducks/modalGlobal";
import Main from "../../../../Layout/Main";
import MainCrud from "../../../../../components/MainCrud";
import { ModalApp } from "../../../../../components/Modal";
import LinkForm from ".//Form/LinksForm";
import GroupForm from "./Form/GroupForm";
import { checkControl } from "../../../../../utils/access_control";
import loadingSVG from "../../../../../assets/images/loading.svg";
import MenuComponent from "../../../../../components/MenuComponent";
import { itensDrawer } from "../../itens-menu";
import api from "../../../../../utils/api";
import { useFetch } from "../../../../../utils/useFetch";
import { useIsMobile } from "../../../../../utils/isMobile";
import { Link, useParams } from "react-router-dom";
import { useMutate } from "../../../../../utils/useMutate";


function GroupPage(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedLink, setSelectedLink] = useState(null);
  const [modalOperation, setModalOperation] = useState("");
  const signin = useSelector((state) => state.signin);
  const isMobile = useIsMobile();
  const { code } = useParams();





  const getLinks = async () => {
    return api.get(`/links?groupCode=${code}`).then((res) => res.data);
  };

  const deleteLinkMutate = async () => {
    return api.delete(`/links?linkId=${selectedLink.id}`);
  };

  const { mutate: deleteLink, loading: loadingDelete } = useMutate(
    deleteLinkMutate,
    {
      onSuccess: () => {
        handleFinish();
      },
    }
  );

  const {
    data: currentGroup,
    loading,
    refetch: refetchLinks,
  } = useFetch(getLinks, { autoFetch: false });
  const dispatch = useDispatch();

  useEffect(() => {
    if (code) {
      refetchLinks();
    }
  }, [code]);

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(10);
  }

  const handleOpenModal = (data) => {
    dispatch(openModal(true));
  };

  const handleFinish = () => {
    dispatch(openModal(false));
    setSelectedLink(null);
    if (modalOperation.match(/link/)) refetchLinks();
  };
  const handleDelete = () => {
    deleteLink();
  };
  let columns = [
    {
      title: "Titulo",
      dataIndex: "title",
    },
    {
      title: "link",
      dataIndex: "href",
    },
    {
      title: "Ações",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="group-actions">
          <Button
            size="middle"
            icon={<EditOutlined />}
            title="Editar grupo"
            onClick={() => {
              setSelectedLink(record);
              setModalOperation("update_link");
              handleOpenModal();
            }}
            style={{
              // ...checkControl(signin.user.profiles, "save_and_update_products"),
              marginTop: "8px",
              borderRadius: "50%",
            }}
          />
          <Popconfirm
            title="Tem certeza que deseja deletar este grupo?"
            onConfirm={handleDelete}
            okText="Sim"
            cancelText="Cancelar"
          >
            <Button
              type="default"
              size="middle"
              danger
              icon={<DeleteOutlined />}
              title="Excluir grupo"
              onClick={() => {
                setSelectedLink(record);
              }}
              style={{
                // ...checkControl(signin.user.profiles, "save_and_update_products"),
                marginTop: "8px",
                borderRadius: "50%",
              }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Links externos",
      path: props.modulePath + "/external-links",
    },
    {
      title: currentGroup?.title,
      path: props.modulePath + "/external-links/" + currentGroup?.code,
    },
  ];

  return (
    <Main
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          itensMenu={itensDrawer}
          modulePath={props.modulePath}
          selectedMenu="12"
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"flex"}
    >
      <div className="row-spaced">
        <div className="row-spaced">
          <h4>{currentGroup?.title}</h4>
        </div>
        <Button
          type="primary"
          size="middle"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalOperation("create_link");
            handleOpenModal();
          }}
          style={{
            ...checkControl(signin.user.profiles, "save_and_update_products"),
            margin: "4px 0",
          }}
        >
          Novo Link
        </Button>
      </div>
      {loading ? (
        <>
          <div className="loading-centar">
            <img src={loadingSVG} alt="loading" height={80} />
          </div>
          <h2 className="text-loading">
            Por favor aguarde, buscando os links...
          </h2>
        </>
      ) : (
        <Table
          columns={columns}
          dataSource={currentGroup?.links || []}
          rowKey={(record) => record.id}
          loading={loading}
          pagination={{
            current: page,
            pageSize: rowsPerPage,
            total: currentGroup?.links.length,
          }}
        
          size="small"
          onChange={handleTableChange}
          // onRow={
          //   handleOpenModal
          //     ? (record) => {
          //         return {
          //           onClick: () => {
          //             handleOpenModal(record);
          //           },
          //         };
          //       }
          //     : null
          // }
        />
      )}

      <ModalApp
        title={
          modalOperation.match(/create/)
            ? "Novo link"
            : `Atualizar link: ${selectedLink?.title}`
        }
        width={1000}
        footer={null}
      >
        {modalOperation.match(/link/) && (
          <LinkForm
            groupCode={code}
            onFinish={handleFinish}
            item={modalOperation.match(/update/) ? selectedLink : null}
          />
        )}
      </ModalApp>
    </Main>
  );
}

export default GroupPage;
