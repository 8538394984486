import api, {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

/*export const listFormApi = {
  listForm: (limit, offset, active = true, param = "") =>
    getRequest(
      `/form?limit=${limit}&offset=${offset}&active=${active}${param}`,
      {}
    ),
};*/

// export const postDocumentApi = async (files, params) => await postRequest("/base_service/document", );
export const postDocumentApi = async (files, params) =>
  await api.post(
    `/base_service/document?${new URLSearchParams(params)}`,
    files
  );

export const getDocumentByBaseService = async (idBaseService) =>
  await getRequest(`/base_service/document?idBaseService=${idBaseService}`);
