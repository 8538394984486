import React, { useEffect, useState, } from "react";
import Main from "../../../Layout/Main";
import { Button, Descriptions, Dropdown, Input, Modal, Row, Spin, Table, Tooltip, Menu, notification } from "antd";
import { PlusOutlined, DownOutlined, AuditOutlined, DeleteOutlined } from "@ant-design/icons";
import { itensMenu } from "../itens-menu";
import { SearchOutlined, } from "@ant-design/icons";
import MenuComponent from "../../../../components/MenuComponent";
import { recurrenceToken } from "../../../../services/recurrenceService";
import { Helmet } from "react-helmet";
import { CreateModalJoinToken } from "./components/CreateJoinToken";
import moment from "moment";
import Swal from "sweetalert2";

function Recurrence(props) {

  const breadcrumb = [
    {
      title: "Financeiro",
      path: props.modulePath,
    },
    {
      title: "Recorrência",
      path: props.modulePath + "/recurrence",
    },
  ];

  const [dataTokens, setDataTokens] = useState([])
  const [searchQuery, setSearchQuery] = useState({})
  const [isModalVisibility, setIsModalVisibility] = useState(false)



  const [isModalVisibilityDetail, setIsModalVisibilityDetail] = useState(false)
  const [currentLink, setCurrentLink] = useState({});

  const [isloadindDetails, setIsloadindDetails] = useState(false)

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    listTokens();
  }, [pagination, searchQuery]);

  function handleChangeSearchQuery(e) {
    const { name, value } = e.target;
    setSearchQuery({ ...searchQuery, [name]: value });
  }

  // filtrar os usuários no backend
  const getColumnSearchApi = (column) => ({
    filterDropdown: () => (
      <div className="divSearch">
        <Input
          style={{ marginBottom: 8 }}
          placeholder="Pesquisar..."
          value={searchQuery[column]}
          onChange={handleChangeSearchQuery}
          name={column}
        />
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });


  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Empresa",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Filial",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Código do Contrato",
      dataIndex: "contract_code",
      key: "contract_code",
      ...getColumnSearchApi("cod_contrt"),

    },
    {
      title: "CPF do Cliente",
      dataIndex: "customer_cpf",
      key: "customer_cpf",
      ...getColumnSearchApi("cpf_client"),
    },
    {
      title: "Nome do Cliente",
      dataIndex: "customer_name",
      key: "customer_name",
      ...getColumnSearchApi("name_client"),
    },
    {
      title: "Valor",
      dataIndex: "value_product",
      key: "value_product",
    },
    {
      title: "Usuário",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
    },
    {
      title: "Ações",
      dataIndex: "actions",
      render: (actions, record) => {
        return (
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu direction="vertical">
                <Menu.Item
                  onClick={async () => {
                    setIsloadindDetails(true)
                    setIsModalVisibilityDetail(true)
                    const token = await recurrenceToken.detailToken(record.token)
                    setCurrentLink({ ...token, status: record.status, link: record.link })
                    setIsloadindDetails(false)
                  }}
                  icon={<AuditOutlined style={{ fontSize: "16px" }} />}
                  key="0"
                >
                  Detalhes
                </Menu.Item>
                <Menu.Item
                  onClick={async () => {
                    Swal.fire({
                      title: "Atenção",
                      text: `Você está cancelando este link de adesão a recorrência, deseja continuar?`,
                      icon: "warning",
                      showDenyButton: true,
                      focusConfirm: false,
                      denyButtonText: "Cancelar",
                      confirmButtonText: "Sim, Cancelar",
                      confirmButtonColor: "#1890ff",
                      denyButtonColor: "rgb(238, 26, 26)",
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        try{
                          await recurrenceToken.deleteToken(record.token)
                          notification.success({
                            message: "Link Cancelado com sucesso",
                          });
                          listTokens();
                        }catch (e){
                          notification.error({
                            message: "Erro ao cancelar o Link",
                            description: e.msg || e.message,
                          });
                        }
                      }
                    });
                  }}
                  icon={<DeleteOutlined style={{ fontSize: "16px" }} />}
                  key="0"
                >
                  Cancelar
                </Menu.Item>
              </Menu>
            }
          >
            <Button style={{ padding: 0 }} type="link">
              Ações
              <DownOutlined />
            </Button>
          </Dropdown>
        )
      }
    }
  ]


  const listTokens = async () => {
    const tokens = await recurrenceToken.listTokens({ ...searchQuery, limit: pagination.pageSize, page: pagination.current - 1 })
    const page = pagination
    page.total = tokens.rows
    setDataTokens(tokens.tokens)
    setPagination(page)
  }
  return (
    <>
      <Helmet>
        <title>Consulta Tokens - webGM</title>
      </Helmet>
      <Main
        key={props.moduleName}
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            modulePath={props.modulePath}
            selectedMenu="3"
            itensMenu={itensMenu}
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <Row style={{ marginRight: ".5rem" }} gutter={24} justify="end">
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => {
                setIsModalVisibility(true);
              }}
            >
              Gerar Link
            </Button>
          </Row>
        }
      >
        <Table
          scroll={{ x: 700 }}
          columns={columns}
          dataSource={dataTokens}
          pagination={pagination}
          onChange={(pagi) => {
            setPagination(pagi)
          }}
        />

        <Modal
          open={isModalVisibilityDetail}
          onCancel={() => setIsModalVisibilityDetail(false)}
          title={`Contrato - ${currentLink?.contract_code}`}
          footer={null}
          destroyOnClose={true}
          centered
        >
          <Spin tip="Buscando as informações do Link, por favor aguarde." spinning={isloadindDetails}>
            <Descriptions className="payment-description" bordered size="small">
              <Descriptions.Item label="Status" span={3}>
                {currentLink?.status}
              </Descriptions.Item>
              <Descriptions.Item label="Nº do contrato" span={3}>
                {currentLink?.contract_code}
              </Descriptions.Item>
              <Descriptions.Item label="Tipo do Link" span={3}>
                {currentLink?.type}
              </Descriptions.Item>
              <Descriptions.Item label="Nome do cliente" span={3}>
                {currentLink?.customer_name}
              </Descriptions.Item>
              <Descriptions.Item label="CPF do cliente" span={3}>
                {currentLink?.customer_cpf}
              </Descriptions.Item>
              <Descriptions.Item label="Link" span={3}>
                {currentLink?.link}
              </Descriptions.Item>
              <Descriptions.Item label="Token" span={3}>
                {currentLink?.token}
              </Descriptions.Item>
              <Descriptions.Item label="Produto" span={3}>
                {currentLink?.product_name}
              </Descriptions.Item>
              <Descriptions.Item label="Negocio" span={3}>
                {currentLink?.business}
              </Descriptions.Item>
              <Descriptions.Item label="Expiração" span={3}>
                {moment(currentLink?.expires_at).format("DD/MM/YYYY")}
              </Descriptions.Item>
            </Descriptions>

          </Spin>
        </Modal>

        <CreateModalJoinToken
          setOpen={(payload) => setIsModalVisibility(payload)}
          open={isModalVisibility}
          onCreateSuccess={(data) => {
            if (data) {
              setCurrentLink({
                status: "pendente",
                contract_code: data.cod_contrt,
                type: data.type,
                customer_name: data.name_client,
                customer_cpf: data.cpf_client,
                link: data.short_link,
                token: data.token,
                product_name: data.name_product,
                business: data.business,
                expires_at: moment().add(15, "days")
              })
              setIsModalVisibilityDetail(true)
              listTokens();
              // setVisibleModal({ type: "details", payload: true })
            }
          }}
        />
      </Main>
    </>
  );
}

export default Recurrence;
