export function timeDiff(date1, date2 = new Date()) {
  console.log(date1);
  console.log(date2);
  const diffMs = date2 - date1;
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000);
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
  return diffHrs + "h " + diffMins + "m";
}

function adicionaZero(numero) {
  if (numero <= 9)
    return "0" + numero;
  else
    return numero;
}

export function formatDateProtheus(date) {
  return date.getFullYear() + adicionaZero(date.getMonth() + 1).toString() + adicionaZero(date.getDate().toString())
}

export const formtDate = (data) => {
  const date = new Date(`${data.replace(" ", "T")}:00`);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  const formatted = `${day}/${month}/${year}`;

  return formatted;
};