import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, message, Row, Col, Button, Divider, Table, Pagination } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

import { listUser, alterUser } from "../../../../../store/ducks/users";

import MainCrud from "../../../../../components/MainCrud";
import Main from "../../../../Layout/Main";
import { ModalApp } from "../../../../../components/Modal";

import { useDebounce } from "../../../../../utils/useDebounce";
import MenuComponent from "../../../../../components/MenuComponent";
import { itensMenu } from "../itens-menu";
import moment from "moment";

import { OfflineAuthorization } from "../../../../../services/offlineAuthorization";


function SearchAuthorization(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState({  deceased_cpf: "", deceased_name: "", contract_code: ""});

  const signin = useSelector((state) => state.signin);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  // Evita que seja feita um requisição a cada novo caracter digitado na pesquisa
  const debouncedSearchQuery = useDebounce(searchQuery, 400);

  useEffect(() => {
    list();
  }, [pagination, debouncedSearchQuery]);


  function list() {
    try {
      setLoading(true);

      const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
      OfflineAuthorization
        .findAuthorization(
          {...searchQuery, page: pagination.current - 1, limit: pagination.pageSize}
        )
        .then((response) => {
          const pagi = pagination 
          pagi.total = response.count
          setPagination(pagi)
          setData(response); 
        });
    } catch (error) {
      message.error("Ops! Aconteceu um erro ao carregar os dados.");
    } finally {
      setLoading(false);
    }
  }


  function handleChangeSearchQuery(e) {
    const { name, value } = e.target;
    setSearchQuery({ ...searchQuery, [name]: value });

    console.log(searchQuery)
  }

  // filtrar os usuários no backend
  const getColumnSearchApi = (column) => ({
    filterDropdown: () => (
      <div className="divSearch">
        <Input
          style={{ marginBottom: 8 }}
          placeholder="Pesquisar..."
          value={searchQuery[column]}
          onChange={handleChangeSearchQuery}
          name={column}
        />
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

 
  const breadcrumb = [
    {
      title: "Central de Atendimento ao Cliente",
      path: props.modulePath,
    },
    {
      title: "Consulta Auxiliar",
      path: props.modulePath + "/offline-search",
    },
    {
      title: "Autorizações",
      path: props.modulePath + "/offline-search/authorization",
    },
  ];

  const columns = [
    {
      title: "Empresa",
      dataIndex: "company",
      key:"company",
    },
    {
      title: "Filial",
      dataIndex: "branch",
      key:"branch",
    },
    {
      title: "Data da Autorização",
      dataIndex: "authorization_date",
      render: (date) =>{
        return moment(date).format("DD/MM/YYYY") + " às " + moment(date).format("h:mm") ;
      }
    },
    {
      title: "Usuário",
      dataIndex: "user_name",
    },
    {
      title: "CPF do Falecido",
      dataIndex: "deceased_cpf",
      ...getColumnSearchApi("deceased_cpf"),
    },
    {
      title: "Nome do Falecido",
      dataIndex: "deceased_name",
      ...getColumnSearchApi("deceased_name"),
    },
    {
      title: "Contrato",
      dataIndex: "contract_code",
      ...getColumnSearchApi("contract_code"),
    },
    {
      title: "Data de Nascimento",
      dataIndex: "birth_date",
      render: (date) =>{
        return moment(date).format("DD/MM/YYYY");
      }
    },
    {
      title: "Status",
      dataIndex: "authorization_status",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Autorizações - webGM</title>
      </Helmet>
      <Main
        key={props.moduleName}
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
          modulePath={props.modulePath}
          selectedMenu="1"
          itensMenu={itensMenu}
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"none"}
    >  
        <Table
            dataSource={data.rows}
            columns={columns}
            loading={loading}
            pagination={pagination}
            onChange={(pagi)=>{
              setPagination(pagi)
            }}
            classTag={[]}
            displayMainCrud="none"
        />
    </Main>
    </> 
  );
}


export default SearchAuthorization;
