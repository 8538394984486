export const itensMenu = [
  {
    key: 0,
    permission: "access_menu_home",
    url: "/",
    title: "Início",
    icon: "HomeOutlined",
  },
  {
    key: 1,
    permission: "access_menu_negotiation",
    url: "/negotiation",
    title: "Negociação",
    icon: "PercentageOutlined",
  },
  {
    key: 2,
    permission: "access_menu_payment_link",
    url: "/payment-link",
    title: "Pagamento por Link",
    icon: "LinkOutlined",
  },
  {
    key: 3,
    permission: "access_menu_recurrence",
    url: "/recurrence",
    title: "Recorrência",
    icon: "CreditCardOutlined",
  },
  {
    key: 4,
    permission: "access_menu_payment_pix",
    url: "/payment-pix",
    title: "Pix",
    icon: "QrcodeOutlined",
  },
  {
    key: 5,
    permission: "access_menu_schedule_monitor",
    url: "/schedule-monitor",
    title: "Monitor de Schedules Protheus",
    icon: "AreaChartOutlined",
  }
];
