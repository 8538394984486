/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { useEffect } from "react";
import { useState } from "react";
import { useDebounce } from "../utils/useDebounce";

/**
 * 
 * Necessário importar o script do google maps no componente que utilizará o hook
 * @example useScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=console.log`) 
 */
export const handleSearchPredictions = async (value, location, callback) => {
    try {
        const autocompleteService =
            new google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions(
            {
                input: value,
                bounds:
                    location &&
                    new google.maps.LatLngBounds(
                        new google.maps.LatLng(location.lat, location.long)
                    ),
                language: "pt_BR",

                componentRestrictions: {
                    country: "br",
                },
            },
            (predictions, status) => {
                if (
                    status !== window.google.maps.places.PlacesServiceStatus.OK
                ) {
                    console.error(status);
                    callback([]);
                    return;
                }
                callback(predictions);
            }
        );
    } catch (error) {
        console.error("[handleSearchPredictions] error", error);
    }
};

/**
 * 
 * Necessário importar o script do google maps no componente que utilizará o hook
 * @example useScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=console.log`) 
 */
export const getPlaceDetails = (place_id) => {
    try {
        let geocoderService = new google.maps.Geocoder();

        return new Promise((resolve, reject) => {
            geocoderService.geocode(
                { placeId: place_id },
                (results, status) => {
                    if (
                        status !==
                        window.google.maps.places.PlacesServiceStatus.OK
                    ) {
                        console.error(status);
                        reject(status);
                        return;
                    }

                    const { address_components } = results[0];

                    const address = address_components.find((component) =>
                        component.types.includes("route")
                    );
                    const district = address_components.find((component) =>
                        component.types.includes("sublocality_level_1")
                    );
                    const city = address_components.find((component) =>
                        component.types.includes("administrative_area_level_2")
                    );
                    const state = address_components.find((component) =>
                        component.types.includes("administrative_area_level_1")
                    );
                    const zipCode = address_components.find((component) =>
                        component.types.includes("postal_code")
                    );
                    const streetNumber = address_components.find((component) =>
                        component.types.includes("street_number")
                    );
                    resolve({
                        address: address?.long_name,
                        district: district?.long_name,
                        city: city?.long_name,
                        state: state?.short_name,
                        cep: zipCode?.long_name,
                        number: streetNumber?.long_name,
                    });
                }
            );
        });
    } catch (error) {
        console.error("[getPlaceDetails] error", error);
    }
};

export const usePlacesAutocomplete = ({ location }) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    if (debouncedValue && debouncedValue.length > 2) {
      handleSearchPredictions(debouncedValue, location, (predictions) => {
        setSuggestions(predictions);
      });
    } else {
      setSuggestions([]);
    }
  }, [debouncedValue]);

  return {
    suggestions,
    setValue,
  };
};
