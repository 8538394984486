import { Select } from "antd";
// import { isOwner } from "../../utils/owner";

const { Option } = Select;

export const getUsers = ({ signin, appointment }) => {

  return appointment.usersProfile.map((user) => {
    return (
      <Option key={user.id} value={user.id}>
        {user.name}
      </Option>
    )
  });
}




// export const getUsers = (signin, appointment) => {
//   return isOwner(signin.user.profiles) &&
//     appointment.usersProfile.length !== 0 ? (
//     appointment.usersProfile.map((user) => (
//       <Option key={user.id} value={`${user.id}-${user.name}`}>
//         {user.name}
//       </Option>
//     ))
//   ) : (
//     <Option
//       key={signin.user.id}
//       value={`${signin.user.id}-${signin.user.name}`}
//     >
//       {signin.user.name}
//     </Option>
//   );
// };