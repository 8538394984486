export const Types = {
  OPEN: "OPEN",
  CLOSE: "CLOSE",
  OPEN_SECONDARY_DRAWER: "OPEN_SECONDARY_DRAWER",
  CLOSE_SECONDARY_DRAWER: "CLOSE_SECONDARY_DRAWER",
};

const INITIAL_STATE = {
  open_drawer: false,
  open_drawer_secondary: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.OPEN:
      return {
        ...state,
        open_drawer: action.payload,
      };

    case Types.CLOSE:
      return {
        ...state,
        open_drawer: action.payload,
      };

    case Types.OPEN_SECONDARY_DRAWER:
      return {
        ...state,
        open_drawer_secondary: action.payload,
      };

    case Types.CLOSE_SECONDARY_DRAWER:
      return {
        ...state,
        open_drawer_secondary: action.payload,
      };

    default:
      return state;
  }
}

export function openDrawer(data) {
  return {
    type: Types.OPEN,
    payload: data,
  };
}

export function closeDrawer(data) {
  return {
    type: Types.CLOSE,
    payload: data,
  };
}

export function openSecondaryDrawerData(data) {
  return {
    type: Types.OPEN_SECONDARY_DRAWER,
    payload: data,
  };
}

export function closeSecondaryDrawerData(data) {
  return {
    type: Types.CLOSE_SECONDARY_DRAWER,
    payload: data,
  };
}
