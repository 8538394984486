import { getRequest } from "../utils/api";

export const SettingServicesProductService = {
  getProductsBySettingService: async (idSettingService) => {
    return await getRequest(
      `/getProductsBySettingService?idSettingService=${idSettingService}`,
      {}
    );
  },
};
