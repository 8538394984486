import React from "react";

import { Table, Button, Switch } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { checkControl } from "../../utils/access_control";

function MainCrud(props) {
  return (
    <>
      <div
        className="buttonsTable"
        style={{ display: props.displayMainCrud || "flex" }}
      >
        <div>
          <h1>{props.title}</h1>
        </div>
        <div>
          <span style={checkControl(props.profiles, props.classTag[1])}>
            <Switch
              onChange={props.activeSwitch}
              style={{ marginRight: "5px" }}
            />
            Exibir inativos
          </span>
          <Button
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => props.handleOpenModal(null)}
            style={{
              ...checkControl(props.profiles, props.classTag[0]),
              marginLeft: "20px",
            }}
          >
            Novo
          </Button>
        </div>
      </div>
      <Table
        columns={props.columnsTable}
        dataSource={props.dataSource}
        rowKey={(record) => record.id}
        loading={props.loading}
        scroll={{ x: 750 }}
        pagination={{
          current: props.page,
          pageSize: props.rowsPerPage,
          showSizeChanger: true,
          defaultPageSize: 5,          
          onShowSizeChange: (current, pageSize) => {
            props.handlerShowSizeChange(current, pageSize);
          },
          pageSizeOptions: [5, 10, 50, 100],
          total: props.countPages,
        }}
        size="small"
        onChange={props.handleTableChange}
        onRow={props.handleOpenModal ? (record, rowIndex) => {
          return {
            onClick: (event) => {
              props.handleOpenModal(record);
            },
          }
        }
          :
          null
        }
      />
    </>
  );
}

export default MainCrud;
