import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";

import { closeModalSecondary } from "../../store/ducks/modalGlobal";

export const SecondaryModalApp = (props) => {
  const modal = useSelector((state) => state.modalGlobal);
  const dispatch = useDispatch();

  return (
    <Modal
      title={props.title}
      centered
      visible={modal.open_modal_secondary}
      onOk={() => dispatch(closeModalSecondary(false))}
      onCancel={() => dispatch(closeModalSecondary(false))}
      width={props.width}
      footer={props.footer}
      bodyStyle={
        props.bodyStyle
          ? {
            height: props.height || 350,
            overflowY: "auto"
          }
          : {}
      }
    >
      {props.children}
    </Modal>
  );
};
