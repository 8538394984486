import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Popconfirm,
  Spin,
  message,
  Switch,
} from "antd";

import {
  alterSector,
  deleteSector,
  addSector,
} from "../../../../../../store/ducks/sectors";

import { SectorsService } from "../../../../../../services/sectors";

import { closeModal } from "../../../../../../store/ducks/modalGlobal";

const validateMessages = {
  required: "${label} é obrigatório!",
};

const FormApp = (props) => {
  const [loading, setLoading] = useState(false);

  const sector = useSelector((state) => state.sectors.sector);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [sector]);

  const handleSubmit = (values) => {
    setLoading(true);

    if (!sector.id) {
      SectorsService.create(values)
        .then((res) => {
          dispatch(addSector(res));
          dispatch(alterSector({}));
          dispatch(closeModal(false));
          message.success("Setor criado com sucesso!");
        })
        .catch((error) => {
          message.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const body = {
        ...sector,
        ...values,
      };

      SectorsService.update(body)
        .then((res) => {
          dispatch(addSector(res));
          dispatch(alterSector({}));
          dispatch(closeModal(false));
          message.success("Setor alterado com sucesso!");
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const remove = () => {
    setLoading(true);

    SectorsService.delete(sector.id)
      .then((res) => {
        dispatch(deleteSector(res));
        dispatch(alterSector({}));
        dispatch(closeModal(false));
        message.success("Setor inativado com sucesso!");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Form
        layout="vertical"
        form={form}
        name="form-sector"
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        initialValues={{
          ["name"]: sector.name,
          ["description"]: sector.description,
        }}
      >
        <Row>
          <Col lg={24}>
            <Form.Item
              name="name"
              label="Nome do Setor"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Digite o nome do setor" />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              name="description"
              label="Descrição do Setor"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Digite descrição do setor" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={24} xl={12}>
            {sector.id && !sector.active ? (
              <Form.Item name="active" valuePropName="checked">
                <Switch checkedChildren="Ativo" unCheckedChildren="Inativo" />
              </Form.Item>
            ) : null}
          </Col>
        </Row>
        <Row justify="end" align="bottom">
          <Col>
            {sector.active ? (
              <Popconfirm
                title="Deseja inativar o setor?"
                onConfirm={remove}
                okText="Sim"
                cancelText="Não"
              >
                <Button type="danger">Inativar</Button>
              </Popconfirm>
            ) : null}

            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "8px",
              }}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default FormApp;
