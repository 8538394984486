import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Spin,
} from "antd";
import { useCompany } from "..";
import { deletePaymentPixMutation } from "../../../../../services/paymentPix";
import { useMutate } from "../../../../../utils/useMutate";

export const CancelModal = ({ open, payment = {}, setOpen, onSuccess }) => {
  const { mutate: deletePayment, loading } = useMutate(
    deletePaymentPixMutation,
    {
      onSuccess: () => {
        onSuccess();
        notification.success({
          message: "Cancelamento realizado com sucesso",
        });
        setOpen(false);
      },
      onError: (e) => {
        notification.error({
          message: "Erro ao cancelar pagamento",
        });
      },
    }
  );

    const {company_erp, subsidiary_erp } = useCompany()

  function handleSubmit(values) {
    deletePayment({
      params: {
        ...values,
        proposal: payment.proposal,
      },
      headers: {
        tenantId: `${company_erp},${subsidiary_erp}`,
      },
    });
  }
  return (
    <Modal
      destroyOnClose={true}
      onCancel={() => setOpen(false)}
      open={open}
      title={`Cancelamento de PIX`}
      footer={null}
    >
      <Spin spinning={loading} tip="Aguarde...">
        <Descriptions style={{ marginBottom: "1rem" }} bordered size="small">
          <Descriptions.Item label="Nº da proposta" span={3}>
            {payment.proposal}
          </Descriptions.Item>
          <Descriptions.Item label="Tipo da proposta" span={3}>
            {payment.typeProposal}
          </Descriptions.Item>
          <Descriptions.Item label="Negócio da proposta" span={3}>
            {payment.businessProposal}
          </Descriptions.Item>
          <Descriptions.Item label="Consultor" span={3}>
            {payment.nameConsultant}
          </Descriptions.Item>
        </Descriptions>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{ numProposal: payment.numProposal }}
        >
          <Form.Item
            label="Motivo do cancelamento"
            rules={[
              {
                required: true,
                message: "Campo obrigatório",
              },
            ]}
            name="justificationCancellation"
          >
            <Input.TextArea placeholder="Motivo do cancelamento" />
          </Form.Item>
          <Row gutter={[8, 0]} justify="end">
            <Col>
              <Button onClick={() => setOpen(false)} type="default">
                Voltar
              </Button>
            </Col>
            <Col>
              <Button htmlType="submit" type="primary">
                Enviar
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};
