import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const listProfileApi = {
  listProfile: (limit = 0, offset = 0, active = true) => {

    var search = "";

    if (limit > 0) {
      search = `&limit=${limit}&offset=${offset}`;
    }

    return getRequest(
      `/profiles?active=${active + search}`,
      {}
    );
  }
};

export const addProfileApi = {
  addProfile: (body) => postRequest("/profiles", body),
};

export const updateProfileApi = {
  updateProfile: (body) => putRequest("/profiles/" + body.id, body),
};

export const deleteProfileApi = {
  deleteProfile: (id) => deleteRequest("/profiles/" + id, {}),
};

export const filterProfileApi = {
  filterProfile: (param) => getRequest(`/profile/filter?param=${param}`, {}),
};
