export const Types = {
  GET_TASKS: "GET_TASKS",
};

const INITIAL_STATE = {
  tasks: []
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_TASKS:
      return {
        ...state,
        tasks: action.payload,
      };
    default:
      return state;
  }
}

export function getTasksOrderService(data) {
  return {
    type: Types.GET_TASKS,
    payload: data,
  };
}