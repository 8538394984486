import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";

import {
  Form,
  Input,
  Select,
  Button,
  Checkbox,
  Radio,
  Row,
  Col,
  message,
  Popconfirm,
} from "antd";

import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { closeModal } from "../../../../../../store/ducks/modalGlobal";

import {
  addItemApi,
  deleteItemApi,
  updateItemApi,
} from "../../../../../../services/formItem";

import {
  addItem,
  alterItem,
  updateItem,
  deleteItem,
} from "../../../../../../store/ducks/formItens";

import {
  addAlternativeApi,
  deleteAlternativeApi,
} from "../../../../../../services/alternative";

import {
  addAlternative,
  deleteAlternative,
} from "../../../../../../store/ducks/alternatives";

import { alterForm } from "../../../../../../store/ducks/forms";

import { checkControl } from "../../../../../../utils/access_control";

const { Option } = Select;

const validateMessages = {
  required: "${label} é obrigatório!",
};

function FormItem(props) {
  const signin = useSelector((state) => state.signin);
  const ItemTypes = useSelector((state) => state.ItemTypes);
  const item = useSelector((state) => state.formItems.item);
  const dataForm = useSelector((state) => state.forms.form);

  const [renderAlternative, setRenderAlternative] = useState("none");
  const [renderRadio, setRenderRadio] = useState(
    item.indicator ? "flex" : "none"
  );
  const [inputsOption, setInputsOptions] = useState([]);
  const [qtdInputs, setQtdInputs] = useState(0);
  const [qtdInputsFixed, setQtdInputsFixed] = useState(1);
  const [loading, setLoading] = useState(false);
  const [checkedIndicator, setCheckedIndicator] = useState(item.indicator);
  const [checkedRequired, setCheckedRequired] = useState(item.required);
  const [radioButton, setRadioButton] = useState(null);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    isChecked();
  }, [item]);

  useEffect(() => {}, [qtdInputs]);

  // check if checkboxs and radio buttons is checked or no
  const isChecked = () => {
    if (item.indicator) {
      setCheckedIndicator(true);
      setRenderRadio("flex");
      if (item.indicator_type) {
        setRadioButton(item.indicator_type);
      }
    } else {
      setCheckedIndicator(false);
      setRenderRadio("none");
    }

    setCheckedRequired(item.required);

    if (item.Alternatives) {
      setRenderAlternative("flex");
      setInputsOptions(item.Alternatives);
      setQtdInputs(item.Alternatives.length);
    } else {
      setRenderAlternative("none");
      setInputsOptions([]);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    let alternatives = [];
    const type = values.id_type;

    if (renderAlternative === "flex") {
      const keys = Object.keys(values);
      alternatives = keys.map((item) => {
        if (item.match(/\d+/)) {
          return {
            description: values[item],
            sequence: item.split("_")[1],
          };
        }
      });

      keys.map((item) => {
        if (item.match(/\d+/)) {
          delete values[item];
        }
      });
    }

    values.id_type =
      ItemTypes.itemTypeList && ItemTypes.itemTypeList.length !== 0
        ? ItemTypes.itemTypeList
            .filter((item) => item.description === values.id_type)
            .map((type) => type.id)[0]
        : item.id_type;

    if (!values.indicator) {
      values.indicator = false;
      values.indicator_type = null;
    } else if (values.indicator === "1") {
      values.indicator = true;
      values.indicator_type = 1;
    } else if (values.indicator === "2") {
      values.indicator = true;
      values.indicator_type = 2;
    }

    values.required = checkedRequired ? true : false;
    values.sequence = props.sequence;

    /**
     * Verifica se o formulário já existe ou não.
     * Caso o formulário não tenha ID, os itens só serão salvos quando o formulário
     * for salvo para poder ter o ID para enviar junto do cadastro do item
     */
    if (!dataForm.id) {
      // envia os dados para o componente de formulário
      props.newItemForm({
        ...values,
        description_type: type,
        alternatives: alternatives,
      });
    } else {
      // cria um novo item
      if (!item.id) {
        addItemApi
          .addItem({ ...values, id_form: dataForm.id })
          .then((res) => {
            if (!res.title || !res.sequence) {
              throw {
                message:
                  "Desculpe! ocorreu um erro ao criar o item, favor tente novamente",
              };
            }

            dispatch(addItem(res));

            props.itensForm({
              ...res,
              ItemType: {
                description: type,
              },
            });

            if (alternatives.length !== 0) {
              onSalveAlternatives(res.id, alternatives);
            }
            message.success("Item criado com sucesso!");
          })
          .catch((err) => {
            message.error(err.message);
          });
      } else {
        delete values.sequence;

        const body = {
          ...item,
          ...values,
          id_form: dataForm.id,
        };

        delete body.alternatives;

        updateItemApi
          .updateItem(body)
          .then(async (res) => {
            if (!res.title || !res.sequence) {
              throw {
                message:
                  "Desculpe! ocorreu um erro ao alterar o item, favor tente novamente",
              };
            }

            const newItems = await dataForm.Items.map((value) => {
              if (value.id === res.id) {
                return {
                  ...res,
                  ItemType: {
                    description: type,
                  },
                };
              } else {
                return value;
              }
            });

            dispatch(updateItem(res));
            dispatch(
              alterForm({
                ...dataForm,
                Items: newItems,
              })
            );

            if (alternatives.length !== 0) {
              onSalveAlternatives(res.id, alternatives);
            }

            message.success("Item atualizado com sucesso!");
          })
          .catch((err) => message.error(err.message));
      }
    }

    dispatch(alterItem({}));
    dispatch(closeModal(false));
    form.resetFields();
    setRenderAlternative("none");
    setInputsOptions([]);
    setQtdInputs(0);
    setQtdInputsFixed(0);
    setLoading(false);
  };

  const onSalveAlternatives = async (id_item, data) => {
    data.map((item) => {
      if (item) {
        addAlternativeApi
          .addAlternative({
            ...item,
            id_item,
          })
          .then((res) => {
            dispatch(addAlternative(res));
            return res.id;
          })
          .catch((err) => {
            message.error(err.message);
          });
      }
    });
  };

  const addInputsOption = () => {
    setQtdInputsFixed(qtdInputsFixed + 1);

    const value = {
      description: `option_${qtdInputsFixed}`,
    };

    if (inputsOption.length === 0) {
      setInputsOptions([value]);
    } else {
      const inputs = inputsOption;
      inputs.push(value);
      setInputsOptions(inputs);
    }

    setQtdInputs(inputsOption.length);
  };

  const removeInputsOption = (value) => {
    setLoading(true);
    if (inputsOption.length !== 0) {
      const inputs = inputsOption.filter((item) => {
        return item.description !== value;
      });

      const data = inputsOption.filter((item) => {
        if (item.description === value) {
          return value;
        }
      });

      deleteAlternativeApi.deleteAlternative(data[0].id).then((response) => {
        dispatch(deleteAlternative(response));
      });

      setInputsOptions(inputs);
      setQtdInputs(inputs.length);
      setLoading(false);
    }
  };

  const renderNewInput = () => {
    return inputsOption && inputsOption.length !== 0
      ? inputsOption.map((input) => {
          return (
            <Form.Item
              name={input.description}
              key={input.description}
              initialValue={input.description}
              rules={[
                {
                  required: renderAlternative === "flex" ? true : false,
                },
              ]}
            >
              <Row>
                <Col offset={1}>
                  <Button
                    type="text"
                    onClick={() => removeInputsOption(input.description)}
                    icon={<MinusCircleOutlined />}
                    style={{ color: "#eb2f96" }}
                  ></Button>
                </Col>

                <Col span={20}>
                  {!input.description.includes("option_") ? (
                    <Input value={input.description} />
                  ) : (
                    <Input />
                  )}
                </Col>
              </Row>
            </Form.Item>
          );
        })
      : null;
  };

  const selectType = (value) => {
    const isMultiple = ItemTypes.itemTypeList.filter(
      (item) => item.description === value
    )[0].is_multiple;

    if (isMultiple) {
      setRenderAlternative("flex");
    } else {
      setRenderAlternative("none");
      setInputsOptions([]);
      setQtdInputs(0);
      setQtdInputsFixed(0);
    }
  };

  const checkIndicator = (e) => {
    setCheckedIndicator(e.target.checked);
    if (renderRadio === "flex") {
      setRenderRadio("none");
    } else {
      setRenderRadio("flex");
    }
  };

  const removeItem = () => {
    setLoading(true);
    deleteItemApi.deleteItem(item.id).then(async (response) => {
      const newItems = dataForm.Items.filter((value) => value.id !== item.id);

      dispatch(
        alterForm({
          ...dataForm,
          Items: newItems,
        })
      );

      dispatch(deleteItem(response));
      setLoading(false);
      dispatch(alterItem({}));
      dispatch(closeModal(false));
      message.success("Item excluído com sucesso!");
    });
  };

  const selectRadio = (e) => {
    setRadioButton(e.target.value);
  };

  // fill the initial values of form
  const generateInitialValue = () => {
    const data = {
      title: item.title,
      indicator: checkedIndicator,
      indicator_type: radioButton || null,
      required: checkedRequired,
      id_type: item.ItemType ? item.ItemType.description : null,
    };

    return data;
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="formItem"
      onFinish={onFinish}
      validateMessages={validateMessages}
      initialValues={generateInitialValue()}
    >
      <Form.Item
        name="title"
        label="Título"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Digite o nome do item" />
      </Form.Item>
      <Form.Item
        name="id_type"
        label="Tipo"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select placeholder="Escolha o tipo do item" onChange={selectType}>
          {ItemTypes.itemTypeList && ItemTypes.itemTypeList.length !== 0
            ? ItemTypes.itemTypeList.map((item) => {
                return (
                  <Option key={item.id} value={item.description}>
                    {item.description}
                  </Option>
                );
              })
            : []}
        </Select>
      </Form.Item>

      <Form.Item style={{ display: renderAlternative }} label="Alternativas">
        {renderNewInput()}

        <Button
          type="text"
          onClick={addInputsOption}
          icon={<PlusCircleOutlined style={{ color: "#52c41a" }} />}
        ></Button>
      </Form.Item>

      <div className="center">
        <Form.Item
          label="É Indicador ?"
          name="indicator"
          rules={[
            {
              required: renderRadio === "flex" ? true : false,
            },
          ]}
        >
          <div className="check">
            <Checkbox onChange={checkIndicator} checked={checkedIndicator} />

            <Radio.Group
              style={{ display: renderRadio }}
              value={radioButton}
              onChange={selectRadio}
              className="radio"
            >
              <Radio value={1}>Qualitativo</Radio>
              <Radio value={2}>Quantitativo</Radio>
            </Radio.Group>
          </div>
        </Form.Item>

        <Form.Item label="Item obrigatório ?" name="required">
          <Checkbox
            checked={checkedRequired}
            onChange={() => {
              if (checkedRequired) {
                setCheckedRequired(false);
              } else {
                setCheckedRequired(true);
              }
            }}
          />
        </Form.Item>
      </div>

      <div className="displayButtons">
        <Form.Item>
          {item.id ? (
            <Popconfirm
              title="Deseja excluir o item?"
              onConfirm={removeItem}
              okText="Sim"
              cancelText="Não"
            >
              <Button
                type="danger"
                style={{
                  ...checkControl(signin.user.profiles, "forms_inactivate"),
                  marginLeft: "8px",
                }}
              >
                Excluir
              </Button>
            </Popconfirm>
          ) : null}
          <Button type="primary" htmlType="submit" style={{ marginLeft: 10 }}>
            {item.id ? "Atualizar" : "Salvar"}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

export default FormItem;
