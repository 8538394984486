import {
  useState,
  useEffect,
  useContext
} from 'react';

import {
  Spin,
  Row,
  Col,
  Form,
  Button,
  DatePicker,
  Input
} from 'antd';

import locale from 'antd/es/date-picker/locale/pt_BR';

import { RemovalAnticipationFormContext } from '../../../../../../Context/removal-anticipation-form-context';


const Caveats = () => {

  const [loading, setLoading] = useState(false);

  const removalAnticipationFormContext = useContext(RemovalAnticipationFormContext);

  const [removalAnticipationCaveatsForm] = Form.useForm();

  const handleReturn = () => {
    removalAnticipationFormContext.setFormResult({
      ...removalAnticipationFormContext.formResult,
      ...removalAnticipationCaveatsForm.getFieldsValue()
    });

    removalAnticipationFormContext.setCurrent(removalAnticipationFormContext.current - 1)
  }

  const handleSubmit = (values) => {

    removalAnticipationFormContext.setFormResult({
      ...removalAnticipationFormContext.formResult,
      caveats: values.caveats
    });

    removalAnticipationFormContext.setCurrent(removalAnticipationFormContext.current + 1)

  }

  return (
    <Spin tip="Carregando..." spinning={loading}>
      <Form
        form={removalAnticipationCaveatsForm}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          caveats: {
            caveat: removalAnticipationFormContext.formResult.caveats.caveat
          }
        }}
      >
        <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
          <Col xs={24} sm={24}>
            <Form.Item
              label="Ressalvas"
              name={['caveats', 'caveat']}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Button style={{ margin: '0 8px' }} onClick={handleReturn}>
              Voltar
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
            >
              Avançar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  )
}

export default Caveats;