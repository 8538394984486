import React from "react";
import IconsHelpText from "../../../../../../assets/images/icons_help_text.png";

function HelpText() {
  return (
    <div>
      <h3>Por quais campos posso realizar uma pesquisa?</h3>
      <p>
        Em contratos: número do contrato, nome do titular ou dependente ( mínimo
        de 06 caracteres ), CPF, data de nascimento, números ou e-mail do
        titular.
      </p>
      <p>
        Em atendimento funerários: número ou data do atendimento, nome do
        declarante ou falecido ( mínimo de 06 caracteres ), CPF, data de
        nascimento ou óbito do falecido local ( mínimo de 06 caracteres ) e data
        de velório, sepultamento ou cremação.
      </p>
      <p>
        Em sepultados: número do sepultado do contrato, nome do sepultado ou
        titular ( mínimo de 06 caracteres ), data de nascimento, óbito ou
        sepultamento e endereços (S/Q/L).
      </p>
      <h3>
        Posso pesquisar somento por contrato, atendimento funerário ou
        sepultados ?
      </h3>
      <p>
        Sim. Basta utilizar os filtros básicos (A), expandir a visualização (B)
        ou utilizar a pesquisa avançada (C).
      </p>
      <img src={IconsHelpText} />
    </div>
  );
}

export default HelpText;
