/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useEffect } from "react";


export const ValidationForm = ({ fieldValues, fields, validations }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      rules: fieldValues.rules?.map((item) => ({
        id_rule_validation: item.validation?.id,
        id_depends_on_field: item.depends_on_field?.id,
      })),
      is_required: fieldValues.is_required ?? false,
      is_indicator: fieldValues.is_indicator ?? false,
      is_visible: fieldValues.is_visible ?? true,
    });
  }, [fieldValues]);

  return (
    <Form form={form} layout="vertical" name="validationForm">
      <Col span={9}>
        <Form.Item
          className="horizontal-form-item"
          label="Obrigatório"
          name="is_required"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          className="horizontal-form-item"
          label="É indicador"
          name="is_indicator"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          className="horizontal-form-item"
          label="É visivel"
          name="is_visible"
          valuePropName="checked"
        >
          <Switch defaultChecked />
        </Form.Item>
      </Col>
      <Divider orientation="left">
        <Typography.Text >Regras de validação</Typography.Text>
      </Divider>
      <Form.List name="rules">
        {(rulesFields, { add, remove }) => (
          <Card
            size="small"
            bodyStyle={{ display: "flex", flexDirection: "column", rowGap: 12 }}
            title={
              rulesFields.length === 0 ? (
                <Row justify="center">
                  <Typography.Text type="secondary">
                    Nenhuma regra de validação adicionada
                  </Typography.Text>
                </Row>
              ) : (
                <Row justify="start">
                  <Col span={10}>
                    <Typography.Text type="secondary">Regra</Typography.Text>
                  </Col>

                  <Col span={14}>
                    <Typography.Text type="secondary">
                      Campo dependente
                    </Typography.Text>
                  </Col>
                </Row>
              )
            }
          >
            {rulesFields.map((field, index) => (
              <Input.Group key={field.key}>
                <Row gutter={12} justify="space-between" align="middle">
                  <Col span={10}>
                    <Form.Item
                      style={{
                        marginBottom: 0,
                      }}
                      {...field}
                      key={`${field.key}-id_rule_validation-${index}`}
                      name={[field.name, "id_rule_validation"]}
                      rules={[
                        {
                          required: false,
                          message: "Selecione a validação",
                        },
                      ]}
                    >
                      <Select placeholder="Selecione a validação">
                        {validations.map((value) => {
                          return (
                            <Select.Option key={value.id} value={value.id}>
                              <Tooltip
                                placement="left"
                                title={
                                  <Space direction="vertical">
                                    <Typography.Text style={{ color: "white" }}>
                                      {value.description}
                                    </Typography.Text>
                                    <Typography.Text
                                      style={{ color: "white" }}
                                      code
                                    >
                                      {value.expression}
                                    </Typography.Text>
                                  </Space>
                                }
                              >
                                {value.description}
                              </Tooltip>
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...field}
                      tooltip="Campo que será referenciado como ':dep' na validação"
                      style={{
                        marginBottom: 0,
                      }}
                      key={`${field.key}-id_depends_on-${index}`}
                      name={[field.name, "id_depends_on_field"]}
                    >
                      <Select
                        allowClear
                        placeholder="Selecione o campo dependente"
                      >
                        {fields?.rows.map((value) => {
                          return (
                            <Select.Option key={value.id} value={value.id}>
                              {value.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Button
                      style={{ margin: "10 10" }}
                      size="small"
                      title="Remover"
                      danger
                      shape="circle"
                      onClick={() => remove(field.name)}
                    >
                      <MinusOutlined />
                    </Button>
                  </Col>
                </Row>
              </Input.Group>
            ))}
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
              style={
                {
                  marginTop: 12,
                  borderRadius: 5,
                }
              }
            >
              Adicionar validação
            </Button>
          </Card>
        )}
      </Form.List>
      <Row style={{ marginTop: 12 }} justify="end">
        <Col>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
