import {
  useState,
  useEffect,
} from 'react';

import {
  Row,
  Col,
  Typography,
  Input,
  Select,
  Button,
  message,
} from 'antd';

import api from "../../../../../../utils/api";

const FormFilter = (props) => {
  const [loading, setLoading] = useState(false);
  const [carriers, setCarriers] = useState([]);
  const [lote, setLote] = useState("");
  const [objeto, setObjeto] = useState("");
  const [transportadora, setTransportadora] = useState("");
  const [status, setStatus] = useState("");
  const [contract, setContract] = useState("");

  useEffect(() => {
    getCarriers();
  }, []);

  const getCarriers = async () => {
    try {

      setLoading(true);

      const results = await api.get("/carriers");

      setCarriers(results.data);
    } catch (error) {
      message.error("Não foi possível buscar os transportadores!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col xs={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Typography.Text>Código do lote:</Typography.Text>
            <Input
              placeholder="Informe o código do lote"
              value={lote}
              onChange={(e) => setLote(e.target.value)}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
          <Col xs={24}>
            <Typography.Text>Código do objeto:</Typography.Text>
            <Input
              placeholder='Informe o código do objeto'
              value={objeto}
              onChange={(e) => setObjeto((`000000000000000${e.target.value}`).slice(-15))}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
          <Col xs={24}>
            <Typography.Text>Código do contrato:</Typography.Text>
            <Input
              placeholder='Informe o código do contrato'
              value={contract}
              onChange={(e) => setContract(e.target.value)}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
          <Col xs={24}>
            <Typography.Text>Transportador:</Typography.Text>
            <Select
              showSearch
              allowClear
              placeholder="Selecione uma transportadora"
              optionFilterProp="children"
              value={transportadora}
              onChange={value => setTransportadora(value)}
              style={{ width: "100%" }}
            >
              {
                carriers.map(option => <Select.Option key={option.carrier_id} value={option.carrier_id}>{option.name}</Select.Option>)
              }
            </Select>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
          <Col xs={24}>
            <Typography.Text>Status:</Typography.Text>
            <Select
              allowClear
              placeholder="Selecione um status"
              style={{ width: "100%" }}
              value={status}
              onChange={value => setStatus(value)}
            >
              <Select.Option key="A" value="A">Aberto</Select.Option>
              <Select.Option key="G" value="G">Recebido</Select.Option>
              <Select.Option key="B" value="B">Bloqueado</Select.Option>
              <Select.Option key="T" value="T">Transito</Select.Option>
              <Select.Option key="E" value="E">Entregue</Select.Option>
              <Select.Option key="D" value="D">Devolvido</Select.Option>
              <Select.Option key="R" value="R">Recusado</Select.Option>
              <Select.Option key="C" value="C">Cancelado</Select.Option>
            </Select>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: "16px" }} justify="end">
          <Col>
            <Button
              type="primary"
              onClick={() => props.handlerFilter({
                lote,
                objeto: objeto !== "000000000000000" ? objeto : "",
                contract,
                transportadora,
                status,
              })}
            >
              Filtrar
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default FormFilter;