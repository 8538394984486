import React, { useState } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import QRCode from "react-qr-code";
import moment from "moment";

import "./styles.css";

import { Row, Col, Button } from "antd";
import { CopyOutlined, CheckCircleOutlined } from "@ant-design/icons";

import favicon from "../../../../assets/images/favicon.ico";
import logo from "../../../../assets/images/logo.png";

function Pix() {
  const [copySuccess, setCopySuccess] = useState("Copiar código do QR Code");
  const [iconCopy, setIconCopy] = useState(<CopyOutlined />);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(qrCode);
    setCopySuccess("Copiado!");
    setIconCopy(<CheckCircleOutlined />);
    const interval = setInterval(() => {
      setCopySuccess("Copiar código do QR Code");
      setIconCopy(<CopyOutlined />);
      clearInterval(interval);
    }, 1000);
  };

  let { base64 } = useParams();
  let buff = atob(base64); // new Buffer(base64, 'base64');
  let stringParams = buff.toString("utf-8");
  let jsonParams;

  try {
    jsonParams = JSON.parse(stringParams);
  } catch (error) {
    console.log(error);
  }

  let base64valid = false;
  if (!!jsonParams) {
    if (
      !!jsonParams.qrCode &&
      !!jsonParams.clientName &&
      !!jsonParams.companyName &&
      !!jsonParams.cnpj &&
      !!jsonParams.bankDescription &&
      !!jsonParams.expireDateTime
    ) {
      base64valid = true;
    } else {
      base64valid = false;
    }
  }

  let qrCode,
    clientFirstName,
    value,
    companyName,
    bankDescription,
    expireDateTime;

  if (base64valid) {
    let clientFullName = jsonParams.clientName.toLowerCase().split(" ");

    qrCode = jsonParams.qrCode;

    clientFirstName =
      clientFullName[0].charAt(0).toUpperCase() + clientFullName[0].slice(1);
    value = !!jsonParams.value ? jsonParams.value : null;
    companyName = jsonParams.companyName;
    //cnpj = jsonParams.cnpj;
    bankDescription = jsonParams.bankDescription;
    expireDateTime = jsonParams.expireDateTime;
  }

  const dateInPast = (firstDate, secondDate) => {
    if (firstDate < secondDate) {
      return true;
    }
    return false;
  };

  let expiration, today;
  let canPay = false;

  if (base64valid) {
    today = new Date();
    expiration = new Date(expireDateTime);
    canPay = dateInPast(expiration, today) ? false : true;
  }

  return (
    <>
      <Helmet>
        <title>Pagamento de Pix ao Morada da Paz</title>
        <meta
          name="description"
          content="Use o QR Code do Pix para realizar o seu pagamento."
        />
        <meta itemprop="name" content="Pagamento de Pix ao Morada da Paz" />
        <meta
          itemprop="description"
          content="Use o QR Code do Pix para realizar o seu pagamento."
        />
        <meta
          itemprop="image"
          content="https://static.moradadapaz.com.br/sites/morada-da-paz/cover-morada-green.jpg"
        />
        <meta
          property="og:url"
          content="https://servicos.moradadapaz.com.br/pix"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Pagamento de Pix ao Morada da Paz" />
        <meta
          property="og:description"
          content="Use o QR Code do Pix para realizar o seu pagamento."
        />
        <meta
          property="og:image"
          content="https://static.moradadapaz.com.br/sites/morada-da-paz/cover-morada-green.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Pagamento de Pix ao Morada da Paz"
        />
        <meta
          name="twitter:description"
          content="Use o QR Code do Pix para realizar o seu pagamento."
        />
        <meta
          name="twitter:image"
          content="https://static.moradadapaz.com.br/sites/morada-da-paz/cover-morada-green.jpg"
        />
        <link id="favicon" rel="icon" type="image/x-icon" href={favicon} />
        {/* AdOpt */}
        <meta
          name="adopt-website-id"
          content="ac856dca-aa6d-47fb-a2d8-91d42a2fa6f1"
        />
        <script
          src="//tag.goadopt.io/injector.js?website_code=ac856dca-aa6d-47fb-a2d8-91d42a2fa6f1"
          class="adopt-injector"
        ></script>
      </Helmet>
      {base64valid && canPay ? (
        <div className="container">
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <img src={logo} width={200} alt="Morada da Paz" />
              <div className="box-title">
                {value ? (
                  <>Pagamento de Pix no valor de R$ {value} ao Morada da Paz.</>
                ) : (
                  <>Pagamento de Pix ao Morada da Paz.</>
                )}
              </div>
              <div className="box-expiration">
                Realize o pagamento até
                <br />
                <span>{moment(expireDateTime).format("DD/MM/YYYY HH:mm")}</span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="box-qr">
                <span>
                  Sr(a): {clientFirstName}, use o QR Code do Pix para pagar
                </span>
                <br />
                <br />
                Abra o app em que vai fazer a transferência, escaneie a imagem
                ou cole o código do QR Code
                <br />
                <br />
                <div className="qrcode">
                  <QRCode size={200} value={qrCode} />
                </div>
                {value ? (
                  <>
                    <br />
                    <span>R$ {value}</span>
                    <br />
                  </>
                ) : null}
                <br />
                <Button
                  style={{ color: "#71bf43", height: 50, borderRadius: 10 }}
                  icon={iconCopy}
                  size="large"
                  onClick={copyToClipboard}
                >
                  {copySuccess}
                </Button>
                <br />
                <br />
                Ou copie manualmente:
                <br />
                <br />
                {qrCode}
                <br />
              </div>
              <div className="box-infos">
                <span>Confira as informações no app:</span>
                <br />
                <br />
                <hr />
                <br />
                <Row>
                  <Col
                    span={6}
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    Nome:
                  </Col>
                  <Col span={18} style={{ textAlign: "right" }}>
                    {companyName}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col
                    span={6}
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    Banco:
                  </Col>
                  <Col span={18} style={{ textAlign: "right" }}>
                    {bankDescription}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <>
          {base64valid && !canPay ? (
            <div className="container">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <img src={logo} width={200} alt="Morada da Paz" />
                  <div className="box-title red-text-1">
                    Este link de pagamento expirou!
                  </div>
                  <div className="box-expiration red-text-2">
                    O prazo para pagamento era até{" "}
                    {moment(expireDateTime).format("DD/MM/YYYY HH:mm")}.
                    Solicite um novo link de pagamento.
                    <br />
                    <br />
                    Central de Atendimento ao Cliente:
                    <br />
                    <br />
                    <span>0800 123 4848</span>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="container">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <img src={logo} width={200} alt="Morada da Paz" />
                  <div className="box-title red-text-1">
                    Link de pagamento inválido!
                  </div>
                  <div className="box-expiration red-text-2">
                    Verifique se você copiou o link fornecido corretamente, ou
                    entre em contato com nossa Central de Atendimento ao
                    Cliente:
                    <br />
                    <br />
                    <span>0800 123 4848</span>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Pix;
