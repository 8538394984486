/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from "react-helmet";
import favicon from "../../../../../assets/images/favicon.ico";
import { isMobile } from "../../../../../utils/isMobile";
import logo from "../../../../../assets/images/logo.png";

import { Col, Layout, Row } from "antd";
import styles from "./styles.module.css";

import { Telephone } from "react-bootstrap-icons";
import { WhatsAppOutlined } from "@ant-design/icons";

const { Header, Content, Footer } = Layout;



/**
 * Componente padrão para o layout das páginas de recorrência.
 * @param {Object} props As propriedades do componente.
 * @param {React.ReactNode} props.children Conteúdo da página.
 * @param {string} props.pageTitle Título da página de recorrência.
 * @returns {JSX.Element} O componente de layout de recorrência.
 */
export function RecurrenceLayout({ children, pageTitle }) {
    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>

                <link
                    id="favicon"
                    rel="icon"
                    type="image/x-icon"
                    href={favicon}
                />

                {/* AdOpt */}
                <meta
                    name="adopt-website-id"
                    content="ac856dca-aa6d-47fb-a2d8-91d42a2fa6f1"
                />
                <script
                    src="//tag.goadopt.io/injector.js?website_code=ac856dca-aa6d-47fb-a2d8-91d42a2fa6f1"
                    class="adopt-injector"
                ></script>
            </Helmet>

            <div className={styles.layoutContainer}>
                <Header className={styles.pageHeader}>
                    <div className="logoImg">
                        <a
                            href="https://www.moradadapaz.com.br/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                alt="Logo"
                                src={logo}
                                width={isMobile() ? 180 : 200}
                            />
                        </a>
                    </div>
                    <Row className={styles.contactWrapper} align="middle">
                        <Col className="icone-phone-1">
                            <span>
                                Central de Atendimento 24h:&nbsp;&nbsp;&nbsp;
                            </span>
                            <a
                                className="icon-color"
                                href="tel:08001234848"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Telephone /> 0800 123 4848
                            </a>
                        </Col>

                        <Col className="icone-phone-2">
                            <a
                                className="icon-color"
                                href="https://wa.me/5508001234848"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <WhatsAppOutlined /> Fale no WhatsApp
                            </a>
                        </Col>
                    </Row>
                </Header>
                <Content className={styles.content}>{children}</Content>
                <Footer className={styles.pageFooter}>
                    <strong>
                        &copy; {new Date().getFullYear()} Morada da Paz.
                    </strong>{" "}
                    Todos os direitos reservados.{" "}
                    <a
                        className="icon-color"
                        href="https://www.moradadapaz.com.br/politica-de-privacidade"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Política de Privacidade
                    </a>
                    <br />
                    <small>
                        Serpos Servicos Postumos LTDA - 04.811.849/0001-04 - R
                        Presidente Joao Goulart, 2118 - Vila Torres Galvão -
                        Paulista, Pernambuco - Brasil.
                    </small>
                </Footer>           
            </div>
        </>
    );
}
