import { useContext, useState } from "react";
import { Row, Col, Button, Table, message } from "antd";
import { } from "@ant-design/icons";

import "./styles.css";

import { NegotiationContext } from "../../../../Context/negotiation-context";
import Message from "../Message";

import { duplicateBilletApi } from "../../../../services/duplicateBillet";
import loadingSVG from "../../../../assets/images/loading.svg";

function ContractList(props) {
  const [loading, setLoading] = useState(false);

  const negotiationContext = useContext(NegotiationContext);

  // busca as parcelas do cliente
  function renderNextButton(data) {
    setLoading(true);

    duplicateBilletApi
      .getBillet(data.CONTRATO, data.TIPOCONTRT, {
        headers: {
          tenantId: `${data.EMPRESA},${data.FILIAL}`,
        },
      })
      .then((response) => {
        if (!response)
          return message.warning("Não encontramos nenhum boleto em aberto!");
        else {

          negotiationContext.setData({
            ...negotiationContext.data,
            contract: {
              ...data,
              FORMA_PAGTO: data.FORMA_PAGTO,
              installments: response,
            },
          });

          negotiationContext.setCurrent(negotiationContext.current + 1);
        }
      })
      .catch(() =>
        message.error(
          "Ops... Parece que estamos enfrentando problemas em nossos servidores!"
        )
      )
      .finally(() => setLoading(false));
  }

  function renderBackButton() {
    negotiationContext.setCurrent(negotiationContext.current - 1);
  }

  const columnsContract = [
    {
      title: "Contrato",
      dataIndex: "CONTRATO_EMPRESA",
      key: "CONTRATO_EMPRESA",
    },
    {
      title: "Titular",
      dataIndex: "CLIENTE",
      key: "CLIENTE",
    },
    {
      align: "right",
      render: (data) => (
        <Button
          style={{ background: "#1890ff", color: "#FFF", borderRadius: 5 }}
          onClick={() => renderNextButton(data)}
          loading={loading}
        >
          {loading ? <>&nbsp; AGUARDE...</> : "VER BOLETOS"}
        </Button>
      ),
    },
  ];

  const ContractData = () => {
    if (negotiationContext.data.contracts.length === 0)
      return (
        <Message
          title="Não encontramos nenhum contrato :("
          text="Verifique se você informou o CPF ou nº do contrato corretamente."
        />
      );

    return (
      <Table
        columns={columnsContract}
        dataSource={negotiationContext.data.contracts}
        size="small"
        pagination={false}
        rowKey={(record) => record.CONTRATO}
      />
    );
  };

  return (
    <>
      <Row>
        <Col span={24}>
          {loading ? (
            <>
              <div className="loading-centar">
                <img src={loadingSVG} alt="loading" height={80} />
              </div>
              <h2 className="text-loading">Por favor, aguarde...</h2>
            </>
          ) : (
            <ContractData />
          )}
        </Col>
      </Row>
      <Row justify="end" className="margin-class-top">
        <Col>
          <Button type="default" onClick={() => renderBackButton()}>
            Voltar
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default ContractList;
