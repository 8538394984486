import { getRequest, postRequest } from "../utils/api";

export const OsMovementsService = {
  getMovements: async (service_id) => {
    return await getRequest(`/movements?service_id=${service_id}`, {});
  },

  create: async (body) => {
    return await postRequest("/movements", body);
  },
};
