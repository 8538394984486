import React, { useState, useEffect } from "react";
import _, { countBy } from "lodash";
import { useSelector } from "react-redux";

import {
  Row,
  Col,
  Input,
  Divider,
  Table,
  message,
  Button,
  Checkbox,
  Spin,
  Select,
} from "antd";

import "./style.css";
import Main from "../../../Layout/Main";
import MenuComponent from "../../../../components/MenuComponent";
import StatusComponent from "../../../../components/StatusComponent";
import CardTotal from "../../../../components/CardTotal";
import { itensDrawer } from "../itens-menu.js";
import { instanceApi } from "../../../../utils/apiProtheus";
import api from "../../../../utils/api";

const step = {
  receive: {
    key: 5,
    previousState: "P",
    nextState: "G",
    textSendButton: "Receber",
    spinMessage: "Enviando objetos para recebimento",
    titleBreadcrumb: "Recebimento de Objetos",
    urlBreadcrumb: "/objects/receive-objects",
    checked: true,
  },
  carrier: {
    key: 6,
    previousState: "G",
    nextState: "T",
    textSendButton: "Enviar para Entregador",
    spinMessage: "Enviando objetos para o entregador",
    titleBreadcrumb: "Enviar para transportadora",
    urlBreadcrumb: "/objects/send-to-carrier",
    checked: false,
  },
};

const ReceiveObjects = (props) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingSearchNeighborhood, setLoadingSearchNeighborhood] = useState(false);
  const [loadingObject, setLoadingObject] = useState(false);
  const [objects, setObjects] = useState([]);
  const [objectsSelecteds, setObjectsSelecteds] = useState(0);
  const [loteValue, setLoteValue] = useState("");
  const [neighborhoodValue, setNeighborhoodValue] = useState("");
  const [objectValue, setObjectValue] = useState("000000000000000");
  const [loadingReceive, setLoadingReceive] = useState(false);
  const [carrier, setCarrier] = useState(null);
  const [carriers, setCarriers] = useState([]);
  const [checked, setChecked] = useState(false);

  const signin = useSelector((state) => state.signin);

  useEffect(() => {
    getCarriers();
    setObjects([]);
  }, []);

  useEffect(() => {
    resetStates();
  }, [props.step]);

  useEffect(() => {
    resetStates();
  }, [signin.user]);

  const getCarriers = async () => {
    try {

      const results = await api.get("/carriers")

      setCarriers(results.data);
    } catch (error) {
      message.error("Não foi possível buscar os transportadores!");
    }
  };

  const resetStates = () => {
    setLoteValue("");
    setObjectValue("");
    setObjectsSelecteds(0);
    setObjects([]);
  };

  const breadcrumb = [
    {
      title: "Faturamento",
      path: props.modulePath,
    },
    {
      title: step[props.step].titleBreadcrumb,
      path: props.modulePath + step[props.step].urlBreadcrumb,
    },
  ];

  let columns = [
    {
      title: <Checkbox
        checked={checked}
        onChange={async () => {

          const newObjects = _.map(objects, item => {
            item.selected = !checked;

            return item;
          });
          setChecked(!checked);

          setObjects(newObjects);
          countSelecteds(objects);
        }
        }
      />,
      //dataIndex: "selected",
      width: "30px",
      render: (object) => {
        return (
          <Checkbox
            checked={object.selected}
            onChange={() => handleSelectItem(object)}
          />
        );
      },
    },
    {
      title: "Negócio",
      dataIndex: "negocio",
    },
    {
      title: "Código",
      dataIndex: "codigo",
    },
    {
      title: "Tipo do Objeto",
      dataIndex: "descproduto",
    },
    {
      title: "Contrato",
      dataIndex: "contrato",
    },
    {
      title: "Município",
      dataIndex: "municipio",
    },
    {
      title: "Cliente",
      render: (object) => object.cliente.cnome,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return (
          <StatusComponent
            title={status.title}
            color={status.color}
            description={status.description}
            loading={false}
          />
        );
      },
    },
  ];

  const handleSelectItem = async (object) => {
    const newObjects = await _.map(objects, item => {
      if (item.codigo === object.codigo) {
        item.selected = !object.selected;
      }
      return item;
    });

    await setObjects(newObjects);
    countSelecteds(objects);
  }
  
  const handleSearchObjects = async () => {
    try {
      setLoadingSearch(true);
      setObjects([]);
      setObjectsSelecteds(0);
      setObjectValue("");

      if (!loteValue) throw "Número de protocolo em branco, favor preencher!";

      var results = await instanceApi(signin.user.tenantId).get(
        `/all/RESTEXPEDICAO/api/v1/getLote/?lote=${loteValue}&status=${step[props.step].previousState}&municipio=${neighborhoodValue}`
      );

      if (results.data.length <= 0)
        throw "Não foi localizado registros em aberto para o lote informado!";

      if (step[props.step].previousState === "G") {
        for (var i in results.data) {
          results.data[i].selected = false;
        }
      }

      setChecked(step[props.step].checked);

      countSelecteds(results.data);

      setObjects(results.data);
    } catch (error) {
      message.warn(error);
    } finally {
      setLoadingSearch(false);
    }
  };


  const countSelecteds = async (data) => {
    const countSelected = await data
      .map((object) => (object.selected ? 1 : 0))
      .reduce((prev, next) => prev + next);

    setObjectsSelecteds(countSelected);
  }

  function handleTableChange(pagination, filters) {
    setPage(pagination.current);
  }

  const handlerSearchObject = async () => {
    setLoadingObject(true);

    try {
      const index = await _.findIndex(objects, (object) => {
        return object.codigo === objectValue;
      });

      if (index < 0) throw "Objeto não localizado nesse lote!";

      if (objects[index].selected) throw "O objeto já foi selecionado!";

      objects[index].selected = true;

      setPageObjectCurrent(index);

      //Soma os objetos selecionados
      sumSelecteds();

      setObjects(objects);
      setObjectValue("");
    } catch (error) {
      message.warn(error);
    } finally {
      setLoadingObject(false);
    }
  };

  const sumSelecteds = async () => {
    const sumSelecteds = await _.sumBy(objects, (current) => current.selected === true);

    setObjectsSelecteds(sumSelecteds);
  }

  const setPageObjectCurrent = (objectIndex) => {
    const totalPage = Math.ceil(objects.length / rowsPerPage);

    for (let page = 1; page <= totalPage; page++) {
      if (objectIndex < page * rowsPerPage) {
        setPage(page);
        break;
      }
    }
  };

  const sendObjects = async () => {
    setLoadingReceive(true);
    try {
      const newObjectsSelecteds = await _.filter(
        objects,
        (object) => object.selected
      );

      const results = await instanceApi(signin.user.tenantId).put(
        `/all/RESTEXPEDICAO/api/v1/receiveObject`,
        {
          transportadora: carrier,
          status: step[props.step].nextState,
          rows: newObjectsSelecteds,
        }
      );

      resetStates();
      message.success("Recebimento de objetos finalizados com sucesso.");

    } catch (error) {
      message.error(error);
    } finally {
      setLoadingReceive(false);
    }
  };

  return (
    <>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu={step[props.step].key}
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
      >
        <Spin tip={step[props.step].spinMessage} spinning={loadingReceive}>
          <Row>
            <Col xs={24} md={12}>
              <Row>
                <Col xs={24} style={{ paddingRight: "20px" }}>
                  <Input.Search
                    className="input-objects"
                    placeholder="Digite o código do lote"
                    enterButton="Buscar"
                    loading={loadingSearch}
                    allowClear
                    onSearch={handleSearchObjects}
                    value={loteValue}
                    onChange={(e) => setLoteValue(e.target.value)}
                  />
                  <Input
                    className="input-objects"
                    placeholder="Digite o Município"
                    allowClear
                    value={neighborhoodValue}
                    onChange={(e) => setNeighborhoodValue(e.target.value)}
                  />
                </Col>
                {
                  props.step === "carrier" ?
                    <Row>
                      <Col xs={24} style={{ paddingRight: "20px", paddingBottom: "10px" }}>
                        <Select
                          showSearch
                          placeholder="Selecione uma transportadora"
                          value={carrier}
                          optionFilterProp="children"
                          onChange={(value) => setCarrier(value)}
                          style={{ width: "100%" }}
                        >
                          {
                            carriers.map(option => <Select.Option key={option.carrier_id} value={option.carrier_id}>{option.name}</Select.Option>)
                          }
                        </Select>
                      </Col>
                      <Col xs={24} style={{ paddingRight: "20px" }}>
                        <Input
                          className="input-objects"
                          placeholder="Digite o código do objeto"
                          loading={loadingObject}
                          disabled={objects.length > 0 ? false : true}
                          value={objectValue}
                          onChange={(e) => setObjectValue((`000000000000000${e.target.value}`).slice(-15))}
                          onPressEnter={handlerSearchObject}
                          allowClear
                        />
                      </Col>
                    </Row>
                    :
                    <></>
                }
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Row justify="space-between">
                <Col xs={8} style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                  <CardTotal
                    value={objects.length}
                    color="#9D9D9D"
                    title="Total:"
                    loading={false}
                  />
                </Col>
                <Col xs={8} style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                  <CardTotal
                    value={objectsSelecteds}
                    color="#31A336"
                    title="Selecionados:"
                    loading={false}
                  />
                </Col>
                <Col xs={8} style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                  <CardTotal
                    value={objects.length - objectsSelecteds}
                    color="#FF0000"
                    title="Pendentes:"
                    loading={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xs={24}>
              <Divider />

              <Table
                row
                columns={columns}
                dataSource={objects}
                rowKey="codigo"
                loading={loadingSearch}
                pagination={{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '50'],
                  onShowSizeChange: (current, size) => console.log(size),
                  current: page,
                  pageSize: rowsPerPage,
                  total: objects.length,
                }}
                onChange={handleTableChange}
                scroll={{ x: 1000 }}
                size="small"
              />
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: "20px" }}>
            <Col>
              <Button
                type="primary"
                disabled={objectsSelecteds === 0}
                onClick={sendObjects}
              >
                {step[props.step].textSendButton}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Main>
    </>
  );
};

export default ReceiveObjects;
