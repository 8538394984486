import React, { useEffect } from "react";
import "./styles.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import Main from "../Layout/Main";
import MenuHome from "./Menu";
import { Col, Row } from "antd";
import { checkControl } from "../../utils/access_control";
import LinksExternal from "../../components/LinksExternal";
import Cards from "../../components/card/card";

function Home(props) {
  const signin = useSelector((state) => state.signin);
  const modules = useSelector((state) => state.modules);

  const history = useHistory();

  useEffect(() => checkFirstAccess(), []);

  const breadcrumb = [
    {
      title: props.moduleName,
      path: "",
    },
  ];

  /**
   * Função que verifica se é o primeiro login do usuário com a senha padrão
   */
  const checkFirstAccess = () => {
    if (signin.user.reset_password_token)
      history.push(
        `/reset-password?id=${signin.user.id}&token=${
          signin.user.reset_password_token
        }&message=${true}`
      );
  };

  /**
   * Função para ordenar pela sequencia
   */
  const sortData = (data) => {
    return data.sort((a, b) => {
      return parseInt(a.sequence) - parseInt(b.sequence);
    });
  };

  var greetings = [
      [18, "Boa noite"],
      [12, "Boa tarde"],
      [5, "Bom dia"],
      [0, "Boa madrugada"],
    ],
    hr = new Date().getHours(),
    currentGreeting = "";

  for (var i = 0; i < greetings.length; i++) {
    if (hr >= greetings[i][0]) {
      currentGreeting = greetings[i][1];
      break;
    }
  }

  return (
    <>
      <Helmet>
        <title>Início - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={<MenuHome selectedMenu="0" />}
        breadcrumb={breadcrumb}
      >
        <p className="title-section">{`${currentGreeting}, ${
          signin.user.name.split(" ")[0]
        }!`}</p>
        <Row gutter={[25, 25]}>
          {modules.modules.map((module, index) => {
            return (
              <Col
                style={{
                  ...checkControl(
                    signin.user.profiles,
                    module.Resource.class_tag
                  ),
                }}
                key={index}
              >
                {Cards(module)}
              </Col>
            );
          })}
        </Row>

        <LinksExternal />
      </Main>
    </>
  );
}

export default Home;
