import React from "react";
import { MdUnfoldMore, MdUnfoldLess } from "react-icons/md";

export const IconTable = ({ current }) => {
  return (
    <>
      {current !== 0 ? (
        <MdUnfoldLess
          size={24}
          className="content-header-contract-icon-unified-query"
        />
      ) : (
        <MdUnfoldMore
          size={24}
          className="content-header-contract-icon-unified-query"
        />
      )}
    </>
  );
};
