export function checkCNPJ(cnpj) {
  cnpj = cnpj.replace(/\D/g, '');

  if (cnpj.length !== 14) {
      return false;
  }

  let soma = 0;
  let peso = 5;
  for (let i = 0; i < 12; i++) {
      soma += parseInt(cnpj.charAt(i)) * peso;
      peso--;
      if (peso === 1) {
          peso = 9;
      }
  }

  let digitoVerificador1 = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  soma = 0;
  peso = 6;
  for (let i = 0; i < 13; i++) {
      soma += parseInt(cnpj.charAt(i)) * peso;
      peso--;
      if (peso === 1) {
          peso = 9;
      }
  }

  let digitoVerificador2 = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  return parseInt(cnpj.charAt(12)) === digitoVerificador1 && parseInt(cnpj.charAt(13)) === digitoVerificador2;
}

export function checkCPF(value) {
  if (typeof value !== "string") return false;

  value = value.replace(/\D/g, "");

  if (value === "") return false;

  if (value.length !== 11) return false;

  if (
    value === "00000000000" ||
    value === "11111111111" ||
    value === "22222222222" ||
    value === "33333333333" ||
    value === "44444444444" ||
    value === "55555555555" ||
    value === "66666666666" ||
    value === "77777777777" ||
    value === "88888888888" ||
    value === "99999999999"
  )
    return false;

  let sum = 0;
  let rest;

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i);
  }

  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(value.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(value.substring(i - 1, i)) * (12 - i);
  }

  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(value.substring(10, 11))) return false;

  return true;
}

export const isJson = (str) => {
  try{
     JSON.parse(str);
  }catch (e){
     return false;
  }

 return true;
}