import { getRequest, postRequest, putRequest } from "../utils/api";

export const FieldsBySettingServicesService = {
  getFieldsBySettingService: async (idSettingService) => {
    return await getRequest(
      `/setting-service/fields?idSettingService=${idSettingService}`,
      {}
    );
  },

  store: (data) => postRequest("/setting-service/field", data),
  update: ({id, data}) => putRequest("/setting-service/field/" + id, data),
};
