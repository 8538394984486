import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";

import { message, Switch, Button, Tag, Popconfirm, Input, Select } from "antd";

import {
  PlusOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import Main from "../../../Layout/Main";
import MainCrud from "../../../../components/MainCrud";
import FormComponent from "../../../../components/FormComponent";

import { ModalApp } from "../../../../components/Modal";
import { checkControl } from "../../../../utils/access_control";

import {
  listFormApi,
  deleteFormApi,
  updateFormApi,
} from "../../../../services/forms";

import { SectorsService } from "../../../../services/sectors";

import { listForm, alterForm, deleteForm } from "../../../../store/ducks/forms";
import { openModal, closeModal } from "../../../../store/ducks/modalGlobal";
import { alterFormComponent } from "../../../../store/ducks/formComponent";
import MenuComponent from "../../../../components/MenuComponent";
import { itensDrawer } from "../itens-menu";

const { Option } = Select;

function Forms(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [valueInputSearch, setValueInputSearch] = useState("");
  const [sectores, setSectores] = useState([]);

  const signin = useSelector((state) => state.signin);
  const form = useSelector((state) => state.forms);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setPage(1);
  }, [checked]);

  useEffect(() => {
    if (!checked) {
      list(true);
    } else {
      list(false);
    }
  }, [page, rowsPerPage, form.form, signin.user.currentCompany]);

  async function list(active) {
    try {
      setLoading(true);

      const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
      listFormApi
        .listForm(rowsPerPage, offset, active, signin.user.currentCompany)
        .then((response) => {
          dispatch(listForm(response));
          listSectores();
        });
    } catch (error) {
      message.error("Erro ao carregar dados!");
    } finally {
      setLoading(false);
    }
  }

  async function listSectores() {
    SectorsService.getSectors(10, 0, true)
      .then((response) => setSectores(response))
      .catch(() => message.error("Erro ao carregar os setores!"));
  }

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(10);
  }

  // filtrar por título
  const getColumnSearchApi = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
      <div className="divSearch">
        <Input
          style={{ marginBottom: 8 }}
          placeholder="Pesquisar..."
          value={valueInputSearch}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setValueInputSearch(e.target.value ? [e.target.value] : "");
          }}
        />

        <div>
          <Button
            type="primary"
            onClick={() => handleSearchApi(selectedKeys)}
            icon={<SearchOutlined />}
            className="buttonSearch"
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              setValueInputSearch("");
              list(true);
            }}
            icon={<DeleteOutlined />}
            className="buttonSearch"
          >
            Remover Filtro
          </Button>
        </div>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  // filtra por setor
  const getColumnSectorSearchApi = () => ({
    filterDropdown: () => (
      <div className="divSearch">
        <Select style={{ width: 120 }} onChange={handleChange}>
          <Option value="">Sem filtro</Option>
          {sectores && sectores.rows && sectores.rows.length !== 0
            ? sectores.rows.map((sector) => (
                <Option value={sector.id}>{sector.name}</Option>
              ))
            : null}
        </Select>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  function handleChange(value) {
    typeof value === "string" ? list(true) : handleSearchApi(value);
  }

  const handleSearchApi = (value) => {
    setLoading(true);

    if (typeof value[0] === "string") value = `&title=${value}`;

    if (typeof value === "number") value = `&id_sector=${value}`;

    listFormApi
      .listForm(10, 0, true, signin.user.currentCompany, value)
      .then((response) => {
        dispatch(listForm(response));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error("Desculpe não foi possível realizar o filtro.");
      });
  };

  function activeSwitch(checked) {
    if (checked) {
      list(false);
      setChecked(true);
      message.info("Exibindo formulários inativos!");
    } else {
      list(true);
      setChecked(false);
      message.info("Exibindo formulários ativos!");
    }
  }

  const handleOpenModal = (data) => {
    dispatch(alterFormComponent(data));

    dispatch(openModal(true));
    dispatch(
      alterForm({
        ...data,
      })
    );
  };

  const handleNewForm = () => {
    dispatch(alterForm({}));
    history.push("/" + props.modulePath + "/add/form");
  };

  const handleUpdateForm = () => {
    dispatch(closeModal(false));
    history.push("/" + props.modulePath + "/add/form");
  };

  const remove = () => {
    setLoading(true);

    deleteFormApi
      .deleteForm(form.form.id)
      .then((res) => {
        dispatch(deleteForm(res));
        dispatch(alterForm({}));
        dispatch(closeModal(false));
        setLoading(false);
        message.success("Formulário inativado com sucesso!");
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };

  const activeForm = (id) => {
    const body = {
      id: form.form.id,
      active: true,
    };

    updateFormApi
      .updateForm(body)
      .then(() => {
        dispatch(alterForm({}));
        dispatch(closeModal(false));
        setLoading(false);
        message.success("Formulário Ativado com sucesso!");
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Formulários",
      path: props.modulePath + "/forms",
    },
  ];

  let columns = [
    {
      title: "Título",
      dataIndex: "title",
      ...getColumnSearchApi(),
    },
    {
      title: "Setor",
      dataIndex: ["Sector", "description"],
      ...getColumnSectorSearchApi(),
    },
    {
      title: "Status",
      dataIndex: "active",
      render: (active) => {
        let color = "";
        if (active === true) {
          color = "green";
        } else {
          color = "volcano";
        }
        return <Tag color={color}>{active ? "Ativo" : "Inativo"}</Tag>;
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Formulários - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="6"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <>
            <span
              style={checkControl(signin.user.profiles, "check_forms_inactivate")}
            >
              <Switch
                onChange={activeSwitch}
                style={{ marginRight: "5px", marginTop: "5px" }}
                checkedChildren="Inativos"
                unCheckedChildren="Ativos"
              />
            </span>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={handleNewForm}
              style={{
                ...checkControl(signin.user.profiles, "insert_forms"),
                marginLeft: "20px",
              }}
            >
              Novo
            </Button>
          </>
        }
      >
        <MainCrud
          columnsTable={columns}
          dataSource={form.formList}
          handleOpenModal={handleOpenModal}
          loading={loading}
          page={page}
          rowsPerPage={rowsPerPage}
          countPages={form.formListCount}
          handleTableChange={handleTableChange}
          activeSwitch={null}
          displayMainCrud="none"
          classTag={[]}
        />
        <ModalApp title={form.form.title} width={1000} footer={null}>
          <FormComponent readonly={true} />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: 25,
            }}
          >
            {form.form.active ? (
              <Popconfirm
                title="Deseja inativar o formulário?"
                onConfirm={remove}
                okText="Sim"
                cancelText="Não"
              >
                <Button
                  type="danger"
                  shape="round"
                  style={{
                    ...checkControl(signin.user.profiles, "forms_inactivate"),
                    marginLeft: "8px",
                  }}
                >
                  Inativar
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Deseja Ativar o formulário?"
                onConfirm={activeForm}
                okText="Sim"
                cancelText="Não"
              >
                <Button
                  type="primary"
                  style={{
                    ...checkControl(
                      signin.user.profiles,
                      "save_and_update_forms"
                    ),
                    marginLeft: "8px",
                  }}
                >
                  Ativar
                </Button>
              </Popconfirm>
            )}

            <Button
              type="primary"
              shape="round"
              style={{ marginLeft: "8px" }}
              onClick={handleUpdateForm}
            >
              Modificar
            </Button>
          </div>
        </ModalApp>
      </Main>
    </>
  );
}

export default Forms;
