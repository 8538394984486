import { Tag } from "antd";
import moment from "moment";

export const columnsContracts = [
  {
    title: "Nº do contrato",
    dataIndex: "contract",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Tipo de Contrato",
    dataIndex: "contract_type",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Produto",
    dataIndex: "product",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Nome do Cliente",
    dataIndex: "client_name",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Tipo do Cliente",
    dataIndex: "client_type",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Empresa Filial",
    dataIndex: "company",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
];

export const columnsAgents = [
  {
    title: "Carro",
    dataIndex: "placa",
    key: "placa",
    render: (text) => {       
      if(text) return <span style={{ fontSize: 12 }}>{text} </span>
      else return <span style={{ fontSize: 12, color: 'orange' }}> placa não informada </span> } ,
  },
  {
    title: "Agente",
    dataIndex: "display_name",
    key: "display_name",
    render: (text) => {       
      if(text) return <span style={{ fontSize: 12 }}>{text} </span>
      else return <span style={{ fontSize: 12, color: 'orange' }}> - </span> } ,
  },
  {
    title: "Origem",
    dataIndex: "address",
    key: 'address',
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Distância",
    dataIndex: "distance",
    key: "distancia",
    render: (text) =>  {       
                      if(text) return <span style={{ fontSize: 12 }}>{text} km</span>
                      else return <span style={{ fontSize: 12 }}> - </span> } ,
  },
  {
    title: "Duração",
    dataIndex: "duration",
    key: "duration",
    render: (text) => {       
      if(text) return <span style={{ fontSize: 12 }}>{text} minutos </span>
      else return <span style={{ fontSize: 12 }}> - </span> } ,
  },
]

export const columnsContractsComplete = [
  {
    title: "Nº do contrato",
    dataIndex: "contract",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Tipo de Contrato",
    dataIndex: "contract_type",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Produto",
    dataIndex: "product",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Nome do Cliente",
    dataIndex: "client_name",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Tipo do Cliente",
    dataIndex: "client_type",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Empresa Filial",
    dataIndex: "company",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Data de Nascimento",
    dataIndex: "birth_date",
    render: (date) => moment(date).format("DD/MM/YYYY"),
  },
  {
    title: "Data de Adesão",
    dataIndex: "accession_date",
    render: (date) => moment(date).format("DD/MM/YYYY"),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (value) => {
      let color = "";
      let status = value ? value : "INDEFINIDO";

      if (value === "ATIVO") {
        color = "green";
      } else if (value === "ENCERRADO") {
        color = "red";
      } else if (value === "SUSPENSO") {
        color = "cyan";
      } else if (value === "AÇÃO ADICIONAL") {
        color = "orange";
      } else {
        color = "purple";
      }

      return <Tag color={color}>{status}</Tag>;
    },
  },
];

export const columnsAttendance = [
  {
    title: "Falecido (a)",
    dataIndex: "deceased",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Atendimento",
    dataIndex: "attendance_date",
    render: (date) => moment(date).format("DD/MM/YYYY"),
  },
  {
    title: "Empresa Filial",
    dataIndex: "company",
    render: (text) => <span style={{ fontSize: 11 }}>{text}</span>,
  },
];

export const columnsAttendanceComplete = [
  {
    title: "N° Atend.",
    dataIndex: "attendance_number",
    render: (text) => <span style={{ fontSize: 11 }}>{text}</span>,
  },
  {
    title: "Tipo Atend",
    dataIndex: "attendance_type",
    render: (text) => <span style={{ fontSize: 11 }}>{text}</span>,
  },
  {
    title: "Data Atend.",
    dataIndex: "attendance_date",
    render: (date) => moment(date).format("DD/MM/YYYY"),
  },
  {
    title: "Empresa Filial",
    dataIndex: "company",
    render: (text) => <span style={{ fontSize: 11 }}>{text}</span>,
  },
  {
    title: "Falecido (a)",
    dataIndex: "deceased",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Declarante",
    dataIndex: "declarent",
    render: (text) => <span style={{ fontSize: 11 }}>{text}</span>,
  },
  {
    title: "Atendente",
    dataIndex: "attendante",
    render: (text) => <span style={{ fontSize: 11 }}>{text}</span>,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (value) => {
      let color = "";
      let status = value ? value : "INDEFINIDO";

      if (value === "ATENDIMENTO") {
        color = "green";
      } else if (value === "PRÉ-ATENDIMENTO") {
        color = "cyan";
      } else {
        color = "purple";
      }

      return <Tag color={color}>{status}</Tag>;
    },
  },
];

export const columnsSepul = [
  {
    title: "Sepultado (a)",
    dataIndex: "deceased",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Endereço",
    dataIndex: "sql",
    render: (text) => <span style={{ fontSize: 11 }}>{text}</span>,
  },
  {
    title: "Empresa Filial",
    dataIndex: "company",
    render: (text) => <span style={{ fontSize: 11 }}>{text}</span>,
  },
];

export const columnsSepulComplete = [
  {
    title: "N° do Sepultado",
    dataIndex: "sepult_number",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Contrato",
    dataIndex: "contract_number",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Titular",
    dataIndex: "contract_hilder",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Sepultado (a)",
    dataIndex: "deceased",
    render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
  },
  {
    title: "Endereço",
    dataIndex: "sql",
    render: (text) => <span style={{ fontSize: 11 }}>{text}</span>,
  },
  {
    title: "Empresa Filial",
    dataIndex: "company",
    render: (text) => <span style={{ fontSize: 11 }}>{text}</span>,
  },
  {
    title: "Gaveta",
    dataIndex: "drawer",
    render: (text) => <span style={{ fontSize: 11 }}>{text}</span>,
  },
  {
    title: "Data do Sepultamento",
    dataIndex: "sepult_date",
    render: (date) => moment(date).format("DD/MM/YYYY"),
  },
  {
    title: "Tipo",
    dataIndex: "type",
    render: (value) => {
      let color = "";
      let status = value ? value : "INDEFINIDO";

      if (value === "SEPULTADO") {
        color = "green";
      } else if (value === "RESTOS MORTAIS") {
        color = "red";
      } else {
        color = "purple";
      }

      return <Tag color={color}>{status}</Tag>;
    },
  },
];

export const buttons = [
  {
    key: "any",
    label: "Tudo",
    current: 0,
  },
  {
    key: "contracts",
    label: "Contratos",
    current: 1,
  },
  {
    key: "attendance",
    label: "Aten. Fun.",
    current: 2,
  },
  {
    key: "sepul",
    label: "Sepultados",
    current: 3,
  },
];

export const dataAPI = {
  constracts: [
    {
      contract: "00000001",
      contract_type: "PLANO",
      product: "MORADA ESSENCIAL 01",
      client_name: "LEANDRO AYALA COSME",
      client_type: "TITULAR",
      company: "MORADA C. LTDA / SAO GONCALO",
      birth_date: "2022-02-19",
      accession_date: "2022-02-19",
      status: "ATIVO",
      payment: "CARNÊ",
      seller: "MANOEL",
      client_number: "000001",
      cpf: "12345678901",
      mail: "LEANDRO@gmail.com",
      send_mail: true,
      phone: "(84) 3508-9594",
      cell_phone: "(84) 99266-9594",
      cep: "59106-130",
      address: "RUA VIDALDO PEREIRA DE ARAUJO, 683",
      neighborhood: "IGAPO",
      city: "NATAL",
      state: "RN",
      dependents: [
        {
          order: 1,
          name: "ANTHONY HUGO BERNARDES",
          relationship: "IRMÃO",
          cpf: "12345678901",
          sex: "MASCULINO",
          birth_date: "2022-02-19",
          accession_date: "2022-02-19",
          lack_date: "2022-02-19",
          lack: "Sim",
        },
        {
          order: 1,
          name: "ANTHONY HUGO BERNARDES",
          relationship: "IRMÃO",
          cpf: "12345678901",
          sex: "MASCULINO",
          birth_date: "2022-02-19",
          accession_date: "2022-02-19",
          lack_date: "2022-02-19",
          lack: "Não",
        },
      ],
    },
    {
      contract: "00000002",
      contract_type: "PLANO",
      product: "MORADA ESSENCIAL 01",
      client_name: "AYALA JÚNIOR",
      client_type: "DEPENDENTE",
      company: "MORADA C. LTDA / SAO GONCALO",
      birth_date: "2022-02-19",
      accession_date: "2022-02-19",
      status: "ENCERRADO",
      payment: "DINHEIRO",
      seller: "IVANILDO",
      client_number: "000002",
      cpf: "12345678901",
      mail: "LEANDRO@gmail.com",
      send_mail: true,
      phone: "(84) 3508-9594",
      cell_phone: "(84) 99266-9594",
      cep: "59106-130",
      address: "RUA VIDALDO PEREIRA DE ARAUJO, 683",
      neighborhood: "IGAPO",
      city: "NATAL",
      state: "RN",
      dependents: [],
    },
    {
      contract: "00000003",
      contract_type: "JAZIGO",
      product: "JAZIGO VIP",
      client_name: "LEANDRO AYALA COSME",
      client_type: "TITULAR",
      company: "MORADA C. LTDA / ZONA SUL",
      birth_date: "2022-02-19",
      accession_date: "2022-02-19",
      status: "SUSPENSO",
      payment: "CARTÃO",
      seller: "GIVALDO",
      client_number: "000003",
      cpf: "12345678901",
      mail: "LEANDRO@gmail.com",
      send_mail: true,
      phone: "(84) 3508-9594",
      cell_phone: "(84) 99266-9594",
      cep: "59106-130",
      address: "RUA VIDALDO PEREIRA DE ARAUJO, 683",
      neighborhood: "IGAPO",
      city: "NATAL",
      state: "RN",
      dependents: [],
    },
    {
      contract: "00000004",
      contract_type: "JAZIGO",
      product: "JAZIGO VIP",
      client_name: "AYALA NETO",
      client_type: "TITULAR",
      company: "MORADA C. LTDA / ZONA SUL",
      birth_date: "2022-02-19",
      accession_date: "2022-02-19",
      status: "AÇÃO ADICIONAL",
      payment: "CARTÃO",
      seller: "AYALA",
      client_number: "000004",
      cpf: "12345678901",
      mail: "LEANDRO@gmail.com",
      send_mail: true,
      phone: "(84) 3508-9594",
      cell_phone: "(84) 99266-9594",
      cep: "59106-130",
      address: "RUA VIDALDO PEREIRA DE ARAUJO, 683",
      neighborhood: "IGAPO",
      city: "NATAL",
      state: "RN",
      dependents: [],
    },
  ],
  attendances: [
    {
      attendance_number: "001",
      attendance_type: "PARTICULAR",
      attendance_date: "2022-02-19",
      company: "MORADA C. LTDA / ZONA SUL",
      deceased: "MANOEL LUCAS",
      declarent: "MANOEL LUCAS",
      attendante: "MANOEL LUCAS",
      status: "ATENDIMENTO",
    },
    {
      attendance_number: "002",
      attendance_type: "PLANO",
      deceased: "LEONARDO FREITAS",
      attendance_date: "2022-02-19",
      company: "MORADA C. LTDA / ZONA SUL",
      declarent: "MANOEL LUCAS",
      attendante: "MANOEL LUCAS",
      status: "ATENDIMENTO",
    },
    {
      attendance_number: "003",
      attendance_type: "PLANO",
      deceased: "MARCOS SILVA",
      attendance_date: "2022-02-19",
      company: "MORADA C. LTDA / ZONA SUL",
      declarent: "ALYSON DANTAS",
      attendante: "MANOEL LUCAS",
      status: "PRÉ-ATENDIMENTO",
    },
  ],
  sepults: [
    {
      sepult_number: "000000",
      contract_number: "000000",
      contract_hilder: "ALYSON DANTAS",
      deceased: "ALYSON DANTAS",
      sql: "00.000.0000",
      company: "MORADA C. LTDA / ZONA SUL",
      drawer: "00",
      sepult_date: "1950-02-20",
      type: "SEPULTADO",
    },
    {
      sepult_number: "000000",
      contract_number: "000000",
      contract_hilder: "ANDRÉ MEDEIROS",
      deceased: "LEONARDO FREITAS",
      sql: "00.000.0000",
      company: "MORADA C. LTDA / ZONA SUL",
      drawer: "00",
      sepult_date: "1950-02-10",
      type: "RESTOS MORTAIS",
    },
  ],
};

// Simula uma pesquisa na api depois pode remover essa função
export const searchAPI = async (params, value) => {
  let constracts = [];
  let attendances = [];
  let sepults = [];

  const newDataAPi = { constracts, attendances, sepults };

  if (params === "text") {
    const result = dataAPI.constracts.filter((e) =>
      e.client_name.includes(value)
    );

    newDataAPi.constracts = result;
  } else if (params === "number") {
    const result = dataAPI.attendances.filter((e) =>
      e.attendance_number.includes(value)
    );

    newDataAPi.attendances = result;
  } else if (params === "date") {
    const result = dataAPI.sepults.filter(
      (e) => e.sepult_date === moment(value, "DD/MM/YYYY").format("YYYY-MM-DD")
    );

    newDataAPi.sepults = result;
  }

  return newDataAPi;
};