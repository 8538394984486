import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Row,
  Col,
  Select,
  Form,
  Input,
  PageHeader,
  Button,
  DatePicker,
  Skeleton,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";

import { OsMovementsService } from "../../services/movementsOs";

import "./styles.css";
import Swal from "sweetalert2";

import "moment/locale/pt-br";
import locale_br from "antd/es/date-picker/locale/pt_BR";
import moment from "moment";

const { Option } = Select;

function OsMovements({ service_id }) {
  const signin = useSelector((state) => state.signin);

  const [movements, setMovements] = useState([]);
  const [movement, setMovement] = useState(false);
  const [counter, setCounter] = useState(1);
  const [loading, setLoading] = useState(false);

  const type = "ORIGEM";
  const locale = "CEMITERIO";

  useEffect(() => getMovementsOs(), []);

  /**
   * Função que carrega todas as OSs já salvas
   */
  const getMovementsOs = () => {
    setLoading(true);

    OsMovementsService.getMovements(service_id)
      .then((response) => {
        return Promise.all(
          response.map((value) => insertMovements(value))
        ).then(() => setLoading(false));
      })
      .catch(() =>
        Swal.fire({
          position: "top",
          icon: "error",
          title: "Erro ao busca as movimentações!",
          showConfirmButton: false,
          timer: 1500,
        })
      );
  };

  /**
   * Função que insere novas linhas de movimentações feitas
   */
  const insertMovements = (data) => {
    setCounter(counter + 1);
    const newMovements = movements;
    newMovements.push({
      index: counter,
      component: <GenerateFormResponse index={counter} initial_values={data} />,
    });
    setMovements(newMovements);
    setMovement(!movement);
  };

  const GenerateFormResponse = ({ index, initial_values }) => {
    const [destination, setDestination] = useState("");
    const [send, setSend] = useState(false);

    let disable = false;
    let localceOther = false;

    const onFinish = async (values) => {
      if (!values.type) values.type = type;
      if (!values.locale) values.locale = locale;

      values.user_id = signin.user.id;
      values.service_id = service_id;
      values.date = moment(values.date).format("DD/MM/YYYY HH:mm");

      OsMovementsService.create(values)
        .then(() => {
          Swal.fire({
            position: "top",
            icon: "success",
            title: "Movimentação salva com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          });

          setSend(true);
        })
        .catch(() => {
          Swal.fire({
            position: "top",
            icon: "error",
            title: "Erro ao salvar a movimentação!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    };

    function handleChangeLocal(value) {
      setDestination(value);
    }

    const initial = (values) => {
      if (!values) return false;

      disable = true;

      const date = moment(values.date, "DD/MM/YYYY HH:mm");

      if (values.locale === "OUTRO" && values.other) {
        localceOther = true;
      }

      const initial_values = {
        ["type"]: values.type,
        ["date"]: date,
        ["locale"]: values.locale,
        ["other"]: values.other,
      };

      return initial_values;
    };

    return (
      <div className="movements">
        {loading ? (
          <Skeleton active />
        ) : (
          <Form onFinish={onFinish} initialValues={initial(initial_values)}>
            <Row gutter={[16, 16]}>
              <Col>
                <Form.Item label="Tipo" name="type">
                  <Select
                    defaultValue={type}
                    style={{ width: 150 }}
                    disabled={send || disable}
                  >
                    <Option value="ORIGEM">Origem</Option>
                    <Option value="DESTINO">Destino</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Data"
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: "Por favor informe o campo.",
                    },
                  ]}
                >
                  <DatePicker
                    showTime={{ format: "HH:mm" }}
                    locale={locale_br}
                    format="DD/MM/YYYY HH:mm"
                    placeholder="Selecione data e hora"
                    disabled={send || disable}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Local" name="locale">
                  <Select
                    defaultValue={locale}
                    style={{ width: 150 }}
                    onChange={handleChangeLocal}
                    disabled={send || disable}
                  >
                    <Option value="CEMITERIO">Cemitério</Option>
                    <Option value="HOSPITAL">Hospital</Option>
                    <Option value="ITEP">ITEP</Option>
                    <Option value="SVO">SVO</Option>
                    <Option value="RESIDENCIA">Residência</Option>
                    <Option value="OUTRO">Outro</Option>
                  </Select>
                </Form.Item>
              </Col>
              {destination === "OUTRO" || localceOther ? (
                <Col>
                  <Form.Item
                    label="Local"
                    name="other"
                    rules={[
                      {
                        required: true,
                        message: "Por favor informe o campo.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Informe o local"
                      disabled={send || disable}
                    />
                  </Form.Item>
                </Col>
              ) : null}
              <Col>
                <Button
                  className="buttonRemove"
                  key="2"
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  disabled={send || disable}
                >
                  Preencher
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    );
  };

  return (
    <>
      <PageHeader
        title="Outras movimentações da OS"
        className="site-page-header-ghost-wrapper"
        extra={[
          <Button
            key="1"
            type="primary"
            onClick={() => insertMovements()}
            icon={<PlusOutlined />}
          />,
        ]}
      />
      {
        movements.map((value) => value.component)
      }
   </>
  );
}

export default OsMovements;
