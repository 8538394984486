import React, { useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  Popconfirm,
  Select,
  Switch,
} from "antd";
import { useDebounce } from "../../../../../../utils/useDebounce";
import api from "../../../../../../utils/api";
import { useMutate } from "../../../../../../utils/useMutate";
import { useFetch } from "../../../../../../utils/useFetch";

const validateMessages = {
  required: "${label} é obrigatório!",
};

const GroupForm = (props) => {
  const [form] = Form.useForm();
  const [groupSearchQuery, setGroupSearchQuery] = React.useState("");

  const debouceGroupSearchQuery = useDebounce(groupSearchQuery, 500);

  const { loading: createGroupLoading, mutate: createGroup } = useMutate(
    createGroupMutate,
    {
      onSuccess: () => {
        props.onFinish && props.onFinish();
        message.success(
          `Grupo ${
            props.currentGroup?.id ? "atualizado" : "criado "
          } com sucesso!`
        );
      },
      onError: () => {
        message.error(
          `Erro ao ${props.currentGroup?.id ? "atualizar" : "criar "} grupo!`
        );
      },
    }
  );

  const { loading: updateGroupLoading, mutate: updateGroup } = useMutate(
    updateGroupMutate,
    {
      onSuccess: () => {
        props.onFinish && props.onFinish();
        message.success("Grupo atualizado com sucesso!");
      },
      onError: (e) => {
        message.error("Erro ao atualizar grupo!" + e);
      },
    }
  );

  const { loading: deleteGroupLoading, mutate: deleteGroup } = useMutate(
    deleteGroupMutate,
    {
      onSuccess: () => {
        props.onDeleteFinish && props.onDeleteFinish();
        message.success("Grupo deletado com sucesso!");
      },
      onError: () => {
        // props.onFinish && props.onFinish();
        message.error("Erro ao deletar grupo!");
        window.location.reload();
      },
    }
  );

  const {
    refetch: searchProfiles,
    data: profiles,
  } = useFetch(searchProfilesMutate);

  async function createGroupMutate(params) {
    return api.post(`/linksgroups`, params);
  }

  async function updateGroupMutate(params) {
    return api.put(`/linksgroups?code=${props.currentGroup.code}`, params);
  }

  async function deleteGroupMutate(params) {
    return api.delete(`/linksgroups?code=${params.code}`);
  }

  async function searchProfilesMutate(params) {
    return (await api.get(
      `/resources/filter?${new URLSearchParams({description: "", limit: 10, ...params})}`
    )).data;
  }

  async function handleSearchProfile(value) {
    setGroupSearchQuery(value);
  }

  const handleSubmit = (values) => {
    if (props.currentGroup?.id) {
      updateGroup(values);
    } else {
      createGroup({...values, sequence: props.groupLength + 1});
    }
  };

  const handleDelete = () => {
    deleteGroup(props.currentGroup);
  };

  useEffect(() => {
    form.resetFields();
  }, [props]);

  useEffect(() => {
    searchProfiles({ description: debouceGroupSearchQuery });
  }, [debouceGroupSearchQuery]);

  return (
    <Form
      layout="vertical"
      form={form}
      name="form-group"
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      initialValues={{
        title: props.currentGroup?.title,
        code: props.currentGroup?.code,
      }}
    >
      <Row gutter={8}>
        <Col lg={24}>
          <Form.Item
            name="code"
            label="Code"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Digite o código do grupo" />
          </Form.Item>
        </Col>
        <Col lg={24}>
          <Form.Item
            name="title"
            label="Título"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Digite o título do grupo" />
          </Form.Item>
        </Col>
        <Col lg={24}>
          <Form.Item
            initialValue={props.currentGroup?.profile_code}
            name="profile_code"
            label="Perfil de acesso"
            help="Selecione o perfil de acesso que terá acesso a este grupo"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              onSearch={handleSearchProfile}
              optionLabelProp="label"
              showSearch
              value={props.currentGroup?.profile_code}
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option.label.toLowerCase().includes(input.toLowerCase())
              }
              }
              options={profiles?.map((profile) => ({
                value: profile.class_tag,
                label: profile.description
              }))}
            />
          
          </Form.Item>
        </Col>
        <Col lg={24}>
          <Form.Item
          initialValue={props.currentGroup?.active}
            name="active" 
            label="Exibir grupo"
            help="Selecione se o grupo estará visível para os usuários"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Switch defaultChecked={props.currentGroup?.active}  checkedChildren="Sim" unCheckedChildren="Não"/>
          </Form.Item>
        </Col>
      </Row>

      <Row justify="end" align="bottom">
        {props.currentGroup?.id && (
          <Col>
            <Popconfirm
              title="Tem certeza que deseja deletar este grupo?"
              onConfirm={handleDelete}
              okText="Sim"
              cancelText="Cancelar"
            >
              <Button
                loading={deleteGroupLoading}
                danger
                type="primary"
                style={{
                  marginLeft: "8px",
                }}
              >
                Excluir
              </Button>
            </Popconfirm>
          </Col>
        )}
        <Col>
          <Button
            loading={createGroupLoading || updateGroupLoading}
            type="primary"
            htmlType="submit"
            style={{
              marginLeft: "8px",
            }}
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default GroupForm;
