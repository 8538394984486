import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./styles.css";

import { useSelector, useDispatch } from "react-redux";
import {
  listResources,
  alterResources,
} from "../../../../store/ducks/resources";

import { openModal } from "../../../../store/ducks/modalGlobal";
import {
  listResourcesApi,
  listResourcesFilterApi,
} from "../../../../services/resources";

import { message, Input, Button, Tag, Switch, Empty } from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import Main from "../../../Layout/Main";
import MainCrud from "../../../../components/MainCrud";

import { ModalApp } from "../../../../components/Modal";
import { FormApp } from "./components/Form";

import { checkControl } from "../../../../utils/access_control";
import MenuComponent from "../../../../components/MenuComponent";
import { itensDrawer } from "../itens-menu";

function Resources(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [valueInputSearch, setValueInputSearch] = useState("");

  const signin = useSelector((state) => state.signin);
  const resources = useSelector((state) => state.resources);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!checked) {
      list(true);
    } else {
      list(false);
    }
  }, [page, rowsPerPage, resources.resource]);

  useEffect(() => {
    setPage(1);
  }, [checked]);

  function list(active) {
    try {
      setLoading(true);

      const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
      listResourcesApi
        .listResources(rowsPerPage, offset, active)
        .then((res) => {
          dispatch(listResources(res));
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      message.error("Erro ao carregar dados!");
    }
  }

  // busca os recursos no backend
  const getColumnSearchApi = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
      <div className="divSearch">
        <Input
          style={{ marginBottom: 8 }}
          placeholder="Digite o recurso..."
          value={valueInputSearch}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setValueInputSearch(e.target.value ? [e.target.value] : "");
          }}
        />

        <div>
          <Button
            type="primary"
            onClick={() => handleSearchApi(selectedKeys)}
            icon={<SearchOutlined />}
            className="buttonSearch"
          >
            Buscar
          </Button>
          <Button
            onClick={() => list(true)}
            icon={<DeleteOutlined />}
            className="buttonSearch"
          >
            Remover Filtro
          </Button>
        </div>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const handleSearchApi = (value) => {
    if (value.length <= 0) {
      return message.error("Digite um recurso para ser filtrado.");
    }

    const index = value[0].toLowerCase();

    setLoading(true);

    listResourcesFilterApi
      .listResourcesFilter(index)
      .then((response) => {
        if (response.length <= 0) {
          throw {
            message: "Ops... erro ao filtrar recursos, favor tente novamente.",
          };
        }

        dispatch(
          listResources({
            rows: response,
            count: response.length,
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  let columns = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "Descrição",
      dataIndex: "description",
      ...getColumnSearchApi("description"),
    },
    {
      title: "Identificador",
      dataIndex: "class_tag",
    },
  ];

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(10);
  }

  function handleOpenModal(data) {
    dispatch(openModal(true));
    dispatch(
      alterResources({
        ...data,
      })
    );
  }

  function activeSwitch(checked) {
    if (checked) {
      list(false);
      setChecked(true);
      message.info("Exibindo recursos inativos!");
    } else {
      list(true);
      setChecked(false);
      message.info("Exibindo recursos ativos!");
    }
  }

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Recursos",
      path: props.modulePath + "/resources",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Recursos - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="16"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <>
            <span
              style={checkControl(
                signin.user.profiles,
                "check_resources_inactivate"
              )}
            >
              <Switch
                checkedChildren="Inativos"
                unCheckedChildren="Ativos"
                onChange={activeSwitch}
                style={{ marginRight: "5px", marginTop: "5px" }}
              />
            </span>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={handleOpenModal}
              style={{
                ...checkControl(signin.user.profiles, "insert_resource"),
                marginLeft: "20px",
              }}
            >
              Novo
            </Button>
          </>
        }
      >
        {resources.resourceList && resources.resourceList.length !== 0 ? (
          <MainCrud
            handleOpenModal={handleOpenModal}
            columnsTable={columns}
            dataSource={resources.resourceList}
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            countPages={resources.resourceListCount}
            handleTableChange={handleTableChange}
            profiles={signin.user.profiles}
            classTag={["", ""]}
          />
        ) : (
          <Empty description="Nenhum recurso encontrado" />
        )}
        <ModalApp
          title={resources.resource.id ? resources.resource.name : "Novo recurso"}
          width={1000}
          footer={null}
        >
          <FormApp />
        </ModalApp>
      </Main>
    </>
  );
}

export default Resources;
