import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Menu } from "antd";
import DynamicIcon from "../../utils/dynamicIcon";

import { HomeOutlined } from "@ant-design/icons";

import { checkControl } from "../../utils/access_control";
import { setCurrentModule } from "../../store/ducks/signin";

function MenuHome(props) {
  const signin = useSelector((state) => state.signin);
  const modules = useSelector((state) => state.modules);

  const dispatch = useDispatch();

  function handleModule(module) {
    dispatch(
      setCurrentModule({ module: module.name, id_sector: module.id_sector })
    );
  }

  return (
    <Menu theme="dark" defaultSelectedKeys={[props.selectedMenu]} mode="inline">
      <Menu.Item
        key="0"
        icon={<HomeOutlined />}
        style={checkControl(signin.user.profiles, "access_menu_home")}
      >
        <Link to="/">Início</Link>
      </Menu.Item>

      {modules.modules.map((module) => {
        return (
          <Menu.Item
            key={module.id}
            onClick={() => handleModule(module)}
            style={checkControl(
              signin.user.profiles,
              module.Resource.class_tag
            )}
            icon={<DynamicIcon name={module.icon} />}
          >
            <Link to={"/" + module.path}>{module.name}</Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );
}

export default MenuHome;
