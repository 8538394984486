import React from "react";



import { itensMenu } from "./itens-menu";

import MenuCard from "../../../../components/menu_card";

function OfflineSearch(props) {

  props = {
    ...props,
    breadcrumb: [
      {
        title: "Atendimento ao Cliente",
        path: props.modulePath,
      },
      {
        title: "Consulta Auxiliar",
        path: props.modulePath + "/offline-search",
      }
    ],
    itensMenu: itensMenu,
  };



  return <MenuCard {...props} />;
}

export default OfflineSearch;
