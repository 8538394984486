import { getRequest } from "../utils/api";

export const TasksOrderService = {
  getTasks: async (

    params
  ) => {
    return await getRequest(
      `/service/tasks?${new URLSearchParams(params).toString()}`,
      {}
    );
  },

  getTasksForUser: async (
    id_sector,
    id_setting_service,
    company,
    user_id,
    params
  ) => {
    return await getRequest(
      `/service/tasks/for/user?id_sector=${id_sector}&id_setting_service=${id_setting_service}&company=${company}&user_id=${user_id}&${new URLSearchParams(params).toString()}`,
      {}
    );
  },
};
