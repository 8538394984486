import { combineReducers } from "redux";

// Import reducers
import resources from "./resources";
import signin from "./signin";
import profiles from "./profiles";
import users from "./users";
import companies from "./companies";
import modalGlobal from "./modalGlobal";
import templatesMail from "./templatesMail";
import modules from "./modules";
import forms from "./forms";
import formItems from "./formItens";
import products from "./products";
import taskOrderServices from "./taskOrderServices";
import ItemTypes from "./intemTypes";
import settingServices from "./settingServices";
import sectors from "./sectors";
import drawer from "./drawer";
import templateDocuments from "./templateDocuments";
import appointment from "./appointments";
import formComponent from "./formComponent";
import modalProtheusData from "./modalProtheusData";
import unifiedQuery from "./UnifiedQuery";

export default combineReducers({
  resources,
  signin,
  modalGlobal,
  profiles,
  users,
  templatesMail,
  companies,
  modules,
  forms,
  formItems,
  products,
  taskOrderServices,
  ItemTypes,
  settingServices,
  sectors,
  drawer,
  templateDocuments,
  appointment,
  modalProtheusData,
  formComponent,
  unifiedQuery,
});
