/* eslint-disable import/first */
import "./styles.css";
import { useState, useEffect, useRef } from "react";
import Main from "../../../Layout/Main";
import { itensMenu } from "../itens-menu";
import { columnsAgents } from "./mocks";
import MapsAgentsComponent from "../../../../components/MapsAgentsComponent";
import { Row } from "antd";
import TableSmall from "./TableSmall";
import Loading from "../../../../components/Loading";
import MenuComponent from "../../../../components/MenuComponent";

function MapsAgents(props) {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCars, setShowCars] = useState(false);

  useEffect(() => {
    setShowCars(true);
    console.log('teste')
  }, []);

  const breadcrumb = [
    {
      key: 0,
      title: "Logística",
      path: props.modulePath,
    },
    {
      key: 1,
      title: "Localização de Agentes",
      path: props.modulePath + "/maps-agents",
    },
  ];

  let _componentStatus = useRef(true);

  function handleChange(dataTableParam, loadingParam) {
    if (dataTableParam !== null && dataTableParam !== undefined) {
      if (_componentStatus.current) {
        setLoading(false);

        setDataTable(dataTableParam);
        
        if (dataTableParam?.length > 0) {
          setShowCars(true);
        }
        else {
          setShowCars(false);
        }
       

        if(dataTableParam?.length === 0)
        {
          setShowCars(false);
        }
      } else {
        _componentStatus = null;
      }
    }

    if (loadingParam !== null && loadingParam !== undefined) {
      setLoading(loadingParam);
    }
  }

  return (
    <Main
      key={props.moduleName}
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          modulePath={props.modulePath}
          selectedMenu="2"
          itensMenu={itensMenu}
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"none"}
    >
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div>
            <MapsAgentsComponent
              onChange={(dataTable, loadingParam) =>
                handleChange(dataTable, loadingParam)
              }
              showCars={showCars}
              markersCarsData={dataTable}
            />
          </div>

          <Row className="mt-3">
            <TableSmall
              title="Relatório de localização dos agentes"
              columnsTable={columnsAgents}
              descriptionEmpty="Nenhum atendimento agente encontrado!"
              dataSource={dataTable}
              key={Math.random()}
            />
          </Row>
        </div>
      )}
    </Main>
  );
}

export default MapsAgents;
