import React, { useState } from "react";
import { Input, Row, Col, message, Divider, Table, Modal, Button, DatePicker, Select, Card, Spin, Form } from 'antd';
import { SearchOutlined, CreditCardOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Helmet } from "react-helmet";
import moment from "moment";


import Main from "../../../../Layout/Main";

import MenuComponent from "../../../../../components/MenuComponent";
import { itensMenu } from "../itens-menu";

import './App.css'; // Importa o arquivo CSS
import { OfflineSearch } from "../../../../../services/offlineSearch";
import ModalContract from "./components/ModalContract";
import TableDependents from "./components/TableDependents";
import ModalJazigo from "./components/ModalJazigo";
import ModalPet from "./components/ModalPet.js";
import ModalFuneralFuturo from "./components/ModalFuneralFu";
import ModalCrema from "./components/ModalCremacao";
import loadingSVG from "../../../../../assets/images/loading.svg";
import TableInstallments from "./components/TableInstallments";
import TableBenefits from "./components/TableBenefits";
import FieldsAutorization from "./components/Autorization/FieldsAutorization";
import FieldsNatimorto from "./components/Autorization/FieldsNatimorto";
import { useSelector, useDispatch } from "react-redux";



function SearchOffline(props) {
  const signin = useSelector((state) => state.signin);

  const { Search } = Input;
  const [selected, setSelected] = useState("customer_name");
  const [searchQuery, setSearchQuery] = useState({})
  const [data, setData] = useState([]);

  const [dataContract, setDataContract] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [dataInstallments, setDataInstallments] = useState([{}]);
  const [isModalInstallmentsVisible, setIsModalInstallmentsVisible] = useState(false);
  const [searchQueryInstallments, setSearchQueryInstallments] = useState({})
  const [loadingModalInstallments, setLoadingModalInstallments] = useState(false)

  const [loadingModalBenefits, setLoadingModalBenefits] = useState(false)
  const [dataBenefits, setDataBenefits] = useState([])
  const [isModalBenefitsVisible, setIsModalBenefitsVisible] = useState(false)

  const [isModalAutorization, setIsModalAutorization] = useState(false);
  const [isLoadingAutorization, setIsLoadingAutorization] = useState(false);
  const [form] = Form.useForm();
  const [typeUserAutorization, setTypeUserAutorization] = useState("Titular");

  const [loading, setLoading] = useState(false);
  const [loadingModalInfo, setLoadingModalInfo] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });

  const [paginationInstallments, setPaginationInstallments] = useState({
    current: 1,
    pageSize: 5,
    total: 5,
  });

  const breadcrumb = [
    {
      title: "Central de Atendimento ao Cliente",
      path: props.modulePath,
    },
    {
      title: "Consulta Auxiliar",
      path: props.modulePath + "/offline-search",
    },
    {
      title: "Consulta",
      path: props.modulePath + "/offline-search/search",
    },
  ];

  //Opções de busca na consulta principal
  const options = [{ value: 'CPF', key: "customer_cpf" }, { value: 'Nome', key: "customer_name" }, { value: 'Número do contrato', key: "contract_code" }, { value: 'Data de nascimento', key: "customer_birth_date" }];

  //Colunas da busca principal
  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Empresa',
      dataIndex: 'company_name',
      key: 'company_name',
    },
    {
      title: 'Filial',
      dataIndex: 'branch_name',
      key: 'branch_name',
    },
    {
      title: 'Contrato',
      dataIndex: 'contract_code',
      key: 'contract_code',
    },
    {
      title: 'Data de nascimento',
      dataIndex: 'customer_birth_date',
      key: 'customer_birth_date',
    },
    {
      title: 'Titular/Cessionário',
      dataIndex: 'customer_name',
      key: 'customer_name',
    },
    {
      title: 'Observação',
      dataIndex: 'obs',
      key: 'obs',
    },
    {
      title: 'Nome do Produto',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'Data de adesão',
      dataIndex: 'contract_date',
      key: 'contract_date',
    },

  ];

  // mudança na paginação da busca principal
  const handleTableChange = async (pagi) => {
    setLoading(true)
    setPagination(pagi)
    const customers = await OfflineSearch.findCustomer({ ...searchQuery, page: pagi.current - 1, limit: pagi.pageSize })
    setData(customers.rows)
    setLoading(false)
  };

  // Mudança nas paginação das parcelas 
  const handleTableChangeInstallments = async (pagi) => {

    try {
      setLoadingModalInstallments(true)
      const filter = {
        ...searchQueryInstallments,
        company: dataContract.contract.company,
        branch: dataContract.contract.branch,
        contract_type: dataContract.contract.contract_type,
        limit: pagi.pageSize,
        page: pagi.current - 1
      }
      const installments = await OfflineSearch.getInstallments(dataContract.contract.contract_code ?? "", filter)
      setDataInstallments({
        installments: installments.rows,
        summary: installments.summary
      })
      setPaginationInstallments(pagi)
      setLoadingModalInstallments(false)
    } catch (e) {
      message.error("Erro ao buscar as Parcelas do contrato");
      setLoadingModalInstallments(false)
    }

  };

  // buscando as informações dos contratos da busca principal
  const findCustomers = async (filter) => {
    try {
      if (filter == null || filter == '') {
        message.error("Campo de busca em branco.");
        return
      }
      if (selected == "customer_birth_date") {
        filter = moment(filter, "DD/MM/YYYY")
      }
      setSearchQuery({ [selected]: filter })
      setLoading(true)
      const customers = await OfflineSearch.findCustomer({ [selected]: filter, page: 0, limit: 10 })

      setPagination({
        current: 1,
        pageSize: 10,
        total: customers.count,
      })
      setData(customers.rows)
      setLoading(false)
    } catch (err) {
      message.error("Falha na busca de informações");
      setLoading(false)
    }

  }


  // Busca das informações do contrato selecionado 
  const detailContract = async (contract, company, branch, contract_type) => {
    try {
      setLoadingModalInfo(true)

      const dependents = await OfflineSearch.findDepedents(contract, company, branch, contract_type)

      const detail = await OfflineSearch.DetailContract(contract, company, branch, contract_type);

      setDataContract({
        contract: detail,
        dependents: dependents ?? [{}]
      })

      setLoadingModalInfo(false)
    } catch (e) {
      message.error("Erro ao buscar as informações do contrato");
      setLoadingModalInfo(false)
    }
  }

  const getInstallments = async () => {
    try {
      setLoadingModalInstallments(true)
      const filter = {
        ...searchQueryInstallments,
        company: dataContract.contract.company,
        branch: dataContract.contract.branch,
        contract_type: dataContract.contract.contract_type
      }
      const installments = await OfflineSearch.getInstallments(dataContract.contract.contract_code ?? "", filter)
      setDataInstallments({
        installments: installments.rows,
        summary: installments.summary
      })
      setPaginationInstallments({
        current: 1,
        pageSize: 5,
        total: installments.count,
      })
      setLoadingModalInstallments(false)
    } catch (e) {
      message.error("Erro ao buscar as Parcelas do contrato");
      setLoadingModalInstallments(false)
    }
  }

  // Abertura do modal de detalhamento
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Fechamento do modal de detalhamento
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const findBenefits = async () => {
    try {
      setLoadingModalBenefits(true)

      const filter = {
        company: dataContract.contract.company,
        branch: dataContract.contract.branch,
        contract_type: dataContract.contract.contract_type
      }

      const dependents = await OfflineSearch.getBenefits(dataContract.contract.contract_code, filter)

      setDataBenefits(dependents)

      setLoadingModalBenefits(false)
    } catch (e) {
      message.error("Erro ao buscar os Benefícios do plano");
      setLoadingModalBenefits(false)
    }
  }

  const handleFinish = async (values) => {
    try {
      setIsLoadingAutorization(true)
      let customer_type = 1
      let customer_id = null
      let age = 0
      let birthDate
      let graceDate = moment()

      if (typeUserAutorization != "TT" && typeUserAutorization != "NM") {
        customer_type = 2
        customer_id = values.deceased.split("-")[2].trim()
      }


      if (customer_type == 2) {
        const depen = dataContract.dependents.find(objeto => objeto.id == values.deceased.split("-")[2].trim())
        let birth = moment(depen.customer_birth_date, "YYYY-MM-DD")
        age = moment().diff(birth, "years")
        birthDate = birth
        graceDate = moment(depen.grace_period, "YYYY-MM-DD")
      }

      if (customer_type == 1) {
        let birth = moment(dataContract.contract.customer_birth_date, "DD-MM-YYYY")
        age = moment().diff(birth, "years")
        birthDate = birth
        graceDate = moment(dataContract.contract.contract_date, "DD-MM-YYYY").add(dataContract.contract.grace_period, "days")
      }


      const autor = await OfflineSearch.postAutorization(
        {
          contract_code: dataContract.contract.contract_code,
          behavior: 1,
          company: dataContract.contract.company,
          branch: dataContract.contract.branch,
          customer_type,
          customer_id,
          user_id: signin.user.id,
          stillborn: typeUserAutorization == "NM" ? true : false,
          deceased_name: typeUserAutorization == "NM" ? values.name_natimorto : values.deceased.split("-")[1].trim(),
          deceased_age: typeUserAutorization == "NM" ? 0 : age,
          stillbirth_relative_name: typeUserAutorization == "NM" ? "PAI/MÃE" : "Nada",
          deceased_gender: typeUserAutorization == "NM" ? "Natimorto" : values.gender,
          birth_date: typeUserAutorization == "NM" ? values.date_birth : birthDate,
          death_date: typeUserAutorization == "NM" ? moment(values.date_death) : moment(),
          deceased_grace_period: typeUserAutorization == "NM" ? moment() : graceDate,
          responsible_name: typeUserAutorization == "NM" ? values.parents : values.declarant,
          responsible_kinship: typeUserAutorization == "NM" ? "PAI/MÃE" : values.kinship,
          responsible_phone: typeUserAutorization == "NM" ? "Sem" : values.phone1,
          responsible_cellphone: typeUserAutorization == "NM" ? "Sem" : values.phone2,
          death_location: typeUserAutorization == "NM" ? "Sem" : values.locale_death,
          funeral_home: typeUserAutorization == "NM" ? "Sem" : values.funerary,
          death_reason: typeUserAutorization == "NM" ? "Sem" : values.reason_death,
          weight: typeUserAutorization == "NM" ? "Sem" : values.weight ? values.weight : "0" ,
          height: typeUserAutorization == "NM" ? "Sem" : values.height ? values.height : "0",
          religion: typeUserAutorization == "NM" ? "Sem" : values.religion ? values.religion : "Sem",
          has_death_certificate: typeUserAutorization == "NM" ? false : values.death_certificate,
          funeral_location: typeUserAutorization == "NM" ? "Sem" : values.place_funeral ? values.place_funeral : "Sem",
          burial_location: typeUserAutorization == "NM" ? "Sem" : values.place_burial ? values.place_funeral : "Sem",
          comments: "",
          type_death: typeUserAutorization == "NM" ? "Sem" : values.death_type
        }
      )


      if (!autor.statusGrace.status) {
        message.error("Autorização não aprovada, devido o contrato ou dependente está em carência");
        setIsLoadingAutorization(false)
        return
      }

      if (!autor.statusInstallments.status) {
        message.error("Autorização não aprovada, devido o contrato ter parcelas em atraso");
        setIsLoadingAutorization(false)

        return
      }
      message.success("Autorização cadastrada com sucesso");

      setIsLoadingAutorization(false)


    } catch (e) {
      message.error("Erro ao gerar a Autorização");
      setIsLoadingAutorization(false)
    }
  }

  //Components

  // Componente de seleção das informações do modal de detalhamento
  function ModalInfo(props) {
    switch (props.data.contract_type) {
      case 'PLANO':
        return (<ModalContract data={props.data} loading={loadingModalInfo} />)
        break;
      case 'JAZIGO':
        return (<ModalJazigo data={props.data} loading={loadingModalInfo} />)
        break;
      case 'FUNERAL':
        return (<ModalFuneralFuturo data={props.data} loading={loadingModalInfo} />)
        break;
      case 'PET':
        return (<ModalPet data={props.data} loading={loadingModalInfo} />)
        break;
      case 'CREMACAO':
        return (<ModalCrema data={props.data} loading={loadingModalInfo} />)
        break;
      default:
        return (<ModalContract data={props.data} loading={loadingModalInfo} />)
    }
  }

  // Componente de texto
  function FieldVier(props) {
    return (
      <Col span={props.span}>
        <Col>
          {props.field}
        </Col>
        <Col>
          <Input style={{ borderWidth: 3, color: "black" }} onChange={(e) => {
            let info = searchQueryInstallments
            if (e.target.value) {
              info[props.id] = e.target.value
            } else {
              delete info[props.id]
            }
            setSearchQueryInstallments(info)
          }} />
        </Col>
      </Col>
    )
  }

  //Componente de Data
  function FieldVierData(props) {
    return (
      <Col span={props.span}>
        <Col>
          {props.field}
        </Col>
        <Col span={24}>
          <DatePicker style={{ borderWidth: 3, color: "black", width: "100%" }} onChange={(date) => {
            let info = searchQueryInstallments
            if (date) {
              info[props.id] = date
            } else {
              delete info[props.id]
            }
            setSearchQueryInstallments(info)
          }} />
        </Col>
      </Col>
    )
  }

  //Componente de seleção
  function FieldVierSelect(props) {
    return (
      <Col span={props.span}>
        <Col>
          {props.field}
        </Col>
        <Col span={24}>
          <Select
            defaultValue={props.defaultValue}
            style={{ width: "100%", borderWidth: 3, color: "black" }}
            onChange={(value) => {
              let info = searchQueryInstallments
              if (info && value != "T") {
                info[props.id] = value
              } else {
                delete info[props.id]
              }
              setSearchQueryInstallments(info)
            }}
            options={props.options}
          />

        </Col>
      </Col>
    )
  }

  return (
    <>
      <Helmet>
        <title>Consulta</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensMenu}
            modulePath={props.modulePath}
            selectedMenu="6"
          />
        }
        breadcrumb={breadcrumb}
      >
        <Row justify={"center"} style={{ marginBottom: "3rem" }}>
          <Col xs={24} sm={12} align="middle" >
            <Col>
              <h1>
                Faça uma busca
              </h1>
            </Col>
            {/* botão de pesquisa */}
            <Row justify={"center"} style={{ marginBottom: "1rem" }}>
              <Col span={20} style={{ paddingRight: "0.5rem" }}>
                <Search placeholder="Pesquisar..." onSearch={findCustomers} enterButton />
              </Col>
            </Row>
            {/* Select do tipo de busca */}
            <Row justify={"center"} style={{ marginBottom: "3rem" }}>
              <Col className="select-container">
                {options.map((option) => (
                  <button
                    key={option.key}
                    className={`select-button ${selected === option.key ? 'selected' : ''}`}
                    onClick={() => setSelected(option.key)}
                  >
                    {option.value}
                  </button>
                ))}
              </Col>
            </Row>
            <Divider />
          </Col>
          {/* Listagem do que foi achado */}
          <Col span={24}>
            <Table
              dataSource={data}
              columns={columns}
              pagination={pagination}
              loading={loading}
              onChange={handleTableChange}
              onRow={(record) => {
                return {
                  onClick: async () => {
                    setDataContract({})
                    showModal()
                    await detailContract(record.contract_code, record.company, record.branch, record.contract_type)
                  },
                };
              }}
            />
          </Col>
        </Row>

        {/* Modal de beneficios */}
        <Modal title={"BENEFÍCIOS DO PLANO"}
          open={isModalBenefitsVisible}
          onCancel={() => {
            setIsModalBenefitsVisible(false)
          }}
          footer={null}
          destroyOnClose={true}
          width={"80%"}>

          {
            loadingModalBenefits ? (
              <>
                <div className="loading-centar">
                  <img src={loadingSVG} alt="loading" height={80} />
                </div>
                <h2 className="text-loading">Carregando...</h2>
              </>
            ) : <TableBenefits data={dataBenefits} />

          }
        </Modal>

        {/* Modal Mensalidades */}
        <Modal title={"CONSULTA DE MENSALIDADES"}
          open={isModalInstallmentsVisible}
          onCancel={() => {
            setIsModalInstallmentsVisible(false)
          }}
          footer={null}
          destroyOnClose={true}
          width={"80%"}>
          {
            // Lembrar de fazer um tratamento melhor para quando não tiver as informações.
            loadingModalInfo ? (
              <>
                <div className="loading-centar">
                  <img src={loadingSVG} alt="loading" height={80} />
                </div>
                <h2 className="text-loading">Carregando...</h2>
              </>
            ) : <div>
              <Row gutter={24} justify={"space-between"}>
                <Col span={24}>
                  {/* Nome */}
                  <Row gutter={12}>
                    <Col>
                      <CreditCardOutlined />
                    </Col>
                    <Col>
                      <p>Mensalidades</p>
                    </Col>
                  </Row>
                  {/* Busca de parcelas */}
                  <Row justify={"space-between"}>
                    <FieldVier span={4} field={"Prefixo:"} id={"prefix"} />
                    <FieldVierSelect span={4} field={"Status:"} defaultValue={searchQueryInstallments.status} id={"status"} options={[
                      { value: 'T', label: 'Todas' },
                      { value: 'A', label: 'Abertas' },
                      { value: 'B', label: 'Baixadas' },
                      { value: 'V', label: 'Vencidas' },
                      { value: 'D', label: 'Baixa Parcial' },
                      { value: 'C', label: 'Em cobrança' },
                      { value: 'P', label: 'Prorrogado' },
                      { value: 'F', label: 'Em Fatura' },
                      { value: 'G', label: 'Prescritas' },
                    ]} />
                    <FieldVierData span={4} field={"Data inicial:"} id={"startDate"} />
                    <FieldVierData span={4} field={"Data Final:"} id={"endDate"} />

                    <Col span={3} style={{ paddingTop: "2%" }}>
                      <Col span={24} >
                        <Button type="primary" icon={<SearchOutlined />} onClick={getInstallments}>
                          Pesquisar...
                        </Button>
                      </Col>
                    </Col>

                    {/**/}
                  </Row>
                  <Divider />
                  <TableInstallments data={dataInstallments.installments} onChange={handleTableChangeInstallments} pagination={paginationInstallments} />
                </Col>
                {/* procedimentos */}
              </Row>
              <Divider />

              <Row justify={"space-between"}>
                <Col span={6}>
                  <Card style={{ borderWidth: '4px', borderColor: '#d9d9d9', borderStyle: 'solid' }} title={"Contrato: " + dataContract.hasOwnProperty("contract") ? dataContract.contract?.contract_code : "000"}>
                    <p>{"Nome: " + dataContract.hasOwnProperty("contract") ? dataContract.contract?.customer_name : "000"}</p>
                    <p>{"Produto: " + dataContract.hasOwnProperty("contract") ? dataContract.contract?.product_name : "000"}</p>
                    <p>{"Opção de pag.: " + dataContract.hasOwnProperty("contract") ? dataContract.contract?.payment_method : "000"}</p>
                    <p> Valor: {"" + dataContract.hasOwnProperty("contract") ? dataContract.contract?.value_plan.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : 0}</p>
                    <p>{"Dia de Vencimento: " + dataContract.hasOwnProperty("contract") ? dataContract.contract?.due_day : "000"}</p>
                  </Card>
                </Col>
                <Col span={17}>
                  <Card style={{ borderWidth: '4px', borderColor: '#d9d9d9', borderStyle: 'solid' }} title="Resumo das parcelas">
                    <Row>
                      <Col span={6}>
                        <Card style={{ borderWidth: "3px" }} title={"ABERTO"}>
                          <p>{"Valor: " + dataInstallments.summary?.open?.value}</p>
                          <p>{"Quantidade: " + dataInstallments.summary?.open?.count}</p>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card style={{ borderWidth: "3px" }} title={"EM ATRASO"}>
                          <p>{"Valor: " + dataInstallments.summary?.delayed?.value}</p>
                          <p>{"Quantidade: " + dataInstallments.summary?.delayed?.count}</p>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card style={{ borderWidth: "3px" }} title={"BAIXADAS"}>
                          <p>{"Valor: " + dataInstallments.summary?.down?.value}</p>
                          <p>{"Quantidade: " + dataInstallments.summary?.down?.count}</p>
                        </Card>
                      </Col>
                      <Col span={6}>
                        <Card style={{ borderWidth: "3px" }} title={"PRESCRITAS"}>
                          <p>{"Valor: " + dataInstallments.summary?.prescribed?.value}</p>
                          <p>{"Quantidade: " + dataInstallments.summary?.prescribed?.count}</p>
                        </Card>
                      </Col>
                    </Row>

                  </Card>
                </Col>
              </Row>
            </div>
          }
        </Modal>

        {/* Modal de autorização de atendimento */}
        <Modal title={"AUTORIZAÇÃO DE ATENDIMENTO"}
          open={isModalAutorization}
          onCancel={() => {
            setIsModalAutorization(false)
          }}
          destroyOnClose={true}
          footer={null}>
          <Spin tip="Gerando a Autorização" spinning={isLoadingAutorization}>
            <Form form={form} onFinish={handleFinish} layout="vertical">

              <Form.Item
                label="Selecione o falecido:"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório",
                  },
                ]}
                name="deceased">
                <Select onChange={(value) => {
                  setTypeUserAutorization(value.split("-")[0].trim())
                }}>
                  {!dataContract.hasOwnProperty("contract") ? null :
                    <Select.Option key={dataContract.contract.id ?? ""} value={"TT - " + dataContract.contract.customer_name ?? ""}>
                      {"Titular - " + dataContract.contract.customer_name ?? ""}
                    </Select.Option>}
                  {!dataContract.hasOwnProperty("dependents") ? null : dataContract.dependents.map(
                    option => (
                      <Select.Option key={option.id} value={option.kinship + " - " + option.name + " - " + option.id}>
                        {option.kinship + " - " + option.name}
                      </Select.Option>
                    )
                  )}
                  <Select.Option key={"NM"} value={"NM - Natimorto"}>
                    {"NM - Natimorto"}
                  </Select.Option>
                </Select>
              </Form.Item>

              {
                typeUserAutorization != "NM" ? <FieldsAutorization /> : <FieldsNatimorto contract={dataContract.contract ?? null} dependents={dataContract.dependents ?? null} />
              }

              <Row justify="end">
                <Button type="primary" htmlType="submit">
                  Gera Autorização
                </Button>
              </Row>
            </Form>
          </Spin>
        </Modal>

        {/* Modal de detalhamento */}
        <Modal
          title={"CONSULTA DE CONTRATO"}
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={"90%"}
          destroyOnClose={true}
        >
          {
            loadingModalInfo ? (
              <>
                <div className="loading-centar">
                  <img src={loadingSVG} alt="loading" height={80} />
                </div>
                <h2 className="text-loading">Carregando...</h2>
              </>
            ) : <div>
              <Row gutter={24} justify={"space-between"}>
                {/* Consultas */}
                <Col span={12}>
                  <Row gutter={12}>
                    <Col>
                      <SearchOutlined />
                    </Col>
                    <Col>
                      <p>Consulta</p>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col>
                      <Button type="primary" icon={<CreditCardOutlined />} iconPosition={"start"} onClick={async () => {
                        setIsModalInstallmentsVisible(true)
                        setSearchQueryInstallments([{}])
                        await getInstallments()
                      }}>
                        Mensalidades
                      </Button>
                    </Col>
                    {dataContract.hasOwnProperty("contract") && dataContract.contract.contract_type == "PLANO" ? (<Col>
                      <Button type="primary" icon={<UnorderedListOutlined />} iconPosition={"start"} onClick={async () => {
                        setIsModalBenefitsVisible(true);
                        await findBenefits()
                      }}>
                        Benefícios do plano
                      </Button>
                    </Col>) : <di></di>}

                  </Row>
                </Col>
                {/* procedimentos */}
                <Col span={12}>
                  <Row gutter={12}>
                    <Col>
                      <SearchOutlined />
                    </Col>
                    <Col>
                      <p>Procedimentos</p>
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col>
                      <Button type="primary" icon={<CreditCardOutlined />} iconPosition={"start"} onClick={() => {
                        setIsModalAutorization(true)
                      }}>
                        Gerar Atendimento
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider />
              {/* informações  */}
              <ModalInfo data={dataContract.hasOwnProperty("contract") ? dataContract.contract : {}} />
              <Divider />
              <TableDependents data={dataContract.hasOwnProperty("dependents") ? dataContract.dependents : []} />
            </div>
          }
        </Modal>
      </Main>
    </>
  );
}

export default SearchOffline;
