import api from "../utils/api";

/**
 *
 * @param {object} params
 * @param {string?} params.name
 * @param {string?} params.cpf
 * @param {string?} params.phone
 * @param {string?} params.id
 * @returns
 */
export const contactsQuery = async (params) => {
    const query = new URLSearchParams(params);
    const response = await api.get(`/huggy/contacts?${query}`);

    return response.data;
};

/**
 *
 * @param {object} params
 * @param {string?} params.contact_id
 * @param {string?} params.id
 * @returns
 */
export const chatsQuery = async (params) => {
    const query = new URLSearchParams(params);
    const response = await api.get(`/huggy/chats?${query}`);

    return response.data;
};

/**
 *
 * @param {object} params
 * @param {string} params.chat_id
 * @param {string?} params.id
 * @returns
 */
export const messagesQuery = async (params) => {
    const query = new URLSearchParams(params);
    const response = await api.get(`huggy/messages?${query}`);

    return response.data;
};

export const getUserNotificationsQuery = async (params) => {
    const response = await api.get(
        `/notification-receivers?${new URLSearchParams({
            ...params,
            limit: 20,
            offset: 0,
        })}`
    );
    return response.data;
};
