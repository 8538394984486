import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Layout, Form, Input, Alert, Button } from "antd";

import { sessionApi } from "../../../../services/session";
import { signIn } from "../../../../store/ducks/signin";
import { setToken } from "../../../../utils/localStoreToken";
import { useSession } from "../../../../Context/SessionProvider";

const { Content } = Layout;

const Login = (props) => {
  const [form] = Form.useForm();
  const [formItemDisabled, setFormItemDisabled] = useState({});
  const [alertVisibility, setAlertVisibility] = useState("none");
  const [errorAlertVisibility, setErrorAlertVisibility] = useState("none");

  const dispatch = useDispatch();
  const { signin } = useSession();

  const onFinish = (values) => {
    setFormItemDisabled({ disabled: "disabled" });

    values["email"] = values["email"].trim().toLowerCase();

    sessionApi
      .signIn(values)
      .then((res) => {
        dispatch(signIn(res));
        // SessionContext
        signin(res);
        setToken(res.token);

        setErrorAlertVisibility("none");
        setAlertVisibility("block");

        props.setCurrent(1);
      })
      .catch((err) => {
        setFormItemDisabled({});
        setErrorAlertVisibility("block");
      });
  };

  return (
    <Content className="login-page-padding-form">
      <Form form={form} name="signin-form" onFinish={onFinish}>
        <Form.Item
          name="email"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Informe seu e-mail ou CPF (apenas números)!",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="E-mail ou CPF (apenas números)"
            {...formItemDisabled}
          />
        </Form.Item>
        <Form.Item
          name="password"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Informe sua senha!",
            },
          ]}
        >
          <Input.Password
            size="large"
            placeholder="Sua senha"
            {...formItemDisabled}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            block
            shape="round"
            {...formItemDisabled}
          >
            Logar
          </Button>
        </Form.Item>
        <a href="/forgot-password" className="login-page-forgot-password-link">
          Esqueceu a senha?
        </a>
      </Form>

      <Alert
        message="Login realizado com sucesso!"
        description="Você será redirecionado(a) para a página inicial."
        type="success"
        showIcon
        style={{ marginTop: "10px", display: alertVisibility }}
      />

      <Alert
        message="Dados incorretos!"
        description="Por favor, revise os dados e tente novamente."
        type="error"
        showIcon
        style={{ marginTop: "10px", display: errorAlertVisibility }}
      />
    </Content>
  );
};

export default Login;
