import { RecurrenceLayout } from "../components/RecurrenceLayout";
import {JoinRecurrenceContent} from "../components/JoinRecurrenceContent"
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { useFetch } from "../../../../../utils/useFetch";
import moment from "moment";
import {  getRecurrencejoinLinkQuery } from "../../../../../services/recurrenceService";
import { set } from "lodash";

function JoinRecurrence () {
  const { token } = useParams();

  const [isValidLink, setIsValidLink] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [validationError, setValidationError] = useState({
    hasError: false,
    message: "",
    title: "",
  });

  const { data: linkData, loading: loadingLinkData } = useFetch(
    getRecurrencejoinLinkQuery,
      {
          initialParams: {
              token,
          },
          initialState: {},
          onSuccess: (data) => {
              if (!data) {
                setIsValidLink(false);
                setErrorMessage("O link que você está tentando acessar é inválido")
              }

              const { active, used, expires_at, valid } = data;

              if (!active) {
                  setValidationError({
                    hasError: true,
                    title: "Link inválido",
                    message: "O link que você está tentando acessar não está ativo."
                  })
                  return;
              }

              if (used) {
                  setValidationError({
                    hasError: true,
                    title: "Link inválido",
                    message: "O link que você está tentando acessar já foi utilizado."
                  })
                  return;
              }

              if (moment(expires_at).isBefore(moment())) {
                  setValidationError({
                    hasError: true,
                    title: "Link inválido",
                    message: "O link que você está tentando acessar expirou."
                  })
                  return;
              }

              if (!valid) {
                setValidationError({
                  hasError: true,
                  title: "Contrato inelegível",
                  message: "Infelizmente seu contrato encontra-se inelegível para adesão à recorrência."
                })
              }
              
          },
          onError: (error) => {
            console.error(error)
              setIsValidLink(false);
          },
      }
  );
  

  return (
      <RecurrenceLayout pageTitle="Adesão à recorrência - Morada da Paz">
          <JoinRecurrenceContent
              data={{ ...linkData, token }}
              validationError={validationError}
              loading={loadingLinkData}
          />
      </RecurrenceLayout>
  );
}

export default JoinRecurrence;