import { getRequest, deleteRequest } from "../utils/api";

export const documentsSettingServiceApi = {
  documentsSettingService: async () => {
    return await getRequest(`/documents/setting/service/sector`, {});
  },

  setDocumentsSettingService: async (id_setting_service) => {
    return await getRequest(
      `/documents/setting/service?id_setting_service=${id_setting_service}`,
      {}
    );
  },

  deleteDocumentsSettingService: async (id_template_document) => {
    return await deleteRequest(
      `/delete/document/setting/service?id_template_document=${id_template_document}`,
      {}
    );
  },
};
