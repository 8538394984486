import React, { useState } from 'react';
import _ from 'lodash';
import './styles.css';
import queryString from 'query-string';
import {
  Row,
  Col,
  Card,
  Form,
  Spin,
  Input,
  Button,
  Radio,
  message,
} from 'antd';

import { api } from '../../../utils/apiProtheus';

import Error from '../../../assets/images/fechar.png';

const { TextArea } = Input;
const { Meta } = Card;

function NoDetectedObject(props) {
  return (
    <Card>
      <Meta
        avatar={<img alt="Error" src={Error} className="card-image" style={{ height: 60 }} />}
        title="Não foi possível localizar o contrato!"
        description="Não foi possível localizar o contrato, entrar em contato com o TI!"
      />
    </Card>
  );
}

function FormContractApproval(props) {
  const [loading, setLoading] = useState(false);
  const [approvalSuccess, setApprovalSuccess] = useState(false);

  const [contractApprovalForm] = Form.useForm();

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const data = {
        codigo: props.params.codigo,
        recnoz35: props.params.recnoz35,
        status: values.aprovacao ? "1" : "2",
        justificativa: values.justificativa,
      }

      await api.post("/all/SOLCONTRATO/solcontrato/aprovacao", data, {
        headers: {
          "Content-Type": "application/json",
          tenantId: `${props.params.empresa},${props.params.filial}`,
        },
      });

      setApprovalSuccess(true);
      message.success("Enviado com sucesso!");

    } catch (error) {
      message.error("Não foi possível gravar o registro!");
    }
    finally {
      setLoading(false);
    }

  }

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Card>
        <Row justify="center" align="top">
          <Col span={24}>
            <h3 className="title-ca">{props.params.type === "diretoria" ? "Solicitação de Aprovação de Contrato" : "Solicitação de Validação de Contrato"} </h3>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p className="paragrafo" >Contrato enviado para {props.params.type === "diretoria" ? "aprovação" : "validação"} conforme anexos enviados via e-mail.</p>
          </Col>
        </Row>
        <Form
          form={contractApprovalForm}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={
            {
              descricao: props.params.descricao,
              aprovacao: false,
              justificativa: "",
            }
          }
        >
          <Row>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Descrição do Contrato:"
                name={'descricao'}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Aprovar Contrato:"
                name={'aprovacao'}
              >
                <Radio.Group disabled={approvalSuccess}>
                  <Radio value={true}>Sim</Radio>
                  <Radio value={false}>Não</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24}>
              <Form.Item
                label="Justificativa:"
                name={'justificativa'}
              >
                <TextArea rows="4" disabled={approvalSuccess} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                disabled={approvalSuccess}
              >
                Confirmar
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </Spin>
  );
}

function ContractApproval(props) {
  const params = queryString.parse(props.location.search);
  return (
    <Row justify="center" align="top">
      <Col span={15}>
        {
          _.isEmpty(params) ?
            <NoDetectedObject />
            :
            <FormContractApproval params={params} />
        }
      </Col>
    </Row>
  );
}

export default ContractApproval;