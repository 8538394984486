import React, { useContext } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Col, Row, Layout, Typography, Button, Table, Empty } from "antd";

import {
  columnsContracts,
  columnsContractsComplete,
  buttons,
} from "../../mocks";
import { UnifiedQueryContext } from "../../../../../../Context/unifiedQueryContext";
import { IconTable } from "../Icon";


import { setContract } from "../../../../../../store/ducks/UnifiedQuery";

const { Content } = Layout;
const { Title } = Typography;

function Contracts() {
  const context = useContext(UnifiedQueryContext);
  const history = useHistory();
  const dispatch = useDispatch();

  const screenContractPush = (value) => {
    const dataContract = context.data.constracts.find(
      (data) => data.contract === value.contract
    );

    dispatch(setContract(dataContract));
    history.push(`/${context.path}/unified/query/contract`);
  };

  const HeaderContract = () => (
    <Content className="content-header-contract-unified-query">
      <Title level={5}>Contratos</Title>
      <Col className="content-header-contract-right-unified-query">
        <span className="content-header-contract-text-unified-query">
          {context.data && context.data.constracts
            ? context.data.constracts.length
            : "0"}{" "}
          resultados
        </span>
        <span>
          <Button
            icon={<IconTable current={context.current} />}
            onClick={() => {
              if (context.current !== 0) {
                context.setCurrent(0);

                return;
              }

              context.setCurrent(
                buttons.find((value) => value.key === "contracts").current
              );
            }}
          />
        </span>
      </Col>
    </Content>
  );

  const tableOrEmpty = (columns, data) => {
    if (data && data.length === 0)
      return (
        <>
          <br />
          <Empty description="Nenhum Contrato encontrado!" />
        </>
      );

    return (
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            size="small"
            rowClassName="unified-query-contract-table-row-click"
            pagination={false}
            onRow={(record) => {
              return {
                onClick: () => screenContractPush(record),
              };
            }}
          />
        </Col>
      </Row>
    );
  };

  return context.current === 1 ? (
    <Col className="content-contract-unified-query content-contract-unified-query-margin-top">
      <HeaderContract context={context} />
      {tableOrEmpty(columnsContractsComplete, context.data.constracts)}
    </Col>
  ) : (
    <Col
      xs={24}
      sm={16}
      md={16}
      lg={16}
      className="content-contract-unified-query"
    >
      <Row>
        <HeaderContract context={context} />
      </Row>
      {tableOrEmpty(columnsContracts, context.data.constracts)}
    </Col>
  );
}

export default Contracts;
