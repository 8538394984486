import { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
} from 'antd';

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';

import "./style.css";
import Main from "../../../Layout/Main";
import MenuComponent from '../../../../components/MenuComponent';
import { itensDrawer } from "../itens-menu.js";
import {
  propsBar,
  propsPier,
} from "./propsGraph";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
);

const Dashboard = (props) => {

  const result = {
    informacoesDoObjeto: {
      labels: [""],
      values: [0]
    },
    informacoesDoLote: {
      labels: [""],
      values: [0]
    },
    eficienciaEntrega: {
      labels: [""],
      values: [0]
    },
    objetosPorEntregador: {
      labels: [""],
      values: [0]
    }
  }

  const [data, setData] = useState();
  const typeGraph = {
    informacoesDoObjeto: {
      data: propsPier,
    },
    informacoesDoLote: {
      data: propsPier
    },
    eficienciaEntrega: {
      data: propsBar,
    },
    objetosPorEntregador: {
      data: propsBar,
    }
  }

  const breadcrumb = [
    {
      title: "Faturamento",
      path: props.modulePath
    },
    {
      title: "Dashboard",
      path: props.modulePath + "/objects/dashboard"
    }
  ];

  useEffect(() => {
    getData();

  });

  const getData = async () => {
    Object
      .keys(result)
      .map(key => {

        console.log(typeGraph[key].data);
      })
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
    },
  };

  return (
    <Main
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent itensMenu={itensDrawer} modulePath={props.modulePath} selectedMenu="2" />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"flex"}

    >
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Card title="Informações de lotes">
            <Pie data={propsPier} />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title="Informações do objeto">
            <Pie data={propsPier} />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title="Eficiência de entrega">
            <Bar options={options} data={propsBar} />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card title="Objetos por entregador">
            <Bar options={options} data={propsBar} />
          </Card>
        </Col>
      </Row>
    </Main>
  );
}

export default Dashboard;