import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const listResourcesApi = {
  listResources: (limit, offset, active = true) =>
    getRequest(
      "/resources?limit=" + limit + "&offset=" + offset + "&active=" + active,
      {}
    ),
};

export const listResourcesFilterApi = {
  listResourcesFilter: (description) =>
    getRequest("/resources/filter?description=" + description, {}),
};

export const addResourcesApi = {
  addResources: (body) => postRequest("/resources", body),
};

export const updateResourcesApi = {
  updateResources: (body) => putRequest("/resources/" + body.id, body),
};

export const deleteResourcesApi = {
  deleteResources: (id) => deleteRequest("/resources/" + id, {}),
};
