import React from "react";
import "./styles.css";

import Main from "../../Layout/Main";
import MenuComponent from "../../../components/MenuComponent";
import itensMenu from "./itens-menu";

function HomeCeremonialist(props) {
  const breadcrumb = [
    {
      title: "Cerimonialista",
      path: props.modulePath,
    },
  ];

  return (
    <Main
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          itensMenu={itensMenu}
          modulePath={props.modulePath}
          selectedMenu="0"
        />
      }
      breadcrumb={breadcrumb}
    >
      <h1>Bem vindo ao módulo Cerimonialista</h1>
    </Main>
  );
}

export default HomeCeremonialist;
