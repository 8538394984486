import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Tag, Input } from "antd";
import {
  SearchOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet";

import { listUser, alterUser } from "../../../../store/ducks/users";

import MainCrud from "../../../../components/MainCrud";
import Main from "../../../Layout/Main";
import { ModalApp } from "../../../../components/Modal";

import { useDebounce } from "../../../../utils/useDebounce";
import MenuComponent from "../../../../components/MenuComponent";
import { itensMenu } from "../itens-menu";
import { listCustomerApi } from "../../../../services/customer";
import moment from "moment";

function CustomerAppList(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState({ email: "", name: "", cpf: "" });

  const signin = useSelector((state) => state.signin);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  // Evita que seja feita um requisição a cada novo caracter digitado na pesquisa
  const debouncedSearchQuery = useDebounce(searchQuery, 400);

  useEffect(() => {
    list();
  }, [page, rowsPerPage, users.user, debouncedSearchQuery]);

  function list() {
    try {
      setLoading(true);

      const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
      listCustomerApi
        .listCustomer(
          rowsPerPage,
          offset,
          searchQuery.name,
          searchQuery.email,
          searchQuery.cpf,
        )
        .then((response) => {
          dispatch(listUser(response));
        });
    } catch (error) {
      message.error("Ops! Aconteceu um erro ao carregar os dados.");
    } finally {
      setLoading(false);
    }
  }

  function handleChangeSearchQuery(e) {
    const { name, value } = e.target;
    setSearchQuery({ ...searchQuery, [name]: value });
  }

  // filtrar os usuários no backend
  const getColumnSearchApi = (column) => ({
    filterDropdown: () => (
      <div className="divSearch">
        <Input
          style={{ marginBottom: 8 }}
          placeholder="Pesquisar..."
          value={searchQuery[column]}
          onChange={handleChangeSearchQuery}
          name={column}
        />
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });


  async function handleTableChange(pagination) {
    setPage(pagination.current);
  }

  const handlerShowSizeChange = (current, size) => {
    setRowsPerPage(size)
  }

  const breadcrumb = [
    {
      title: "Central de Atendimento ao Cliente",
      path: props.modulePath,
    },
    {
      title: "Usuários do App Morada",
      path: props.modulePath + "/customer-app",
    },
  ];

  let columns = [
    {
      title: "Nome",
      dataIndex: "name",
      ...getColumnSearchApi("name"),
    },
    {
      title: "CPF",
      dataIndex: "cpf",
      ...getColumnSearchApi("cpf"),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      ...getColumnSearchApi("email"),
    },
    {
      title: "Telefone",
      dataIndex: "phone",
    },
    {
      title: "Código do cliente",
      dataIndex: "code_customer_protheus",

    },
    {
      title: "Tipo",
      dataIndex: "is_guest",
      render: (value) => {
        return value ? "Convidado" : "Cliente"
      }
    },
    {
      title: "Data de criação",
      dataIndex: "created_at",
      render: (date) =>{
        return moment(date).format("DD/MM/YYYY");
      }
    },
  ];

  return (
    <>
      <Helmet>
        <title>Usuários do App Morada - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensMenu}
            modulePath={props.modulePath}
            selectedMenu="6"
          />
        }
        breadcrumb={breadcrumb}
      >
        <MainCrud

          columnsTable={columns}
          dataSource={users.userList}
          loading={loading}
          page={page}
          rowsPerPage={rowsPerPage}
          handlerShowSizeChange={handlerShowSizeChange}
          countPages={users.userListCount}
          handleTableChange={handleTableChange}
          profiles={signin.user.profiles}
          classTag={[]}
          displayMainCrud="none"
        />
        <ModalApp title="Usuário" width={1000} footer={null}>
          {/* <FormApp /> */}
        </ModalApp>
      </Main>
    </>
  );
}

export default CustomerAppList;
