import {
  useState,
} from 'react';

import {
  Row,
  Col,
  Table,
  Input,
  Button,
  Space,
  message,
} from 'antd';

import {
  DeleteOutlined,
} from '@ant-design/icons';

import {
  validateEmail
} from '../../../../../../../utils/text';

const Email = (props) => {
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(1);
  const [email, setEmail] = useState("");

  const columns = [
    {
      title: 'E-mail',
      dataIndex: 'email',
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'right',
      render: (data) => (
        <Space size="middle">
          <Button
            type="text"
            onClick={() => deleteData(data.email)}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const deleteData = (email) => {
    const filtered = props.emails.filter(function (el) { return el.email !== email; });

    props.setEmails(filtered);
  }

  const add = () => {
    try {
      if (!email)
        throw "É necessário informar um e-mail.";

      if (!validateEmail(email))
        throw "É necessário informar um e-mail válido.";

      const findEmail = props.emails.filter(data => data.email === email);

      if (findEmail.length > 0)
        throw "Email já cadastrado.";

      setEmail("");
      props.setEmails([{ email }, ...props.emails]);

    } catch (error) {
      message.warning(error);
    }
  }

  return (
    <Row>
      <Col xs={24}>
        <Row gutter={[8, 8]}>
          <Col span={20}>
            <Input
              allowClear
              value={email}
              style={{ width: "100%" }}
              placeholder="Informe o e-mail"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Col>
          <Col span={4}>
            <Button
              style={{ width: "100%" }}
              onClick={add}
            >
              Adicionar
            </Button>
          </Col>
        </Row>
        <Row gutter={[8, 8]} style={{ marginTop: "10px" }}>
          <Col span={24}>
            {
              <Table
                columns={columns}
                dataSource={props.emails}
                pagination={{
                  current: offset,
                  pageSize: limit,
                  total: props.emails.length,
                }}
                size="small"
              //onChange={handleTableChange}
              />
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Email;