const Types = {
     ADD: "ADD",
     UPDATE: "UPDATE",
     REMOVE: "REMOVE",
     GET: "GET",
     SET: "SET_PROFILE",
};

const INITIAL_STATE = {
     companyList: [],
     companyListCount: 0,
     company: [],
};

export default function reducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case Types.GET:
               return {
                    ...state,
                    companyList: action.payload.rows,
                    companyListCount: action.payload.count,
               };

          case Types.ADD:
               return {
                    ...state,
                    companyList: action.payload.rows,
                    companyListCount: action.payload.count,
               };

          case Types.UPDATE:
               return {
                    ...state,
                    companyList: action.payload.rows,
                    companyListCount: action.payload.count,
               };

          case Types.REMOVE:
               return {
                    ...state,
                    companyList: action.payload.rows,
                    companyListCount: action.payload.count,
               };

          case Types.SET:
               return {
                    ...state,
                    company: {
                         ...action.payload,
                    },
               };

          default:
               return state;
     }
}

export function listCompany(data) {
     return {
          type: Types.GET,
          payload: data,
     };
}

export function addCompany(data) {
     return {
          type: Types.ADD,
          payload: data,
     };
}

export function alterCompany(data) {
     return {
          type: Types.SET,
          payload: data,
     };
}

export function updateCompany(data) {
     return {
          type: Types.UPDATE,
          payload: data,
     };
}

export function deleteCompany(data) {
     return {
          type: Types.REMOVE,
          payload: data,
     };
}