import { Descriptions,  Modal, Row, Tag, Typography } from "antd";
import moment from "moment";
import { useMemo } from "react";
import { getStatus, statusDictionary } from "../index";
import "./styles.css";

export const DetailsModal = ({ open, payment = {}, setOpen }) => {
  const status = useMemo(() =>
    getStatus({
      statusId: payment.status,
      createdAt: payment.createdAt,
      expiresIn: payment.expiration,
    }),
    [payment]
  );

  const canceledStatusId = parseInt(
    Object.keys(statusDictionary).find(
      (key) => statusDictionary[key].description === "Cancelado"
    )
  );

  return (
    <Modal
      onCancel={() => setOpen(false)}
      open={open}
      title={`PIX - ${payment.proposal}`}
      width={800}
      footer={null}
      destroyOnClose={true}
      centered
      className="modal-details"
    >
      <Descriptions className="payment-description" bordered size="small">
        <Descriptions.Item label="Nº da proposta" span={3}>
          {payment.proposal}
        </Descriptions.Item>
        <Descriptions.Item label="Tipo da proposta" span={3}>
          {payment.typeProposal}
        </Descriptions.Item>
        <Descriptions.Item label="Negócio da proposta" span={3}>
          {payment.businessProposal}
        </Descriptions.Item>
        <Descriptions.Item label="CPF do consultor(a)" span={3}>
          {payment.cpfConsultant}
        </Descriptions.Item>
        <Descriptions.Item label="Email do cliente" span={3}>
          {payment.email}
        </Descriptions.Item>
        <Descriptions.Item label="Valor do PIX" span={3}>
          {payment.value?.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </Descriptions.Item>
        <Descriptions.Item label="Status" span={3}>
          <Tag color={status?.color}>{status?.description}</Tag>
        </Descriptions.Item>

        <Descriptions.Item label="TXID" span={3}>
          {payment.txid}
        </Descriptions.Item>
        {payment.status !== canceledStatusId && (
          <Descriptions.Item label="Link PIX" span={3}>
            <Row align="middle" justify="space-between">
              <Typography.Link
                style={{ width: "100%" }}
                href={payment.link}
                target="_blank"
                ellipsis
                copyable={{
                  text: payment.link,
                  tooltips: ["Copiar link", "Link copiado com sucesso!"],
                }}
              >
                {payment.link}
              </Typography.Link>
            </Row>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Data de criação" span={3}>
          {payment.createdAt &&
            moment(payment.createdAt).format("DD/MM/YYYY HH:mm")}
        </Descriptions.Item>
        <Descriptions.Item label="Data de expiração" span={3}>
          {payment.expiration &&
            payment.createdAt &&
            moment(payment.createdAt)
              .add(payment.expiration, "hours")
              .format("DD/MM/YYYY HH:mm")}
        </Descriptions.Item>
        <Descriptions.Item label="Observações" span={3}>
          {payment.obs}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};
