/* eslint-disable no-template-curly-in-string */
import {
  useState,
  useEffect,
  useContext
} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Button,
  message,
  AutoComplete,
  Collapse,
  Select
} from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import axios from 'axios';

import MaskedInput from "antd-mask-input";
import InputMask from "react-input-mask";


import { RemovalAnticipationFormContext } from '../../../../../../Context/removal-anticipation-form-context';
import SelectStaticProtheusData from '../../../../../../components/SelectStaticProtheusData';
import { checkCPF } from "../../../../../../utils/validations";
import { useSelector } from "react-redux";
import { getPlaceDetails, usePlacesAutocomplete } from "../../../../../../services/autocompleteService";
import { companiesLocation } from '../../../../../../utils/constants';
import { getClientByCpfQuery, getVendedor } from '../../../../../../services/protheusService';
import moment from 'moment';
import { getCityCode } from '../../../../../../utils/address';

const defaultRequiredMessage = "Campo ${label} é obrigatório!";


const DeceasedData = () => {

  const [cep, setCep] = useState("");
  const [loading, setLoading] = useState(false);
  const signin = useSelector((state) => state.signin);
  const removalAnticipationFormContext = useContext(RemovalAnticipationFormContext);
  const [removalAnticipationContractDataForm] = Form.useForm();
  const removeType = Form.useWatch(['contract', 'removeType'], removalAnticipationContractDataForm);
  const addressState = Form.useWatch(['contract', 'address', 'state'], removalAnticipationContractDataForm);
  const addressCity = Form.useWatch(['contract', 'address', 'city'], removalAnticipationContractDataForm);
  const [sallerInputStatus, setSallerInputStatus] = useState('none')



  const { Hierarquia } = signin.user.userCompanies.find(
    (company) => company.id === signin.user.currentCompany
  );

  const companyLocation = companiesLocation.find(
    (company) => company.code_erp === Hierarquia.code_erp
  );

  const { setValue, suggestions } = usePlacesAutocomplete({
    location: companyLocation,
  });
  
  useEffect(() => {
    handleCep()
  }, [cep]);


  useEffect(() => {
    if (addressState) {

      (async () => {
        const codMun = await getCityCode(addressState, addressCity)

        if (!codMun) {
          return
        }

        removalAnticipationContractDataForm.setFieldsValue({
          contract: {
            address: {
              // Primeiros dois digitos são o codigo do estado
              codMun: codMun
            }
          }
        })
      })()

    }
  }, [addressState, addressCity])

  const handleCep = async () => {
    try {

      if (!cep) {
        return
      }

      setLoading(true)

      const result = await axios.get(`https://viacep.com.br/ws/${cep.replace(/\.|\-/g, '')}/json/`);

      removalAnticipationContractDataForm.setFieldsValue({
        contract: {
          address: {
            address: result.data.logradouro,
            district: result.data.bairro,
            city: result.data.localidade,
            state: result.data.uf,
            complement: result.data.complemento
          }
        }
      })
    } catch (error) {
      message.info('Não foi possível localizar esse CEP')
    } finally {
      setLoading(false);
    }
  }

  const handleSelectPrediction = async (addressData) => {
    const addressDetails = await getPlaceDetails(addressData.place_id);
    removalAnticipationContractDataForm.setFieldsValue({
      contract: {
        address: addressDetails,
      },
    });
  };

  const handleSearchClient = async (e) => {
      const value = e.target.value.replace(/\D/g, "");

      if (value.length === 11) {
          const resp = await getClientByCpfQuery(value);
          const birthDate = moment(resp.nascimento, "DD/MM/YYYY HH:mm:ss");

          if (Object.keys(resp).length > 0) {
              removalAnticipationContractDataForm.setFieldsValue({
                  contract: {
                      address: {
                          cep: resp.cep,
                          address: resp.rua,
                          district: resp.bairro,
                          city: resp.cidade,
                          state: resp.uf,
                          number: resp.numero,
                          complement: resp.complemento,
                      },
                      fullName: resp.nome,
                      birthDate: birthDate.isValid() ? birthDate : null,
                      gender: resp.sexo,
                      cell: resp.celular,
                      telephone: resp.telefone,
                      maritalStatus: resp.estadoCivil,
                      email: resp.email,
                  },
              });
          }
      }
  };

  const handleSubmit = (values) => {
    setLoading(true);
    try {
      removalAnticipationFormContext.setFormResult({
        ...removalAnticipationFormContext.formResult,
        contract: values.contract
      });

      removalAnticipationFormContext.setCurrent(removalAnticipationFormContext.current + 1)

    } catch (error) {
      message.error(error)
    } finally {
      setLoading(false);
    }
  }

  return (
      <Form
          onKeyDown={(e) => {
              if (e.key === "Enter") {
                  e.preventDefault();
              }
          }}
          form={removalAnticipationContractDataForm}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
              contract: removalAnticipationFormContext.formResult.contract,
          }}
      >
          <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
              <Col xs={24} sm={8} md={6}>
                  <Form.Item
                      name={["contract", "removeType"]}
                      label="Tipo de remoção"
                      initialValue={"humano"}
                  >
                      <Select
                          options={[
                              { label: "Humano", value: "humano" },
                              { label: "PET", value: "pet" },
                          ]}
                      />
                  </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={6}>
                  <SelectStaticProtheusData
                      defaultValue="300004"
                      type="salesSource"
                      label="Origem"
                      name={["contract", "salesSource"]}
                      rules={[
                          {
                              required: true,
                              message: defaultRequiredMessage,
                          },
                      ]}
                  />
              </Col>
              <Col xs={24} sm={8} md={6}>
                  {removeType === "humano" ? (
                      <SelectStaticProtheusData
                          type="attendanceType"
                          label="Tipo de Atendimento"
                          name={["contract", "attendanceType"]}
                          rules={[
                              {
                                  required: true,
                                  message: defaultRequiredMessage,
                              },
                          ]}
                      />
                  ) : (
                      <SelectStaticProtheusData
                          type="salesChannel"
                          label="Canal de Venda"
                          name={["contract", "salesChannel"]}
                          rules={[
                              {
                                  required: true,
                                  message: defaultRequiredMessage,
                              },
                          ]}
                      />
                  )}
              </Col>
              <Col xs={24} sm={10} md={6}>
                  <SelectStaticProtheusData
                      type="attendanceModality"
                      label="Modalidade de atendimento"
                      name={["contract", "attendanceModality"]}
                      rules={[
                          {
                              required: true,
                              message: defaultRequiredMessage,
                          },
                      ]}
                  />
              </Col>

              {removeType === "humano" && (
                  <Col xs={24} sm={8} md={6}>
                      <Form.Item
                          required
                          name={["contract", "deceasedType"]}
                          label="Restos mortais"
                      >
                          <Select
                              defaultValue={""}
                              options={[
                                  { label: "Sim", value: "09" },
                                  { label: "Não", value: "" },
                              ]}
                          />
                      </Form.Item>
                  </Col>
              )}
              <Col>
                  <Form.Item
                      name={["contract", "saller"]}
                      label="Código do vendedor(a)"
                      rules={[
                          {
                              required: true,
                              message: defaultRequiredMessage,
                          },
                          {
                              validator: async (_, value) => {
                                  if (!value) {
                                      setSallerInputStatus("none");
                                      return Promise.resolve();
                                  }

                                  if (value.length < 6) {
                                      setSallerInputStatus("none");
                                      return Promise.reject(
                                          "Código do vendedor inválido!"
                                      );
                                  }

                                  try {
                                      setSallerInputStatus("loading");

                                      const resp = await getVendedor(value);

                                      if (!resp) {
                                          throw new Error();
                                      }

                                      setSallerInputStatus("success");
                                      return Promise.resolve();
                                  } catch (error) {
                                      setSallerInputStatus("error");
                                      return Promise.reject(
                                          "Código do vendedor inválido!"
                                      );
                                  }
                              },
                          },
                      ]}
                      hasFeedback
                      validateStatus={sallerInputStatus}
                  >
                      <Input.Search
                          loading={sallerInputStatus === "loading"}
                          onSearch={() => {
                              removalAnticipationContractDataForm.validateFields(
                                  [["contract", "saller"]]
                              );
                          }}
                      />
                  </Form.Item>
              </Col>
          </Row>

          <Collapse defaultActiveKey={["1"]}>
              <Collapse.Panel
                  header={
                      removeType === "humano"
                          ? "Dados do declarante"
                          : "Dados do tutor"
                  }
                  key="1"
              >
                  <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                      <Col xs={24} sm={9} lg={6}>
                          <Form.Item
                              label="CPF"
                              name={["contract", "cpf"]}
                              rules={[
                                  {
                                      required: true,
                                      message: defaultRequiredMessage,
                                  },
                                  ({ getFieldValue }) => ({
                                      validator(rule, value) {
                                          if (checkCPF(value) || !value) {
                                              return Promise.resolve();
                                          } else {
                                              return Promise.reject(
                                                  "Informe um CPF válido!"
                                              );
                                          }
                                      },
                                  }),
                              ]}
                          >
                              <MaskedInput
                                  onChange={handleSearchClient}
                                  mask="000.000.000-00"
                                  placeholder="000.000.000-00"
                                  allowClear
                              />
                          </Form.Item>
                      </Col>
                      <Col xs={24} sm={15} lg={13}>
                          <Form.Item
                              label="Nome Completo"
                              name={["contract", "fullName"]}
                              rules={[
                                  {
                                      required: true,
                                      message: defaultRequiredMessage,
                                  },
                              ]}
                          >
                              <Input allowClear />
                          </Form.Item>
                      </Col>
                      <Col xs={24} sm={6} md={6} lg={5}>
                          <SelectStaticProtheusData
                              type="gender"
                              label="Gênero"
                              name={["contract", "gender"]}
                              rules={[
                                  {
                                      required: true,
                                      message: defaultRequiredMessage,
                                  },
                              ]}
                          />
                      </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                      <Col>
                          <Form.Item
                              label="Data de Nascimento"
                              name={["contract", "birthDate"]}
                              rules={[
                                  {
                                      required: true,
                                      message: defaultRequiredMessage,
                                  },
                              ]}
                          >
                              <DatePicker
                                  format={"DD/MM/YYYY"}
                                  locale={locale}
                              />
                          </Form.Item>
                      </Col>
                      <Col xs={24} sm={8}>
                          <SelectStaticProtheusData
                              type="kinShip"
                              label="Parentesco"
                              name={["contract", "declarantKinship"]}
                              rules={[
                                  {
                                      required: true,
                                      message: defaultRequiredMessage,
                                  },
                              ]}
                          />
                      </Col>
                      <Col xs={24} sm={8}>
                          <SelectStaticProtheusData
                              type="maritalStatus"
                              label="Estado Civil"
                              name={["contract", "maritalStatus"]}
                              rules={[
                                  {
                                      required: true,
                                      message: defaultRequiredMessage,
                                  },
                              ]}
                          />
                      </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 12, md: 24 }}></Row>
                  <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                      <Col xs={24} md={4}>
                          <Form.Item
                              label="CEP"
                              name={["contract", "address", "cep"]}
                              rules={[
                                  {
                                      required: false,
                                      message: defaultRequiredMessage,
                                  },
                              ]}
                          >
                              <MaskedInput
                                  mask="00.000-000"
                                  placeholder="00000-000"
                                  allowClear
                                  onBlur={(e) => setCep(e.target.value)}
                              />
                          </Form.Item>
                      </Col>
                      <Col xs={24} md={16}>
                          <Form.Item
                              label="Endereço"
                              name={["contract", "address", "address"]}
                              rules={[
                                  {
                                      required: true,
                                      message: defaultRequiredMessage,
                                  },
                              ]}
                          >
                              <AutoComplete
                                  options={suggestions?.map(
                                      ({ description, ...rest }) => ({
                                          value: description,
                                          ...rest,
                                      })
                                  )}
                                  onSelect={(_, option) => {
                                      handleSelectPrediction(option);
                                  }}
                                  onSearch={(value) => setValue(value)}
                              />
                          </Form.Item>
                      </Col>
                      <Col xs={24} md={4}>
                          <Form.Item
                              label="Número"
                              name={["contract", "address", "number"]}
                              rules={[
                                  {
                                      required: true,
                                      message: defaultRequiredMessage,
                                  },
                              ]}
                          >
                              <Input />
                          </Form.Item>
                      </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                      <Col xs={24} md={10}>
                          <Form.Item
                              label="Bairro"
                              name={["contract", "address", "district"]}
                              rules={[
                                  {
                                      required: true,
                                      message: defaultRequiredMessage,
                                  },
                              ]}
                          >
                              <Input />
                          </Form.Item>
                      </Col>
                      {/* Codigo do municipio */}
                      <Form.Item
                          name={["contract", "address", "codMun"]}
                          hidden
                      >
                          <Input />
                      </Form.Item>
                      <Col xs={24} md={8}>
                          <Form.Item
                              label="Cidade"
                              name={["contract", "address", "city"]}
                              rules={[
                                  {
                                      required: true,
                                      message: defaultRequiredMessage,
                                  },
                              ]}
                          >
                              <Input />
                          </Form.Item>
                      </Col>
                      <Col xs={24} md={3}>
                          <Form.Item
                              label="Estado"
                              name={["contract", "address", "state"]}
                              rules={[
                                  {
                                      required: true,
                                      message: defaultRequiredMessage,
                                  },
                              ]}
                          >
                              <Input />
                          </Form.Item>
                      </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                      <Col xs={24} md={24}>
                          <Form.Item
                              label="Complemento"
                              name={["contract", "address", "complement"]}
                          >
                              <Input />
                          </Form.Item>
                      </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                      <Col xs={24} sm={12} lg={6}>
                          <Form.Item
                              label="Celular"
                              name={["contract", "cell"]}
                              rules={[
                                  {
                                      required: true,
                                      message: defaultRequiredMessage,
                                  },
                              ]}
                              normalize={(value) => value.replace(/\D/g, "")}
                          >
                            <InputMask
                                mask="(99) 9 9999-9999"
                                placeholder="(84) 9 0000-0000"
                                allowClear
                                maskChar={null}
                                
                            >
                                {(inputProps) => <Input {...inputProps} />}
                            </InputMask>
                          </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={6}>
                          <Form.Item
                              label="Telefone"
                              name={["contract", "telephone"]}
                              normalize={(value) => value.replace(/\D/g, "")}
                          >
                            <InputMask
                                mask="(00) 0000-0000"
                                placeholder="(84) 0000-0000"
                                allowClear
                                maskChar={null}
                            >
                                {(inputProps) => <Input {...inputProps} />}
                            </InputMask>
                          </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} lg={12}>
                          <Form.Item
                              label="E-mail"
                              name={["contract", "email"]}
                              rules={[
                                  {
                                      required: true,
                                      type: "email",
                                      message: "Informe um e-mail válido!",
                                  },
                              ]}
                          >
                              <Input />
                          </Form.Item>
                      </Col>
                  </Row>
              </Collapse.Panel>

              {removeType === "pet" && (
                  <Collapse.Panel header="Dados do Declarante" key="2">
                      <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                          <Col xs={24} sm={12}>
                              <Form.Item
                                  label="Nome"
                                  name={["contract", "declarant", "fullName"]}
                              >
                                  <Input />
                              </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                              <Form.Item
                                  label="Email"
                                  name={["contract", "declarant", "email"]}
                              >
                                  <Input />
                              </Form.Item>
                          </Col>
                      </Row>
                      <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                          <Col xs={24} sm={12}>
                              <Form.Item
                                  label="Telefone"
                                  name={["contract", "declarant", "telephone"]}
                              >
                                  <MaskedInput
                                      mask="(00) 0000-0000"
                                      placeholder="(84) 0000-0000"
                                      allowClear
                                  />
                              </Form.Item>
                          </Col>
                      </Row>
                  </Collapse.Panel>
              )}
          </Collapse>

          <Row justify="end" style={{ marginTop: "1rem" }}>
              <Col>
                  <Button type="primary" htmlType="submit">
                      Avançar
                  </Button>
              </Col>
          </Row>
      </Form>
  );
}

export default DeceasedData;