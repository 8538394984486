import React, { useState } from "react";
import "./style.css";
import { useSelector } from "react-redux";
import { Modal, Form, Spin, Tabs, notification } from "antd";
import _ from "lodash";
import { listItemTypeApi } from "../../../../../../../services/itemType";
import { listGeneralStatusApi } from "../../../../../../../services/generalStatus";
import { convertNameInTagName } from "../../../../../../../utils/automatic_response";
import { FieldsBySettingServicesService } from "../../../../../../../services/fieldsBySettingServicesService";
import { ValidationForm } from "./ValidationForm";
import { GeneralForm } from "./GeneralForm";
import { useMutate } from "../../../../../../../utils/useMutate";
import { userServices } from "../../../../../../../services/users";
import { useFetch } from "../../../../../../../utils/useFetch";
import { ruleValidationServices } from "../../../../../../../services/ruleValidationServices";

const ModalField = ({ fields, onFinish, fieldValues, ...props }) => {
  const signin = useSelector((state) => state.signin);
  const company_id = signin.user.currentCompany;
  const [activeTab, setActiveTab] = useState("generalForm");

  const { data: userGroups } = useFetch(userServices.getAllUserGroups, {
    initialParams: {
      limit: 10,
      active: true,
      company_id: company_id,
    },
  });

  const { data: fieldTypes } = useFetch(listItemTypeApi.listItemType, {
    initialParams: {
      limit: 10,
      active: true,
      offset: 0,
    },
    initialState: {},
  });

  const { data: fieldStatus } = useFetch(
    listGeneralStatusApi.listGeneralStatus,
    {
      initialParams: {
        limit: 10,
        active: true,
        offset: 0,
      },
    }
  );

  const { data: validations } = useFetch(ruleValidationServices.getAll, {
    initialParams: {
      limit: 30,
      offset: 0,
    },
    initialState: {
      rows: [],
    },
  });

  const { mutate: updateField, loading: loadingUpdateField } = useMutate(
    FieldsBySettingServicesService.update,
    {
      onSuccess: () => {
        props.cleanFields({});
        setActiveTab("generalForm");
        onFinish();
        notification.success({
          message: "Campo atualizado com sucesso!",
        });
      },
    }
  );

  const { mutate: createField, loading: loadingCreateField } = useMutate(
    FieldsBySettingServicesService.store,
    {
      onSuccess: () => {
        props.cleanFields({});
        setActiveTab("generalForm");
        onFinish();
        notification.success({
          message: "Campo criado com sucesso!",
        });
      },
    }
  );

  const handleSubmit = async (name, { values, forms }) => {
    let dependentFormValues;
    const dependentFormName =
      name === "generalForm" ? "validationForm" : "generalForm";

    const dependentForm = forms[dependentFormName];

    if (
      (!fieldValues.id && name === "generalForm") ||
      (name === "validationForm" && !dependentForm)
    ) {
      setActiveTab(dependentFormName);
      return;
    }

    // Validação de campos dependentes
    if (dependentForm) {
      const fields = Object.keys(dependentForm.getFieldsValue());

      const validationPromise = new Promise((resolve) => {
        dependentForm
          .validateFields(fields)
          .then((values) => {
            resolve(values);
          })
          .catch((error) => {
            resolve(error);
          });
      });

      const { errorFields } = await validationPromise;

      if (errorFields) {
        setActiveTab(dependentFormName);
        return;
      }

      dependentFormValues = dependentForm.getFieldsValue();
    }
    let formValues = {
      ...values,
      ...dependentFormValues,
    };

    // Remove campos undefined ou null
    formValues = _(formValues).omitBy(_.isNil).value();

    // Submição do formulário
    try {
      if (formValues.id) {
        updateField({ id: formValues.id, data: formValues });
      } else {
        const lastFieldSequence = Math.max(
          ...fields.rows.map((field) => field.sequence)
        );

        const payload = {
          ...formValues,
          id_setting_service: props.settingServicesID,
          tag_name: convertNameInTagName(formValues.name),
          sequence: parseInt(lastFieldSequence) + 1,
        };

        createField(payload);
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: `Error ao ${formValues.id ? "atualizar" : "criar"} campo!`,
      });
    }
  };

  return (
    <Modal
      title={fieldValues?.name ?? "Incluir Campo"}
      centered
      open={props.openModal}
      width={600}
      bodyStyle={{
        maxHeight: "85vh",
        overflowY: "auto",
        padding: "0 24px 24px",
      }}
      className="modal-field"
      onCancel={() => {
        props.cleanFields({});
        setActiveTab("generalForm");
        return props.setOpenModal(false);
      }}
      destroyOnClose
      footer={null}
    >
      <Spin
        spinning={loadingCreateField || loadingUpdateField}
        tip="Aguarde..."
      >
        <Form.Provider onFormFinish={handleSubmit}>
          <Tabs
            onChange={setActiveTab}
            activeKey={activeTab}
            defaultActiveKey="generalForm"
            items={[
              {
                key: "generalForm",
                label: "Dados do Campo",
                children: (
                  <GeneralForm
                    fieldMomentStatus={fieldStatus}
                    fieldTypes={fieldTypes}
                    fieldValues={fieldValues}
                    userGroups={userGroups}
                  />
                ),
              },
              {
                key: "validationForm",
                label: "Validações",
                children: (
                  <ValidationForm
                    validations={validations.rows}
                    fieldValues={fieldValues}
                    fields={fields}
                  />
                ),
              },
            ]}
          />
        </Form.Provider>
      </Spin>
    </Modal>
  );
};

export default ModalField;
