import { isObjectEmpty } from "../../utils/text";

const Types = {
  GET_TEMPLATES_DOCUMENTS: "GET_TEMPLATES_DOCUMENTS",
  ADD_TEMPLATE_DOCUMENTS: "ADD_TEMPLATE_DOCUMENTS",
  SET_TEMPLATE_DOCUMENTS: "SET_TEMPLATE_DOCUMENTS",
  UPDATE_TEMPLATE_DOCUMENTS: "UPDATE_TEMPLATE_DOCUMENTS",
  DELETE_TEMPLATE_DOCUMENTS: "DELETE_TEMPLATE_DOCUMENTS",
  SET_READONLY: "SET_READONLY",
  SET_TEMPLATE_DOCUMENT_BY_APPOINTMENT: "SET_TEMPLATE_DOCUMENT_BY_APPOINTMENT"
};

const INITIAL_STATE = {
  list: [],
  count: 0,
  templateDocument: {},
  templateDocumentByAppointment: {},
  readonly: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_TEMPLATES_DOCUMENTS:
      return {
        ...state,
        list: action.payload.rows,
        count: action.payload.count,
      };

    case Types.SET_TEMPLATE_DOCUMENTS:
      return {
        ...state,
        templateDocument: {
          ...action.payload,
        },
      };

    case Types.SET_READONLY:
      return {
        ...state,
        readonly: action.payload,
      };

    case Types.ADD_TEMPLATE_DOCUMENTS:
      return {
        ...state,
        list: action.payload.rows,
        count: action.payload.count,
      };

    case Types.DELETE_TEMPLATE_DOCUMENTS:
      return {
        ...state,
        list: action.payload.rows,
        count: action.payload.count,
      };

    case Types.UPDATE_TEMPLATE_DOCUMENTS:
      return {
        ...state,
        list: action.payload.rows,
        count: action.payload.count,
      };

    case Types.SET_TEMPLATE_DOCUMENT_BY_APPOINTMENT:
      return {
        ...state,
        templateDocumentByAppointment: {
          ...action.payload
        }
      }

    default:
      return state;
  }
}

export function listTemplateDocument(data) {
  return {
    type: Types.GET_TEMPLATES_DOCUMENTS,
    payload: data,
  };
}

export function addTemplateDocument(data) {
  return {
    type: Types.ADD_TEMPLATE_DOCUMENTS,
    payload: data,
  };
}

export function alterTemplatDocument(data) {
  return {
    type: Types.SET_TEMPLATE_DOCUMENTS,
    payload: isObjectEmpty(data) ? INITIAL_STATE.templateDocument : data,
  };
}

export function updateTemplatDocument(data) {
  return {
    type: Types.UPDATE_TEMPLATE_DOCUMENTS,
    payload: data,
  };
}

export function deleteTemplatDocument(data) {
  return {
    type: Types.DELETE_TEMPLATE_DOCUMENTS,
    payload: data,
  };
}

export function setReadonly(data) {
  return {
    type: Types.SET_READONLY,
    payload: data,
  };
}

export function setTemplateDocumentByAppointment(data) {
  return {
    type: Types.SET_TEMPLATE_DOCUMENT_BY_APPOINTMENT,
    payload: data
  }
}