
/**
 * relação entre id do setor e url
 */
export const sectorsIdURLBinding = [

  {
    sector_id: 3,
    url: 'logistics'
  },
  {
    sector_id: 5,
    url: 'flower-shop'
  },
  {
    sector_id: 8,
    url: 'funerals-center'
  },
  {
    sector_id: 9,
    url: 'cemetery'
  },
  {
    sector_id: 3,
    url: 'financial'
  },
]