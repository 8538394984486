import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import Main from "../../../../../../pages/Layout/Main";

import { Button, Col, Layout, Result, Row, Tabs } from "antd";
import { MdKeyboardArrowLeft, MdCreate, MdOpenInNew } from "react-icons/md";

import { appointmentsApiProtheus } from "../../../../../../services/appointmentsProtheus";
import Loading from "../../../../../../components/Loading";

import Resume from "./Resume";
import MenuComponent from "../../../../../../components/MenuComponent";
import itensMenu from "../../../itens-menu";

const { Content } = Layout;
const { TabPane } = Tabs;

function Attendance(props) {
  const attendance = useSelector((state) => state.unifiedQuery.attendance);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [resultDataAttendance, setResultDataAttendance] = useState({});

  const history = useHistory();

  useEffect(() => {
    getAttendanceSelected("929832");
  }, []);

  // busca o atendimento no protheus
  const getAttendanceSelected = async (attendance) => {
    setLoading(true);

    try {
      const result = await appointmentsApiProtheus.getDetailsAttendanceProtheus(
        attendance,
        {
          headers: {
            tenantId: `03,01`,
          },
        }
      );

      if (result.ATENDIMENTO) {
        setResultDataAttendance(result);
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const breadcrumb = [
    {
      title: "Central de atendimento ao cliente",
      path: props.modulePath,
    },
    {
      title: "Consulta unificada",
      path: props.modulePath + "/unified/query",
    },
    {
      title: "Consulta de atendimento",
      path: props.modulePath + "/unified/query/attendance",
    },
  ];

  const buttonsOption = [
    {
      label: "Memorial",
      icon: <MdOpenInNew size={16} />,
    },
    {
      label: "Registrar Ocorrência",
      icon: <MdCreate size={16} />,
    },
  ];

  return (
    <Main
      moduleName={props.moduleName}
      collapsed={true}
      moduleMenu={
        <MenuComponent
          itensMenu={itensMenu}
          modulePath={props.modulePath}
          selectedMenu="3"
        />
      }
      breadcrumb={breadcrumb}
    >
      {loading ? (
        <Loading />
      ) : error ? (
        <Result
          status="warning"
          title="Nenhum atendimento encontrado"
          extra={
            <Button
              type="primary"
              key="console"
              onClick={() => history.goBack()}
            >
              Voltar
            </Button>
          }
        />
      ) : (
        <Content>
          <Row justify="start" gutter={[8, 8]}>
            <Col>
              <Button
                icon={<MdKeyboardArrowLeft size={26} />}
                onClick={() => history.goBack()}
              />
            </Col>
            {buttonsOption.map((value) => (
              <Col>
                <Button type="primary">
                  <Row align="middle">
                    {value.icon}&nbsp; {value.label}
                  </Row>
                </Button>
              </Col>
            ))}
          </Row>

          <Row
            justify="start"
            gutter={[8, 8]}
            className="content-contract-unified-query-margin-top"
          >
            <Col span={24}>
              <Tabs tabPosition="left">
                <TabPane tab="Resumo" key="1">
                  <Resume
                    resultDataAttendance={resultDataAttendance}
                    attendance={attendance}
                  />
                </TabPane>
                <TabPane tab="Falecido" key="2">
                  Content of Tab 2
                </TabPane>
                <TabPane tab="Remoção" key="3">
                  Content of Tab 3
                </TabPane>
                <TabPane tab="Velório" key="4">
                  Content of Tab 4
                </TabPane>
                <TabPane tab="Sepultamento" key="5">
                  Content of Tab 5
                </TabPane>
                <TabPane tab="Cremação" key="6">
                  Content of Tab 6
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Content>
      )}
    </Main>
  );
}

export default Attendance;
