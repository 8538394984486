import {
  useState,
  useEffect,
} from 'react';

import { useSelector } from "react-redux";

import {
  Spin,
  Row,
  Col,
  Input,
  message,
  Typography,
  Button,
  Table,
  Divider,
  Tooltip,
} from 'antd';

import {
  DownloadOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';

import { instanceApi } from '../../../../../utils/apiProtheus';

const View = (props) => {
  const [loading, setLoading] = useState(false);
  const [object, setObject] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const signin = useSelector((state) => state.signin);

  useEffect(() => {
    getObject();
  }, [props.object, signin.user]);

  const getObject = async () => {
    try {
      setLoading(true);

      const result = await instanceApi(signin.user.tenantId)
        .get("/all/RESTExpedicao/api/v1/getCode", {
          params: {
            objeto: props.object,
            detail: true,
          }
        });

      setObject(result.data);

    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    {
      title: "Data",
      dataIndex: "MOVIMENTS",
    },
    {
      title: "Observação",
      dataIndex: "OBS",
    },
    {
      title: "Status",
      dataIndex: "TYPE",
    },
    {
      title: "Ações",
      render: (data) => {

        return (
          <Row gutter={[8, 8]} justify="space-between">
            {
              (data.LATITUDE.trim().length !== 0) &&
              <Col>
                <Tooltip title="Mostrar no google maps">
                  <Button
                    type="text"
                    icon={<EnvironmentOutlined />}
                    href={`https://www.google.com.br/maps/place/${data.LATITUDE},${data.LONGITUDE}`}
                    target="_blank"
                  />
                </Tooltip>
              </Col>
            }
          </Row>
        );
      }
    }
  ];

  function handleTableChange(pagination, filters) {
    setPage(pagination.current);
  }

  return (
    <Spin spinning={loading} tip="Buscando objeto...">
      <Row gutter={[8, 8]}>
        <Col xs={24}>
          <Typography.Text>Código do objeto:</Typography.Text>
          <Input value={object?.codigo} disabled />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} md={8}>
          <Typography.Text>Contrato:</Typography.Text>
          <Input value={object?.contrato} disabled />
        </Col>
        <Col xs={24} md={16}>
          <Typography.Text>Nome:</Typography.Text>
          <Input value={object?.cliente?.cnome} disabled />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24}>
          <Typography.Text>Produto:</Typography.Text>
          <Input value={object?.descproduto} disabled />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24}>
          <Typography.Text>Transportador:</Typography.Text>
          <Input value={`${object?.transportador}: ${object?.nometransportador}`} disabled />
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ marginTop: '10px' }} justify="space-between">
        <Col xs={24}>
          <Button
            download="objeto.jpeg"
            type="primary"
            icon={<DownloadOutlined />}
            href={`data:image/jpeg;base64,${object.imagem}`}
            disabled={object.imagem !== "" ? false : true}
          >
            Dowload do protocolo
          </Button>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ marginTop: '10px' }}>
        <Divider style={{ margin: '5px' }} />
        <Typography.Title level={5}>Movimentações</Typography.Title>
        <Divider style={{ margin: '5px' }} />
      </Row>
      <Row gutter={[8, 8]} style={{ marginTop: '10px' }}>
        <Col xs={24}>
          <Table
            row
            columns={columns}
            dataSource={object?.details}
            rowKey="codigo"
            loading={loading}
            pagination={{
              current: page,
              pageSize: rowsPerPage,
              total: object?.details?.length,
            }}
            onChange={handleTableChange}
            size="small"
          />
        </Col>
      </Row>
    </Spin>
  );
}

export default View;