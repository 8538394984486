import { useEffect, useState, useContext } from "react";

import {
  Modal,
  Spin,
  Form,
  Row,
  Col,
  Upload,
  Button,
  message,
  Tooltip,
  List,
} from "antd";

import {
  InboxOutlined,
  PlusOutlined,
  FilePdfOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { saveAs } from "file-saver";

import "./styles.css";
import Swal from "sweetalert2";

import {
  postDocumentApi,
  getDocumentByBaseService,
} from "../../services/documentBaseService";

import { BaseAttendanceContext } from "../../Context/baseAttendanceContext";

import { awsApi } from "../../services/aws";
import { ServiceDocuments } from "../../services/service_document";

import loadingSVG from "../../assets/images/loading.svg";
import { ServicesService } from "../../services/services";
import moment from "moment";

const { Dragger } = Upload;

const validateMessages = {
  required: "O campo ${label} é obrigatório!",
};

const FormDocument = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [files, setFiles] = useState([]);
  const [modalIncluir, setModalIncluir] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [loadingReleaseAttendace, setLoadingReleaseAttendance] =
    useState(false);

  const [formDocument] = Form.useForm();

  const baseAttendanceContext = useContext(BaseAttendanceContext);

  useEffect(() => {
    getDocumentsBySettingService();
  }, [baseAttendanceContext]);

  const getDocumentsBySettingService = async () => {
    try {
      if (!baseAttendanceContext.baseAttendance) return;

      const resultDocuments = await getDocumentByBaseService(
        baseAttendanceContext.baseAttendance
      );

      setDocuments(resultDocuments);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();

    values.files.forEach((file) => {
      formData.append("documents", file.originFileObj, file.originFileObj.name);
    });

    setLoading(true);
    postDocumentApi(formData, {
      attendance: baseAttendanceContext.baseAttendance,
    })
      .then((res) => {
        setModalIncluir(false);
        baseAttendanceContext.setModalDocument(false);
        setFiles([]);
        formDocument.setFieldsValue({
          files: [],
        });
        message.success(res.message);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  };

  const handleSubmitReleaseAttendance = async () => {
    try {
      if (!baseAttendanceContext.baseAttendance) {
        message.error("Error");
        return;
      }
      setLoadingReleaseAttendance(true);
      await ServicesService.putIsBlockedAttendance(
        {
          is_blocked_attendance: "L",
          unlocked_date: moment(),
          date_register_liberation: moment(),
        },
        baseAttendanceContext.baseAttendance
      );

      return message.success("OSs de remoção liberadas para utilização!");
    } catch (e) {
      return message.error("Oops... Não foi possivel liberar as OSs!");
    } finally {
      setLoadingReleaseAttendance(false);
    }
  };

  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  const config = {
    name: "file",
    multiple: true,
    fileList: files,
    beforeUpload: (file) => {
      setFiles((prev) => [...prev, file.originFileObj]);
      return false;
    },
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleDownloadDocumento = async (document) => {
    try {
      const objectURL = await awsApi.fetchFile(document.path);
      saveAs(objectURL, document.title);
    } catch (error) {
      message.error("Erro ao baixar arquivo");
    }
  };

  /**
   * Função que deleta um documento
   */
  const handleDeleteDocument = async (document_id) => {
    Swal.fire({
      title: "Atenção!",
      text: "Você deseja deletar este documento ?",
      icon: "warning",
      showDenyButton: true,
      focusConfirm: false,
      denyButtonText: "Nâo",
      confirmButtonText: "Sim",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoadingDocuments(true);

        ServiceDocuments.deletedDocuments(document_id)
          .then(() => {
            setLoadingDocuments(false);
            message.success("Documento deletado com sucesso.");
            props.onVisibleDocumentsDeleted();
          })
          .catch(() => {
            setLoadingDocuments(false);
            message.error(
              "Oops... tivemos um problema ao deletar o documento."
            );
          });
      }
    });
  };

  return (
    <Modal
      title={props.title}
      visible={props.visible}
      onCancel={props.onCancel}
      footer={props.footer}
    >
      <Row gutter={[8, 0]} justify="end" align="bottom">
        <Col>
          <Tooltip title="Incluir">
            <Button
              type="primary"
              ghost
              icon={<PlusOutlined />}
              onClick={() => setModalIncluir(true)}
            >
              Incluir
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          {loadingDocuments ? (
            <>
              <div className="loading-centar">
                <img src={loadingSVG} alt="loading" height={80} />
              </div>
              <h2 className="text-loading">Carregando...</h2>
            </>
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={documents}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<FilePdfOutlined />}
                    title={
                      <div className="button-document">
                        <Button
                          type="link"
                          size="small"
                          onClick={() => handleDownloadDocumento(item)}
                        >
                          {item.title}
                        </Button>
                        <Button
                          onClick={() => handleDeleteDocument(item.id)}
                          type="danger"
                          shape="circle"
                          ghost
                          size="small"
                        >
                          <DeleteOutlined />
                        </Button>
                      </div>
                    }
                  />
                </List.Item>
              )}
              pagination={{ pageSize: 5 }}
            />
          )}
        </Col>
      </Row>

      <Modal
        title={"Incluir"}
        visible={modalIncluir}
        onCancel={() => setModalIncluir(false)}
        footer={false}
      >
        <Spin tip="Salvando..." spinning={loading}>
          <Form
            layout="vertical"
            form={formDocument}
            encType="multipart/form-data"
            name="form-users"
            onFinish={handleSubmit}
            validateMessages={validateMessages}
          >
            <Row>
              <Col xs={24}>
                <Form.Item name="files" label="">
                  <Form.Item
                    name="files"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                  >
                    <Dragger {...config}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Clique ou arraste o arquivo para esta área para fazer o
                        upload
                      </p>
                      <p className="ant-upload-hint">
                        Suporte para upload único ou em massa.
                      </p>
                    </Dragger>
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" align="bottom">
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    marginLeft: "8px",
                  }}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </Modal>
  );
};

export default FormDocument;
