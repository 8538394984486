import { putRequest } from "../utils/api";

export const password = {
  resetPassword: async (id, token, password) => {
    await putRequest(`/update-password?id=${id}&token=${token}`, {
          "password": password
    }, "")
  },
  forgotPassword: async (email) => {
    await putRequest(`/forgot-password`, { email })
  }
};