import { getRequest, postRequest } from "../utils/apiProtheus";

export const reservationSQL = {
  getVendedor: (query, headers) =>
    getRequest(
      `/GetDadosVend/${query.empresa}/${query.filial}/${query.grupoUser}/${query.codVend}`,
      headers
    ),

  getPropsta: (query, headers) =>
    getRequest(
      `/GetPropostas/${query.empresa}/${query.filial}/${query.grupoUser}/${query.codVend}`,
      headers
    ),

  getProducts: (query, headers) =>
    getRequest(
      `/GetSQLProd/${query.empresa}/${query.filial}/${query.grupoUser}`,
      headers
    ),

  getReserva: (query, headers) =>
    getRequest(
      `/GetEnderecos/${query.empresa}/${query.filial}/${query.tipoJaz}/${query.user}/${query.gruopUser}/${query.typeUse}`,
      headers
    ),

  cancelReserva: (query, headers) =>
    postRequest(
      `/CancPreRsv/${query.empresa}/${query.filial}/${query.endreco}/${query.gruopUser}`,
      headers
    ),

  confirmReserva: (query, headers) =>
    postRequest(
      `/SetReserva/${query.empresa}/${query.filial}/${query.endreco}/${query.codVend}/${query.contrato}/${query.observation}/${query.nomeOper}/${query.groupUser}`,
      headers
    ),
};
