import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Popconfirm,
  Spin,
  message,
  Switch,
  AutoComplete,
  Divider,
  Tooltip,
} from "antd";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/theme/neat.css";
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/javascript/javascript.js";

import { Controlled as CodeMirror } from "react-codemirror2";

import {
  addTemplateEmail,
  alterTemplateMail,
  deleteTemplateMail,
  setContent,
  updateTemplateMail,
} from "../../../../../../store/ducks/templatesMail";

import { TemplateMailService } from "../../../../../../services/TemplateMailService";

import { closeModal } from "../../../../../../store/ducks/modalGlobal";

const { Option } = Select;

const validateMessages = {
  required: "${label} é obrigatório!",
};

export const FormApp = () => {
  const templateMail = useSelector((state) => state.templatesMail.templateMail);

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [templateMail]);

  const handleSubmit = (values) => {
    setLoading(true);

    values.content = templateMail.content;

    if (!templateMail.id) {
      TemplateMailService.create(values)
        .then((res) => {
          dispatch(addTemplateEmail(res));
          dispatch(alterTemplateMail({}));
          dispatch(closeModal(false));
          message.success("Template de E-mail criado com sucesso!");
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const body = {
        ...templateMail,
        ...values,
      };
      TemplateMailService.update(body)
        .then((res) => {
          dispatch(updateTemplateMail(res));
          dispatch(alterTemplateMail({}));
          dispatch(closeModal(false));
          message.success("Template de E-mail alterado com sucesso!");
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const remove = () => {
    setLoading(true);

    TemplateMailService.delete(templateMail.id)
      .then((res) => {
        dispatch(deleteTemplateMail(res));
        dispatch(alterTemplateMail({}));
        dispatch(closeModal(false));
        message.success("Template de E-mail deletado com sucesso");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = (value) => {
    let res = [];

    if (!value || value.indexOf("@") >= 0) {
      res = [];
    } else {
      res = ["moradadapaz.com.br", "gmail.com", "outlook.com"].map(
        (domain) => `${value}@${domain}`
      );
    }

    setResult(res);
  };

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Form
        layout="vertical"
        form={form}
        name="form-templateMail"
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        initialValues={{
          ["name"]: templateMail.name,
          ["identifier"]: templateMail.identifier,
          ["content"]: templateMail.content,
          ["mail"]: templateMail.mail,
          ["mails_extra"]: templateMail.mails_extra,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xl={12}>
            <Form.Item
              name="name"
              label="Nome"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="identifier"
              label="Identificador"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="content" hidden="true"></Form.Item>

            {templateMail.id && !templateMail.active ? (
              <Form.Item name="active" label="Ativar" valuePropName="checked">
                <Switch />
              </Form.Item>
            ) : null}
          </Col>
          <Col lg={24} xl={12}>
            <Form.Item name="mail" label="E-mail de envio">
              <AutoComplete
                onSearch={handleSearch}
                placeholder="Digite o e-mail"
              >
                {result.map((email) => (
                  <Option key={email} value={email}>
                    {email}
                  </Option>
                ))}
              </AutoComplete>
            </Form.Item>

            <Tooltip title="Digite os e-mails de cópia separados por vírgula.">
              <Form.Item name="mails_extra" label="Cópia para">
                <Input placeholder="Digite os e-mails de cópia separados por vírgula." />
              </Form.Item>
            </Tooltip>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col lg={24} xl={12}>
            <CodeMirror
              className="CodeMirror"
              value={templateMail.content}
              options={{
                theme: "material",
                lineNumbers: true,
                tabSize: 2,
              }}
              onBeforeChange={(editor, data, value) => {
                dispatch(setContent(value));
              }}
            />
          </Col>
          <Col
            lg={24}
            xl={12}
            className="viewHtml"
            dangerouslySetInnerHTML={{ __html: templateMail.content }}
          ></Col>
        </Row>
        <br />
        <Row justify="end" align="bottom">
          <Col>
            {templateMail.active ? (
              <Popconfirm
                title="Deseja inativar o recurso?"
                onConfirm={remove}
                okText="Sim"
                cancelText="Não"
              >
                <Button type="danger">Inativar</Button>
              </Popconfirm>
            ) : null}

            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "8px",
              }}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
