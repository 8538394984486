import { Button, Dropdown, Menu } from "antd";
import {
  DownOutlined,
  AuditOutlined,
  StopOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { useMemo } from "react";
import moment from "moment";

const Actions = ({ data, onOpenModal, onClick }) => {
  const handleClick = (type) => {
    onClick();
    onOpenModal({ type, payload: true });
  };

  const pendingStatus = 10;
  const isExpired = useMemo(() => {
    return data.status === pendingStatus && moment(data.createdAt).add(data.expiration, "hours").isBefore(moment());
  }, [data]);


  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu direction="vertical">
          <Menu.Item
            onClick={() => {
              handleClick("details");
            }}
            icon={<AuditOutlined style={{ fontSize: "16px" }} />}
            key="0"
          >
            Detalhes
          </Menu.Item>
          {data.status === pendingStatus && !isExpired && (
            <>
              <Menu.Item
                onClick={() => {
                  handleClick("cancel");
                }}
                icon={<StopOutlined style={{ fontSize: "16px" }} />}
                key="1"
              >
                Cancelar
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  handleClick("resend");
                }}
                icon={<MailOutlined style={{ fontSize: "16px" }} />}
                key="2"
              >
                {data.email ? "Reeviar email" : "Enviar email"}
              </Menu.Item>
            </>
          )}
        </Menu>
      }
    >
      <Button style={{ padding: 0 }} type="link">
        Ações
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default Actions;
