
/**
 * 
 * @param {string} state - UF
 * @param {string} cityName - Nome da cidade
 * @returns {Promise<string>} - Código do município (IBGE).
 */
export async function getCityCode(state, cityName) {

  if (!state || !cityName) {
    return;
  }

    const cities = await fetch(
        `https://brasilapi.com.br/api/ibge/municipios/v1/${state}?providers=dados-abertos-br,gov,wikipedia`
    );
    const response = await cities.json();

    const city = response.find(
        (city) => city["nome"].toUpperCase() === cityName.toUpperCase()
    );

    if (!city) {
        return;
    }

    const codMun = city["codigo_ibge"];

    if (typeof codMun !== "string") {
        return;
    }

    // Primeiros dois digitos são o codigo do estado
    return codMun.substring(2)
}
