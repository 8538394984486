/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import "./styles.css";
import { Button, Table, Row, Col, message, Tooltip, Popconfirm, Modal, Spin } from "antd";
import { useSelector } from "react-redux";

import { NegotiationContext } from "../../../../Context/negotiation-context";

import { formtDate } from "../../../../utils/formatDateTime";
import Swal from "sweetalert2";
import { checkControl } from "../../../../utils/access_control";
import { useMemo } from "react";
import { useMutate } from "../../../../utils/useMutate";
import { getRecurrenceCreditCardTokenMutation } from "../../../../services/recurrenceService";
import { duplicateBilletApi } from "../../../../services/duplicateBillet";

const columns = [
  {
    title: "Negociação",
    dataIndex: "PREFIXO",
    render: (data) => {
      return data === "FAT" ? "SIM" : "";
    },
  },
  {
    title: "Vencimento Original",
    dataIndex: "VENCORIG",
    render: (data) => {
      return formtDate(data);
    },
  },
  {
    title: "Vencimento Atual",
    dataIndex: "VENCIMENTO",
    render: (data) => {
      return formtDate(data);
    },
  },
  {
    title: "Valor",
    dataIndex: "VALOR",
    render: (data) => {
      return "R$ " + data;
    },
  },
  {
    title: "Valor Multa",
    dataIndex: "VALOR_MULTA",
    render: (data) => {
      return "R$ " + data;
    },
  },
  {
    title: "Valor Juros",
    dataIndex: "VALOR_JUROS",
    render: (data) => {
      return "R$ " + data;
    },
  },
  {
    title: "Valor Atual",
    dataIndex: "VLRTOTAL",
    render: (data) => {
      return "R$ " + data;
    },
  },
];

//Número máximo de tentativas de pagamento (recorrência)
const MAX_PAYMENT_ATTEMPTS = 5

function Installments(props) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [objectsSelected, setObjectsSelected] = useState([]);
  const [quantidadeAtraso, setQuantidadeAtraso] = useState(0);
  const [valorAtraso, setValorAtraso] = useState(0);

  const negotiationContext = useContext(NegotiationContext);
  const signin = useSelector((state) => state.signin);


  const canProceedNext = useMemo(() => {
      if (objectsSelected.length === 0) {

          return {
              canProceed: false,
              message: "Selecione pelo menos uma parcela para prosseguir",
          };
      }

      return {
          canProceed: true,
          message: "",
      }
  }, [objectsSelected]);

  const canProceedCancel = useMemo(() => {
    if (objectsSelected.length === 0) {
        return {
            canProceed: false,
            message: "Selecione pelo menos uma parcela para cancelar",
        };
    }

    if (objectsSelected.length > 1) {
        return {
            canProceed: false,
            message: "Selecione apenas uma parcela para cancelar",
        };
    }

    const isNegotiation = objectsSelected.some(
        (installment) => installment.PREFIXO === "FAT"
    );

    if (!isNegotiation) {
        return {
            canProceed: false,
            message: "Selecione uma parcela em negociação para cancelar",
        };
    }

    return {
        canProceed: true,
        message: "",
    };
  }, [objectsSelected, negotiationContext.data.contract.installments])
  
  
  const filteredInstallments = useMemo(() => {
      // Contrato em recorrencia: retorna apenas as parcelas em atraso com 5 tentativas de pagamento
      if (negotiationContext.data.contract.RECORRENCIA === true) {

          const overdueInstallments =
              negotiationContext.data.contract.installments.filter(
                  (installment) => {
                      const overdueDate = moment(
                          installment.VENCIMENTO,
                          "YYYY-MM-DD HH:mm"
                      ).set({
                          hour: 23,
                          minute: 59,
                          second: 59,
                      });
                      console.log(installment)
                      return (
                          overdueDate.isBefore(moment()) ||
                          installment.PREFIXO == "FAT"
                      );
                  }
              );

          return overdueInstallments;
      }

      return negotiationContext.data.contract.installments;
  }, [
      negotiationContext.data.contract.installments,
      negotiationContext.data.contract.RECORRENCIA,
  ]);

  const { mutate:  getRecurrenceCreditCardToken, loading: loadingRecurrenceCreditCardToken} = useMutate(
    getRecurrenceCreditCardTokenMutation,
    {
      onSuccess: (response) => {
        navigator.clipboard.writeText(`https://servicos.moradadapaz.com.br/recorrencia/atualizacao-cartao/${response.token}`)
        message.success("Link copiado para área de transferência")
      },
      onError: (error) => {
        message.error("Erro ao gerar link de atualização de cartão")
      }
    }
  )

  const { mutate: cancelNegociation, loading: loadingCancelNegociation } =
      useMutate(duplicateBilletApi.cancelNegociation, {
          onSuccess: async () => {
              Modal.success({
                  title: "Negociação cancelada com sucesso",
              });

              try {
                  const installments = await duplicateBilletApi.getBillet(
                      negotiationContext.data.contract.CONTRATO,
                      negotiationContext.data.contract.TIPOCONTRT,
                      {
                          headers: {
                              tenantId: `${negotiationContext.data.contract.EMPRESA},${negotiationContext.data.contract.FILIAL}`,
                          },
                      }
                  );

                  if (!installments) {
                      return message.warning(
                          "Não encontramos nenhum boleto em aberto!"
                      );
                  }

                  negotiationContext.setData({
                      ...negotiationContext.data,
                      contract: {
                          ...negotiationContext.data.contract,
                          installments: installments,
                      },
                  });
              } catch (err) {
                  message.error("Erro ao atualizar parcelas");
              } finally {
                  setRowsSelected([]);
                  setObjectsSelected([]);
              }
          },
          onError: async (error) => {
              const { data } = error.response.data;

              Modal.error({
                  title: "Erro ao cancelar negociação",
                  content: data,
                  centered: true,
              });
          },
      });

  useEffect(() => {
    handleOverdueAmount();
  }, [negotiationContext]);

  const sortData = (data) => {
    return data.sort((a, b) => {
      return (
        new Date(a.VENCORIG).getTime() - new Date(b.VENCORIG).getTime()
      );
    });
  };

  const rowSelection = {
    selectedRowKeys: rowsSelected,
    onChange: (selectedRowKeys, selectedRows) => {
      setRowsSelected(selectedRowKeys);
      setObjectsSelected(selectedRows);


      // console.log(selectedRows);

    },
  };
  async function handleTableChange(pagination) {
    setPage(pagination.current);
  }

  const handleReturn = () =>
    negotiationContext.setCurrent(negotiationContext.current - 1);

  const handleNext = async () => {
    if (objectsSelected.length > 0) {
      let checkTypeFT = objectsSelected.filter((e) => e.TIPO === "FT");

      if (checkTypeFT.length !== 0) {
        Swal.fire({
          title: "Atenção, fatura selecionada",
          text: "Não é possível negociar a partir de uma parcela de negociação, deseja imprimir o boleto desta negociação ?",
          icon: "warning",
          showDenyButton: true,
          focusConfirm: false,
          denyButtonText: "Não",
          confirmButtonText: "Sim, imprimir",
          confirmButtonColor: "#40a9ff",
          denyButtonColor: "rgb(238, 26, 26)",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await negotiationContext.setData({
              ...negotiationContext.data,
              contract: {
                ...negotiationContext.data.contract,
                installmentsSelected: objectsSelected.filter(
                  (value) => value.TIPO === "FT"
                ),
              },
            });

            return negotiationContext.setCurrent(
              negotiationContext.current + 2
            );
          } else {
            setRowsSelected([]);
            setObjectsSelected([]);
          }
        });

        return;
      }
    }

    let index = objectsSelected.findIndex(
      (installment) =>
        moment() >
        moment(installment.VENCIMENTO, "YYYY-MM-DD HH:mm").set({
          hour: 23,
          minute: 59,
          second: 59,
        })
    );


    //menor do que 5 anos   
    let debit_overdue = objectsSelected.filter(
      (boleto) => ((new Date() - new Date(boleto.VENCORIG)) < 157788000000)
    )

    const visible = checkControl(signin.user.profiles, "financial_morada")


    await negotiationContext.setData({
      ...negotiationContext.data,
      contract: {
        ...negotiationContext.data.contract,
        installmentsSelected: objectsSelected,
      },
      // payment_prescribed: debit_overdue.length == 0 || visible.display == "" ? true : false
    });


    if (visible.display == "") {

      if (
        index >= 0
        && (negotiationContext.data.contract.TIPOCONTRT.trim() === "C" || negotiationContext.data.contract.TIPOCONTRT.trim() === "P" || 
        negotiationContext.data.contract.TIPOCONTRT.trim() === "CF" || negotiationContext.data.contract.TIPOCONTRT.trim() === "CP" || 
        negotiationContext.data.contract.TIPOCONTRT.trim() === "FF" )
  ) {
        negotiationContext.setCurrent(negotiationContext.current + 1);
      } else {
        negotiationContext.setCurrent(negotiationContext.current + 2);
      }
    } else {
      message.error("Existem parcelas em aberto prescritas e não prescritas");
    }



  };

  const handleOverdueAmount = () => {
    const dataAtual = moment();

    const data = filteredInstallments
      .filter((value) => {
        var vencimento = moment(value.VENCIMENTO, "YYYY-MM-DD HH:mm").set({
          hour: 23,
          minute: 59,
          second: 59,
        });

        if (dataAtual > vencimento) {
          return value;
        }
      })
      .map((value) => {
        return {
          ...value,
          value: value.VLRTOTAL.trim().replace(",", "."),
        };
      });

    const total = data.reduce(
      (total, value) =>
        total +
        parseFloat(value.VLRTOTAL.trim().replace(".", "").replace(",", ".")),
      0
    );

    setQuantidadeAtraso(data.length);
    setValorAtraso(total.toFixed(2));
  };

  const handlerShowSizeChange = (current, size) => setRowsPerPage(size);

  const handleGenerateLink = async () => {    
    getRecurrenceCreditCardToken({
      recurrence_customer_id: negotiationContext.data.contract.CLIRECORREN,
      customer_name: negotiationContext.data.contract.CLIENTE,
      contract: negotiationContext.data.contract.CONTRATO,
      product_name: negotiationContext.data.contract.DESCRICAO,
      installment_value: negotiationContext.data.contract.installments[0].VLRTOTAL_INTEIRO
      
    })
  }

  const handleCancelNegotiation = async () => {
      if (!canProceedCancel.canProceed) {
          message.error(canProceedCancel.message);
          return;
      }

      const installment = objectsSelected[0];

      cancelNegociation({
          body: {
              contrato: installment.CONTRATO,
              cliente: negotiationContext.data.contract.CODIGOCLIENTE,
              prefixo: installment.PREFIXO,
              numero: installment.NUMERO,
              parcela: installment.PARCELA,
              tipo: installment.TIPO,
          },
          headers: {
              tenantId: negotiationContext.data.contract.EMPRESA + "," + negotiationContext.data.contract.FILIAL,
          },
      });
  };



  return (
      <Spin tip="Carregando... Por favor aguarde." spinning={loadingCancelNegociation}>
          <div className="screen-card-negociation">
              <div className="header-contract-negociation">
                  <Row>
                      <Col span={6}>
                          <strong>Contrato:</strong>
                      </Col>
                      <Col span={18}>
                          {negotiationContext.data.contract.CONTRATO}
                      </Col>
                  </Row>
                  <Row>
                      <Col span={6}>
                          <strong>Titular:</strong>
                      </Col>
                      <Col span={18}>
                          {negotiationContext.data.contract.CLIENTE}
                      </Col>
                  </Row>
                  <Row>
                      <Col
                          style={{
                              minWidth: "160px",
                          }}
                          span={6}
                      >
                          <strong>Forma de pagamento:</strong>
                      </Col>
                      <Col>{negotiationContext.data.contract.FORMA_PAGTO}</Col>
                  </Row>
              </div>
              <div className="header-plots-opened-negociation">
                  <Row>
                      <Col span={12}>
                          <strong>Boletos em atraso:</strong>
                      </Col>
                      <Col span={12}>{quantidadeAtraso}</Col>
                  </Row>
                  <Row>
                      <Col span={12}>
                          <strong>Valor total em atraso:</strong>
                      </Col>
                      <Col span={12}>R$ {valorAtraso}</Col>
                  </Row>
              </div>
          </div>
          <h3 className="title-screen title-screen-table-mobile">
              SELECIONE OS BOLETOS QUE VOCÊ DESEJA EMITIR:
          </h3>
          <div>
              <Table
                  scroll={{ x: 720 }}
                  dataSource={sortData(filteredInstallments)}
                  loading={loading}
                  rowClassName={(record) => {
                      return moment() >
                          moment(record.VENCIMENTO, "YYYY-MM-DD HH:mm").set({
                              hour: 23,
                              minute: 59,
                              second: 59,
                          })
                          ? "table-row-red"
                          : "table-row-dark";
                  }}
                  size="small"
                  rowKey={(record) =>
                      `${record.PREFIXO}_${record.NUMERO}_${record.PARCELA}_${record.TIPO}`
                  }
                  rowSelection={{
                      ...rowSelection,
                      checkStrictly: false,
                      type: "checkbox",
                  }}
                  pagination={{
                      current: page,
                      pageSize: rowsPerPage,
                      total: filteredInstallments.length,
                      showSizeChanger: true,
                      defaultPageSize: 3,
                      onShowSizeChange: handlerShowSizeChange,
                      pageSizeOptions: [3, 10, 50, 100],
                  }}
                  onChange={handleTableChange}
                  columns={columns}
              />
          </div>
          <Row justify="end">
              {negotiationContext.data.contract.RECORRENCIA === true && (
                  <Col>
                      <Button
                          loading={loadingRecurrenceCreditCardToken}
                          onClick={handleGenerateLink}
                      >
                          Copiar link de atualização de cartão
                      </Button>
                  </Col>
              )}
              <Col>
                  <Tooltip title={canProceedCancel.message}>
                      <Popconfirm
                          title="Deseja realmente cancelar a negociação?"
                          onConfirm={handleCancelNegotiation}
                          okText="Sim"
                          cancelText="Não"
                          disabled={!canProceedCancel.canProceed}
                      >
                          <Button disabled={!canProceedCancel.canProceed}>
                              Cancelar negociação
                          </Button>
                      </Popconfirm>
                  </Tooltip>
              </Col>

              <Col>
                  <Button
                      style={{ margin: "0 8px" }}
                      onClick={() => negotiationContext.setCurrent(0)}
                  >
                      Realizar nova consulta
                  </Button>
              </Col>
              <Col>
                  <Button style={{ margin: "0 8px" }} onClick={handleReturn}>
                      Voltar
                  </Button>
              </Col>
              <Col>
              <Tooltip title={canProceedNext.message}>
                  <Button
                      type="primary"
                      style={{ margin: "0 8px" }}
                      onClick={handleNext}
                      disabled={!canProceedNext.canProceed}
                  >
                      Avançar
                  </Button>
              </Tooltip>
              </Col>
          </Row>
      </Spin>
  );
}

export default Installments;
