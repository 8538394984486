import { useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router";

/**
 * Hook para sincronizar state con query params
 * @param {string} query - query param a sincronizar
 * @param {string | number} defaultValue - valor padrão do query param
 * @returns {Array} - [value, setValue]
 * @example
 * const [search, setSearch] = useQueryState("search");
 * setSearch("test");
 * console.log(search); // "test"
 * console.log(window.location.search); // "?search=test"
 */
export function useQueryState(query, defaultValue = null) {
    const location = useLocation();
    const history = useHistory();

    const setQuery = useCallback(
        (value) => {
            // Busca valores já existentes no query params
            const existingQuery = new URLSearchParams(location.search);

            // Agrupa os valores existentes do query params com o valor do estado alterado
            const queryString = new URLSearchParams({
                ...Object.fromEntries(existingQuery),
                [query]: value,
            }).toString();

            // Verifica se o valor do estado alterou
            // eslint-disable-next-line eqeqeq
            const hasChanged = existingQuery.get(query) != value;

            if (!hasChanged) return;

            // Atualiza query param
            history.push(`${location.pathname}?${queryString}`);
        },
        [location, history, query]
    );

    useEffect(() => {
        if (defaultValue === null) return;
        const existingQuery = new URLSearchParams(location.search);

        if (!existingQuery.get(query)) {
            // Atualiza o estado com o valor do query param
            setQuery(defaultValue);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [
        new URLSearchParams(location.search).get(query) ?? defaultValue,
        setQuery,
    ];
}
