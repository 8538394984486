import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import "./styles.css";

import {
  Form,
  Input,
  Button,
  Select,
  message,
  Popconfirm,
  Spin,
  Switch,
  Checkbox,
  notification,
  Tabs,
  Empty,
} from "antd";

import Editor from "../../../../../../components/RichText";
import TableForm from "../../../../../../components/TableForm";
import { SecondaryModalApp } from "../../../../../../components/SecondaryModal";

import { SectorsService } from "../../../../../../services/sectors";
import { TemplateDocuments } from "../../../../../../services/templateDocuments";
import { TagsApi } from "../../../../../../services/tags";

import {
  closeModal,
  openModalSecondary,
} from "../../../../../../store/ducks/modalGlobal";

import {
  addTemplateDocument,
  deleteTemplatDocument,
  alterTemplatDocument,
} from "../../../../../../store/ducks/templateDocuments";

import { checkControl } from "../../../../../../utils/access_control";
import { SettingServicesService } from "../../../../../../services/settingService";

const { Option } = Select;
const { TabPane } = Tabs;

const validateMessages = {
  required: "${label} é obrigatório!",
};

function FormData() {
  const signin = useSelector((state) => state.signin);
  const documents = useSelector((state) => state.templateDocuments);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [sectors, setSectors] = useState([]);
  const [tags, setTags] = useState([]);
  const [settingServicesLists, setSettingServicesLists] = useState(0);
  const [settingServiceSelect, setSettingServiceSelect] = useState(null);
  const [fieldsSettingService, setFieldsSettingService] = useState([]);
  const [checkedIndicator, setCheckedIndicator] = useState(
    documents.templateDocument.signature_client
  );

  const dispatch = useDispatch();

  let html = false;

  const [form] = Form.useForm();

  useEffect(() => {
    getSectors();
    getTags();
  }, []);

  useEffect(() => {
    getTags();
  }, [page]);

  useEffect(() => {
    listSettingServices();
  }, [signin.user.currentCompany]);

  useEffect(() => {
    form.resetFields();
    isChecked();
  }, [documents.templateDocument]);

  const isChecked = () => {
    if (documents.templateDocument.signature_client) {
      setCheckedIndicator(true);
    } else {
      setCheckedIndicator(false);
    }
  };

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const handleSubmit = (values) => {
    setLoading(true);

    if (!values.signature_client) {
      values.signature_client = false;
    }

    if (!html) {
      openNotificationWithIcon(
        "warning",
        "Atenção",
        "Documento é obrigatório!"
      );
      return false;
    }

    if (!documents.templateDocument.id) {
      TemplateDocuments.create({
        ...values,
        html,
      })
        .then((response) => {
          dispatch(addTemplateDocument(response));
          message.success("Documento criado com sucesso!");
        })
        .catch((err) => {
          message.error(err.message);
        });
    } else {
      const body = {
        ...documents.templateDocument,
        ...values,
        signature_client: checkedIndicator,
        html: html,
      };

      TemplateDocuments.update(body)
        .then((response) => {
          dispatch(
            alterTemplatDocument({
              ...response,
              html: html,
            })
          );
          message.success("Documento alterado com sucesso!");
        })
        .catch((err) => {
          message.error(err.message);
        });
    }

    dispatch(alterTemplatDocument({}));
    dispatch(closeModal(false));
    setLoading(false);
  };

  const getTags = () => {
    setLoading(true);

    const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;

    TagsApi.list(rowsPerPage, offset)
      .then((response) => setTags(response))
      .catch((err) => {
        message.error(err.message);
      });

    setLoading(false);
  };

  const getSectors = () => {
    setLoading(true);

    SectorsService.getSectors()
      .then((response) => setSectors(response.rows))
      .catch((err) => {
        message.error(err.message);
      });

    setLoading(false);
  };

  const handleRemoveTemplate = () => {
    setLoading(true);

    TemplateDocuments.delete(documents.templateDocument.id)
      .then((response) => {
        message.success("Documento inativado com sucesso.");
        dispatch(deleteTemplatDocument(response));
      })
      .catch((err) => {
        message.error(err.message);
      });

    dispatch(alterTemplatDocument({}));
    dispatch(closeModal(false));
    setLoading(false);
  };

  const handleHtml = (value) => {
    html = value;
  };

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(4);
  }

  // lista todos os setting services
  const listSettingServices = () => {
    setLoading(true);

    SettingServicesService.getSettingServices(
      50,
      0,
      true,
      signin.user.currentCompany
    )
      .then((response) => setSettingServicesLists(response))
      .catch(() => false)
      .finally(() => setLoading(false));
  };

  // busca os campos do serviço pelo ID
  const getFieldsSettingService = (value) => {
    setLoading(true);
    setSettingServiceSelect(value);

    SettingServicesService.getSettingServiceById(value)
      .then((response) =>
        setFieldsSettingService(response.SettingServiceFields)
      )
      .catch(() => false)
      .finally(() => setLoading(false));
  };

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Form
        layout="vertical"
        form={form}
        name="form-resources"
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        initialValues={{
          ["name"]: documents.templateDocument.name,
          ["id_sector"]: documents.templateDocument.id_sector,
        }}
      >
        <Form.Item
          name="name"
          label="Nome"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Digite um título para o documento" />
        </Form.Item>

        <Form.Item
          name="id_sector"
          label="Setor"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="Selecione um setor" allowClear>
            {sectors && sectors.length !== 0
              ? sectors.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.description}
                  </Option>
                ))
              : null}
          </Select>
        </Form.Item>

        <Form.Item name="signature_client">
          <span style={{ marginRight: 10 }}> Cliente assina ? </span>
          <Checkbox
            onChange={() => setCheckedIndicator(!checkedIndicator)}
            checked={checkedIndicator}
          />
        </Form.Item>

        <Form.Item name="html">
          <Editor handleHtml={handleHtml} html={documents.templateDocument} />
        </Form.Item>

        <div className="buttonsClass">
          {documents.templateDocument.id &&
          !documents.templateDocument.active ? (
            <Form.Item name="active" valuePropName="checked">
              <Switch />
            </Form.Item>
          ) : null}

          <Form.Item>
            {documents.templateDocument.active ? (
              <Popconfirm
                title="Deseja inativar o documento?"
                onConfirm={handleRemoveTemplate}
                okText="Sim"
                cancelText="Não"
              >
                <Button
                  type="danger"
                  style={checkControl(
                    signin.user.profiles,
                    "template_docs_inactivate"
                  )}
                >
                  Inativar
                </Button>
              </Popconfirm>
            ) : null}

            <Button
              style={{
                marginLeft: "8px",
              }}
              onClick={() => dispatch(openModalSecondary(true))}
            >
              Tags
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              style={{
                ...checkControl(
                  signin.user.profiles,
                  "save_and_update_template_docs"
                ),
                marginLeft: "8px",
              }}
            >
              {documents.templateDocument.id ? "Atualizar" : "Salvar"}
            </Button>
          </Form.Item>
        </div>
      </Form>
      <SecondaryModalApp
        title="Tags"
        width={600}
        footer={null}
        bodyStyle={true}
        height={600}
      >
        <div>
          <p>
            Você pode personalizar os documentos emitidos utilizando as tags
            abaixo.
          </p>
          <p>
            Caso o conteúdo da tag utilizada não tenha sido cadastrado no
            sistema, o campo aparecerá vazio na visualização do documento ao
            usuário.
          </p>
        </div>

        <Tabs defaultActiveKey="1" type="card" size="small">
          <TabPane tab="Tags fixas" key="1">
            <TableForm
              data={tags.rows}
              loading={loading}
              page={page}
              rowsPerPage={rowsPerPage}
              countPages={tags.count}
              handleTableChange={handleTableChange}
              columns={[
                {
                  title: "Tag",
                  dataIndex: "tag",
                },
                {
                  title: "Descrição",
                  dataIndex: "description",
                },
              ]}
            ></TableForm>
          </TabPane>
          <TabPane tab="Tags Dinâmicas" key="2">
            <Select
              placeholder="Selecione um serviço"
              allowClear
              style={{ width: 550 }}
              onChange={(value) => getFieldsSettingService(value)}
            >
              {settingServicesLists && settingServicesLists.rows.length !== 0
                ? settingServicesLists.rows.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))
                : null}
            </Select>

            <br />
            <br />

            {settingServiceSelect === null ? (
              <Empty description="Nenhum Serviço selecionado." />
            ) : (
              <TableForm
                data={fieldsSettingService}
                loading={loading}
                page={page}
                rowsPerPage={rowsPerPage}
                countPages={fieldsSettingService.length}
                handleTableChange={handleTableChange}
                columns={[
                  {
                    title: "Nome",
                    dataIndex: "name",
                  },
                  {
                    title: "Tag",
                    dataIndex: "tag_name",
                    render: (data) => `{{ ${data} }}`,
                  },
                ]}
              ></TableForm>
            )}
          </TabPane>
        </Tabs>
      </SecondaryModalApp>
    </Spin>
  );
}

export default FormData;
