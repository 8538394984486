export async function getBinaryContent(url) {
  try {
    // Fetch data from the URL
    const response = await fetch(url);

    // Check if the request was successful (status code 200-299)
    if (!response.ok) {
      throw new Error(`Failed to fetch content. Status: ${response.status}`);
    }

    // Get the binary data as ArrayBuffer
    const content = await response.arrayBuffer();

    return content;
  } catch (error) {
    console.error(`Error fetching content: ${error.message}`);
    throw error;
  }
}