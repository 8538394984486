import utf8 from "utf8";

export function Initials(n) {
  const i = n.split(" ");
  return (i[0] + " " + i[i.length - 1])
    .match(/\b(\w)/g)
    .join("")
    .toUpperCase();
}

export const bin2string = (array) => utf8.decode(String.fromCharCode(...array));

// export function bin2string(array) {
//   var result = "";
//   for (var i = 0; i < array.length; ++i) {
//     result += String.fromCharCode(array[i]);
//   }
//   return result;
// }

export function isObjectEmpty(obj) {
  for (var x in obj) {
    return false;
  }
  return true;
}

export function maskPhone(v) {
  var r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");
  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 5) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else {
    r = r.replace(/^(\d*)/, "($1");
  }
  return r;
}

export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function capitalizeFirstLetter(str) {
  if (!str) return ""
  return str.charAt(0).toUpperCase() + str.slice(1)
}