import React, { useState, useEffect, useContext } from 'react';
import { QrReader } from 'react-qr-reader';
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import "./styles.css";

import {
  Row,
  Col,
  Input,
  Spin,
  Form,
  message
} from "antd";

import {
  LeftOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import QrCodeMarker from '../../../../../../assets/images/qrcode-marker.svg'

import { instanceApi } from '../../../../../../utils/apiProtheus';

const QrCodePage = (props) => {

  const [loading, setLoading] = useState(false);
  const [inputSearchCode, setInputSearchCode] = useState(true);
  const [inputCode, setInputCode] = useState("");

  const signin = useSelector((state) => state.signin);
  const history = useHistory();

  const [form] = Form.useForm();

  const handlerResult = async (result, error) => {
    try {

      if (!!result) {
        const codigo = JSON.parse(result?.text).codigo;

        setLoading(true);

        const response = await instanceApi(signin.user.tenantId)
          .get("/all/RESTEXPEDICAO/api/v1/getCode/", {
            params: {
              objeto: codigo
            }
          });

        if (Object.keys(response.data).length === 0)
          throw "Não foi possível localizar o objeto informado!";

        if (response.data.status.id === "E") {
          message.info("Objeto já entregue, o status dele não pode ser alterado!")
        } else {
          history.push(`/invoicing/objects/read-barcode/form-return-object?object=${codigo}&cliente=${response.data.contrato}: ${response.data.cliente.cnome}`);
        }
      }

    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleReturn = () => {
    history.goBack();
  }

  const handleResultCode = async () => {
    setLoading(true);

    try {
      const response = await instanceApi(signin.user.tenantId)
        .get(`/all/RESTEXPEDICAO/api/v1/getCode/?objeto=${inputCode}`);

      if (Object.keys(response.data).length === 0)
        throw "Não foi possível localizar o objeto informado!";

      if (response.data.status.id === "E") {
        message.info("Objeto já entregue, o status dele não pode ser alterado!")
      } else {
        history.push(`/invoicing/objects/read-barcode/form-return-object?object=${inputCode}&cliente=${response.data.contrato}: ${response.data.cliente.cnome}`);
      }

    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Spin tip="Buscando Objeto...." spinning={loading}>
      <div className="Container">
        <Row justify='space-between' style={{ marginTop: '10px' }}>
          <Col>
            <div
              onClick={handleReturn}
              className='button'
            >
              <LeftOutlined style={{ color: '#fff' }} />
            </div>
          </Col>
          <Col>
            <div
              onClick={() => setInputSearchCode(!inputSearchCode)}
              className='button'
            >
              <EditOutlined style={{ color: '#fff' }} />
            </div>
          </Col>
        </Row>
        <div>
          <QrReader
            constraints={{ facingMode: "environment" }}
            //scanDelay={5000}
            className='QrCode'
            onResult={handlerResult}
          />
        </div>
        <div className='ScanMarker'>
          <img
            src={QrCodeMarker}
            style={{ height: 300 }}
          />
        </div>
        <Row justify='center' hidden={inputSearchCode}>
          <Col xs={18}>
            <Input.Search
              placeholder="Digite o código aqui"
              allowClear
              value={inputCode}
              onChange={(e) => setInputCode((`000000000000000${e.target.value}`).slice(-15))}
              enterButton="Buscar"
              size="large"
              onSearch={handleResultCode}
            />
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default QrCodePage;