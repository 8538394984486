import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Popconfirm,
  Spin,
  message,
  Switch,
} from "antd";

import { listResourcesApi } from "../../../../../../services/resources";
import { SectorsService } from "../../../../../../services/sectors";
import { ModulesService } from "../../../../../../services/modules";

import {
  addModule,
  alterModule,
  deleteModule,
} from "../../../../../../store/ducks/modules";
import { closeModal } from "../../../../../../store/ducks/modalGlobal";

const { Option } = Select;

const validateMessages = {
  required: "${label} é obrigatório!",
};

export const FormApp = () => {
  const [loading, setLoading] = useState(false);
  const [pageResource, setPageResource] = useState(1);
  const [rowsPerPageResource, setRowsPerPageResource] = useState(10);
  const [pageSector, setPageSector] = useState(1);
  const [rowsPerPageSector, setRowsPerPageSector] = useState(10);

  const module = useSelector((state) => state.modules.module);
  const [resourcesList, setResourcesList] = useState([]);
  const [sectorsList, setSectorsList] = useState([]);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [module]);

  useEffect(() => {
    getResources();
  }, [pageResource]);

  useEffect(() => {
    getSectors();
  }, [pageSector]);

  const handleSubmit = (values) => {
    setLoading(true);

    if (!module.id) {
      ModulesService.create(values)
        .then((res) => {
          dispatch(addModule(res));
          dispatch(alterModule({}));
          dispatch(closeModal(false));
          message.success("Módulo criado com sucesso!");
        })
        .catch((error) => {
          message.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const body = {
        ...module,
        ...values,
      };

      ModulesService.update(body)
        .then((res) => {
          dispatch(addModule(res));
          dispatch(alterModule({}));
          dispatch(closeModal(false));
          message.success("Módulo alterado com sucesso!");
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const remove = () => {
    setLoading(true);

    ModulesService.delete(module.id)
      .then((res) => {
        dispatch(deleteModule(res));
        dispatch(alterModule({}));
        dispatch(closeModal(false));
        message.success("Módulo inativado com sucesso!");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSectors = () => {
    setLoading(true);

    const offset = pageSector !== 1 ? rowsPerPageSector * (pageSector - 1) : 0;
    SectorsService.getSectors(rowsPerPageSector, offset, true).then(
      (response) => {
        if (pageSector !== 1) {
          response.rows.map((res) => {
            sectorsList.rows.push(res);
          });
        } else {
          setSectorsList(response);
        }
        setLoading(false);
      }
    );
  };

  const getResources = () => {
    setLoading(true);

    const offset =
      pageResource !== 1 ? rowsPerPageResource * (pageResource - 1) : 0;
    listResourcesApi
      .listResources(rowsPerPageResource, offset, true)
      .then((response) => {
        if (pageResource !== 1) {
          response.rows.map((res) => {
            resourcesList.rows.push(res);
          });
        } else {
          setResourcesList(response);
        }
        setLoading(false);
      });
  };

  const onPopupScrollResources = (e) => {
    e.persist();
    let target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      const newPage = pageResource + 1;
      setPageResource(newPage);
    }
  };

  const onPopupScrollSectors = (e) => {
    e.persist();
    let target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      const newPage = pageSector + 1;
      setPageSector(newPage);
    }
  };

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Form
        layout="vertical"
        form={form}
        name="form-modulo"
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        initialValues={{
          ["name"]: module.name,
          ["icon"]: module.icon,
          ["path"]: module.path,
          ["id_resource"]: module.id_resource,
          ["id_sector"]: module.id_sector,
        }}
      >
        <Row gutter={8}>
          <Col lg={24} xl={12}>
            <Form.Item
              name="name"
              label="Nome"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={24} xl={12}>
            <Form.Item
              name="icon"
              label="Icone"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col lg={24} xl={12}>
            <Form.Item
              name="path"
              label="Path"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={24} xl={12}>
            <Form.Item
              name="id_resource"
              label="Hierarquia"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Selecione uma opção"
                allowClear
                onPopupScroll={onPopupScrollResources}
              >
                {resourcesList.length !== 0
                  ? resourcesList.rows.map((resource) => (
                      <Option key={resource.id} value={resource.id}>
                        {resource.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={24} xl={12}>
            <Form.Item
              name="id_sector"
              label="Setor"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Selecione os setores"
                optionLabelProp="label"
                onPopupScroll={onPopupScrollSectors}
                allowClear
              >
                {sectorsList.length !== 0
                  ? sectorsList.rows.map((sector) => (
                      <Option key={sector.id} value={sector.id}>
                        {sector.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} xl={12}>
            {module.id && !module.active ? (
              <Form.Item name="active" label="Ativar" valuePropName="checked">
                <Switch />
              </Form.Item>
            ) : null}
          </Col>
        </Row>
        <Row justify="end" align="bottom">
          <Col>
            {module.active ? (
              <Popconfirm
                title="Deseja inativar o módulo?"
                onConfirm={remove}
                okText="Sim"
                cancelText="Não"
              >
                <Button type="danger">Inativar</Button>
              </Popconfirm>
            ) : null}

            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "8px",
              }}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
