import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const listCustomerApi = {
  listCustomer: (limit, offset, name, email, cpf) =>
    getRequest(
      `/customers?limit=${limit}&offset=${offset}&name=${name}&email=${email}&cpf=${cpf}`,
      {}
    ),
};
