import "./styles.css";
import { itensMenu } from "./itens-menu";
import { Helmet } from "react-helmet";
import MenuCard from "../../../components/menu_card";

function HomeCommercial(props) {
  props = {
    ...props,
    breadcrumb: [
      {
        title: "Comercial",
        path: props.modulePath,
      },
    ],
    itensMenu: itensMenu,
  };

  return (
    <>
      <Helmet>
        <title>Comercial - webGM</title>
      </Helmet>
      <MenuCard {...props} />
    </>
  );
}

export default HomeCommercial;
