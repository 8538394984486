/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { getConsultantProposalsQuery } from "../../../../../services/protheusService";
import { useFetch } from "../../../../../utils/useFetch";
import { useMutate } from "../../../../../utils/useMutate";
import { MaskedInput } from "antd-mask-input";
import { useMemo } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { getOrCreateRecurrenceJoinLinkMutation } from "../../../../../services/recurrenceService";


export const CreateModalJoinToken = ({ open, setOpen, onCreateSuccess }) => {
  const signin = useSelector((state) => state.signin);
  const [form] = Form.useForm();
  const consultantCPF = Form.useWatch("cpfConsultant", form);
  const consultantCodigo = Form.useWatch("codigoConsultor", form);
  const [searchType, setSearchType] = useState("cpf");


  const subsidiary_erp = "01"
  const company_erp = "05"

  const {
    data: consultantProposals,
    error: errorConsultantProposals,
    refetch: getConsultantProposals,
    setData: setConsultantsProposal,
    loading: loadingConsultantProposals,
  } = useFetch(getConsultantProposalsQuery, {
    autoFetch: false,
    onError: (e) => {
      notification.error({
        message: "Erro ao buscar propostas do consultor",
        description: e.msg,
      });
    },
  });

  const { mutate: createJoinToken, loading } = useMutate(
    getOrCreateRecurrenceJoinLinkMutation,
    {
      onSuccess: (data) => {
        onCreateSuccess(data);
        notification.success({
          message: "Link Criado com sucesso",
        });

        form.resetFields();
        setOpen(false);
      },
      onError: (e) => {
        notification.error({
          message: "Erro ao criar PIX",
          description: e.msg || e.message,
        });
      },
    }
  );

  function handleSubmit(values) {
    const proposal = consultantProposals.propostas.find(
      (proposal) => proposal.PROPOSTA === values.proposal
    );
    const payload = {
      cod_client: "0000000",
      name_client: values.name_client,
      cpf_client: values.cpf_client.replaceAll("-", "").replaceAll(".", ""),
      email_client: values.email_client,
      phone_client: values.phone_client.replaceAll("-", "").replaceAll("(", "").replaceAll(")", ""),
      cod_contrt: proposal["PROPOSTA"],
      name_product: "MORADA ESSENCIAL",
      value_product: 0,
      number_installments: 0,
      date_installments: 0,
      initial_date: moment(),
      company: "05",
      branch: "01",
      business: "PLANO",
      user_id: signin.user.id,
      type: "adesao"
    };

    createJoinToken(payload);
}


const proposalHelpMessage = useMemo(() => {
  if (errorConsultantProposals || !consultantProposals) {
    return "";
  }
  if (loadingConsultantProposals) {
    return "Buscando propostas do consultor";
  }
  if (!consultantProposals?.propostas?.length) {
    return "Consultor não possui propostas";
  }
  return "Selecione a proposta";
}, [
  errorConsultantProposals,
  consultantProposals,
  loadingConsultantProposals,
]);

const consultantHelpMessage = useMemo(() => {
  const consultantName =
    consultantProposals?.vendedor || errorConsultantProposals?.vendedor;
  if (consultantName) {
    return `Consultor: ${consultantProposals?.vendedor}`;
  }
  if (errorConsultantProposals) {
    return errorConsultantProposals.msg || "";
  }
}, [consultantProposals, errorConsultantProposals]);

useEffect(() => {
  if (
    (consultantCPF && consultantCPF.length === 11) ||
    (consultantCodigo && consultantCodigo.length >= 6)
  )
    getConsultantProposals({
      params: {
        valor: consultantCPF || consultantCodigo,
        tipo: searchType,
      },
      headers: {
        tenantId: `${company_erp},${subsidiary_erp}`,
      },
    });
}, [consultantCPF, consultantCodigo]);

return (
  <Modal
    open={open}
    onCancel={() => {
      setConsultantsProposal(null);
      form.resetFields();
      setOpen(false);
    }}
    title="Gerar Link"
    footer={null}
    destroyOnClose={true}
    centered
  >
    <Spin tip="Gerando Link, por favor aguarde." spinning={loading}>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item help={consultantHelpMessage} label="Consultor(a)" required>
          <Input.Group compact>
            <Select
              onChange={(value) => {
                setSearchType(value);
                setConsultantsProposal(null);
              }}
              value={searchType}
              style={{ width: "90px" }}
              defaultValue="cpf"
            >
              <Select.Option value="cpf">CPF</Select.Option>
              <Select.Option value="codigo">Código</Select.Option>
            </Select>
            {searchType === "cpf" ? (
              <Form.Item
                style={{ marginBottom: "0px" }}
                className="form-item-cpf"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório",
                  },
                  {
                    message: "CPF inválido",
                    validateTrigger: "onSubmit",
                    validator: (_, value) => {
                      if (value?.length === 11) {
                        return Promise.resolve();
                      }
                      return Promise.reject("CPF inválido");
                    },
                  },
                ]}
                name="cpfConsultant"
                normalize={(value) => {
                  return value.replace(/\D/g, "");
                }}
              >
                <MaskedInput
                  name="cpfConsultant"
                  mask={"000.000.000-00"}
                  onChange={(e) => {
                    setConsultantsProposal([]);
                    form.resetFields(["proposal"]);
                  }}
                />
              </Form.Item>
            ) : (
              <Form.Item
                style={{ marginBottom: "0px" }}
                className="form-item-codigo"
                name="codigoConsultor"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    setConsultantsProposal([]);
                    form.resetFields(["proposal"]);
                  }}
                />
              </Form.Item>
            )}
          </Input.Group>
        </Form.Item>

        <Form.Item
          help={proposalHelpMessage}
          label="Proposta:"
          rules={[
            {
              required: true,
              message: "Campo obrigatório",
            },
          ]}
          name="proposal"
        >
          <Select
            disabled={consultantProposals?.vendedor ? false : true}
            placeholder={
              loadingConsultantProposals ? "Buscando propostas..." : ""
            }
            loading={loadingConsultantProposals}
            notFoundContent={
              loadingConsultantProposals ? null : (
                <Empty description="Nenhum registro encontrado" />
              )
            }
          >
            {consultantProposals?.propostas?.map((proposal) => (
              <Select.Option
                key={proposal.PROPOSTA}
                value={proposal.PROPOSTA}
              >
                {proposal.PROPOSTA} - {proposal.TIPO_PROPOSTA} -{" "}
                {proposal.NEGOCIO}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Nome do cliente:"
          name="name_client"
          rules={[
            {
              required: true,
              message: "Campo obrigatório",
            },
          ]}>
          <Input />
        </Form.Item>

        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              name="cpf_client"
              label="CPF do Cliente:"
              rules={[
                {
                  required: true,
                  message: "Campo obrigatório",
                },
              ]}
            >
              <MaskedInput
                name="cpf_client"
                mask={"000.000.000-00"}

              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="phone_client"
              label="Telefone do Cliente:"
              rules={[{ required: true, message: "Campo obrigatório" }]}
            >
              <MaskedInput
                name="phone_client"
                mask={"(00) 00000-0000"}

              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="E-mail do Cliente:"
          name="email_client"
          rules={[
            {
              required: true,
              message: "Campo obrigatório",
            },
          ]}>
          <Input type="email" />
        </Form.Item>

        <Row justify="end">
          <Button type="primary" htmlType="submit">
            Gerar Link
          </Button>
        </Row>
      </Form>
    </Spin>
  </Modal>
);
};
