export const Types = {
  ADD: "ADD",
  REMOVE: "REMOVE",
  GET: "GET",
  SET_FORM_COMPONENT: "SET_FORM_COMPONENT",
  SET_FORM_COMPONENT_RESPONSE: "SET_FORM_COMPONENT_RESPONSE",
};

const INITIAL_STATE = {
  formComponent: {},
  formResponse: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_FORM_COMPONENT:
      return {
        ...state,
        formComponent: {
          ...action.payload,
        },
      };

    case Types.SET_FORM_COMPONENT_RESPONSE:
      return {
        ...state,
        formResponse: action.payload,
      };

    default:
      return state;
  }
}

export function alterFormComponent(data) {
  return {
    type: Types.SET_FORM_COMPONENT,
    payload: data,
  };
}

export function responseFormComponent(data) {
  return {
    type: Types.SET_FORM_COMPONENT_RESPONSE,
    payload: data,
  };
}
