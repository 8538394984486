export const itensMenu = [
  {
    key: 0,
    permission: "access_menu_home",
    url: "/",
    title: "Início",
    icon: "HomeOutlined",
  },
  {
    key: 1,
    permission: "access_menu_order_service",
    url: "/order-service",
    title: "Ordens de Serviços",
    icon: "ProjectOutlined",
  },
];
