import { Helmet } from "react-helmet";
import Main from "../../../../Layout/Main";
import MenuComponent from "../../../../../components/MenuComponent";
import { itensMenu } from "../../itens-menu";
import styles from "./chat.module.css";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useFetch } from "../../../../../utils/useFetch";
import {
    contactsQuery,
    messagesQuery,
} from "../../../../../services/huggyBackupService";
import { Button, Col, Empty, Row, Tag, notification } from "antd";
import Loading from "../../../../../components/Loading";
import { useQueryState } from "../../../../../hooks/useQueryState";
import { usePDF } from "react-to-pdf";
import { useState } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { getBinaryContent } from "../../../../../utils/binaryContetnt";

export function HuggyChat(props) {
    const params = useParams();
    const { chatId } = params;
    const [contactId] = useQueryState("contactId");
    const [chatBodyNoScroll, setChatBodyNoScroll] = useState(false);
    const [downloadFileLoading, setDownloadFileLoading] = useState(false);
    const [createPDFLoading, setCreatePDFLoading] = useState(false);

    const { data: messages, loading } = useFetch(messagesQuery, {
        onError: (e) => {
            notification.error({
                message: `Erro ao buscar mensagens: `,
                description: e.MSG,
                duration: 5,
            });
        },
        initialState: [],
        initialParams: {
            chat_id: chatId,
        },
    });

    const { data: contacts } = useFetch(contactsQuery, {
        initialParams: {
            id: contactId,
        },
    });

    const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });

    const contact = contacts?.[0] || {};

    const breadcrumb = [
        {
            title: "Central de Atendimento ao Cliente",
            path: props.modulePath,
        },
        {
            title: "Backup Huggy",
            path: props.modulePath + "/huggy-backup",
        },
        ...(contact
            ? [
                  {
                      title: contact.name,
                      path:
                          props.modulePath +
                          "/huggy-backup?contact=" +
                          contact.id,
                  },
              ]
            : []),
        {
            title: `Chats`,
            path: props.modulePath + `/huggy-backup/chats/${chatId}`,
        },
        {
            title: `${chatId}`,
            path: props.modulePath + `/huggy-backup/chats/${chatId}`,
        },
    ];

    const handleCreatePDF = () => {
        setChatBodyNoScroll(true);
        setCreatePDFLoading(true);

        setTimeout(() => {
            toPDF({
              method: 'open'
            });
        }, 1000);

        setTimeout(() => {
            setChatBodyNoScroll(false);
            setCreatePDFLoading(false);
        }, 1000);
    };

    async function handleDownloadMedias() {
        const mediaTypes = ["document", "audio"];
        const errors = [];
        const zip = new JSZip();
        let count = 0;
        const zipFilename = `Arquivos de midia atendimento ${chatId} - ${moment().format(
            "DD-MM-YYYY HH:mm:ss"
        )}.zip`;

        const filterMedia = (message) => mediaTypes.includes(message.type);

        const createMediaObject = (media) => ({
            url: media.file_url,
            filename: `${media.sender_name} - ${moment(media.created_at).format(
                "DD-MM-YYYY HH:mm:ss"
            )} - ${media.type}.${media.file_url.split(".").pop()}`,
        });

        const medias = messages.filter(filterMedia).map(createMediaObject);

        if (medias.length === 0) {
            notification.warning({
                message: "Nenhum arquivo encontrado",
                description: "Não há arquivos para baixar",
            });
            return;
        }

        const downloadFile = async (media) => {
            try {
                const file = await getBinaryContent(
                    `${process.env.REACT_APP_BACKEND_API}getBinaryContent?url=${media.url}`
                );
                zip.file(media.filename, file, { binary: true });
                count++;

                if (!file)
                    throw new Error(
                        `Erro ao baixar arquivo: ${media.filename}`
                    );

                if (count === medias.length) {
                    zip.generateAsync({ type: "blob" }).then((content) => {
                        saveAs(content, zipFilename);
                    });
                }
            } catch (err) {
                errors.push(media.filename);
            }
        };

        setDownloadFileLoading(true);

        for (const media of medias) {
            await downloadFile(media);
        }

        setDownloadFileLoading(false);


        if (errors.length > 0) {
            notification.warning({
                message: "Alguns arquivos não foram baixados",
                description: `Os seguintes arquivos não foram baixados: ${errors.join(
                    ", "
                )}`,
            });
        }
    }

    return (
        <>
            <Helmet>
                <title>Chat Huggy - webGM</title>
            </Helmet>
            <Main
                moduleName={props.moduleName}
                moduleMenu={
                    <MenuComponent
                        itensMenu={itensMenu}
                        modulePath={props.modulePath}
                        selectedMenu="1"
                    />
                }
                breadcrumb={breadcrumb}
            >
                <div>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <h2>Atendimento</h2>
                        </Col>
                        <Col>
                            <Button
                                style={{ marginLeft: "10px" }}
                                onClick={handleCreatePDF}
                                loading={createPDFLoading}
                            >
                                Gerar PDF
                            </Button>
                            <Button
                                style={{ marginLeft: "10px" }}
                                onClick={handleDownloadMedias}
                                loading={downloadFileLoading}
                            >
                                Baixar arquivos de mídia
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div ref={targetRef} className={styles.gridContainer}>
                    {/* Chat */}
                    <div>
                        {/* Chat header */}
                        <div className={styles.chatHeader}>
                            <h2>{contact.name}</h2>
                            <div>
                                <span>
                                    <strong>ID do atendimento: {chatId}</strong>
                                </span>
                            </div>
                        </div>

                        {/* Chat body */}
                        <div
                            scroll={chatBodyNoScroll ? "none" : ""}
                            className={styles.chatBody}
                        >
                            {loading ? (
                                <Loading />
                            ) : messages.length === 0 ? (
                                <Empty description="Nenhuma mensagem encontrada" />
                            ) : (
                                messages?.map((message) => (
                                    <ChatMessage
                                        key={message.id}
                                        message={message}
                                    />
                                ))
                            )}
                        </div>
                    </div>

                    {/* Contact details */}
                    <div className={styles.userContainer}>
                        <h2>Detalhes do contato</h2>
                        <p>
                            <strong>ID:</strong> {contact.id}
                        </p>
                        {/* <p>Last Seen: {contact.last_seen.toLocaleString()}</p> */}
                        <p>
                            <strong>Status: </strong>
                            {contact.status}
                        </p>
                        <p>
                            <strong>Phone: </strong>
                            {contact.phone}
                        </p>
                        {/* {contact.cpf && <p>
                            
                            CPF: {contact.cpf}</p>} */}
                        <p>
                            <strong>Bloqueado: </strong>
                            {contact.block ? "Sim" : "Não"}
                        </p>
                        <p>
                            <strong>Organização: </strong>
                            {contact.org_block ? "Bloqueado" : ""}
                        </p>
                        <p>
                            <strong>Data de criação: </strong>
                            {moment(contact.created_at).format("DD/MM/YYYY")}
                        </p>
                        <p>
                            <strong>Última atualização: </strong>
                            {moment(contact.updated_at).format("DD/MM/YYYY")}
                        </p>
                    </div>
                </div>
            </Main>
        </>
    );
}
/**
 *
 * @param {object} props
 * @param {ChatMessage} props.message
 */
const ChatMessage = ({ message }) => {
    const direction =
        message.sender_type?.toLocaleLowerCase() === "agent" ? "right" : "left";

    return (
        <div
            style={{
                alignSelf: direction === "right" ? "flex-end" : "flex-start",
            }}
            className={styles.messageContainer}
        >
            <div direction={direction} className={styles.messageContent}>
                <div className={styles.messageHeader}>
                    <span className={styles.messageSender}>
                        {message.sender_name}
                    </span>
                    <span className={styles.messageTime}>
                        {moment(message.created_at).format(
                            "DD/MM/YYYY HH:mm:ss"
                        )}
                    </span>
                </div>
                <div className={styles.messageBody}>
                    {message.type === "text" ? (
                        <span className={styles.messageText}>
                            {message.text}
                        </span>
                    ) : message.type === "image" ? (
                        <img className={styles.messageImage} loading="lazy" src={message.file_url} alt="" />
                    ) : message.type === "document" ? (
                        <a
                            href={message.file_url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Ver documento
                        </a>
                    ) : message.type === "audio" ? (
                        <audio controls src={message.file_url} />
                    ) : (
                        <span>
                            Tipo de mensagem não suportado: {message.type}
                        </span>
                    )}
                </div>
                {message.sender_type?.toLocaleLowerCase() === "agent" && (
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <Tag style={{ borderRadius: "10px" }} color="#108ee9">
                            Agente
                        </Tag>
                    </div>
                )}
            </div>
        </div>
    );
};

/**
 * @typedef {Object} ChatMessage
 * @property {number} id - Primary key for the chat migration, not null.
 * @property {number} chat_id - Foreign key referencing chats_huggy table in commons schema, not null.
 * @property {string} type - String representing the type of chat.
 * @property {?string} text - String representing the text of the chat (nullable).
 * @property {string} sender_name - String representing the sender's name, not null.
 * @property {?string} sender_phone - String representing the sender's phone (nullable).
 * @property {?string} sender_type - String representing the sender's type (nullable).
 * @property {string} receiver_name - String representing the receiver's name, not null.
 * @property {string} receiver_type - String representing the receiver's type, not null.
 * @property {Date} created_at - Date representing creation time, not null.
 * @property {Date} updated_at - Date representing last update time, not null.
 * @property {?string} file_url - String representing the file url (nullable).
 */
