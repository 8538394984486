import { Input, Row, Col } from 'antd';

function FieldVier(props) {
  return (
    <Col span={props.span}>
      <Col>
        {props.field}
      </Col>
      <Col>
        <Input style={{ borderWidth: 3, color: "black" }} value={props.value ?? ""} disabled />
      </Col>
    </Col>
  )
}

function ModalContract(props) {
  return (
      <Col>
        <Col>
          <p>
            Gestão de contratos
          </p>
        </Col>
        <Row justify={"space-between"} style={{ paddingTop: "1%" }}>
          <FieldVier span={3} field={"Contrato"} value={props.data != {} ? props.data.contract_code : ""} />
          <FieldVier span={3} field={"Emissão"} value={props.data != {} ? props.data.contract_date : ""} />
          <FieldVier span={2} field={"Cliente"} value={props.data != {} ? props.data.customer_code : ""} />
          <FieldVier span={7} field={"Nome"} value={props.data != {} ? props.data.customer_name : ""} />
          <FieldVier span={3} field={"Nascimento"} value={props.data != {} ? props.data.customer_birth_date : ""} />
          <FieldVier span={4} field={"CPF"} value={props.data != {} ? props.data.customer_cpf : ""} />
        </Row>
        <Row justify={"space-between"} style={{ paddingTop: "1%" }}>
          <FieldVier span={7} field={"Endereço"} value={props.data != {} ? props.data.contract_address : ""} />
          <FieldVier span={4} field={"Bairro"} value={props.data != {} ? props.data.contract_neighborhood : ""} />
          <FieldVier span={3} field={"CEP"} value={props.data != {} ? props.data.contract_cep : ""} />
          <FieldVier span={5} field={"Município"} value={props.data != {} ? props.data.contract_state : ""} />
          <FieldVier span={2} field={"UF"} value={props.data != {} ? props.data.contract_state : ""} />
        </Row>
        <Row justify={"space-between"} style={{ paddingTop: "1%" }}>
          <FieldVier span={3} field={"Telefone"} value={props.data != {} ? props.data.contract_phone : ""} />
          <FieldVier span={3} field={"Celular"} value={props.data != {} ? props.data.contract_cellphone : ""} />
          <FieldVier span={4} field={"Produto"} value={props.data != {} ? props.data.product_name : ""} />
          <FieldVier span={4} field={"Código do vendedor"} value={props.data != {} ? props.data.seller_code : ""} />
          <FieldVier span={4} field={"Vendedor"} value={props.data != {} ? props.data.saller_name : ""} />
          <FieldVier span={4} field={"Forma de pagamento"} value={props.data != {} ? props.data.payment_method : ""} />
        </Row>
        <Row justify={"space-between"} style={{ paddingTop: "1%" }}>
          <FieldVier span={3} field={"Recebe E-mail"} value={props.data.contract_receive_email ? "Sim" : "Não"} />
          <FieldVier span={5} field={"E-mail"} value={props.data != {} ?  props.data.contract_email : ""} />
          <FieldVier span={3} field={"Recorrência"} value={props.data.recurrence_active ? "Sim" : "Não"} />
          <FieldVier span={3} field={"Fatura Digital"} value={props.data.digital_invoice_active ? "Sim" : "Não"} />
          <FieldVier span={4} field={"Carência"} value={ props.data != {} ? props.data.grace_period : ""} />
          <FieldVier span={3} field={"Óbito"} value={props.data.is_dead ? "Sim" : "Não"} />
        </Row>
      </Col>
  )
}


export default ModalContract;