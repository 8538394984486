import { getFileType } from "../../../../utils/file_type";
import {
  FilePdfOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { awsApi } from "../../../../services/aws";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Divider, Modal, Tooltip } from "antd";
import styles from "./styles.module.css";
import { useIsMobile } from "../../../../utils/isMobile";
import { saveAs } from "file-saver";

export const AttachmentItem = ({ item, onClick, active, setLoading }) => {
  const filteType = getFileType(item.path);
  const [imageUrl, setImageUrl] = useState(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    let isMounted = true;
    if (filteType.mimetype?.match(/^image/)) {
      setLoading(true);
      awsApi
        .fetchFile(item.path)
        .then((url) => {
          if (isMounted) {
            setImageUrl(url);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setImageUrl(item.path);
    }

    // cleanup function to cancel the request
    return () => {
      isMounted = false;
    };
  }, [item.path, filteType.mimetype]);

  useEffect(() => {
    if (active) {
    }
  }, [active]);

  return (
    <Tooltip  placement={isMobile ? "top" : "left"} title={item.title}>
      <button
        onClick={() => onClick(item)}
        style={{
          backgroundColor: active && "#f5f5f5",
          margin: isMobile ? "0px 4px" : "0px 0px 4px 0px",
          padding: ".5rem",
          width: 150,
          height: 100,
          overflow: "hidden",
        }}
        className={styles.attachment_item}
      >
        {filteType.mimetype === "application/pdf" ? (
          <FilePdfOutlined
            style={{
              fontSize: 60,
            }}
          />
        ) : (
          <img
            style={{
              objectFit: "contain",
              overflow: "hidden",
              width: "100%",
            }}
            src={imageUrl}
            alt={item.title}
          />
        )}
      </button>
    </Tooltip>
  );
};

export const AttachmentsModal = ({
  attachments = [],
  visible,
  setVisible,
  initialAttachment,
}) => {
  const [currentAttachment, setCurrentAttachment] = useState(null);
  const [attachmentUrl, setAttachmentUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const isMobile = useIsMobile();
  const [imageZoom, setImageZoom] = useState(false);

  const selectedAttachment = currentAttachment || initialAttachment;

  const filteType = getFileType(selectedAttachment?.path);

  const handleClick = (attachment) => {
    setCurrentAttachment(attachment);
  };

  const handleDowload = () => {
    saveAs(attachmentUrl, selectedAttachment.title);
  };

  useEffect(() => {
    if (selectedAttachment) {
      setLoading(true);
      awsApi.fetchFile(selectedAttachment.path).then((url) => {
        setAttachmentUrl(url);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [selectedAttachment]);

  return (
    <Modal
      open={visible}
      title="Anexos da OS"
      centered
      width="100%"
      bodyStyle={{
        height: "calc(100vh - 108px)",
        top: "0px",
      }}
      footer={null}
      onCancel={() => {
        setCurrentAttachment(null);
        setVisible(false);
      }}
    >
      <div
        className={styles.container}
        style={{
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <div className={styles.view_content}>
          {loading ? (
            <LoadingOutlined style={{ fontSize: 34 }} spin />
          ) : filteType.mimetype?.match(/^image/) ? (
            <img
              alt={selectedAttachment.title}
              className={styles.attachment_img}
              style={{
                height: imageZoom ? "auto" : "100%",
                cursor: imageZoom ? "zoom-out" : "zoom-in",
              }}
              onClick={() => setImageZoom(!imageZoom)}
              src={attachmentUrl}
              loading="lazy"
            />
          ) : (
            <embed
              src={attachmentUrl}
              type="application/pdf"
              width="100%"
              height="100%"
            />
          )}
        </div>
        <Divider
          type={isMobile ? "horizontal" : "vertical"}
          style={{  height: isMobile ? "1px" : "100%" }}
        />
        <div className={styles.aside_container}>
          <div
            style={{
              flexDirection: isMobile ? "row" : "column",
            }}
            className={styles.items_content}
          >
            {attachments?.length > 1 &&
              attachments?.map((item, index) => (
                <AttachmentItem
                  setLoading={setLoading}
                  active={selectedAttachment?.id === item.id}
                  onClick={handleClick}
                  key={index}
                  item={item}
                />
              ))}
          </div>
          <Button
            icon={<DownloadOutlined />}
            size="large"
            onClick={handleDowload}
            type="primary"
            style={{
              borderRadius: "4px",
              width: "100%",
              marginLeft: "24px",
              top: 0,
              right: "1rem",
            }}
          >
            Baixar
          </Button>
        </div>
      </div>
    </Modal>
  );
};
