
/**
 * Função utilitária para remover valores undefined, nulo ou strings vazias ou  de um objeto - recursivamente
 * @param {object} obj Objeto a ser verificado
 * @returns {object} Objeto sem valores undefined
 */
export const removeNullable = (obj) => {
  try {

    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") removeNullable(obj[key]);
      else if (typeof obj[key] !== "boolean" && !obj[key]) delete obj[key];
    });
    return obj;
  } catch (error) {
    console.log(error);

    return obj
  }
}