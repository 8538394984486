import api from "../utils/api";

export const ruleValidationServices = {
  getAll: async ({ limit, offset }) => {
    const result = await api.get(
      `/setting-service/rule-validation?${new URLSearchParams({ limit, offset })}`
    );

    return result.data;
  },
  create: async (data) => {
    const result = await api.post(`/setting-service/rule-validation`, data);
    return result.data
  },
  update: async ({ id, data }) => {
    const result = await api.put(`/setting-service/rule-validation/${id}`, data);
    return result.data
  },

  delete: async (id) => {
    const result = await  api.delete(`/setting-service/rule-validation/${id}`);
    return result.data
  },
};
