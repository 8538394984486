import { getRequest } from "../utils/api";

export const baseServicesService = {
  getBaseServices: async ({
    limit,
    offset,
    company_id,
    initial_date_filter,
    end_date_filter,
  }) => {
    return await getRequest(
      `/base/service?limit=${limit}&offset=${offset}&company_id=${company_id}&initial_date_filter=${initial_date_filter}&end_date_filter=${end_date_filter}`,
      {}
    );
  },

  getBaseServicesSearch: async (param) => {
    return await getRequest(`/base/service/search?param=${param}`, {});
  },

  getAttendances: async ({limit, offset, company_id}) => {
    return await getRequest(
      `/base/service/attendances?limit=${limit}&offset=${offset}&company_id=${company_id}`,
      {}
    );
  },

  getAttendancesFilter: async (limit, offset, company_id, url) => {
    return await getRequest(
      `/base/service/attendances/filter?limit=${limit}&offset=${offset}&company_id=${company_id}${url}`,
      {}
    );
  },
};
