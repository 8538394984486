import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.css";

import {
  Form,
  Input,
  Button,
  Select,
  message,
  Popconfirm,
  Spin,
  Switch,
} from "antd";

import {
  addResources,
  alterResources,
  updateResources,
  deleteResources,
} from "../../../../../../store/ducks/resources";

import { closeModal } from "../../../../../../store/ducks/modalGlobal";

import {
  addResourcesApi,
  updateResourcesApi,
  deleteResourcesApi,
} from "../../../../../../services/resources";

import { listResourcesApi } from "../../../../../../services/resources";

import { checkControl } from "../../../../../../utils/access_control";

const { Option } = Select;

const validateMessages = {
  required: "${label} é obrigatório!",
};

export const FormApp = () => {
  const [loading, setLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [resourcesList, setResourcesList] = useState([]);

  const signin = useSelector((state) => state.signin);
  const resource = useSelector((state) => state.resources.resource);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [resource]);

  useEffect(() => {
    getResources();
  }, [page]);

  const getResources = () => {
    setLoading(true);

    const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
    listResourcesApi
      .listResources(rowsPerPage, offset, true)
      .then((response) => {
        if (page !== 1) {
          response.rows.map((res) => {
            resourcesList.rows.push(res);
          });
        } else {
          setResourcesList(response);
        }
        setLoading(false);
      });
  };

  const handleSubmit = (values) => {
    setLoading(true);

    if (!values.id_resource) {
      values.id_resource = null;
    }

    if (!resource.id) {
      addResourcesApi
        .addResources(values)
        .then((res) => {
          dispatch(addResources(res));
          dispatch(alterResources({}));
          dispatch(closeModal(false));
          setLoading(false);
          message.success("Recurso criado com sucesso!");
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    } else {
      const body = {
        ...resource,
        ...values,
      };
      updateResourcesApi
        .updateResources(body)
        .then((res) => {
          dispatch(updateResources(res));
          dispatch(alterResources({}));
          dispatch(closeModal(false));
          setLoading(false);
          message.success("Recurso alterado com sucesso!");
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message);
        });
    }
  };

  const remove = () => {
    setLoading(true);

    deleteResourcesApi
      .deleteResources(resource.id)
      .then((res) => {
        dispatch(deleteResources(res));
        dispatch(alterResources({}));
        dispatch(closeModal(false));
        setLoading(false);
        message.success("Recurso inativado com sucesso!");
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };

  const onPopupScroll = (e) => {
    e.persist();
    let target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      const newPage = page + 1;
      setpage(newPage);
      setRowsPerPage(10);
    }
  };

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Form
        layout="vertical"
        form={form}
        name="form-resources"
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        initialValues={{
          ["name"]: resource.name,
          ["description"]: resource.description,
          ["class_tag"]: resource.class_tag,
          ["id_resource"]: resource.id_resource,
        }}
      >
        <Form.Item
          name="name"
          label="Nome"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Descrição"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="class_tag"
          label="Identificador"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="id_resource" label="Hierarquia">
          <Select
            placeholder="Selecione uma opção"
            onPopupScroll={onPopupScroll}
          >
            {resourcesList.length !== 0
              ? resourcesList.rows.map((resource) => (
                  <Option key={resource.id} value={resource.id}>
                    {resource.name}
                  </Option>
                ))
              : null}
          </Select>
        </Form.Item>

        <div className="buttonsClass">
          {resource.id && !resource.active ? (
            <Form.Item name="active" valuePropName="checked">
              <Switch checkedChildren="Ativo" unCheckedChildren="Inativo" />
            </Form.Item>
          ) : null}

          <Form.Item>
            {resource.active ? (
              <Popconfirm
                title="Deseja inativar o recurso?"
                onConfirm={remove}
                okText="Sim"
                cancelText="Não"
              >
                <Button
                  type="danger"
                  style={checkControl(
                    signin.user.profiles,
                    "inactivate_resource"
                  )}
                >
                  Inativar
                </Button>
              </Popconfirm>
            ) : null}

            <Button
              type="primary"
              htmlType="submit"
              style={{
                ...checkControl(
                  signin.user.profiles,
                  "save_and_update_resource"
                ),
                marginLeft: "8px",
              }}
            >
              Salvar
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};
