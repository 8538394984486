const Types = {
  SET_CONTRACT_UNIFIED_QUERY: "SET_CONTRACT_UNIFIED_QUERY",
  SET_ATTENDANCE_UNIFIED_QUERY: "SET_ATTENDANCE_UNIFIED_QUERY",
};

const INITIAL_STATE = {
  contract: {},
  attendance: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_CONTRACT_UNIFIED_QUERY:
      return {
        ...state,
        contract: {
          ...action.payload,
        },
      };

    case Types.SET_ATTENDANCE_UNIFIED_QUERY:
      return {
        ...state,
        attendance: {
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export function setContract(data) {
  return {
    type: Types.SET_CONTRACT_UNIFIED_QUERY,
    payload: data,
  };
}

export function setAttendance(data) {
  return {
    type: Types.SET_ATTENDANCE_UNIFIED_QUERY,
    payload: data,
  };
}
