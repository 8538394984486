import React from "react";

import Main from "../../../Layout/Main";
import MenuFuneralService from "../../../Layout/Menu";
import { itensMenu } from "../itens-menu";

import DuplicateBillet from "../../../../components/DuplicateBillet";
import MenuComponent from "../../../../components/MenuComponent";

function Negotiation(props) {
  const breadcrumb = [
    {
      title: "Financeiro",
      path: props.modulePath,
    },
    {
      title: "Negociação",
      path: props.modulePath + "/negotiation",
    },
  ];

  return (
    <Main
      key={props.moduleName}
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          modulePath={props.modulePath}
          selectedMenu="1"
          itensMenu={itensMenu}
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"none"}
    >
      <DuplicateBillet activeRecapcha={false} />
    </Main>
  );
}

export default Negotiation;
