import { getRequest, postRequest, deleteRequest } from "../utils/api";

export const submissionAnswer = {
  list: async (limit, offset) =>
    await getRequest(`/submission/answer?limit=${limit}&offset=${offset}`, {}),

  create: async (data) => await postRequest("/submission/answer", data),

  delete: async (id) => await deleteRequest(`/submission/answer/${id}`, {}),
};
