/* eslint-disable react-hooks/exhaustive-deps */

import { EventSourcePolyfill } from "event-source-polyfill";
import { useEffect, useRef } from "react";

/**
 * Hook para consumir eventos de servidor(Server Sent Event)
 * @param {*} source  URL do servidor
 * @param {*} options
 * @returns {string} Dados recebidos do servidor
 */
export const useSSE = (source, { onMessage = () => {} } = {}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!source) {
      console.error("[SSE] Source not defined");
      return;
    }

    if (!("EventSource" in window)) {
      console.warn("[SSE] EventSource not supported");
      return;
    }

    ref.current = new EventSourcePolyfill(source, {
      heartbeatTimeout: 3 * 60 * 1000, // 2 minutes
    });

    ref.current.addEventListener("message", (event) => {
      try {
        const data = JSON.parse(event.data);

        onMessage(data);
      } catch (error) {
        onMessage(event.data);
        console.error("[SSE] Error parsing data", error);
      }
    });

    ref.current.addEventListener("error", (event) => {
      if (event.target.readyState === EventSource.CLOSED) {
        console.warn("[SSE] Connection was closed.");
      }
      if (event.target.readyState === EventSource.CONNECTING) {
        console.warn("[SSE] Connetion lost, trying to reconnect...");
      }
    });

    return () => {
      ref.current.close();
      ref.current.removeEventListener("message", ref.current);
    };
  }, [source, onMessage]);
};
