/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  Row,
  Col,
  Empty,
  Button,
  message,
  Input,
  DatePicker,
  Drawer,
  Form,
  Tooltip,
  Alert,
  Select,
} from "antd";
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
  FilterOutlined,
  FilterFilled,
  SyncOutlined
} from "@ant-design/icons";
import { VolumeMute, VolumeUp } from 'react-bootstrap-icons'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import "./styles.css";
import Main from "../../pages/Layout/Main";
import ColumnTasks from "../ColumnTasks";
import { TasksOrderService } from "../../services/tasksOrderService";
import { SettingServicesService } from "../../services/settingService";
import { listUserByProfileApi } from "../../services/users";
import { setSettingServiceBySector } from "../../store/ducks/settingServices";
import {
  setAppointment,
  setAppointmentDefaultPhotos,
} from "../../store/ducks/appointments";
import loadingSVG from "../../assets/images/loading.svg";
import { useFetch } from "../../utils/useFetch";
import MenuComponent from "../MenuComponent";
import { useIsMobile } from "../../utils/isMobile";
import { useSSE } from "../../hooks/serverSentEvent";
import { debounce } from "lodash";
import { useCallback } from "react";
import { checkControl } from "../../utils/access_control";

const usersBySectorQuery = async (params) => {
 const resp = await fetch(`${process.env.REACT_APP_BACKEND_API}users-by-sector?${new URLSearchParams(params)}`)

  const data = await resp.json()
  
  return data

}

function Kanban(props) {
  const signin = useSelector((state) => state.signin);
  const [selectedSettingService, setSelectedSettingService] = useState(0);
  const [settingServiceSector, setSettingServiceSector] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [nameSettingService, setNameSettingService] = useState("Todos");
  const [visible, setVisible] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [tasksParams, setTasksParams] = useState({});

  const [allowSound, setAllowSound] = useState(true);
  const { Option } = Select;
  const isMobile = useIsMobile();

  const [firstLoading, setFirstLoading] = useState(true);
  const {
    data: tasksData,
    refetch: refetchTasks,
    loading: tasksLoading,
  } = useFetch(tasksQuery, {
    autoFetch: false,
    onSuccess: () => {
      firstLoading && setFirstLoading(false);
      setLastUpdated(new Date())
    },
  });

  const { data: usersProfile, loading: usersProfileLoading } = useFetch(
    userProfilesQuery,
    {
      onError: () =>
        message.error(
          "Desculpe... Algo deu errado ao carregar os colaboradores."
        ),
    }
  );

  const {data: usersBySector, refetch: refetchUsersBySector} = useFetch(usersBySectorQuery, {
    onError: () =>
      message.error("Erro ao carregar os colaboradores."),
      initialParams: {
        sector: signin.user.currentModule.id_sector,
        company_id: signin.user.currentCompany,
     
      }
  })

  const debouncedTaksUpdate = debounce(
    () => {
      refetchTasks({
        ...tasksParams,
        id_sector: signin.user.currentModule.id_sector,
        // id_setting_service: selectedSettingService,
        company: signin.user.currentCompany,
      }).then(() => {
        if (allowSound) {
          new Audio("/audio/notification.wav").play();
        }
      });
    },
    3000,
    {
      leading: true,
      trailing: true,
      maxWait: 3000,
    }
  );

  const handleMessages = useCallback(
    (event) => {
      debouncedTaksUpdate();
    },
    [
      allowSound,
      tasksParams,
      // selectedSettingService,
      signin.user.currentModule.id_sector,
      signin.user.currentCompany,
    ]
  );

  useSSE(`${process.env.REACT_APP_BACKEND_API}sse?channel=${props.channel}`, {
    onMessage: handleMessages,
  });

  const dispatch = useDispatch();

  const breadcrumb = [
    {
      title: props.moduleName,
      path: props.modulePath,
    },
    {
      title: "Serviços",
      path: props.modulePath + "/order-service",
    },
    {
      title: nameSettingService,      
      path: props.modulePath + "/order-service",
    },
  ];

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onFinish = ({ date, ...rest }) => {
    const [start_date, end_date] = date || [];    
    const aux = {
      ...rest,
      start_date: start_date?.toISOString(),
      end_date: end_date?.toISOString(),
    };


    // remove empty values
    Object.keys(aux).forEach((key) => {
      if (!aux[key]) {
        delete aux[key];
      }
    });   

    setTasksParams(aux);
    setVisible(false);
  };


  function getSettingServiceBySector() {
    SettingServicesService.getSettingServiceFilterParams({
      sector: signin.user.currentModule.id_sector,
      company_id: signin.user.currentCompany,
    })
      .then((res) => {
        setSettingServiceSector(res.rows)
        dispatch(setSettingServiceBySector(res))
      })
      .catch((error) => message.error(error));
  }

  useEffect(() => {
    getSettingServiceBySector();
    dispatch(setAppointmentDefaultPhotos([]));

    const allowSoundLocalStorage = localStorage.getItem("allowSound");
    setAllowSound(allowSoundLocalStorage === "true");
  }, []);
  useEffect(() => {
    getSettingServiceBySector()
    refetchTasks({
      ...tasksParams,
      id_sector: signin.user.currentModule.id_sector,
      // id_setting_service: selectedSettingService,
      company: signin.user.currentCompany,
    });
    refetchUsersBySector({
      sector: signin.user.currentModule.id_sector,
      company_id: signin.user.currentCompany,
    })
  }, [
    // selectedSettingService,
    signin.user.currentModule,
    signin.user.currentCompany,
    tasksParams,
  ]);

  function tasksQuery(params) {
    return TasksOrderService.getTasks(params);
  }

  // Busca os usuários que tem o perfil de "LOGISTICA" para mostrar no select de colaboradores.
  function userProfilesQuery() {
    return listUserByProfileApi.listUserByProfile(
      props.profile,
      signin.user.currentCompany
    );
  }

  // function getSectos() {

  //   SettingServicesService.getSettingServices(
  //     10,
  //     0,
  //     true,
  //     signin.user.currentCompany,
  //     value
  //   )
  // }

  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    if (fullScreen) {
      handle.exit();
    } else {
      handle.enter();
    }
    setFullScreen(!fullScreen);
  };

  const hasParamsFilter = () => Object.values(tasksParams).some((v) => v);

  return (
    <Main
      key={props.moduleName}
      moduleName={props.moduleName}
      collapsed={true}
      moduleMenu={
        <MenuComponent
          itensMenu={props.itensMenu}
          modulePath={props.modulePath}
          selectedMenu="1"
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"flex"}
      buttonHeader={
        <div style={{
          width: "100%",

        }} className="filter">
          {isMobile ? (
            <Row align="middle">
              <Col>
                <Tooltip placement="left" title={"Filtros"}>
                  <Button
                    type="secondary"
                    icon={
                      hasParamsFilter() ? <FilterFilled /> : <FilterOutlined />
                    }
                    onClick={showDrawer}
                  />
                </Tooltip>
              </Col>
            </Row>
          ) : (
            <Row justify="space-between" gutter={[12, 8]} align="middle">
              <Alert
                style={{ minWidth: "174px" }}
                message={
                  tasksLoading
                    ? "Atualizando..."
                    : `Atualizado às ${moment(lastUpdated).format("LT")}`
                }
                type={tasksLoading ? "info" : "success"}
                showIcon
              />
              <Col>
                <Tooltip placement="left" title={allowSound ? "Desativar som" : "Ativar som"}>
                  <Button
                    style={{ padding: 0, display: "flex", alignItems: "center", justifyContent: "center" }}
                    className="header-button"
                    type="secondary"
                    onClick={() => {
                      localStorage.setItem("allowSound", !allowSound)
                      setAllowSound(!allowSound)
                    }}
                  >
                    {allowSound ? <VolumeUp size={24} /> : <VolumeMute size={24} />}
                  </Button>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip placement="left" title={"Atualização manual"}>
                  <Button
                    className="header-button"
                    type="secondary"
                    disabled={tasksLoading}
                    icon={<SyncOutlined spin={tasksLoading}/>}
                    onClick={() => {
                      refetchTasks({
                        ...tasksParams,
                        id_sector: signin.user.currentModule.id_sector,
                        company: signin.user.currentCompany,
                      });
                    }}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip placement="left" title={"Filtros"}>
                  <Button
                    className="header-button"
                    type="secondary"
                    icon={
                      hasParamsFilter() ? <FilterFilled /> : <FilterOutlined />
                    }
                    onClick={showDrawer}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Button
                  className="header-button"
                  type="secondary"
                  icon={
                    !fullScreen ? (
                      <FullscreenOutlined />
                    ) : (
                      <FullscreenExitOutlined />
                    )
                  }
                  onClick={handleFullScreen}
                />
              </Col>
            </Row>
          )}
        </div>
      }
    >
      <FullScreen handle={handle}>
        {firstLoading && (tasksLoading || usersProfileLoading) ? (
          <>
            <div className="loading-centar">
              <img src={loadingSVG} alt="loading" height={80} />
            </div>
            <h2 className="text-loading">Carregando...</h2>
          </>
        ) : (
          <Row gutter={[8, 8]} justify="space-around">
            {tasksData ? (
              tasksData.map((column, index) => {
                return (
                  <Col key={index} xs={24} sm={12} md={8} lg={6}>
                    <ColumnTasks
                      color={column.color}
                      key={column.id}
                      description={column.description}
                      tasks={column.Services}
                      fields={column.StatusFieldServices}
                      columnId={(setting_service) =>
                        dispatch(
                          setAppointment({
                            ...setting_service,
                            status: column.description,
                            usersProfile: usersProfile,
                          })
                        )
                      }
                      withCustomClickServiceOrder={true}
                      modulePath={props.modulePath}
                      clickColumnTasks={() => {
                        //history.push("/" + props.modulePath + "/appointments");
                      }}
                    />
                  </Col>
                );
              })
            ) : (
              <Col xs={24}>
                <Empty style={{ maxWidth: "100px" }} />
              </Col>
            )}
          </Row>
        )}
      </FullScreen>

      <Drawer
        title="Filtrar Ordem de Serviços"
        //30
        width={isMobile ? "90%" : "30%"}
        onClose={onClose}
        visible={visible}
      >
        <Form
          name="complex-form"
          onFinish={onFinish}
          layout="vertical"
          labelCol={{
            span: 16,
          }}
          wrapperCol={{
            span: 25,
          }}
        >
          <Form.Item name="id_user" label="Tarefas">
            <Select
              optionFilterProp="label"
              defaultValue={""} showSearch allowClear={true}
              options={[
                {
                  label: "Filtros rápidos",
                  options: [
                    {
                      label: "Todas",
                      value: "",
                    },
                    {
                      label: "Minhas Tarefas",
                      value: signin.user.id,
                    }
                  ]
                },
                ...(checkControl(signin.user.profiles, "financial_morada").display !== "none" ?
                    [
                      {
                      label: "Por colaborador",
                      options: [
                        ...(usersBySector||[])?.map((user) => {
                          return {
                            label: user.user_name,
                            value: user.user_code,
                            }
                          })
                        ]
                      }
                    ]    
                    : []
                  )
              ]}
          />
          
          </Form.Item>

          <Form.Item name="name_deceased" label="Nome do Falecido(a)">
            <Input />
          </Form.Item>

          <Form.Item name="num_attendance" label="Numero do Atendimento">
            <Input />
          </Form.Item>

          <Form.Item name="id_setting_service" label="Configurador de serviço">
            <Select showSearch allowClear={true}>
              {settingServiceSector.map((settingservices) => {
                return (
                  <Option key={settingservices.id} value={settingservices.id}>
                    {settingservices.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="date" label="Data do Atendimento">
            <DatePicker.RangePicker
              placeholder={["Data inicial", "Data final"]}
              format="DD/MM/YYYY"
              style={{
                width: "100%",
              }}
              getPopupContainer={(trigger) => trigger.parentElement}
            />
          </Form.Item>

          <Form.Item label=" " colon={false}>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </Main>
  );
}

export default Kanban;
