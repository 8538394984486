import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import queryString from "query-string";
import { useSelector } from "react-redux";

import {
  Spin,
  Form,
  Row,
  Col,
  Input,
  Upload,
  Button,
  Radio,
  Switch,
  message,
} from "antd";

import {
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import Main from '../../../../../Layout/Main';
import MenuComponent from '../../../../../../components/MenuComponent';
import { itensMenu } from '../../../itens-menu';

import { instanceApi } from '../../../../../../utils/apiProtheus';

const validateMessages = {
  required: "${label} é obrigatório!",
};

const options = [
  {
    label: 'Mudou-se',
    value: 'Mudou-se'
  },
  {
    label: 'Recusado',
    value: 'Recusado'
  },
  {
    label: 'Desconhecido',
    value: 'Desconhecido'
  },
  {
    label: 'Numero Não Existe',
    value: 'Numero Não Existe'
  },
  {
    label: 'Falecido',
    value: 'Falecido'
  },
  {
    label: 'Endereço Insuficiente',
    value: 'Endereço Insuficiente'
  },
  {
    label: 'Outros',
    value: 'Outros'
  }
];

const FormReturnObject = (props) => {
  const [loading, setLoading] = useState(false);
  const [delivered, setDelivered] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [coordinates, setCoordinates] = useState({});

  const params = queryString.parse(useLocation().search);

  const history = useHistory();
  const [form] = Form.useForm();
  const signin = useSelector((state) => state.signin);

  const breadcrumb = [
    {
      title: "Faturamento",
      path: props.modulePath
    },
    {
      title: "Objetos",
      path: props.modulePath + "/objects"
    }
  ];

  useEffect(() => {
    setCurrentPosition();
  }, []);

  const handleSubmit = async (values) => {

    setLoading(true);
    try {

      setCurrentPosition()

      if (values.recebimento === true && !values.photo){
        throw "É necessário o upload do canhoto do objeto!";        
      }

      if (values.recebimento === false && !values.motivo) {
        throw "É necessário informar o motivo da devolução do objeto!";
      }

      var imgBase64 = "";
      if (imageUrl !== "") {
        imgBase64 = await blobToBase64(imageUrl);
      }

      const coords = coordinates?.latitude ? {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
      } : {}

      const data = {
        coordinates: coords,
        ...values,
        newStatus: values.recebimento ? "E" : "D",
        photo: imgBase64,
        motivo: values.motivo ? values.motivo : "",
      }

      if (data.newStatus === "D") {
        data.newStatus = values.motivo === "Recusado" ? "R" : "D"
      }

      const response = await instanceApi(signin.user.tenantId)
        .put(`/all/RESTEXPEDICAO/api/v1/putObject?objeto=${values.code}`, data);

      message.success("Informação enviada com sucesso!");

      history.push(`/invoicing/objects/carrier`);

    } catch (error) {
      let messageError = "";
      if (typeof error === "string") {
        messageError = error;        
      }
      else if (error.response.data["Message"]) {
        messageError = error.response.data["Message"];
      }
      else {
        messageError = "Erro ao enviar o retorno do objeto!";
      }
      message.error(messageError);      
      setLoading(false);
    }

  }

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const optionsPosition = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };

  function successPosition(pos) {
    setCoordinates(pos.coords);
  }

  function errorPosition(err) {
    setCoordinates({});
  }

  const setCurrentPosition = () => {
    if (!navigator.geolocation) {
      message.warning('Serviço de localização não disponivel para esse browser.');
      return {};
    } else {
      navigator.geolocation.getCurrentPosition(successPosition, errorPosition, optionsPosition)
    }
  }

  return (
    <Main
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent itensMenu={itensMenu} modulePath={props.modulePath} selectedMenu="1" />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"flex"}
    >
      <Spin tip="Loading..." spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          name="form-companies"
          onFinish={handleSubmit}
          validateMessages={validateMessages}
          initialValues={
            {
              code: params.object,
              cliente: params.cliente,
              recebimento: true,
            }
          }
        >
          <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
            <Col xs={24} md={8}>
              <Form.Item
                name="code"
                label="Código do objeto:"
              >
                <Input allowClear disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={16}>
              <Form.Item
                name="cliente"
                label="Dados do cliente:"
              >
                <Input allowClear disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
            <Col xs={24} md={24}>
              <Form.Item
                name="recebimento"
                label="Recebimento:"
                rules={
                  [
                    {
                      required: true,
                    }
                  ]
                }
              >
                <Switch
                  checkedChildren="Entregue"
                  unCheckedChildren="Devolvido"
                  defaultChecked
                  onChange={(value) => setDelivered(value)}
                />
              </Form.Item>
            </Col>
          </Row>
          {
            delivered ?
              <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
                <Col xs={24} md={24}>
                  <Form.Item
                    name="photo"
                    label="Foto do Canhoto:"
                  >
                    <Upload
                      name="photo"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={(file) => {
                        setImageFile(URL.createObjectURL(file));
                        setImageUrl(file);
                        return false;
                      }}
                    >
                      {imageUrl ? (
                        <img
                          src={imageFile}
                          alt="avatar"
                          style={{
                            height: '100%',
                            width: '100%',
                          }}
                        />
                      ) : (
                        <div>
                          {loading ? <LoadingOutlined /> : <PlusOutlined />}
                          <div
                            style={{
                              marginTop: 8,
                            }}
                          >
                            Upload
                          </div>
                        </div>
                      )}
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              :
              <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
                <Col xs={24} md={24}>
                  <Form.Item
                    name="motivo"
                    label="Informe o Motivo:"
                  >
                    <Radio.Group
                      options={options}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>
                </Col>
              </Row>
          }
          <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
            <Col xs={24} md={24}>
              <Form.Item
                name="observacao"
                label="Observação"
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: "20px" }}>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  Confirmar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>

    </Main>
  );
}

export default FormReturnObject;