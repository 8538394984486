import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import Main from "../../../Layout/Main";
import MainCrud from "../../../../components/MainCrud";
import Form from "./components/Form";

import { ModalApp } from "../../../../components/Modal";

import { checkControl } from "../../../../utils/access_control";

import { TemplateDocuments } from "../../../../services/templateDocuments";
import { SectorsService } from "../../../../services/sectors";

import {
  listTemplateDocument,
  alterTemplatDocument,
} from "../../../../store/ducks/templateDocuments";
import { openModal } from "../../../../store/ducks/modalGlobal";

import { message, Switch, Button, Tag, Input, Select, Empty } from "antd";

import {
  PlusOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import MenuComponent from "../../../../components/MenuComponent";
import { itensDrawer } from "../itens-menu";

const { Option } = Select;

function TemplateDocument(props) {
  const signin = useSelector((state) => state.signin);
  const documents = useSelector((state) => state.templateDocuments);

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [valueInputSearch, setValueInputSearch] = useState("");
  const [sectores, setSectores] = useState([]);

  useEffect(() => {
    if (!checked) {
      list(true);
    } else {
      list(false);
    }
  }, [page, rowsPerPage, documents.templateDocument]);

  useEffect(() => {
    setPage(1);
  }, [checked]);

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Modelos de Documentos",
      path: props.modulePath + "/template/documents",
    },
  ];

  async function list(active) {
    try {
      setLoading(true);

      const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
      TemplateDocuments.listTemplates(rowsPerPage, offset, active).then(
        (response) => {
          dispatch(listTemplateDocument(response));
          listSectores();
        }
      );
    } catch (error) {
      message.error("Erro ao carregar dados!");
    } finally {
      setLoading(false);
    }
  }

  async function listSectores() {
    SectorsService.getSectors(10, 0, true)
      .then((response) => setSectores(response))
      .catch(() => message.error("Erro ao carregar os setores!"));
  }

  // filtrar por título
  const getColumnSearchApi = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
      <div className="divSearch">
        <Input
          style={{ marginBottom: 8 }}
          placeholder="Pesquisar..."
          value={valueInputSearch}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setValueInputSearch(e.target.value ? [e.target.value] : "");
          }}
        />

        <div>
          <Button
            type="primary"
            onClick={() => handleSearchApi(selectedKeys)}
            icon={<SearchOutlined />}
            className="buttonSearch"
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              setValueInputSearch("");
              list(true);
            }}
            icon={<DeleteOutlined />}
            className="buttonSearch"
          >
            Remover Filtro
          </Button>
        </div>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  // filtra por setor
  const getColumnSectorSearchApi = () => ({
    filterDropdown: () => (
      <div className="divSearch">
        <Select style={{ width: 120 }} onChange={handleChange}>
          <Option value="">Sem filtro</Option>
          {sectores && sectores.rows && sectores.rows.length !== 0
            ? sectores.rows.map((sector) => (
                <Option value={sector.id}>{sector.name}</Option>
              ))
            : null}
        </Select>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  function handleChange(value) {
    typeof value === "string" ? list(true) : handleSearchApi(value);
  }

  const handleSearchApi = (value) => {
    setLoading(true);

    if (typeof value[0] === "string") value = `&name=${value}`;

    if (typeof value === "number") value = `&id_sector=${value}`;

    TemplateDocuments.listTemplates(10, 0, true, value)
      .then((response) => {
        dispatch(listTemplateDocument(response));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error("Desculpe não foi possível realizar o filtro.");
      });
  };

  function activeSwitch(checked) {
    if (checked) {
      list(false);
      setChecked(true);
      message.info("Exibindo modelos de documentos inativos!");
    } else {
      list(true);
      setChecked(false);
      message.info("Exibindo modelos de documentos ativos!");
    }
  }

  const handleNewDocument = (data) => {
    if (data.id) {
      dispatch(
        alterTemplatDocument({
          ...data,
        })
      );
    }
    dispatch(openModal(true));
  };

  let columns = [
    {
      title: "Documento",
      dataIndex: "name",
      ...getColumnSearchApi(),
    },
    {
      title: "Setor",
      dataIndex: ["Sector", "description"],
      ...getColumnSectorSearchApi(),
    },
    {
      title: "Atualizado",
      dataIndex: "updatedAt",
      render: (data) => {
        const date = new Date(data);

        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();

        const formatted = `${day}/${month}/${year}`;

        return formatted;
      },
    },
    {
      title: "Situação",
      dataIndex: "active",
      render: (active) => {
        let color = "";
        if (active === true) {
          color = "green";
        } else {
          color = "volcano";
        }
        return <Tag color={color}>{active ? "Ativo" : "Inativo"}</Tag>;
      },
    },
  ];

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(10);
  }

  const cleanStoreComponent = (value) => {
    dispatch(alterTemplatDocument(value));
  };

  return (
    <>
      <Helmet>
        <title>Modelos de Documentos - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="7"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <>
            <span
              style={checkControl(
                signin.user.profiles,
                "check_template_docs_inactivate"
              )}
            >
              <Switch
                onChange={activeSwitch}
                style={{ marginRight: "5px", marginTop: "5px" }}
                checkedChildren="Inativos"
                unCheckedChildren="Ativos"
              />
            </span>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={handleNewDocument}
              style={{
                ...checkControl(signin.user.profiles, "insert_template_docs"),
                marginLeft: "20px",
              }}
            >
              Novo
            </Button>
          </>
        }
      >
        {documents.count === 0 ? (
          <Empty description="Nenhum contrato cadastro" />
        ) : (
          <MainCrud
            columnsTable={columns}
            dataSource={documents.list}
            handleOpenModal={handleNewDocument}
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            countPages={documents.count}
            handleTableChange={handleTableChange}
            activeSwitch={null}
            displayMainCrud="none"
            classTag={[]}
          />
        )}

        <ModalApp
          title={documents.templateDocument.name || "Novo Documento"}
          width={1100}
          footer={null}
          cleanStoreComponent={cleanStoreComponent}
          messageCloese={true}
        >
          <Form />
        </ModalApp>
      </Main>
    </>
  );
}

export default TemplateDocument;
