export const Types = {
  OPEN: "OPEN",
  CLOSE: "CLOSE",
  OPEN_SECONDARY: "OPEN_SECONDARY",
  CLOSE_SECONDARY: "CLOSE_SECONDARY",
  OPEN_PROTHEUS_DATA: "OPEN_PROTHEUS_DATA",
  MODAL_NEGOTIATION: "MODAL_NEGOTIATION",
  MODAL_REPORTS: "MODAL_REPORTS",
};

const INITIAL_STATE = {
  open_modal: false,
  open_modal_secondary: false,
  open_modal_protheus_data: false,
  modal_negotiation_data: false,
  modal_reports: false
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.OPEN:
      return {
        ...state,
        open_modal: action.payload,
      };

    case Types.CLOSE:
      return {
        ...state,
        open_modal: action.payload,
      };

    case Types.OPEN_SECONDARY:
      return {
        ...state,
        open_modal_secondary: action.payload,
      };

    case Types.CLOSE_SECONDARY:
      return {
        ...state,
        open_modal_secondary: action.payload,
      };

    case Types.OPEN_PROTHEUS_DATA:
      return {
        ...state,
        open_modal_protheus_data: action.payload,
      };

    case Types.MODAL_NEGOTIATION:
      return {
        ...state,
        modal_negotiation_data: action.payload,
      };

      case Types.MODAL_REPORTS:
        return {
          ...state,
          modal_reports: action.payload,
        };

    default:
      return state;
  }
}

export function openModal(data) {
  return {
    type: Types.OPEN,
    payload: data,
  };
}

export function closeModal(data) {
  return {
    type: Types.CLOSE,
    payload: data,
  };
}

export function openModalSecondary(data) {
  return {
    type: Types.OPEN_SECONDARY,
    payload: data,
  };
}

export function closeModalSecondary(data) {
  return {
    type: Types.CLOSE_SECONDARY,
    payload: data,
  };
}

export function modalProtheus(data) {
  return {
    type: Types.OPEN_PROTHEUS_DATA,
    payload: data,
  };
}

export function modalNegotiation(data) {
  return {
    type: Types.MODAL_NEGOTIATION,
    payload: data,
  };
}

export function modalReports(data) {
  return {
    type: Types.MODAL_REPORTS,
    payload: data,
  };
}
