import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { message, Tag, Switch, Button, Input, notification } from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  DeleteOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";

import { useSelector, useDispatch } from "react-redux";
import "./styles.css";

import { ProductsService } from "../../../../services/products";
import { getProductsProtheus } from "../../../../services/protheusService";

import { getProducts, alterProduct } from "../../../../store/ducks/products";
import { openModal } from "../../../../store/ducks/modalGlobal";

import Main from "../../../Layout/Main";
import MainCrud from "../../../../components/MainCrud";
import { ModalApp } from "../../../../components/Modal";
import FormApp from "./components/Form";
import { checkControl } from "../../../../utils/access_control";

import loadingSVG from "../../../../assets/images/loading.svg";
import MenuComponent from "../../../../components/MenuComponent";
import { itensDrawer } from "../itens-menu";

function Products(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [valueInputSearch, setValueInputSearch] = useState("");
  const [messageLoading, setMessageLoading] = useState(
    "Por favor aguarde, buscando os produtos..."
  );

  const signin = useSelector((state) => state.signin);
  const products = useSelector((state) => state.products);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!checked) {
      list(true);
    } else {
      list(false);
    }
  }, [page, rowsPerPage, products.product, signin.user.currentCompany]);

  useEffect(() => {
    setPage(1);
  }, [checked]);

  async function list(active) {
    try {
      setLoading(true);

      const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
      ProductsService.getProducts(
        rowsPerPage,
        offset,
        active,
        signin.user.currentCompany
      ).then((response) => {
        dispatch(getProducts(response));
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      message.error("Erro ao carregar dados!");
    }
  }

  const getColumnSearchApi = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
      <div className="divSearch">
        <Input
          style={{ marginBottom: 8 }}
          placeholder="Pesquisar..."
          value={valueInputSearch}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setValueInputSearch(e.target.value ? [e.target.value] : "");
          }}
        />

        <div>
          <Button
            type="primary"
            onClick={() => handleSearchApi(selectedKeys)}
            icon={<SearchOutlined />}
            className="buttonSearch"
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              setValueInputSearch("");
              list(true);
            }}
            icon={<DeleteOutlined />}
            className="buttonSearch"
          >
            Remover Filtro
          </Button>
        </div>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const handleSearchApi = (value) => {
    setLoading(true);

    const filter = /\d/.test(value)
      ? `&code=${value}`
      : `&description=${value}`;

    ProductsService.getProducts(10, 0, true, signin.user.currentCompany, filter)
      .then((response) => dispatch(getProducts(response)))
      .catch(() =>
        message.error("Desculpe não foi possível realizar o filtro.")
      )
      .finally(() => setLoading(false));
  };

  const activeSwitch = (checked) => {
    if (checked) {
      list(false);
      setChecked(true);
      message.info("Exibindo produtos inativos!");
    } else {
      list(true);
      setChecked(false);
      message.info("Exibindo produtos ativos!");
    }
  };

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(10);
  }

  const handleOpenModal = (data) => {
    dispatch(openModal(true));
    dispatch(
      alterProduct({
        ...data,
      })
    );
  };

  const handleGetProductsProtheus = () => {
    const { code_erp: subsidiary_erp, Hierarquia } = signin.user.userCompanies.find(
      (company) => company.id === signin.user.currentCompany
    );

    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() =>
          getProductsProtheusData(Hierarquia.code_erp, subsidiary_erp)
        }
      >
        Confirmar
      </Button>
    );
    notification["info"]({
      message: "Atenção!",
      description:
        "Essa ação irá buscar todos os produtos do protheus referentes a empresa logada na web, deseja continuar ?",
      btn,
    });
  };

  /**
   * Identifica a empresa logada e busca os dados dos prodtuos para essa empresa
   */
  const getProductsProtheusData = async (cod_emp, cod_fil) => {
    setMessageLoading("Por favor aguarde, buscando os produtos...");
    setLoading(true);

    getProductsProtheus
      .listProducts({
        headers: {
          tenantId: `${cod_emp},${cod_fil}`,
        },
      })
      .then((response) => {
        setMessageLoading("Por favor aguarde, cadastrando os produtos...");
        return Promise.all(
          response.map((product) => {
            ProductsService.create({
              code: product.codigo,
              description: product.descricao,
              company_id: signin.user.currentCompany,
            });
          })
        ).then(() => {
          setLoading(false);
          notification["success"]({
            message: "Sucesso!",
            description: "Produtos cadastrados.",
          });
        });
      })
      .catch(() => {
        setLoading(false);
        notification["error"]({
          message: "Ops!",
          description:
            "Desculpe estamos com problemas em nossos servidores, e não conseguimos buscar os produtos.",
        });
      });
  };

  let columns = [
    {
      title: "Código",
      dataIndex: "code",
      ...getColumnSearchApi(),
    },
    {
      title: "Descrição",
      dataIndex: "description",
      ...getColumnSearchApi(),
    },
    {
      title: "Situação",
      dataIndex: "active",
      render: (active) => {
        let color = "";
        if (active === true) {
          color = "green";
        } else {
          color = "volcano";
        }
        return <Tag color={color}>{active ? "Ativo" : "Inativo"}</Tag>;
      },
    },
  ];

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Produtos",
      path: props.modulePath + "/products",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Produtos - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="12"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <>
            <span
              style={checkControl(signin.user.profiles, "products_inactivate")}
            >
              <Switch
                checkedChildren="Inativos"
                unCheckedChildren="Ativos"
                onChange={activeSwitch}
                style={{ marginRight: "5px", marginTop: "5px" }}
              />
            </span>
            <Button
              type="primary"
              size="middle"
              icon={<CloudDownloadOutlined />}
              onClick={handleGetProductsProtheus}
              style={{
                ...checkControl(signin.user.profiles, "save_and_update_products"),
                marginLeft: "20px",
              }}
            >
              Carregar produtos
            </Button>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={handleOpenModal}
              style={{
                ...checkControl(signin.user.profiles, "save_and_update_products"),
                marginLeft: "20px",
              }}
            >
              Novo
            </Button>
          </>
        }
      >
        {loading ? (
          <>
            <div className="loading-centar">
              <img src={loadingSVG} alt="loading" height={80} />
            </div>
            <h2 className="text-loading">{messageLoading}</h2>
          </>
        ) : (
          <MainCrud
            handleOpenModal={handleOpenModal}
            columnsTable={columns}
            dataSource={products.productsList}
            page={page}
            rowsPerPage={rowsPerPage}
            countPages={products.productsListCount}
            handleTableChange={handleTableChange}
            profiles={signin.user.profiles}
            displayMainCrud="none"
            classTag={[]}
          />
        )}
        <ModalApp title="Produtos" width={1000} footer={null}>
          <FormApp />
        </ModalApp>
      </Main>
    </>
  );
}

export default Products;
