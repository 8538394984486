export const itensMenu = [
  {
    key: 0,
    permission: "access_menu_home",
    url: "/",
    title: "Início",
    icon: "HomeOutlined",
  },
  {
    key: 1,
    permission: "access_menu_base_services",
    url: "/base_services",
    title: "Atendimento Funerário",
    icon: "ProjectOutlined",
  },
  {
    key: 2,
    permission: "access_menu_removal-anticipation-form",
    url: "/removal-anticipation-form",
    title: "FAR",
    description: "Formulário de Antecipação de Remoção",
    icon: "FormOutlined",
  },
];
