import { useEffect, useReducer } from "react";

import { Helmet } from "react-helmet";
import { Button, Input, Space, Spin, Table, notification } from "antd";

import Main from "../../../Layout/Main";
import MenuComponent from "../../../../components/MenuComponent";
import { itensMenu } from "../itens-menu";
import { useQueryState } from "../../../../hooks/useQueryState";
import { ContactView } from "./components/CantactDetails";
import { contactsQuery } from "../../../../services/huggyBackupService";
import { useFetch } from "../../../../utils/useFetch";
import { SearchOutlined } from "@ant-design/icons";
import { useRef } from "react";
import { useDebounce } from "../../../../utils/useDebounce";
import _ from "lodash";

export default function HuggyBackup(props) {
    const [contactId, setContactId] = useQueryState("contact");
    const [limit, setLimit] = useQueryState("limit", 10);
    const [offset, setOffset] = useQueryState("offset", 0);

    const {
        data: contacts,
        loading,
        refetch,
    } = useFetch(contactsQuery, {
        onError: (e) => {
            notification.error({
                message: `Erro ao buscar contatos`,
                description: e.MSG,
                duration: 5,
            });
        },
        initialState: [],
       autoFetch: false
    });

    const [searchTerm, setSearchTerm] = useReducer((state, action) => {
        switch (action.type) {
            case "name":
                return {
                    ...state,
                    name: action.payload,
                };
            case "phone":
                return {
                    ...state,
                    phone: action.payload,
                };
            case "id":
                return {
                    ...state,
                    id: action.payload,
                };
            default:
                return state;
        }
    }, {});

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const searchInput = useRef(null);

    const currentContact = contacts?.rows?.find(
        (item) => Number(item.id) === Number(contactId)
    );

    const breadcrumb = [
        {
            title: "Central de Atendimento ao Cliente",
            path: props.modulePath,
        },
        {
            title: "Backup Huggy",
            path: props.modulePath + "/huggy-backup",
        },

        ...(currentContact
            ? [
                  {
                      title: currentContact.name,
                      path:
                          props.modulePath +
                          "/huggy-backup?contact=" +
                          contactId,
                  },
              ]
            : []),
    ];

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: () => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder="Pesquisar..."
                    value={searchTerm[dataIndex]}
                    onChange={(e) => {
                        const { value } = e.target;
                        setSearchTerm({ type: dataIndex, payload: value });
                    }}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        block
                        onClick={() =>
                            setSearchTerm({ type: dataIndex, payload: "" })
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Limpar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: () => (
            <SearchOutlined
                style={{
                    color: searchTerm[dataIndex] ? "#1890ff" : undefined,
                }}
            />
        ),

        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    useEffect(() => {
      // Filtra os valores do estado para não enviar valores vazios
      const params = _.pickBy({
        ...debouncedSearchTerm,
        offset,
        limit,
      }, (value) => {
        return value !== "" && value !== undefined && value !== null;

      })
        refetch(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm, offset, limit]);

    return (
        <>
            <Helmet>
                <title>Backup Huggy - webGM</title>
            </Helmet>
            <Main
                moduleName={props.moduleName}
                moduleMenu={
                    <MenuComponent
                        itensMenu={itensMenu}
                        modulePath={props.modulePath}
                        selectedMenu="1"
                    />
                }
                breadcrumb={breadcrumb}
            >
                <Spin tip="Cadastrando..." spinning={loading}>
                    <h2>Contatos</h2>
                    {currentContact ? (
                        <>
                            <ContactView contact={currentContact} />
                        </>
                    ) : (
                        <Table
                            columns={[
                                {
                                    title: "Nome",
                                    dataIndex: "name",
                                    key: "name",
                                    ...getColumnSearchProps("name"),
                                },
                                {
                                    title: "ID do contato",
                                    dataIndex: "id",
                                    key: "id",
                                    ...getColumnSearchProps("id"),
                                },
                                {
                                    title: "Telefone",
                                    dataIndex: "phone",
                                    key: "phone",
                                    ...getColumnSearchProps("phone"),
                                },
                            ]}
                            dataSource={contacts?.rows || []}
                            onRow={(record) => {
                                return {
                                    onClick: (event) => {
                                        setContactId(record.id);
                                    },
                                };
                            }}
                            pagination={{
                                total: contacts?.count,
                                pageSize: limit,
                                current: offset / limit + 1,
                                onChange: (page, pageSize) => {
                                    setOffset((page - 1) * pageSize);
                                },

                                showSizeChanger: true,
                                onShowSizeChange: (current, size) => {
                                  console.log({size})
                                    setLimit(size);
                                },
                                pageSizeOptions: ["10", "20", "50", "100"],
                            }}

                        />
                    )}
                </Spin>
            </Main>
        </>
    );
}
