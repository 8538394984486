import { useState } from "react"

export const useMutate = (mutateFn, { onError, onSuccess } = {}) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  async function mutate(params) {
    try {
      setLoading(true);
      const result = await mutateFn(params);
      setData(result);

      if (typeof onSuccess === "function") onSuccess(result);
    } catch (e) {
      setError(e);
      if (typeof onError === "function") onError(e);
    } finally {
      setLoading(false);
    }
  }

  return { data, loading, error, mutate };
};
