/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect } from "react";
import {
  getUserNotificationsQuery,
  updateNotificationReceiversMutation,
} from "../services/notificationService";
import { onMessageListener } from "../utils/firebase";
import { useFetch } from "../utils/useFetch";
import { useMutate } from "../utils/useMutate";
import { useSession } from "./SessionProvider";

const Context = createContext({});

export const NotificationProvider = ({ children }) => {
  const { session } = useSession();

  const { data: userNotifications, refetch: refetchUserNotifications } =
    useFetch(getUserNotificationsQuery, {
      autoFetch: false,
      initialParams: {
        user_code: session.user.id,
        application_code: "web-gm",
      },
    });

  const { mutate: updateNotificationReceivers } = useMutate(
    updateNotificationReceiversMutation
  );


  function handleMessaListiner(payload) {
    refetchUserNotifications({
      user_code: session.user.id,
      application_code: "web-gm",
    });
    
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        new Audio("/audio/notification.wav").play();

        const notification = new Notification(payload.notification.title, {
          body: payload.notification.body,
          icon: payload.notification.icon || "https://cdn-icons-png.flaticon.com/512/252/252035.png",
          silent: true,
        });
        notification.onclick = () => {
          window.open(payload.fcmOptions.link);
        };
      }
    }
  }

  useEffect(() => {
    if (session.user.id) {
      refetchUserNotifications({
        user_code: session.user.id,
        application_code: "web-gm",
      });


      const unsubscrib = onMessageListener((payload) => {
        handleMessaListiner(payload)
      });


      // Canal de comunicação com o Service Worker
      const broadcast = new BroadcastChannel('message-channel');
      broadcast.onmessage = (event) => {
        // Atualiza notificações quando for disparado um evento pelo Service Worker
        if (event.data && event.data.type === 'BACKGROUND_MESSAGE') {
          refetchUserNotifications({
            user_code: session.user.id,
            application_code: "web-gm",
          })
          
        }
      };

      // evitar memory leak
      return unsubscrib;
    }
  }, [session.user]);

  useEffect(() => {
    if (userNotifications?.length > 0) {
      const notificationsToBeUpdated = userNotifications.filter(
        (notification) => !notification.delivery_date
        );
        
        // Atualiza as notificações que ainda não foram marcadas como entregue
        if (notificationsToBeUpdated?.length) {
          updateNotificationReceivers(
            notificationsToBeUpdated.map((notification) => ({
              id: notification.id,
              data: {
                delivery_date: new Date(),
              },
            }))
            );
          }
        }
      }, [userNotifications]);

  return (
    <Context.Provider
      value={{
        userNotifications,
        refetchUserNotifications,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useNotifications = () => {
  const ctx = useContext(Context);

  if (ctx === undefined) {
    throw new Error(
      "useNotifications must be used within a NotificationProvider"
    );
  }
  const { userNotifications, refetchUserNotifications } = ctx;

  return { userNotifications, refetchUserNotifications };
};
