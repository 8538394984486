import {
  useState,
  useEffect,
  useContext
} from 'react';

import {
  Collapse,
  Spin,
  Form,
  Row,
  Col,
  Input,
  message,
  Button,
  AutoComplete,
  Modal
} from 'antd';

import MaskedInput from "antd-mask-input";

import axios from 'axios';

import { RemovalAnticipationFormContext } from '../../../../../../Context/removal-anticipation-form-context';
import { getPlaceDetails, usePlacesAutocomplete } from '../../../../../../services/autocompleteService';
import { useSelector } from 'react-redux';
import { companiesLocation } from '../../../../../../utils/constants';
import {SearchOutlined} from '@ant-design/icons';
import { api } from '../../../../../../utils/apiProtheus';
import moment from 'moment';

const validateMessages = {
  required: "${label} é obrigatório!",
};

const Removal = (props) => {
  const [loading, setLoading] = useState(false);
  const [cep, setCep] = useState({ target: '', number: '' });
  const [errorForm, setErrorForm] = useState({ origin: false, destinary: false });
  const signin = useSelector((state) => state.signin);
  const [removalAnticipationRemovalForm] = Form.useForm();
  const removalAnticipationFormContext = useContext(RemovalAnticipationFormContext);


  const { Hierarquia } = signin.user.userCompanies.find(
    (company) => company.id === signin.user.currentCompany
  );

  const companyLocation = companiesLocation.find(
    (company) => company.code_erp === Hierarquia.code_erp
  );

  const { setValue, suggestions } = usePlacesAutocomplete({
    location: companyLocation,
  });
  
  const handleSelectPrediction = async (addressData, target) => {
    const addressDetails = await getPlaceDetails(addressData.place_id);

    const { structured_formatting } = suggestions[0]

    const addresName = structured_formatting?.main_text

    addressDetails.addressName = addresName
   removalAnticipationRemovalForm.setFieldsValue({
    removal: {
      [target]: addressDetails,
    }
   })
 }
  
  useEffect(() => {
    handleCep()
  }, [cep]);

  const handleCep = async () => {
    try {

      if (!cep.number) {
        return
      }

      setLoading(true)

      const result = await axios.get(`https://viacep.com.br/ws/${cep.number.replace(/\.|\-/g, '')}/json/`);

      const address = {
        address: result.data.logradouro,
        district: result.data.bairro,
        city: result.data.localidade,
        state: result.data.uf,
        complement: result.data.complemento
      }

      let data = {}
      if (cep.target === 'origin') {
        data = {
          removal: {
            origin: address
          }
        }
      } else {
        data = {
          removal: {
            destinary: address
          }
        }
      }

      removalAnticipationRemovalForm.setFieldsValue(data)
    } catch (error) {
      message.info('Não foi possível localizar esse CEP')
    } finally {
      setLoading(false);
    }
  }

  const handleReturn = () => {
    setLoading(true);
    try {
      removalAnticipationFormContext.setFormResult({
        ...removalAnticipationFormContext.formResult,
        ...removalAnticipationRemovalForm.getFieldsValue()
      });

      removalAnticipationFormContext.setCurrent(removalAnticipationFormContext.current - 1)
    } catch (error) {
      message.error(error)
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const headers = {
        tenantId: signin.user.tenantId,
        "Content-Type": "application/json",
      };


      const data = {
        ...removalAnticipationFormContext.formResult,
        user: {
          codeProtheus: signin.user.codeProtheusUser,
        },
        contract: {
          ...removalAnticipationFormContext.formResult.contract,
          cpf: removalAnticipationFormContext.formResult.contract.cpf?.replace(/\.|\-/g, ""),
          rg: removalAnticipationFormContext.formResult.contract.rg?.replace(/\.|\-/g, ""),
          cell: removalAnticipationFormContext.formResult.contract.cell?.replace(/([^0-9a-zA-Z])/g, ""),
          telephone: removalAnticipationFormContext.formResult.contract.telephone 
            ? removalAnticipationFormContext.formResult.contract.telephone.replace(/([^0-9a-zA-Z])/g,"") : "",
          birthDate: moment(removalAnticipationFormContext.formResult.contract.birthDate,"DD/MM/YYYY HH:mm").format("DD/MM/YYYY"),
          address: {
            ...removalAnticipationFormContext.formResult.contract.address,
            cep: removalAnticipationFormContext.formResult.contract.address?.cep?.replace(/\.|\-/g, ""),
          },
        },
        deceased: {
          ...removalAnticipationFormContext.formResult.deceased,
          cpf: removalAnticipationFormContext.formResult.deceased.cpf?.replace(/\.|\-/g, ""),
          birthDate: moment(removalAnticipationFormContext.formResult.deceased.birthDate, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY"),
          deathDate: moment(removalAnticipationFormContext.formResult.deceased.deathDate, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY"),
        },
        removal: {
          origin: {
            ...values.removal.origin,
            cep: values.removal.origin.cep?.replace(/\.|\-/g, ""),

          },
          destinary: {
            ...values.removal.destinary,
            cep: values.removal.destinary.cep?.replace(/\.|\-/g, ""),
          },
        },
        
      };

      if (!data.contract.declarant?.fullName) {
        delete data.contract.declarant
      }

    
      const resp = await api.post(`/all/RESTFAR`, data, {
        headers,
      });

      const sucessStatus = [200, 201];

      if (!sucessStatus.includes(resp.status)) {
        throw new Error(resp.data.message);
      }

      Modal.success({
        title: "FAR cadastrado com sucesso!",
        centered: true,
        
      })

      removalAnticipationFormContext.setFormResult({
        contract: {},
        deceased: {},
        products: [],
        caveats: {},
        removal: {},
        formOfPayments: [],
      });
      removalAnticipationFormContext.setCurrent(0);

    } catch (error) {
      message.error("Erro ao cadastrar FAR: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOnFinishFailed = async ({ errorFields }) => {
    const posOrigin = errorFields.map(error => error.name[1]).indexOf('origin');
    const posDestinary = errorFields.map(error => error.name[1]).indexOf('destinary');

    setErrorForm({
      origin: posOrigin >= 0 ? true : false,
      destinary: posDestinary >= 0 ? true : false
    })

  }

  const HandleFailedForm = (props) => {
    return (
      <>
        {
          errorForm[props.form]
            ?
            <span style={{ color: 'red' }}>Existem campos obrigatório que não foram preenchidos *</span>
            : null
        }
      </>
    )
  }

  return (
    <Spin tip="Carregando..." spinning={loading}>
      <Form
       onKeyDown={(e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    }}
        form={removalAnticipationRemovalForm}
        layout="vertical"
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        onFinishFailed={handleOnFinishFailed}
        initialValues={{
          removal: removalAnticipationFormContext.formResult.removal
        }}
      >
        <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
          <Col xs={24} sm={24}>
            <Collapse defaultActiveKey={['1', '2']}>
              <Collapse.Panel header="Origem" key="1" extra={<HandleFailedForm form="origin" />}>
                <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                  <Col xs={24} md={4}>
                    <Form.Item
                      label="CEP"
                      name={["removal", "origin", "cep"]}
                    >
                      <MaskedInput
                        mask="00000-000"
                        placeholder="00000-000"
                        allowClear
                        onBlur={(e) => setCep({ target: 'origin', number: e.target.value })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={16}>
                    <Form.Item hidden name={["removal", "origin", "addressName"]}/>
                    <Form.Item
                      label="Endereço"
                      name={["removal", "origin", "address"]}
                      rules={
                        [
                          {
                            required: true,
                            message: 'Campo Endereço é obrigatório!'
                          }
                        ]
                      }
                    >
                       <AutoComplete
                        placeholder="Digite o endereço ou busque pelo nome do local"
                        options={suggestions?.map(
                          ({ description, ...rest }) => ({
                            value: description,
                            ...rest,
                          })
                        )}
                        suffixIcon={<SearchOutlined />}
                        onSelect={(_, option) => {
                          handleSelectPrediction(option, 'origin')
                        }}
                        onSearch={(value) => setValue(value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4}>
                    <Form.Item
                      label="Número"
                      name={["removal", "origin", "number"]}
                      rules={
                        [
                          {
                            required: true,
                            message: 'Campo Número é obrigatório!'
                          }
                        ]
                      }
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                  <Col xs={24} md={14}>
                    <Form.Item
                      label="Bairro"
                      name={["removal", "origin", "district"]}
                      rules={
                        [
                          {
                            required: true,
                            message: 'Campo Bairro é obrigatório!'
                          }
                        ]
                      }
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={7}>
                    <Form.Item
                      label="Cidade"
                      name={["removal", "origin", "city"]}
                      rules={
                        [
                          {
                            required: true,
                            message: 'Campo Cidade é obrigatório!'
                          }
                        ]
                      }
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={3}>
                    <Form.Item
                      label="Estado"
                      name={["removal", "origin", "state"]}
                      rules={
                        [
                          {
                            required: true,
                            message: 'Campo Estado é obrigatório!'
                          }
                        ]
                      }
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="Complemento"
                      name={["removal", "origin", "complement"]}
                      rules={[
                        {
                          required: true,
                          message: validateMessages.required,
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel header="Destino" key="2" extra={<HandleFailedForm form="destinary" />}>
                <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                  <Col xs={24} md={4}>
                    <Form.Item
                      label="CEP"
                      name={["removal", "destinary", "cep"]}
                    >
                      <MaskedInput
                        mask="00.000-000"
                        placeholder="00000-000"
                        allowClear
                        onBlur={(e) => setCep({ target: 'destinary', number: e.target.value })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={16}>
                  <Form.Item hidden name={["removal", "destinary", "addressName"]}/>
                    <Form.Item
                      label="Endereço"
                      name={["removal", "destinary", "address"]}
                      rules={
                        [
                          {
                            required: true,
                            message: 'Campo Endereço é obrigatório!'
                          }
                        ]
                      }
                    >
                      <AutoComplete
                        options={suggestions?.map(
                          ({ description, ...rest }) => ({
                            value: description,
                            ...rest,
                          })
                        )}
                        onSelect={(_, option) => {
                          handleSelectPrediction(option, 'destinary')
                        }}
                        onSearch={(value) => setValue(value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4}>
                    <Form.Item
                      label="Número"
                      name={["removal", "destinary", "number"]}
                      rules={
                        [
                          {
                            required: true,
                            message: 'Campo Número é obrigatório!'
                          }
                        ]
                      }
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                  <Col xs={24} md={14}>
                    <Form.Item
                      label="Bairro"
                      name={["removal", "destinary", "district"]}
                      rules={
                        [
                          {
                            required: true,
                            message: 'Campo Bairro é obrigatório!'
                          }
                        ]
                      }
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={7}>
                    <Form.Item
                      label="Cidade"
                      name={["removal", "destinary", "city"]}
                      rules={
                        [
                          {
                            required: true,
                            message: 'Campo Cidade é obrigatório!'
                          }
                        ]
                      }
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={3}>
                    <Form.Item
                      label="Estado"
                      name={["removal", "destinary", "state"]}
                      rules={
                        [
                          {
                            required: true,
                            message: 'Campo Estado é obrigatório!'
                          }
                        ]
                      }
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="Complemento"
                      name={["removal", "destinary", "complement"]}
                      rules={[
                        {
                          required: true,
                          message: validateMessages.required,
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: 15 }}>
          <Col>
            <Button style={{ margin: '0 8px' }} onClick={handleReturn}>
              Voltar
            </Button>
          </Col>
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
              >
                Avançar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}

export default Removal;