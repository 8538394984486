import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Divider,
  Form,
  Drawer,
  Input,
  Switch,
  Button,
  Steps,
} from "antd";

import {
  PlusOutlined, FilterFilled, FilterOutlined 
} from "@ant-design/icons";

import Main from "../../../Layout/Main";
import MenuFuneralService from "../../../Layout/Menu";
import { itensMenu } from "../itens-menu";
import Index from "./components/Index";
import { ModalApp } from "../../../../components/Modal";
import { openModal } from "./../../../../store/ducks/modalGlobal";
import { useSession } from "../../../../Context/SessionProvider";
import { isMobile } from "./../../../../utils/isMobile";
import { Helmet } from "react-helmet";
import { checkControl } from "../../../../utils/access_control";

import FormPaymentLink from "./components/FormPaymentLink";
import Send from "../components/Send";
import MenuComponent from "../../../../components/MenuComponent";

const { Step } = Steps;

function PaymentLink(props) {
  const [current, setCurrent] = useState(0);
  const [filterForm] = Form.useForm();
  const [offset, setOffset] = useState(1);

  const [result, setResult] = useState({});
  const [filters, setFilters] = useState({});
  const { session } = useSession();

  const [openFilter, setOpenFilter] = useState(false);
  const hasFilter = Object.keys(filters).some((key) => filters[key] !== "");

  const signin = useSelector((state) => state.signin);

  const dispatch = useDispatch();

  const steps = [
    {
      key: 0,
      title: "Formulário",
      content: <FormPaymentLink setCurrent={() => setCurrent(current + 1)} setResult={setResult} />,
    },
    {
      key: 1,
      title: "Envio",
      content: <Send setCurrent={() => setCurrent(0)} result={result} />,
    },
  ]

  const breadcrumb = [
    {
      title: "Financeiro",
      path: props.modulePath,
    },
    {
      title: "Pagamento por Link",
      path: props.modulePath + "/payment-link",
    },
  ];

  const handleOpenModal = (data) => {
    dispatch(openModal(true));
  };

  

  const cleanStoreComponent = (value) => {
    setCurrent(0);
  };

  function handleSubmit(values) {
    let filter2 = filters
    filter2.codigo = values.codigo
    setOffset(1)

    setFilters(filter2);
    setOpenFilter(false);
  }
  
  
  return (
    <Main
      key={props.moduleName}
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          modulePath={props.modulePath}
          selectedMenu="2"
          itensMenu={itensMenu}
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"flex"}
      buttonHeader={
        <Row justify="center" style={{ marginTop: 6 }}>
          <Form.Item
            style={{ margin: "0 .5rem" }}
            tooltip="Quando ativo, exibe apenas os pagamentos por link"
            label="Meus Pagamentos"
          >
            <Switch
              onChange={(value) => {
                setFilters({ userId: value ? session.user.id : null });
              }}
              defaultChecked={false}
            />
          </Form.Item>
          <Col>
            <Button
              icon={hasFilter ? <FilterFilled /> : <FilterOutlined />}
              onClick={() => setOpenFilter(!openFilter)}
            ></Button>
          </Col>

          <Button
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={handleOpenModal}
            style={{
              ...checkControl(signin.user.profiles, "insert_link_payment"),
              marginLeft: "20px",
            }}
          >
            Novo
          </Button>
          
        </Row>
      }
    >
      <Helmet>
        <title>Pagamento por Link - webGM</title>
      </Helmet>
      <Index result={result} idUser={filters.userId} codFilter={filters.codigo} offset={offset} setOffset={setOffset}/>

      <ModalApp
            title={"Formulário de Geração de Link"}
            width={500}
            footer={null}
            cleanStoreComponent={cleanStoreComponent}
          >
            <Row>
             <Col xs={24} sm={24} lg={24}>
                <Steps
                  current={current}
                  size="small"
                  direction="horizontal"
                >
                  {

                    isMobile() ?
                      <Step
                        key={steps[current].key}
                        title={steps[current].title}
                      />
                      :
                      steps.map(item => (
                        <Step
                          key={item.key}
                          title={item.title}
                        />
                      ))
                  }
                </Steps>
                <Divider orientation="left" />
                <Row>
                  <Col xs={24}>
                    {steps[current].content}
                  </Col>
                </Row>
                
              </Col>
            </Row>
          </ModalApp>
      <Drawer
        title="Filtros"
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        placement="right"
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      > 
        <Form form={filterForm} onFinish={handleSubmit} layout="vertical">
          <Form.Item label="Nº da Proposta" name="codigo">
            <Input allowClear />
          </Form.Item>
          
          <Row justify="end">
            <Button
              style={{
                marginTop: "auto",
              }}
              htmlType="submit"
              type="primary"
            >
              Filtrar
            </Button>
          </Row>
        </Form>
      </Drawer>
    </Main >
  );

}

export default PaymentLink;