import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Switch, Button, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

import { checkControl } from "../../../../utils/access_control";

import "./styles.css";

import Main from "../../../Layout/Main";
import MainCrud from "../../../../components/MainCrud";
import { ModalApp } from "../../../../components/Modal";
import { FormApp } from "./components/Form";

import { ModulesService } from "../../../../services/modules";

import { listModulesIndex, alterModule } from "../../../../store/ducks/modules";
import { openModal } from "../../../../store/ducks/modalGlobal";

import MenuComponent from "../../../../components/MenuComponent";
import { itensDrawer } from "../itens-menu";

function Modules(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const signin = useSelector((state) => state.signin);
  const modules = useSelector((state) => state.modules);

  const dispatch = useDispatch();

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "Icone",
      dataIndex: "description",
    },
    {
      title: "Path",
      dataIndex: "path",
    },
  ];

  useEffect(() => {
    if (!checked) {
      list(true);
    } else {
      list(false);
    }
  }, [page, rowsPerPage, modules.module]);

  function list(active) {
    try {
      setLoading(true);

      const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;

      ModulesService.getModulesIndex(rowsPerPage, offset, active).then(
        (res) => {
          dispatch(listModulesIndex(res));
        }
      );
    } catch (error) {
      message.error("Erro ao carregar dados!");
    } finally {
      setLoading(false);
    }
  }

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(10);
  }

  function handleOpenModal(data) {
    dispatch(openModal(true));
    dispatch(
      alterModule({
        ...data,
      })
    );
  }

  function activeSwitch(checked) {
    if (checked) {
      list(false);
      setChecked(true);
      message.info("Exibindo módulos inativos!");
    } else {
      list(true);
      setChecked(false);
      message.info("Exibindo módulos ativos!");
    }
  }

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Módulos",
      path: props.modulePath + "/modules",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Módulos - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="17"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <>
            <span
              style={checkControl(
                signin.user.profiles,
                "check_modules_inactivate"
              )}
            >
              <Switch
                checkedChildren="Inativos"
                unCheckedChildren="Ativos"
                onChange={activeSwitch}
                style={{ marginRight: "5px", marginTop: "5px" }}
              />
            </span>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={handleOpenModal}
              style={{
                ...checkControl(signin.user.profiles, "insert_module"),
                marginLeft: "20px",
              }}
            >
              Novo
            </Button>
          </>
        }
      >
        {modules.moduleList && modules.moduleList.length !== 0 ? (
          <MainCrud
            handleOpenModal={handleOpenModal}
            columnsTable={columns}
            dataSource={modules.moduleList}
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            countPages={modules.moduleListCount}
            handleTableChange={handleTableChange}
            profiles={signin.user.profiles}
            classTag={[]}
          />
        ) : (
          <Empty description="Nenhum módulo encontrado" />
        )}

        <ModalApp title="Módulo" width={1000} footer={null}>
          <FormApp />
        </ModalApp>
      </Main>
    </>
  );
}

export default Modules;
