/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import "./styles.css";
import { listUserByProfileApi } from "../../services/users";
import {
  Row, Col, Form, Select, Button, message, DatePicker, Input, InputNumber,
  Radio, Checkbox, Skeleton, List, Avatar, Empty, Divider, Tabs, Popconfirm
} from "antd";
import { ContainerOutlined, FormOutlined, FilePdfOutlined, LeftOutlined, MenuOutlined, DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import OsMovements from "../OsMovements";
import "moment/locale/pt-br";
import locale from "antd/es/date-picker/locale/pt_BR";
import moment from "moment";
import { useIsMobile } from "../../utils/isMobile";
import { appointmentsApi } from "../../services/appointments";
import { appointmentsApiProtheus } from "../../services/appointmentsProtheus";
import { ServicesService } from "../../services/services";
import { awsApi } from "../../services/aws";
import { showFormApi } from "../../services/forms";
import { recordMovementsApi } from "../../services/recordMovementsOs";
import { listUserIdApi } from "../../services/users";
import { openDrawer, openSecondaryDrawerData } from "../../store/ducks/drawer";
import { alterFormComponent } from "../../store/ducks/formComponent";
import { setAppointment as setAppointmentRedux, setAppointmentDefaultPhotos } from "../../store/ducks/appointments";
import Main from "../../pages/Layout/Main";
import Collapse from "../Collapse";
import DrawerData from "../Drawer";
import DrawerSecondary from "../DrawerSecondary";
import Editor from "../RichText";
import FormComponent from "../FormComponent";
import ModalProtheusData from "../ModalProtheusData";
import { getSectorId } from "./functions";
import { modalProtheus, openModalSecondary } from "../../store/ducks/modalGlobal";
import { setData, setLoadingProtheusData } from "../../store/ducks/modalProtheusData";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import { convertNameInTagName } from "../../utils/automatic_response";
import { SecondaryModalApp } from "../SecondaryModal";
import { bin2string } from "../../utils/text";
import { getTagInCode } from "../../utils/tagsInCode";
import Loading from "../Loading";
import { settingServiceFieldsBinding } from "./constants/settingServiceFieldBinding";
import { firebaseApi } from "../../services/firebaseApi";
import api from "../../utils/api";
import ProtheusSales from "../ProtheusSales";
import { UploadDocument } from "./components/UploadDocument";
import { checkControl } from "../../utils/access_control";
import { AttachmentsModal } from "./components/Attachements";
import { convertFieldValue, validateField } from "../../utils/ruleValidator";
const { Option } = Select;
const { TabPane } = Tabs;

/**
 * Formata a data de registro do serviço para retornar no formato dd/mm/yyyy
 */
const formatingDate = (value) => {
  const date = new Date(value);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const formatted = `${day}/${month}/${year}`;
  return formatted;
};

const formatingHours = (value) => {
  const date = new Date(value);
  const hours = date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours();
  const minutes =
    date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes();
  return `${hours}:${minutes}`;
};

function AppointmentsWithParams(props) {
  const signin = useSelector((state) => state.signin);
  const formComponents = useSelector(
    (state) => state.formComponent.formComponent
  );

  const [loading, setLoading] = useState(false);
  const [loadingSecondary, setLoadingSecondary] = useState(false);
  const [loadingChecklist, setLoadingChecklist] = useState(false);
  const [operation, setOperation] = useState("");
  const [fieldsRender, setFieldsRender] = useState([]);
  const [fieldsRenderSecondary, setFieldsRenderSecondary] = useState();
  const [settingServiceFields, setSettingServiceFields] = useState([]);
  const [html, setHtml] = useState("");
  const [formStart, setFormStart] = useState([]);
  const [formStartResponse, setFormStartResponse] = useState([]);
  const [detailsProtehusData, setDetailsProtehusData] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [idUserOs, setIdUserOs] = useState(0);
  const [htmlDocument, setHtmlDocument] = useState("");
  const [fieldsRenderTag, setFieldsRenderTag] = useState([]);
  const [save, setSave] = useState(true);
  const [baseService, setBaseService] = useState();
  const [appointment, setAppointment] = useState(null);
  const [usersProfile, setUsersProfile] = useState(null);
  const [sendTaskToFirebase, setSendTaskToFirebase] = useState(false);
  const [taskIsInServiceQueue, setTaskIsInServiceQueue] = useState(false);
  const [openModalAttachment, setOpenModalAttachment] = useState(false);
  const [currentAttachment, setCurrentAttachment] = useState(null);
  const [form] = Form.useForm();

  const wordsToIgnoreLocal = [
    "GENERICA",
    "RESIDENCIA",
    "HOSPITAL DA UNIMED",
    "FUNERARIA",
    "I.M.L",
  ];


  const isMobile = useIsMobile()


  const handleOpenAttachmentModal = async (attachment) => {
    setCurrentAttachment(attachment);
    setOpenModalAttachment(true)
  }

  const dispatch = useDispatch();

  const history = useHistory();

  const {
    appointment_id,
    num_attendance_erp,
    id_general_status,
    setting_service_id,
    isMobileParam,
  } = useParams();

  useEffect(() => {
    if (save) {

      getAppointment();

      getUsersByProfile();

      getBaseService();

      getFields();

      isForm();

      detailsProtheus();

      getDocumentsBySettingService();

      verifyIfTaskIsInServiceQueue();


      setSave(false);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [save]);

  useEffect(() => {

    getFields();

    getDocumentsBySettingService();

    verifyIfTaskIsInServiceQueue();

  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usersProfile]);

  useEffect(() => {
    if (sendTaskToFirebase === true) {
      sendTaskToFirebaseApp();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    [settingServiceFields, sendTaskToFirebase]);

  useEffect(() => {
    if (
      appointment !== null &&
      baseService !== undefined &&
      detailsProtehusData !== {}
    ) {
      if (
        baseService?.company_id !== signin?.user?.currentCompany &&
        appointment?.os?.length === 0
      ) {
        getAppointment(baseService.company_id);
      }
      setLoading(false);
      getDocumentsBySettingService();
      verifyIfTaskIsInServiceQueue();
    } else if (appointment != null) {
      setLoading(false);
      getDocumentsBySettingService();
      verifyIfTaskIsInServiceQueue();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appointment, baseService, detailsProtehusData]);

  const containsStringToIgnoreLocalOrigem = (element) => {
    const arraryBooleanResult = wordsToIgnoreLocal?.map((item) =>
      element?.trim()?.toUpperCase()?.includes(item)
    );
    if (arraryBooleanResult?.filter((x) => x === true)?.length > 0) return true;
    else return false;
  };

  /**
   * verifica se a task está na fila de atendimento
   */
  function verifyIfTaskIsInServiceQueue() {
    firebaseApi
      .GetTask(appointment_id)
      .then((task) => {
        if (
          task?.userId !== null &&
          (task?.startedAt === null || task?.startedAt === undefined) &&
          (task?.endedAt !== null || task?.endedAt !== undefined)
        )
          setTaskIsInServiceQueue(true);
        else setTaskIsInServiceQueue(false);
      })
      .catch(() => setTaskIsInServiceQueue(false));
  }

  /**
   * constrói o local de origem com informações extras do endereço
   * @param {*} local_origem
   * @param {*} endereco_origem
   * @param {*} numero_origem
   * @returns
   */
  const buildLocalOrigem = (
    local_origem = null,
    endereco_origem = null,
    numero_origem = null
  ) => {
    //local origem + endereço + número
    if (endereco_origem != null && numero_origem != null)
      return `${local_origem?.trim()} ${endereco_origem?.trim()} ${numero_origem?.trim()}`;
    else if (endereco_origem != null)
      return `${local_origem?.trim()} ${endereco_origem?.trim()}`;
    else return `${local_origem?.trim()}`;
  };


  function deleteOS() {
    const css = checkControl(signin.user.profiles, "delete_os")
    return { marginRight: 10, marginTop: 5, ...css }
  }

  function voltarOS() {
    const css = checkControl(signin.user.profiles, "voltar_os")
    return { marginLeft: 5, ...css }
  }

  /**
   * realiza o envio das tasks para o firebase
   */
  function sendTaskToFirebaseApp() {
    let id_general_status = 0;
    if (operation === "Liberar") id_general_status = 6;
    else if (operation === "RemoverDaFila") id_general_status = 7;
    else if (operation === "Agendar") id_general_status = 5;
    else if (operation === "Finalizar") id_general_status = 3;

    //enviar task para a api (se for agendar)
    if (id_general_status === 5) {
      try {

        const tipoAtendimento = appointment?.Product?.description?.trim(); // (CORTEJO, REMOÇÃO, ETC)

        const jsonBody = {
          id: appointment_id,
          toDoDate: getDataAgendamentoBySettingService(),
          toDoLocation:
            containsStringToIgnoreLocalOrigem(
              detailsProtehusData[tipoAtendimento]['local_origem']
            ) === true
              ? `${detailsProtehusData[tipoAtendimento]['endereco_origem']?.trim()}  ${detailsProtehusData[tipoAtendimento]['numero_end_origem']?.trim()}`
              : buildLocalOrigem(
                detailsProtehusData[tipoAtendimento]['local_origem'],
                detailsProtehusData[tipoAtendimento]['endereco_origem'],
                detailsProtehusData[tipoAtendimento]['numero_end_origem']
              ),
          toDoDescription: appointment.SettingService?.name?.trim(),
          toDoName: appointment.SettingService?.description?.trim(),
          toDoState: false,
          userId: getUserIdBySettingService(),
          scheduled: true,
          userIdFieldName: getNameFieldUserIdBySettingService(),
          userIdFieldId: getIdFieldUserIdBySettingService(),
          userIdFieldIdOrName: getUserPatchFieldName(),
          routeId: `/logistics/appointment/${appointment_id}/${num_attendance_erp}/${appointment?.id_general_status}/${setting_service_id}/1`,
        };

        return firebaseApi
          .Agendar(jsonBody)
          .then(() => setSendTaskToFirebase(false))
          .catch((err) => console.log(err));
      } catch (error) {
        setSendTaskToFirebase(true);
        console.log(error);
      }
    } else if (id_general_status === 3) {
      //se for finalizar

      try {
        let id_agente_finalizador = getUserIdBySettingService();
        var millesecondsOffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = new Date(Date.now() - millesecondsOffset)
          .toISOString()
          .slice(0, -1);
        const jsonBody = {
          id: appointment_id,
          endedAt: localISOTime,
          toDoState: true,
          userId: id_agente_finalizador,
        };

        return firebaseApi
          .Finalizar(jsonBody, appointment_id)
          .then(() => setSendTaskToFirebase(false));
      } catch (error) {
        setSendTaskToFirebase(true);
        console.log(error);
      }
    } else if (id_general_status === 6) {
      //liberar

      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      var rightNow = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);

      const tipoAtendimento = appointment?.Product?.description?.trim();

      try {
        const jsonBody = {
          id: appointment_id,
          toDoDate: rightNow,
          toDoLocation:
            containsStringToIgnoreLocalOrigem(
              detailsProtehusData[tipoAtendimento]['local_origem']
            ) === true
              ? `${detailsProtehusData[tipoAtendimento]['endereco_origem']?.trim()}  ${detailsProtehusData[tipoAtendimento]['numero_end_origem']?.trim()}`
              : buildLocalOrigem(
                detailsProtehusData[tipoAtendimento]['local_origem'],
                detailsProtehusData[tipoAtendimento]['endereco_origem'],
                detailsProtehusData[tipoAtendimento]['numero_end_origem']
              ),
          toDoDescription: appointment.SettingService.name?.trim(),
          toDoName: appointment.SettingService.description?.trim(),
          toDoState: false,
          scheduled: false,
          userIdFieldName: getNameFieldUserIdBySettingService(),
          userIdFieldId: getIdFieldUserIdBySettingService(),
          //userIdFieldIdOrName: getUserPatchFieldName(),
          routeId: `/logistics/appointment/${appointment_id}/${num_attendance_erp}/${appointment?.id_general_status}/${setting_service_id}/1`,
        };
        return firebaseApi.Liberar(jsonBody).then(() => {
          setSendTaskToFirebase(false);
          setLoading(false);
        });
      } catch (error) {
        setLoading(false);
        console.log("erro ao cadastrar task no firebase (liberar): ", error);
      }
    }

    if (id_general_status === 7) {
      // remover da fila de atendimento

      return firebaseApi.RemoverDaFilaDeAtendimento(appointment_id).then(() => {
        setSendTaskToFirebase(false);
        setLoading(false);
      });
    }
  }

  /**
   * Busca os usuários que tem o perfil de "CERIMONIALISTA" para mostrar no select
   * de colaboradores.
   */
  const getUsersByProfile = () => {
    setLoading(true);

    listUserByProfileApi
      .listUserByProfile(
        signin?.user?.currentModule.module
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, ""),
        signin?.user?.currentCompany
      )
      .then((response) => {
        setUsersProfile(response);
      })
      .catch((error) => {
        message.error(
          "Desculpe... Algo deu errado ao carregar os colaboradores."
        );
      });
  };

  /**
   * informações base da OS
   * @returns
   */
  function getBaseService() {
    setLoading(true);
    return api
      .get(
        `${process.env.REACT_APP_BACKEND_API}base/service/attendance/${num_attendance_erp}`
      )
      .then((res) => {
        setBaseService(res.data);
      })
      .catch(() => setBaseService(null));
  }

  /**
   * retorna e seta os dados do appointment
   * @returns
   */
  function getAppointment(currentCompanyId = null) {
    setLoading(true);

    return api
      .get(
        `service/tasks?id_sector=${getSectorId(
          window.location.pathname,
          signin?.user?.currentModule?.id_sector
        )}&id_setting_service=${setting_service_id}` +
        `&company=${currentCompanyId === null
          ? signin.user.currentCompany
          : currentCompanyId
        }` +
        `&num_attendance=${num_attendance_erp}`,
        {
          headers: {
            Authorization: `Bearer ${signin.user.token}`,
          },
        }
      )
      .then((res) => {
        let finds = [];
        let status = "";

        res?.data?.map((item) => {
          if (item?.Services?.find((x) => x.id === parseInt(appointment_id)))
            status = item.description;

          finds.push(
            item?.Services?.find((x) => x.id === parseInt(appointment_id))
          );
        });

        if (status === "") status = "Finalizado";
        let rootObject = finds.filter((x) => x !== undefined)[0] ?? [];
        rootObject["os"] = finds.filter((x) => x !== undefined)[0] ?? [];
        rootObject["status"] = status;

        const newapoint = rootObject;

        setAppointment(newapoint);

        getDocumentsBySettingService();
      })
      .catch((err) =>
        console.log("erro no carregamento do appointment: ", err)
      )
      .finally(() => {
        //if (appointment != null) setLoading(false)
      });
  }

  /**
   * Busca os dados da OS novamente para atualizar a pagina
   */
  function listTasks() {
    setLoading(true);
    getAppointment();
  }

  /**
   * Busca os anexos da base de atendimento
   */
  const getDocumentsBySettingService = async () => {
    setAttachments(appointment?.Order?.BaseService?.ServiceDocuments ?? []);
  };

  /**
   * Busca os dados do protheus que alimentam os detalhes
   */
  const detailsProtheus = () => {
    setLoading(true);
    setLoadingSecondary(true);
    dispatch(setLoadingProtheusData(true));

    const { code_erp: subsidiary_erp, Hierarquia } = signin.user.userCompanies.find(
      (company) => company.id === signin.user.currentCompany
    );

    appointmentsApiProtheus
      .getDetailsAttendanceProtheus(num_attendance_erp, {
        headers: {
          tenantId: `${Hierarquia.code_erp},${subsidiary_erp}`,
        },
      })
      .then((response) => {
        setDetailsProtehusData(response);
        dispatch(setData(response));
      })
      .catch(() => {
        setDetailsProtehusData({});
        dispatch(setData({}));
      })
      .finally(() => {
        // setLoading(false);
        setLoadingSecondary(false);
        dispatch(setLoadingProtheusData(false));
      });
  };

  const handleDownloadDocumento = async (document) => {
    try {
      const result = await awsApi.fetchFile(document.path);

      saveAs(result, document.title);
    } catch (error) {
      message.error(error);
    }
  };

  /**
   * Monta o collapse para identificar os dados gerais do atendimento
   */
  const GeneralDateAttendance = () =>
    loadingSecondary ? (
      <Loading />
    ) : !detailsProtehusData.ATENDIMENTO ? (
      <Empty description="Dados não localizados" />
    ) : (
      <>
        <div className="detailsAttendance">
          <div>
            <h4>Número: </h4>
            <h4>Data: </h4>
            <h4>Tipo: </h4>
            <h4>Falecido: </h4>
            <h4>Data de Nascimento: </h4>
            <h4>Religião: </h4>
            <h4>Data/Hora do óbito: </h4>
            <h4>Local do óbito: </h4>
            <h4>Causa da morte: </h4>
          </div>
          <div className="font detailsAttendanceMargin">
            <p>{detailsProtehusData.ATENDIMENTO.numero}</p>
            <p>{detailsProtehusData.ATENDIMENTO.data_atendimento}</p>
            <p>{detailsProtehusData.ATENDIMENTO.tipo}</p>
            <p>{detailsProtehusData.FALECIDO.nome}</p>
            <p>{detailsProtehusData.FALECIDO.data_nascimento}</p>
            <p>{detailsProtehusData.FALECIDO.religiao}</p>
            <p>
              {detailsProtehusData.FALECIDO.data_obito} -{" "}
              {detailsProtehusData.FALECIDO.hora_obito}{" "}
            </p>
            <p>{detailsProtehusData.FALECIDO.local_obito}</p>
            <p>{detailsProtehusData.FALECIDO.causa_morte}</p>
          </div>
        </div>
        <Divider />
        <div className="detailsAttendance">
          <div>
            <h4>Nome: </h4>
            <h4>E-mail: </h4>
            <h4>Telefone: </h4>
            <h4>Celular: </h4>
          </div>
          <div className="font detailsAttendanceMargin">
            <p>{detailsProtehusData.DECLARANTE.nome}</p>
            <p>{detailsProtehusData.DECLARANTE.email}</p>
            <p>
              {detailsProtehusData.DECLARANTE.ddd}{" "}
              {detailsProtehusData.DECLARANTE.telfixo}
            </p>
            <p>
              {detailsProtehusData.DECLARANTE.ddd}{" "}
              {detailsProtehusData.DECLARANTE.telcel}
            </p>
          </div>
        </div>
        <Divider />
        <h4>
          Produto: {appointment.Product.code ? appointment.Product.code : null}{" "}
          -{" "}
          {appointment.Product.description
            ? appointment.Product.description
            : null}{" "}
        </h4>
      </>
    );

  const titleChecklist = (title, required) => {
    return (
      <>
        {required ? (
          <span style={{ color: "red", marginRight: 5 }}>*</span>
        ) : null}
        <span>{title}</span>
      </>
    );
  };
  const data = [
    {
      header: "Checklists",
      key: "1",
      component: loadingChecklist ? (
        <Loading />
      ) : (
        <List
          itemLayout="horizontal"
          size="small"
          dataSource={formStart}
          renderItem={(item) => {
            let checklistsAwser = item.f.filter(
              (e) => e.services_id === parseInt(appointment_id)
            );
            return (
              <List.Item
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (id_general_status === 3 || id_general_status === 4) {
                    return false;
                  }

                  setLoadingChecklist(true);
                  showFormApi
                    .showFormService(item.id, appointment_id)
                    .then((response) => {
                      dispatch(
                        alterFormComponent({
                          ...response,
                          submission: item,
                          responseForms: formStartResponse,
                        })
                      );
                      dispatch(setAppointmentRedux(appointment));
                      dispatch(openDrawer(true));
                    })
                    .finally(() => setLoadingChecklist(false));
                }}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={
                        checklistsAwser.length !== 0
                          ? checklistsAwser[0].status_id === 1
                            ? { backgroundColor: "#1890ff" }
                            : { backgroundColor: "green" }
                          : { backgroundColor: "#a09f9f" }
                      }
                      icon={<FormOutlined />}
                    />
                  }
                  title={titleChecklist(item.title, item.is_required)}
                  description={item.description}
                />
              </List.Item>
            );
          }}
        />
      ),
    },
    {
      header: "Anexos",
      key: "2",
      component: (
        <>
          <Row justify="end">
            <Col>
              <UploadDocument
                baseAttendance={appointment?.Order?.BaseService.id}
                onUploadSuccess={() => {
                  listTasks();
                }}
                trigger={({ handleOpen }) => (
                  <Button
                    onClick={handleOpen}
                    type="default"
                    icon={<UploadOutlined />}
                  >
                    Incluir Anexo
                  </Button>
                )}
              />
            </Col>
          </Row>
          <List
            itemLayout="horizontal"
            dataSource={attachments}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<FilePdfOutlined />}
                  title={
                    <Button
                      className="button-attachment-item"
                      title={item.title}
                      type="link"
                      size="small"
                      onClick={() => {
                        handleOpenAttachmentModal(item);
                      }}
                    >
                      {item.title}
                    </Button>
                  }
                />
              </List.Item>
            )}
          />
        </>
      ),
    },
    {
      header: "Documentos da OS",
      key: "3",
      component: (
        <List
          itemLayout="horizontal"
          size="small"
          dataSource={appointment?.SettingService?.template_documents ?? []}
          renderItem={(item) => (
            <List.Item
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(openModalSecondary(true));
                setHtmlDocument({
                  ...item,
                  html: handleHtmlTag(bin2string(item.html.data)),
                });
              }}
            >
              <List.Item.Meta
                avatar={<Avatar icon={<FormOutlined />} />}
                title={item.name}
              />
            </List.Item>
          )}
        />
      ),
    },
    {
      header: "Dados do atendimento",
      key: "4",
      component: <GeneralDateAttendance />,
    },
    {
      header: "Detalhes da venda",
      key: "5",
      component: (
        <ProtheusSales
          atendimento={appointment?.Order?.BaseService?.num_attendance_erp}
        />
      ),
    },
  ];

  const register = {
    status: appointment?.status,
    register:
      appointment?.createdAt !== undefined && appointment?.createdAt !== null
        ? `${formatingDate(appointment?.createdAt)} às ${formatingHours(
          appointment?.createdAt
        )}`
        : "",
    attendant: appointment?.Order?.BaseService?.name_attendant,
    funerary: appointment?.Order?.BaseService?.name_funerary,
  };

  const breadcrumb = [
    {
      title: props.moduleName,
      path: props.modulePath,
    },
    {
      title: "Serviços",
      path: props.modulePath + "/order-service",
    },
    {
      title: appointment?.SettingService?.name ?? "",
      path: props.modulePath + "/order-service",
    },
    isMobile || isMobileParam === 1
      ? false
      : {
        title: `#${num_attendance_erp} - ${appointment?.Order?.BaseService?.name_deceased}`,
        path: props.modulePath + "/appointments",
      },
  ];

  /**
   * Verifica se existe formulário para ser respondido
   */
  const isForm = async () => {
    let checklists = await appointmentsApi.getAppointmentsChecklist(
      appointment_id,
      getSectorId(
        window.location.pathname,
        signin?.user?.currentModule?.id_sector
      ),
      setting_service_id
    );

    if (checklists.length === 0) {
      return false;
    }

    setFormStartResponse(checklists);
    checklists = checklists.ServiceForms.map((value) => {
      return {
        ...value.Form,
        is_required: value.is_required,
        fill_moment: value.fill_moment,
      };
    });

    if (checklists.length > 0) {
      checklists.sort((a, b) => {
        return a.id - b.id;
      });
    }

    setFormStart(checklists);

  };

  /**
   * Verifica qual collapse foi chamado
   */
  const handleCollapse = (key) => {
    if (key.includes("3")) {
      // Busca o histórico
    }
  };

  const handleRollback = () => {
    let id_user = idUserOs || signin.user.id;
    handleUpdateStatusService({
      id: appointment_id,
      id_user,
      id_general_status: 2,
    });

    history.goBack()
  }

  /**
   * grava a movimentação da OS
   */
  const handleRecordMovemntOS = async (status_id, user_id, service_id) => {
    setLoading(true);
    try {
      await recordMovementsApi.addRecordMovements({
        status_id,
        user_id,
        service_id,
        date: moment(),
      });
    } catch (e) {
      return false;
    } finally {
      if (appointment?.id_general_status === status_id) setLoading(false);
    }
  };

  /**
   * Define qual função deve chamar para montar os Options
   */
  const optionSelect = (field) => {

    if (field.get_list) {
      return field.users.map(choice => {
        if (choice.active == false) {
          return null
        }
        return (
          <Option key={choice.id} value={choice.id}>{choice.name}
          </Option>
        )
      });
    } else {
      if (
        field.SettingServiceFieldChoices &&
        field.SettingServiceFieldChoices.length !== 0
      ) {
        return field.SettingServiceFieldChoices.map((choice) => (
          <Option key={choice.id} value={choice.description}>
            {choice.description}
          </Option>
        ));
      }
    }
  };

  /**
   * busca todos os campos a serem renderizados
   */
  const getFields = () => {
    setLoading(true);
    setFieldsRender(<Skeleton />);
    appointmentsApi
      .appointmentsGet(id_general_status, appointment_id, setting_service_id)
      .then((response) => {

        getFieldsForRender(response);

        setSettingServiceFields(response)

      })
      .catch((err) => {
        message.error("Oops... Erro ao renderizar os campos");
        console.log("erro ao tentar carregar os campos: " + err.toString());
      })
      .finally(() => {
        if (appointment !== null) {
          setLoading(false);
        }
      });
  };

  /**
   * Verifica se existe algum formulário que precisa ser respondido
   */
  const checkForms = () => {
    let checkFinallyOs = true;
    let formsRequired = formStart.filter((el) => el.is_required === true);

    if (formsRequired.length === 0) {
      return checkFinallyOs;
    }

    for (const key in formsRequired) {
      if (Object.hasOwnProperty.call(formsRequired, key)) {
        const element = formsRequired[key];
        if (element.f.length > 0) {
          if (element.f[0].status_id != 2) {
            checkFinallyOs = false;
            break;
          }
        } else {
          checkFinallyOs = false;
          break;
        }
      }
    }
    return checkFinallyOs;
  };

  const handleSubmitForm = async (values) => {

    // checa se nas chaves possuí o campo de paragrafo para preencher com o conteúdo correto
    let reg = /[0-9]$/;
    for (let key in values) {
      if (reg.test(key)) {
        if (key.match(reg)[0] === "8") {
          // if (!html) {
          //   return message.warning(
          //     "Oops... necessário informar as observações!"
          //   );
          // }

          key = key.replace(/_[0-9]$/, "");
          values[key] = html.replace(/<(.|\n)*?>/g, "");
        }
      }
    }

    setLoading(true);
    const data = {
      ...values,
    };
    let id_responsable
    // montar um array de objetos com as respostas para serem salvas no BD

    let arrayData = Object.keys(data).map((key) => {
      if (data[key]) {
        // busca o ID do campo
        let field_id = settingServiceFields.find(
          (element) => element.tag_name === convertNameInTagName(key)
        );


        if(field_id != null){
          if(field_id?.responsible_field && typeof data[key] == "number"){
            id_responsable = data[key]
          }
        }

        if (!field_id) {
          field_id = 0;
        } else {
          field_id = field_id.id;
        }

        const field = settingServiceFields.find(x => x.id === field_id);
        if (
          field?.id_type === 6
          && field?.get_list === true
          && field?.tag_function !== null
          && field?.users !== null) {
          if (typeof data[key] === "string") {
            data[key] = field.users.find(x => x.name === data[key]).id;
          }
        }

        if (typeof data[key] !== "object") {
          return {
            key: key,
            value: data[key],
            id_setting_service_field: field_id,
          };
        } else if (key === "photo") {
          return {
            key: "photo",
            value: data.photo,
            id_setting_service_field: field_id,
          };
        } else if (data[key].isValid()) {
          return {
            key: key,
            value: data[key],
            id_setting_service_field: field_id,
          };
        }
      }
    });


    arrayData = arrayData.filter((e) => e !== undefined || null || false || 0);
    arrayData = arrayData.filter((e) => e.id_setting_service_field !== 0);


    let id_user = id_responsable ? id_responsable : signin.user.id

    appointmentsApi
      .appointmentsPost({
        id_service: appointment_id,
        values: arrayData,
      })
      .then(async () => {

        if (operation === "Salvar") {

          let status = appointment?.id_general_status;

          await handleRecordMovemntOS(status, id_user, appointment_id);

          handleUpdateStatusService({
            id: appointment_id,
            id_user,
            id_general_status: status,
          });

          history.goBack()

        }

        if (operation === "Liberar") {

          listTasks(`service_id = ${appointment_id}`);
          setSave(true);
          setSendTaskToFirebase(true);
          setLoading(false);
          history.goBack()

        }

        if (operation === "RemoverDaFila") {
          setSendTaskToFirebase(true);
        }

        if (operation === "Agendar") {

          let status = 5;

          await handleRecordMovemntOS(status, id_user, appointment_id);

          handleUpdateStatusService({
            id: appointment_id,
            id_user,
            id_general_status: status,
          });

          history.goBack()

        } else if (operation === "Iniciar") {

          let status = 2;
          await handleRecordMovemntOS(status, id_user, appointment_id);

          handleUpdateStatusService({
            id: appointment_id,
            id_user,
            id_general_status: status,
          });

          history.goBack()

        } else if (operation === "Finalizar") {

          const check = checkForms(2);

          if (!check) {

            message.error({
              type: 'Atenção',
              content: 'Antes de finalizar a OS, verifique se existem checkists a serem respondidos!',
            });

          } else {

            let status = 3;
            await handleRecordMovemntOS(status, id_user, appointment_id);

            handleUpdateStatusService({
              id: appointment_id,
              id_user,
              id_general_status: status,
            });

            history.goBack()
          }

        } else if (operation === "Cancelar") {
          Swal.fire({
            title: "Atenção",
            text: "Você deseja realmente cancelar a OS ?",
            icon: "warning",
            showDenyButton: true,
            focusConfirm: false,
            denyButtonText: "Não",
            confirmButtonText: "Sim, Cancelar",
            confirmButtonColor: "rgb(238, 26, 26)",
            denyButtonColor: "#40a9ff",
          }).then(async (result) => {
            if (result.isConfirmed) {
              let status = 4;

              await handleRecordMovemntOS(status, id_user, appointment_id);

              handleUpdateStatusService({
                id: appointment_id,
                id_user,
                id_general_status: status,
              });
            } else {
              return false;
            }
          });
        } else if (operation === "Aberto") {
          Swal.fire({
            title: "Atenção",
            text: "Você deseja realmente reabrir a OS ?",
            icon: "warning",
            showDenyButton: true,
            focusConfirm: false,
            denyButtonText: "Não",
            confirmButtonText: "Sim, reabrir",
            confirmButtonColor: "#40a9ff",
            denyButtonColor: "rgb(238, 26, 26)",
          }).then(async (result) => {
            if (result.isConfirmed) {
              let status = 2;

              await handleRecordMovemntOS(status, id_user, appointment_id);

              handleUpdateStatusService({
                id: appointment_id,
                id_user,
                id_general_status: status,
              });
            } else {
              return false;
            }
          });
        }
      })
      .catch((err) => { message.error("Oops... Erro ao gravar as alterações!") })
      .finally(() => setLoading(false));

    dispatch(setAppointmentDefaultPhotos([]));
  };

  /**
   * Função responsavel por alterar o status no kanban e enviar para tela do kanban
   */
  const handleUpdateStatusService = (data) => {

    setLoading(true);

    ServicesService.putService(
      {
        id_general_status: data.id_general_status,
        sector_id_last_change: getSectorId(
          window.location.pathname,
          signin?.user?.currentModule?.id_sector
        ),
        is_blocked_default: false,
        id_user: data.id_user,
      },
      data.id
    )
      .then(() => {

        message.success("Status alterado com sucesso!");

        setSendTaskToFirebase(true);
      })
      .catch(() => {

        message.error("Erro ao alterar o status");
        setLoading(false);

      })
      .finally(() => {
        listTasks();
        setSave(true);
        //setLoading(false);
      });
  };

  const getUserPatchFieldName = () => {
    const fieldIdData = settingServiceFieldsBinding.find(
      (x) => x.settingServiceId === Number(setting_service_id)
    ).IdAgente;
    return settingServiceFields.find((x) => x.id === fieldIdData)?.FieldValues[0]?.value;
  };

  /**
   * 
   * @returns retorna o id do usuário selecionado
   */
  const getUserIdBySettingService = () => {

    const IdCampoAgenteSelecionado = settingServiceFieldsBinding.find(
      (x) => x.settingServiceId === Number(setting_service_id)
    ).IdAgente;

    const IdDoAgente = settingServiceFields.find((x) => x.id === IdCampoAgenteSelecionado).FieldValues[0]
      .value;

    return IdDoAgente;
  };
  /**
   *
   * @returns encontra o tag name do campo que fica o id do user
   */
  const getNameFieldUserIdBySettingService = () => {

    const IdCampoDoAgente = settingServiceFieldsBinding.find(
      (x) => x.settingServiceId === Number(setting_service_id)
    ).IdAgente;

    const tagNameCampoAgente = settingServiceFields.find((x) => x.id === IdCampoDoAgente)?.tag_name;

    return tagNameCampoAgente;

  };

  /**
   *
   * @returns encontra id do campo que usa um id user vinculado a OS (para que seja realizada a atualização no app)
   */
  const getIdFieldUserIdBySettingService = () => {

    return settingServiceFieldsBinding.find(

      (x) => x.settingServiceId === Number(setting_service_id)

    )?.IdAgente;

  };

  /**
   * @returnspega a data que será realizado o agendamento
   **/
  const getDataAgendamentoBySettingService = () => {

    const fieldIdData = settingServiceFieldsBinding.find(
      (x) => x.settingServiceId === Number(setting_service_id)
    )?.IdData;

    const dataAgendamento = settingServiceFields.find((x) => x.id === fieldIdData).FieldValues[0]
      .value;

    return dataAgendamento;
  };

  const handleHtml = (value) => setHtml(value.replaceAll("<br>", "\n"));

  // Identifica o valor da tag para substituir o nome pelo valor
  const handleHtmlTag = (value) => {
    do {
      let value_tag = "";

      // busca tags no código
      const checkTagInCode = getTagInCode(
        value.substring(value.indexOf("{") + 2, value.indexOf("}")).trim(),
        signin.user.userCompanies.find(
          (value) => value.id === signin.user.currentCompany
        ).name
      );

      if (checkTagInCode) {
        value_tag = checkTagInCode.value;
      } else {
        // busca as tags de cada campo da OS
        let tag = fieldsRenderTag.filter(
          (data) =>
            data.tag_name.trim() ===
            value.substring(value.indexOf("{") + 2, value.indexOf("}")).trim()
        );

        if (tag && tag.length !== 0) {
          tag = tag[0];
          if (tag.FieldValues.length === 0) {
            value_tag = "";
          } else {
            value_tag = tag.FieldValues[0].value;

            try {
              if (moment(value_tag).isValid()) {
                value_tag = new String(
                  moment(value_tag).format("DD/MM/YYYY HH:mm")
                );
              } else {
                value_tag = tag.FieldValues[0].value;
              }
            } catch (e) {
              value_tag = tag.FieldValues[0].value;
            }
          }
        } else {
          // busca tags fixas do protheus
          try {
            tag = value
              .substring(value.indexOf("{") + 2, value.indexOf("}"))
              .trim()
              .split("/");

            let key = detailsProtehusData[tag[0]];
            let value_key = key[tag[1]];

            if (moment(value_key).isValid()) {
              value_tag = new String(
                moment(value_tag).format("DD/MM/YYYY HH:mm")
              );
            } else {
              value_tag = value_key;
            }
          } catch (e) {
            value_tag = "";
          }
        }
      }

      value = value.replaceAll(
        value.substring(value.indexOf("{"), value.indexOf("}") + 2),
        value_tag.bold()
      );
    } while (value.includes("{"));

    return value;
  };

  // busca o ID do usuário caso exista
  const getIdUser = async (value, option, field) => {

    try {
      if (field.get_list && field.tag_function === "getUsers") {
        let idUser = parseInt(value.split("-")[0]);
        if (isNaN(idUser)) throw false;

        const user = await listUserIdApi.listUserId(idUser);
        setIdUserOs(user.id);
      } else {
        setIdUserOs(signin.user.id);
      }
    } catch (e) {
      setIdUserOs(signin.user.id);
      return false;
    }
  };

  // ordena os campos por sequencia
  const sortData = (data) =>
    data
      .filter((value) => value && value)
      .sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence));

  // renderiza os campos criados pelo usuário
  const renderFieldsSettingService = (field) => {

    let tag;

    const tagsHtml = [
      false,
      <Input
        placeholder={`Digite aqui o(a) ${field.name}`}
        disabled={field.is_automatic || appointment?.status === "Finalizado" || id_general_status === "3"}
      />,
      <InputNumber style={{ width: "100%" }} disabled={field.is_automatic || appointment?.status === "Finalizado" || id_general_status === "3"} />,
      isMobile || isMobileParam === 1 ? (
        <DatePicker
          locale={locale}
          showTime={{ format: "HH:mm" }}
          format="DD/MM/YYYY HH:mm"
          placeholder={field.name}
          disabled={field.is_automatic || appointment?.status === "Finalizado" || id_general_status === "3"}
          style={{ width: 300 }}
        />
      ) : (
        /*  <input type="datetime-local" className="w-100" /> */
        <DatePicker
          locale={locale}
          showTime={{ format: "HH:mm" }}
          format="DD/MM/YYYY HH:mm"
          placeholder={field.name}
          disabled={field.is_automatic || appointment?.status === "Finalizado" || id_general_status === "3"}
          style={field.size === "100" ? { width: 700 } : { width: 350 }}
        />
      ),
      <Input placeholder="Digite o título" disabled={field.is_automatic || appointment?.status === "Finalizado" || id_general_status === "3"} />,
      <>
        {field.SettingServiceFieldChoices &&
          field.SettingServiceFieldChoices.length !== 0
          ? field.SettingServiceFieldChoices.map((choice) => (
            <Radio
              key={choice.id}
              value={choice.id}
              disabled={field.is_automatic || appointment?.status === "Finalizado" || id_general_status === "3"}
            >
              {choice.description}
            </Radio>
          ))
          : null}
      </>,
      <Select
        optionFilterProp="children"
        disabled={field.is_automatic || appointment?.status === "Finalizado" || id_general_status === "3"}
        onChange={(value, option) => getIdUser(value, option, field)}
        showSearch
      >
        {optionSelect(field)}
      </Select>,
      <>
        {field.SettingServiceFieldChoices &&
          field.SettingServiceFieldChoices.length !== 0
          ? field.SettingServiceFieldChoices.map((choice) => (
            <Checkbox disabled={field.is_automatic || appointment?.status === "Finalizado" || id_general_status === "3"}>
              {choice.description}
            </Checkbox>
          ))
          : null}
      </>,
      <Editor
        handleHtml={handleHtml}
        minimalButtons={true}
        disabled={field.is_automatic || appointment?.status === "Finalizado" || id_general_status === "3"}
        html={{
          html:
            field.FieldValues.length !== 0 ? field.FieldValues[0].value : false,
        }}
      />,
    ];

    // checa se existe resposta no campo
    let value = "";
    try {
      if (field && field.FieldValues.length !== 0) {
        if (field.id_type === 3) {
          let initial_value =
            field.FieldValues && field.FieldValues.length !== 0
              ? field.FieldValues[0].value
              : false;

          if (initial_value) {
            initial_value = moment(initial_value);
            initial_value = initial_value.format("DD/MM/YYYY HH:mm");
            initial_value = initial_value.split(" ");

            value = moment(initial_value, "DD/MM/YYYY HH:mm");

            // if(isMobile) value = new Date(value).toISOString();
          } else {
            value = "";
          }
        } else {
          // verifica se o campo de usuário está preenchendo
          if (field.get_list && field.tag_function === "getUsers") {
            let value_field = field.FieldValues[0].value;
            getIdUser(value_field, null, field);
          }

          if (field.get_list) {


            let user = field.users.filter((user) => user.id.toString() === field.FieldValues[0].value)

            if (user === undefined || user === null) {
              user = field.users.filter((user) => user.name.toString() === field.FieldValues[0].value)
            }


            value = user[0].name


          } else {

            value = field.FieldValues[0].value;

          }
        }
      }
    } catch (e) {
      value = "";
      console.log("catch no carregamento dos valores: " + e);
    }

    let field_name =
      field.id_type === 8
        ? `${field.tag_name}_${field.id_type}`
        : field.tag_name;


    if (isMobile || isMobileParam === 1) {
      tag = (
        <Col xs={24}>
          <Form.Item
            name={field_name}
            label={field.name}
            rules={[
              { required: field.is_automatic ? false : field.is_required, message: `Este campo é obrigatório!` },
              {
                validator: (_, value) => {
                  // condição para evitar que o campo seja validado quando o usuário não preencheu o campo
                  if (value === "" || value === undefined || value === null) {
                    return Promise.resolve();
                  }

                  if (field.rules.length) {
                    const rules = field.rules.map((r) => {
                      const { expression, message } = r.validation;
                      let dependentFieldValue;

                      if (r.depends_on_field) {
                        const fieldValue = form.getFieldValue(
                          r.depends_on_field.tag_name
                        );
                        dependentFieldValue = convertFieldValue(
                          fieldValue,
                          r.depends_on_field.id_type
                        );
                      }

                      return {
                        expression,
                        message,
                        dependent: {
                          name: r.depends_on_field?.name,
                          value: dependentFieldValue,
                        },
                      };
                    });

                    const { errors, isValid } = validateField({
                      name: field.name,
                      value: convertFieldValue(value, field.id_type),
                      rules,
                    });

                    if (!isValid)
                      return Promise.reject(errors[0].helpMessage);
                  }
                  return Promise.resolve();
                },
              },
            ]}
            initialValue={value}
          >
            {tagsHtml[field.id_type]}
          </Form.Item>
        </Col>
      );
    } else {
      if (field.size === "100") {
        tag = (
          <Col span={24}>
            <Form.Item
              name={field_name}
              label={field.name}
              rules={[
                { required: field.is_automatic ? false : field.is_required, message: `Este campo é obrigatório!` },
                {
                  validator: (_, value) => {
                     // condição para evitar que o campo seja validado quando o usuário não preencheu o campo
                    if (value === "" || value === undefined || value === null) {
                      return Promise.resolve();
                    }

                    if (field.rules.length) {
                      const rules = field.rules.map((r) => {
                        const { expression, message } = r.validation;
                        let dependentFieldValue;

                        if (r.depends_on_field) {
                          const fieldValue = form.getFieldValue(
                            r.depends_on_field.tag_name
                          );
                          dependentFieldValue = convertFieldValue(
                            fieldValue,
                            r.depends_on_field.id_type
                          );
                        }

                        return {
                          expression,
                          message,
                          dependent: {
                            name: r.depends_on_field?.name,
                            value: dependentFieldValue,
                          },
                        };
                      });

                      const { errors, isValid } = validateField({
                        name: field.name,
                        value: convertFieldValue(value, field.id_type),
                        rules,
                      });

                      if (!isValid)
                        return Promise.reject(errors[0].helpMessage);
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              initialValue={value}
            >
              {tagsHtml[field.id_type]}
            </Form.Item>
          </Col>
        );
      } else if (field.size === "50") {

        tag = (
          <Col span={12}>
            <Form.Item
              name={field_name}
              label={field.name}
              rules={[
                { required: field.is_automatic ? false : field.is_required, message: `Este campo é obrigatório!` },
                {
                  validator: (_, value) => {
                    // condição para evitar que o campo seja validado quando o usuário não preencheu o campo
                    if (value === "" || value === undefined || value === null) {
                      return Promise.resolve();
                    }

                    if (field.rules.length) {
                      const rules = field.rules.map((r) => {
                        const { expression, message } = r.validation;
                        let dependentFieldValue;

                        if (r.depends_on_field) {
                          const fieldValue = form.getFieldValue(
                            r.depends_on_field.tag_name
                          );
                          dependentFieldValue = convertFieldValue(
                            fieldValue,
                            r.depends_on_field.id_type
                          );
                        }

                        return {
                          expression,
                          message,
                          dependent: {
                            name: r.depends_on_field?.name,
                            value: dependentFieldValue,
                          },
                        };
                      });

                      const { errors, isValid } = validateField({
                        name: field.name,
                        value: convertFieldValue(value, field.id_type),
                        rules,
                      });

                      if (!isValid)
                        return Promise.reject(errors[0].helpMessage);
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              initialValue={value}
            >
              {tagsHtml[field.id_type]}
            </Form.Item>
          </Col>
        );
      }
    }


    return <>{tag}</>;
  };

  // array com os campos que serão renderizados
  const getFieldsForRender = (fields) => {
    let fieldsRender = [];
    let fieldsRenderSecondary = [];

    // let newFields = sortData(fields.filter((value) => value.SettingServiceField?.active === true).map((value) => value.SettingServiceField));

    let newFields = sortData(fields);

    setFieldsRenderTag(newFields);

    // newFields = newFields.filter(
    //   (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    // );

    newFields?.map((field) => {
      if (field?.active && field.is_visible && !field.extra_field) {
        fieldsRender.push(renderFieldsSettingService(field));
      }
    });

    setFieldsRender(<>{fieldsRender}</>);
    setFieldsRenderSecondary(<>{fieldsRenderSecondary}</>);

    if (fieldsRender.length === 0)
      setFieldsRender(
        <Col span={24}>
          <Empty description="Campos não configurados." />
        </Col>
      );

    if (fieldsRenderSecondary.length === 0) setFieldsRenderSecondary(false);
  };

  // busca novamente os dados dos checklists respondidos
  const resetIsForm = async (item) => {
    if (item) {
      setLoading(true);

      setTimeout(() => {
        document.location.reload(true);
      }, 5000);
    }
  };

  // Inativa a OS no banco de dados para o setor especifico
  const removeOS = () => {
    Swal.fire({
      title: "Atenção",
      text: `Você está deletando a OS de número: ${appointment_id}, deseja continuar ? `,
      icon: "warning",
      showDenyButton: true,
      focusConfirm: false,
      denyButtonText: "Cancelar",
      confirmButtonText: "Sim, deletar",
      confirmButtonColor: "#1890ff",
      denyButtonColor: "rgb(238, 26, 26)",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await ServicesService.changeActiveOsStatus(
          appointment_id,
          signin.user.id
        );
        return history.goBack();
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue(
      settingServiceFields.map((field) => ({
        [field.tag_name]: field.FieldValues[0]?.value,
      }))
    );
  }, [settingServiceFields])

  return (
    <Main
      key={props.moduleName}
      moduleName={props.moduleName}
      breadcrumb={isMobile || isMobileParam === 1 ? false : breadcrumb}
      collapsed={true}
      displayMenuButtons={"flex"}
      buttonHeader={
        <>
          <Button
            size="middle"
            icon={<LeftOutlined />}
            onClick={() => history.goBack()}
            className="button-details space-button-details"
            style={{ marginRight: 10 }}
          >
            Voltar
          </Button>
          {!isMobile && isMobileParam === undefined ? (
            <Popconfirm
              title="Deseja deletar está OS?"
              onConfirm={() => removeOS()}
              okText="Sim"
              cancelText="Não"
            >
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                size="middle"
                style={deleteOS()}
              >
                Deletar OS
              </Button>
            </Popconfirm>
          ) : null}

          {!isMobile && isMobileParam === undefined ? (
            <Button
              type="primary"
              size="middle"
              icon={<ContainerOutlined />}
              onClick={() => dispatch(modalProtheus(true))}
              className="button-details space-button-details"
              style={
                isMobile || isMobileParam === 1
                  ? { marginRight: 10 }
                  : { marginRight: 0 }
              }
            >
              Detalhes
            </Button>
          ) : null}

          {isMobile || isMobileParam === 1 ? (
            <Button
              size="middle"
              icon={<MenuOutlined />}
              onClick={() => dispatch(openSecondaryDrawerData(true))}
              style={
                isMobile || isMobileParam === 1
                  ? { marginRight: 10, marginTop: 5 }
                  : { marginRight: 0, marginTop: 0 }
              }
            ></Button>
          ) : null}
        </>
      }
    >
      {loading ? (
        <Loading />

      ) : (
        <>
          <Row gutter={[16, 16]}>
            <Col md={16}>
              <Form
                form={form}
                name="tannate_enable"
                onFinish={handleSubmitForm}
                layout="vertical"
              >
                <Tabs defaultActiveKey="1" size="small" type="card">
                  <TabPane tab="Informações da OS" key="1">
                    <Row gutter={[8, 8]}>{fieldsRender}</Row>
                    {appointment?.SettingService?.is_movements_os &&
                      appointment?.status !== "Finalizado" ? (
                      <Row>
                        <OsMovements service_id={appointment_id} />
                      </Row>
                    ) : null}
                  </TabPane>
                  {fieldsRenderSecondary ? (
                    <TabPane tab="Informações extra" key="2">
                      <Row gutter={[8, 8]}>{fieldsRenderSecondary}</Row>
                    </TabPane>
                  ) : null}
                </Tabs>
              </Form>
            </Col>

            {/* Divisão entre as telas de formulário e informação do settings service */}

            <DrawerData
              position="right"
              title={formComponents.title}
              render={
                <FormComponent
                  resetIsForm={(item) => resetIsForm(item)}
                  readonly={false}
                />
              }
              width={isMobile || isMobileParam === 1 ? 400 : 1000}
            />

            {isMobile || isMobileParam === 1 ? (
              <DrawerSecondary
                position="right"
                title="Detalhes"
                width={"100%"}
                render={
                  <Collapse
                    panel={data}
                    pageheader={true}
                    register={register}
                    handleData={handleCollapse}
                  />
                }
              />
            ) : (
              <Col md={7} offset={1}>
                <Collapse
                  panel={data}
                  pageheader={true}
                  register={register}
                  handleData={handleCollapse}
                />
              </Col>
            )}
          </Row>

          {isMobile || isMobileParam === 1 ? (
            <Col md={16} className={`buttonGroups`}>
              <Popconfirm
                title="Deseja deletar está OS?"
                onConfirm={() => removeOS()}
                okText="Sim"
                cancelText="Não"
              >
                <Button
                  type="danger"
                  icon={<DeleteOutlined />}
                  size="middle"
                  style={deleteOS()}
                >
                  Deletar OS
                </Button>
              </Popconfirm>

              <Button
                type="primary"
                size="middle"
                icon={<ContainerOutlined />}
                onClick={() => dispatch(modalProtheus(true))}
                className="button-details space-button-details"
                style={
                  isMobile || isMobileParam === 1
                    ? { marginRight: 10 }
                    : { marginRight: 0 }
                }
              >
                Detalhes
              </Button>
            </Col>
          ) : null}

          <Col
            md={16}
            className={`buttonGroups ${isMobile || isMobileParam === 1 ? "mb-5" : ""
              } `}
          >
            <Row style={{ rowGap: "1em" }}>
              {appointment?.status === "Aberto" ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  form="tannate_enable"
                  onClick={() => setOperation("Agendar")}
                >
                  Agendar
                </Button>
              ) : null}

              {appointment?.status === "Aberto" &&
                taskIsInServiceQueue === false ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  form="tannate_enable"
                  style={{ marginLeft: 5 }}
                  onClick={() => setOperation("Liberar")}
                >
                  Liberar para atendimento
                </Button>
              ) : null}

              {taskIsInServiceQueue === true ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  form="tannate_enable"
                  style={{ marginLeft: 5 }}
                  onClick={() => setOperation("RemoverDaFila")}
                >
                  Remover do atendimento
                </Button>
              ) : null}

              {appointment?.status === "Aberto" ||
                appointment?.status === "Agendado" ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  form="tannate_enable"
                  style={{ marginLeft: 5 }}
                  onClick={() => setOperation("Iniciar")}
                >
                  Iniciar
                </Button>
              ) : null}

              {appointment?.status === "Finalizado" ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  form="tannate_enable"
                  style={voltarOS()}
                  onClick={() => handleRollback()}
                >
                  Reabrir OS
                </Button>
              ) : null}

              {appointment?.status === "Iniciado" ||
                (appointment?.status === "Agendado") ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  form="tannate_enable"
                  style={{ marginLeft: 5 }}
                  onClick={() => setOperation("Salvar")}
                >
                  Salvar
                </Button>
              ) : null}

              {appointment?.status === "Iniciado" ? (
                <>
                  <Button
                    htmlType="submit"
                    shape="round"
                    style={{ marginLeft: 5 }}
                    type="primary"
                    form="tannate_enable"
                    onClick={() => setOperation("Finalizar")}
                  >
                    Finalizar
                  </Button>
                </>
              ) : null}

              {appointment?.status === "Cancelado" ? (
                <Button
                  htmlType="submit"
                  shape="round"
                  type="primary"
                  form="tannate_enable"
                  onClick={() => setOperation("Aberto")}
                >
                  Reabrir OS
                </Button>
              ) : null}
            </Row>
          </Col>
        </>
      )}
      <ModalProtheusData />
      <SecondaryModalApp title={htmlDocument.name} width={1000} footer={null}>
        <Editor
          html={htmlDocument}
          minimalButtons={false}
          disabled={true}
          handleHtml={handleHtmlTag}
        />
      </SecondaryModalApp>

      <AttachmentsModal attachments={attachments} visible={openModalAttachment} setVisible={setOpenModalAttachment} initialAttachment={currentAttachment} />
    </Main>
  );
}

export default AppointmentsWithParams;
