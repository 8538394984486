export function checkControl(profiles, className) {
  let visible = {
    display: "none",
  };

  if (profiles && profiles.length !== 0) {
    profiles.map((profile) => {
      profile.resources.map((resource) => {
        if (resource.class_tag === className) visible.display = "";
      });
    });
  }

  return visible;
}
