import api from "../utils/api"

export const createPaymentPixMutation = async ({ params, headers }) => {
  const result = await api.post(`/payment-pix`, params, {
    headers,
  });
  return result.data;
}

export const getPaymentsPixQuery = async (params) => {
  const result = await api.get(`/payment-pix?${new URLSearchParams(params)}`)
  return result.data
}

export const deletePaymentPixMutation = async ({ params, headers }) => {
  const result = await api.delete(`/payment-pix`, {
    data: params,
    headers,
  });
  return result.data;
};

export const resendEmailPaymentPixMutation = async (params) => {
  const result = await api.post(`/payment-pix/resendemail`, params);
  return result.data;
};
