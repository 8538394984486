import { Input, Row, Col, Form, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';



function FieldsAutorization(props) {

  const parentesco = [
    { value: "AFILHADO(A)", label: "AFILHADO(A)" },
    { value: "AMIGA (O)", label: "AMIGA (O)" },
    { value: "AVO", label: "AVO" },
    { value: "BISNETO(A)", label: "BISNETO(A)" },
    { value: "CONJUGE", label: "CONJUGE" },
    { value: "CUNHADA(O)", label: "CUNHADA(O)" },
    { value: "ENTEADO(A)", label: "ENTEADO(A)" },
    { value: "ESPECIAL", label: "ESPECIAL" },
    { value: "FILHO(A)", label: "FILHO(A)" },
    { value: "GENRO/NORA", label: "GENRO/NORA" },
    { value: "IRMA(O)", label: "IRMA(O)" },
    { value: "MAE", label: "MAE" },
    { value: "NETO(A)", label: "NETO(A)" },
    { value: "OUTROS", label: "OUTROS" },
    { value: "PADRASTO/MADRASTA", label: "PADRASTO/MADRASTA" },
    { value: "PAI", label: "PAI" },
    { value: "PRIMO(A)", label: "PRIMO(A)" },
    { value: "SOBRINHO(A)", label: "SOBRINHO(A)" },
    { value: "SOGRO(A)", label: "SOGRO(A)" },
    { value: "TIO(A)", label: "TIO(A)" },
    { value: "TITULAR", label: "TITULAR" },
    { value: "PORTEIRO", label: "PORTEIRO" },
    { value: "VIZINHO", label: "VIZINHO" },
    { value: "CAIXA DE CORREIO", label: "CAIXA DE CORREIO" },
    { value: "PET", label: "PET" },
    { value: "NORA", label: "NORA" },
    { value: "PADRINHO", label: "PADRINHO" },
    { value: "MADRINHA", label: "MADRINHA" },
    { value: "AMIGO(A) DA FAMILIA", label: "AMIGO(A) DA FAMILIA" },
    { value: "AMIGO(A) DO TRABALHO", label: "AMIGO(A) DO TRABALHO" },
    { value: "COLEGA DE TRABALHO", label: "COLEGA DE TRABALHO" },
    { value: "COLEGA DE FACULDADE", label: "COLEGA DE FACULDADE" },
    { value: "ALUNO(A)", label: "ALUNO(A)" },
    { value: "PROFESSOR(A)", label: "PROFESSOR(A)" }
  ];

  const companies = [
    {
      value: "000006 - MORADA DA PAZ RECIFE",
      label: "000006 - MORADA DA PAZ RECIFE"
    },
    {
      value: "000007 - MORADA DA PAZ JP",
      label: "000007 - MORADA DA PAZ JP"
    },
    {
      value: "000008 - A VIAGEM (CAMPINA GRANDE)",
      label: "000008 - A VIAGEM (CAMPINA GRANDE)"
    },
    {
      value: "000010 - FUNERÁRIA CAMINHO DO CEU (PARELHAS)",
      label: "000010 - FUNERÁRIA CAMINHO DO CEU (PARELHAS)"
    },
    {
      value: "000013 - SAFAM MASTER (MOSSORÓ)",
      label: "000013 - SAFAM MASTER (MOSSORÓ)"
    },
    {
      value: "000028 - MORADA DA PAZ EMAÚS",
      label: "000028 - MORADA DA PAZ EMAÚS"
    },
    {
      value: "000029 - GRUPO IMPERIAL (GUARABIRA)",
      label: "000029 - GRUPO IMPERIAL (GUARABIRA)"
    },
    {
      value: "000030 - MORADA DA PAZ GUARABIRA",
      label: "000030 - MORADA DA PAZ GUARABIRA"
    },
  ]

  return (
    <div>
      <Form.Item
        label="Declarante:"
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          },
        ]}
        name="declarant">
        <Input />
      </Form.Item>

      <Form.Item
        label={"Parentesco do declarante"}
        name={"kinship"}
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          }
        ]}
      >
        <Select options={parentesco} />
      </Form.Item>

      <Row justify={"space-between"}>
        <Form.Item
          label="Telefone 1:"
          rules={[
            {
              required: true,
              message: "Campo obrigatório",
            },
          ]}
          name="phone1">
          <MaskedInput
            name="phone1"
            mask={"(00) 00000-0000"}
          />
        </Form.Item>

        <Form.Item
          label="Telefone 2:"
          rules={[
            {
              required: true,
              message: "Campo obrigatório",
            },
          ]}
          name="phone2">
          <MaskedInput
            name="phone2"
            mask={"(00) 00000-0000"}
          />
        </Form.Item>
      </Row>

      <Form.Item
        label="Local do Falecimento:"
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          },
        ]}
        name="locale_death">
        <Input />
      </Form.Item>

      <Form.Item
        label="Funerária:"
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          },
        ]}
        name="funerary">
        <Select options={companies} />
      </Form.Item>

      <Form.Item
        label="Motivo da Morte:"
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          },
        ]}
        name="reason_death">
        <Select options={[{
          value: "Outros tipos de morte (Doenças, Aborto, Suicídio, entre outros).",
          label: "Outros tipos de morte (Doenças, Aborto, Suicídio, entre outros)."
        }, {
          value: "Morte acidental (Evento com data caracterizada e imprevisível).",
          label: "Morte acidental (Evento com data caracterizada e imprevisível)."
        }
        ]} />
      </Form.Item>

      <Form.Item
        label="Gênero do Falecido:"
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          },
        ]}
        name="gender">
        <Select options={[{
          value: "Masculino",
          label: "Masculino"
        }, {
          value: "Feminino",
          label: "Feminino"
        }
        ]} />
      </Form.Item>

      <Row justify={"space-between"}>
        <Form.Item
          label="Peso:"
          name="weight">
          <Input />
        </Form.Item>

        <Form.Item
          label="Altura:"
          name="height">
          <Input />
        </Form.Item>
      </Row>

      <Form.Item
        label="Religião:"
        name="religion">
        <Input />
      </Form.Item>

      <Form.Item
        label="Declaração de obito?"
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          },
        ]}
        name="death_certificate">
        <Select options={[{
          value: true,
          label: "Sim"
        }, {
          value: false,
          label: "Não"
        }
        ]} />
      </Form.Item>

      <Form.Item
        label="Local do Velório:"
        name="place_funeral">
        <Input />
      </Form.Item>

      <Form.Item
        label="Local do Sepultamento:"
        name="place_burial">
        <Input />
      </Form.Item>

      <Form.Item
        label="Tipo de Óbito"
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          },
        ]}
        name="death_type">
        <Select options={[{
          value: "Suspeito Covid",
          label: "Suspeito Covid"
        }, {
          value: "Covid Confirmado",
          label: "Covid Confirmado"
        }, {
          value: "Casos Gerais",
          label: "Casos Gerais"
        }
        ]} />
      </Form.Item>

      <Form.Item
        label="Observações"
        name="obs">
        <Input />
      </Form.Item>
    </div>
  )
}


export default FieldsAutorization;