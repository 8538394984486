import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import "./styles.css";

import {
  Row,
  Col,
  Form,
  Select,
  Button,
  message,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Checkbox,
  Skeleton,
  List,
  Avatar,
  Empty,
  Divider,
  notification,
  Tabs,
  Popconfirm,
} from "antd";

import {
  ContainerOutlined,
  FormOutlined,
  FilePdfOutlined,
  LeftOutlined,
  MenuOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import OsMovements from "../OsMovements";
import "moment/locale/pt-br";
import locale from "antd/es/date-picker/locale/pt_BR";
import moment from "moment";

import { isMobile } from "../../utils/isMobile";

import { isOwner } from "../../utils/owner";

import ProtheusSales from "../ProtheusSales";
import { appointmentsApi } from "../../services/appointments";
import { appointmentsApiProtheus } from "../../services/appointmentsProtheus";
import { ServicesService } from "../../services/services";
import { awsApi } from "../../services/aws";
import { showFormApi } from "../../services/forms";
import { recordMovementsApi } from "../../services/recordMovementsOs";
import { listUserIdApi } from "../../services/users";

import { openDrawer, openSecondaryDrawerData } from "../../store/ducks/drawer";
import {
  alterFormComponent,
  responseFormComponent,
} from "../../store/ducks/formComponent";

import {
  removeAppointment,
  setAppointment,
  setAppointmentDefaultPhotos,
} from "../../store/ducks/appointments";

import Main from "../../pages/Layout/Main";
import Collapse from "../Collapse";
import DrawerData from "../Drawer";
import DrawerSecondary from "../DrawerSecondary";

import Editor from "../RichText";
import FormComponent from "../FormComponent";

import ModalProtheusData from "../ModalProtheusData";
import { getUsers } from "./functions";

import {
  modalProtheus,
  openModalSecondary,
} from "../../store/ducks/modalGlobal";
import {
  setData,
  setLoadingProtheusData,
} from "../../store/ducks/modalProtheusData";

import Swal from "sweetalert2";

import { saveAs } from "file-saver";
import { convertNameInTagName } from "../../utils/automatic_response";
import { SecondaryModalApp } from "../SecondaryModal";
import { bin2string } from "../../utils/text";
import { getTagInCode } from "../../utils/tagsInCode";
import { TasksOrderService } from "../../services/tasksOrderService";
import Loading from "../Loading";

const { Option } = Select;
const { TabPane } = Tabs;

/**
 * Formata a data de registro do serviço para retornar no formato dd/mm/yyyy
 */
const formatingDate = (value) => {
  const date = new Date(value);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  const formatted = `${day}/${month}/${year}`;

  return formatted;
};

const formatingHours = (value) => {
  const date = new Date(value);

  const hours = date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours();
  const minutes =
    date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes();

  return `${hours}:${minutes}`;
};

function Appointments(props) {
  const signin = useSelector((state) => state.signin);
  const appointment = useSelector((state) => state.appointment.appointment);
  const formComponents = useSelector(
    (state) => state.formComponent.formComponent
  );

  const [mobile, setMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSecondary, setLoadingSecondary] = useState(false);
  const [loadingChecklist, setLoadingChecklist] = useState(false);
  const [operation, setOperation] = useState("");
  const [fieldsRender, setFieldsRender] = useState([]);
  const [fieldsRenderSecondary, setFieldsRenderSecondary] = useState();
  const [settingServiceFields, setSettingServiceFields] = useState([]);
  const [html, setHtml] = useState("");
  const [formStart, setFormStart] = useState([]);
  const [formStartResponse, setFormStartResponse] = useState([]);
  const [detailsProtehusData, setDetailsProtehusData] = useState({});
  const [attachments, setAttachments] = useState({});
  const [idUserOs, setIdUserOs] = useState(0);
  const [htmlDocument, setHtmlDocument] = useState("");
  const [fieldsRenderTag, setFieldsRenderTag] = useState([]);
  const [save, setSave] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (save) {
      dispatch(responseFormComponent(false));
      getFields();
      isForm();
      detailsProtheus();
      getDocumentsBySettingService();
      setMobile(isMobile());

      setSave(false);
    }
  }, [save]);

  /**
   * Busca os dados da OS novamente para atualizar a pagina
   */
  function listTasks(paramFilter) {
    setLoading(true);

    TasksOrderService.getTasks(
      signin.user.currentModule.id_sector,
      appointment.SettingService.id,
      signin.user.currentCompany,
      paramFilter
    )
      .then((res) => {
        const os = res.filter((value) => value.Services.length !== 0);

        dispatch(
          setAppointment({
            ...os?.[0]?.Services?.[0],
            status: os?.[0].description,
          })
        );
      })
      .catch(() => false)
      .finally(() => setLoading(false));
  }

  /**
   * Busca os anexos da base de atendimento
   */
  const getDocumentsBySettingService = async () => {
    setAttachments(appointment.Order.BaseService.ServiceDocuments);
  };

  /**
   * Busca os dados do protheus que alimentam os detalhes
   */
  const detailsProtheus = () => {
    setLoading(true);
    setLoadingSecondary(true);
    dispatch(setLoadingProtheusData(true));

    const { code_erp: subsidiary_erp, Hierarquia } = signin.user.userCompanies.find(
      (company) => company.id === signin.user.currentCompany
    );

    appointmentsApiProtheus
      .getDetailsAttendanceProtheus(
        appointment.Order.BaseService.num_attendance_erp,
        {
          headers: {
            tenantId: `${Hierarquia.code_erp},${subsidiary_erp}`,
          },
        }
      )
      .then((response) => {
        setDetailsProtehusData(response);
        dispatch(setData(response));
      })
      .catch(() => {
        setDetailsProtehusData({});
        dispatch(setData({}));
      })
      .finally(() => {
        setLoading(false);
        setLoadingSecondary(false);
        dispatch(setLoadingProtheusData(false));
      });
  };

  const handleDownloadDocumento = async (document) => {
    try {
      const result = await awsApi.fetchFile(document.path);

      saveAs(result);
    } catch (error) {
      message.error(error);
    }
  };

  /**
   * Monta o collapse para identificar os dados gerais do atendimento
   */
  const GeneralDateAttendance = () =>
    loadingSecondary ? (
      <Loading />
    ) : !detailsProtehusData.ATENDIMENTO ? (
      <Empty description="Dados não localizados" />
    ) : (
      <>
        <div className="detailsAttendance">
          <div>
            <h4>Número: </h4>
            <h4>Data: </h4>
            <h4>Tipo: </h4>
            <h4>Falecido: </h4>
            <h4>Data de Nascimento: </h4>
            <h4>Religião: </h4>
            <h4>Data/Hora do óbito: </h4>
            <h4>Local do óbito: </h4>
            <h4>Causa da morte: </h4>
          </div>
          <div className="font detailsAttendanceMargin">
            <p>{detailsProtehusData.ATENDIMENTO.numero}</p>
            <p>{detailsProtehusData.ATENDIMENTO.data_atendimento}</p>
            <p>{detailsProtehusData.ATENDIMENTO.tipo}</p>
            <p>{detailsProtehusData.FALECIDO.nome}</p>
            <p>{detailsProtehusData.FALECIDO.data_nascimento}</p>
            <p>{detailsProtehusData.FALECIDO.religiao}</p>
            <p>
              {detailsProtehusData.FALECIDO.data_obito} -{" "}
              {detailsProtehusData.FALECIDO.hora_obito}{" "}
            </p>
            <p>{detailsProtehusData.FALECIDO.local_obito}</p>
            <p>{detailsProtehusData.FALECIDO.causa_morte}</p>
          </div>
        </div>
        <Divider />
        <div className="detailsAttendance">
          <div>
            <h4>Nome: </h4>
            <h4>E-mail: </h4>
            <h4>Telefone: </h4>
            <h4>Celular: </h4>
          </div>
          <div className="font detailsAttendanceMargin">
            <p>{detailsProtehusData.DECLARANTE.nome}</p>
            <p>{detailsProtehusData.DECLARANTE.email}</p>
            <p>
              {detailsProtehusData.DECLARANTE.ddd}{" "}
              {detailsProtehusData.DECLARANTE.telfixo}
            </p>
            <p>
              {detailsProtehusData.DECLARANTE.ddd}{" "}
              {detailsProtehusData.DECLARANTE.telcel}
            </p>
          </div>
        </div>
        <Divider />
        <h4>
          Produto: {appointment.Product.code ? appointment.Product.code : null}{" "}
          -{" "}
          {appointment.Product.description
            ? appointment.Product.description
            : null}{" "}
        </h4>
      </>
    );

  const titleChecklist = (title, required) => {
    return (
      <>
        {required ? (
          <span style={{ color: "red", marginRight: 5 }}>*</span>
        ) : null}
        <span>{title}</span>
      </>
    );
  };

  const dataCollapse = [
    {
      header: "Checklists",
      key: "1",
      component: loadingChecklist ? (
        <Loading />
      ) : (
        <List
          itemLayout="horizontal"
          size="small"
          dataSource={formStart}
          renderItem={(item) => {
            let checklistsAwser = item.f.filter(
              (e) => e.services_id === appointment.id
            );

            return (
              <List.Item
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (
                    appointment.id_general_status === 3 ||
                    appointment.id_general_status === 4
                  ) {
                    return false;
                  }

                  setLoadingChecklist(true);
                  showFormApi
                    .showFormService(item.id, appointment.id)
                    .then((response) => {
                      dispatch(
                        alterFormComponent({
                          ...response,
                          submission: item,
                          responseForms: formStartResponse,
                        })
                      );
                      dispatch(openDrawer(true));
                    })
                    .finally(() => setLoadingChecklist(false));
                }}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={
                        checklistsAwser.length !== 0
                          ? checklistsAwser[0].status_id === 1
                            ? { backgroundColor: "#1890ff" }
                            : { backgroundColor: "green" }
                          : { backgroundColor: "#a09f9f" }
                      }
                      icon={<FormOutlined />}
                    />
                  }
                  title={titleChecklist(item.title, item.is_required)}
                  description={item.description}
                />
              </List.Item>
            );
          }}
        />
      ),
    },
    {
      header: "Anexos",
      key: "2",
      component: (
        <List
          itemLayout="horizontal"
          dataSource={attachments}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<FilePdfOutlined />}
                title={
                  <Button
                    type="link"
                    size="small"
                    onClick={() => handleDownloadDocumento(item)}
                  >
                    {item.title}
                  </Button>
                }
              />
            </List.Item>
          )}
          pagination={{ pageSize: 5 }}
        />
      ),
    },
    {
      header: "Documentos da OS",
      key: "3",
      component: (
        <List
          itemLayout="horizontal"
          size="small"
          dataSource={appointment.SettingService.template_documents}
          renderItem={(item) => (
            <List.Item
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(openModalSecondary(true));
                setHtmlDocument({
                  ...item,
                  html: handleHtmlTag(bin2string(item.html.data)),
                });
              }}
            >
              <List.Item.Meta
                avatar={<Avatar icon={<FormOutlined />} />}
                title={item.name}
              />
            </List.Item>
          )}
        />
      ),
    },
    {
      header: "Dados do atendimento",
      key: "4",
      component: <GeneralDateAttendance />,
    },
    {
      header: "Detalhes da venda",
      key: "5",
      component: (
        <ProtheusSales
          atendimento={appointment.Order.BaseService.num_attendance_erp}
        />
      ),
    },
  ];

  const register = {
    status: appointment.status,
    register: `${formatingDate(appointment.createdAt)} às ${formatingHours(
      appointment.createdAt
    )}`,
    attendant: appointment.Order.BaseService.name_attendant,
    funerary: appointment.Order.BaseService.name_funerary,
  };

  const breadcrumb = [
    {
      title: props.moduleName,
      path: props.modulePath,
    },
    {
      title: "Serviços",
      path: props.modulePath + "/order-service",
    },
    {
      title: appointment.SettingService.name,
      path: props.modulePath + "/order-service",
    },
    isMobile()
      ? false
      : {
          title: `#${appointment.Order.BaseService.num_attendance_erp} - ${appointment.Order.BaseService.name_deceased}`,
          path: props.modulePath + "/appointments",
        },
  ];

  /**
   * Verifica se existe formulário para ser respondido
   */
  const isForm = async () => {
    let checklists = await appointmentsApi.getAppointmentsChecklist(
      appointment.id,
      appointment.Order.id_sector,
      appointment.id_setting_service
    );

    if (checklists.length === 0) {
      return false;
    }

    setFormStartResponse(checklists);
    checklists = checklists.ServiceForms.map((value) => {
      return {
        ...value.Form,
        is_required: value.is_required,
        fill_moment: value.fill_moment,
      };
    });

    if (checklists.length > 0) {
      checklists.sort((a, b) => {
        return a.id - b.id;
      });
    }

    setFormStart(checklists);
  };

  /**
   * Verifica qual collapse foi chamado
   */
  const handleCollapse = (key) => {
    if (key.includes("3")) {
      // Busca o histórico
    }
  };

  /**
   * grava a movimentação da OS
   */
  const handleRecordMovemntOS = async (status_id, user_id, service_id) => {
    setLoading(true);
    try {
      await recordMovementsApi.addRecordMovements({
        status_id,
        user_id,
        service_id,
        date: moment(),
      });
    } catch (e) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Define qual função deve chamar para montar os Options
   */
  const optionSelect = (field) => {
    if (field.get_list) {
      if (field.tag_function === "getUsers") {
        return getUsers(signin, appointment);
      }
      // Caso existam novas chamadas que busquem de API, pode inserir aqui
    } else {
      if (
        field.SettingServiceFieldChoices &&
        field.SettingServiceFieldChoices.length !== 0
      ) {
        return field.SettingServiceFieldChoices.map((choice) => (
          <Option key={choice.id} value={choice.description}>
            {choice.description}
          </Option>
        ));
      }
    }
  };

  /**
   * busca todos os campos a serem renderizados
   */
  const getFields = () => {
    setLoading(true);
    setFieldsRender(<Skeleton />);
    appointmentsApi
      .appointmentsGet(
        appointment.id_general_status,
        appointment.id,
        appointment.id_setting_service
      )
      .then((response) => {
        // seta os campos a serem renderizados
        getFieldsForRender(response);

        // seta um array com os a pergunta de cada campo para buscar o ID da pergunta
        setSettingServiceFields(
          response
            .filter(
              (value) => value.SettingServiceField && value.SettingServiceField
            )
            .map((dataValue) => dataValue.SettingServiceField)
        );

        setLoading(false);
      })
      .catch(() => {
        message.error("Oops... Erro ao renderizar os campos");
        history.push("/" + props.modulePath + "/order-service");
        dispatch(removeAppointment({}));
      });
  };

  /**
   * Verifica se existe algum formulário que precisa ser respondido
   */
  const checkForms = (fill_moment) => {
    let checkFinalyOs = false;
    let formsRequered = formStart.filter((el) => el.is_required === true);

    if (formsRequered.length === 0) {
      checkFinalyOs = true;
      return checkFinalyOs;
    }

    for (const key in formsRequered) {
      if (Object.hasOwnProperty.call(formsRequered, key)) {
        const element = formsRequered[key];

        if (element.fill_moment === fill_moment) {
          if (element.f.length !== 0) {
            let check = element.f.filter((e) => e.status_id !== fill_moment);

            if (check) {
              checkFinalyOs = true;
            }
          } else {
            checkFinalyOs = false;
            break;
          }
        } else {
          checkFinalyOs = true;
          break;
        }
      }
    }

    return checkFinalyOs;
  };

  const handleSubmitForm = async (values) => {
    // checa se nas chaves possuí o campo de paragrafo para preencher com o conteúdo correto
    let reg = /[0-9]/;
    for (let key in values) {
      if (reg.test(key)) {
        if (key.split("_")[1] === "8") {
          if (!html) {
            return message.warning(
              "Oops... necessário informar as observações!"
            );
          }

          key = key.split("_")[0];
          values[key] = html.replace(/<(.|\n)*?>/g, "");
        }
      }
    }

    setLoading(true);
    const data = {
      ...values,
    };

    // montar um array de objetos com as respostas para serem salvas no BD
    let arrayData = Object.keys(data).map((key) => {
      if (data[key]) {
        // busca o ID do campo
        let field_id = settingServiceFields.find(
          (element) => element.tag_name === convertNameInTagName(key)
        );

        if (!field_id) {
          field_id = 0;
        } else {
          field_id = field_id.id;
        }

        if (typeof data[key] !== "object") {
          return {
            key: key,
            value: data[key],
            id_setting_service_field: field_id,
          };
        } else if (key === "photo") {
          return {
            key: "photo",
            value: data.photo,
            id_setting_service_field: field_id,
          };
        } else if (data[key].isValid()) {
          return {
            key: key,
            value: data[key],
            id_setting_service_field: field_id,
          };
        }
      }
    });

    arrayData = arrayData.filter((e) => e !== undefined || null || false || 0);
    arrayData = arrayData.filter((e) => e.id_setting_service_field !== 0);

    let id_user = idUserOs || signin.user.id;

    appointmentsApi
      .appointmentsPost({
        id_service: appointment.id,
        values: arrayData,
      })
      .then(async () => {
        if (operation === "Salvar") {
          let status = appointment.id_general_status;
          await handleRecordMovemntOS(status, id_user, appointment.id);

          handleUpdateStatusService({
            id: appointment.id,
            id_user,
            id_general_status: status,
          });
        }
        if (operation === "Agendar") {
          let status = 5;
          await handleRecordMovemntOS(status, id_user, appointment.id);

          handleUpdateStatusService({
            id: appointment.id,
            id_user,
            id_general_status: status,
          });
        } else if (operation === "Iniciar") {
          let status = 2;
          await handleRecordMovemntOS(status, id_user, appointment.id);

          handleUpdateStatusService({
            id: appointment.id,
            id_user,
            id_general_status: status,
          });
        } else if (operation === "Finalizar") {
          const check = checkForms(2);

          if (!check) {
            Swal.fire({
              title: "Atenção",
              text: "Antes de finalizar a OS, verifique se existem checkists a serem respondidos! deseja continuar e finlaizar a OS ?",
              icon: "warning",
              showDenyButton: true,
              focusConfirm: false,
              denyButtonText: "Verificar checkists",
              confirmButtonText: "Sim, Finalizar",
              confirmButtonColor: "#40a9ff",
              denyButtonColor: "rgb(238, 26, 26)",
            }).then(async (result) => {
              if (result.isConfirmed) {
                let status = 3;
                await handleRecordMovemntOS(status, id_user, appointment.id);

                handleUpdateStatusService({
                  id: appointment.id,
                  id_user,
                  id_general_status: status,
                });
              } else {
                return false;
              }
            });
          } else {
            let status = 3;
            await handleRecordMovemntOS(status, id_user, appointment.id);

            handleUpdateStatusService({
              id: appointment.id,
              id_user,
              id_general_status: status,
            });
          }
        } else if (operation === "Cancelar") {
          Swal.fire({
            title: "Atenção",
            text: "Você deseja realmente cancelar a OS ?",
            icon: "warning",
            showDenyButton: true,
            focusConfirm: false,
            denyButtonText: "Não",
            confirmButtonText: "Sim, Cancelar",
            confirmButtonColor: "rgb(238, 26, 26)",
            denyButtonColor: "#40a9ff",
          }).then(async (result) => {
            if (result.isConfirmed) {
              let status = 4;

              await handleRecordMovemntOS(status, id_user, appointment.id);

              handleUpdateStatusService({
                id: appointment.id,
                id_user,
                id_general_status: status,
              });
            } else {
              setLoading(false);
              return false;
            }
          });
        } else if (operation === "Aberto") {
          Swal.fire({
            title: "Atenção",
            text: "Você deseja realmente reabrir a OS ?",
            icon: "warning",
            showDenyButton: true,
            focusConfirm: false,
            denyButtonText: "Não",
            confirmButtonText: "Sim, reabrir",
            confirmButtonColor: "#40a9ff",
            denyButtonColor: "rgb(238, 26, 26)",
          }).then(async (result) => {
            if (result.isConfirmed) {
              let status = 2;

              await handleRecordMovemntOS(status, id_user, appointment.id);

              handleUpdateStatusService({
                id: appointment.id,
                id_user,
                id_general_status: status,
              });
            } else {
              setLoading(false);
              return false;
            }
          });
        }
      })
      .catch(() => message.error("Oops... Erro ao gravar as alterações!"));

    dispatch(setAppointmentDefaultPhotos([]));
  };

  /**
   * Verifica se a OS foi finalizada para enviar os dados necessários para o backend
   */
  const handleChangeOtherOs = async (params) =>
    appointmentsApi
      .appointmentsChangeOtherOSs(params)
      .then(() =>
        notification.open({
          message: "OS finalizada.",
          description:
            "Atenção! Outros setores serão notificados da finalização dessa OS.",
        })
      )
      .catch(() => false);

  /**
   * Função responsavel por alterar o status no kanban e enviar para tela do kanban
   */
  const handleUpdateStatusService = (data) => {
    setLoading(true);
    ServicesService.putService(
      {
        id_general_status: data.id_general_status,
        sector_id_last_change: signin.user.currentModule.id_sector,
        is_blocked_default: false,
        id_user: data.id_user,
      },
      data.id
    )
      .then(() => message.success("Status alterado com sucesso!"))
      .catch(() => message.error("Erro ao alterar o status"))
      .finally(() => {
        listTasks(`service_id=${appointment.id}`);
        setSave(true);
        setLoading(false);
      });
  };

  const handleHtml = (value) => setHtml(value.replaceAll("<br>", "\n"));

  // Identifica o valor da tag para substituir o nome pelo valor
  const handleHtmlTag = (value) => {
    do {
      let value_tag = "";

      // busca tags no código
      const checkTagInCode = getTagInCode(
        value.substring(value.indexOf("{") + 2, value.indexOf("}")).trim(),
        signin.user.userCompanies.find(
          (value) => value.id === signin.user.currentCompany
        ).name
      );

      if (checkTagInCode) {
        value_tag = checkTagInCode.value;
      } else {
        // busca as tags de cada campo da OS
        let tag = fieldsRenderTag.filter(
          (data) =>
            data.tag_name.trim() ===
            value.substring(value.indexOf("{") + 2, value.indexOf("}")).trim()
        );

        if (tag && tag.length !== 0) {
          tag = tag[0];
          if (tag.FieldValues.length === 0) {
            value_tag = "";
          } else {
            value_tag = tag.FieldValues[0].value;

            try {
              if (moment(value_tag).isValid()) {
                value_tag = new String(
                  moment(value_tag).format("DD/MM/YYYY HH:mm")
                );
              } else {
                value_tag = tag.FieldValues[0].value;
              }
            } catch (e) {
              value_tag = tag.FieldValues[0].value;
            }
          }
        } else {
          // busca tags fixas do protheus
          try {
            tag = value
              .substring(value.indexOf("{") + 2, value.indexOf("}"))
              .trim()
              .split("/");

            let key = detailsProtehusData[tag[0]];
            let value_key = key[tag[1]];

            if (moment(value_key).isValid()) {
              value_tag = new String(
                moment(value_tag).format("DD/MM/YYYY HH:mm")
              );
            } else {
              value_tag = value_key;
            }
          } catch (e) {
            value_tag = "";
          }
        }
      }

      value = value.replaceAll(
        value.substring(value.indexOf("{"), value.indexOf("}") + 2),
        value_tag.bold()
      );
    } while (value.includes("{"));

    return value;
  };

  // busca o ID do usuário caso exista
  const getIdUser = async (value, option, field) => {
    try {
      if (field.get_list && field.tag_function === "getUsers") {
        let idUser = parseInt(value.split("-")[0]);
        if (isNaN(idUser)) throw false;

        const user = await listUserIdApi.listUserId(idUser);
        setIdUserOs(user.id);
      } else {
        setIdUserOs(signin.user.id);
      }
    } catch (e) {
      setIdUserOs(signin.user.id);
      return false;
    }
  };

  // ordena os campos por sequencia
  const sortData = (data) =>
    data
      .filter((value) => value && value)
      .sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence));

  // renderiza os campos criados pelo usuário
  const renderFieldsSettingService = (field) => {
    let tag;

    const tagsHtml = [
      false,
      <Input
        placeholder={`Digite aqui o(a) ${field.name}`}
        disabled={field.is_automatic}
      />,
      <InputNumber disabled={field.is_automatic} />,
      isMobile() ? (
        <input type="datetime-local" />
      ) : (
        <DatePicker
          locale={locale}
          showTime={{ format: "HH:mm" }}
          format="DD/MM/YYYY HH:mm"
          placeholder={field.name}
          disabled={field.is_automatic}
          style={field.size === "100" ? { width: 700 } : { width: 350 }}
        />
      ),
      <Input placeholder="Digite o título" disabled={field.is_automatic} />,
      <>
        {field.SettingServiceFieldChoices &&
        field.SettingServiceFieldChoices.length !== 0
          ? field.SettingServiceFieldChoices.map((choice) => (
              <Radio
                key={choice.id}
                value={choice.id}
                disabled={field.is_automatic}
              >
                {choice.description}
              </Radio>
            ))
          : null}
      </>,
      <Select
        disabled={field.is_automatic}
        onChange={(value, option) => getIdUser(value, option, field)}
      >
        {optionSelect(field)}
      </Select>,
      <>
        {field.SettingServiceFieldChoices &&
        field.SettingServiceFieldChoices.length !== 0
          ? field.SettingServiceFieldChoices.map((choice) => (
              <Checkbox disabled={field.is_automatic}>
                {choice.description}
              </Checkbox>
            ))
          : null}
      </>,
      <Editor
        handleHtml={handleHtml}
        minimalButtons={true}
        disabled={field.is_automatic}
        html={{
          html:
            field.FieldValues.length !== 0 ? field.FieldValues[0].value : false,
        }}
      />,
    ];

    // checa se existe resposta no campo
    let value = "";

    try {
      if (field && field.FieldValues.length !== 0) {
        if (field.id_type === 3) {
          let initial_value =
            field.FieldValues && field.FieldValues.length !== 0
              ? field.FieldValues[0].value
              : false;

          if (initial_value) {
            initial_value = moment(initial_value);
            initial_value = initial_value.format("DD/MM/YYYY HH:mm");
            initial_value = initial_value.split(" ");

            value = moment(initial_value, "DD/MM/YYYY HH:mm");
          } else {
            value = "";
          }
        } else {
          // verifica se o campo de usuário está preenchendo
          if (field.get_list && field.tag_function === "getUsers") {
            let value_field = field.FieldValues[0].value;
            getIdUser(value_field, null, field);
          }

          value = field.FieldValues[0].value;
        }
      }
    } catch (e) {
      value = "";
    }

    let field_name =
      field.id_type === 8
        ? `${field.tag_name}_${field.id_type}`
        : field.tag_name;

    if (isMobile()) {
      tag = (
        <Col xs={24}>
          <Form.Item
            name={field_name}
            label={field.name}
            rules={[
              { required: field.is_automatic ? false : field.is_required },
            ]}
            initialValue={value}
          >
            {tagsHtml[field.id_type]}
          </Form.Item>
        </Col>
      );
    } else {
      if (field.size === "100") {
        tag = (
          <Col span={24}>
            <Form.Item
              name={field_name}
              label={field.name}
              rules={[
                { required: field.is_automatic ? false : field.is_required },
              ]}
              initialValue={
                field.FieldValues && field.FieldValues.length !== 0
                  ? field.FieldValues[0].value
                  : ""
              }
            >
              {tagsHtml[field.id_type]}
            </Form.Item>
          </Col>
        );
      } else if (field.size === "50") {
        tag = (
          <Col span={12}>
            <Form.Item
              name={field_name}
              label={field.name}
              rules={[
                { required: field.is_automatic ? false : field.is_required },
              ]}
              initialValue={value}
            >
              {tagsHtml[field.id_type]}
            </Form.Item>
          </Col>
        );
      }
    }

    return <>{tag}</>;
  };

  // array com os campos que serão renderizados
  const getFieldsForRender = (fields) => {
    let fieldsRender = [];
    let fieldsRenderSecondary = [];

    let newFields = sortData(fields.map((value) => value.SettingServiceField));

    setFieldsRenderTag(newFields);

    newFields = newFields.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );

    newFields.map((field) => {
      if (field.active && field.is_visible && !field.extra_field) {
        fieldsRender.push(renderFieldsSettingService(field));
      } else if (field.extra_field) {
        fieldsRenderSecondary.push(renderFieldsSettingService(field));
      }
    });

    setFieldsRender(<>{fieldsRender}</>);
    setFieldsRenderSecondary(<>{fieldsRenderSecondary}</>);

    if (fieldsRender.length === 0)
      setFieldsRender(
        <Col span={24}>
          <Empty description="Campos não configurados." />
        </Col>
      );

    if (fieldsRenderSecondary.length === 0) setFieldsRenderSecondary(false);
  };

  // busca novamente os dados dos checklists respondidos
  const resetIsForm = async (item) => {
    if (item) {
      setLoading(true);

      setTimeout(() => {
        document.location.reload(true);
      }, 5000);
    }
  };

  // Inativa a OS no banco de dados para o setor especifico
  const removeOS = () => {
    Swal.fire({
      title: "Atenção",
      text: `Você está deletando a OS de número: ${appointment.id}, deseja continuar?`,
      icon: "warning",
      showDenyButton: true,
      focusConfirm: false,
      denyButtonText: "Cancelar",
      confirmButtonText: "Sim, deletar",
      confirmButtonColor: "#1890ff",
      denyButtonColor: "rgb(238, 26, 26)",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await ServicesService.changeActiveOsStatus(
          appointment.id,
          signin.user.id
        );
        return history.goBack();
      }
    });
  };

  return (
    <Main
      key={props.moduleName}
      moduleName={props.moduleName}
      breadcrumb={isMobile() ? false : breadcrumb}
      collapsed={true}
      displayMenuButtons={"flex"}
      buttonHeader={
        <>
          <Button
            size="middle"
            icon={<LeftOutlined />}
            onClick={() => history.goBack()}
            className="button-details space-button-details"
            style={{ marginRight: 10 }}
          >
            Voltar
          </Button>

          <Popconfirm
            title="Deseja deletar está OS?"
            onConfirm={() => removeOS()}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="middle"
              style={{ marginRight: 10, marginTop: 5 }}
            >
              Deletar OS
            </Button>
          </Popconfirm>

          <Button
            type="primary"
            size="middle"
            icon={<ContainerOutlined />}
            onClick={() => dispatch(modalProtheus(true))}
            className="button-details space-button-details"
            style={isMobile() ? { marginRight: 10 } : { marginRight: 0 }}
          >
            Detalhes
          </Button>

          {isMobile() ? (
            <Button
              size="middle"
              icon={<MenuOutlined />}
              onClick={() => dispatch(openSecondaryDrawerData(true))}
              style={
                isMobile()
                  ? { marginRight: 10, marginTop: 5 }
                  : { marginRight: 0, marginTop: 0 }
              }
            ></Button>
          ) : null}
        </>
      }
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row gutter={[16, 16]}>
            <Col md={16}>
              <Form
                name="tannate_enable"
                onFinish={handleSubmitForm}
                layout="vertical"
              >
                <Tabs defaultActiveKey="1" size="small" type="card">
                  <TabPane tab="Informações da OS" key="1">
                    <Row gutter={[8, 8]}>{fieldsRender}</Row>
                    {appointment.SettingService.is_movements_os &&
                    appointment.status !== "Finalizado" ? (
                      <Row>
                        <OsMovements service_id={appointment.id} />
                      </Row>
                    ) : null}
                  </TabPane>
                  {fieldsRenderSecondary ? (
                    <TabPane tab="Informações extra" key="2">
                      <Row gutter={[8, 8]}>{fieldsRenderSecondary}</Row>
                    </TabPane>
                  ) : null}
                </Tabs>
              </Form>
            </Col>

            {/* Divisão entre as telas de formulário e informação do settings service */}

            <DrawerData
              position="right"
              title={formComponents.title}
              render={
                <FormComponent
                  resetIsForm={(item) => resetIsForm(item)}
                  readonly={false}
                />
              }
              width={isMobile() ? 400 : 1000}
            />

            {mobile ? (
              <DrawerSecondary
                position="right"
                title="Detalhes"
                width={335}
                render={
                  <Collapse
                    panel={dataCollapse}
                    pageheader={true}
                    register={register}
                    handleData={handleCollapse}
                  />
                }
              />
            ) : (
              <Col md={7} offset={1}>
                <Collapse
                  panel={dataCollapse}
                  pageheader={true}
                  register={register}
                  handleData={handleCollapse}
                />
              </Col>
            )}
          </Row>

          <Col md={16} className="buttonGroups">
            <Row>
              {appointment.status === "Aberto" ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  form="tannate_enable"
                  onClick={() => setOperation("Agendar")}
                >
                  Agendar
                </Button>
              ) : null}

              {appointment.status === "Aberto" ||
              appointment.status === "Agendado" ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  form="tannate_enable"
                  style={{ marginLeft: 5 }}
                  onClick={() => setOperation("Iniciar")}
                >
                  Iniciar
                </Button>
              ) : null}

              {appointment.status === "Iniciado" ||
              (appointment.status === "Finalizado" &&
                isOwner(signin.user.profiles)) ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  form="tannate_enable"
                  onClick={() => setOperation("Salvar")}
                >
                  Salvar
                </Button>
              ) : null}

              {appointment.status === "Iniciado" ? (
                <>
                  <Button
                    htmlType="submit"
                    shape="round"
                    style={{ marginLeft: 5 }}
                    type="primary"
                    form="tannate_enable"
                    onClick={() => setOperation("Finalizar")}
                  >
                    Finalizar
                  </Button>
                </>
              ) : null}

              {appointment.status === "Cancelado" ? (
                <Button
                  htmlType="submit"
                  shape="round"
                  type="primary"
                  form="tannate_enable"
                  onClick={() => setOperation("Aberto")}
                >
                  Reabrir OS
                </Button>
              ) : null}
            </Row>
          </Col>
        </>
      )}
      <ModalProtheusData />
      <SecondaryModalApp title={htmlDocument.name} width={1000} footer={null}>
        <Editor
          html={htmlDocument}
          minimalButtons={false}
          disabled={true}
          handleHtml={handleHtmlTag}
        />
      </SecondaryModalApp>
    </Main>
  );
}

export default Appointments;
