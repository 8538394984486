import { getRequest } from "../utils/api";

export const dashboardService = {
  getSectors: async ({ company_id }) => {
    return await getRequest(`/dashboard/sectors?company_id=${company_id}`, {});
  },

  getSettingServicesBySector: async ({ id_sector, company_id }) => {
    return await getRequest(
      `/dashboard/settingservices?id_sector=${id_sector}&company_id=${company_id}`,
      {}
    );
  },

  getAvailableChecklists: async ({ company_id, setting_service_id }) => {
    return await getRequest(
      `/dashboard/checklist?${new URLSearchParams({
        company_id,
        setting_service_id,
      })}`
    );
  },

  getChecklistCSVData: async ({ checklist_id, ...params  }) => {
    return await getRequest(
      `/dashboard/checklist/csv/${checklist_id}?${new URLSearchParams(params)}`
    );
  },

  getDashboard: async (params) => {
    return await getRequest(
      `/dashboard/attendances?${new URLSearchParams(params)}`,
      {}
    );
  },

  getDashboardMovements: async (company_id, sector_id, params) => {
    return await getRequest(
      `/dashboard/movements?company_id=${company_id}&sector_id=${sector_id}${params}`,
      {}
    );
  },
};
