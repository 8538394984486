import "./styles.css";

import { itensMenu, itensDrawer } from "./itens-menu";
import MenuCard from "../../../components/menu_card";

function HomeInvoicing(props) {

  props = {
    ...props,
    breadcrumb: [
      {
        title: "Faturamento",
        path: props.modulePath,
      },
    ],
    itensMenu: itensMenu,
    itensDrawer: itensDrawer,
  };

  return <MenuCard {...props} />;
}

export default HomeInvoicing;
