import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Tag, Switch, Button, Input, Row, Col, Tooltip } from "antd";
import { 
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet";
import "./styles.css";

import { openModal } from "../../../../store/ducks/modalGlobal";
import { listUser, alterUser } from "../../../../store/ducks/users";
import { listUserApi } from "../../../../services/users";

import MainCrud from "../../../../components/MainCrud";
import Main from "../../../Layout/Main";
import { ModalApp } from "../../../../components/Modal";
import FormApp from "./components/Form";

import { checkControl } from "../../../../utils/access_control";
import { useDebounce } from "../../../../utils/useDebounce";
import MenuComponent from "../../../../components/MenuComponent";
import { itensDrawer } from "../itens-menu";

function Users(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [showInactiveUsers, setShowInactiveUsers] = useState(false);
  const [searchQuery, setSearchQuery] = useState({ email: "", name: "" });

  const signin = useSelector((state) => state.signin);
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  // Evita que seja feita um requisição a cada novo caracter digitado na pesquisa
  const debouncedSearchQuery = useDebounce(searchQuery, 400);

  useEffect(() => {
    list();
  }, [page, rowsPerPage, users.user, debouncedSearchQuery, showInactiveUsers]);

  useEffect(() => {
    setPage(1);
    message.info(
      `Exibindo usuários ${showInactiveUsers ? "inativos" : "ativos"}.`
    );
  }, [showInactiveUsers]);

  function list() {
    try {
      setLoading(true);

      const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
      listUserApi
        .listUser(
          rowsPerPage,
          offset,
          !showInactiveUsers,
          searchQuery.name,
          searchQuery.email
        )
        .then((response) => {
          dispatch(listUser(response));
        });
    } catch (error) {
      message.error("Ops! Aconteceu um erro ao carregar os dados.");
    } finally {
      setLoading(false);
    }
  }

  function handleChangeSearchQuery(e) {
    const { name, value } = e.target;
    setSearchQuery({ ...searchQuery, [name]: value });
  }

  // filtrar os usuários no backend
  const getColumnSearchApi = (column) => ({
    filterDropdown: () => (
      <div className="divSearch">
        <Input
          style={{ marginBottom: 8 }}
          placeholder="Pesquisar..."
          value={searchQuery[column]}
          onChange={handleChangeSearchQuery}
          name={column}
        />
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const toggleSwitch = () => {
    setShowInactiveUsers(!showInactiveUsers);
  };


  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(10);
  }

  const handleOpenModal = (data) => {
    dispatch(openModal(true));
    dispatch(
      alterUser({
        ...data,
      })
    );
  };

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Usuários",
      path: props.modulePath + "/users",
    },
  ];

  let columns = [
    {
      title: "Nome",
      dataIndex: "name",
      ...getColumnSearchApi("name"),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      ...getColumnSearchApi("email"),
    },
    {
      title: "Situação",
      dataIndex: "active",
      render: (active) => {
        let color = "";
        if (active === true) {
          color = "green";
        } else {
          color = "volcano";
        }
        return <Tag color={color}>{active ? "Ativo" : "Inativo"}</Tag>;
      },
    },
    {
      title: "Ações",
      dataIndex: "actions",
      render: (actions, record) => {
        return (
          <Row gutter={[8,8]} justify="end">
            <Col>
              <Tooltip title="Editar">
              <Button
                type="primary"
                shape="circle"
                ghost size="small"
                icon={<EditOutlined />}
                onClick={() => handleOpenModal(record)}
              >
              </Button>
              </Tooltip>
            </Col>
            <Col>
            <Tooltip
              title="Logs de usuário"
            >
              <Button
              type="text"
              shape="circle"
              ghost size="small"
              onClick={() => {
                dispatch(
                  alterUser({
                    ...record,
                  })
                  );
                }}
                href={`/${props.modulePath}/users/${record.id}/logs`}
                icon={<CodeOutlined />}
                >
              </Button>
                </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Usuários - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="2"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <>
            <span
              style={checkControl(
                signin.user.profiles,
                "check_users_inactivated"
              )}
            >
              <Switch
                checkedChildren="Inativos"
                unCheckedChildren="Ativos"
                onChange={toggleSwitch}
                style={{ marginRight: "5px", marginTop: "5px" }}
              />
            </span>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={handleOpenModal}
              style={{
                ...checkControl(signin.user.profiles, "insert_user"),
                marginLeft: "20px",
              }}
            >
              Novo
            </Button>
          </>
        }
      >
        <MainCrud
          columnsTable={columns}
          dataSource={users.userList}
          loading={loading}
          page={page}
          rowsPerPage={rowsPerPage}
          countPages={users.userListCount}
          handleTableChange={handleTableChange}
          profiles={signin.user.profiles}
          classTag={[]}
          displayMainCrud="none"
        />
        <ModalApp title="Usuário" width={1000} footer={null}>
          <FormApp />
        </ModalApp>
      </Main>
    </>
  );
}

export default Users;
