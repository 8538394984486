import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const FormsBySettingServicesService = {
  getFormsBySettingService: async (idSettingService) => {
    return await getRequest(
      `/setting-service/forms?idSettingService=${idSettingService}`,
      {}
    );
  },
};
