import { getRequest, postRequest } from "../utils/api"
import { setToken } from "../utils/localStoreToken";
import { sessionApi } from "./session";
import { getRequest as getProtheusRequest } from "../utils/apiProtheus";


export const createProfilePaymentMutation = async (data) => {
  return postRequest("/recurrence/profile_payment", data)
}
//holder_name, card_expiration, card_number, card_cvv, payment_company_code

/**
 *  Mutation para adesão a recorrência
 * 
 * @param {Object} data - O objeto de dados contendo informações para a consulta.
 * 
 * @param {string} data.token - O token do link de adesão a recorrência.
 * 
 * @param {Object} data.customer - O objeto de dados contendo informações do cliente.
 * @param {string} data.customer.id - O id do cliente.
 * @param {string} data.customer.name - O nome do cliente.
 * @param {string} data.customer.email - O email do cliente.
 * @param {string} data.customer.cpf - O cpf do cliente.
 * @param {string} data.customer.phone - O telefone do cliente.
 * 
 * @param {Object} data.profile_payment - O objeto de dados contendo informações do cartão de crédito.
 * @param {string} data.profile_payment.holder_name - O nome do titular do cartão de crédito.
 * @param {string} data.profile_payment.card_expiration - A data de expiração do cartão de crédito.
 * @param {string} data.profile_payment.card_number - O número do cartão de crédito.
 * @param {string} data.profile_payment.card_cvv - O código de segurança do cartão de crédito.
 * @param {string} data.profile_payment.payment_company_code - O código da bandeira do cartão de crédito.
 * 
 * @returns {Promise<Object>} Uma promessa que se resolve com os dados do contrato.
 */
export const joinRecurrenceMutation = async (data) => {
  return postRequest("/recurrence/join", data)
}

/**
 * Query para buscar os dados do contrato usados para atualizar o cartão de crédito
 * 
 * @param {Object} data - O objeto de dados contendo informações para a consulta.
 * @param {string} data.token - O token relacionado ao contrato.
 * @returns {Promise<Object>} Uma promessa que se resolve com os dados do contrato.
 */
export const getLinkDataByTokenQuery = async (data) => {
  await defaultAuthenticator();

  return getRequest(`/recurrence/credit-card/token/${data.token}`);
};


/**
 * Query para buscar os dados do cliente e contrato, usados para adesão a recorrência
 * 
 * @param {Object} data - O objeto de dados contendo informações para a consulta.
 * @param {string} data.token - O token relacionado ao contrato.
 * @returns {Promise<Object>} Uma promessa que se resolve com os dados do contrato.
 */
export const getRecurrencejoinLinkQuery = async (data) => {
  await defaultAuthenticator();

  return getRequest(`/recurrence/join/token/${data.token}`);
}

async function defaultAuthenticator() {
  const resp = await sessionApi.signIn({
    email: process.env.REACT_APP_DEFAULT_AUTH_EMAIL,
    password: process.env.REACT_APP_DEFAULT_AUTH_PASS,
  });

  setToken(resp?.token);
}


/**
 * 
 * @param {object} data - Dados para criação do token
 * @param {string} data.recurrence_customer_id - ID do clinete na VINDI
 * @param {string} data.customer_name - Nome do cliente
 * @param {string} data.contract - Número do contrato
 * @param {string} data.product_name - Nome do produto
 * @param {number} data.installment_value - Valor da parcela
 * 
 * @returns 
 */

export const getRecurrenceCreditCardTokenMutation = async (data) => {
  return postRequest("/recurrence/credit-card/token/get-or-create", data)
}
/**
 * Busca contratos em recorrencia com pendencias no cadastro
 */

export const getPendingContractsQuery = async () => {
  // await defaultAuthenticator();

  return getProtheusRequest("/all/RestRecorrencia/api/recorrencia/contratos-pendentes");

}

export const getOrCreateRecurrenceJoinLinkMutation = async (data, ...args) => {
  return postRequest("/recurrence/join/token/get-or-create", data)
}

export const recurrenceToken = {
  listTokens: (params) =>
    getRequest(
      `/recurrence/v1/links/list`, {
      params
    }),
  detailToken: (token) => getRequest(`/recurrence/v1/link/details/${token}`),
  deleteToken: (token) => postRequest(`/recurrence/v1/link/cancel/${token}`)
};