import React, { useState } from "react";
import "./styles.css";

import { Row, Col, Typography, Layout, Steps } from "antd";

import { isMobile } from "../../utils/isMobile";
import { CloudFilled } from "@ant-design/icons";
import Login from "./components/Login";
import SelectCompany from "./components/SelectCompany";

import gMorada from "../../assets/images/gmorada-header.png";

const { Step } = Steps;
const { Content } = Layout;
const { Title } = Typography;

function SignIn() {
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      key: 0,
      title: "Login",
      content: <Login setCurrent={setCurrent} />,
    },
    {
      key: 1,
      title: "Empresa",
      content: <SelectCompany />,
    },
  ];

  return (
    <Row align="middle" style={{ height: "100vh" }}>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={{ span: 8, offset: 8 }}
        xl={{ span: 8, offset: 8 }}
        className={isMobile() ? "login-page-padding-form wrapper" : "wrapper"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <CloudFilled style={{ fontSize: "50px" }} />
          <Title level={1} style={{ paddingLeft: "10px" }}>
            webGM
          </Title>
        </div>

        <Content className="form-content">
          <Steps
            type="navigation"
            size="small"
            current={current}
            className="site-navigation-steps login-page-padding-form"
          >
            {isMobile() ? (
              <Step key={steps[current].key} title={steps[current].title} />
            ) : (
              steps.map((item) => <Step key={item.key} title={item.title} />)
            )}
          </Steps>

          <Row>
            <Col xs={24}>{steps[current].content}</Col>
          </Row>
        </Content>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <a
            href="https://www.moradadapaz.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={gMorada} width={150} />
          </a>
        </div>

        <Row justify="center" className="login-page-footer">
          &copy; {new Date().getFullYear()} TI - Grupo Morada
        </Row>
      </Col>
    </Row>
  );
}

export default SignIn;
