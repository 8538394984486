const Types = {
  ADD: "ADD",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
  GET_MODULES: "GET_MODULES",
  GET_MODULES_INDEX: "GET_MODULES_INDEX",
  SET: "SET_MODULE"
};

const INITIAL_STATE = {
  moduleList: [],
  modules: [],
  moduleListCount: 0,
  module: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_MODULES:
      return {
        ...state,
        modules: action.payload
      };
    case Types.GET_MODULES_INDEX:
      return {
        ...state,
        moduleList: action.payload.rows,
        moduleListCount: action.payload.count
      }
    case Types.ADD:
      return {
        ...state,
        moduleList: action.payload.rows,
        moduleListCount: action.payload.count,
      };
    case Types.UPDATE:
      return {
        ...state,
        moduleList: action.payload.rows,
        moduleListCount: action.payload.count,
      };
    case Types.SET:
      return {
        ...state,
        module: action.payload
      }
    case Types.REMOVE:
      return {
        ...state,
        moduleList: action.payload.rows,
        moduleListCount: action.payload.count,
      };
    default:
      return state;
  }
}

export function listModules(data) {
  return {
    type: Types.GET_MODULES,
    payload: data,
  };
}

export function listModulesIndex(data) {
  return {
    type: Types.GET_MODULES_INDEX,
    payload: data,
  };
}

export function addModule(data) {
  return {
    type: Types.ADD,
    payload: data,
  };
}

export function updateModule(data) {
  return {
    type: Types.UPDATE,
    payload: data,
  };
}

export function alterModule(data) {
  return {
    type: Types.SET,
    payload: data,
  };
}

export function deleteModule(data) {
  return {
    type: Types.REMOVE,
    payload: data,
  };
}