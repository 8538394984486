import { getRequest, putRequest } from "../utils/api";

export const ServicesService = {
  getServiceTasks: async () => {
    return await getRequest("/service/tasks", {});
  },

  putService: async (body, id) => {
    return await putRequest("/service/" + id, body, {});
  },

  putIsBlockedAttendance: async (body, id) => {
    return await putRequest("/service/is_blocked_attendance/" + id, body, {});
  },

  changeActiveOsStatus: async (id, user_id) => {
    return await putRequest(`/service/inactive/os/${id}`, {
      user_id,
    });
  },
};
