import React, { useEffect, useState } from "react";

import { Button, Col, Table, Typography, Layout, Empty } from "antd";

const { Content } = Layout;
const { Title } = Typography;

function TableSmall({ title, columnsTable, descriptionEmpty, dataSource }) {

  return (
    <Col className="content-contract-unified-query" span={24}>
      <Content className="content-header-contract-unified-query">
        <Title level={5}>{title}</Title>
        <Col className="content-header-contract-right-unified-query">
  
          <span className="content-header-contract-text-unified-query">
            {dataSource ? dataSource.length : 0} resultados
          </span>
        </Col>
      </Content>
      {dataSource && dataSource.length === 0 ? (
        <Empty description={descriptionEmpty} />
      ) : (
        <Table
          columns={columnsTable}
          rowClassName="unified-query-contract-table-row-click"
          dataSource={dataSource}
          size="small"
          pagination={false}
          key={Math.random()}
        />
      )}
    </Col>
  );
}

export default TableSmall;
