import { deleteRequest } from "../utils/api";

export const ServiceDocuments = {
  deletedDocuments: async (document_id) => {
    return await deleteRequest(
      `/delete/document?document_id=${document_id}`,
      {}
    );
  },
};
