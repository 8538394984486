import {
  Button,
  Divider,
  Form,
  notification,
  Radio,
  Row,
  Select,
  DatePicker,
  Spin,
} from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useReports } from "../../../../../../Context/reportsContext";
import { dashboardService } from "../../../../../../services/dashboard";
import {
  generateArrayCSV,
  generateDataAttendanceCSV,
  generateDataOsCSV,
} from "../../../../../../utils/csvExportDataGS";
import locale from "antd/es/date-picker/locale/pt_BR";
import Loading from "../../../../../../components/Loading";
import "./style.css";
import { removeNullable } from "../../../../../../utils/object";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const ReportForm = () => {
  const [loading, setLoading] = useState(false);
  const {
    setStage,
    availableSectors,
    availableSettingServices,
    setReportData,
    selectedSector,
    reportType,
    setReportType,
    form,
    availableChecklists,
    setIntervalDate,
  } = useReports();

  const signin = useSelector((state) => state.signin);

  const selectedSettingService = Form.useWatch("settingService", form);

  const handleExportCSV = async (formValues) => {
    try {
      setLoading(true);
      let dataPromise;
      let successDescription;


      const [initial_date_filter, end_date_filter] = formValues.date_interval || [];

      // Verifica qual tipo de relatorio foi selecionado e chama a função correspondente

      initial_date_filter.set({
        'hour': 0,
        'minute': 0,
        'second': 0
      })
      end_date_filter.add(1, 'days')
      end_date_filter.set({
        'hour': 0,
        'minute': 0,
        'second': 0
      })
      if (reportType === "attendance_and_os") {
        let params = {
          company_id: signin.user.currentCompany,
          initial_date_filter: initial_date_filter?.toISOString(),
          end_date_filter: end_date_filter?.toISOString(),
          setting_service_id: formValues.settingService,
          sector_id: formValues.sector,
        };
        params = removeNullable(params)

        dataPromise = dashboardService.getDashboard(params);

        successDescription =
          "Você já pode baixar os CSV's de Atendimentos e OS's.";
      } else {
        dataPromise = dashboardService.getChecklistCSVData({
          checklist_id: formValues.checklist,
          start_date: initial_date_filter.toISOString(),
          end_date: end_date_filter.toISOString(),
        });
        successDescription = "Você já pode baixar o CSV de Checklist.";
      }

      const response = await dataPromise;

      if (reportType === "checklist") {
        // Rotas de checklist retorna erro 404 quando não há dados, então é necessário verificar se o array está vazio

        setReportData({
          dataOsCSV: {},
          dataAttendanceCSV: {},
          dataChecklistCSV: response,
        });
      } else {
        if (!response.data_csv.length)
          return notification.error({
            message: "Não há dados para gerar o CSV.",
            description: "Por favor, tem um intervalo de datas diferente.",
          });

        const arrayCSV = await generateArrayCSV(response.data_csv);
        const dataAttendanceCSV = await generateDataAttendanceCSV(
          arrayCSV,
          signin
        );
        const dataOsCSV = await generateDataOsCSV(arrayCSV);

        setReportData({
          dataOsCSV,
          dataAttendanceCSV,
        });
      }
      notification.success({
        message: "CSV's gerados com sucesso!",
        description: successDescription,
      });
      setIntervalDate([initial_date_filter?.toISOString(), end_date_filter?.toISOString()]);

      setStage("download");
    } catch (e) {
      notification.error({
        description: e.message || "Ocorreu um erro ao gerar o CSV.",
        message: "Erro ao gerar CSV",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin indicator={<Loading />} spinning={loading}>
      <Form
        className="report-form"
        form={form}
        onFinish={handleExportCSV}
        layout="vertical"
      >
        <Row>
          <Radio.Group
            value={reportType}
            onChange={(e) => {
              if (e.target.value === "attendance_and_os")
                form.validateFields(["sector", "settingService"]);
              setReportType(e.target.value);
            }}
          >
            <Radio value="attendance_and_os">Atendimentos + OS's</Radio>
            <Radio value="checklist">Checklists</Radio>
          </Radio.Group>
        </Row>
        <Divider />
        <Form.Item
          help={
            !selectedSector
              ? "Selecione um setor para visualizar os serviços disponíveis."
              : " "
          }
          style={{
            fontWeight: "500",
          }}
          name="sector"
          label="Setores disponíveis"
          rules={[
            {
              required: reportType === "checklist",
              message: "Por favor, selecione um setor.",
            },
          ]}
        >
          <Select
            onChange={() => {
              form.setFieldsValue({ settingService: undefined });
            }}
            style={{ width: "100%" }}
            allowClear
          >
            {availableSectors?.rows.map((value) => (
              <Option key={value.id}>{value.name}</Option>
            ))}
          </Select>
        </Form.Item>
        {availableSettingServices?.rows.length > 0 && selectedSector ? (
          <>
            <Form.Item
              help={
                !selectedSettingService
                  ? "Selecione um serviço para visualizar os checklists disponíveis."
                  : " "
              }
              style={{
                fontWeight: "500",
              }}
              name="settingService"
              label="Serviços disponíveis para o setor"
              rules={[
                {
                  required: reportType === "checklist",
                  message: "Por favor, selecione um dos serviços.",
                },
              ]}
            >
              <Select
                onChange={() => {
                  form.setFieldsValue({ checklist: undefined });
                }}
                style={{ width: "100%" }}
              >
                {availableSettingServices.rows.map((value) => (
                  <Option key={value.id}>{value.name}</Option>
                ))}
              </Select>
            </Form.Item>
            {reportType === "checklist" && selectedSettingService && (
              <Form.Item
                style={{
                  fontWeight: "500",
                }}
                label="Checklists disponíveis para o serviço"
                name="checklist"
                rules={[
                  {
                    required: true,
                    message: "Por favor, selecione um dos checklists.",
                  },
                ]}
              >
                <Select>
                  {availableChecklists?.map((value) => (
                    <Option key={value.id}>{value.title}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </>
        ) : (
          <Row>
            <p>
              {selectedSector && (
                <small>
                  Não há serviços disponíveis para o setor selecionado.
                </small>
              )}
            </p>
          </Row>
        )}
        <br />
        <Form.Item
          style={{
            fontWeight: "500",
          }}
          tooltip={reportType === "attendance_and_os" ? "Se não for especificado o periodo, será gerado o CSV das últimas 24h." : ""}
          name="date_interval"
          rules={[
            {
              required: reportType === "checklist",
              message: "Por favor, selecione um período.",
            },
          ]}
          label="Período desejado"
        >
          <RangePicker
            placeholder={["Data inicial", "Data final"]}
            locale={locale}
            format="DD/MM/YYYY"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <br />

        <Button type="primary" htmlType="submit" size="large" block>
          Exportar em CSV
        </Button>
      </Form>
    </Spin>
  );
};
