import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { modalReports } from "../../../../../../store/ducks/modalGlobal";
import { useReports } from "../../../../../../Context/reportsContext";
import { ReportResults } from "./ReportResults";
import { ReportForm } from "./ReportForm";

function ModalExportDataGS(props) {
  const modal = useSelector((state) => state.modalGlobal);
  const dispatch = useDispatch();
  const {  stage, handleFinish } = useReports();


  return (
    <Modal
      title={props.title}
      open={modal.modal_reports}
      onCancel={() => {
        handleFinish();
        dispatch(modalReports(false));
      }}
      width={props.width}
      footer={props.footer}
      centered={true}
    >
      {/* Etapa de seleção de tipos de relatorios, setores*/}
      {stage === "form" ? (
        <ReportForm />
      ) : (
        stage === "download" && (
          // Estagio de exibiçao de relatorios
          <ReportResults />
        )
      )}
    </Modal>
  );
}

export default ModalExportDataGS;
