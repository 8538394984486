import { getRequest, postRequest, deleteRequest, putRequest } from "../utils/api";

export const submission = {
  list: async (limit, offset) =>
    await getRequest(`/form/submission?limit=${limit}&offset=${offset}`, {}),

  create: async (data) => await postRequest("/form/submission", data),

  update: async (id, data) => await putRequest(`/form/submission/${id}`, data),

  delete: async (id) => await deleteRequest(`/form/submission/${id}`, {}),

  show: async (service_id, sector_id) =>
    await getRequest(`/get/form/submission/${service_id}/${sector_id}`, {}),
};
