import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer } from "antd";

import { closeSecondaryDrawerData } from "../../store/ducks/drawer";

function DrawerData(props) {
  const visible = useSelector((state) => state.drawer.open_drawer_secondary);
  const dispatch = useDispatch();

  useEffect(() => {}, [visible]);

  return (
    <Drawer
      title={props.title}
      placement={props.position}
      onClose={() => dispatch(closeSecondaryDrawerData(false))}
      visible={visible}
      width={props.width ? props.width : 250}
    >
      {props.render}
    </Drawer>
  );
}

export default DrawerData;
