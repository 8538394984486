import React from "react";
import {
  Row,
  Col,
  Button,
  message
} from "antd";

import {
  CopyOutlined,
  DesktopOutlined,
} from "@ant-design/icons";

import successSVG from "../../../../../assets/images/success.svg";

const Send = (props) => {
  return (
    <Row justify="center">
      <Col>
        <Row style={{ marginBottom: 30, flexDirection: "row", alignItems: "center" }} justify="center">
          <Col style={{ marginRight: 30 }}>
            <img src={successSVG} alt="loading" height={70} />
          </Col>
          <Col>
            <h2>Link gerado com sucesso!</h2>
          </Col>
        </Row>
        <Row justify="center">
          <Col style={{ paddingInline: 10 }}>
            <Button
              className="button-download-negociation"
              type="primary"
              onClick={() => {
                navigator.clipboard.writeText(
                  props.result.paymentLink
                );
                message.success("Copiado para área de transferência");
              }}
            >
              <CopyOutlined size={13} />
              &nbsp; <strong>COPIAR LINK</strong>
            </Button>
          </Col>
          <Col style={{ paddingInline: 10 }}>
            <Button type="primary" className="button-download-negociation">
              <a
                href={props.result.paymentLink}
                target="_blank"
                rel="noreferrer"
              >
                <DesktopOutlined size={13} />
                &nbsp; <strong>ABRIR EM UMA NOVA GUIA</strong>
              </a>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Send;