/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { sessionApi } from "../services/session";
import { api } from "../utils/apiProtheus";
import { Initials } from "../utils/text";
import { getToken as getFirebaseMessageToken, deleteMessageToken } from "../utils/firebase";
import { storeUserMessageTokenMutation } from "../utils/api";
import { useMutate } from "../utils/useMutate";

const SessionContext = createContext({});

export const SessionProvider = ({ children }) => {
  const sessionInitialState = {
    user: {},
    apiProtheus: {},
  };
  const [session, setSession] = useState(sessionInitialState);
  const [currentMessageToken, setCurrentMessageToken] = useState(null);

  const { mutate: storeUserMessageToken } = useMutate(
    storeUserMessageTokenMutation,
    {
      onSuccess: (result) => {
        // Atualiza o token do usuário no contexto
        if (result?.length) {
          setSession({
            ...session,
            user: {
              ...session.user,
              messageTokens: result,
            },
          });
        }
      },
    }
  );

  function handleUpdateMessageToken(token) {
    setCurrentMessageToken(token);
    // Verifica se o token já está cadastrado
    if (
      !session.user.messageTokens?.some(
        (messageToken) => messageToken.token === token
      )
    ) {
      storeUserMessageToken({
        id_user: session.user.id,
        token,
      });
    }
  }

  function signin(payload) {
    setSession((prev) => ({
      ...prev,
      user: {
        ...payload,
        currentModule: {
          module: "",
          id_sector: 0,
        },
        initials: Initials(payload.name),
      },
    }));
  }

  async function signout() {
    try {
      await sessionApi.signOut(session.user.id, {
        messageToken: currentMessageToken,
      });

      // Remove o token do usuário no firebase
      await deleteMessageToken();
      setSession(sessionInitialState);
      localStorage.removeItem("session");
    } catch (err) {
      console.error("[SessionContext - signout]", err);
    }
  }

  function updateCurrentUser(payload) {
    setSession((prev) => ({
      ...prev,
      user: {
        ...prev.user,
        ...payload,
      },
    }));
  }

  async function setProtheusApiToken() {
    try {
      const resp = await api.post(
        "/all/api/oauth2/v1/token?grant_type=password&password=jN8Pkf5ea*Z!&username=webgm_meireles"
      );
      setSession((prev) => ({
        ...prev,
        apiProtheus: {
          ...resp.data,
          expires_date: new Date().getTime() + resp.data.expires_in * 1000, // expires_in is in seconds
        },
      }));
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const localSession = JSON.parse(localStorage.getItem("session") || "{}");

    if (Object.keys(localSession).length) {
      setSession((prev) => ({ ...prev, ...localSession }));
    }
  }, []);

  useEffect(() => {
    if (
      session.user?.token &&
      (!session.apiProtheus?.access_token ||
        session.apiProtheus?.expires_date < new Date().getTime())
    ) {
      setProtheusApiToken();
    }

    if (session.user?.id) getFirebaseMessageToken(handleUpdateMessageToken);
  }, [session.user]);

  useEffect(() => {
    if (Object.keys(session).length) {
      localStorage.setItem("session", JSON.stringify(session));
    }
  }, [session]);

  return (
    <SessionContext.Provider
      value={{
        signin,
        signout,
        session,
        setSession,
        updateCurrentUser,
        setProtheusApiToken,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const ctx = useContext(SessionContext);

  if (ctx === undefined) {
    throw new Error("useSession must be used within a SessionProvider");
  }

  const { session, signin, updateCurrentUser, signout, setSession } = ctx;

  return {
    session,
    setSession,
    signin,
    signout,
    updateCurrentUser,
  };
};
