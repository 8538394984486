import React from "react";

import loadingSVG from "../../assets/images/loading.svg";

function Loading({ title }) {
  return (
    <>
      <div className="loading-centar">
        <img src={loadingSVG} alt="loading" height={80} />
      </div>
      <h2 className="text-loading">{title ? title : "Carregando..."}</h2>
    </>
  );
}

export default Loading;
