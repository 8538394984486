import React from "react";
import { Layout, Row, Col } from "antd";

import moment from "moment";

const { Content } = Layout;

function Attendance({ resultDataAttendance, attendance }) {
  const textsAttendance = [
    {
      textHeader: "N° do Atendimento",
      textBody: attendance.attendance_number,
    },
    {
      textHeader: "Tipo de Atendimento",
      textBody: attendance.attendance_type,
    },
    {
      textHeader: "Data do Atendimento",
      textBody: moment(attendance.attendance_date).format("DD/MM/YYYY"),
    },
    {
      textHeader: "Empresa/Filial",
      textBody: attendance.company,
    },
    {
      textHeader: "Atendente",
      textBody: attendance.attendante,
    },
    {
      textHeader: "Status",
      textBody: attendance.status,
    },
  ];

  const HeaderText = (props) => (
    <Row gutter={[16, 16]} align="middle">
      <Col>
        <h1>{props.children}</h1>
      </Col>
    </Row>
  );

  const BodyText = (props) => (
    <Col>
      <Row>
        <span className="unified-query-contract-text-data">{props.header}</span>
      </Row>
      <Row>{props.body}</Row>
    </Col>
  );

  // verifica se o dado da API existe
  const checkData = (data, property) => {
    if (resultDataAttendance.hasOwnProperty(property)) return data ? data : "-";

    return null;
  };

  return (
    <Content>
      <Row>
        <Col span={24}>
          <HeaderText>ATENDIMENTO</HeaderText>

          <Row gutter={[32, 16]} align="middle">
            {textsAttendance.map((value) => (
              <Col>
                <Row>
                  <span className="unified-query-contract-text-data">
                    {value.textHeader}
                  </span>
                </Row>
                <Row>{value.textBody}</Row>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <br />
      <Row>
        <Col span={24}>
          <HeaderText>FALECIDO(A)</HeaderText>

          <Row gutter={[32, 16]} align="middle">
            <BodyText
              header="Nome do Falecido(a)"
              body={() =>
                checkData(resultDataAttendance.FALECIDO.nome, "FALECIDO")
              }
            />

            <BodyText
              header="CPF"
              body={() =>
                checkData(resultDataAttendance.FALECIDO.cpf, "FALECIDO")
              }
            />

            <BodyText
              header="Data de Nascimento"
              body={() =>
                checkData(
                  resultDataAttendance.FALECIDO.data_nascimento,
                  "FALECIDO"
                )
              }
            />

            <BodyText
              header="Data de Óbito"
              body={() =>
                checkData(resultDataAttendance.FALECIDO.data_obito, "FALECIDO")
              }
            />

            <BodyText
              header="Religião"
              body={() =>
                checkData(resultDataAttendance.FALECIDO.religiao, "FALECIDO")
              }
            />
          </Row>
        </Col>
      </Row>

      <br />
      <Row>
        <Col span={24}>
          <HeaderText>DECLARANTE</HeaderText>

          <Row gutter={[32, 16]} align="middle">
            <BodyText
              header="Nome do Declarante"
              body={() =>
                checkData(resultDataAttendance.DECLARANTE.nome, "DECLARANTE")
              }
            />

            <BodyText
              header="CPF"
              body={() =>
                checkData(resultDataAttendance.DECLARANTE.cpf, "DECLARANTE")
              }
            />

            <BodyText
              header="Data de Nascimento"
              body={() =>
                checkData(
                  resultDataAttendance.DECLARANTE.data_nascimento,
                  "DECLARANTE"
                )
              }
            />

            <BodyText
              header="Celular"
              body={() =>
                checkData(
                  resultDataAttendance.DECLARANTE.telcel.replace(
                    /(\d{2})(\d{5})(\d{4})/,
                    "($1) $2 - $3"
                  ),
                  "DECLARANTE"
                )
              }
            />

            <BodyText
              header="E-mail"
              body={() =>
                checkData(resultDataAttendance.DECLARANTE.email, "DECLARANTE")
              }
            />
          </Row>
        </Col>
      </Row>

      <br />
      <Row>
        <Col xs={24} sm={8} md={8} lg={8}>
          <HeaderText>VELÓRIO</HeaderText>
          <BodyText
            header="Local do Velório"
            body={() =>
              checkData(
                resultDataAttendance.VELORIO.local_velorio1 ||
                  resultDataAttendance.VELORIO.local_velorio2,
                "VELORIO"
              )
            }
          />

          <Row gutter={[16, 16]}>
            <BodyText
              header="Data de Início"
              body={() =>
                checkData(
                  `${resultDataAttendance.VELORIO.data_inicio} ${resultDataAttendance.VELORIO.hora_inicio}` ||
                    `${resultDataAttendance.VELORIO.data_inicio_velorio2} ${resultDataAttendance.VELORIO.hora_inicio_velorio2}`,
                  "VELORIO"
                )
              }
            />

            <BodyText
              header="Data de Fim"
              body={() =>
                checkData(
                  `${resultDataAttendance.VELORIO.data_fim} ${resultDataAttendance.VELORIO.hora_fim}` ||
                    `${resultDataAttendance.VELORIO.data_fim_velorio2} ${resultDataAttendance.VELORIO.hora_fim_velorio2}`,
                  "VELORIO"
                )
              }
            />
          </Row>
        </Col>
        <Col xs={24} sm={8} md={8} lg={8}>
          <HeaderText>SEPULTAMENTO</HeaderText>

          <BodyText
            header="Local do Sepultamento"
            body={() =>
              checkData(resultDataAttendance.SEPULTAMENTO.local, "SEPULTAMENTO")
            }
          />

          <Row gutter={[16, 16]}>
            <BodyText
              header="Data do Cortejo"
              body={() =>
                checkData(
                  `${resultDataAttendance.CORTEJO.data} ${resultDataAttendance.CORTEJO.hora}`,
                  "CORTEJO"
                )
              }
            />

            <BodyText
              header="Data do Sepultamento"
              body={() =>
                checkData(
                  `${resultDataAttendance.SEPULTAMENTO.data} ${resultDataAttendance.SEPULTAMENTO.hora}`,
                  "SEPULTAMENTO"
                )
              }
            />
          </Row>
        </Col>
        <Col xs={24} sm={8} md={8} lg={8}>
          <HeaderText>CREMAÇÃO</HeaderText>

          <BodyText
            header="Local da Cremação"
            body={() =>
              checkData(resultDataAttendance.CREMACAO.local, "CREMACAO")
            }
          />

          <BodyText
            header="Data da Cerimonial"
            body={() =>
              checkData(
                `${resultDataAttendance.CREMACAO.data_inicio} ${resultDataAttendance.CREMACAO.hora_inicio}`,
                "CREMACAO"
              )
            }
          />
        </Col>
      </Row>
    </Content>
  );
}

export default Attendance;
