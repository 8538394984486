import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Spin,
  Typography,
} from "antd";
import { resendEmailPaymentPixMutation } from "../../../../../services/paymentPix";
import { useMutate } from "../../../../../utils/useMutate";

const { Text } = Typography;

export const ResendEmailModal = ({ open, setOpen, payment }) => {
  const sendedEmailMessage = `Um e-mail com o link para pagamento foi enviado para o endereço ${payment?.email}. Caso não tenha recebido, clique no botão abaixo para reenviar.`;
  const notSendedEmailMessage = `Informe o e-mail para enviar o link de pagamento.`;

  const { mutate: resendEmailPayment, loading } = useMutate(
    resendEmailPaymentPixMutation,
    {
      onSuccess: () => {
        notification.success({
          message: "E-mail enviado com sucesso",
        });
        setOpen(false);
      },
      onError: (e) => {
        notification.error({
          message: "Erro ao enviar e-mail",
        });
      },
    }
  );

  function handleSubmit(values) {
    resendEmailPayment({
      id: payment.id,
      email: values.email,
    });
  }

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title="Reenviar e-mail"
      destroyOnClose
      footer={null}
    >
      <Spin spinning={loading} tip="Enviando email. Aguarde...">
        <Text type="secondary">
          {payment?.email ? sendedEmailMessage : notSendedEmailMessage}
        </Text>
        <Form
          onFinish={handleSubmit}
          style={{ marginTop: "1rem" }}
          layout="vertical"
        >
          <Form.Item
            initialValue={payment?.email}
            name="email"
            label="E-mail"
            rules={[{ required: true, message: "Campo obrigatório" }]}
          >
            <Input type="email" placeholder="E-mail" />
          </Form.Item>

          <Row justify="end">
            <Button htmlType="submit" type="primary">
              {payment?.email ? "Reenviar" : "Enviar"}
            </Button>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};
