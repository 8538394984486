import { Select, Form } from 'antd';

import { staticProtheusData } from '../../utils/staticProtheusData';

const { Option } = Select;

const SelectStaticProtheusData = (props) => {
  return (
    <Form.Item
      initialValue={props.defaultValue || ""}
      label={props.label}
      name={props.name}
      rules={
        [
          ...(props.rules || [])
        ]
      }
    >
      <Select >
        {
          staticProtheusData[props.type].map(data => <Option key={data.id} value={data.id}>{data.description}</Option>)
        }
      </Select>
    </Form.Item>
  );
}

export default SelectStaticProtheusData;