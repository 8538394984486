/**
 * campos que são feitos os bindings para o envio no firebase - com o id do serviço, o agente que será informado e a data
 */
export const settingServiceFieldsBinding = [

  {
    settingServiceId: 16, 
    IdAgente: 143,
    IdData: 144,
  },
  {
    settingServiceId: 36,
    IdAgente: 143,
    IdData: 350,
  },
  {
    settingServiceId: 27,
    IdAgente: 143,
    IdData: 270,
  },
  {
    settingServiceId: 29,
    IdAgente: 143,
    IdData: 284
  },
  {
    settingServiceId: 31,
    IdAgente: 143,
    IdData: 284,
  },
  {
    settingServiceId: 22,
    IdAgente: 671,
    IdData: 656,
  },
  {
    settingServiceId: 23,
    IdAgente: 192,
    IdData: 194,
  },
  {
    settingServiceId: 24,
    IdAgente: 199,
    IdData: 200,
  },
  {
    settingServiceId: 26,
    IdAgente: 213,
    IdData: 215,
  },
  {
    settingServiceId: 48,
    IdAgente: 485,
    IdData: 489,
  },
  {
    settingServiceId: 51,
    IdAgente: 773,
    IdData: 777,
  },
  {
    settingServiceId: 97,
    IdAgente: 1085,
    IdData: 1101,
  },
  {
    settingServiceId: 98,
    IdAgente: 1111,
    IdData: 1114,
  },
  {
    settingServiceId: 99,
    IdAgente: 1122,
    IdData: 1124,
  },
  {
    settingServiceId: 100,
    IdAgente: 1129,
    IdData: 1133,
  },
  {
    settingServiceId: 101,
    IdAgente: 1152,
    IdData: 1147,
  },
  {
    settingServiceId: 102,
    IdAgente: 1154,
    IdData: 1155,
  },
  {
    settingServiceId: 103,
    IdAgente: 1167,
    IdData: 1171,
  },
  {
    settingServiceId: 104,
    IdAgente: 1174,
    IdData: 1183,
  },
  {
    settingServiceId: 105,
    IdAgente: 1182,
    IdData: 1183,
  },
  {
    settingServiceId: 106,
    IdAgente: 1190,
    IdData: 1193,
  },
]