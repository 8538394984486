import React, { useContext } from "react";

import { Col, Row, Input, Button, message } from "antd";
import { QuestionCircleOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { MdTune } from "react-icons/md";

import { UnifiedQueryContext } from "../../../../../../Context/unifiedQueryContext";
import { buttons, searchAPI } from "../../mocks";

import { openDrawer } from "../../../../../../store/ducks/drawer";
import { useDispatch } from "react-redux";

const { Search } = Input;

function SearchComponent({ width, getDataScreen }) {
  const context = useContext(UnifiedQueryContext);
  const dispatch = useDispatch();

  const onSearch = async (value) => {
    if (!value) {
      return message.info("Favor informar o parâmetro da pesquisa!");
    }

    const regex = /^[a-zA-Z]+$/;
    let params = null;
    let searchValue = null;

    if (regex.test(value)) {
      if (value.length < 6)
        return message.info("Favor informar no mínimo 6 caracteres!");

      params = "text";
      searchValue = value.toUpperCase();
    } else if (moment(value, "DD/MM/YYYY").isValid()) {
      params = "date";
      searchValue = value;
    } else {
      params = "number";
      searchValue = value;
    }

    try {
      context.setComponentLoading(true);

      const result = await searchAPI(params, searchValue);
      context.setData(result);
    } catch (e) {
      return message.error(
        "Desculpe, ocorreu um erro ao buscar os dados, tente novamente mais tarde!"
      );
    } finally {
      context.setComponentLoading(false);
    }
  };

  return (
    <Row justify="space-between" gutter={[16, 16]}>
      <Col xs={24} sm={16} md={16} lg={16}>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col>
            <Search
              placeholder="Pesquise por titular, dependente, contrato, cpf..."
              onSearch={onSearch}
              enterButton
              style={{ width: width / 3 }}
            />

            <Button
              style={{ marginLeft: 15 }}
              icon={<QuestionCircleOutlined />}
              size="middle"
              onClick={() => dispatch(openDrawer(true))}
            />
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<EditOutlined style={{ fontSize: 20 }} />}
            >
              Registrar Ocorrência
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8}>
        <Row gutter={[3, 0]}>
          <Col>
            {buttons.map((button) => {
              return (
                <Button
                  key={button.key}
                  onClick={() => context.setCurrent(button.current)}
                  type={
                    button.current === context.current ? "primary" : "default"
                  }
                >
                  {button.label}
                </Button>
              );
            })}
          </Col>
          <Col>
            <Button
              icon={<MdTune size={24} />}
              onClick={() => getDataScreen()}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SearchComponent;
