import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { checkControl } from "../../utils/access_control";

import DynamicIcon from "../../utils/dynamicIcon";
import SubMenu from "antd/lib/menu/SubMenu";

function MenuComponent(props) {
  const signin = useSelector((state) => state.signin);

  function menus(menu) {
    return (
      <Menu.Item
        key={menu.key}
        icon={<DynamicIcon name={menu.icon} />}
        style={checkControl(signin.user.profiles, menu.permission)}
      >
        <Link to={"/" + props.modulePath + menu.url}>{menu.title}</Link>
      </Menu.Item>
    );
  }

  function subMenus(menu) {

    return (
      <SubMenu
        key={menu.key}
        icon={<DynamicIcon name={menu.icon} />}
        title={menu.title}
      >
        {menu.subMenu.map((subMenu) => (
          <Menu.Item
            key={subMenu.key}
            icon={<DynamicIcon name={subMenu.icon} />}
            style={checkControl(signin.user.profiles, subMenu.permission)}
          >
            <Link to={"/" + props.modulePath + subMenu.url}>
              {subMenu.title}
            </Link>
          </Menu.Item>
        ))}
      </SubMenu>
    );
  }

  return (
    <Menu theme="dark" defaultSelectedKeys={[props.selectedMenu]} mode="inline">
      {props.itensMenu.map((menu) =>
        menu.subMenu ? subMenus(menu) : menus(menu)
      )}
    </Menu>
  );
}

export default MenuComponent;
