import React, { useState, useEffect} from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  Spin,
  Form,
  Row,
  Col,
  TreeSelect,
  Select,
  Input,
  Checkbox,
  Button,
  message,
  Alert,
  Cascader,
  Empty,
} from "antd";
import { 
  SendOutlined,
  LoadingOutlined 
} from "@ant-design/icons";
import { Center } from "@chakra-ui/react/dist/chakra-ui-react.cjs";
 
import InputMask from 'react-input-mask';

import {
  treeProducts,
  treeCanal,
  getCompanies,
  submitMoskit,
} from "./auxiliar_moskit";

import Main from "../../../Layout/Main";
import MenuComponent from "../../../../components/MenuComponent";
import { itensMenu } from "../itens-menu";
import "./style.css"
import { checkControl } from "../../../../utils/access_control";

const validateMessages = {
  required: "Este campo é obrigatório!",
};

const { Option } = Select;
const { TextArea } = Input;

function Leads(props) {
  const [loading, setLoading] = useState(false);
  const [valueTreeProducts, setValueTreeProducts] = useState("");
  const [optionsCompany, setOptionsCompany] = useState([]);
  const [valueCompany, setValueCompany] = useState("");
  const [valueTreeCanal, setValueTreeCanal] = useState("");
  const [valueName, setValueName] = useState("");
  const [valuePhone, setValuePhone] = useState("");
  const [valueMail, setValueMail] = useState("");
  const [equipes, setEquipes] = useState([]);
  const [valueTreeEquipes, setValueTreeEquipes] = useState("");
  const [vendedores, setVendedores] = useState([]);
  const [valueVendedor, setValueVendedor] = useState("");
  const [observacao, setObservacao] = useState("");
  const [inputPhone, setInputPhone] = useState("");
  const [loadingVendedores, setLoadingVendedores] = useState(false);
  
  const [hiddenVendedor, setHiddenVendedor] = useState(true);
  
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);

  const [form] = Form.useForm();

  const webgmUser = useSelector((state) => state.signin.user.name);

  const signin = useSelector((state) => state.signin);

  useEffect(() => {
    getEquipes();    
  }, []);

  useEffect(() => {
    if(valueTreeEquipes !== "")
      getVendedores();
  }, [valueTreeEquipes]);

  const getVendedores = async () => {
    setLoadingVendedores(true);

    try {
      setVendedores([]);
      const result = await axios.post(
        `https://api.moskitcrm.com/v2/users/search`,
        [
          {
            field: "team",
            expression: "one_of",
            values: [valueTreeEquipes],
          },
          {
            field: "active",
            expression: "is",
            values: [true],
          },
        ],
        {
          headers: {
            apikey: process.env.REACT_APP_MOSKIT_API_KEY,
          },
        }
      );

      setVendedores(result.data);
      setLoadingVendedores(false);
    } catch (e) {
      setTimeout(() => {
        getVendedores();
      }, 1000);
    }
  };

  const getEquipes = async () => {
    const retorno = [];

    const result = await axios.get(
      "https://api.moskitcrm.com/v2/teams?quantity=50",
      {
        headers: { apikey: process.env.REACT_APP_MOSKIT_API_KEY },
      }
    );

    const groups = result.data.filter(
      (equipes) =>
        equipes.hasOwnProperty("parent") && equipes.parent.id === 34692
    );

    groups.map((grupo) => {
      const childrens = result.data
        .filter(
          (result) =>
            result.hasOwnProperty("parent") && result.parent.id === grupo.id
        )
        .map((result) => {
          return {
            title: result.name,
            value: result.id,
          };
        });

      retorno.push({
        title: grupo.name,
        value: grupo.id,
        disabled: true,
        children: childrens,
      });
    });    
    setEquipes(retorno);
  };

  const breadcrumb = [
    {
      title: "Central de Atendimento ao Cliente",
      path: props.modulePath,
    },
    {
      title: "Cadastro de Leads",
      path: props.modulePath + "/leads",
    },
  ];

  const onChangeProducts = (value) => {
    setValueTreeProducts(value);
    setOptionsCompany(getCompanies(value.slice(-1)[0]));
  };

  const onChangeCanal = (value) => setValueTreeCanal(value);

  const onChangeEquipes = (value) =>{
    setValueTreeEquipes(value);
    form.setFieldsValue({ vendedor: "" });
  } 

  const onChangeCheckBox = (e) => {
    setCheckboxChecked(e.target.checked);
    setHiddenVendedor(!!!hiddenVendedor);
  };

  const handleSubmit = async (values) => {
    setLoading(true); 

    try {
      const visible = checkControl(signin.user.profiles, "cac_morada")
      const cemeteryProducts = ['0', '2'];
      const selectedCemeteryProducts = cemeteryProducts.some(valor => values.product.includes(valor));

      if (visible.display == "") {
        if (selectedCemeteryProducts && !isCheckboxChecked) {
          message.error("Selecione a opção 'Contato pré-imediato ou imediato'")
          return
        }       
      }

      values.product = values.product.slice(-1)[0];
      const response = await submitMoskit(values, webgmUser);

      if (response.status !== 200)
        throw new Error("Algo deu errado... Tente novamente.");

      setValueTreeProducts("");
      form.resetFields();

      message.success("Cadastro realizado com sucesso!");
    } catch (error) {
      if (error.message) {
        message.error(error.message);
      } else {
        message.error("Algo deu errado... Tente novamente.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Cadastro de Leads - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensMenu}
            modulePath={props.modulePath}
            selectedMenu="1"
          />
        }
        breadcrumb={breadcrumb}
      >
        <Spin tip="Cadastrando..." spinning={loading}>
          <Form
            layout="vertical"
            form={form}
            name="form-leads"
            onFinish={handleSubmit}
            validateMessages={validateMessages}
            initialValues={{
              product: valueTreeProducts,
              company: valueCompany,
              canal: valueTreeCanal,
              name: valueName,
              phone: valuePhone,
              mail: valueMail,
              equipe: valueTreeEquipes,
              vendedor: valueVendedor,
              observacao: observacao,
            }}
          >
            <Row gutter={8}>
              <Col lg={24} xl={8}>
                <Form.Item
                  name="product"
                  label="Produto de interesse:"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Cascader
                    options={treeProducts}
                    value={valueTreeProducts}
                    onChange={onChangeProducts}
                    expandTrigger="hover"
                  />
                </Form.Item>
              </Col>
              <Col lg={24} xl={8}>
                <Form.Item
                  name="company"
                  label="Empresa de destino:"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select style={{ width: "100%" }}>
                    {optionsCompany.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.company}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={24} xl={8}>
                <Form.Item
                  name="canal"
                  label="Canal de contato:"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TreeSelect
                    style={{ width: "100%" }}
                    value={valueTreeCanal}
                    treeData={treeCanal}
                    placeholder="Selecione um canal..."
                    onChange={onChangeCanal}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col lg={24} xl={8}>
                <Form.Item
                  name="name"
                  label="Nome do cliente:"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={24} xl={8}>
                <Form.Item
                  name="phone"
                  label="Telefone do cliente:"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <InputMask
                    className="mask-phone"
                    mask="(99) 99999-9999"
                    maskChar={null}
                    placeholder="(99) 99999-9999"
                  />
                </Form.Item>
              </Col>
              <Col lg={24} xl={8}>
                <Form.Item
                  name="mail"
                  label="E-mail do cliente:"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                lg={24}
                xl={24}
                style={{
                  marginBottom: "20px",
                }}
              >
                <Checkbox onChange={onChangeCheckBox}>
                  Contato pré-imediato ou imediato
                </Checkbox>
              </Col>
            </Row>
            <Row hidden={hiddenVendedor}>
              <Col
                lg={24}
                xl={24}
                style={{
                  marginBottom: "20px",
                }}
              >
                <Alert
                  message="Ao utilizar essa opção o lead não passará pela roleta."
                  type="warning"
                  showIcon
                  closable
                />
              </Col>
            </Row>
            <Row gutter={8} hidden={hiddenVendedor}>
              <Col lg={24} xl={12}>
                <Form.Item
                  name="equipe"
                  label="Equipe de destino:"
                  rules={[
                    {
                      required: !!!hiddenVendedor,
                    },
                  ]}
                >
                  <TreeSelect
                    style={{ width: "100%" }}
                    value={valueTreeEquipes}
                    treeData={equipes}
                    placeholder="Selecione uma equipe..."
                    onChange={onChangeEquipes}
                  />
                </Form.Item>
              </Col>
              <Col lg={24} xl={12}>
                {loadingVendedores ? (
                  <div style={{ textAlign: "center" }}>
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 24, display: Center }}
                          spin
                        />
                      }
                    />
                    <h1 style={{ color: "gray", fontSize: "15" }}>
                      Carregando vendedores...
                    </h1>
                  </div>
                ) : (
                  <Form.Item
                    name="vendedor"
                    label="Vendedor(a):"
                    rules={[
                      {
                        required: !!!hiddenVendedor,
                      },
                    ]}
                  >
                    <Select
                      notFoundContent={
                        <Empty description="Nenhum vendedor encontrado" />
                      }
                      style={{ width: "100%" }}
                    >
                      {vendedores.map((option) => (
                        <Option key={option.id} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={24} xl={24}>
                <Form.Item
                  name="observacao"
                  label="Observações:"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <TextArea rows={2} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" align="bottom">
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  icon={<SendOutlined />}
                >
                  Cadastrar Lead
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Main>
    </>
  );
}

export default Leads;
