import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";

import { password } from "../../services/password";
import gMorada from "../../assets/images/gmorada-header.png";
import {
  Input,
  Button,
  Row,
  Col,
  Typography,
  Layout,
  Alert,
  Form,
  Progress,
} from "antd";

import { CloudFilled } from "@ant-design/icons";
import { isMobile } from "../../utils/isMobile";

function ResetPassword() {
  const { Title } = Typography;
  const { Content } = Layout;
  const [form] = Form.useForm();
  const [formItemDisabled, setFormItemDisabled] = useState({});
  const [alertVisibility, setAlertVisibility] = useState("none");
  const [errorAlertVisibility, setErrorAlertVisibility] = useState("none");
  const [percent, setPercent] = useState(0);
  const [invalidLinkAlertVisibility, setInvalidLinkAlertVisibility] =
    useState("none");

  const history = useHistory();

  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const id = params.get("id");
  const message = params.get("message");

  useEffect(() => {
    if (!!!token || !!!id) {
      setFormItemDisabled({ disabled: "disabled" });
      setInvalidLinkAlertVisibility("block");
    }
  }, [token, id]);

  useEffect(() => {
    if (percent === 100) return history.push("/signin");
    if (percent === 0) return;

    setTimeout(() => {
      setPercent(percent + 10);
    }, 300);
  }, [percent]);

  const onFinish = (values) => {
    setPercent(10);
    setFormItemDisabled({ disabled: "disabled" });

    password
      .resetPassword(id, token, values["type-password"])
      .then(() => {
        setErrorAlertVisibility("none");
        form.resetFields();
        setAlertVisibility("block");
      })
      .catch(() => {
        setFormItemDisabled({});
        setErrorAlertVisibility("block");
      });
  };

  return (
    <Row align="middle" style={{ height: "100vh" }}>
      <Col
        xs={24}
        sm={24}
        md={{ span: 10, offset: 7 }}
        lg={{ span: 8, offset: 8 }}
        xl={{ span: 8, offset: 8 }}
        className={isMobile() ? "login-page-padding-form wrapper" : "wrapper"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <CloudFilled style={{ fontSize: "50px" }} />
          <Title level={1} style={{ paddingLeft: "10px" }}>
            webGM
          </Title>
        </div>

        <Content className="form-content login-page-padding-form">
          <Form form={form} name="reset-password-form" onFinish={onFinish}>
            <Form.Item
              name="type-password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Preenchimento obrigatório!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("type-password") === value) {
                      let regex =
                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
                      if (!value || value.match(regex)) {
                        return Promise.resolve();
                      } else {
                        const message = (
                          <div>
                            <ul>
                              <li> A senha deve conter de 8 a 15 caracteres</li>
                              <li>
                                pelo menos uma letra minúscula e uma maiúscula
                              </li>
                              <li>
                                um dígito numérico e um caractere especial
                              </li>
                            </ul>
                          </div>
                        );

                        return Promise.reject(message);
                      }
                    }
                    return Promise.reject(
                      "As duas senhas informadas precisam ser iguais!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Insira a senha"
                {...formItemDisabled}
              />
            </Form.Item>
            <Form.Item
              name="retype-password"
              dependencies={["type-password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Preenchimento obrigatório!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("type-password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "As duas senhas informadas precisam ser iguais!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Repita a senha"
                {...formItemDisabled}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                shape="round"
                htmlType="submit"
                block
                {...formItemDisabled}
              >
                Definir senha
              </Button>
            </Form.Item>
          </Form>

          <Alert
            message="Senha definida com sucesso!"
            description="Você será redirecionado(a) para efetuar o login."
            type="success"
            showIcon
            style={{ marginTop: "10px", display: alertVisibility }}
          />

          <Alert
            message="Algo deu errado!"
            description="Por favor, tente novamente."
            type="error"
            showIcon
            style={{ marginTop: "10px", display: errorAlertVisibility }}
          />

          <Alert
            message="Link de redefinição de senha inválido!"
            description="Por favor, verifique se você está utilizando o link correto que foi enviado."
            type="error"
            showIcon
            style={{ marginTop: "10px", display: invalidLinkAlertVisibility }}
          />

          {percent !== 0 ? (
            <>
              <br />
              <Progress percent={percent} />
            </>
          ) : null}
        </Content>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <a
            href="https://www.moradadapaz.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={gMorada} width={150} />
          </a>
        </div>

        <Row justify="center" className="login-page-footer">
          &copy; {new Date().getFullYear()} TI - Grupo Morada
        </Row>
      </Col>
    </Row>
  );
}

export default ResetPassword;
