import { Col, Table } from 'antd';
import StatusComponent from '../../../../../../components/StatusComponent';


function TableInstallments(props) {
  const status = {
    "P": {
      description: "Prorrogado",
      color: "#f4e51a"
    },
    "A": {
      description: "Aberta",
      color: "#92d150"
    },
    "B": {
      description: "Baixada",
      color: "#6c8de9"
    },
    "V": {
      description: "Vencida",
      color: "#d52626"
    },
    "C": {
      description: "Em cobrança",
      color: "!#ffe144"
    },
    "F": {
      description: "Em Proc. Pag.",
      color: "#777777"
    },
    "D": {
      description: "Baixa Parcial",
      color: "#efb555"
    },
    "G": {
      description: "Prescritas",
      color: "#ef57e5"
    },
  }

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value) => {
        return <StatusComponent
          description={status[value]?.description}
          title={status[value]?.description}
          color={status[value]?.color}
        />
      }
    },
    {
      title: 'Título',
      dataIndex: 'installment_code',
      key: 'installment_code',
    },
    {
      title: 'Registro no banco',
      dataIndex: 'bank_status',
      key: 'bank_status',
      render: (value) => (value === true ? "Registrado" : "Não Registrado")
    },
    {
      title: 'opção',
      dataIndex: 'option',
      key: 'option',
    },
    {
      title: 'Nosso Número',
      dataIndex: 'nosso_numero',
      key: 'nosso_numero',
    },
    {
      title: 'Emissão',
      dataIndex: 'issue_date',
      key: 'issue_date',
    },
    {
      title: 'PIX',
      dataIndex: 'pix',
      key: 'pix',
    },
    {
      title: 'Vencimento Original',
      dataIndex: 'original_due_date',
      key: 'original_due_date',
    },
    {
      title: 'Vencimento Atual',
      dataIndex: 'actual_due_date',
      key: 'actual_due_date',
    },
    {
      title: 'Valor Original',
      dataIndex: 'original_amount',
      key: 'original_amount',
      render: (value) => {
        return value?.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      title: 'Valor Atual',
      dataIndex: 'actual_amount',
      key: 'actual_amount',
      render: (value) => {
        return value?.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      title: 'Data de pagamento',
      dataIndex: 'payed_at',
      key: 'payed_at',
    },
    {
      title: 'Valor pago',
      dataIndex: 'amount_paid',
      key: 'amount_paid',
      render: (value) => {
        return value?.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      title: 'Lote',
      dataIndex: 'batch',
      key: 'batch',
    },

  ];
  return (
    <Col span={24}>
      <Table
        dataSource={props.data ?? [{}]}
        columns={columns}
        scroll={{ x: 1300 }}
        onChange={props.onChange}
        pagination={props.pagination}
      />
    </Col>
  )
}

export default TableInstallments;