import { useState, useEffect, useContext } from "react";

import { Button, Radio, Form, Row, Col, notification } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import InputMask from 'react-input-mask';

import ReCAPTCHA from "react-google-recaptcha";

import { NegotiationContext } from "../../../../Context/negotiation-context";

import { duplicateBilletApi } from "../../../../services/duplicateBillet";

function InformCpf(props) {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("Informe o CPF do titular:");
  const [placeholder, setPlaceholder] = useState("000.000.000-00");
  const [mask, setMask] = useState("000.000.000-00");
  const [inputValue, setInputValue] = useState("");
  const [valueRadioButton, setValueRadioButton] = useState(1);
  const [recaptcha, setRecaptcha] = useState("");
  const [disableButton, setDisableButton] = useState(true);

  const negotiationContext = useContext(NegotiationContext);

  const [form] = Form.useForm();

  useEffect(() => {
    if (!inputValue || (!recaptcha && props.activeRecapcha)) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [inputValue, recaptcha]);

  const handleRadioButton = (e) => {
    setValueRadioButton(e.target.value);
  };

  const onChangeReCAPTCHA = (value) => {
    if (value) {
      setRecaptcha(value);
    }
  };

  const handleSubmit = async ({ value }) => {
    setLoading(true);

    try {
      const response = await duplicateBilletApi.listBol(
        value.replace(/[^a-zA-Z0-9]/g, ""),
        {
          headers: {
            tenantId: `03,01`,
          },
        }
      );

      if (!response) throw "Contrato não localizado!";

      negotiationContext.setData({
        ...negotiationContext.data,
        contracts: response.map((value) => {
          return {
            ...value,
            CONTRATO_EMPRESA: `${value.CONTRATO} - ${value.UNIDADE}`,
          };
        }),
      });

      negotiationContext.setCurrent(negotiationContext.current + 1);
    } catch (error) {
      openNotification();
    } finally {
      setLoading(false);
    }
  };

  const openNotification = () => {
    notification.open({
      message: "Não encontramos nenhum contrato!",
      description:
        "Verifique se você informou o CPF ou nº do contrato corretamente.",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
    });
  };

  return (
    <Row justify="center">
      <Col>
        <div>
          <Radio.Group
            onChange={handleRadioButton}
            value={valueRadioButton}
            buttonStyle="solid"
          >
            <Radio.Button
              className="button-content"
              value={1}
              onClick={() => {
                setText("Informe o CPF do titular:");
                setPlaceholder("000.000.000-00");
                setMask("000.000.000-00");
              }}
            >
              USAR CPF
            </Radio.Button>
            <Radio.Button
              className="button-content"
              value={2}
              onClick={() => {
                setText("Informe o n° do contrato:");
                setPlaceholder("00000000");
                setMask("00000000");
              }}
            >
              USAR CONTRATO
            </Radio.Button>
          </Radio.Group>
        </div>
        <br />
        <h2 className="text-choice">{text}</h2>
        <div className="input-data">
          <Form name="form" onFinish={handleSubmit} form={form}>
            <Form.Item name="value">
              <InputMask                  
                  className="mask-input"
                  mask="999.999.999-99"
                  placeholder="000.000.000-00"                                
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}                  
                />    
            </Form.Item>

            {props.activeRecapcha ? (
              <div className="recaptcha-before">
                <ReCAPTCHA
                  className="recaptcha"
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={onChangeReCAPTCHA}
                  style={{ marginBottom: 10 }}
                />
              </div>
            ) : (
              <></>
            )}

            <Button
              type="primary"
              size="large"
              //className="button-search-color"
              htmlType="submit"
              style={{ borderRadius: 5, width: 303 }}
              disabled={disableButton}
              loading={loading}
            >
              {loading ? <>&nbsp; AGUARDE...</> : "CONSULTAR"}
            </Button>
          </Form>
        </div>
      </Col>
    </Row>
  );
}

export default InformCpf;
