import React from "react";
import "./styles.css";

import Main from "../../Layout/Main";
import MenuComponent from "../../../components/MenuComponent";
import itensMenu from "./itens-menu";

function HomeUndertaker(props) {
  const breadcrumb = [
    {
      title: "Agente Funerário",
      path: props.modulePath,
    },
  ];
  // não achei aonde isso se aplica
  return (
    <Main
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent modulePath={props.modulePath} itensMenu={itensMenu} />
      }
      breadcrumb={breadcrumb}
    >
      <h1>Home Agente Funerário</h1>
    </Main>
  );
}

export default HomeUndertaker;
