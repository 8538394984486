import {
    Button,
    Form,
    Input,
    Result,
    Skeleton,
    Space,
    Spin,
    Steps,
} from "antd";
import { InvalidLink } from "./InvalidLink";
import styles from "./styles.module.css";
import { useState } from "react";
import { CreditCard } from "../../../../../components/CreditCard";
import { BiUser } from "react-icons/bi";
import { MdPayment } from "react-icons/md";
import InputMask from "react-input-mask";
import { useMutate } from "../../../../../utils/useMutate";
import { joinRecurrenceMutation } from "../../../../../services/recurrenceService";
import "./override.css";
import Loading from "../../../../../components/Loading";
import moment from "moment";

export function JoinRecurrenceContent({ validationError, data, loading }) {
    const [currentStep, setCurrentStep] = useState(0);
    const [pageStatus, setPageStatus] = useState("progress");

    const { mutate: joinRecurrence, loading: loadingJoinRecurrence } =
        useMutate(joinRecurrenceMutation, {
            onSuccess: () => {
                setPageStatus("success");
            },
            onError: () => {
                setPageStatus("error");
            },
        });

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleSubmit = (name, { values, forms }) => {
        if (name === "customer") {
            const { customer } = forms;
            const { errors } = customer.getFieldsValue();
            if (!errors) {
                nextStep();
            }
        }

        if (name === "creditCard") {
            const { creditCard, customer } = forms;
            const { errors } = creditCard.getFieldsValue();
            const customerValues = customer.getFieldsValue();

            if (errors) {
                console.error(errors);
                return;
            }

            const payload = {
                profile_payment: {
                    ...values,
                    card_expiration: moment(values.card_expiration).format(
                        "MM/YYYY"
                    ),
                },
                token: data.token,
                customer: {
                    ...customerValues,
                    phone: customerValues.phone.replace(/\D/g, ""),
                    cpf: customerValues.cpf.replace(/\D/g, ""),
                },
            };

            joinRecurrence(payload);
        }
    };

    if (loading) return <Loading />;

    if (validationError.hasError) return <InvalidLink title={validationError.title} message={validationError.message}/>;

    if (pageStatus === "error")
        return (
            <Result
                status="warning"
                title="Erro na adesão ao pagamento recorrente."
                subTitle={
                    <p>
                        Por favor, tente novamente mais tarde. Caso o erro
                        persista, entre em contato com a nossa{" "}
                        <a href="https://wa.me/5508001234848">
                            Central de Atendimento.
                        </a>
                    </p>
                }
                extra={
                    <Button
                        className={styles.button}
                        onClick={() => setPageStatus("progress")}
                    >
                        Tentar novamente
                    </Button>
                }
            />
        );

    if (pageStatus === "success")
        return (
            <Result
                status="success"
                title="Adesão ao pagamento recorrente realizada com sucesso."
                subTitle="A partir de agora a cobrança do seu plano será realizada automaticamente."
            />
        );

    return (
        <>
            <Skeleton active loading={loading}>
                <h1 className={styles.title}>
                    Olá, {data.name_client}. <br />
                </h1>
                <p className={styles.subtitle}>
                    Finalize a adesão ao pagamento recorrente do{" "}
                    <span>{data.name_product}</span> (Contrato de{" "}
                    <span>Nº {data.cod_contrt}</span>).
                </p>
            </Skeleton>

            <Steps
                current={currentStep}
                responsive={true}
                className={`${styles.stepItem} override-steps`}
                items={[
                    {
                        title: data.type == "adesao" ? "Confirmação de dados cadastrais" : "Atualize seus dados cadastrais" ,
                        icon: <BiUser />,
                    },
                    {
                        title: "Pagamento",
                        icon: <MdPayment />,
                    },
                ]}
            />
            <Form.Provider onFormFinish={handleSubmit}>
                <Spin spinning={loadingJoinRecurrence}>
                    <div
                        style={{
                            display: currentStep === 0 ? "block" : "none", //Exibição condicional por style para evitar perca de dados do form
                        }}
                        className={styles.borderWrapper}
                    >
                        <h2 className={styles.title}>
                            {data.type == "adesao" ? "Confirmação de dados cadastrais" : "Atualize seus dados cadastrais" }
                        </h2>
                        <Form
                            name="customer"
                            initialValues={{
                                name: data.name_client,
                                cpf: data.cpf_client,
                                email: data.email_client,
                                phone: data.phone_client,
                            }}
                            layout="vertical"
                        >
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Por favor, informe o seu nome completo.",
                                    },
                                ]}
                                name="name"
                                label="Nome Completo"
                            >
                                <Input className={styles.input} />
                            </Form.Item>

                            <Form.Item required name="cpf" label="CPF">
                                <InputMask disabled mask="999-999-999-99">
                                    {(inputProps) => (
                                        <Input
                                            {...inputProps}
                                            disabled
                                            className={styles.input}
                                            placeholder="Número do CPF"
                                        />
                                    )}
                                </InputMask>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Por favor, informe o seu e-mail.",
                                    },
                                ]}
                                name="email"
                                label="E-mail"
                            >
                                <Input className={styles.input} />
                            </Form.Item>

                            <Form.Item
                                normalize={(value) => {
                                    return value.replace(/\D/g, "");
                                }}
                                required
                                name="phone"
                                label="DDD + Nº  Celular"
                            >
                                <InputMask mask="(99) 9 9999-9999">
                                    {(inputProps) => (
                                        <Input
                                            {...inputProps}
                                            className={styles.input}
                                            placeholder="(99) 9 9999-9999"
                                        />
                                    )}
                                </InputMask>
                            </Form.Item>

                            <Button
                                htmlType="submit"
                                style={{ marginTop: "3rem" }}
                                type="primary"
                                block
                                className={styles.button}
                            >
                                Próximo
                            </Button>
                        </Form>
                    </div>

                    <Space
                        direction="vertical"
                        style={{
                            width: "100%",
                            display: currentStep === 1 ? "flex" : "none", //Exibição condicional por style para evitar perca de dados do form
                        }}
                    >
                        <Button
                            className={styles.button}
                            block
                            onClick={prevStep}
                        >
                            Voltar
                        </Button>
                        <CreditCard
                            title="Informe seus dados de pagamento"
                            footerMessage=""
                            chargeValue={parseFloat(data.value_product)}
                            type={data.type}
                            loading={loading}
                        />
                    </Space>
                </Spin>
            </Form.Provider>
        </>
    );
}
