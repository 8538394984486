import React, { useState, useEffect } from "react";

import "./style.css";

import Task from "../Task";

import { Col, Empty } from "antd";

import { useHistory } from "react-router";

function ColumnTasks(props) {
  const history = useHistory();

  return (
    <div className="kanban-column">
      <span
        className="header-color-tag"
        style={{ backgroundColor: props.color }}
      ></span>
      <div className="column-header">
        <div className="column-header-title">{props.description}</div>
        <div className="column-header-right">{props.tasks.length}</div>
      </div>
      <div className="column-body">
        {props.tasks.length > 0 ? (
          props.tasks.map((task) => {
            return (
              <Task
                key={task.id}
                id={task.Order.BaseService.num_attendance_erp}
                name={task.Order.BaseService.name_deceased}
                start={task.createdAt}
                product={task.Product}
                is_blocked_attendance={
                  task.Order.BaseService.is_blocked_attendance
                }
                blocked={task.is_blocked_default}
                user={task.User}
                os={task.Order.cod_budget}
                id_sector={task.Order.id_sector}
                sector_id_last_change={task.sector_id_last_change}
                settingService={task.SettingService}
                clickTask={() => {
                  if (props.withCustomClickServiceOrder === true) {
                    props.columnId(task);
                    history.push(
                      "/" +
                        props.modulePath +
                        "/appointment/" +
                        task.id +
                        "/" +
                        task.Order.BaseService.num_attendance_erp +
                        "/" +
                        task.id_general_status +
                        "/" +
                        task.id_setting_service
                    );
                  } else {
                    props.columnId(task);
                    props.clickColumnTasks();
                  }
                }}
                order={task.Order}
                typeKanban={props.description}
              />
            );
          })
        ) : (
          <Empty className="empty-box" description="Sem Serviço" />
        )}
      </div>
    </div>
  );
}

export default ColumnTasks;
