import { useState } from "react";

import {
  Switch,
  Button,
  Row,
  Col,
  Tag,
  Spin,
  Table,
  message,
  Popconfirm,
} from "antd";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";

import Main from "../../../Layout/Main";
import MenuComponent from "../../../../components/MenuComponent";
import { itensDrawer } from "../itens-menu";
import { checkControl } from "../../../../utils/access_control";
import { useFetch } from "../../../../utils/useFetch";
import {
  deleteApplicationMutation,
  getApplicationsQuery,
} from "../../../../services/notificationService";
import { ModalApp } from "../../../../components/Modal";
import { openModal } from "../../../../store/ducks/modalGlobal";
import { useDispatch } from "react-redux";
import ApplicatinoForm from "./components/ApplicationForm";
import { useEffect } from "react";
import { useMutate } from "../../../../utils/useMutate";

const Notification = (props) => {
  const [active, setActive] = useState(true);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const [currentApplication, setCurrentApplication] = useState(null);
  const [modalOperation, setModalOperation] = useState("");
  const {
    data: applications,
    loading,
    refetch: refetchApplications,
  } = useFetch(getApplicationsQuery);

  const { mutate: deleteApplication } = useMutate(deleteApplicationMutation, {
    onSuccess: () => {
      message.success("Aplicação deletada com sucesso!");
      refetchApplications();
    },
    onError: () => {
      message.error("Erro ao deletar aplicação!");
    },
  });

  const signin = useSelector((state) => state.signin);
  const dispatch = useDispatch();

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Central de notificações",
      path: props.modulePath + "/notifications",
    },
    {
      title: "Aplicações",
      path: props.modulePath + "/notifications-application",
    },
  ];

  const handleOpenModal = (data) => {
    dispatch(openModal(true));
  };
  const handleDelete = (id) => {
    deleteApplication({ id });
  };

  const handleFinish = () => {
    dispatch(openModal(false));
    setCurrentApplication(null);
    refetchApplications();
  };

  useEffect(() => {
    refetchApplications({ active });
  }, [active]);

  function handleTableChange(pagination) {
    setPage(pagination.current);
  }

  let columns = [
    {
      title: "Código",
      dataIndex: "code",
    },
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "Situação",
      dataIndex: "deleted_at",
      render: (isDeleted) => {
        return (
          <Tag color={isDeleted ? "volcano" : "green"}>
            {isDeleted ? "Inativo" : "Ativo"}
          </Tag>
        );
      },
    },
    {
      title: "Ações",
      render: (record) => {
        return (
          <Row justify="space-evenly">
            <Col>
              <Button
                title="Editar"
                type="default"
                icon={<EditOutlined />}
                onClick={() => {
                  setCurrentApplication(record);
                  setModalOperation("update");
                  handleOpenModal();
                }}
                style={{
                  borderRadius: "50%",
                }}
              />
            </Col>
            <Col>
              <Popconfirm
                title="Deseja realmente deletar esta aplicação?"
                onConfirm={() => handleDelete(record.id)}
                okText="Sim"
                cancelText="Não"
                placement="left"
              >
                <Button
                  title="Deletar"
                  danger
                  size="middle"
                  type="default"
                  icon={<DeleteOutlined />}
                  onClick={() => {}}
                  style={{
                    // ...checkControl(signin.user.profiles, "save_and_update_products"),
                    borderRadius: "50%",
                  }}
                />
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin tip="Carregando notificações..." spinning={loading}>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="12"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <Row gutter={[8, 8]} justify="space-between">
            <Col>
              <span
                style={checkControl(
                  signin.user.profiles,
                  "products_inactivate"
                )}
              >
                <Switch
                  checkedChildren="Inativos"
                  unCheckedChildren="Ativos"
                  onChange={() => setActive(!active)}
                  style={{ marginRight: "5px", marginTop: "5px" }}
                />
              </span>
            </Col>
            <Col>
              <Button
                type="primary"
                size="middle"
                icon={<PlusOutlined />}
                onClick={() => {
                  setCurrentApplication(null);
                  setModalOperation("create");
                  handleOpenModal();
                }}
                style={{
                  ...checkControl(
                    signin.user.profiles,
                    "save_and_update_products"
                  ),
                  marginLeft: "20px",
                }}
              >
                Nova aplicação
              </Button>
            </Col>
          </Row>
        }
      >
        <Table
          columns={columns}
          dataSource={applications?.rows.map((column, index) => ({
            ...column,
            key: index,
          }))}
          loading={loading}
          offset={page * rowsPerPage}
          scroll={{ x: 500 }}
          pagination={{
            current: page,
            pageSize: rowsPerPage,
            total: applications?.count,
          }}
          onChange={handleTableChange}
          profiles={signin.user.profiles}
        />
        <ModalApp
          title={
            modalOperation.match(/create/)
              ? "Nova aplicação"
              : `Atualizar aplicação: ${currentApplication?.name}`
          }
          width={1000}
          footer={null}
        >
          <ApplicatinoForm
            currentApplication={currentApplication}
            onFinish={handleFinish}
          />
        </ModalApp>
      </Main>
    </Spin>
  );
};

export default Notification;
