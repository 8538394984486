import axios from "axios";
// import "firebase/messaging"
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { onMessage, getToken as getMessageToken,deleteToken } from "firebase/messaging";

export const api = axios.create({
  baseURL: process.env.REACT_APP_FIREBASE_API,
  headers: {
    "Content-Type": "application/json",
  },
});

export const instanceApi = (tenantId) => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_FIREBASE_API,
    headers: {
      "Content-Type": "application/json",
      tenantId,
    },
  });

  return api;
};

async function handleHttpError(error) {
  return error;
}

function makeHttpRequest(apiCall) {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await apiCall();
      resolve(data.data);
    } catch (e) {
      reject(handleHttpError(e));
    }
  });
}

export const getRequest = async (path, options = {}) =>
  await makeHttpRequest(() => api.get(path, options));
export const postRequest = (path, options = {}, headers = {}) =>
  makeHttpRequest(() => api.post(path, options, headers));
export const putRequest = (path, options = {}, headers) =>
  makeHttpRequest(() => api.put(path, options, headers));
export const deleteRequest = (path, options = {}) =>
  makeHttpRequest(() => api.delete(path, options));

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_MESSAGE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_MESSAGE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_MESSAGE_APP_ID,
};

// Initialize Firebase
let app;
let messaging;
try {
  app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
} catch (error) {
  console.error(error);
}
export { messaging };

export const getToken = async (callback) => {
  try {
    if (!messaging) {
      throw new Error("Firebase Messaging is not initialized");
    }
    const currentToken = await getMessageToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_MESSAGE_VAPID_KEY,
    });
    if (currentToken) {
      console.log(
        "[Firebase Message] - current token for client: ",
        currentToken
      );
      callback && callback(currentToken);
    } else {
      console.log(
        "[Firebase Message] - No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.error("[Firebase Message] - An error occurred while retrieving token. ", err);
  }
};

export const deleteMessageToken = async () => {
  try {
    console.log("[Firebase Message] - Deleting token");
    if (!messaging) {
      throw new Error("Firebase Messaging is not initialized");
    }
    return await deleteToken(messaging);
  } catch (err) {
    console.error(
      "[Firebase Message] - An error occurred while deleting token. ",
      err
    );
  }
};

export const onMessageListener = (callback) => {
  try {
    if (!messaging) {
      throw new Error("Firebase Messaging is not initialized");
    }
    return onMessage(messaging, (payload) => {
      callback && callback(payload);
    });
  } catch (err) {
    console.error("[Firebase Message] - An error occurred while retrieving token. ", err);
  }
};
