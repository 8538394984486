import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Tag, Switch, Button, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import "./styles.css";

import { openModal } from "../../../../store/ducks/modalGlobal";
import { listCompany, alterCompany } from "../../../../store/ducks/companies";
import { listCompanyApi } from "../../../../services/companies";

import MainCrud from "../../../../components/MainCrud";
import Main from "../../../Layout/Main";
import { ModalApp } from "../../../../components/Modal";
import FormApp from "./components/Form";

import { checkControl } from "../../../../utils/access_control";
import { itensDrawer } from "../itens-menu";
import MenuComponent from "../../../../components/MenuComponent";

function Companies(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const signin = useSelector((state) => state.signin);
  const companies = useSelector((state) => state.companies);
  const dispatch = useDispatch();

  let columns = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "CNPJ",
      dataIndex: "cnpj",
    },
    {
      title: "Contato",
      dataIndex: "phone",
    },
    {
      title: "Situação",
      dataIndex: "active",
      render: (active) => {
        let color = "";
        if (active === true) {
          color = "green";
        } else {
          color = "volcano";
        }
        return <Tag color={color}>{active ? "Ativo" : "Inativo"}</Tag>;
      },
    },
  ];

  useEffect(() => {
    if (!checked) {
      list(true);
    } else {
      list(false);
    }
  }, [page, rowsPerPage, companies.company]);

  useEffect(() => {
    setPage(1);
  }, [checked]);

  function list(active) {
    try {
      setLoading(true);

      const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
      listCompanyApi
        .listCompany(rowsPerPage, offset, active)
        .then((response) => {
          response.rows.map((row) => {
            row.cnpj = row.cnpj.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
            );
            if (row.phone.length === 11)
              row.phone = row.phone.replace(
                /^(\d{2})(\d{5})(\d{4})/,
                "($1) $2-$3"
              );
            if (row.phone.length === 10)
              row.phone = row.phone.replace(
                /^(\d{2})(\d{5})(\d{4})/,
                "($1) $2-$3"
              );
            row.children.map((children) => {
              children.cnpj = children.cnpj.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
              );
              if (children.phone.length === 11)
                children.phone = children.phone.replace(
                  /^(\d{2})(\d{5})(\d{4})/,
                  "($1) $2-$3"
                );
              if (children.phone.length === 10)
                children.phone = children.phone.replace(
                  /^(\d{2})(\d{4})(\d{4})/,
                  "($1) $2-$3"
                );
            });
          });
          dispatch(listCompany(response));
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      message.error("Ops! Aconteceu um erro ao carregar os dados.");
    }
  }

  const activeSwitch = (checked) => {
    if (checked) {
      list(false);
      setChecked(true);
      message.info("Exibindo empresas inativas");
    } else {
      list(true);
      setChecked(false);
      message.info("Exibindo empresas ativas");
    }
  };

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(10);
  }

  const handleOpenModal = (data) => {
    dispatch(openModal(true));
    dispatch(
      alterCompany({
        ...data,
      })
    );
  };

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Empresas",
      path: props.modulePath + "/companies",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Empresas - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="15"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <>
            <span
              style={checkControl(
                signin.user.profiles,
                "check_companies_inactivated"
              )}
            >
              <Switch
                checkedChildren="Inativos"
                unCheckedChildren="Ativos"
                onChange={activeSwitch}
                style={{ marginRight: "5px", marginTop: "5px" }}
              />
            </span>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={handleOpenModal}
              style={{
                ...checkControl(signin.user.profiles, "insert_company"),
                marginLeft: "20px",
              }}
            >
              Novo
            </Button>
          </>
        }
      >
        {companies.companyList && companies.companyList.length !== 0 ? (
          <MainCrud
            handleOpenModal={handleOpenModal}
            columnsTable={columns}
            dataSource={companies.companyList}
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            countPages={companies.companyListCount}
            handleTableChange={handleTableChange}
            activeSwitch={activeSwitch}
            profiles={signin.user.profiles}
            classTag={[]}
            displayMainCrud="none"
          />
        ) : (
          <Empty description="Nenhum empresa encontrado" />
        )}
        <ModalApp title="Empresa" width={1000} footer={null}>
          <FormApp />
        </ModalApp>
      </Main>
    </>
  );
}

export default Companies;
