import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const ModulesService = {
  getModules: async () => {
    return await getRequest("/modules", {});
  },
  getModulesIndex: async (limit, offset, active) => {
    return await getRequest(
      `/modules/index?limit=${limit}&offset=${offset}&active=${active}`,
      {}
    );
  },
  create: async (body) => {
    const dados = await postRequest("/module", body);
    return dados;
  },
  update: async (body) => {
    const dados = await putRequest(`/module?id=${body.id}`, body);
    return dados;
  },
  delete: async (id) => {
    const dados = await deleteRequest(`/module?id=${id}`, {});
    return dados;
  },
};
