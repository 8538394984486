/**
 * Valida o número do cartão de crédito
 * 
 * @param {string} num - Número do cartão de crédito
 * @returns {boolean} - Retorna true se o cartão for válido
 */
export function validateCreditCard(num) {
    var regex = new RegExp("^[0-9]{16}$");
    num = num.replace(/\s/g, "");
    if (!regex.test(num)) return false;


    return luhnCheck(num);
}

// função para identificar a bandeira do cartão
export function getCardFlag(cardnumber) {
    cardnumber = cardnumber.replace(/[^0-9]+/g, '');

    var cards = {
        visa: /^4[0-9]{12}(?:[0-9]{3})/,
        mastercard: /^5[1-5][0-9]{14}/,
        diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        american_express: /^3[47][0-9]{13}/,
        hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
        elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    };

    for (var flag in cards) {
        if (cards[flag].test(cardnumber)) {
            return flag;
        }
    }

    return "";
}

/**
 * Algoritimo de Luhn para validar cartão de crédito
 */
const luhnCheck = num => {
  const arr = (num + '')
    .split('')
    .reverse()
    .map(x => parseInt(x));
  const lastDigit = arr.shift();
  let sum = arr.reduce(
    (acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val)),
    0
  );
  sum += lastDigit;
  return sum % 10 === 0;
};
