import { getRequest } from "../utils/apiProtheus";

export const appointmentsApiProtheus = {
  // Busca dados do atendimento direto do protheus
  getDetailsAttendanceProtheus: async (num_attendance_erp, headers) =>
    await getRequest(
      `/all/restatendimento/getatendimento?cod=${num_attendance_erp}`,
      headers
    ),
};
