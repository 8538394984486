import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "antd";

export default function TableForm(props) {
  const data =
    props.dataDefault && props.dataDefault.length !== 0
      ? props.dataDefault.map((item) => item.id)
      : [];

  const [rowsSelected, setRowsSelected] = useState(data);

  const modal = useSelector((state) => state.modalGlobal.open_modal_secondary);

  useEffect(() => {
    const newData =
      props.dataDefault && props.dataDefault.length !== 0
        ? props.dataDefault.map((item) => item.id)
        : [];

    setRowsSelected(newData);
  }, [props.page, modal]);

  const rowSelection = {
    selectedRowKeys: rowsSelected,
    onChange: (selectedRowKeys, selectedRows) => {
      props.selectData(selectedRows);
      setRowsSelected(selectedRowKeys);
    },
  };

  const resources = props.data;
  if (props.resources) {
    resources.map((item) => {
      if (!item.id_resource) {
        delete item.children;
        return item;
      }
    });
  }

  return (
    <>
      <Table
        columns={props.columns}
        rowSelection={
          props.rowSelec ? { ...rowSelection, checkStrictly: false } : false
        }
        dataSource={resources}
        rowKey={(record) => record.id}
        onChange={props.handleTableChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              if (props.removeData) {
                props.removeData(record);
              } else if (props.handleOpenModal) {
                props.handleOpenModal(record);
              }
            },
          };
        }}
        size="small"
        loading={props.loading}
        pagination={{
          current: props.page,
          pageSize: props.rowsPerPage,
          total: props.countPages,
        }}
        components={
          props.tableSort
            ? {
                body: {
                  wrapper: props.DraggableContainer,
                  row: props.DraggableBodyRow,
                },
              }
            : false
        }
      />
    </>
  );
}
