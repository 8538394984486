import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import Main from "../../../Layout/Main";
import MainCrud from "../../../../components/MainCrud";
import FormGoals from "./components/FormGoals";

import { GoalService } from "../../../../services/indicatorGoals";
import loadingSVG from "../../../../assets/images/loading.svg";

import { message, Divider, Empty, Button, Popconfirm } from "antd";

import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import MenuComponent from "../../../../components/MenuComponent";
import { itensDrawer } from "../itens-menu";

function Goal(props) {
  const signin = useSelector((state) => state.signin);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [goals, setGoals] = useState();
  const [goal, setGoal] = useState({});

  useEffect(() => {
    list();
  }, [signin.user.currentCompany]);

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Metas",
      path: props.modulePath + "/administrative/goal",
    },
  ];

  let columns = [
    {
      title: "Tipo da meta",
      dataIndex: "goal_type",
    },
    {
      title: "Meta",
      dataIndex: "goal",
      render: (goal) => {
        if (goal) {
          if (goal.length > 3) {
            return moment(goal).format("HH:mm:ss");
          } else {
            return `${goal}%`;
          }
        } else {
          return "Período de datas";
        }
      },
    },
    {
      title: "Data inicial",
      dataIndex: "start_date",
      render: (value) => {
        if (value) {
          return value;
        }
      },
    },
    {
      title: "Data final",
      dataIndex: "end_date",
      render: (value) => {
        if (value) {
          return value;
        }
      },
    },
    {
      render: (data) => (
        <Popconfirm
          title="Deseja remover a meta?"
          onConfirm={() => removeGoal(data)}
          okText="Sim"
          cancelText="Não"
        >
          <Button type="danger" shape="circle" ghost size="small">
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
      align: "right",
    },
  ];

  async function list() {
    setLoading(true);

    const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
    GoalService.index(rowsPerPage, offset, signin.user.currentCompany)
      .then((response) => {
        setGoals(response);
      })
      .catch(() => message.error("Erro ao carregar dados!"))
      .finally(() => setLoading(false));
  }

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(10);
  }

  const refreshTable = (value) => {
    setGoal(value);
    list();
  };

  const removeGoal = async (value) => {
    setLoading(true);

    GoalService.delete(value.id)
      .then(() => message.success("Meta deletada com sucesso!"))
      .catch(() => message.error("Erro ao deletar a meta!"))
      .finally(() => list());
  };

  return (
    <>
      <Helmet>
        <title>Metas - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="8"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
      >
        <FormGoals refreshTable={refreshTable} goal={goal} />
        <Divider />
        {loading ? (
          <>
            <div className="loading-centar">
              <img src={loadingSVG} alt="loading" height={80} />
            </div>
            <h2 className="text-loading">Carregando...</h2>
          </>
        ) : goals && goals.rows.length === 0 ? (
          <Empty description="Nenhum meta cadastrada." />
        ) : (
          <MainCrud
            columnsTable={columns}
            dataSource={goals && goals.rows.length !== 0 ? goals.rows : []}
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            countPages={goals && goals.count ? goals.count : 0}
            handleTableChange={handleTableChange}
            profiles={signin.user.profiles}
            displayMainCrud="none"
            classTag={[]}
          />
        )}
      </Main>
    </>
  );
}

export default Goal;
