import { useState } from "react";

import { awsApi } from "../services/aws";

export const useUploadFile = ({ onSuccess, onError } = {}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const uploadFile = async (
    file,
    variables = {
      prefixname: "",
      folder: "",
      format: "",
      width: "",
      quality: "",
    }
  ) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file, variables.prefixname);

      const response = await awsApi.upload(formData, variables);

      setData(response.data);
      if (typeof onSuccess == "function") onSuccess();
      // return result;
    } catch (err) {
      setData(null);
      if (typeof onError == "function") onError();
    } finally {
      setLoading(false);
    }
  };

  return { uploadFile, loading, data, reset: () => setData(null) };
};
