import { postRequest } from "../utils/api";

export const sessionApi = {
  signIn: (values) =>
    postRequest(
      "/signin",
      {
        email: values["email"],
        password: values["password"],
        cpf: values["cpf"],
      },
      ""
    ),
    signOut: (user_id, payload) => postRequest(`/signout/${user_id}`, payload),
};
