import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Form,
  InputNumber,
  Button,
  Row,
  Col,
  message,
  Select,
  DatePicker,
  TimePicker,
} from "antd";

import { GoalService } from "../../../../../../services/indicatorGoals";
import loadingSVG from "../../../../../../assets/images/loading.svg";

import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

function FormGoals(props) {
  const signin = useSelector((state) => state.signin);

  const [loading, setLoading] = useState(false);
  const [loadingGoal, setLoadingGoal] = useState(false);
  const [typeGoal, setTypeGoal] = useState("porcentagem");
  const [intervalFilter, setIntervalFilter] = useState([]);

  /**
   * Cria ou edita uma meta
   */
  const onFinish = async (values) => {
    setLoading(true);

    if (props.goal.id) {
      GoalService.update(
        {
          ...props.goal,
          ...values,
          goal_type: typeGoal,
          start_date: intervalFilter[0],
          end_date: intervalFilter[1],
          company_id: signin.user.currentCompany,
        },
        props.goal.id
      )
        .then(() => message.success("Meta alterada com sucesso."))
        .catch(() =>
          message.error("Desculpe, não foi possível alterar a meta!")
        )
        .finally(() => {
          setLoading(false);
          props.refreshTable({});
        });
    } else {
      GoalService.create({
        ...values,
        goal_type: typeGoal,
        start_date: intervalFilter[0],
        end_date: intervalFilter[1],
        company_id: signin.user.currentCompany,
      })
        .then(() => message.success("Meta criada com sucesso."))
        .catch(() =>
          message.error("Desculpe, não foi possível cadastrar a meta!")
        )
        .finally(() => {
          setLoading(false);
          props.refreshTable({});
          window.location.reload();
          return false;
        });
    }
  };

  return loadingGoal ? (
    <>
      <div className="loading-centar">
        <img src={loadingSVG} alt="loading" height={80} />
      </div>
      <h2 className="text-loading">Carregando...</h2>
    </>
  ) : (
    <Form
      name="form_goals"
      onFinish={onFinish}
      initialValues={{
        goal: props.goal ? props.goal.goal : null,
        active: props.goal ? props.goal.active : false,
      }}
    >
      <Row>
        <Col span={2}>
          <Select
            onChange={(value) => setTypeGoal(value)}
            defaultValue={typeGoal}
            style={{ width: 140 }}
          >
            <Option value="porcentagem">Porcentagem</Option>
            <Option value="tempo">Período de tempo</Option>
            <Option value="data">Período de data</Option>
          </Select>
        </Col>
        {typeGoal === "porcentagem" ? (
          <Col span={2} offset={1}>
            <Form.Item name="goal" rules={[{ required: true }]}>
              <InputNumber
                style={{ width: 120 }}
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
              />
            </Form.Item>
          </Col>
        ) : typeGoal === "tempo" ? (
          <Col span={3} offset={1}>
            <Form.Item name="goal" rules={[{ required: true }]}>
              <TimePicker defaultOpenValue={moment("00:00:00", "HH:mm:ss")} />
            </Form.Item>
          </Col>
        ) : (
          <Col offset={1}></Col>
        )}

        <Col
          span={5}
          style={
            typeGoal === "porcentagem" ? { marginLeft: 30 } : { marginLeft: 0 }
          }
        >
          <Form.Item name="data" rules={[{ required: true }]}>
            <RangePicker
              placeholder={["Data inicial", "Data final"]}
              format="DD/MM/YYYY"
              value={intervalFilter}
              onChange={(dates, stringDate) => setIntervalFilter(stringDate)}
            />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item
            wrapperCol={{
              offset: 2,
              span: 4,
            }}
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              {loading ? "Salvando..." : "Inserir Meta"}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default FormGoals;
