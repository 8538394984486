import { createActions } from "reduxsauce";
import { Initials } from "../../utils/text";

export const Types = {
  SIGNIN: "SIGNIN",
  SIGNOUT: "SIGNOUT",
  SET_CURRENT_COMPANY: "SET_CURRENT_COMPANY",
  SET_CURRENT_TENANT_ID: "SET_CURRENT_TENANT_ID",
  SET_CURRENT_MODULE: "SET_CURRENT_MODULE",
  SET_CURRENT_PHOTO: "SET_CURRENT_PHOTO",
};

const INITIAL_STATE = {
  user: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SIGNIN:
      return {
        user: {
          ...action.payload,
          currentModule: {
            module: "",
            id_sector: 0,
          },
          initials: Initials(action.payload.name),
        },
      };
    case Types.SET_CURRENT_COMPANY:
      return {
        user: {
          ...state.user,
          currentCompany: action.payload,
        },
      };
    case Types.SET_CURRENT_TENANT_ID:
      return {
        user: {
          ...state.user,
          tenantId: action.payload,
        },
      };
    case Types.SET_CURRENT_MODULE:
      return {
        user: {
          ...state.user,
          currentModule: action.payload,
        },
      };
    case Types.SIGNOUT: {
      return { user: {} };
    }

    case Types.SET_CURRENT_PHOTO:
      return {
        user: {
          ...state.user,
          avatar_user: action.payload,
        },
      };

    default:
      return state;
  }
}

export function signIn(data) {
  return {
    type: Types.SIGNIN,
    payload: data,
  };
}

export function signOut() {
  return {
    type: Types.SIGNOUT,
    payload: {},
  };
}

export function setCurrentCompany(data) {
  return {
    type: Types.SET_CURRENT_COMPANY,
    payload: data,
  };
}
export function setTenantId(data) {
  return {
    type: Types.SET_CURRENT_TENANT_ID,
    payload: data,
  };
}

export function setCurrentModule(data) {
  return {
    type: Types.SET_CURRENT_MODULE,
    payload: data,
  };
}

export function setAvatar(data) {
  return {
    type: Types.SET_CURRENT_PHOTO,
    payload: data,
  };
}
