const Types = {
  GET: "GET",
  SET: "SET_USER",
  ADD: "ADD",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
};

const INITIAL_STATE = {
  userList: [],
  userListCount: 0,
  user: [],
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        userList: action.payload.rows,
        userListCount: action.payload.count,
      };

    case Types.ADD:
      return {
        ...state,
        userList: action.payload.rows,
        userListCount: action.payload.count,
      };

    case Types.UPDATE:
      return {
        ...state,
        userList: action.payload.rows,
        userListCount: action.payload.count,
      };

    case Types.REMOVE:
      return {
        ...state,
        userList: action.payload.rows,
        userListCount: action.payload.count,
      };

    case Types.SET:
      return {
        ...state,
        user: {
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export function listUser(data) {
  return {
    type: Types.GET,
    payload: data,
  };
}

export function addUser(data) {
  return {
    type: Types.ADD,
    payload: data,
  };
}

export function alterUser(data) {
  return {
    type: Types.SET,
    payload: data,
  };
}

export function updateUser(data) {
  return {
    type: Types.UPDATE,
    payload: data,
  };
}

export function deleteUser(data) {
  return {
    type: Types.REMOVE,
    payload: data,
  };
}
