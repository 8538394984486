import {
  getRequest, postRequest
} from "../utils/api";

export const OfflineSearch = {
  findCustomer: (params) =>
    getRequest(
      `/offline-search/v1/contracts/list`, {
      params
    }),

  findDepedents: (contract, company, branch, contract_type) => getRequest(`/offline-search/v1/contract/dependents/${contract}`, {
    params: {
      company,
      branch,
      contract_type
    }
  }),

  DetailContract: (contract, company, branch, contract_type) => getRequest(`/offline-search/v1/contract/${contract}/details`, {
    params: {
      company,
      branch,
      contract_type
    }
  }),

  getInstallments: (contract, params) => getRequest(`/offline-search/v1/installments/${contract}/details`, {
    params
  }),
 
  getBenefits: (contract, params) => getRequest(`offline-search/v1/benefits/${contract}/details`, {
    params
  }),

  postAutorization: (body) => postRequest("/offline-search/v1/authorizations", body)
};
