import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { Row, Button, Form, Input, Col, message } from "antd";
import {
  MailOutlined,
  CopyOutlined,
  DesktopOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { IoMdDownload } from "react-icons/io";
import { NegotiationContext } from "../../../../Context/negotiation-context";
import loadingSVG from "../../../../assets/images/loading.svg";
import ModalSend from "../Modal";
import { modalNegotiation } from "../../../../store/ducks/modalGlobal";
import { clientBoletosApi } from "../../../../services/boletosCLient";
import { duplicateBilletApi } from "../../../../services/duplicateBillet";

import "./styles.css";

function Send() {
  const [loading, setLoading] = useState(false);
  const [arrowDownload, setArrowDownload] = useState(false);
  const [content, setContent] = useState("");
  const [form] = Form.useForm();
  const [emailClient, setEmailClient] = useState("");

  const negotiationContext = useContext(NegotiationContext);
  const dispatch = useDispatch();

  const messageErrorServer =
    "Ops... Parece que estamos enfrentando problemas em nossos servidores!";

  // faz o download em PDF
  const download = async () => {
    if (negotiationContext.data.contract.installmentsSelected.length === 0)
      return false;

    setLoading(true);
    setArrowDownload(true);

    let values;
    // console.log(negotiationContext.data.contract.negotiation.boleto.BASE64)
    if (
      negotiationContext.data.contract.negotiation &&
      negotiationContext.data.contract.negotiation.boleto
    ) {
      const newData = negotiationContext.data.contract.negotiation.boleto.BASE64
      values = [{ base64: newData, name: `Vencimento ${new Date(
        negotiationContext.data.contract.installmentsSelected[0].VENCIMENTO
      ).toLocaleDateString()} - Boleto Morada da Paz.pdf` }];
    } else {
      values = await handleGenerateBillet(
        negotiationContext.data.contract.installmentsSelected
      );
    }

    if (values.length === 0) return false;
    handleOk(values);
    setLoading(false)
  };

  /**
   * Monta o iframe com o pdf na página do cliente
   */
  const handleOk = (value) => {
    let counter = 0;

    return value.map((file) => {
      downloadPdfFile(file.base64, counter);
      counter++;
    });

  };

  /**
   * Função para baixar o arquivo na máquina do cliente
   */
  const downloadPdfFile = (pdf, counter) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `Vencimento ${new Date(
      negotiationContext.data.contract.installmentsSelected[counter].VENCIMENTO
    ).toLocaleDateString()} - Boleto Morada da Paz`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  // abre o modal de envio de e-mail
  const sendMail = async () => {
    if (negotiationContext.data.contract.installmentsSelected.length === 0)
      return false;

    setLoading(true);

    let values;

    if (negotiationContext.data.contract.negotiation) {
      if (negotiationContext.data.contract.negotiation.link) {
        values = negotiationContext.data.contract.negotiation.link;

        let modal = <RenderModalSendMail />;
        setContent(modal);
        dispatch(modalNegotiation(true));
        return false;
      } else {
        const newData = negotiationContext.data.contract.negotiation.boleto.BASE64
        values = [{ base64: newData, name: `Vencimento ${new Date(
          negotiationContext.data.contract.installmentsSelected[0].VENCIMENTO
        ).toLocaleDateString()} - Boleto Morada da Paz.pdf` }];
      }
    } else {
      values = await handleGenerateBillet(
        negotiationContext.data.contract.installmentsSelected
      );

      if (values.length === 0) return false;
    }

    // Gera o arquivo PDF no backend para envio ou donwload

    let modal = <RenderModalSendMail files={values} />;
    setContent(modal);
    dispatch(modalNegotiation(true));
    setLoading(false);
  };

  // Componente de caixa de envio de e-mail
  const RenderModalSendMail = ({ files }) => {
    return (
      <>
        <div className="title-modal">
          <h3>Para qual e-mail você deseja enviar?</h3>
        </div>
        <br />
        <Form
          name="form-send-mail"
          onFinish={(values) => {
            if (
              negotiationContext.data.contract.negotiation &&
              negotiationContext.data.contract.negotiation.link
            )
              return sendMailClientLinks(values);

            return sendMailClient(values, files);
          }}
          form={form}
        >
          <Form.Item name="email">
            <Input className="input-send-mail" placeholder="seu@email.com" />
          </Form.Item>
          <div className="footer-modal">
            <Button
              size="large"
              danger
              style={{ marginRight: 10, borderRadius: 5 }}
              onClick={handleCloseModal}
            >
              <strong>CANCELAR</strong>
            </Button>
            <Button
              type="primary"
              className="button-modal-negociation"
              size="large"
              htmlType="submit"
            >
              <strong>ENVIAR</strong>
            </Button>
          </div>
        </Form>
      </>
    );
  };

  // envia o e-mail com anexos
  const sendMailClient = ({ email }, files) => {
    setLoading(true);
    setEmailClient(email);
    handleCloseModal();
    clientBoletosApi
      .sendMail({
        files,
        email,
        client: negotiationContext.data.contract.CLIENTE,
      })
      .then(() => {
        message.success(`E-mail enviado com sucesso para: ${email}!`);
      })
      .catch(() => message.error(messageErrorServer))
      .finally(() => setLoading(false));
  };

  // envia o e-mail com links
  const sendMailClientLinks = ({ email }) => {
    setLoading(true);
    setEmailClient(email);
    handleCloseModal();

    clientBoletosApi
      .sendMailLink({
        link: negotiationContext.data.contract.negotiation.link,
        email,
        client: negotiationContext.data.contract.CLIENTE,
      })
      .then(() => {
        message.success(`E-mail enviado com sucesso para: ${email}!`);
      })
      .catch(() => message.error(messageErrorServer))
      .finally(() => setLoading(false));
  };

  // fecha o modal
  const handleCloseModal = () => dispatch(modalNegotiation(false));

  /**
   * Remove o arquivo salvo na pasta tmp do backend
   */
  const removeFile = (file) => {
    clientBoletosApi
      .delete({
        file,
      })
      .then((response) => response);
  };

  /**
   * Gera as parcelas para o cliente, caso necessário será reprogramado
   */
  const handleGenerateBillet = async (billets) => {
    let counter = 0
    return Promise.all(
      billets.map(async (billet) => {
        if (!billet.PREFIXO || !billet.NUMERO || !billet.TIPO) {
          setLoading(false);
          return false;
        }

        return new Promise((resolve, reject) => {
          duplicateBilletApi
            .gerarParcela(
              {
                prefixo: billet.PREFIXO,
                numero: billet.NUMERO,
                parcela: billet.PARCELA,
                tipo: billet.TIPO,
                tipoContrt: billet.TIPOCONTRT,
                contrato: billet.CONTRATO,
              },
              {
                headers: {
                  tenantId: `${billet.EMPRESA},${billet.FILIAL}`,
                },
              }
            )
            .then((response) => {
              resolve({
                "base64": response,
                "name": `Vencimento ${new Date(
                  negotiationContext.data.contract.installmentsSelected[counter].VENCIMENTO
                ).toLocaleDateString()} - Boleto Morada da Paz.pdf`
              });
              counter ++;
            })
            .catch(() => reject(false))
            .finally(() => {
              setLoading(false)
            });
        });

      })
    ).then((response) => {
      return response.filter((value) => {
        if (value) {
          return value;
        }
      });
    });
  };

  const ButtonSendMail = () => (
    <Button
      className="button-send-mail-negociation"
      type="primary"
      onClick={sendMail}
      loading={loading}
    >
      <MailOutlined /> &nbsp; <strong>ENVIAR POR E-MAIL</strong>
    </Button>
  );

  // mostra os botões de download e envio de e-mail
  const ButtonsDowMail = () => (
    <>
      <Button
        style={{ margin: "0 8px" }}
        onClick={() => negotiationContext.setCurrent(0)}
      >
        Nova Consulta
      </Button>
      <Button
        className="button-download-negociation"
        type="primary"
        onClick={download}
        loading={loading}
      >
        <IoMdDownload size={13} />
        &nbsp; <strong>BAIXAR SELECIONADOS</strong>
      </Button>
      <ButtonSendMail />
    </>
  );

  // Componente com URL de pix ou pylink
  const ComponentPixPylink = () => (
    <>
      <Input
        disabled={true}
        value={negotiationContext.data.contract.negotiation.link}
      />
      <div className="buttons-pix-paylink">
        <Button
          style={{ margin: "0 8px" }}
          onClick={() => negotiationContext.setCurrent(0)}
        >
          Nova Consulta
        </Button>
        <Button
          className="button-download-negociation"
          type="primary"
          onClick={() => {
            navigator.clipboard.writeText(
              negotiationContext.data.contract.negotiation.link
            );
            message.success("Copiado para área de transferência");
          }}
        >
          <CopyOutlined size={13} />
          &nbsp; <strong>COPIAR</strong>
        </Button>
        <Button type="primary" className="button-download-negociation">
          <a
            href={negotiationContext.data.contract.negotiation.link}
            target="_blank"
            rel="noreferrer"
          >
            <DesktopOutlined size={13} />
            &nbsp; <strong>ABRIR UMA NOVA GUIA</strong>
          </a>
        </Button>
        <ButtonSendMail />
      </div>
    </>
  );

  // componente que verifica quais botões irão aparecer
  const ButtonsChoice = () => {
    if (
      negotiationContext.data.contract &&
      negotiationContext.data.contract.negotiation
    ) {
      if (negotiationContext.data.contract.negotiation.link) {
        return <ComponentPixPylink />;
      }
    }

    return <ButtonsDowMail />;
  };

  return (
    <>
      <Row justify="center">
        <Col>
          <DownloadOutlined style={{ fontSize: 80, color: "#1890ff" }} />
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 15 }}>
        <Col>
          <h3>Tudo certo, agora você precisa informar como deseja enviar!</h3>
        </Col>
      </Row>
      <Row gutter={(16, 16)} justify="center" style={{ marginTop: 50 }}>
        <Col span={18} offset={6}>
          {loading ? (
            <>
              <div className="loading-centar">
                <img src={loadingSVG} alt="loading" height={80} />
              </div>
              <h2 className="text-loading">Por favor, aguarde...</h2>
            </>
          ) : (
            <ButtonsChoice />
          )}
        </Col>
      </Row>
      <ModalSend content={content} />
    </>
  );
}

export default Send;
