import api from '../utils/api'
export const getCemeteryReserveQuery = async (params) => {
  const response = await api.get(
    `/cemetery-reserve?${new URLSearchParams(params)}`
  );
  return response.data;
}

export const createCemiteryReserveMutation = async (params) => {
  const response = await api.post(
    `/cemetery-reserve/create`,
    params
  );
  return response.data;
}

export const updateCemiteryReserveMutation = async (params) => {
  const response = await api.put(
    `/cemetery-reserve/update`,
    params
  );
  return response.data;
}