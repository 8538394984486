import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const listItemApi = {
  listItem: (limit, offset, active = true) =>
    getRequest(
      "/item?limit=" + limit + "&offset=" + offset + "&active=" + active,
      {}
    ),
};

export const addItemApi = {
  addItem: (body) => postRequest("/item", body),
};

export const updateItemApi = {
  updateItem: (body) => putRequest("/item/" + body.id, body),
};

export const deleteItemApi = {
  deleteItem: (id) => deleteRequest("/item/" + id, {}),
};
