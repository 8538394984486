export const Types = {
  SET_DATA: "SET_DATA",
  REMOVE_DATA: "REMOVE_DATA",
  SET_LOADING: "SET_LOADING",
  SET_BASESERVICE: "SET_BASESERVICE",
};

const INITIAL_STATE = {
  data: {},
  loading: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case Types.REMOVE_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case Types.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case Types.SET_BASESERVICE:
      return {
        ...state,
        baseService: action.payload,
      };

    default:
      return state;
  }
}

export function setData(data) {
  return {
    type: Types.SET_DATA,
    payload: data,
  };
}

export function removeData(data) {
  return {
    type: Types.REMOVE_DATA,
    payload: data,
  };
}

export function setLoadingProtheusData(data) {
  return {
    type: Types.SET_LOADING,
    payload: data,
  };
}

export function setBaseService(data) {
  return {
    type: Types.SET_BASESERVICE,
    payload: data,
  };
}