import ModalExportDataGS from "./components/ModalExportDataGS";
import { itensMenu, itensDrawer } from "./itens-menu";
import { Helmet } from "react-helmet";
import MenuCard from "../../../../components/menu_card";
import { ReportsProvider } from "../../../../Context/reportsContext";

function Reports(props) {
  props = {
    ...props,
    breadcrumb: [
      {
        title: "Configurador",
        path: props.modulePath,
      },
      {
        title: "Relatórios",
        path: props.modulePath + "/reports",
      },
    ],
    itensMenu: itensMenu,
    itensDrawer: itensDrawer,
  };

  return <>
    <Helmet>
      <title>Relatórios - webGM</title>
    </Helmet>
    <MenuCard {...props} />
    <ReportsProvider>
      <ModalExportDataGS
        title="Exportar Dados do Gestão de Serviços"
        footer={null}
      />
    </ReportsProvider>
  </>;
}

export default Reports;
