import AWS from "aws-sdk";
import axiosClient, { getRequest } from "../utils/api";
import { getFileType } from "../utils/file_type";
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});
export const S3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
  },
});

export const awsApi = {
  upload: async (formData, variables) => {
    return await axiosClient.post(
      `/files/upload?${new URLSearchParams(variables)}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  deleteFile: async (file_key) => {
    return await axiosClient.delete(`/files/delete?file_key=${file_key}`);
  },
  fetchFile: async (image_key) => {
    const params = {
      Key: image_key,
    };
    const file = await S3.getObject(params).promise();

  
    const objectString = file.Body.toString();
    // verfica se o arquivo é um base64
    if (objectString.match("^data")) {
      return objectString;
    }
    const mimeType = getFileType(image_key).mimetype;
    const blob = new Blob([file.Body.buffer], {type: mimeType});
    if(blob.size === 0)
      return
    return URL.createObjectURL(blob);
  },
};
