import {
  useEffect,
  useState,
} from 'react';

import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Select,
  message,
  Button,
  Table,
  Divider,
  Popconfirm,
  Switch,
} from 'antd';

import {
  DeleteOutlined,
} from '@ant-design/icons';

import api from '../../../../../../utils/api';
import { useSelector } from 'react-redux';

const FormUserGroup = (props) => {
  const signin = useSelector((state) => state.signin);

  const [loading, setLoading] = useState("");
  const [loadingUsers, setLoadingUsers] = useState("");
  const [nameFilterUser, setNameFilterUser] = useState("");
  const [userSelected, setUserSelected] = useState(null);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [usersGroup, setUsersGroup] = useState([]);

  const company_id = signin.user.currentCompany

  const [formUserGroup] = Form.useForm();

  const columns = [
    {
      dataIndex: "id",
      key: "id",
      title: "ID"
    },
    {
      dataIndex: "name",
      key: "name",
      title: "Nome",
    },
    {
      title: "Ações",
      render: (data) => (
        <Popconfirm
          title="Deseja remover o campo?"
          onConfirm={() => handleRemoveUser(data)}
          okText="Sim"
          cancelText="Não"
        >
          <Button type="danger" shape="circle" ghost size="small">
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
      align: "right",
    },
  ];

  useEffect(() => {
    getUsers();
  }, [nameFilterUser]);

  useEffect(() => {
    formUserGroup.setFieldsValue(props.userGroup);

    if (props.userGroup.users) {
      setUsersGroup(props.userGroup.users);
    }
  }, [props.userGroup]);

  const getUsers = async () => {
    try {

      const result = await api.get("/users", {
        params: {
          name: nameFilterUser,

        }
      });

      setUsers(result.data);
    } catch (error) {
      message.error(error);
    } finally {
      setLoadingUsers(false);
    }
  }

  const onFinish = async (values) => {
    try {
      setLoading(true);

      const data = {
        ...values,
        users: usersGroup,
        company_id: company_id
      }

      if (props.userGroup?.id) {

        const result = await api.put("/user-groups", data, {
          params: {
            id: props.userGroup.id,
          },
        });

        props.setShowDrawer(false);
        props.setReloadIndex(true);

        message.success("Grupo de usuário alterado com sucesso.")
      } else {
        const result = await api.post("/user-groups", data);

        message.success("Grupo de usuário cadastrado com sucesso.")
      }


    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleAddUser = async () => {
    try {
      setLoadingUsers(true);

      const user = await users.find((user) => user.id === userSelected);

      if (usersGroup.includes(user)) throw "Usuário já selecionado para esse grupo.";

      setUsersGroup([user, ...usersGroup]);
      setUserSelected(null);

    } catch (error) {
      message.warning(error)
    } finally {
      setLoadingUsers(false);
    }

  }

  const handleRemoveUser = (data) => {
    try {
      setLoadingUsers(true);
      setUsersGroup(usersGroup.filter(user => user.id !== data.id));

    } catch (error) {
      message.error(error);
    } finally {
      setLoadingUsers(false);
    }
  }

  return (
    <Spin spinning={loading} tip="Salvando...">
      {console.log(props.userGroup)}
      <Form
        name="form-filter"
        form={formUserGroup}
        onFinish={onFinish}
        layout="vertical"
        wrapperCol={{
          span: 24,
        }}
      >
        <Row>
          <Col xs={24}>
            <Form.Item
              name="name"
              label="Nome">
              <Input
                placeholder="Digite o nome do grupo de usuários"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              name="description"
              label="Descrição">
              <Input
                placeholder="Digite a descrição do grupo de usuários"
              />
            </Form.Item>
          </Col>
        </Row>
        {
          props.userGroup.active === false
            ?
            <Row>
              <Col xs={24}>
                <Form.Item
                  name="active"
                  label="Ativo">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            :
            null
        }
        <Divider prefixCls='Teste' style={{ marginTop: "5px", marginBottom: "5px" }} />
        <Row gutter={[8, 8]} justify='space-between'>
          <Col xs={22}>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Selecione o usuário"
              value={userSelected}
              onChange={(value) => setUserSelected(value)}
              style={{ width: "100%" }}
            >
              {
                users.map(user => {
                  return <Select.Option key={user.id} value={user.id}>{user.name}</Select.Option>
                })
              }
            </Select>
          </Col>
          <Col xs={2}>
            <Button
              type='primary'
              onClick={handleAddUser}
            >
              Incluir
            </Button>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <Table
              size="small"
              columns={columns}
              dataSource={usersGroup}
              rowKey={(record) => record.sequence}
              loading={loadingUsers}
              pagination={{
                current: page,
                pageSize: rowsPerPage,
                total: usersGroup.length,
                showSizeChanger: true,
                onShowSizeChange: function (current, size) {
                  setRowsPerPage(size);
                },
                pageSizeOptions: [5, 10, 50, 100],
              }}
              onChange={(pagination) =>
                setPage(pagination.current)
              }
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]} justify="end">
          <Col>
            <Form.Item
              label=" "
              colon={false}
            >
              <Button type="primary" htmlType="submit">
                {(props.userGroup?.id) ? 'Alterar' : 'Cadastrar'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}

export default FormUserGroup;