/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Drawer,
  Form,
  Input,
  Select,
  Switch,
  Empty,
} from "antd";
import { PlusOutlined, FilterFilled, FilterOutlined } from "@ant-design/icons";
import Main from "../../../Layout/Main";
import { itensMenu } from "../itens-menu";
import MenuComponent from "../../../../components/MenuComponent";
import StatusComponent from "../../../../components/StatusComponent";
import Actions from "./components/Actions";
import { useReducer } from "react";
import { DetailsModal } from "./components/DetailsModal";
import { CancelModal } from "./components/CancelModal";
import { CreateModal } from "./components/CreateModal";
import { useFetch } from "../../../../utils/useFetch";
import { getPaymentsPixQuery } from "../../../../services/paymentPix";
import { ResendEmailModal } from "./components/ResendEmailModal";
import { useDebounce } from "../../../../utils/useDebounce";
import { useSession } from "../../../../Context/SessionProvider";
import { Helmet } from "react-helmet";
import { removeNullable } from "../../../../utils/object";
import { MaskedInput } from "antd-mask-input";
import moment from "moment";
import { useSelector } from "react-redux";

export const statusDictionary = {
  10: {
    description: "Pendente",
    color: "#FFC107",
  },
  4: {
    description: "Cancelado",
    color: "#DC3545",
  },
  6: {
    description: "Finalizado",
    color: "#28A745",
  },
  8: {
    description: "Aguardando RA",
    color: "#007BFF",
  },
  "expired": {
    description: "Expirado",
    color: "#6C757D",
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "details":
      return {
        ...state,
        details: action.payload,
      };
    case "resend":
      return {
        ...state,
        resend: action.payload,
      };
    case "cancel":
      return {
        ...state,
        cancel: action.payload,
      };
    case "create":
      return {
        ...state,
        create: action.payload,
      };
    default:
      return state;
  }
};


export function getStatus ({ statusId, createdAt, expiresIn }) {
  const expirationDate = moment(createdAt).add(expiresIn, "hours")

  const isExpired = moment().isAfter(expirationDate)

  if (statusId === 10 && isExpired) {
    return statusDictionary["expired"];
  }

  return statusDictionary[statusId];
}

function PIX(props) {
  const { session } = useSession();
  const [filterForm] = Form.useForm();

  const [visibleModal, setVisibleModal] = useReducer(reducer, {
    details: false,
    resend: false,
    cancel: false,
    create: false,
  });
  const [openFilter, setOpenFilter] = useState(false);

  const [currentPayment, setCurrentPayment] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const [filters, setFilters] = useState({});
  const hasFilter = Object.keys(filters).some((key) => filters[key] !== "");

  const debouncedFilters = useDebounce(filters, 400);

  const {
    data: payments,
    loading,
    refetch: refetchPayments,
  } = useFetch(getPaymentsPixQuery, {
    initialState: {},
    initialParams: { page: 0 },
  });

  const columnsTable = [
    {
      title: "Nº da proposta",
      dataIndex: "proposal",
      width: 130,
    },
    {
      title: "Negócio da proposta",
      dataIndex: "businessProposal",
    },
    {
      title: "Valor do PIX",
      dataIndex: "value",

      render: (value) => {
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (id, record) => {
       const status = getStatus({ statusId: id, expiresIn: record.expiration, createdAt: record.createdAt});
       
        if (!status) {
          return null;
        }
        return (
          <StatusComponent
            description={status.description}
            title={status.description}
            color={status.color}
          />
        );
      },
    },
    {
      title: "Ações",
      dataIndex: "actions",
      align: "left",
      render: (_, record) => {
        return (
          <Actions
            onClick={() => setCurrentPayment(record)}
            onOpenModal={setVisibleModal}
            data={record}
          />
        );
      },
    },
  ];

  const breadcrumb = [
    {
      title: "Financeiro",
      path: props.modulePath,
    },
    {
      title: "PIX",
      path: props.modulePath + "/payment-pix",
    },
  ];

  function handleSubmit(values) {
    setFilters(values);
    setOpenFilter(false);
  }

  useEffect(() => {
    removeNullable(debouncedFilters);

    refetchPayments({ page: 0, ...debouncedFilters });
  }, [debouncedFilters]);

  return (
    <Main
      key={props.moduleName}
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          modulePath={props.modulePath}
          selectedMenu="4"
          itensMenu={itensMenu}
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"flex"}
      buttonHeader={
        <Row style={{ marginRight: ".5rem" }} gutter={24} justify="end">
          <Form.Item
            style={{ margin: "0 .5rem" }}
            tooltip="Quando ativo, exibe apenas os PIX gerados pelo usuário logado"
            label="Meus PIX"
          >
            <Switch
              onChange={(value) => {
                setFilters({ userId: value ? session.user.id : null });
              }}
              defaultChecked={false}
            />
          </Form.Item>
          <Col>
            <Button
              icon={hasFilter ? <FilterFilled /> : <FilterOutlined />}
              onClick={() => setOpenFilter(!openFilter)}
            ></Button>
          </Col>

          <Button
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => setVisibleModal({ type: "create", payload: true })}
          >
            Gerar PIX
          </Button>
        </Row>
      }
    >
      <Helmet>
        <title>PIX - webGM</title>
      </Helmet>
      <Table
        className="pix-payments-table"
        locale={{
          emptyText: <Empty description="Nenhum pagamento encontrado" />,
        }}
        loading={{
          spinning: loading,
          tip: "Buscando pagamentos...",
        }}
        scroll={{ x: 800 }}
        columns={columnsTable}
        dataSource={payments.rows?.sort((a, b) => {
          return moment(b.createdAt).diff(moment(a.createdAt));
        })}
          
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: payments.count,

          onChange: (page) => {
            setCurrentPage(page);

            refetchPayments({ page: page - 1, ...filters });
          },
        }}
        rowKey={(record) => record.id}
      />

      <DetailsModal
        setOpen={(payload) => setVisibleModal({ type: "details", payload })}
        open={visibleModal.details}
        payment={currentPayment}
      />
      <CancelModal
        setOpen={(payload) => setVisibleModal({ type: "cancel", payload })}
        open={visibleModal.cancel}
        payment={currentPayment}
        onSuccess={refetchPayments}
      />
      <CreateModal
        setOpen={(payload) => setVisibleModal({ type: "create", payload })}
        open={visibleModal.create}
        onCreateSuccess={(data) => {
          refetchPayments()
          if (data) {
            setCurrentPayment(data)
            setVisibleModal({ type: "details", payload: true })
          }
        }}
      />
      <ResendEmailModal
        setOpen={(payload) => setVisibleModal({ type: "resend", payload })}
        open={visibleModal.resend}
        payment={currentPayment}
      />

      <Drawer
        title="Filtros"
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        placement="right"
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Form form={filterForm} onFinish={handleSubmit} layout="vertical">
          <Form.Item label="Nº da proposta" name="proposal">
            <Input allowClear />
          </Form.Item>
          <Form.Item
            rules={[
              {
                message: "CPF inválido",
                validateTrigger: "onSubmit",
                validator: (_, value) => {
                  if (value && value?.length !== 11) {
                    return Promise.reject("CPF inválido");
                  }
                  return Promise.resolve();
                },
              },
            ]}
            normalize={(value) => {
              return value.replace(/\D/g, "");
            }}
            name="cpfConsultant"
            label="CPF do(a) consultor"
          >
            <MaskedInput name="cpfConsultant" mask={"000.000.000-00"} />
          </Form.Item>
          <Form.Item label="Status do pagamento" name="status">
            <Select
              allowClear
              options={
                Object.keys(statusDictionary).map((key) => ({
                  value: key,
                  label: statusDictionary[key].description,
                })) || []
              }
            />
          </Form.Item>

          <Row justify="end">
            <Button
              style={{
                marginTop: "auto",
              }}
              htmlType="submit"
              type="primary"
            >
              Filtrar
            </Button>
          </Row>
        </Form>
      </Drawer>
    </Main>
  );
}

export const useCompany = () => {
  const signin = useSelector((state) => state.signin);
  const { subsidiary_erp, company_erp } = useMemo(() => {
    const { code_erp: subsidiary_erp, Hierarquia } =
      signin.user.userCompanies.find(
        (company) => company.id === signin.user.currentCompany
      );

    return { subsidiary_erp, company_erp: Hierarquia.code_erp };
  }, [signin]);

  return { subsidiary_erp, company_erp };
};

export default PIX;
