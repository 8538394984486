import {
  useState,
  useEffect,
  useContext,
} from 'react';

import {
  Row,
  Col,
  Select,
  Table,
  Button,
  message,
  Space,
} from 'antd';

import {
  DeleteOutlined
} from '@ant-design/icons';

import { listProfileApi } from "../../../../../../../../services/profiles";
import { UserContext } from "../../../../../../../../Context/userContext";

const Profile = (props) => {
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [limit, setLimit] = useState(3);
  const [offset, setOffset] = useState(1);
  const [selectProfile, setSelectProfile] = useState(null);

  const userContext = useContext(UserContext);

  useEffect(() => {
    getProfiles();
  }, []);

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'right',
      render: (data) => (
        <Space size="middle">
          <Button
            type="text"
            onClick={() => deleteUserProfile(data.id)}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Button>
        </Space>
      ),
    },
  ]

  const getProfiles = async () => {
    setLoading(true);

    try {
      const result = await listProfileApi
        .listProfile(true)

      setProfiles(result.rows);

    } catch (error) {
      message.error("Não foi possível buscar os perfis");
    } finally {
      setLoading(false);
    }
  }

  const handleTableChange = (pagination) => {
    setOffset(pagination.current);
  }

  const deleteUserProfile = async (id) => {
    const filtered = await userContext.userProfiles.filter(function (el) { return el.id !== id; });

    userContext.setUserProfiles(filtered);
  }

  const handleAddProfile = async () => {
    try {
      if (!selectProfile)
        throw "É necessário selecionar o perfil que deseja incluir."

      const profilesTemp = userContext.userProfiles;

      const profileFiltered = await profiles.find(profile => profile.id === selectProfile);

      const findProfileInUserProfiles = await profilesTemp.filter(userProfile => userProfile.id === selectProfile);

      if (findProfileInUserProfiles.length > 0)
        throw "Perfil já cadastrado";

      await profilesTemp.push(profileFiltered);

      setSelectProfile(null);
      userContext.setUserProfiles([...profilesTemp])
    } catch (error) {
      message.info(error)
    }

  }

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={20}>
          <Select
            allowClear
            //showSearch
            value={selectProfile}
            style={{ width: "100%" }}
            placeholder="Selecione o perfil"
            optionFilterProp="children"
            loading={loading}
            showArrow={true}
            onChange={(e) => setSelectProfile(e)}
          >
            {
              profiles.length !== 0 ?
                profiles.map(profile => {
                  return <Select.Option key={profile.id} value={profile.id}>{profile.name}</Select.Option>
                })
                :
                <></>
            }
          </Select>
        </Col>
        <Col span={4}>
          <Button
            style={{ width: "100%" }}
            onClick={handleAddProfile}
          >
            Adicionar
          </Button>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ marginTop: "10px" }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={userContext.userProfiles}
            pagination={{
              current: offset,
              pageSize: limit,
              total: userContext.userProfiles ? userContext.userProfiles.length : 0,
            }}
            size="small"
            onChange={handleTableChange}
          />

        </Col>
      </Row>
    </>
  );
}

export default Profile;