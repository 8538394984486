import React from "react";
import "./styles.css";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Main from "../../Layout/Main";

import { Col, Row } from "antd";
import { checkControl } from "../../../utils/access_control";
import { itensMenu } from "./itens-menu";
import Cards from "../../../components/card/card";
import MenuComponent from "../../../components/MenuComponent";
import MenuCard from "../../../components/menu_card";

function HomeFuneralsCenter(props) {
  const signin = useSelector((state) => state.signin);
  const history = useHistory();
  props = {
    ...props,
    breadcrumb: [
      {
        title: "Central de Velórios",
        path: props.modulePath,
      },
    ],
    itensMenu: itensMenu,
  };

  return <MenuCard {...props} />;
}

export default HomeFuneralsCenter;
