import { getRequest, postRequest } from "../utils/api";

export const clientBoletosApi = {
  clientBoletos: (body) => postRequest("/boleto", body),
  base64: (body) => postRequest("/boleto/base64", body),
  delete: (body) => postRequest("/boleto/delete", body),
  sendMail: (body) => postRequest("/boleto/send/mail", body),
  sendMailLink: (body) => postRequest("/boleto/send/mail/link", body),
  sendMailError: (body) => postRequest("/boleto/send/mail/error", body),
  sendManyMail: (body) => postRequest("/boleto/send-many/mail", body),
  getSerpro: async (cpf) => await getRequest("/serpro/search/" + cpf)
};
