import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Tag, Switch, Button, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

import { SectorsService } from "../../../../services/sectors";

import { listSectors, alterSector } from "../../../../store/ducks/sectors";
import { openModal } from "../../../../store/ducks/modalGlobal";

import Main from "../../../Layout/Main";
import MainCrud from "../../../../components/MainCrud";
import { ModalApp } from "../../../../components/Modal";

import FormApp from "./components/form";

import { checkControl } from "../../../../utils/access_control";
import MenuComponent from "../../../../components/MenuComponent";
import { itensDrawer } from "../itens-menu";

const Sectors = (props) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const signin = useSelector((state) => state.signin);
  const sectors = useSelector((state) => state.sectors);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!checked) {
      list(true);
    } else {
      list(false);
    }
  }, [page, rowsPerPage, sectors.sector]);

  useEffect(() => {
    setPage(1);
  }, [checked]);

  const list = (active) => {
    try {
      setLoading(true);

      const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
      SectorsService.getSectors(rowsPerPage, offset, active).then(
        (response) => {
          dispatch(listSectors(response));
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      message.error("Erro ao carregar dados!");
    }
  };

  let columns = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "Descrição",
      dataIndex: "description",
    },
    {
      title: "Situação",
      dataIndex: "active",
      render: (active) => {
        let color = "";
        if (active === true) {
          color = "green";
        } else {
          color = "volcano";
        }
        return <Tag color={color}>{active ? "Ativo" : "Inativo"}</Tag>;
      },
    },
  ];

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(10);
  }

  const activeSwitch = (checked) => {
    if (checked) {
      list(false);
      setChecked(true);
      message.info("Exibindo setores inativos!");
    } else {
      list(true);
      setChecked(false);
      message.info("Exibindo setores ativos!");
    }
  };

  const handleOpenModal = (data) => {
    dispatch(openModal(true));
    dispatch(
      alterSector({
        ...data,
      })
    );
  };

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Setores",
      path: props.modulePath + "/service_management/setors",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Setores - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="11"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <>
            <span
              style={checkControl(signin.user.profiles, "sectors_inactivate")}
            >
              <Switch
                checkedChildren="Inativos"
                unCheckedChildren="Ativos"
                onChange={activeSwitch}
                style={{ marginRight: "5px", marginTop: "5px" }}
              />
            </span>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={handleOpenModal}
              style={{
                ...checkControl(signin.user.profiles, "save_and_update_sectors"),
                marginLeft: "20px",
              }}
            >
              Novo
            </Button>
          </>
        }
      >
        {sectors.sectorsList && sectors.sectorsList.length !== 0 ? (
          <MainCrud
            handleOpenModal={handleOpenModal}
            columnsTable={columns}
            dataSource={sectors.sectorsList}
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            countPages={sectors.sectorsListCount}
            handleTableChange={handleTableChange}
            profiles={signin.user.profiles}
            classTag={[]}
            displayMainCrud="none"
          />
        ) : (
          <Empty description="Nenhum setor encontrado" />
        )}
        <ModalApp title="Setor" width={500} footer={null}>
          <FormApp />
        </ModalApp>
      </Main>
    </>
  );
};

export default Sectors;
