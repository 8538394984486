import safeEval from "safer-eval";

// Expressão regular para encontrar referências de expressões
const refRegex = /:[a-z]+(:[a-z]+)?/gi;

/**
 * Dicionário de referências
 * @description As referências são substituídas por seus valores
 * - :self - Referência para o campo atual
 * - :dep - Referência para o campo dependente
 * - :self:notNull - Verifica se o campo atual não é nulo
 * - :dep:notNull - Verifica se o campo dependente não é nulo
 * - :self.name - Título do campo atual
 * - :dep.name - Título do campo dependente
 */
const refDict = {
  ":dep": "dep.value",
  ":self": "self.value",
  ":self:notNull": 'self.value != null && self.value != ""',
  ":dep:notNull": 'dep.value != null && dep.value != ""',
  ":self.name": "self.name",
  ":dep.name": "dep.name",
};

/**
 * Conxtexto de execução das expressões
 * @typedef {object} Context
 * @property {object} self - Referência para o campo atual
 * @property {object} dep - Referência para o campo dependente
 */

/**
 * Executa a expressão de acordo com o contexto
 * @param {string} input - Expressão a ser validada
 * @param {Context} context - Objeto com as referências
 * @example validateExpression(":self:notNull && :dep:notNull",
 * { self: { value: "João" }, dep: { value: "Silva" } }) // true
 * @returns {boolean}
 */
export function execExpression(input = "", context) {
  // Substitui as referências por seus valores
  const exp =
    input.match(refRegex)?.reduce((acc, ref) => {
      const value = refDict[ref];
      if (!value) throw new Error(`Referência não encontrada: ${ref}`);

      return acc.replace(ref, value);
    }, input) || input;

  return safeEval(exp, context);
}

/**
 * @typedef {object} Field
 * @property {string} name - Nome do campo
 * @property {any} value - Valor do campo
 * @property {Array.<{expression: string, message: string, dependent: Dependent}>} rules - Regras de validação
 */

/**
 * @typedef {object} Dependent
 * @property {string} name - Nome do campo dependente
 * @property {any} value - Valor do campo dependente
 */

/**
 * @typedef {object} ValidationResult
 * @property {boolean} isValid - Indica se o campo é válido
 * @property {Array.<{helpMessage: string}>} errors - Lista de erros
 */

/**
 * Valida o campo de acordo com as regras
 * @param {Field} field
 * @returns {ValidationResult}
 */
export function validateField(field) {
  const result = {
    isValid: true,
    errors: [],
  };
  field.rules.map((e) => e.expression);
  field.rules.forEach((rule) => {
    const context = {
      self: field,
      dep: rule.dependent,
    };
    const isValid = execExpression(rule.expression, context);
    const helpMessage = rule.message
      .replace(/:dep.name/gi, context.dep?.name)
      .replace(/:self.name/gi, context.self?.name);

    if (!isValid) {
      result.isValid = false;
      result.errors.push({
        helpMessage,
      });
    }
  });

  return result;
}
/**
 * Verifica se a expressão é válida
 * @param {string} input
 * @example isValidExpInput(":self:notNull && :dep:notNull") // true
 * @returns
 */
export function validateExpInput(input) {
  const result = {
    isValid: true,
    errors: [],
  };
  input.match(refRegex)?.forEach((ref) => {
    const value = refDict[ref];
    if (!value) {
      result.isValid = false;
      result.errors.push({
        code: "INVALID_REF",
        helpMessage: `Referência inválida: (${ref})`,
      });
    }
  });

  return result;
}

// Expressão regular para encontrar referências nas mensagens de ajuda
const messageRefRegex = /:(self|dep).[a-z]+/gi;

/**
 * Verifica se a mensagem de ajuda é válida
 * @param {string} input
 * @example isValidHelpMessage(":self.name é obrigatório") // true
 * @returns {object} { isValid: boolean, errors: array }
 */
export function validateHelpMessage(input) {
  const result = {
    isValid: true,
    errors: [],
  };
  input.match(messageRefRegex)?.forEach((ref) => {
    const value = refDict[ref];
    if (!value) {
      result.isValid = false;
      result.errors.push({
        code: "INVALID_MESSAGE_REF",
        helpMessage: `Referência inválida: (${ref})`,
      });
    }
  });

  return result;
}

/**
 * Converte o valor do campo de acordo com o tipo de campo
 * @param {*} value
 * @param {number} idType
 * @returns
 */
export const convertFieldValue = (value, idType) => {
  const parsers = {
    2: parseInt,
    3: (value) => new Date(value),
  };

  const parser = parsers[idType];
  return parser ? parser(value) : value;
};
