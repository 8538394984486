import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  message,
} from "antd";

import MainCrud from "../../../../../../components/MainCrud";
import api from "../../../../../../utils/api";
import Acoes from './components/Acoes';

const typesCode = {
  1: "Contrato Morada Essencial",
  2: "Contrato Jazigo",
  3: "Contrato Cremação",
  4: "Crédito Funeral Futuro",
  5: "Contrato Plano Prever",
  6: "Contrato Cremação Pet",
  7: "Funerais/Cemitériais de Venda",
  8: "Negociação",
}

const Index = (props) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [installments, setInstallments] = useState([]);

  const signin = useSelector((state) => state.signin);

  const columns = [
    {
      title: "#",
      dataIndex: "id",

    },
    {
      title: "Tipo do Contrato",
      dataIndex: "typeCode",
      render: (typeCode) => {
        return typesCode[typeCode]
      }
    },
    {
      title: "Código",
      dataIndex: "code",
    },
    {
      title: "Vencimento",
      dataIndex: "dueDate",
      render: (dueDate) => {
        return new Date(dueDate).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
      },
    },
    {
      title: "Valor",
      dataIndex: "value",
      render: (value) => {
        const valueLink = value ? value : 0.0;
        return valueLink.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
      }
    },
    {
      title: "Qtd. Parcelas",
      dataIndex: "installment",
      render: (installment) => `Até ${installment}X`
    },
    {
      title: "",
      align: 'right',
      render: installment => {
        return <Acoes installment={installment} />
      }
    },
  ];

  useEffect(() => {
    list();
  }, [page, rowsPerPage, props, signin.user.currentCompany,]);

  async function list() {
    try {
      setLoading(true);
      const filterUser = props.idUser == null ? "" :"&idUser="+ props.idUser
      const filterCod =  props.codFilter == null ? "" :"&codFilter="+ props.codFilter

      const offset = props.offset !== 1 ? rowsPerPage * (props.offset - 1) : 0;

      const result = await api.get(`/payment-link/index?limit=${rowsPerPage}
      &offset=${offset}&companyId=${signin.user.currentCompany}`+ filterUser + filterCod)

      setInstallments(result.data);

    } catch (error) {
      setLoading(false);
      message.error("Erro ao carregar dados!");
    }
  }

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    props.setOffset(pagination.current);
    setRowsPerPage(10);
  }

  return (
    <MainCrud
      columnsTable={columns}
      dataSource={installments.rows}
      page={page}
      rowsPerPage={rowsPerPage}
      countPages={installments.count}
      handleTableChange={handleTableChange}
      profiles={signin.user.profiles}
      displayMainCrud="none"
      classTag={[]}
    />
  );
}

export default Index;