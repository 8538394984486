import React, { useState } from "react";
import { Button, message, Popconfirm, Switch, Table } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import "./styles.css";
import { openModal } from "../../../../store/ducks/modalGlobal";
import Main from "../../../Layout/Main";
import { ModalApp } from "../../../../components/Modal";
import GroupForm from "./components/Form/GroupForm";
import { checkControl } from "../../../../utils/access_control";
import loadingSVG from "../../../../assets/images/loading.svg";
import MenuComponent from "../../../../components/MenuComponent";
import { itensDrawer } from "../itens-menu";
import api from "../../../../utils/api";
import { useFetch } from "../../../../utils/useFetch";
import { useIsMobile } from "../../../../utils/isMobile";
import { Link } from "react-router-dom";
import { useMutate } from "../../../../utils/useMutate";
import { MenuOutlined } from "@ant-design/icons";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainerData = sortableContainer((props) => (
  <tbody {...props} />
));

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

function ExternallLinks(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOperation, setModalOperation] = useState("");
  const signin = useSelector((state) => state.signin);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [currentGroup, setCurrentGroup] = useState();

  const getLinksGroups = async () => {
    return api.get("/linksgroups").then((res) => res.data);
  };

  const deleteGroupMutate = async () => {
    return api.delete(`/linksgroups?code=${currentGroup?.code}`);
  };

  async function updateGroupMutate(params) {
    return api.put(`/linksgroups?code=${params.code}`, params);
  }

  const {
    data: groups,
    refetch: refetchGroups,
    loading,
    setData: setGroups,
  } = useFetch(getLinksGroups);
  const { loading: deleteGroupLoading, mutate: deleteGroup } = useMutate(
    deleteGroupMutate,
    {
      onSuccess: () => {
        handleFinish();
        message.success("Grupo deletado com sucesso!");
      },
      onError: () => {
        handleFinish();
        message.error("Erro ao deletar grupo!");
      },
    }
  );

  const { loading: updateGroupLoading, mutate: updateGroup } = useMutate(
    updateGroupMutate,
    {
      onError: (e) => {
        message.error("Erro ao atualizar grupo!" + e);
      },
    }
  );
  // const [groups, setListItems] = useState(groups);

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(10);
  }

  const handleOpenModal = (data) => {
    dispatch(openModal(true));
  };

  const handleFinish = () => {
    dispatch(openModal(false));
    setCurrentGroup(null);
    refetchGroups();
  };

  const handleDeleteGroupFinish = () => {
    dispatch(openModal(false));
    refetchGroups();
    setCurrentGroup(null);
  };

  const handleActiveChange = (group) => {
    setGroups((prev) => {
      const newGroups = prev.map((item) => {
        if (item.code === group.code) {
          return { ...item, active: !group.active };
        }
        return item;
      });
      return newGroups;
    });
  };

  const handleSaveGroups = () => {
    Promise.all(
      groups.map((group) => {
        return updateGroup(group);
      })
    ).then(() => {
      handleFinish();
        message.success("Grupo atualizado com sucesso!");
    }).catch(() => {
      handleFinish();
        message.error("Erro ao atualizar grupo!");

    });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex != newIndex) {
      setGroups((items) =>
        arrayMove(items, oldIndex, newIndex).map((item, index) => {
          return {
            ...item,
            sequence: index + 1,
          };
        })
      );
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainerData
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = (groups ? groups : []).findIndex(
      (x) => x.id === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const sortData = (data) => {
    return data.sort((a, b) => {
      return parseInt(a.sequence) - parseInt(b.sequence);
    });
  };

  let columns = [
    {
      render: () => <DragHandle />,
    },
    {
      title: "Sequência",
      dataIndex: "sequence",
    },
    {
      title: "Titulo",
      dataIndex: "title",
      render: (text, record) => (
        <Link to={`/administrative/external-links/${record.code}`}>
          {record.title}
        </Link>
      ),
    },
    {
      title: "Código",
      dataIndex: "code",
    },
    {
      title: "Ações",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="group-actions">
          <Button
            size="middle"
            icon={<EditOutlined />}
            title="Editar grupo"
            onClick={() => {
              setCurrentGroup(record);
              setModalOperation("update_group");
              handleOpenModal();
            }}
            style={{
              // ...checkControl(signin.user.profiles, "save_and_update_products"),
              marginTop: "8px",
              borderRadius: "50%",
            }}
          />
          <Popconfirm
            title="Tem certeza que deseja deletar este grupo?"
            onConfirm={deleteGroup}
            okText="Sim"
            cancelText="Cancelar"
          >
            <Button
              type="default"
              size="middle"
              onClick={() => {
                setCurrentGroup(record);
              }}
              danger
              icon={<DeleteOutlined />}
              title="Excluir grupo"
              style={{
                // ...checkControl(signin.user.profiles, "save_and_update_products"),
                marginTop: "8px",
                borderRadius: "50%",
              }}
            />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: "Ativo",
      dataIndex: "active",
      render: (text, record) => (
        <Switch
          checked={record.active}
          onChange={() => handleActiveChange(record)}
        />
      ),
    },
  ];

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Links Externos",
      path: props.modulePath + "/external-links",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Links Externos - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="12"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
      >
        <div className="row-spaced">
          <Button
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            onClick={() => {
              handleOpenModal();
              setModalOperation("create_group");
            }}
            style={{
              ...checkControl(signin.user.profiles, "save_and_update_products"),
              margin: "4px 0",
            }}
          >
            Novo Grupo
          </Button>
          <Button
            type="primary"
            size="middle"
            // icon={<PlusOutlined />}
            loading={updateGroupLoading}
            onClick={handleSaveGroups}
            style={{
              ...checkControl(signin.user.profiles, "save_and_update_products"),
              margin: "4px 0",
            }}
          >
            Salvar
          </Button>
        </div>
        {loading ? (
          <>
            <div className="loading-centar">
              <img src={loadingSVG} alt="loading" height={80} />
            </div>
            <h2 className="text-loading">
              Por favor aguarde, buscando os links...
            </h2>
          </>
        ) : (
          <Table
            columns={columns}
            dataSource={sortData(groups || [])}
            rowKey={(record) => record.id}
            loading={loading}
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            pagination={{
              current: page,
              pageSize: rowsPerPage,
              total: groups?.length,
            }}
            size="small"
            onChange={handleTableChange}
          />
        )}

        <ModalApp
          title={
            modalOperation.match(/create/)
              ? "Novo grupo"
              : `Atualizar grupo: ${currentGroup?.title}`
          }
          width={1000}
          footer={null}
        >
          {modalOperation.match(/group/) && (
            <GroupForm
              groupLength={groups?.length || 0}
              currentGroup={modalOperation.match(/update/) && currentGroup}
              onFinish={handleFinish}
              onDeleteFinish={handleDeleteGroupFinish}
            />
          )}
        </ModalApp>
      </Main>
    </>
  );
}

export default ExternallLinks;
