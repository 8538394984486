import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const listCompanyApi = {
  listCompany: (limit = 0, offset = 0, active = true) => {

    var search = "";

    if (limit > 0) {
      search = `&limit=${limit}&offset=${offset}`;
    }

    return getRequest(
      `/companies?active=${active + search}`,
      {}
    );
  }
};

export const addCompanyApi = {
  addCompany: (body) => postRequest("/company", body),
};

export const updateCompanyApi = {
  updateCompany: (body, id) =>
    putRequest("/company?id=" + id, body),
};

export const inactivateCompanyApi = {
  inactivateCompany: (id) =>
    deleteRequest("/company?id=" + id, {}),
};

export const getCompaniesAndSubsidiaries = {
  listCompany: () =>
    getRequest(
      "/getcompanies",
      {}
    )
}

export const CompanyApi = {
  get: async (id) => {
    return await getRequest(
      `/company?id=${id}`,
      {}
    );
  }
}