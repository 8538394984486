/**
 * Verifica se o usuáro é o Owner do sistema
 * @returns Perfil Owner
 */

export const isOwner = (data) => {
  let isAdmin = false;

  data.filter((profile) => {
    if (profile.id === 1 || profile.name.includes("OWNER")) {
      isAdmin = true;
    }
  });

  return isAdmin;
};
