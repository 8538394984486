const Types = {
  ADD: "ADD",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
  GET: "GET",
  SET: "SET_PROFILE",
};

const INITIAL_STATE = {
  profileList: [],
  profileListCount: 0,
  profile: {
    id: null,
    active: false,
    name: "",
    description: "",
    resources: [],
  },
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        profileList: action.payload.rows,
        profileListCount: action.payload.count,
      };

    case Types.ADD:
      return {
        ...state,
        profileList: action.payload.rows,
        profileListCount: action.payload.count,
      };

    case Types.UPDATE:
      return {
        ...state,
        profileList: action.payload.rows,
        profileListCount: action.payload.count,
      };

    case Types.REMOVE:
      return {
        ...state,
        profileList: action.payload.rows,
        profileListCount: action.payload.count,
      };

    case Types.SET:
      return {
        ...state,
        profile: {
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export function listProfile(data) {
  return {
    type: Types.GET,
    payload: data,
  };
}

export function addProfile(data) {
  return {
    type: Types.ADD,
    payload: data,
  };
}

export function alterProfile(data) {
  return {
    type: Types.SET,
    payload: data,
  };
}

export function updateProfile(data) {
  return {
    type: Types.UPDATE,
    payload: data,
  };
}

export function deleteProfile(data) {
  return {
    type: Types.REMOVE,
    payload: data,
  };
}
