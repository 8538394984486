/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "antd";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { useSelector } from "react-redux";
import { dashboardService } from "../services/dashboard";
import { useFetch } from "../utils/useFetch";

const ReportsContext = createContext();

export const ReportsProvider = ({ children }) => {
  const signin = useSelector((state) => state.signin);
  const [reportData, setReportData] = useState({});
  const [reportType, setReportType] = useState("attendance_and_os");
  const [stage, setStage] = useState("form");
  const [intervalDate, setIntervalDate] = useState([])
  const { data: availableSectors, refetch: refetchAvailableSectors } = useFetch(
    dashboardService.getSectors,
    {
      autoFetch: false,
    }
  );
  const {
    data: availableSettingServices,
    refetch: refetchAvailableSettingServices,
  } = useFetch(dashboardService.getSettingServicesBySector, {
    autoFetch: false,
  });

  const { data: availableChecklists, refetch: refetchAvailableChecklists } =
    useFetch(dashboardService.getAvailableChecklists, { autoFetch: false });

  const [form] = Form.useForm();
  const selectedSector = Form.useWatch("sector", form);
  const selectedSettingService = Form.useWatch("settingService", form);


  const handleFinish = () => {
    setStage("form");
    setReportData({});
  };

  useEffect(() => {
    if (selectedSector) {
      refetchAvailableSettingServices({
        company_id: signin.user.currentCompany,
        id_sector: selectedSector,
      });
    }
  }, [selectedSector]);

  useEffect(() => {
    if (selectedSettingService && reportType === "checklist") {
      refetchAvailableChecklists({
        company_id: signin.user.currentCompany,
        setting_service_id: selectedSettingService,
      });
    }
  }, [selectedSettingService]);

  useEffect(() => {
    if (signin.user.currentCompany) {
      refetchAvailableSectors({
        company_id: signin.user.currentCompany,
      });
    }
  }, [signin.user.currentCompany]);

  return (
    <ReportsContext.Provider
      value={{
        availableSectors,
        availableSettingServices,
        availableChecklists,
        reportData,
        setReportData,
        selectedSector,
        reportType,
        setReportType,
        stage,
        setStage,
        form,
        handleFinish,
        intervalDate,
        setIntervalDate,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export const useReports = () => {
  const ctx = useContext(ReportsContext);

  if (!ctx) {
    throw new Error("useReports must be used within a ReportsProvider");
  }

  const {
    availableSectors,
    availableSettingServices,
    availableChecklists,
    reportData,
    setReportData,
    selectedSector,
    reportType,
    setReportType,
    stage,
    setStage,
    form,
    handleFinish,
    intervalDate,
    setIntervalDate,
  } = ctx;

  return {
    availableSectors,
    availableSettingServices,
    availableChecklists,
    reportData,
    setReportData,
    selectedSector,
    reportType,
    setReportType,
    stage,
    setStage,
    form,
    handleFinish,
    intervalDate,
    setIntervalDate,
  };
};
