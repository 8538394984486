export const itensMenu = [
  {
    key: 0,
    permission: "access_offline_search",
    url: "/offline-search/search",
    title: "Consulta",
    icon: "SearchOutlined",
  },
  {
    key: 1,
    permission: "access_offline_search",
    url: "/offline-search/authorization",
    title: "Autorizações",
    icon: "FileSearchOutlined",
  },

];
