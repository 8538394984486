export const Types = {
  SET_APPOINTMENT: "SET_APPOINTMENT",
  REMOVE_APPOINTMENT: "REMOVE_APPOINTMENT",
  SET_APPOINTMENT_DEFAULT_PHOTOS: "SET_APPOINTMENT_DEFAULT_PHOTOS",
};

const INITIAL_STATE = {
  appointment: {},
  photos_default: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_APPOINTMENT:
      return {
        ...state,
        appointment: action.payload,
      };

    case Types.REMOVE_APPOINTMENT:
      return {
        ...state,
        appointment: action.payload,
      };

    case Types.SET_APPOINTMENT_DEFAULT_PHOTOS:
      return {
        ...state,
        photos_default: action.payload,
      };

    default:
      return state;
  }
}

export function setAppointment(data) {
  return {
    type: Types.SET_APPOINTMENT,
    payload: data,
  };
}

export function removeAppointment(data) {
  return {
    type: Types.REMOVE_APPOINTMENT,
    payload: data,
  };
}

export function setAppointmentDefaultPhotos(data) {
  return {
    type: Types.SET_APPOINTMENT_DEFAULT_PHOTOS,
    payload: data,
  };
}
