const Types = {
  ADD: "ADD",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
  GET_PRODUCTS: "GET_PRODUCTS",
  SET: "SET_MODULE"
};

const INITIAL_STATE = {
  productsList: [],
  productsListCount: 0,
  product: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_PRODUCTS:
      return {
        ...state,
        productsList: action.payload.rows,
        productsListCount: action.payload.count
      }

    case Types.ADD:
      return {
        ...state,
        productList: action.payload.rows,
        productListCount: action.payload.count,
      };
    case Types.UPDATE:
      return {
        ...state,
        productList: action.payload.rows,
        productListCount: action.payload.count,
      };

    case Types.SET: {
      return {
        ...state,
        product: {
          ...action.payload,
        },
      }
    }

    case Types.REMOVE:
      return {
        ...state,
        productList: action.payload.rows,
        productListCount: action.payload.count,
      };
    default:
      return state;
  }
}

export function getProducts(data) {
  return {
    type: Types.GET_PRODUCTS,
    payload: data,
  };
}

export function addProduct(data) {
  return {
    type: Types.ADD,
    payload: data,
  };
}

export function updateProduct(data) {
  return {
    type: Types.UPDATE,
    payload: data,
  };
}

export function alterProduct(data) {
  return {
    type: Types.SET,
    payload: data
  }
}

export function deleteProduct(data) {
  return {
    type: Types.REMOVE,
    payload: data,
  };
}