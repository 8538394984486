import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Button, Col, Table, Typography, Layout, Empty } from "antd";
import { UnifiedQueryContext } from "../../../../../../Context/unifiedQueryContext";
import { IconTable } from "../Icon";

import { setAttendance } from "../../../../../../store/ducks/UnifiedQuery";

const { Content } = Layout;
const { Title } = Typography;

function TableSmall({ title, columnsTable, table, current, descriptionEmpty }) {
  const [dataTable, setDataTable] = useState([]);
  const context = useContext(UnifiedQueryContext);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    checkDataTable();
  }, [table]);

  const checkDataTable = () => {
    if (table === "attendance") {
      setDataTable(context.data.attendances);
    } else if (table === "sepul") {
      setDataTable(context.data.sepults);
    } else {
      setDataTable([]);
    }
  };

  const screenAttendanceOrSepulttPush = (value) => {
    let dataSearch;

    if (table === "attendance") {
      dataSearch = context.data.attendances.find(
        (data) => data.attendance_number === value.attendance_number
      );

      dispatch(setAttendance(dataSearch));
      history.push(`/${context.path}/unified/query/${table}`);
    }
  };

  return (
    <Col className="content-contract-unified-query" span={24}>
      <Content className="content-header-contract-unified-query">
        <Title level={5}>{title}</Title>
        <Col className="content-header-contract-right-unified-query">
          <span className="content-header-contract-text-unified-query">
            {dataTable ? dataTable.length : 0} resultados
          </span>
          <span>
            <Button
              icon={<IconTable current={context.current} />}
              onClick={() => {
                if (context.current !== 0) {
                  context.setCurrent(0);

                  return;
                }

                context.setCurrent(current);
              }}
            />
          </span>
        </Col>
      </Content>
      {dataTable && dataTable.length === 0 ? (
        <Empty description={descriptionEmpty} />
      ) : (
        <Table
          columns={columnsTable}
          rowClassName="unified-query-contract-table-row-click"
          dataSource={dataTable}
          size="small"
          onRow={(record) => {
            return {
              onClick: () => screenAttendanceOrSepulttPush(record),
            };
          }}
          pagination={false}
          style={context.current === 0 ? { width: 400 } : {}}
        />
      )}
    </Col>
  );
}

export default TableSmall;
