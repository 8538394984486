import { api } from '../utils/apiProtheus';

export const getListSchedules = async (params) => {
  const filters = params.filters ? `&filtro=${params.filters.toString()}` : "";
  const result = await api.get(`/03/RestSched/api/v1/getschedules?limit=${params.limit}&offset=${params.offset}${filters}`);
  return result.data;
}

export const getResumo = async () => {
  const result = await api.get(`/03/RestSched/api/v1/getresume`);
  return result.data;
}