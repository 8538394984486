/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Form, Input, Row, Select, Switch, Typography } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useEffect, useMemo } from "react";

const fileldSizes = [
  {
    value: "50",
    label: "50%",
  },
  {
    value: "100",
    label: "100%",
  },
];

export const GeneralForm = ({
  fieldValues,
  userGroups,
  fieldMomentStatus,
  fieldTypes,
}) => {
  const [form] = Form.useForm();
  const loadAlternative = Form.useWatch("get_list", form);
  const fieldType = Form.useWatch("id_type", form);

  const allowAlternatives = useMemo(() => {
    const type = fieldTypes.rows?.find((item) => item.id === fieldType);

    return type?.is_multiple;
  }, [fieldType, fieldTypes]);

  useEffect(() => {
    if (fieldValues?.id) {
      form.setFieldsValue({
        id: fieldValues.id,
        sequence: fieldValues.sequence,
        name: fieldValues.name,
        id_general_status: fieldValues.StatusFieldServices?.map(
          (item) => item.id_general_status
        ),
        size: fieldValues.size,
        id_type: fieldValues.id_type,
        responsible_field: fieldValues.responsible_field,
        is_required: fieldValues.is_required,
        is_indicator: fieldValues.is_indicator,
        is_visible: fieldValues.is_visible,
        extra_field: fieldValues.extra_field,
        get_list: fieldValues.get_list,
        tag_function: fieldValues.tag_function,
        alternatives: fieldValues.SettingServiceFieldChoices,
      });
    }
  }, [fieldValues]);

  return (
    <Form
      className="setting-service-field-form"
      layout="vertical"
      form={form}
      name="generalForm"
    >
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="sequence" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label="Nome"
        name="name"
        rules={[{ required: true, message: "Campo obrigatório" }]}
      >
        <Input placeholder="Digite o nome do campo" />
      </Form.Item>
      <Row gutter={12}>
        <Col span={16}>
          <Form.Item
            label="Status"
            tooltip="Em que momento o campo será exibido?"
            name="id_general_status"
            rules={[
              {
                required: true,
                message: "Campo obrigatório",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Selecione o status"
              options={[{ description: "Todos", id: 0 }]
                .concat(fieldMomentStatus.rows)
                .map((value) => ({
                  label: value.description,
                  value: value.id,
                }))}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Campo obrigatório",
              },
            ]}
            label="Tamanho do campo"
            name="size"
          >
            <Select options={fileldSizes} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12} justify="space-between">
        <Col span={16}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Campo obrigatório",
              },
            ]}
            label="Tipo"
            name="id_type"
          >
            <Select
              placeholder="Selecione o tipo do campo"
              options={fieldTypes.rows.map((item) => ({
                label: item.description,
                value: item.id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: "1rem" }}>
        <Col span={14}>
          <Form.Item
            className="horizontal-form-item"
            label="Este campo indica o responsável?"
            name="responsible_field"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            className="horizontal-form-item"
            label="É um campo sencundário"
            name="extra_field"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="get_list"
            className="horizontal-form-item"
            label="Carregar dados das alternativas?"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        {loadAlternative && userGroups && (
          <Col span={12}>
            <Form.Item name="tag_function" label="Função de busca">
              <Select
                placeholder="Selecione a função de busca"
                options={userGroups.rows.map((item) => ({
                  label: item.name,
                  value: item.id.toString(), //tag_function is a string in the database
                }))}
              />
            </Form.Item>
          </Col>
        )}
      </Row>

      {allowAlternatives && !loadAlternative && (
        <Form.List name="alternatives">
          {(fields, { add, remove }) => (
            <Card
              title={
                <Row justify="space-between">
                  <Col>
                    <Typography.Text>Alternativas</Typography.Text>
                  </Col>
                  {fields.length === 0 && (
                    <Col>
                      <Button
                        type="link"
                        onClick={() => {
                          add({ description: "Sim" });
                          add({ description: "Não" });
                        }}
                      >
                        {/* <PlusOutlined /> */}
                        Preencher com Sim e Não
                      </Button>
                    </Col>
                  )}
                </Row>
              }
              size="small"
              bodyStyle={{
                display: "flex",
                flexDirection: "column",
                rowGap: 12,
              }}
            >
              {fields.map((field) => (
                <Row key={field.name} gutter={8} justify="space-between" align="middle">
                  <Col span={22}>
                    <Form.Item
                      className="compact-form-item"
                      {...field}
                      name={[field.name, "description"]}
                    >
                      <Input placeholder="Digite a alternativa" />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Button
                      size="small"
                      title="Remover"
                      danger
                      shape="circle"
                      onClick={() => remove(field.name)}
                    >
                      <MinusOutlined />
                    </Button>
                  </Col>
                </Row>
              ))}
              <Button
                title="Adicionar"
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                style={{ marginTop: 12, borderRadius: 5 }}
              >
                Adicionar alternativa
              </Button>
            </Card>
          )}
        </Form.List>
      )}
      <Row
        style={{
          marginTop: 12,
        }}
        justify="end"
      >
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              marginLeft: "8px",
            }}
          >
            { fieldValues?.id ? "Salvar" : "Próximo" }
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
