import React, { useEffect, useState } from "react";
import "./styles.css";

import Sidebar from "./Sidebar";
import Toolbar from "./Toolbar";

import { Layout, Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";

export default function Home(props) {
  const [menu, setMenu] = useState(true);

  const { Content, Footer } = Layout;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {menu ? (
        <Sidebar moduleMenu={props.moduleMenu} collapsed={props.collapsed} />
      ) : null}
      <Layout className="site-layout">
        <Toolbar moduleName={props.moduleName} />
        <Content style={{ margin: "0 16px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: ".8rem",
              paddingBottom: ".8rem",
            }}
          >
            <Breadcrumb className="breadcrumbClass">
              <Breadcrumb.Item href="/">
                <HomeOutlined />
              </Breadcrumb.Item>

              {props.breadcrumb
                ? props.breadcrumb.map((item, index) => (
                  <Breadcrumb.Item key={index} href={"/" + item.path}>
                    {item.title}
                  </Breadcrumb.Item>
                ))
                : null}
            </Breadcrumb>

            <div style={{ display: props.displayMenuButtons || "none" }}>
              {props.buttonHeader ? props.buttonHeader : null}
            </div>
          </div>

          <div
            className="site-layout-background content-layout"
            style={{
              padding: "1rem",
              minHeight: 360,
              borderRadius: "1rem",
            }}
          >
            {props.children}
          </div>
        </Content>

        <Footer className="footer-layout">
          <small>&copy; {new Date().getFullYear()} TI - Grupo Morada</small>
        </Footer>
      </Layout>
    </Layout>
  );
}
