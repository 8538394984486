import { Button, Divider, Empty, Switch } from "antd";
import { useState } from "react";
import { useIsMobile } from "../../utils/isMobile";
import styles from "./styles.module.css";
import { useMutate } from "../../utils/useMutate";
import { updateNotificationReceiversMutation } from "../../services/notificationService";
import { NotificationItem } from "./NotificationItem";
import { useNotifications } from "../../Context/NotificationProvider";
import { useSession } from "../../Context/SessionProvider";

export const Notifications = ({ items, className }) => {
  const { session } = useSession();
  const [showReadNotifications, setShowReadNotifications] = useState(true);
  const filteredItems = items
  .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  .filter((item) => !(!showReadNotifications && item.reading_date));
  
  const isMobile = useIsMobile();
  const { refetchUserNotifications } = useNotifications();
  const { mutate: updateNotificationReceivers } = useMutate(
    updateNotificationReceiversMutation,
    {
      onSuccess: () => {
        refetchUserNotifications({
          user_code: session.user.id,
          application_code: "web-gm",
        });
      },
    }  
  );

  const handleMarkAllAsRead = () => {
    const reading_date = new Date();
    const payload = items
      .filter((item) => !item.reading_date)
      .map((item) => ({
        id: item.id,
        data: {
          reading_date,
        },
      }));
    updateNotificationReceivers(payload);
  };

  const handleMarkAsRead = (id) => {
    const payload = [
      {
        id,
        data: {
          reading_date: new Date(),
        },
      },
    ];

    updateNotificationReceivers(payload);
  };
 
  return (
    <div
      style={{
        width: isMobile ? "100%": "400px",
      }}
      className={`${styles.container} ${className}`}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem 1rem 0 0",
          gap: "1rem",
        }}
      >
        <span className={styles.headerTitle}>Notificações</span>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: ".5rem",
          }}
        >
          <span
            style={{
              color: "#6b778c",
            }}
          >
            Mostrar apenas não lidos
          </span>
          <Switch
            size="small"
            title="Show"
            checked={!showReadNotifications}
            onClick={() => setShowReadNotifications(!showReadNotifications)}
          />
        </div>
      </div>
      <Divider
        style={{
          marginBottom: 0,
        }}
      />
      {filteredItems.some((item) => !item.reading_date) && (
        <Button
          color="#6B778C"
          style={{
            alignSelf: "flex-end",
          }}
          type="text"
          onClick={handleMarkAllAsRead}
        >
          Marcar todos como lido
        </Button>
      )}
      {filteredItems.length > 0 ? (
        filteredItems.map((item, index) => (
          <NotificationItem
            item={item}
            key={index}
            onMarkAsRead={handleMarkAsRead}
          />
        ))
      ) : (
        <Empty description="Nenhuma notificação" />
      )}
    </div>
  );
};
