/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Modal, notification, Row, Spin } from "antd";
import { useEffect } from "react";
import { ruleValidationServices } from "../../../../../services/ruleValidationServices";
import {
  validateExpInput,
  validateHelpMessage,
} from "../../../../../utils/ruleValidator";
import { useMutate } from "../../../../../utils/useMutate";

export const FormModal = ({ open, data, onSuccess, onClose }) => {
  const [form] = Form.useForm();

  const { mutate: updateRule, loading: loadingUpdate } = useMutate(
    ruleValidationServices.update,
    {
      onSuccess: () => {
        onClose && onClose();
        form.resetFields();
        onSuccess && onSuccess();

        notification.success({
          message: "Sucesso",
          description: "Regra atualizada com sucesso",
        });
      },
    }
  );

  const { mutate: createRule, loading: loadingCreate } = useMutate(
    ruleValidationServices.create,
    {
      onSuccess: () => {
        form.resetFields();
        onSuccess && onSuccess();
        onClose && onClose();
        notification.success({
          message: "Sucesso",
          description: "Regra criada com sucesso",
        });
      },
    }
  );

  const handleFinish = (values) => {
    console.log(values);
    if (values.id) {
      updateRule({ id: values.id, data: values });
    } else {
      createRule(values);
    }
  };

  const handleCancel = () => {
    onClose && onClose();
    form.resetFields();
  };

  useEffect(() => {
    if (data.id) {
      form.setFieldsValue({
        ...data,
      });
    }
  }, [data]);

  console.log("data", data);
  return (
    <Modal
      onCancel={handleCancel}
      title="Regra de Validação"
      open={open}
      footer={null}
      destroyOnClose
    >
      <Spin tip="Carregando..." spinning={loadingUpdate || loadingCreate}>
        <Form onFinish={handleFinish} form={form} layout="vertical">
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Campo obrigatório" }]}
            name="description"
            label="Descrição"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Campo obrigatório",
              },
              {
                validator: (_, value) => {
                  if (value) {
                    const { isValid, errors } = validateExpInput(value);
                    if (!isValid) {
                      return Promise.reject(errors[0].helpMessage);
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
            name="expression"
            label="Expressão"
          >
            <Input.TextArea spellCheck={false} />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "Campo obrigatório" },
              {
                validator: (_, value) => {
                  if (value) {
                    const { isValid, errors } = validateHelpMessage(value);
                    if (!isValid) {
                      return Promise.reject(errors[0].helpMessage);
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
            name="message"
            label="Mensagem de erro"
          >
            <Input />
          </Form.Item>
          <Row gutter={12} justify="end">
            <Col>
              <Button onClick={handleCancel}>Cancelar</Button>
            </Col>
            <Col>
              <Button htmlType="submit" type="primary">
                {data.id ? "Atualizar" : "Criar"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};
