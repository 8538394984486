import React, { useState } from "react";
import "./styles.css";

import { useSelector } from "react-redux";

import Main from "../../../Layout/Main";

import MOZS from "../../../../assets/images/MOZS.jpg";
import MOPE from "../../../../assets/images/MOPE.jpg";
import MOMI from "../../../../assets/images/MOMI.jpg";

import {
  Steps,
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  Avatar,
  Table,
  Radio,
  Skeleton,
  Modal,
} from "antd";

import {
  RightOutlined,
  UserOutlined,
  FileDoneOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import { reservationSQL } from "../../../../services/reservation_sql";
import MenuComponent from "../../../../components/MenuComponent";
import { itensMenu } from "../itens-menu";

const { Step } = Steps;
const { TextArea } = Input;

const statesSteps = {
  one: "process",
  two: "wait",
  three: "wait",
  four: "wait",
  five: "wait",
};

function ReservationSQL(props) {
  const signin = useSelector((state) => state.signin);

  const [current, setCurrent] = useState(0);
  const [states, setStates] = useState(statesSteps);
  const [screen, setScreen] = useState(1);
  const [dataCemetery, setDataCemetery] = useState([]);
  const [data, setData] = useState([]);
  const [dataSQL, setDataSQL] = useState([]);
  const [messageError, setMessageError] = useState("");
  const [dataVendedor, setDataVendedor] = useState({});
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState({});
  const [proposal, setProposal] = useState("");
  const [renderUseType, setRenderUseType] = useState(false);
  const [typeUse, setTypeUse] = useState({});
  const [valueTextArea, setValueTextArea] = useState("");
  const [productSQL, setProductSQL] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmOurCancel, setConfirmOurCancel] = useState("");
  const [sql, setSql] = useState({});

  const breadcrumb = [
    {
      title: "Cemitério",
      path: props.modulePath,
    },
    {
      title: "Reserva de SQL",
      path: props.modulePath + "/reservation/sql",
    },
  ];

  const onChangeCurrent = (current) => {
    setCurrent(current.step);
    setStates(current.states);
  };

  /**
   * Função para selecionar o cemitério
   */
  const handleCemetery = (values) => {
    values.currentStep = values.currentStep + 1;
    onChangeCurrent({
      step: values.currentStep,
      states: {
        one: "finish",
        two: "process",
        three: "wait",
        four: "wait",
        five: "wait",
      },
    });
    setScreen(2);
    setDataCemetery(values);
    setCompany(values.company);
  };

  /**
   * Função que seleciona o tipo de uso selecionado
   */
  const onChangeRadio = (value) => {
    if (value.target.value === "V") {
      setTypeUse({
        type: value.target.value,
        text: "UF",
        textlong: "Uso Futuro",
      });
    } else if (value.target.value === "R") {
      setTypeUse({
        type: value.target.value,
        text: "UI",
        textlong: "Uso Imediato",
      });
    }
  };

  /**
   * Função que preenche o text area
   */
  const onChangeTextArea = ({ target: { value } }) => {
    setValueTextArea(value);
  };

  const onFinishSubmit = (values) => {
    setLoading(true);

    // busca os vendedores
    reservationSQL
      .getVendedor(
        {
          empresa: company.matriz,
          filial: company.filial,
          grupoUser: "ti", // buscar de acordo com o usuário logado
          codVend: values.cod_vend,
        },
        null
      )
      .then((response) => {
        setDataVendedor(response);

        // busca as propostas no protheus
        reservationSQL
          .getPropsta(
            {
              empresa: company.matriz,
              filial: company.filial,
              grupoUser: "ti",
              codVend: values.cod_vend,
            },
            null
          )
          .then((response) => {
            setData(response);
            setLoading(false);
          })
          .catch(() => {
            setMessageError({
              firstMessage:
                "Ops... Não encontramos nenhuma proposta disponível :(",
            });
            setLoading(false);
          });
      })
      .catch(() => {
        setMessageError({
          firstMessage: "Ops... Não encontramos ninguém com esse código :(",
          secondaryMessage: "Verifique se o código está correto",
        });
        setDataVendedor({
          error: 1,
        });
        setLoading(false);
      });
  };

  /**
   * Corta uma string de nome usuário maior que 20 caracteres
   */
  const splitStringName = (name) => {
    if (name.length >= 15) {
      return `${name.substr(0, 15)}...`;
    } else {
      return name;
    }
  };

  /**
   * Renderiza a imagem na segunda etapa
   */
  const renderImg = (imgage) => {
    let img;

    if (imgage === "MOZS") {
      img = MOZS;
    } else if (imgage === "MOPE") {
      img = MOPE;
    } else if (imgage === "MOMI") {
      img = MOMI;
    }

    return <img src={img} height={67} width={55} />;
  };

  /**
   * Renderiza o card de cemitério
   */
  const renderCardsViewCemetery = () => {
    return (
      <Row className="img-border size-card">
        <Col>{renderImg(dataCemetery.cemetery)}</Col>
        <Col>
          <Row>
            <Col className="img-text">
              <span>{dataCemetery.title_first}</span> <br />
              <span>{dataCemetery.title_secondary}</span> <br />
            </Col>
          </Row>
          <Row>
            <Col className="img-text-link">
              <span
                style={{ color: "#1890ff" }}
                onClick={() => {
                  setScreen(1);
                  setStates(statesSteps);
                  setCurrent(0);
                }}
                className="cursor"
              >
                {dataCemetery.text}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  /**
   * Renderiza o card do usuário
   */
  const renderCardsViewUser = () => {
    return (
      <Row className="img-border size-card">
        <Col className="img-border" style={{ padding: 10 }}>
          <Avatar size={35} icon={<UserOutlined />} />
        </Col>
        <Col>
          <Row>
            <Col className="img-text">
              <span style={{ fontWeight: "bold" }}>
                {splitStringName(dataVendedor.nomvend)}
              </span>
            </Col>
          </Row>
          <Row>
            <Col className="img-text-link">
              <span
                style={{ color: "#1890ff" }}
                onClick={() => {
                  setScreen(2);
                  setCurrent(1);
                  setDataVendedor({});
                  setData([]);
                  setTypeUse({});
                  setStates({
                    one: "finish",
                    two: "process",
                    three: "wait",
                    four: "wait",
                    five: "wait",
                  });
                }}
                className="cursor"
              >
                Mudar Vendedor(a)
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  /**
   * Renderiza o card da propsta
   */
  const renderCardsViewProposal = () => {
    return (
      <Row className="img-border size-card">
        <Col className="img-border" style={{ padding: 10 }}>
          <Avatar size={35} icon={<FileDoneOutlined />} />
        </Col>
        <Col>
          <Row>
            <Col className="img-text-proposal" style={{ paddingTop: 2 }}>
              <span
                style={{ fontWeight: "bold" }}
              >{`${proposal} - ${typeUse.text}`}</span>{" "}
            </Col>
          </Row>
          <Row>
            <Col className="img-text-link">
              <span
                style={{ color: "#1890ff" }}
                onClick={() => {
                  setTypeUse({});
                  setRenderUseType(false);
                  setProposal("");
                  setScreen(3);
                  setCurrent(2);
                  setStates({
                    one: "finish",
                    two: "finish",
                    three: "process",
                    four: "wait",
                    five: "wait",
                  });
                }}
                className="cursor"
              >
                Mudar Proposta
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  /**
   * Seleciona a proposta
   */
  const handleSetProposta = (value) => {
    setRenderUseType(true);
    if (!proposal) {
      setProposal(value.CODPROP.trim());
    }
  };

  /**
   * Busca os produtos disponíveis para a venda
   */
  const handleGetProducts = () => {
    setLoading(true);

    reservationSQL
      .getProducts({
        empresa: company.matriz,
        filial: company.filial,
        grupoUser: "ti", // buscar de acordo com o usuário logado
      })
      .then((response) => {
        setDataSQL(response);
        setScreen(5);
        setCurrent(3);
        setStates({
          one: "finish",
          two: "finish",
          three: "finish",
          four: "process",
          five: "wait",
        });
        setLoading(false);
      })
      .catch(() => {
        setIsModalVisible(true);
        setConfirmOurCancel("error");
        setMessageError({
          firstMessage: "Ops... estamos com problemas nos nossos servidores :(",
        });
        setLoading(false);
      });
  };

  /**
   * Função para pré reservar o sql
   */
  const handlePrePoposal = (product) => {
    setLoading(true);

    let typeJaz;

    if (product.CODIGO.trim() === "009940") {
      typeJaz = "SETOR01_01";
    } else if (product.CODIGO.trim() === "009941") {
      typeJaz = "SETOR01_02";
    } else if (product.CODIGO.trim() === "009947") {
      typeJaz = "SETOR01_03";
    } else if (product.CODIGO.trim() === "009948") {
      typeJaz = "SETOR01_04";
    } else if (product.CODIGO.trim() === "009942") {
      typeJaz = "SETOR02_01";
    } else if (product.CODIGO.trim() === "009943") {
      typeJaz = "SETOR02_02";
    } else if (product.CODIGO.trim() === "009945") {
      typeJaz = "SETOR03_01";
    } else if (product.CODIGO.trim() === "009946") {
      typeJaz = "SETOR04_01";
    } else {
      typeJaz = "";
    }

    reservationSQL
      .getReserva(
        {
          empresa: company.matriz,
          filial: company.filial,
          tipoJaz: typeJaz,
          user: signin.user.codeProtheusUser,
          gruopUser: "ti",
          typeUse: typeUse.type.toUpperCase(),
        },
        null
      )
      .then((response) => {
        setSql(response[0]);
        setProductSQL(product);
        setCurrent(4);
        setScreen(6);
        setStates({
          one: "finish",
          two: "finish",
          three: "finish",
          four: "finish",
          five: "process",
        });
        setLoading(false);
      })
      .catch(() => {
        setCurrent(4);
        setScreen(7);
        setLoading(false);
        setStates({
          one: "finish",
          two: "finish",
          three: "finish",
          four: "finish",
          five: "process",
        });
      });
  };

  /**
   * Função para confirmar a proposta
   */
  const handleSuccessProposal = () => {
    setLoading(true);
    reservationSQL
      .confirmReserva({
        empresa: company.matriz,
        filial: company.filial,
        endereco: sql.endereco,
        codVend: dataVendedor.codvend,
        contrato: proposal,
        observation: valueTextArea ? valueTextArea : "Sem observacoes",
        nomeOper: signin.user.nameUserProtheus,
        groupUser: "ti",
      })
      .then(() => {
        setConfirmOurCancel("confirm");
        setIsModalVisible(true);
        setLoading(false);
      })
      .catch(() => {
        setIsModalVisible(true);
        setConfirmOurCancel("error");
        setMessageError({
          firstMessage:
            "Ops... estamos com problemas nos nossos servidores a pré-reserva não foi confirmada :(",
        });
        setLoading(false);
      });
  };

  /**
   * Função para cancelar a proposta
   */
  const handleCancelProposal = () => {
    setLoading(true);

    reservationSQL
      .cancelReserva({
        empresa: company.matriz,
        filial: company.filial,
        endreco: sql.endereco,
        gruopUser: "ti",
      })
      .then(() => {
        setConfirmOurCancel("cancel");
        setIsModalVisible(true);
        setLoading(false);
      })
      .catch(() => {
        setIsModalVisible(true);
        setConfirmOurCancel("error");
        setMessageError({
          firstMessage:
            "Ops... estamos com problemas nos nossos servidores a pré-reserva não foi cancelada :(",
        });
        setLoading(false);
      });
  };

  /**
   * Função que define qual tela será renderizada
   */
  const renderScreen = () => {
    let html;

    // tela inicial com os cards
    if (screen === 1) {
      html = (
        <div className="cards">
          <Card
            className="card"
            cover={<img alt="MOZS" src={MOZS} className="card-image" />}
            onClick={() => {
              setDataVendedor({});
              setData([]);
              setTypeUse({});
              handleCemetery({
                company: {
                  matriz: "03",
                  filial: "01",
                },
                cemetery: "MOZS",
                title_first: "MORADA DA",
                title_secondary: "PAZ EMAÚS",
                text: "Mudar Cemitério",
                currentStep: current,
              });
            }}
          >
            <h2 className="card-title">
              <span>Morada da Paz Emaús</span> <RightOutlined />
            </h2>
          </Card>
          <Card
            className="card"
            cover={<img alt="MOPE" src={MOPE} className="card-image" />}
            onClick={() => {
              setData([]);
              setTypeUse({});
              handleCemetery({
                company: {
                  matriz: "05",
                  filial: "01",
                },
                cemetery: "MOPE",
                title_first: "MORADA DA",
                title_secondary: "PAZ PAULISTA",
                text: "Mudar Cemitério",
                currentStep: current,
              });
            }}
          >
            <h2 className="card-title">
              <span>Morada da Paz Paulista</span> <RightOutlined />
            </h2>
          </Card>
          <Card
            className="card"
            cover={<img alt="MOMI" src={MOMI} className="card-image" />}
            onClick={() => {
              setData([]);
              setTypeUse({});
              handleCemetery({
                company: {
                  matriz: "03",
                  filial: "05",
                },
                cemetery: "MOMI",
                title_first: "MORADA DA",
                title_secondary: "PAZ MIPIBU",
                text: "Mudar Cemitério",
                currentStep: current,
              });
            }}
          >
            <h2 className="card-title">
              <span>Morada da Paz Mipibu</span> <RightOutlined />
            </h2>
          </Card>
        </div>
      );

      // tela para escolher o vendedor
    } else if (screen === 2) {
      html = (
        <Row>
          <Col
            span={4}
            className={
              dataVendedor ? "spacing-top-medium-card img-card" : "img-card"
            }
          >
            {renderCardsViewCemetery()}
          </Col>
          <Col span={16} offset={2}>
            <Row className="flex-col">
              <Col>
                <span className="text">Código do(a) vendedor(a):</span>
              </Col>
              <Col span={6}>
                <Form layout="vertical" name="form" onFinish={onFinishSubmit}>
                  <Form.Item
                    name="cod_vend"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      className="input"
                      placeholder="Digite o código aqui..."
                    />
                  </Form.Item>
                </Form>
              </Col>
              <Col span={5}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  form="form"
                  style={{ borderRadius: 5 }}
                  loading={loading}
                  disabled={loading ? true : false}
                >
                  {loading ? <>&nbsp; Aguarde...</> : "Pesquisar"}
                </Button>
              </Col>
            </Row>
            {loading ? (
              <Skeleton active></Skeleton>
            ) : dataVendedor && dataVendedor.codvend ? (
              <Row>
                <Col
                  className="img-border result-vendedor"
                  span={18}
                  offset={2}
                >
                  <Row>
                    <Col span={18}>
                      <h1>Nome: {dataVendedor.nomvend}</h1>
                      <h1>Celular: {dataVendedor.numcelu}</h1>
                    </Col>
                    <Col>
                      <Button
                        style={{
                          color: "#FFF",
                          background: "#017f3e",
                          borderRadius: 4,
                          marginTop: 13,
                          marginLeft: 15,
                        }}
                        size="large"
                        onClick={() => {
                          onChangeCurrent({
                            step: current + 1,
                            states: {
                              one: "finish",
                              two: "finish",
                              three: "process",
                              four: "wait",
                              five: "wait",
                            },
                          });
                          setScreen(3);
                        }}
                      >
                        Confirmar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : dataVendedor && dataVendedor.error ? (
              <Row>
                <Col span={20} offset={1}>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Col span={3}>
                      <CloseCircleOutlined
                        style={{ color: "red", fontSize: 60 }}
                      />
                    </Col>
                    <Col>
                      <h1 style={{ fontSize: 22 }}>
                        {messageError.firstMessage}
                      </h1>
                      <h1 style={{ fontSize: 22 }}>
                        {messageError.secondaryMessage}
                      </h1>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>
      );

      // tela com as propostas
    } else if (screen === 3) {
      html = (
        <Row>
          <Col span={4} className="spacing-top-medium-card img-card">
            {renderCardsViewCemetery()}
            <br />
            {renderCardsViewUser()}
          </Col>
          <Col span={6} offset={1} className="spacing-top-medium-card">
            {data && data.length !== 0 ? (
              <>
                <h3>Propostas disponíveis:</h3>

                <Table
                  size="small"
                  disabled={true}
                  style={{ cursor: "pointer" }}
                  dataSource={data}
                  rowKey={(record) => record.CODPROP}
                  rowClassName={(record) => {
                    if (!proposal) {
                      return null;
                    } else {
                      if (record.CODPROP.trim() === proposal) {
                        return "line-active";
                      } else {
                        return "line-inactive";
                      }
                    }
                  }}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        handleSetProposta(record);
                      },
                    };
                  }}
                  columns={[
                    {
                      dataIndex: "CODPROP",
                      key: "CODPROP",
                    },
                    {
                      align: "right",
                      render: () => <RightOutlined />,
                    },
                  ]}
                />
              </>
            ) : (
              setScreen(4)
            )}
          </Col>
          {renderUseType ? (
            <Col offset={3} className="spacing-top-medium-card">
              <h1>Tipo de Uso:</h1>
              <Radio.Group onChange={onChangeRadio} value={typeUse.type}>
                <Radio value="V" className="radioStyle">
                  Uso Futuro
                </Radio>
                <Radio value="R" className="radioStyle">
                  Uso Imediato
                </Radio>
              </Radio.Group>

              <h1 style={{ marginTop: 20 }}>Observações:</h1>
              <TextArea
                style={{ width: 300 }}
                value={valueTextArea}
                onChange={onChangeTextArea}
                placeholder="Digite aqui suas observações..."
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
              <div className="spacing-top-medium-button flex-button">
                <Button
                  type="default"
                  className="button-default"
                  size="large"
                  onClick={() => {
                    setTypeUse({});
                    setRenderUseType(false);
                    setProposal("");
                  }}
                >
                  Mudar Proposta
                </Button>
                <Button
                  type="primary"
                  className="button-primary"
                  size="large"
                  disabled={typeUse.type || loading ? false : true}
                  onClick={() => handleGetProducts()}
                  loading={loading}
                >
                  {loading ? <>&nbsp; Aguarde...</> : "Confirmar"}
                </Button>
              </div>
            </Col>
          ) : null}
        </Row>
      );

      // propostas ou vendedor não encontrados
    } else if (screen === 4) {
      html = (
        <Row className="flex">
          <Col span={4} className="img-card spacing-top-medium-card">
            {renderCardsViewCemetery()}
            <br />
            {renderCardsViewUser()}
          </Col>
          <Col span={16} offset={1}>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Col span={3}>
                <CloseCircleOutlined style={{ color: "red", fontSize: 60 }} />
              </Col>
              <Col>
                <h1 style={{ fontSize: 22 }}>{messageError.firstMessage}</h1>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    } else if (screen === 5) {
      html = (
        <Row>
          <Col span={4} className="img-card spacing-top-medium-card">
            {renderCardsViewCemetery()}
            <br />
            {renderCardsViewUser()}
            <br />
            {renderCardsViewProposal()}
          </Col>
          <Col span={6} offset={1} className="spacing-top-medium-card">
            {loading ? (
              <Skeleton active></Skeleton>
            ) : (
              <>
                <h3>Produtos com S/Q/L disponíveis:</h3>
                <Table
                  size="small"
                  style={{ cursor: "pointer" }}
                  dataSource={dataSQL}
                  rowKey={(record) => record.CODIGO}
                  onRow={(record) => {
                    return {
                      onClick: () => handlePrePoposal(record),
                    };
                  }}
                  columns={[
                    {
                      dataIndex: "DESCRICAO",
                      key: "CODIGO",
                    },
                    {
                      align: "right",
                      render: () => <RightOutlined />,
                    },
                  ]}
                />
              </>
            )}
          </Col>
        </Row>
      );
    } else if (screen === 6) {
      html = (
        <>
          <Row>
            <Col span={16} offset={8}>
              <h1 className="text-h1 spacing-top-medium-card">
                Endereço pré-reservado
              </h1>
            </Col>
          </Row>
          <Row>
            <Col span={14} offset={5}>
              <div className="reservation-title">
                <Row className="pre-information">
                  <Col>
                    <h1>Setor</h1>
                    <h1>{sql.endereco ? sql.endereco.substr(0, 2) : null}</h1>
                  </Col>
                  <Col>
                    <h1>Quadra</h1>
                    <h1>{sql.endereco ? sql.endereco.substr(2, 3) : null}</h1>
                  </Col>
                  <Col>
                    <h1>Lote</h1>
                    <h1>****</h1>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={12} offset={6}>
              <p style={{ fontStyle: "italic", marginLeft: 25 }}>
                A numeração completa poderá ser visualizada somente após a
                conofirmação da reserva!
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={14} offset={5}>
              <div className="reservation information">
                <Row>
                  <Col>
                    <h1 className="information-title">Resumo dos dados:</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1>Cemitério:</h1>
                  </Col>
                  <Col offset={1}>
                    <h1>{dataCemetery.title}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1>Vendedor(a):</h1>
                  </Col>
                  <Col offset={1}>
                    <h1>
                      {dataVendedor.nomvend} - {dataVendedor.numcelu}
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1>Proposta:</h1>
                  </Col>
                  <Col offset={1}>
                    <h1>
                      {" "}
                      {proposal} - {typeUse.textlong}
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1>Produto:</h1>
                  </Col>
                  <Col offset={1}>
                    <h1>{productSQL.DESCRICAO}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1>Observações:</h1>
                  </Col>
                  <Col offset={1}>
                    <h1>{valueTextArea}</h1>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={8} offset={8} className="buttons">
              <Button
                danger
                type="default"
                style={{ borderRadius: 5 }}
                size="large"
                disabled={loading ? true : false}
                onClick={() => handleCancelProposal()}
                loading={loading}
              >
                {loading ? <>&nbsp; Aguarde...</> : "Cancelar Pré-reserva"}
              </Button>
              <Button
                type="primary"
                className="button-primary"
                size="large"
                disabled={loading ? true : false}
                onClick={() => handleSuccessProposal()}
                loading={loading}
              >
                {loading ? <>&nbsp; Aguarde...</> : "Confirmar Reserva"}
              </Button>
            </Col>
          </Row>
        </>
      );
    } else if (screen === 7) {
      html = (
        <>
          <Row>
            <Col span={10} offset={7}>
              <h1 style={{ fontSize: 26, marginTop: 15 }}>
                Ops... Não temos mais nenhum S/Q/L <br /> disponível para o
                produto escolhido.
              </h1>
            </Col>
          </Row>
          <Row>
            <Col span={14} offset={5}>
              <div className="reservation information">
                <Row>
                  <Col>
                    <h1 className="information-title">Resumo dos dados:</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1>Cemitério:</h1>
                  </Col>
                  <Col offset={1}>
                    <h1>{dataCemetery.title}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1>Vendedor(a):</h1>
                  </Col>
                  <Col offset={1}>
                    <h1>
                      {dataVendedor.nomvend} - {dataVendedor.numcelu}
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1>Proposta:</h1>
                  </Col>
                  <Col offset={1}>
                    <h1>
                      {" "}
                      {proposal} - {typeUse.textlong}
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1>Produto:</h1>
                  </Col>
                  <Col offset={1}>
                    <h1>{productSQL.DESCRICAO}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h1>Observações:</h1>
                  </Col>
                  <Col offset={1}>
                    <h1>{valueTextArea}</h1>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={8} offset={8} className="buttons">
              <Button
                danger
                type="default"
                style={{ borderRadius: 5 }}
                size="large"
                disabled={loading ? true : false}
                onClick={() => handleCancelProposal()}
                loading={loading}
              >
                {loading ? <>&nbsp; Aguarde...</> : "Cancelar Pré-reserva"}
              </Button>
              <Button
                type="primary"
                className="button-primary"
                size="large"
                disabled={loading ? true : false}
                onClick={() => {
                  setCurrent(2);
                  setScreen(3);
                  setStates({
                    one: "finish",
                    two: "finish",
                    three: "process",
                    four: "await",
                    five: "await",
                  });
                }}
                loading={loading}
              >
                Escolher outro produto
              </Button>
            </Col>
          </Row>
        </>
      );
    }

    return html;
  };

  /**
   * Renderiza o modal com a mensagem correta
   */
  const renderModal = () => {
    let html;

    if (confirmOurCancel === "cancel") {
      html = (
        <>
          <Row
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col span={3}>
              <CloseCircleOutlined style={{ color: "red", fontSize: 60 }} />
            </Col>
            <Col>
              <h1 style={{ fontSize: 22, marginLeft: 30 }}>
                A pré-reserva foi cancelada!
              </h1>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{
                color: "#FFF",
                background: "#017f3e",
                borderRadius: 4,
                marginTop: 15,
                width: 80,
              }}
              size="large"
              onClick={() => {
                onChangeCurrent({
                  step: 0,
                  states: {
                    one: "process",
                    two: "wait",
                    three: "wait",
                    four: "wait",
                    five: "wait",
                  },
                });
                setScreen(1);
                setIsModalVisible(false);
                setProposal("");
                setValueTextArea("");
                setRenderUseType(false);
              }}
            >
              OK
            </Button>
          </Row>
        </>
      );
    } else if (confirmOurCancel === "confirm") {
      html = (
        <>
          <Row
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col span={3}>
              <CheckCircleOutlined style={{ color: "green", fontSize: 60 }} />
            </Col>
            <Col>
              <h1 style={{ fontSize: 22, marginLeft: 30 }}>
                Reserva de endereço efetuada com sucesso!
              </h1>
              <h3 className="sql-reservaion-confirm">
                {`${sql.endereco.substr(0, 2)}/${sql.endereco.substr(
                  2,
                  3
                )}/${sql.endereco.substr(5)}`}
              </h3>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>Cemitério:</Col>
            <Col offset={1}>
              <h5>{dataCemetery.title}</h5>
            </Col>
          </Row>
          <Row>
            <Col>Vendedor(a):</Col>
            <Col offset={1}>
              <h5>
                {dataVendedor.nomvend} - {dataVendedor.numcelu}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col>Proposta:</Col>
            <Col offset={1}>
              <h5>
                {" "}
                {proposal} - {typeUse.textlong}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col>Produto:</Col>
            <Col offset={1}>
              <h5>{productSQL.DESCRICAO}</h5>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{
                color: "#FFF",
                background: "#017f3e",
                borderRadius: 4,
                marginTop: 15,
                width: 80,
              }}
              size="large"
              onClick={() => {
                onChangeCurrent({
                  step: 0,
                  states: {
                    one: "process",
                    two: "wait",
                    three: "wait",
                    four: "wait",
                    five: "wait",
                  },
                });
                setScreen(1);
                setIsModalVisible(false);
                setProposal("");
                setValueTextArea("");
                setRenderUseType(false);
              }}
            >
              OK
            </Button>
          </Row>
        </>
      );
    } else if (confirmOurCancel === "error") {
      html = (
        <>
          <Row
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col span={3}>
              <CloseCircleOutlined style={{ color: "red", fontSize: 60 }} />
            </Col>
            <Col>
              <h1 style={{ fontSize: 22, marginLeft: 30 }}>
                {messageError.firstMessage}
              </h1>
              <p style={{ marginLeft: 30 }}>
                Favor entrar em contato com a equipe de TI.
              </p>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{
                color: "#FFF",
                background: "#017f3e",
                borderRadius: 4,
                marginTop: 15,
                width: 80,
              }}
              size="large"
              onClick={() => {
                onChangeCurrent({
                  step: 0,
                  states: {
                    one: "process",
                    two: "wait",
                    three: "wait",
                    four: "wait",
                    five: "wait",
                  },
                });
                setScreen(1);
                setIsModalVisible(false);
                setProposal("");
                setValueTextArea("");
                setRenderUseType(false);
              }}
            >
              OK
            </Button>
          </Row>
        </>
      );
    }

    return html;
  };

  /**
   * Altera o step com o click do mouse
   */
  const currentStep = (currentStepData) => {
    if (currentStepData < current) {
      setCurrent(currentStepData);
      setScreen(currentStepData + 1);
    }
  };

  return (
    <Main
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          itensMenu={itensMenu}
          modulePath={props.modulePath}
          selectedMenu="0"
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"flex"}
    >
      <Steps
        type="navigation"
        current={current}
        className="site-navigation-steps"
        size="small"
        onChange={currentStep}
      >
        <Step status={states.one} title="Escolha o cemitério" />
        <Step status={states.two} title="Informe o(a) vendedor(a)" />
        <Step status={states.three} title="Escolha a proposta" />
        <Step status={states.four} title="Escolha o produto" />
        <Step status={states.five} title="Confirme a reserva" />
      </Steps>

      {renderScreen()}

      <Modal
        title={false}
        visible={isModalVisible}
        footer={false}
        closable={false}
        width={600}
      >
        {renderModal()}
      </Modal>
    </Main>
  );
}

export default ReservationSQL;
