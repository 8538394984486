import React from "react";
import { useSelector } from 'react-redux'
import { Modal } from "antd";

function ModalSend(props) {
  const visible = useSelector(state => state.modalGlobal.modal_negotiation_data)

  return (
    <Modal
      title={false}
      visible={visible}
      footer={false}
      closable={false}
    >
      {props.content}
    </Modal>
  );
}

export default ModalSend;
