/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

/**
 * Hook para carregar scripts externos
 * @param {string} url - URL do script
 * @param {boolean} asyncMode - Carregar script de forma assíncrona
 */
export const useScript = (url, asyncMode = true) => {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            const script = document.createElement("script");

            script.src = url;
            script.async = asyncMode;

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [url]);

    return ref;
};
