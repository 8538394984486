import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { message, Button, Input, DatePicker, Row, Col, Tooltip, Tag } from "antd";
import { SearchOutlined, DeleteOutlined, CalendarOutlined } from "@ant-design/icons";

import Main from "../../../Layout/Main";
import MainCrud from "../../../../components/MainCrud";
import { itensMenu } from "../itens-menu";

import { baseServicesService } from "../../../../services/baseServices";
import { appointmentsApiProtheus } from "../../../../services/appointmentsProtheus";

import loadingSVG from "../../../../assets/images/loading.svg";
import { modalProtheus } from "../../../../store/ducks/modalGlobal";
import {
  setData,
  setLoadingProtheusData,
} from "../../../../store/ducks/modalProtheusData";
import ModalProtheusData from "../../../../components/ModalProtheusData";
import locale from "antd/es/date-picker/locale/pt_BR";
import { isMobile } from "../../../../utils/isMobile";
import moment from "moment";
import MenuComponent from "../../../../components/MenuComponent";

import { setBaseService } from "../../../../store/ducks/modalProtheusData";
import { useFetch } from "../../../../utils/useFetch";
import { CemeteryReserveModal } from "./CemeteryReserveModal";

const { RangePicker } = DatePicker;

function BaseService(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [valueInputSearch, setValueInputSearch] = useState("");
  const {
    data: baseservices,
    refetch: refetchBaseServices,
    loading: baseServiceLoading,
    setData: setBaseServices,
  } = useFetch(baseServicesService.getBaseServices, { autoFetch: false });

  const signin = useSelector((state) => state.signin);
  const [intervalFilter, setIntervalFilter] = useState([]);

  const [openReserveModal, setOpenReserveModal] = useState(false);

  const [currentBaseService, setCurrentBaseService] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const [initial_date_filter, end_date_filter] = intervalFilter;

    refetchBaseServices({
      limit: rowsPerPage,
      offset: (page - 1) * rowsPerPage,
      company_id: signin.user.currentCompany,
      initial_date_filter,
      end_date_filter,
    })
  }, [page, rowsPerPage, signin.user.currentCompany, intervalFilter]);

  const getDataProtheus = (num_attendance_erp, baseService) => {
    dispatch(modalProtheus(true));
    dispatch(setLoadingProtheusData(true));
    dispatch(setBaseService(baseService))
    const { code_erp: subsidiary_erp, Hierarquia } = signin.user.userCompanies.find(
      (company) => company.id === signin.user.currentCompany
    );

    let restProtheus = num_attendance_erp.includes("P") ? "restcremapet/getcremapet" : "restatendimento/getatendimento"

    appointmentsApiProtheus
      .getDetailsAttendanceProtheus(num_attendance_erp, {
        headers: {
          tenantId: `${Hierarquia.code_erp},${subsidiary_erp}`,
        },
      },
      restProtheus)
      .then((response) => dispatch(setData(response)))
      .catch(() => dispatch(setData({})))
      .finally(() => {
        dispatch(setLoadingProtheusData(false));
      });
  };


  // filtra os atendimentos
  const getColumnSearchApi = (text) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
      <div className="divSearch">
        <Input
          style={{ marginBottom: 8 }}
          placeholder={`Buscar pelo ${text}`}
          value={valueInputSearch}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setValueInputSearch(e.target.value ? [e.target.value] : "");
          }}
        />

        <div>
          <Button
            type="primary"
            onClick={() => search(selectedKeys)}
            icon={<SearchOutlined />}
            className="buttonSearch"
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              refetchBaseServices({
                limit: rowsPerPage,
                offset: (page - 1) * rowsPerPage,
                company_id: signin.user.currentCompany,
              });
              setValueInputSearch("");
            }}
            icon={<DeleteOutlined />}
            className="buttonSearch"
          >
            Remover Filtro
          </Button>
        </div>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });


  async function search(param) {
    if (param.length <= 0) {
      return message.error("Digite um atendimento para ser filtrado.");
    }

    try {

      baseServicesService.getBaseServicesSearch(param).then((response) => {
        setBaseServices({
          rows: response,
          count: response.length,
        });
      });
    } catch (error) {
      message.error("Erro ao filtrar os dados!");
    }
  }

  let columnsIsMobile = [
    {
      title: "Atendimento",
      dataIndex: "num_attendance_erp",
      ...getColumnSearchApi("Atendimento"),
      render: (text, row) => (
        <Button type="link" onClick={() => getDataProtheus(text, row)}>{text}</Button>
      ),
    },
    {
      title: "Registro",
      dataIndex: "registration_date",
      render: (data) => {
        const date = new Date(data);

        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();

        const formatted = `${day}/${month}/${year}`;

        return formatted;
      },
    },
    {
      title: "Ente Querido",
      dataIndex: "name_deceased",
      ...getColumnSearchApi("Ente Querido"),
    },
  ];

  let columns = [
    {
      title: "Atendimento",
      dataIndex: "num_attendance_erp",
      ...getColumnSearchApi("Atendimento"),
      render: (text, row) => (
        <Button type="link" onClick={() => getDataProtheus(text, row)}>{text}</Button>
      ),
    },
    {
      title: "Registro",
      dataIndex: "registration_date",
      render: (data) => moment(data).format("DD/MM/YYYY"),
    },
    {
      title: "Ente Querido",
      dataIndex: "name_deceased",
      ...getColumnSearchApi("Ente Querido"),
    },
    // {
    //   title: "Data de nascimento",
    //   dataIndex: "birth_decease" ,
    //   render: (data) => moment(data).format("DD/MM/YYYY")
    // },
    {
      title: "Declarante",
      dataIndex: "name_declarant",
    },
    {
      title: "Contato",
      dataIndex: "contact_declarant",
    },
    {
      title: "Status",
      dataIndex: "is_blocked_attendance",
      render: (actions, record) =>
      record.is_blocked_attendance == "P" ?
      <Tag color="magenta">Pendente</Tag> : record.is_blocked_attendance == "L" ? <Tag color="green">Liberado</Tag> :
      record.is_blocked_attendance == "A" ? <Tag color="blue">Agendado</Tag>  :   "Sem Status" 

    },
    {
      title: "Ações",
      dataIndex: "actions",
      render: (actions, record) => {
        return (
          <Row gutter={[8, 8]} justify="center">
            <Col>
              <Tooltip title="Reserva Cemitério Externo">
                <Button
                  type="primary"
                  shape="circle"
                  ghost size="small"
                  icon={<CalendarOutlined />}
                  onClick={() => {
                    setCurrentBaseService(record);
                    setOpenReserveModal(true);
                  }}
                >
                </Button>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  const breadcrumb = [
    {
      title: "Atendimento Funerário",
      path: props.modulePath,
    },
    {
      title: "Atendimentos",
      path: props.modulePath + "/base-service",
    },
  ];

  async function handleTableChange(pagination) {
    setPage(pagination.current);
    setRowsPerPage(10);
  }

  return (
    <Main
      key={props.moduleName}
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          modulePath={props.modulePath}
          selectedMenu="1"
          itensMenu={itensMenu}
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"flex"}
      buttonHeader={
        <div className="filter-date">
          <RangePicker
            format="DD/MM/YYYY"
            onChange={(dates) => setIntervalFilter(dates || [])}
            locale={locale}
          />
        </div>
      }
    >
      {baseServiceLoading ? (
        <>
          <div className="loading-centar">
            <img src={loadingSVG} alt="loading" height={80} />
          </div>
          <h2 className="text-loading">Carregando...</h2>
        </>
      ) : (
        <MainCrud
          // handleOpenModal={getDataProtheus}
          displayMainCrud="none"
          columnsTable={isMobile() ? columnsIsMobile : columns}
          dataSource={baseservices?.rows}
          page={page}
          rowsPerPage={rowsPerPage}
          countPages={baseservices?.count}
          handleTableChange={handleTableChange}
          profiles={signin.user.profiles}
          classTag={["insert_base_service", "check_base_service_inactivate"]}
        />
      )}
      <ModalProtheusData />
      <CemeteryReserveModal
        open={openReserveModal}
        setOpen={setOpenReserveModal}
        baseService={currentBaseService}
        onClose={() => {
          setOpenReserveModal(false);
          setCurrentBaseService(null);
        }}
        onSuccess={() => {
          const [initial_date_filter, end_date_filter] = intervalFilter;
          refetchBaseServices({
            limit: rowsPerPage,
            offset: (page - 1) * rowsPerPage,
            company_id: signin.user.currentCompany,
            initial_date_filter,
            end_date_filter,
          });
        }}
      />
    </Main>
  );
}

export default BaseService;
