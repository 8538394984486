import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Popconfirm,
  Spin,
  message,
  Switch,
} from "antd";

import {
  addProduct,
  updateProduct,
  deleteProduct,
  alterProduct,
} from "../../../../../../store/ducks/products";

import { ProductsService } from "../../../../../../services/products";

import { closeModal } from "../../../../../../store/ducks/modalGlobal";

const validateMessages = {
  required: "${label} é obrigatório!",
};

const FormApp = (props) => {
  const signin = useSelector((state) => state.signin);
  const product = useSelector((state) => state.products.product);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [product]);

  const handleSubmit = (values) => {
    setLoading(true);

    if (!product.id) {
      ProductsService.create({
        ...values,
        company_id: signin.user.currentCompany,
      })
        .then((res) => {
          dispatch(addProduct(res));
          dispatch(alterProduct({}));
          dispatch(closeModal(false));
          message.success("Produto criado com sucesso!");
        })
        .catch((err) => {
          message.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const body = {
        ...product,
        ...values,
      };
      ProductsService.update(body)
        .then((res) => {
          dispatch(updateProduct(res));
          dispatch(alterProduct({}));
          dispatch(closeModal(false));
          message.success("Produto alterado com sucesso!");
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const remove = () => {
    setLoading(true);

    ProductsService.delete(product.id)
      .then((res) => {
        dispatch(deleteProduct(res));
        dispatch(alterProduct({}));
        dispatch(closeModal(false));
        message.success("Produto deletado com sucesso");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Form
        layout="vertical"
        form={form}
        name="form-product"
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        initialValues={{
          ["code"]: product.code,
          ["description"]: product.description,
        }}
      >
        <Row>
          <Col lg={24}>
            <Form.Item
              name="code"
              label="Código ERP"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Digite o código do produto no ERP" />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              name="description"
              label="Descrição do Produto"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Digite o nome do produto" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={24}>
            {product.id && !product.active ? (
              <Form.Item name="active" valuePropName="checked">
                <Switch checkedChildren="Ativo" unCheckedChildren="Inativo" />
              </Form.Item>
            ) : null}
          </Col>
        </Row>
        <Row justify="end" align="bottom">
          <Col>
            {product.active ? (
              <Popconfirm
                title="Deseja inativar o recurso?"
                onConfirm={remove}
                okText="Sim"
                cancelText="Não"
              >
                <Button type="danger">Inativar</Button>
              </Popconfirm>
            ) : null}

            <Button
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "8px",
              }}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default FormApp;
