import React, { useState, useEffect } from "react";
import "./styles.css";
import { Collapse, PageHeader } from "antd";

const { Panel } = Collapse;

function CollapseData(props) {
  const [panel, setPanel] = useState(props.panel);
  const [register, setRegister] = useState(props.register);

  useEffect(() => {
    setPanel(props.panel);
  }, [props.panel]);

  function callback(key) {
    props.handleData(key);
  }

  return (
    <>
      {props.pageheader ? (
        <PageHeader
          style={{
            backgroundColor: "#F8F9F9",
            border: "solid ",
            borderColor: "#D5D8DC",
            borderWidth: 1,
          }}
        >
          <div className="menu_text">
            <div>
              <span className="border">Status:</span>
              <br />
              <span className="border">Registro:</span>
              <br />
              <span className="border">Atendente:</span>
              <br />
              <span className="border">Funerária:</span>
            </div>
            <div>
              <span>{register.status}</span>
              <br />
              <span>{register.register}</span>
              <br />
              <span>{register.attendant}</span>
              <br />
              <span>{register.funerary}</span>
            </div>
          </div>
        </PageHeader>
      ) : null}
      <Collapse onChange={callback}>
        {panel.map((objectPanel) => (
          <Panel header={objectPanel.header} key={objectPanel.key}>
            {objectPanel.component}
          </Panel>
        ))}
      </Collapse>
    </>
  );
}

export default CollapseData;
