import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Row,
    Skeleton,
    Tooltip,
} from "antd";
import styles from "./style.module.css";
import visaIcon from "../../assets/images/creditCards/visa_credit_card_flag.png";
import mastercardIcon from "../../assets/images/creditCards/mastercard_credit_card_flag.png";
import dinersIcon from "../../assets/images/creditCards/diners_credit_card_flag.png";
import amexIcon from "../../assets/images/creditCards/amex_credit_card_flag.png";
import hipercardIcon from "../../assets/images/creditCards/hipercard_credit_card_flag.png";
import eloIcon from "../../assets/images/creditCards/elo_credit_card_flag.png";
import { GrCircleQuestion } from "react-icons/gr";

import InputMask from "react-input-mask";
import { getCardFlag, validateCreditCard } from "../../utils/creditCard";
import { useState } from "react";
import { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { checkCPF } from "../../utils/validations";

const flags = [
    {
        name: "visa",
        image: visaIcon,
    },
    {
        name: "mastercard",
        image: mastercardIcon,
    },
    {
        name: "diners_club",
        image: dinersIcon,
    },
    {
        name: "american_express",
        image: amexIcon,
    },
    {
        name: "hipercard",
        image: hipercardIcon,
    },

    {
        name: "elo",
        image: eloIcon,
    },
];

const CardFlag = ({ flag }) => {
    return (
        <div className={styles.cardFlag}>
            <img src={flag.image} alt={flag.name} />
        </div>
    );
};

export const CreditCard = ({ title, chargeValue, loading, onSubmit, type }) => {
    const [currentFlag, setCurrentFlag] = useState("");
    const [form] = Form.useForm();
    const cardNumber = Form.useWatch("card_number", form);
    const [recaptcha, setRecaptcha] = useState("");

    const cardNumberSuffix = currentFlag ? (
        <CardFlag flag={flags.find((flag) => flag.name === currentFlag)} />
    ) : (
        <span></span>
    );

    const onChangeReCAPTCHA = (value) => {
        if (value) {
            setRecaptcha(true);
        } else {
            setRecaptcha(false);
        }
    };

    useEffect(() => {
        if (cardNumber) {
            const flag = getCardFlag(cardNumber);

            setCurrentFlag(flag);
        }
    }, [cardNumber]);

    return (
        <div className={styles.cardContainer}>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        gap: 10,
                        flexDirection: "column",
                    }}
                >
                    <Skeleton.Input active block />
                    <Skeleton.Input active block />
                    <Skeleton.Input active block />
                    <Skeleton.Input active block />
                    <Skeleton.Input active block />

                    <Skeleton active={loading} paragraph={{ rows: 3 }} />
                </div>
            ) : (
                <div>
                    <h3 className={styles.title}>{title}</h3>
                    <div className={styles.cardFlagsWrapper}>
                        {flags.map((flag, index) => (
                            <CardFlag key={index} flag={flag} />
                        ))}
                    </div>
                    <Form
                        name="creditCard"
                        form={form}
                        onFinish={(data) => {
                            typeof onSubmit == "function" &&
                                onSubmit({
                                    ...data,
                                    card_expiration:
                                        data.card_expiration.format("MM/YYYY"),
                                    payment_company_code: currentFlag,
                                });
                        }}
                    >
                        <Form.Item
                            normalize={(value) => {
                                return value.replace(/[^0-9]/g, "");
                            }}
                            name="card_number"
                            rules={[
                                {
                                    required: true,
                                    message: "Informe o número do cartão",
                                },
                                {
                                    validator: (_, value) => {
                                        if (value && value.length) {
                                            if (!validateCreditCard(value))
                                                return Promise.reject(
                                                    "Número de cartão inválido"
                                                );
                                        }
                                        return Promise.resolve();
                                    },
                                    validateTrigger: ["onBlur", "onSubmit"],
                                },
                            ]}
                        >
                            <InputMask
                                maskChar={null}
                                mask="9999 9999 9999 9999"
                            >
                                {(inputProps) => (
                                    <Input
                                        suffix={cardNumberSuffix}
                                        {...inputProps}
                                        className={styles.cardInput}
                                        placeholder="Número do cartão"
                                    />
                                )}
                            </InputMask>
                        </Form.Item>
                        <Row gutter={12}>
                            <Col span={17}>
                                <Form.Item
                                    name="card_expiration"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Informe a data de validade",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        format="MM/YYYY"
                                        className={styles.cardInput}
                                        placeholder="Data de validade"
                                        picker="month"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    name="card_cvv"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Informe o CVV",
                                        },
                                    ]}
                                >
                                    <Input
                                        className={styles.cardInput}
                                        placeholder="CVV"
                                        suffix={
                                            <Tooltip title="Código de segurança">
                                                <GrCircleQuestion
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        color: "",
                                                    }}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            name="holder_name"
                            rules={[
                                {
                                    required: true,
                                    message: "Informe o nome do titular",
                                },
                            ]}
                        >
                            <Input
                                className={styles.cardInput}
                                placeholder="Nome do titular"
                            />
                        </Form.Item>

                        <Form.Item
                            name="cardCpf"
                            normalize={(value) => {
                                return value.replace(/[^0-9]/g, "");
                            }}
                            rules={[
                                {
                                    required: false,
                                    validateTrigger: ["onBlur", "onSubmit"],
                                    validator: (_, value) => {
                                        if (!value) {
                                            return Promise.resolve();
                                        }
                                        if (!checkCPF(value)) {
                                            return Promise.reject(
                                                "CPF inválido"
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <InputMask maskChar={null} mask="999.999.999-99">
                                {(inputProps) => (
                                    <Input
                                        {...inputProps}
                                        className={styles.cardInput}
                                        placeholder="CPF do titular (opcional)"
                                    />
                                )}
                            </InputMask>
                        </Form.Item>

                        <Divider />
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={onChangeReCAPTCHA}
                            style={{ marginBottom: 10 }}
                        />

                        <p className={styles.footerMessage}>
                            Ao clicar no botão “Salvar“ você concorda que o Morada da Paz
                            renove automaticamente sua assinatura
                            {type == "migracao" && (
                              <>
                                ,{" "} no valor de {" "}
                                <strong
                                      style={{
                                          whiteSpace: "pre",
                                      }}
                                  >

                                      {chargeValue?.toLocaleString("pt-br", {
                                          style: "currency",
                                          currency: "BRL",
                                      })}
                                </strong>
                                ,{" "}
                              </>
                            )}
                            {" "}até que você deseje modificar seu plano ou cancelar sua assinatura.
                            Para mais informações, entre em contato com nossa Central de Atendimento ao Cliente.
                        </p>
                        <Button
                            block
                            className={styles.cardButton}
                            type="primary"
                            htmlType="submit"
                            disabled={!recaptcha}
                        >
                            Salvar
                        </Button>
                    </Form>
                </div>
            )}
        </div>
    );
};
