import moment from "moment";

export const checkTypeResponse = (response_obj) => {
  if (response_obj.response_type === "DATE_HOURS") {
    return moment();
  }

  return false;
};

export const convertNameInTagName = (str) => {
  let i = 0;

  str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  for (i; i < str.length; i++) {
    str = str.replace(" ", "_");
  }

  return str.toUpperCase().trim();
};
