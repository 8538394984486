export const itensMenu = [
  {
    key: 0,
    permission: "access_menu_home",
    url: "/",
    title: "Início",
    icon: "HomeOutlined",
  },
  {
    key: 2,
    permission: "access_page_objects_dashboard",
    url: "/objects/dashboard",
    title: "Dashboard",
    icon: "BarChartOutlined",
  },
  {
    key: 3,
    permission: "access_page_objects_invoicing",
    url: "/objects",
    title: "Objetos",
    icon: "FileOutlined",
  },
  {
    key: 4,
    permission: "access_page_objects_invoicing",
    url: "/objects/carrier",
    title: "Objetos do transportador",
    icon: "WalletOutlined",
  },
  {
    key: 5,
    permission: "access_page_receive_objects",
    url: "/objects/receive-objects",
    title: "Receber Malote",
    icon: "DownloadOutlined",
  },
  {
    key: 6,
    permission: "access_page_send_to_carrier",
    url: "/objects/send-to-carrier",
    title: "Enviar para transportadora",
    icon: "UploadOutlined",
  },
  {
    key: 7,
    permission: "access_page_send_objects_to_production",
    url: "/objects/send-to-production",
    title: "Enviar objeto para produção",
    icon: "BuildOutlined"
  }
];

export const itensDrawer = [
  {
    key: 0,
    permission: "access_menu_home",
    url: "/",
    title: "Início",
    icon: "HomeOutlined",
  },
  {
    key: 1,
    icon: "UserOutlined",
    title: "Gestão de Objetos",
    subMenu: [
      {
        key: 2,
        permission: "access_page_objects_dashboard",
        url: "/objects/dashboard",
        title: "Dashboard",
        icon: "BarChartOutlined",
      },
      {
        key: 3,
        permission: "access_page_objects_invoicing",
        url: "/objects",
        title: "Objetos",
        icon: "FileOutlined",
      },
      {
        key: 4,
        permission: "access_page_objects_invoicing",
        url: "/objects/carrier",
        title: "Transportador",
        icon: "WalletOutlined",
      },
      {
        key: 5,
        permission: "access_page_receive_objects",
        url: "/objects/receive-objects",
        title: "Receber da Gráfica",
        icon: "DownloadOutlined",
      },
      {
        key: 6,
        permission: "access_page_send_to_carrier",
        url: "/objects/send-to-carrier",
        title: "Enviar para transportadora",
        icon: "UploadOutlined",
      },
      {
        key: 7,
        permission: "access_page_send_objects_to_production",//TODO: criar acesso
        url: "/objects/send-to-production",
        title: "Enviar objeto para produção",
        icon: "BuildOutlined"//TODO: trocar icone
      }
    ],
  },
];
