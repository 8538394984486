import axios from "axios";
import { getToken, removeToken, setToken } from "./localStoreToken";
import { sessionApi } from "../services/session";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  headers: {},
});

api.interceptors.request.use(async (config) => {
  const token = await getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;

async function handleHttpError(error) {
  const genericErrorMessage = "Algo deu errado, por favor tente novamente.";

  if (error && error.response && error.response.status === 401) {
    removeToken();
  } else if (error.response && error.response.data) {
    return (
      error.response.data.error || error.response.data || genericErrorMessage
    );
  }

  return genericErrorMessage;
}

function makeHttpRequest(apiCall) {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await apiCall();
      resolve(data.data);
    } catch (e) {
      reject(await handleHttpError(e));
    }
  });
}

export const getRequest = (path, options = {}) =>
  makeHttpRequest(() => api.get(path, options));
export const postRequest = (path, options = {}) =>
  makeHttpRequest(() => api.post(path, options));
export const putRequest = (path, options = {}) =>
  makeHttpRequest(() => api.put(path, options));
export const deleteRequest = (path, options = {}) =>
  makeHttpRequest(() => api.delete(path, options));

  /**
   * 
   * @param {object} payload 
   * @param {number} payload.id_user
   * @param {string} payload.token
   */
  export const storeUserMessageTokenMutation = async (payload) => {
    const response = await api.post(
      `/users/message-tokens/register`,
      payload
    );
    return response.data;
  };


/**
 * 
 * @param {object} payload 
 * @param {number} payload.id_user
 */
  export const getUserMessageTokensQuery = async (payload) => {
    const response = await api.get(
      `/users/message-tokens/list?id_user=${payload.id_user}`
    );
    return response.data;
  }
  
  export const defaultAuthenticator = async () => {
    const resp = await sessionApi.signIn({
      email: process.env.REACT_APP_DEFAULT_AUTH_EMAIL,
      password: process.env.REACT_APP_DEFAULT_AUTH_PASS,
    });
  
    setToken(resp?.token);
  }