import {
  getRequest,
} from "../utils/api";

export const OfflineAuthorization = {
  findAuthorization: (params) =>
    getRequest(
      `/offline-search/v1/authorizations`, {
        params
      }),
};
