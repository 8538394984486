import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  Row,
  Col,
  Button,
  Switch,
  Tag,
  message,
  Drawer,
  Popconfirm,
  Table,
  Tooltip,
} from "antd";

import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

import Main from "../../../Layout/Main";
import MenuComponent from "../../../../components/MenuComponent";

import { itensDrawer } from "../itens-menu";
import { checkControl } from "../../../../utils/access_control";
import api from "../../../../utils/api";
import FormUserGroup from "./components/Form";

const UserGroup = (props) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userGroups, setUserGroups] = useState({});
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [titleDrawer, setTitleDrawer] = useState("");
  const [userGroup, setUserGroup] = useState({});
  const [reloadIndex, setReloadIndex] = useState(false);

  const signin = useSelector((state) => state.signin);
  const company_id = signin.user.currentCompany

  useEffect(() => {
    getUserGroups();
  }, [page, rowsPerPage, toggleSwitch, reloadIndex]);

  useEffect(() => {
    if (reloadIndex === true) {
      getUserGroups();
      setReloadIndex(false);
    }
  }, [reloadIndex, signin]);

  const getUserGroups = async () => {
    try {

      const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;

      const result = await api.get(`/user-groups?limit=${rowsPerPage}&offset=${offset}&active=${!toggleSwitch}&company_id=${company_id}`);

      console.log(result)

      setUserGroups(result.data);

    } catch (error) {
      message.error(error);
    }
  }

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Grupos de usuários",
      path: props.modulePath + "/user-groups",
    },
  ];

  const columns = [
    {
      dataIndex: "id",
      key: "id",
      title: "ID"
    },
    {
      dataIndex: "name",
      key: "name",
      title: "Nome",
    },
    {
      title: "Descrição",
      dataIndex: "description",
    },
    {
      title: "Situação",
      dataIndex: "active",
      render: (active) => {
        let color = "";
        if (active === true) {
          color = "green";
        } else {
          color = "volcano";
        }

        return <Tag color={color}>{active ? "Ativo" : "Inativo"}</Tag>;
      },
    },
    {
      title: "Ações",

      render: (data) => (
        <Row gutter={[8, 8]} justify="end">
          <Col>
            <Tooltip
              title="Editar grupo de usuário"
            >
              <Button
                type="primary"
                shape="circle"
                ghost size="small"
                onClick={async () => {
                  await setUserGroup(data);
                  setTitleDrawer('Editar grupo de usuário')
                  setShowDrawer(true);
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          </Col>
          {
            !toggleSwitch
              ?
              <Col>
                <Popconfirm
                  title="Deseja remover o campo?"
                  onConfirm={() => handleRemove(data)}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Button type="danger" shape="circle" ghost size="small">
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Col>
              :
              <></>
          }
        </Row>
      ),
      align: "right",
    }
  ]

  const handleRemove = async (data) => {
    try {
      setLoading(true);
      console.log('entrou')
      const result = await api.delete("/user-groups", {
        params: {
          id: data.id,
        },
      });

      setReloadIndex(true);

      message.success("Grupo de usuário alterado com sucesso.")

    } catch (error) {
      console.log(error)
      message.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Main
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          itensMenu={itensDrawer}
          modulePath={props.modulePath}
          selectedMenu="2"
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"flex"}
      buttonHeader={
        <Row>
          <Col>
            <span
              style={checkControl(
                signin.user.profiles,
                "check_user_group_inactivated"
              )}
            >
              <Switch
                checkedChildren="Inativos"
                unCheckedChildren="Ativos"
                onChange={() => setToggleSwitch(!toggleSwitch)}
                style={{ marginRight: "5px", marginTop: "5px" }}
              />
            </span>
          </Col>
          <Col>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={async () => {
                await setUserGroup({
                  name: "",
                  description: "",
                  active: true,
                  users: [],
                });
                setTitleDrawer('Incluir grupo de usuário');
                setShowDrawer(true);
              }}
              style={{
                ...checkControl(signin.user.profiles, "insert_user_group"),
                marginLeft: "20px",
              }}
            >
              Novo
            </Button>
          </Col>
        </Row>
      }
    >
      <Table
        size="small"
        columns={columns}
        dataSource={userGroups.rows}
        rowKey={(record) => record.sequence}
        loading={loading}
        pagination={{
          current: page,
          pageSize: rowsPerPage,
          total: userGroups.count,
          showSizeChanger: true,
          onShowSizeChange: function (current, size) {
            setRowsPerPage(size);
          },
          pageSizeOptions: [5, 10, 50, 100],
        }}
        onChange={(pagination) =>
          setPage(pagination.current)
        }
      />

      <Drawer
        title={titleDrawer}
        width="70%"
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
        getContainer={false}
      >
        <FormUserGroup
          key="formUserGroup"
          userGroup={userGroup}
          setShowDrawer={setShowDrawer}
          setReloadIndex={setReloadIndex}
        />
      </Drawer>

    </Main>
  );
}

export default UserGroup;