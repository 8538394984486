import { Button, Col, Form, message, Modal, Row, Spin } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { useState } from "react";
import { postDocumentApi } from "../../../../services/documentBaseService";
import { useMutate } from "../../../../utils/useMutate";
import { InboxOutlined } from "@ant-design/icons";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const uploadDocumentMutation = ({ data, params }) => {
  return postDocumentApi(data, params);
};

export const UploadDocument = ({
  baseAttendance,
  trigger,
  onUploadSuccess,
}) => {
  const { mutate: uploadDocument, loading } = useMutate(
    uploadDocumentMutation,
    {
      onSuccess: (res) => {
        message.success("Documento enviado com sucesso!");
        onUploadSuccess && onUploadSuccess();
        setIsOpen(false);
      },
      onError: (err) => {
        message.error(err.message);
      },
    }
  );

  const [isOpen, setIsOpen] = useState(false);

  const [files, setFiles] = useState([]);

  const config = {
    name: "file",
    multiple: true,
    fileList: files,
    beforeUpload: (file) => {
      setFiles((prev) => [...prev, file.originFileObj]);
      return false;
    },
  };

  const [formDocument] = Form.useForm();

  const handleSubmit = async (values) => {
    const formData = new FormData();

    values.files.forEach((file) => {
      formData.append("documents", file.originFileObj, file.originFileObj.name);
    });

    uploadDocument({
      data: formData,
      params: {
        attendance: baseAttendance,
      },
    });
  };

  return (
    <>
      {trigger &&
        trigger({
          handleOpen: () => setIsOpen(true),
        })}
      <Modal
        title="Incluir"
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={false}
      >
        <Spin tip="Salvando..." spinning={loading}>
          <Form
            layout="vertical"
            form={formDocument}
            encType="multipart/form-data"
            name="form-users"
            onFinish={handleSubmit}
            //   validateMessages={validateMessages}
          >
            <Row>
              <Col xs={24}>
                <Form.Item name="files" label="">
                  <Form.Item
                    name="files"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                  >
                    <Dragger {...config}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Clique ou arraste o arquivo para esta área para fazer o
                        upload
                      </p>
                      <p className="ant-upload-hint">
                        Suporte para upload único ou em massa.
                      </p>
                    </Dragger>
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" align="bottom">
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    marginLeft: "8px",
                  }}
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
