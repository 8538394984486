/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles.css";
import { Tooltip } from "antd";
import { SyncOutlined,DownOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

import { FullScreen, useFullScreenHandle } from "react-full-screen";

import Main from "../../../Layout/Main";
import DynamicIcon from "../../../../utils/dynamicIcon";

import loadingSVG from "../../../../assets/images/loading.svg";
import moment from "moment";

import { baseServicesService } from "../../../../services/baseServices";
import { ServicesService } from "../../../../services/services";
import { appointmentsApi } from "../../../../services/appointments";
import { appointmentsApiProtheus } from "../../../../services/appointmentsProtheus";

import { ModalApp } from "../../../../components/Modal";
import ModalProtheusData from "../../../../components/ModalProtheusData";

import { openModal, modalProtheus } from "../../../../store/ducks/modalGlobal";
import {
  setData,
  setLoadingProtheusData,
} from "../../../../store/ducks/modalProtheusData";

import { BaseAttendanceContext } from "../../../../Context/baseAttendanceContext";
import "moment/locale/pt-br";
import locale from "antd/es/date-picker/locale/pt_BR";

import {
  Row,
  Col,
  Alert,
  Collapse,
  Tag,
  Steps,
  Form,
  DatePicker,
  Input,
  Button,
  message,
  Skeleton,
  Pagination,
  Spin,
  Modal,
  Divider,
  Select,
  Popover,
} from "antd";

import {
  FilePdfOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import { VolumeMute, VolumeUp } from 'react-bootstrap-icons'

import {
  LoadingOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  FileSyncOutlined,
  FileExcelOutlined,
  ContainerOutlined,
} from "@ant-design/icons";

import FormDocument from "../../../../components/FormDocument";
import MenuComponent from "../../../../components/MenuComponent";
import { itensMenu } from "../itens-menu";
import { useFetch } from "../../../../utils/useFetch";
import { useSSE } from "../../../../hooks/serverSentEvent";
import { debounce } from "lodash";
import { useCallback } from "react";

const { Panel } = Collapse;
const { Step } = Steps;
const { RangePicker } = DatePicker;
const { Option } = Select;

// ordena os campos por ID do status
const sortData = (data) =>
  data
    .map((value) => value && value)
    .sort((a, b) => b.general_status_id - a.general_status_id);

// ordena os campos por sequencia
const sortSequence = (data) =>
  data.sort(
    (a, b) => a.SettingServiceField.sequence - b.SettingServiceField.sequence
  );

function ScreenOrderService(props) {
  const signin = useSelector((state) => state.signin);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [primaryLoading, setPrimaryLoading] = useState(false);
  const [loadingSecondary, setLoadingSecondary] = useState(false);
  const [threeLoading, setThreeLoading] = useState(false);
  const [attendanceFilter, setAttendanceFilter] = useState("");
  const [attendanceFilterName, setAttendanceFilterName] = useState("");
  const [intervalFilter, setIntervalFilter] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [dataModal, setDataModal] = useState();
  const [baseAttendance, setBaseAttendance] = useState("");
  const [modalDocument, setModalDocument] = useState(false);
  const [modalOSAttendance, setModalOSAttendance] = useState(false);
  const [statusAttendanceLiberate, setStatusAttendanceLiberate] = useState(0);
  const [idAttendance, setIdAttendance] = useState(0);
  const [dateLiberation, setDateLiberation] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [allowSound, setAllowSound] = useState(true);	
  const [firstLoading, setFirstLoading] = useState(true);
  const { data: attendances, refetch: refetchAttendances, setData: setAttendances, loading: attendancesLoading } = useFetch(
    baseServicesService.getAttendances,
    {
      autoFetch: false,
      onSuccess: () => {
        firstLoading && setFirstLoading(false);
        setLastUpdated(new Date());
      }
    },
  );

  const debouncedTaksUpdate = debounce(
      () => {
          refetchAttendances({
              limit: rowsPerPage,
              offset: (page - 1) * rowsPerPage,
              company_id: signin.user.currentCompany,
          }).then(() => {
              if (allowSound) {
                  new Audio("/audio/notification.wav").play();
              }
          });
      },
      3000,// tempo de espera para disparar a função
      {
          leading: true,
          trailing: true,
          maxWait: 3000,// tempo máximo para disparar a função
      }
  );

  const handleMessages = useCallback(
      (event) => {
          debouncedTaksUpdate();
      },
      [
          allowSound,
          signin.user.currentCompany,
      ]
  );

  useSSE(`${process.env.REACT_APP_BACKEND_API}sse?channel=monitor-company=${signin.user.currentCompany}`, {
      onMessage: handleMessages,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const allowSoundLocalStorage = localStorage.getItem("allowSound");
    setAllowSound(allowSoundLocalStorage === "true");
  }, [])

  useEffect(() => {
    refetchAttendances({
      limit: rowsPerPage,
      offset: (page - 1) * rowsPerPage,
      company_id: signin.user.currentCompany,
    });
  }, [page, rowsPerPage, signin.user.currentCompany]);


  const breadcrumb = [
    {
      title: "Central de Atendimento ao Cliente",
      path: props.modulePath,
    },
    {
      title: "Monitor de Atendimentos",
      path: props.modulePath + "",
    },
  ];

  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    if (fullScreen) {
      handle.exit();
      setRowsPerPage(4);
      setPage(1);
    } else {
      handle.enter();
      setRowsPerPage(7);
      setPage(1);
    }
    setFullScreen(!fullScreen);
  };


  /**
   * Busca os detalhes do atendimento
   */
  const getDataProtheus = (data) => {
    dispatch(modalProtheus(true));
    dispatch(setLoadingProtheusData(true));

    const { code_erp: subsidiary_erp, Hierarquia } = signin.user.userCompanies.find(
      (company) => company.id === signin.user.currentCompany
    );

    let restProtheus = data.includes("P") ? "restcremapet/getcremapet" : "restatendimento/getatendimento"

    appointmentsApiProtheus
      .getDetailsAttendanceProtheus(data, {
        headers: {
          tenantId: `${Hierarquia.code_erp},${subsidiary_erp}`,
        },
      },
      restProtheus)
      .then((response) => dispatch(setData(response)))
      .catch(() => dispatch(setData({})))
      .finally(() => {
        dispatch(setLoadingProtheusData(false));
      });
  };

  /**
   * Componente que monta os dados da resposta vindos do backend para cada serviço
   */
    const DataResponseField = ({ id_service }) => {
    const [loading, setLoading] = useState(true);
    const [html, setHtml] = useState();

    const getResponses = (id) => {
      setLoading(true);
      const fields = [];

      appointmentsApi
        .getAppointmentsStatusOS(id)
        .then(async(response) => {

          if (response.length !== 0) {
            sortSequence(response).map((field) => {
              let e = (
                <div key={field.id}>
                  <h4>{field.SettingServiceField.name}:</h4>
                  <p>
                    {field.value
                      ? moment(field.value).isValid() && field.SettingServiceField.id_type === 3
                        ? moment(field.value).format("DD/MM/YYYY HH:mm")
                        : field.value
                      : "Não informado pelo operador"}
                  </p>
                </div>
              );

              fields.push(e);
            });
            setHtml(<>{fields}</>);
          } else {
            setHtml(
              <>
                <p>A OS não foi iniciada.</p>
              </>
            );
          }
        })
        .finally(() => setLoading(false));
    };

    useEffect(() => {
      getResponses(id_service);
    }, []);

    return (
      <>
        {loading ? (
          <>
            <div className="loading-centar">
              <img src={loadingSVG} alt="loading" height={80} />
            </div>
            <h3 className="text-loading">Por favor, aguarde...</h3>
          </>
        ) : (
          html
        )}
      </>
    );
  };

  /**
   * Busca informações do apontamento/atendimento atual
   */
  const getListAppontments = (base_service_id, id_sector) => {
    setThreeLoading(true);

    appointmentsApi
      .getAppointmentsStatus(base_service_id, id_sector)
      .then((response) => {
        let html = response
          .filter(
            (v, i, a) => a.findIndex((t) => t.cod_budget === v.cod_budget) === i
          )
          .map((order) => (
            <Collapse className="border-radius" key={order.id}>
              <Panel
                header={`Venda: ${order.cod_budget}`}
                key={order.id}
                className="border-radius"
                style={{ marginBottom: 5 }}
              >
                {response.map((order) =>
                  order.Services.map((service) => (
                    <Collapse className="border-radius" key={service.id}>
                      <Panel
                        header={`OS: ${service.id} - ${service.SettingService.name}: ${service.GeneralStatus.description}`}
                        key={service.id}
                        className="border-radius"
                        style={{ marginBottom: 5 }}
                      >
                        <DataResponseField id_service={service.id} />
                      </Panel>
                    </Collapse>
                  ))
                )}
              </Panel>
            </Collapse>
          ));

        setDataModal(html);
      })
      .catch(() => {
        let htmlError = (
          <Alert
            message="Oops.. Estamos com problemas em nossos servidores!"
            description="Não encontramos campos para esse atendimento."
            type="error"
            showIcon
          />
        );

        setDataModal(htmlError);
      })
      .finally(() => setThreeLoading(false));
  };

  const Description = ({ attendance, sector }) => {
    let countOsLogistics = 0;
    let countOsTanatopraxia = 0;
    let countOsFlowerShop = 0;
    let countOsFuneralsCenter = 0;
    let countOsCemetery = 0;

    let countOsLogisticsFinaly = 0;
    let countOsTanatopraxiaFinaly = 0;
    let countOsFlowerShopFinaly = 0;
    let countOsFuneralsCenterFinaly = 0;
    let countOsCemeteryFinaly = 0;

    if (attendance.Orders.length > 0) {
      attendance.Orders.map((order) => {
        if (order.id_sector === 3) {
          countOsLogistics += order.Services.length;
          countOsLogisticsFinaly += order.Services.filter(
            (service) => service.id_general_status === 3
          ).length;
        } else if (order.id_sector === 1) {
          countOsTanatopraxia += order.Services.length;
          countOsTanatopraxiaFinaly += order.Services.filter(
            (service) => service.id_general_status === 3
          ).length;
        } else if (order.id_sector === 8) {
          countOsFuneralsCenter += order.Services.length;
          countOsFuneralsCenterFinaly += order.Services.filter(
            (service) => service.id_general_status === 3
          ).length;
        } else if (order.id_sector === 5) {
          countOsFlowerShop += order.Services.length;
          countOsFlowerShopFinaly += order.Services.filter(
            (service) => service.id_general_status === 3
          ).length;
        } else if (order.id_sector === 4) {
          countOsCemetery += order.Services.length;
          countOsCemeteryFinaly += order.Services.filter(
            (service) => service.id_general_status === 3
          ).length;
        }
      });
    }

    return (
      <div>
        <div className="fonts">
          {sector === 3 ? (
            <strong
              className="pointer"
              onClick={() => {
                getListAppontments(attendance.id, sector);
                if (countOsLogistics !== 0) dispatch(openModal(true));
              }}
            >
              OS: {countOsLogistics}
              <br />
              {`${countOsLogisticsFinaly}/${countOsLogistics} Finalizadas`}
            </strong>
          ) : null}
          {sector === 1 ? (
            <strong
              className="pointer"
              onClick={() => {
                getListAppontments(attendance.id, sector);
                if (countOsTanatopraxia !== 0) dispatch(openModal(true));
              }}
            >
              OS: {countOsTanatopraxia}
              <br />
              {`${countOsTanatopraxiaFinaly}/${countOsTanatopraxia} Finalizadas`}
            </strong>
          ) : null}
          {sector === 8 ? (
            <strong
              className="pointer"
              onClick={() => {
                getListAppontments(attendance.id, sector);
                if (countOsFuneralsCenter !== 0) dispatch(openModal(true));
              }}
            >
              OS: {countOsFuneralsCenter}
              <br />
              {`${countOsFuneralsCenterFinaly}/${countOsFuneralsCenter} Finalizadas`}
            </strong>
          ) : null}
          {sector === 5 ? (
            <strong
              className="pointer"
              onClick={() => {
                getListAppontments(attendance.id, sector);
                if (countOsFlowerShop !== 0) dispatch(openModal(true));
              }}
            >
              OS: {countOsFlowerShop}
              <br />
              {`${countOsFlowerShopFinaly}/${countOsFlowerShop} Finalizadas`}
            </strong>
          ) : null}
          {sector === 4 ? (
            <strong
              className="pointer"
              onClick={() => {
                getListAppontments(attendance.id, sector);
                if (countOsCemetery !== 0) dispatch(openModal(true));
              }}
            >
              OS: {countOsCemetery}
              <br />
              {`${countOsCemeteryFinaly}/${countOsCemetery} Finalizadas`}
            </strong>
          ) : null}
        </div>
      </div>
    );
  };

  /**
   * Define o icone do status
   */
  const DefineIconStatus = ({ attendance, sector }) => {
    setLoadingSecondary(true);

    const [iconLogistics, setIconLogistics] = useState(3);
    const [iconTanatopraxia, setIconTanatopraxia] = useState(3);
    const [iconFuneralsCenter, setIconFuneralsCenter] = useState(3);
    const [iconFlowerShop, setIconFlowerShop] = useState(3);
    const [iconCemetery, setIconCemetery] = useState(3);

    useEffect(() => {
      getIcon(attendance);
    }, []);

    const icons = [
      <FileTextOutlined style={{ color: "#f1f15f" }} />,
      <FileSyncOutlined />,
      <FileDoneOutlined style={{ color: "#52c41a" }} />,
      <FileExcelOutlined style={{ color: "#a09f9f" }} />,
      <ContainerOutlined style={{ color: "#949292" }} />,
    ];

    const getIcon = async (data) => {
      sortData(data.Orders).map((order) => {
        if (order.id_sector === 3) {
          if (order.general_status_id === 1 && order.Services.length > 0) {
            setIconLogistics(0);
          } else if (order.general_status_id === 2 && order.Services.length > 0) {
            setIconLogistics(1);
          } else if (order.general_status_id === 3 && order.Services.length > 0) {
            setIconLogistics(2);
          } else if (order.general_status_id === 5 && order.Services.length > 0) {
            setIconLogistics(4);
          }
        } else if (order.id_sector === 1) {
          if (order.general_status_id === 1 && order.Services.length > 0) {
            setIconTanatopraxia(0);
          } else if (order.general_status_id === 2 && order.Services.length > 0) {
            setIconTanatopraxia(1);
          } else if (order.general_status_id === 3 && order.Services.length > 0) {
            setIconTanatopraxia(2);
          } else if (order.general_status_id === 5 && order.Services.length > 0) {
            setIconTanatopraxia(4);
          }
        } else if (order.id_sector === 8) {
          if (order.general_status_id === 1 && order.Services.length > 0) {
            setIconFuneralsCenter(0);
          } else if (order.general_status_id === 2 && order.Services.length > 0) {
            setIconFuneralsCenter(1);
          } else if (order.general_status_id === 3 && order.Services.length > 0) {
            setIconFuneralsCenter(2);
          } else if (order.general_status_id === 5 && order.Services.length > 0) {
            setIconFuneralsCenter(4);
          }
        } else if (order.id_sector === 5) {
          if (order.general_status_id === 1 && order.Services.length > 0) {
            setIconFlowerShop(0);
          } else if (order.general_status_id === 2 && order.Services.length > 0) {
            setIconFlowerShop(1);
          } else if (order.general_status_id === 3 && order.Services.length > 0) {
            setIconFlowerShop(2);
          } else if (order.general_status_id === 5 && order.Services.length > 0) {
            setIconFlowerShop(4);
          }
        } else if (order.id_sector === 4) {
          if (order.general_status_id === 1 && order.Services.length > 0) {
            setIconCemetery(0);
          } else if (order.general_status_id === 2 && order.Services.length > 0) {
            setIconCemetery(1);
          } else if (order.general_status_id === 3 && order.Services.length > 0) {
            setIconCemetery(2);
          } else if (order.general_status_id === 5 && order.Services.length > 0) {
            setIconCemetery(4);
          }
        }
      });
    };

    setLoadingSecondary(false);

    if (sector === 3) {
      return icons[iconLogistics];
    } else if (sector === 1) {
      return icons[iconTanatopraxia];
    } else if (sector === 8) {
      return icons[iconFuneralsCenter];
    } else if (sector === 5) {
      return icons[iconFlowerShop];
    } else if (sector === 4) {
      return icons[iconCemetery];
    } else {
      return icons[3];
    }
  };

  /**
   * Define o texto que será exibido no status
   */
  const setTitleStatus = (data, sector) => {
    let titleLogistics = "";
    let titleTanatopraxia = "";
    let titleFlowerShop = "";
    let titleFuneralsCenter = "";
    let titleCemetery = "";

    if (data.Orders.length > 0) {
      sortData(data.Orders).map((order) => {
        if (order.id_sector === 3) {
          if (order.general_status_id === 1 && order.Services.length > 0) {
            titleLogistics = "Aberto";
          } else if (order.general_status_id === 2 && order.Services.length > 0) {
            titleLogistics = "Iniciado";
          } else if (order.general_status_id === 3 && order.Services.length > 0) {
            titleLogistics = "Finalizado";
          } else if (order.general_status_id === 5 && order.Services.length > 0) {
            titleLogistics = "Agendado";
          }
        } else if (order.id_sector === 1) {
          if (order.general_status_id === 1 && order.Services.length > 0) {
            titleTanatopraxia = "Aberto";
          } else if (order.general_status_id === 2 && order.Services.length > 0) {
            titleTanatopraxia = "Iniciado";
          } else if (order.general_status_id === 3 && order.Services.length > 0) {
            titleTanatopraxia = "Finalizado";
          } else if (order.general_status_id === 5 && order.Services.length > 0) {
            titleTanatopraxia = "Agendado";
          }
        } else if (order.id_sector === 8) {
          if (order.general_status_id === 1 && order.Services.length > 0) {
            titleFuneralsCenter = "Aberto";
          } else if (order.general_status_id === 2 && order.Services.length > 0) {
            titleFuneralsCenter = "Iniciado";
          } else if (order.general_status_id === 3 && order.Services.length > 0) {
            titleFuneralsCenter = "Finalizado";
          } else if (order.general_status_id === 5 && order.Services.length > 0) {
            titleFuneralsCenter = "Agendado";
          }
        } else if (order.id_sector === 5) {
          if (order.general_status_id === 1 && order.Services.length > 0) {
            titleFlowerShop = "Aberto";
          } else if (order.general_status_id === 2 && order.Services.length > 0) {
            titleFlowerShop = "Iniciado";
          } else if (order.general_status_id === 3 && order.Services.length > 0) {
            titleFlowerShop = "Finalizado";
          } else if (order.general_status_id === 5 && order.Services.length > 0) {
            titleFlowerShop = "Agendado";
          }
        } else if (order.id_sector === 4) {
          if (order.general_status_id === 1 && order.Services.length > 0) {
            titleCemetery = "Aberto";
          } else if (order.general_status_id === 2 && order.Services.length > 0) {
            titleCemetery = "Iniciado";
          } else if (order.general_status_id === 3 && order.Services.length > 0) {
            titleCemetery = "Finalizado";
          } else if (order.general_status_id === 5 && order.Services.length > 0) {
            titleCemetery = "Agendado";
          }
        }
      });
    }

    if (sector === 3) {
      return titleLogistics ? titleLogistics : <br />;
    } else if (sector === 1) {
      return titleTanatopraxia ? titleTanatopraxia : <br />;
    } else if (sector === 5) {
      return titleFlowerShop ? titleFlowerShop : <br />;
    } else if (sector === 8) {
      return titleFuneralsCenter ? titleFuneralsCenter : <br />;
    } else if (sector === 4) {
      return titleCemetery ? titleCemetery : <br />;
    }
  };

  /**
   * Função para paginar a tela
   */
  const handlePageData = (page, pageSize) => {
    setPage(page);
    setRowsPerPage(pageSize);
  };

  /**
   * Formulário submit de filtro
   */
  const handleSubmit = async () => {
    if (
      !attendanceFilter &&
      !attendanceFilterName &&
      intervalFilter.length === 0
    ) {
      message.warning("Preencha um dos campos para realizar o filtro.");
      return false;
    }

    setPrimaryLoading(true);

    let url = "";

    if (intervalFilter && intervalFilter.length !== 0) {
      url += `&initial_date=${moment(
        intervalFilter[0]._d
      ).format()}&end_date=${moment(intervalFilter[1]._d).format()}`;
    }

    if (attendanceFilter) {
      url += `&num_attendance_erp=${attendanceFilter}`;
    }

    if (attendanceFilterName) {
      url += `&name_deceased=${attendanceFilterName.toUpperCase()}`;
    }

    // Precisa refatorar
    baseServicesService
      .getAttendancesFilter(5, 0, signin.user.currentCompany, url)
      .then((response) => {
        setAttendances(response);
      })
      .catch(() => message.warning("Oops... nenhum dado encontrado!"))
      .finally(() => setPrimaryLoading(false));
      
  };

  const handleSubmitDocument = (attendance) => {
    setBaseAttendance(attendance);
    setModalDocument(true);
  };

  const onVisibleDocumentsDeleted = () => setModalDocument(false);

  // libera a OS de remoção primária para iniciar
  const handleSubmitIsBlockedAttendance = async ({is_blocked_attendance}, id) => {
    try {
      await ServicesService.putIsBlockedAttendance(
        {
          is_blocked_attendance: is_blocked_attendance,
          unlocked_date: dateLiberation ? dateLiberation : moment(),
          date_register_liberation: moment(),
        },
        id
      );
      
      refetchAttendances();
      return message.success("OSs liberadas");
    } catch (e) {
      return message.error("Oops... Não foi possível liberar as OSs!");
    } finally {
      setModalOSAttendance(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Monitor de Atendimentos - webGM</title>
      </Helmet>
      <BaseAttendanceContext.Provider
        value={{
          setModalDocument,
          baseAttendance,
        }}
      >
        <Main
          moduleName={props.moduleName}
          collapsed={true}
          moduleMenu={
            <MenuComponent
              itensMenu={itensMenu}
              modulePath={props.modulePath}
              selectedMenu="2"
            />
          }
          breadcrumb={breadcrumb}
        >
          <FullScreen handle={handle}>
            <header className="header-attendances">
              <Row className="bars">
                <Col span={1}>
                  <Button
                    type="secondary"
                    icon={
                      !fullScreen ? (
                        <FullscreenOutlined />
                      ) : (
                        <FullscreenExitOutlined />
                      )
                    }
                    onClick={handleFullScreen}
                  />
                </Col>

                <Col span={1}>
                  <Tooltip placement="left" title={"Atualizar manualmente"}>
                    <Button
                      type="secondary"
                      icon={<SyncOutlined spin={attendancesLoading}/>}
                      disabled={attendancesLoading}
                      onClick={() => {
                        refetchAttendances();
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={1}>
                  <Tooltip placement="left" title={allowSound ? "Desativar som" : "Ativar som"}>
                    <Button
                      style={{ padding: 0, display: "flex", alignItems: "center", justifyContent: "center" }}
                      className="header-button"
                      type="secondary"
                      onClick={() => {
                        localStorage.setItem("allowSound", !allowSound)
                        setAllowSound(!allowSound)
                      }}
                      >
                      {allowSound ? <VolumeUp size={24} /> : <VolumeMute size={24} />}
                    </Button>
                  </Tooltip>
                </Col>
                <Col span={4}>
                  <Alert
                    message={attendancesLoading ? "Atualizando..."
                    : `Atualizado às ${moment(lastUpdated).format("LT")}`}
                    type="success"
                    showIcon
                  />
                </Col>

                <Col span={17}>
                  <div className="bars size">
                    <div className="space">
                      <DynamicIcon
                        name="CarOutlined"
                        style={{ fontSize: 14 }}
                      />
                      <h5 className="space-left">Logística</h5>
                    </div>
                    <div className="space size-header-icons">
                      <DynamicIcon
                        name="SkinOutlined"
                        style={{ fontSize: 14 }}
                      />
                      <h5 className="space-left">Tanatopraxia</h5>
                    </div>
                    <div className="space size-header-icons">
                      <DynamicIcon
                        name="ShopOutlined"
                        style={{ fontSize: 14 }}
                      />
                      <h5 className="space-left">Floricultura</h5>
                    </div>
                    <div className="space size-header-icons">
                      <DynamicIcon
                        name="BankOutlined"
                        style={{ fontSize: 14 }}
                      />
                      <h5 className="space-left">Cerimonial</h5>
                    </div>
                    <div className="space size-header-icons">
                      <DynamicIcon
                        name="InsertRowLeftOutlined"
                        style={{ fontSize: 14 }}
                      />
                      <h5 className="space-left">Cemitério</h5>
                    </div>
                  </div>
                </Col>
              </Row>
            </header>
            <div>
              <Row>
                <Col span={24}>
                  {firstLoading && (attendancesLoading || primaryLoading) ? (
                    <Skeleton active />
                  ) : attendances?.rows.length === 0 ? (
                    <div className="alertWarning">
                      <Alert
                        message="Ops... Não encontramos nenhum atendimento."
                        description="Tente novamente utilizando outros filtros."
                        type="warning"
                        showIcon
                        closable
                      />
                    </div>
                  ) : (
                    attendances?.rows.map((attendance) =>
                      loadingSecondary ? (
                        <div className="loading">
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{ fontSize: 24, marginRight: 10 }}
                                spin
                              />
                            }
                          />
                          <span>Carregando...</span>
                        </div>
                      ) : (
                        <Row className="rows">
                          <Col span={8}>
                            <h3
                              className="click"
                              onClick={() =>
                                getDataProtheus(attendance.num_attendance_erp)
                              }
                            >
                              {attendance.num_attendance_erp} -{" "}
                              {attendance.name_deceased}
                            </h3>
                            <div className="bottom">
                              <Tag color="default" className="tag">
                                {moment(attendance.createdAt).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </Tag>
                              <Tag color="default" className="tag">
                                {attendance.name_attendant}
                              </Tag>
                              <Popover
                                placement="bottomLeft"
                                title="Opções"
                                content={
                                  <Col>
                                    <Row style={{ marginBottom: 8 }}>
                                      <Button
                                        size="small"
                                        icon={<FilePdfOutlined />}
                                        onClick={() =>
                                          handleSubmitDocument(attendance.id)
                                        }
                                      >
                                        Documentos
                                      </Button>
                                    </Row>

                                    <Row>
                                      <Button
                                        size="small"
                                        icon={<CheckSquareOutlined />}
                                        // disabled={
                                        //   attendance.is_blocked_attendance ===
                                        //   "A"
                                        //     ? true
                                        //     : false
                                        // }
                                        onClick={() => {
                                          setIdAttendance(attendance.id);
                                          setModalOSAttendance(true);
                                        }}
                                      >
                                        Liberar OS's
                                      </Button>
                                    </Row>
                                  </Col>
                                }
                                trigger="click"
                              >
                                <Button icon={<DownOutlined />} size="small" />
                              </Popover>
                            </div>
                          </Col>
                          <Col span={16}>
                            <Steps>
                              <Step
                                status="finish"
                                icon={
                                  <DefineIconStatus
                                    attendance={attendance}
                                    sector={3}
                                  />
                                }
                                title={setTitleStatus(attendance, 3)}
                                description={
                                  <Description
                                    attendance={attendance}
                                    sector={3}
                                  />
                                }
                              />
                              <Step
                                status="finish"
                                icon={
                                  <DefineIconStatus
                                    attendance={attendance}
                                    sector={1}
                                  />
                                }
                                title={setTitleStatus(attendance, 1)}
                                description={
                                  <Description
                                    attendance={attendance}
                                    sector={1}
                                  />
                                }
                              />
                              <Step
                                status="finish"
                                icon={
                                  <DefineIconStatus
                                    attendance={attendance}
                                    sector={5}
                                  />
                                }
                                title={setTitleStatus(attendance, 5)}
                                description={
                                  <Description
                                    attendance={attendance}
                                    sector={5}
                                  />
                                }
                              />
                              <Step
                                status="finish"
                                icon={
                                  <DefineIconStatus
                                    attendance={attendance}
                                    sector={8}
                                  />
                                }
                                title={setTitleStatus(attendance, 8)}
                                description={
                                  <Description
                                    attendance={attendance}
                                    sector={8}
                                  />
                                }
                              />
                              <Step
                                status="finish"
                                icon={
                                  <DefineIconStatus
                                    attendance={attendance}
                                    sector={4}
                                  />
                                }
                                title={setTitleStatus(attendance, 4)}
                                description={
                                  <Description
                                    attendance={attendance}
                                    sector={4}
                                  />
                                }
                              />
                            </Steps>
                          </Col>
                        </Row>
                      )
                    )
                  )}
                </Col>
              </Row>
            </div>
            {attendances?.rows.length === 0 ? null : (
              <footer className="footer-attendances">
                <Divider />
                <Form onFinish={handleSubmit}>
                  <Row gutter={8}>
                    <Col span={6}>
                      <Form.Item name="intervalo">
                        <h6>Intervalo:</h6>
                        <RangePicker
                          placeholder={["Data inicial", "Data final"]}
                          format="DD/MM/YYYY"
                          value={intervalFilter}
                          onChange={(dates) => setIntervalFilter(dates)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={3}>
                      <Form.Item name="attendance">
                        <h6>Atendimento:</h6>
                        <Input
                          value={attendanceFilter}
                          onChange={(e) => setAttendanceFilter(e.target.value)}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item name="attendance">
                        <h6>Falecido:</h6>
                        <Input
                          value={attendanceFilterName}
                          onChange={(e) =>
                            setAttendanceFilterName(e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8} offset={1}>
                      {attendances?.count !== 0 ? (
                        <Row className="float-right">
                          <Pagination
                            size="small"
                            current={page}
                            pageSize={rowsPerPage}
                            total={attendances?.count}
                            onChange={handlePageData}
                          />
                        </Row>
                      ) : null}
                      <br />
                      <Row className="maring-button">
                        <Col span={3} offset={9}>
                          <Button
                            type="primary"
                            shape="round"
                            size="small"
                            htmlType="submit"
                          >
                            Filtrar
                          </Button>
                        </Col>

                        <Col span={5}>
                          <Button
                            type="default"
                            shape="round"
                            size="small"
                            className="button"
                            onClick={() => {
                              setPrimaryLoading(true);
                              setAttendanceFilter("");
                              setAttendanceFilterName("");
                              setIntervalFilter([]);
                              setPage(1);
                              refetchAttendances();
                              setPrimaryLoading(false);
                            }}
                          >
                            Limpar
                          </Button>
                        </Col>

                        <Col span={3}>
                          <Button
                            type="default"
                            shape="round"
                            size="small"
                            className="button"
                            onClick={() => window.location.reload()}
                          >
                            Atualizar
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </footer>
            )}
            <ModalApp title="Detalhe das OSs" footer={null}>
              {threeLoading ? (
                <>
                  <div className="loading-centar">
                    <img src={loadingSVG} alt="loading" height={80} />
                  </div>
                  <h3 className="text-loading">Por favor, aguarde...</h3>
                </>
              ) : (
                dataModal
              )}
            </ModalApp>

            <FormDocument
              title="Documentos do Atendimento"
              visible={modalDocument}
              onVisibleDocumentsDeleted={onVisibleDocumentsDeleted}
              onCancel={() => setModalDocument(false)}
              footer={null}
            />
          </FullScreen>
          <ModalProtheusData />
          <Modal
            title="Liberar OS"
            visible={modalOSAttendance}
            onCancel={() => setModalOSAttendance(false)}
            footer={null}
          >
            <Form
              onFinish={(values) =>
                handleSubmitIsBlockedAttendance(values, idAttendance)
              }
            >
              <Row gutter={8}>
                <Col>
                  <Form.Item name="is_blocked_attendance" label="Status">
                    <Select
                      onChange={(value) => setStatusAttendanceLiberate(value)}
                      defaultValue="P"
                    >
                      <Option value="P">Pendente</Option>
                      <Option value="L">Liberar</Option>
                      <Option value="A">Agendar</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <DatePicker
                    placeholder="Selecione a data de agendamento"
                    showTime={{ format: "HH:mm" }}
                    disabled={statusAttendanceLiberate !== "A" ? true : false}
                    onChange={(date) => setDateLiberation(date)}
                    locale={locale}
                  />
                </Col>
                <Col>
                  <Button type="primary" shape="round" htmlType="submit">
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal>
        </Main>
      </BaseAttendanceContext.Provider>
    </>
  );
}

export default ScreenOrderService;
