import { Button, Divider, Empty } from "antd";
import styles from "./chat-list.module.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Loading from "../../../../../components/Loading";

/**
 *
 * @param {object} props
 * @param {Chat[]} props.chats
 * @returns
 */
export function ChatsList({ chats, loading }) {
    return (
        <div>
            <h2>Chats</h2>
            <div className={styles.chatsWrapper}>
                {loading ? (
                    <Loading />
                ) : chats.length === 0 ? (
                    <Empty description="Nenhum chat encontrado" />
                ) : (
                    chats.map((chat) => <ChatItem key={chat.id} chat={chat} />)
                )}
            </div>
        </div>
    );
}

/**
 *
 * @param {object} props
 * @param {Chat} props.chat
 * @returns
 */

const ChatItem = ({ chat }) => {
    return (
        <div className={styles.chatItem}>
            <div className={styles.flexRow}>
                <div className={styles.chatItemDetails}>
                    <div>
                        <span>Canal</span>
                        <p>{chat.channel}</p>
                    </div>
                    <div>
                        <span>Situação</span>
                        <p>{chat.situation}</p>
                    </div>

                    <div>
                        <span>ID do atendimento</span>
                        <p>{chat.id}</p>
                    </div>
                    <div>
                        <span>Tabulação</span>

                        <p>{chat.tabulation}</p>
                    </div>
                    <div>
                        <span>Departamento</span>
                        <p>{chat.department}</p>
                    </div>
                </div>
                <Link
                    style={{ marginLeft: "auto" }}
                    to={`/customer-service-center/huggy-backup/chats/${chat.id}?contactId=${chat.contact_id}`}
                >
                    <Button type="primary">Abrir Conversa</Button>
                </Link>
            </div>

            <Divider />
            <div className={styles.flexRow}>
                <span>
                    {moment(chat.created_at).format("DD MMM YYYY, ddd HH:mm")}
                </span>
            </div>
        </div>
    );
};

/**
 * @typedef {object} Chat
 * @property {number} id
 * @property {number} contact_id
 * @property {string} tabulation
 * @property {string} department
 * @property {string} channel
 * @property {?string} situation
 * @property {string} name_agent
 * @property {string} phone_agent
 * @property {string} phone_contact
 * @property {Date} created_at
 * @property {Date} updated_at
 * @property {?Date} closed_at - Date representing closure time (nullable).
 */
