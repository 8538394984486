const Types = {
  ADD: "ADD",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
  GET: "GET",
  SET_SECTOR: "SET_SECTOR",
};

const INITIAL_STATE = {
  sectorsList: [],
  sectorsListCount: 0,
  sector: {
    id: null,
    name: "",
    description: "",
  },
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        sectorsList: action.payload.rows,
        sectorsListCount: action.payload.count,
      };

    case Types.SET_SECTOR:
      return {
        ...state,
        sector: {
          ...action.payload,
        },
      };

    case Types.ADD:
      return {
        ...state,
        sectorsList: action.payload.rows,
        sectorsListCount: action.payload.count,
      };

    case Types.REMOVE:
      return {
        ...state,
        sectorsList: action.payload.rows,
        sectorsListCount: action.payload.count,
      };

    default:
      return state;
  }
}

export function listSectors(data) {
  return {
    type: Types.GET,
    payload: data,
  };
}

export function alterSector(data) {
  return {
    type: Types.SET_SECTOR,
    payload: data,
  };
}

export function deleteSector(data) {
  return {
    type: Types.REMOVE,
    payload: data,
  };
}

export function addSector(data) {
  return {
    type: Types.ADD,
    payload: data,
  };
}