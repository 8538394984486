import { Col, Table } from 'antd';


function TableBenefits(props) {
  const columns = [
    {
      title: 'Código',
      dataIndex: 'benefit_code',
      key: 'benefit_code',
    },
    {
      title: 'Descrição',
      dataIndex: 'benefit_description',
      key: 'benefit_description',
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantity',
      key: 'quantity',
    },
  ];
  return (
    <Col span={24}>
      <Table
        dataSource={props.data ?? [{}]}
        columns={columns}
        scroll={{ x: 1100 }}
      />
    </Col>
  )
}

export default TableBenefits;