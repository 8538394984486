import { useState } from "react";
import { Col, Row, Steps, Divider } from "antd";
import { Helmet } from "react-helmet";
import Main from "../../../Layout/Main";
import { isMobile } from "../../../../utils/isMobile";
import { itensMenu } from "../itens-menu";

import ContractData from "./components/contract-data";
import DeceasedData from "./components/deceased-data";
import Product from "./components/product";
import Caveats from "./components/caveats";
// import FormOfPayment from "./components/form-of-payment";
import Removal from "./components/Removal";

import { RemovalAnticipationFormContext } from "../../../../Context/removal-anticipation-form-context";
import MenuComponent from "../../../../components/MenuComponent";
import { useScript } from "../../../../hooks/script";

const { Step } = Steps;

const RemovalAnticipationForm = (props) => {
  const [current, setCurrent] = useState(0);
  const [formResult, setFormResult] = useState({
    contract: {
      removeType: "humano",
      address: {
        complement: "",
      }
    },
    deceased: {},
    products: [],
    caveats: {},
    removal: {},
    // formOfPayments: [],
  });

  const scriptRef = useScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=console.log`)
  
  const breadcrumb = [
    {
      title: "Atendimento Funerário",
      path: props.modulePath,
    },
    {
      title: "Formulário de Antecipação de Remoção",
      path: props.modulePath + "/removal-anticipation-form",
    },
  ];

  const steps = [
    {
      title: "DADOS DO CONTRATO",
      content: <ContractData />,
    },
    {
      title: "DADOS DO FALECIDO (A)",
      content: <DeceasedData />,
    },
    {
      title: "PRODUTOS",
      content: <Product />,
    },
    {
      title: "RESSALVAS",
      content: <Caveats />,
    },
    {
      title: "REMOÇÃO",
      content: <Removal />,
    },
    // {
    //   key: 5,
    //   title: "FORMA DE PAGAMENTO",
    //   content: <FormOfPayment />,
    // },
  ];

  return (
    <div ref={scriptRef}>
      <Helmet>
        <title>FAR - webGM</title>
      </Helmet>

    <RemovalAnticipationFormContext.Provider
      value={{
        formResult,
        setFormResult,
        current,
        setCurrent,
      }}  
    >
     
      <Main
        key={props.moduleName}
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            modulePath={props.modulePath}
            selectedMenu="2"
            itensMenu={itensMenu}
          />
        }
        collapsed={true}
        breadcrumb={breadcrumb}
      >
        <Steps items={steps} responsive={ true } current={current} size="small">
        </Steps>
        <Divider orientation="left" />
        <Row>
          <Col xs={24}>{steps[current].content}</Col>
        </Row>
      </Main>
    </RemovalAnticipationFormContext.Provider>
    </div>
  );
};

export default RemovalAnticipationForm;
