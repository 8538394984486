import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./style.css";
import { useHistory } from "react-router";

import {
  Spin,
  Form,
  Row,
  Col,
  Button,
  Popconfirm,
  Input,
  Switch,
  Tabs,
  Select,
  message,
  Table,
  Tooltip,
  TreeSelect,
} from "antd";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";

import arrayMove from "array-move";

import {
  PlusCircleOutlined,
  DeleteOutlined,
  MenuOutlined,
  EditOutlined,
} from "@ant-design/icons";

import ModalField from "./ModalField";
import ModalForm from "./ModalForm";
import TableForm from "../../../../../../components/TableForm";
import Main from "../../../../../Layout/Main";

import { SettingServicesService } from "../../../../../../services/settingService";
import { ProductsService } from "../../../../../../services/products";
import { SectorsService } from "../../../../../../services/sectors";
import { SettingServicesProductService } from "../../../../../../services/SettingServiceProduct";
import { FormsBySettingServicesService } from "../../../../../../services/FormsBySettingServicesService";
import { FieldsBySettingServicesService } from "../../../../../../services/fieldsBySettingServicesService";
import { documentsSettingServiceApi } from "../../../../../../services/documentsSettingService";
import { TemplateDocuments } from "../../../../../../services/templateDocuments";

import { listCompanyApi } from "../../../../../../services/companies";
import { GoalService } from "../../../../../../services/indicatorGoals";

import Swal from "sweetalert2";
import moment from "moment";
import { isOwner } from "../../../../../../utils/owner";
import MenuComponent from "../../../../../../components/MenuComponent";
import { itensMenu } from "../../../itens-menu";
import Email from "./Email";
import { useFetch } from "../../../../../../utils/useFetch";

const { TabPane } = Tabs;
const { Option } = Select;
const { TreeNode } = TreeSelect;

const validateMessages = {
  required: "${label} é obrigatório!",
};

const InsertOrUpdateSettingService = (props) => {
  const settingService = useSelector(
    (state) => state.settingServices.settingService
  );

  const [pageFields, setPageFields] = useState(1);
  const [rowsPerPagefields, setRowsPerPageFields] = useState(10);

  const [loading, setLoading] = useState(false);
  const [openModalField, setOpenModalField] = useState(false);
  const [openModalForm, setOpenModalForm] = useState(false);

  const [loadingProduct, setLoadingProduct] = useState(false);
  const [products, setProducts] = useState({ rows: [], count: 0 });
  const [productsList, setProductsList] = useState({ rows: [], count: 0 });
  const [pageSelectProduct, setPageSelectProduct] = useState(1);
  const [rowsPerPageSelectProduct, setRowsPerPageSelectProduct] = useState(10);
  const [pageSelectGoal, setPageSelectGoal] = useState(1);
  const [rowsPerPageSelectGoal, setRowsPerPageSelectGoal] = useState(10);
  const [likeDescriptionProduct, setLikeDescriptionProduct] = useState("");
  const [productSelected, setProductSelected] = useState([]);
  const [pageProduct, setPageProduct] = useState(1);
  const [rowsPerPageProduct, setRowsPerPageProduct] = useState(5);
  const [companies, setCompanies] = useState([]);

  const [loadingForm, setLoadingForm] = useState(false);

  const [forms, setForms] = useState({ rows: [], count: 0 });
  const [pageForm, setPageForm] = useState(1);
  const [rowsPerPageForm, setRowsPerPageForm] = useState(2);

  const [likeNameSector, setLikeNameSector] = useState("");
  const [SectorsList, setSectorsList] = useState({ rows: [], count: 0 });
  const [pageSelectSector, setPageSelectSector] = useState(1);
  const [rowsPerPageSelectSector, setRowsPerPageSelectSector] = useState(10);
  const [sectorSelected, setSectorSelected] = useState("");

  const [likeNameSettingService, setLikeNameSettingService] = useState("");
  const [SettingServices, setSettingServices] = useState({
    rows: [],
    count: 0,
  });
  const [pageSelectSettingServices, setPageSelectSettingServices] = useState(1);
  const [
    rowsPerPageSelectSettingServices,
    setRowsPerPageSelectSettingServices,
  ] = useState(10);

  const [loadingField, setLoadingField] = useState(false);
  const [fieldsRemove, setFieldsRemove] = useState({ rows: [], count: 0 });
  //const [pageField, setPageField] = useState(1);
  const [currentField, setCurrentField] = useState({});

  const [loadingDocument, setLoadingDocument] = useState(false);
  const [pageDocument, setPageDocument] = useState(1);
  const [rowsPerPageDocument, setRowsPerPageDocument] = useState(2);
  const [documentsList, setDocumentsList] = useState({ rows: [], count: 0 });
  const [documents, setDocuments] = useState({ rows: [], count: 0 });
  const [documentSelected, setDocumentSelected] = useState("");
  const [pageCompany, setPageCompany] = useState(1);
  const [rowsPerPageCompany, setRowsPerPageCompany] = useState(10);

  const [listGoal, setListGoal] = useState([]);
  const [likeGoal, setLikeGoal] = useState("");
  const [goalSelected, setGoalSelected] = useState("");

  const [emails, setEmails] = useState([]);

  const signin = useSelector((state) => state.signin);

  const [form] = Form.useForm();
  const history = useHistory();

  const {
    data: fieldsData,
    setData: setFieldsData,
    loading: loadingFildsData,
    refetch: refetchFieldsData,
  } = useFetch(FieldsBySettingServicesService.getFieldsBySettingService, {
    initialParams: settingService.id, autoFetch: false,
    initialState: {rows: [], count: 0}
  });

  const handleModalFieldFinish = () => {
    refetchFieldsData()
    setOpenModalField(false);
  }

  useEffect(() => {
    getProductsBySettingService();
    getFormsBySettingService();
    getDocuments();
    if (settingService && settingService.id) {
      getDocumentsBySettingService();
      refetchFieldsData();
      setEmails(settingService.SettingServiceEmails)
    }
  }, []);

  useEffect(() => {
    getCompanies();
  }, [pageCompany]);

  /**
   * Busca as empresas
   */
  const getCompanies = () => {
    setLoading(true);

    const offset =
      pageCompany !== 1 ? rowsPerPageCompany * (pageCompany - 1) : 0;
    listCompanyApi
      .listCompany(rowsPerPageCompany, offset, true)
      .then((response) => {
        if (pageCompany !== 1) {
          response.rows.map((res) => {
            companies.rows.push(res);
          });
        } else {
          setCompanies(response);
        }
        setLoading(false);
      });
  };

  /**
   * Pegar as ligações já existentes entre documento e setting service
   */
  const getDocumentsBySettingService = async () => {
    try {
      if (!settingService.id) throw "Setting Service não informado";

      const result =
        await documentsSettingServiceApi.setDocumentsSettingService(
          settingService.id
        );

      setDocuments(result);
    } catch (error) {
      message.error(error);
    }
  };



  const getProductsBySettingService = () => {
    if (settingService.id)
      SettingServicesProductService.getProductsBySettingService(
        settingService.id
      ).then((res) => setProducts(res));
  };

  const getFormsBySettingService = async () => {
    try {
      if (settingService.id) {
        const data = [];

        const result =
          await FormsBySettingServicesService.getFormsBySettingService(
            settingService.id
          );

        result.rows.map((form) => {
          data.push({ ...form, ...form.Form });
        });

        setForms({ rows: data, count: data.length });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getProducts();
    getSectors();
    getSettingServices();
    getGoals();
    getGoalSettinsService();
  }, []);

  useEffect(() => {
    getProducts();
  }, [pageSelectProduct, likeDescriptionProduct]);

  useEffect(() => {
    getGoals();
  }, [pageSelectGoal, likeGoal]);

  useEffect(() => {
    getSectors();
  }, [pageSelectSector, likeNameSector]);

  useEffect(() => {
    getSettingServices();
  }, [
    rowsPerPageSelectSettingServices,
    pageSelectSettingServices,
    likeNameSettingService,
    sectorSelected,
  ]);

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Configuração de Serviços",
      path: props.modulePath + "/service-management/setting-services",
    },
    {
      title: "Adicionar/Alterar",
      path:
        props.modulePath +
        "/service-management/setting-services/insert-or-update",
    },
  ];

  /**
   * Função que busca os ocumentos daquele setting service
   */

  const getDocuments = async () => {
    try {
      const result = await documentsSettingServiceApi.documentsSettingService();

      setDocumentsList(result);
    } catch (error) {
      message.error("Oops... Erro ao buscar os documentos!");
    }
  };

  /**
   * Busca as metas
   */
  const getGoals = () => {
    const offset =
      pageSelectGoal !== 1 ? rowsPerPageSelectGoal * (pageSelectGoal - 1) : 0;

    GoalService.index(
      rowsPerPageSelectGoal,
      offset,
      signin.user.currentCompany,
      likeGoal
    )
      .then((res) => {
        return Promise.all(
          res.rows.map((value) => {
            if (!value.goal) {
              return {
                ...value,
                goal: `${moment(value.start_date).format(
                  "DD/MM/YYYY"
                )} - ${moment(value.end_date).format("DD/MM/YYYY")}`,
              };
            } else if (value.goal.length <= 3) {
              return {
                ...value,
                goal: `${value.goal}%`,
              };
            } else {
              return {
                ...value,
                goal: moment(value.goal).format("HH:mm:ss"),
              };
            }
          })
        ).then((response) => setListGoal(response));
      })
      .catch(() =>
        message.error(
          "Ops. não encontramos as metas, verifique se elas estam cadastrados."
        )
      );
  };

  /**
   * Busca a meta desse setting service
   */
  const getGoalSettinsService = () => {
    setLoading(true);

    GoalService.show(settingService.id)
      .then((response) => setGoalSelected(response))
      .catch(() => message.error("Ops. ocorreu um erro ao carregar a meta."))
      .finally(() => setLoading(false));
  };

  /**
   * Seleciona a meta para o setting service
   */
  const addGoal = () => {
    setLoading(true);

    GoalService.update({
      id: goalSelected,
      setting_service_id: settingService.id,
    })
      .then(() => message.success("Meta salva com sucesso."))
      .catch(() => message.error("Ops. ocorreu um erro ao salvar a meta."))
      .finally(() => setLoading(false));
  };

  const getProducts = () => {
    let param = "";
    let reg = /[0-9]/;
    const offset =
      pageSelectProduct !== 1
        ? rowsPerPageSelectProduct * (pageSelectProduct - 1)
        : 0;

    if (likeDescriptionProduct) {
      if (!reg.test(likeDescriptionProduct)) {
        param = `&description=${likeDescriptionProduct.toUpperCase()}`;
      } else {
        param = `&code=${likeDescriptionProduct}`;
      }
    }

    ProductsService.getProducts(
      rowsPerPageSelectProduct,
      offset,
      true,
      signin.user.currentCompany,
      param
    )
      .then((res) => setProductsList(res))
      .catch(() =>
        message.error(
          "Ops. não encontramos o produto, verifique se ele esta cadastrado."
        )
      );
  };

  const getSectors = () => {
    const offset =
      pageSelectSector !== 1
        ? rowsPerPageSelectSector * (pageSelectSector - 1)
        : 0;

    SectorsService.getSectors(
      rowsPerPageSelectSector,
      offset,
      true,
      likeNameSector
    ).then((res) => setSectorsList(res));
  };

  const getSettingServices = () => {
    const offset =
      pageSelectSettingServices !== 1
        ? rowsPerPageSelectSettingServices * (pageSelectSettingServices - 1)
        : 0;

    SettingServicesService.getSettingServiceFilterParams({
      limit: rowsPerPageSelectSettingServices,
      offset,
      active: true,
      id_sector: sectorSelected,
      likeNameSettingService,
    }).then((res) => setSettingServices(res));
  };

  const onFinish = (values) => {
    setLoading(true);

    if (!values.is_monitoring) {
      values.is_monitoring = false;
    }

    if (!values.is_sms) {
      values.is_sms = false;
    }

    if (!values.is_photo) {
      values.is_photo = false;
    }

    const data = {
      id: settingService.id,
      ...values,
      products: products.rows,
      forms: forms.rows,
      fields: fieldsData.rows,
      fiedsRemove: fieldsRemove.rows,
      documents: documents.rows,
      emails,
    };

    if (!data.id) {
      SettingServicesService.postSettingServices(data)
        .then((res) => {
          message.success("Registro criado com sucesso!");
          history.goBack();
        })
        .catch((error) => {
          message.success(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      SettingServicesService.putSettingServices({
        ...data,
        fields: fieldsData.rows,
      })
        .then((res) => {
          message.success("Registro alterado com sucesso!");
          history.goBack();
        })
        .catch((error) => {
          message.success(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const remove = () => {
    const active = settingService.active ? false : true;
    setLoading(true);

    SettingServicesService.deleteSettingServices(settingService.id, active)
      .then((res) => {
        message.success("Registro removido com sucesso!");
        history.goBack();
      })
      .catch((error) => {
        message.success(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveProduct = (data) => {
    const newProducts = products.rows.filter(
      (product) => product.id !== data.id
    );

    if (newProducts.length === 0) setPageProduct(1);

    setProducts({ rows: newProducts, count: newProducts.length });
  };

  const handleRemoveDocument = async (data) => {
    const newDocuments = documents.rows.filter(
      (document) => document.id !== data.id
    );

    await documentsSettingServiceApi
      .deleteDocumentsSettingService(data.id)
      .then(() => {
        message.success("Documento excluído com sucesso.");
        return setDocuments({ rows: newDocuments, count: newDocuments.length });
      })
      .catch(() => {
        return message.error("Oops... erro ao excluir o documento.");
      });
  };

  // add novos produtos ao serviço
  const addProduct = async () => {
    setLoadingProduct(true);

    const productsForm = [...products.rows];

    return Promise.all(
      productSelected.map(async (element) => {
        try {
          if (products.rows.find((product) => product.id === element))
            return message.info("Produto(s) já existente na lista!");

          await ProductsService.show(element)
            .then((res) => productsForm.push(res))
            .catch(() => false);
        } catch (e) {
          throw e;
        }
      })
    )
      .then(() =>
        setProducts({ rows: productsForm, count: productsForm.length })
      )
      .catch(() => message.error("Não foi possível localizar o produto"))
      .finally(() => setLoadingProduct(false));
  };

  const addDocument = () => {
    try {
      if (documents.rows.find((product) => product.id === documentSelected))
        return message.info("Documento já existente!");

      TemplateDocuments.show(documentSelected)
        .then((response) => {
          const newDocuments = [...documents.rows];
          newDocuments.push({
            id: response.id,
            id_sector: response.id_sector,
            name: response.name,
            active: response.active,
            signature_client: response.signature_client,
          });
          setDocuments({ rows: newDocuments, count: newDocuments.length });
        })
        .catch((error) =>
          message.error("Não foi possível localizar o documento")
        );
    } catch (error) {
      TemplateDocuments.show(documentSelected)
        .then((response) => {
          setDocuments({
            rows: [
              {
                id: response.id,
                id_sector: response.id_sector,
                name: response.name,
                active: response.active,
                signature_client: response.signature_client,
              },
            ],
            count: [response].length,
          });
        })
        .catch((error) =>
          message.error("Não foi possível localizar o documento")
        );
    }
  };

  const handleRemoveForm = (data) => {
    const newForms = forms.rows.filter((form) => form.id !== data.id);

    if (newForms.length === 0) setPageForm(1);

    setForms({ rows: newForms, count: newForms.length });
  };

  const handleRemoveField = (data) => {
    const newFields = fieldsData.rows.filter((field) => field.id !== data.id);
    const removeFieds = [...fieldsRemove.rows];
    removeFieds.push(data);

    if (newFields.length === 0) setPageFields(1);

    setFieldsData({ rows: newFields, count: newFields.length });
    setFieldsRemove({ rows: removeFieds, count: removeFieds.length });
  };

  const onReset = () => {
    form.resetFields();

    history.push(
      "/" + props.modulePath + "/service-management/setting-services"
    );
  };

  const columnsDocuments = [
    {
      dataIndex: "name",
      title: "Descrição",
    },
    {
      dataIndex: ["Sector", "description"],
      title: "Setor",
    },
    {
      render: (data) => (
        <Popconfirm
          title="Deseja remover o documento?"
          onConfirm={() => handleRemoveDocument(data)}
          okText="Sim"
          cancelText="Não"
        >
          <Button type="danger" shape="circle" ghost size="small">
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
      align: "right",
    },
  ];

  const columnsProducts = [
    {
      dataIndex: "code",
      key: "code",
      title: "Código ERP",
    },
    {
      dataIndex: "description",
      key: "description",
      title: "Descrição",
    },
    {
      dataIndex: ["Company", "name"],
      key: "company",
      title: "Empresa",
    },
    {
      render: (data) => (
        <Popconfirm
          title="Deseja remover o produto?"
          onConfirm={() => handleRemoveProduct(data)}
          okText="Sim"
          cancelText="Não"
        >
          <Button type="danger" shape="circle" ghost size="small">
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
      align: "right",
    },
  ];

  const columnsForms = [
    {
      dataIndex: "title",
      key: "title",
      title: "Título",
    },
    {
      dataIndex: "fill_moment",
      key: "fill_moment",
      title: "Momento",
      render: (value) => (value === 1 ? "Início" : "Final"),
    },
    {
      dataIndex: "is_required",
      key: "is_required",
      title: "Obrigatório",
      render: (value) => (value === true ? "Sim" : "Não"),
    },
    {
      render: (data) => (
        <Popconfirm
          title="Deseja remover o formulário?"
          onConfirm={() => handleRemoveForm(data)}
          okText="Sim"
          cancelText="Não"
        >
          <Button type="danger" shape="circle" ghost size="small">
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
      align: "right",
    },
  ];

  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
  ));

  const columnsFields = [
    {
      render: () => <DragHandle />,
    },
    {
      dataIndex: "name",
      key: "name",
      title: "Nome",
    },
    {
      title: "Tipo",
      render: (value) => value.ItemType.description,
    },
    {
      dataIndex: "is_required",
      key: "is_required",
      title: "Obrigatório",
      render: (value) => (value === true ? "Sim" : "Não"),
      align: "center",
    },
    {
      dataIndex: "is_indicator",
      key: "is_indicator",
      title: "Indicador",
      render: (value) => (value === true ? "Sim" : "Não"),
      align: "center",
    },
    {
      dataIndex: "is_visible",
      key: "is_visible",
      title: "Visualizar",
      render: (value) => (value === true ? "Sim" : "Não"),
      align: "center",
    },
    {
      align: "right",
      render: (data) => (
        <Button
          type="primary"
          shape="circle"
          ghost
          size="small"
          onClick={() => handleOpenModal(data)}
        >
          <EditOutlined />
        </Button>
      ),
    },
    {
      render: (data) => (
        <Popconfirm
          title="Deseja remover o campo?"
          onConfirm={() => handleRemoveField(data)}
          okText="Sim"
          cancelText="Não"
        >
          <Button type="danger" shape="circle" ghost size="small">
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
      align: "right",
    },
  ];

  /**
   * Funções para fazer o drag and drop da tabela de campos
   */

  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainerData = sortableContainer((props) => (
    <tbody {...props} />
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(fieldsData.rows),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      const data = newData.map((item, index) => {
        return {
          ...item,
          sequence: index + 1,
        };
      });

      setFieldsData({
        rows: [...data],
        count: data.length,
      });
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = fieldsData.rows.findIndex(
      (x) => x.sequence === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const DraggableContainer = (props) => (
    <SortableContainerData
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  /**
   * Função para abrir o modal com dados de campos, caso seja para edição
   */
  const handleOpenModal = async (data) => {
    setCurrentField(data);
    setOpenModalField(true);
  };

  /**
   * Função para ordenar pela sequencia
   */
  const sortData = (data) => {
    return data.sort((a, b) => {
      return parseInt(a.sequence) - parseInt(b.sequence);
    });
  };

  /**
   * Função que limpa o openModalField caso feche o modal sem fazer alterações
   */
  const cleanFields = (value) => {
    setCurrentField(value);
  };

  const messageClose = () => {
    Swal.fire({
      title: "Oops...",
      text: "Você possui alterações não salvas. Deseja realmente sair sem salvar ?",
      icon: "warning",
      showDenyButton: true,
      focusConfirm: false,
      denyButtonText: "Continuar editando",
      confirmButtonText: "Sair sem salvar",
      confirmButtonColor: "#c9c9c9",
      denyButtonColor: "#40a9ff",
    }).then((result) => {
      if (result.isConfirmed) {
        onReset();
      }
    });
  };

  return (
    <Main
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          itensMenu={itensMenu}
          modulePath={props.modulePath}
          selectedMenu="7"
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"flex"}
      buttonHeader={
        <>
          <Button htmlType="button" onClick={messageClose}>
            Cancelar
          </Button>
          {settingService.id ? (
            <Popconfirm
              title="Deseja remover o registro?"
              onConfirm={() => remove()}
              okText="Sim"
              cancelText="Não"
            >
              <Button type="primary" danger style={{ marginLeft: 10 }}>
                {settingService.active ? "Inativar" : "Ativar"}
              </Button>
            </Popconfirm>
          ) : null}
          <Button
            type="primary"
            htmlType="submit"
            form="form-setting-service"
            style={{ marginLeft: 10 }}
          >
            Salvar
          </Button>
        </>
      }
    >
      <Spin tip="Loading..." spinning={loading}>
        <Form
          layout="vertical"
          form={form}
          name="form-setting-service"
          onFinish={onFinish}
          validateMessages={validateMessages}
          initialValues={{
            name: settingService.name,
            description: settingService.description,
            id_sector: settingService.id_sector,
            is_monitoring: settingService.is_monitoring,
            is_sms: settingService.is_sms,
            is_photo: settingService.is_photo,
            setting_service_id: settingService.setting_service_id,
            type_service: settingService.type_service,
            company_id: settingService.company_id,
          }}
        >
          <Row gutter={8}>
            <Col lg={8}>
              <Form.Item
                name="name"
                label="Nome"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Digite o nome da configuração do serviço" />
              </Form.Item>
            </Col>
            <Col lg={16}>
              <Form.Item
                name="description"
                label="Descrição"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Digite a descrição da configuração do serviço" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col lg={8}>
              <Form.Item
                name="id_sector"
                label="Setor"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Selecione uma opção"
                  optionFilterProp="children"
                  onChange={(value) => setSectorSelected(value)}
                  onSearch={(value) => setLikeNameSector(value)}
                >
                  {SectorsList.count
                    ? SectorsList.rows.map((sector) => (
                      <Option key={sector.id} value={sector.id}>
                        {sector.name}
                      </Option>
                    ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={16}>
              <Form.Item
                name="setting_service_id"
                label={
                  <Tooltip title="Qual serviço deve ser executado antes desse novo serviço ?">
                    <span>Sequência de Execução</span>
                  </Tooltip>
                }
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Selecione uma opção"
                  optionFilterProp="children"
                  allowClear
                  //onChange={(value) => setSectorSelected(value)}
                  onSearch={(value) => setLikeNameSettingService(value)}
                >
                  {SettingServices.count
                    ? SettingServices.rows.map((setting_service) => {
                      return (
                        <Option
                          key={setting_service.id}
                          value={setting_service.id}
                        >
                          {setting_service.name}
                        </Option>
                      );
                    })
                    : null}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col lg={8}>
              <Form.Item name="type_service" label="Tipo de Serviço">
                <Select
                  showSearch
                  placeholder="Selecione uma opção"
                  optionFilterProp="children"
                >
                  <Option key={0} value=""></Option>
                  <Option key={1} value="REMOCAO">
                    Remoção
                  </Option>
                  <Option key={2} value="PREPARACAO_CORPO">
                    Preparação de corpo
                  </Option>
                  <Option key={3} value="VELORIO">
                    Velorio
                  </Option>
                  <Option key={4} value="CORTEJO">
                    Cortejo
                  </Option>
                  <Option key={5} value="TRANSLADO">
                    Translado
                  </Option>
                  <Option key={6} value="CREMACAO">
                    Cremação
                  </Option>
                  <Option key={7} value="SEPULTAMENTO">
                    Sepultamento
                  </Option>
                  <Option key={8} value="RETIRADA_URNA">
                    Retirada de urna
                  </Option>
                  <Option key={9} value="FLORICULTURA">
                    Floricultura
                  </Option>
                  <Option key={10} value="SERVICO_RELIGIOSO_MUSICO">
                    Serviço Religioso/Músico
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={16}>
              <Form.Item
                name="company_id"
                label="Empresa"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TreeSelect
                  showSearch
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  style={{ width: "100%" }}
                  placeholder="Selecione as empresas"
                  allowClear
                  treeDefaultExpandAll
                >
                  {companies.length !== 0
                    ? companies.rows.map((company) =>
                      company.children.map((children) => (
                        <TreeNode
                          key={children.id}
                          title={children.name}
                          value={children.id}
                        />
                      ))
                    )
                    : null}
                </TreeSelect>
              </Form.Item>
            </Col>
            <Col lg={4} className="switch-bottom">
              <Form.Item name="is_movements_os">
                <Switch
                  checkedChildren="Mov. de OS"
                  unCheckedChildren="Não mov. de OS"
                  defaultChecked={settingService.is_movements_os}
                />
              </Form.Item>
            </Col>
            {/* <Col lg={4} className="switch-bottom">
              <Form.Item name="is_monitoring">
                <Switch
                  checkedChildren="Monitora"
                  unCheckedChildren="Não Monitora"
                  defaultChecked={settingService.is_monitoring}
                />
              </Form.Item>
            </Col>
            <Col lg={4} className="switch-bottom">
              <Form.Item name="is_sms">
                <Switch
                  checkedChildren="Envia SMS"
                  unCheckedChildren="Não Envia SMS"
                  defaultChecked={settingService.is_sms}
                />
              </Form.Item>
            </Col> 
            <Col lg={4} className="switch-bottom">
              <Form.Item
                name="is_photo"
              >
                <Switch
                  checkedChildren="Inclui Fotos"
                  unCheckedChildren="Não Inclui Fotos"
                  defaultChecked={settingService.is_photo}
                />
              </Form.Item>
            </Col> */}
          </Row>
          <Row>
            <Col lg={24}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Produtos" key="1">
                  <Row gutter={8} justify="end">
                    <Col>
                      <Select
                        showSearch
                        style={{ width: 500 }}
                        placeholder="Selecione um Produto"
                        optionFilterProp="children"
                        mode="multiple"
                        onChange={(value) => setProductSelected(value)}
                        onSearch={(value) => setLikeDescriptionProduct(value)}
                      >
                        {productsList &&
                          productsList.rows &&
                          productsList.rows.length !== 0
                          ? productsList.rows.map((product) => {
                            return (
                              <Option key={product.id} value={product.id}>
                                {product.code} - {product.description}
                              </Option>
                            );
                          })
                          : null}
                      </Select>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        ghost
                        icon={<PlusCircleOutlined />}
                        onClick={addProduct}
                        disabled={productSelected ? false : true}
                      >
                        Adicionar
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <Col lg={24}>
                      <TableForm
                        data={products.rows}
                        loading={loadingProduct}
                        page={pageProduct}
                        rowsPerPage={rowsPerPageProduct}
                        rowSelec={false}
                        selectData={null}
                        countPages={
                          products.rows && products.rows.length > 0
                            ? products.rows.length
                            : 0
                        }
                        handleTableChange={(pagination) => {
                          setRowsPerPageProduct(5);
                          return setPageProduct(pagination.current);
                        }}
                        columns={columnsProducts}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Controle de Qualidade" key="2">
                  <Row justify="end">
                    <Col>
                      <Button
                        type="primary"
                        ghost
                        icon={<PlusCircleOutlined />}
                        onClick={() => setOpenModalForm(true)}
                      >
                        Adicionar
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <Col lg={24}>
                      <TableForm
                        data={forms.rows}
                        loading={loadingForm}
                        page={pageForm}
                        rowsPerPage={rowsPerPageForm}
                        rowSelec={false}
                        selectData={null}
                        countPages={
                          forms.rows && forms.rows.length > 0
                            ? forms.rows.length
                            : 0
                        }
                        handleTableChange={(pagination) => {
                          setRowsPerPageForm(2);
                          return setPageForm(pagination.current);
                        }}
                        columns={columnsForms}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Campos" key="3">
                  <Row justify="end">
                    <Col>
                      <Button
                        type="primary"
                        ghost
                        icon={<PlusCircleOutlined />}
                        onClick={() => setOpenModalField(true)}
                      >
                        Adicionar
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <Col lg={24}>
                      <Table
                        size="small"
                        columns={columnsFields}
                        dataSource={sortData(fieldsData.rows)}
                        rowKey={(record) => record.sequence}
                        loading={loadingField}
                        pagination={{
                          current: pageFields,
                          pageSize: rowsPerPagefields,
                          total: fieldsData.count,
                          showSizeChanger: true,
                          onShowSizeChange: function (current, size) {
                            setRowsPerPageFields(size);
                          },
                          pageSizeOptions: [10, 50, 100],
                        }}
                        onChange={(pagination) =>
                          setPageFields(pagination.current)
                        }
                        components={{
                          body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                          },
                        }}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Documentos" key="4">
                  <Row gutter={8} justify="end">
                    <Col>
                      <Select
                        showSearch
                        style={{ width: 500 }}
                        placeholder="Selecione um Documento"
                        optionFilterProp="children"
                        onChange={(value) => setDocumentSelected(value)}
                      >
                        {documentsList.rows && documentsList.rows.length !== 0
                          ? documentsList.rows.map((document) => {
                            return (
                              <Option key={document.id} value={document.id}>
                                {document.Sector.description} -{" "}
                                {document.name}
                              </Option>
                            );
                          })
                          : null}
                      </Select>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        ghost
                        icon={<PlusCircleOutlined />}
                        onClick={addDocument}
                      >
                        Adicionar
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <Col lg={24}>
                      <TableForm
                        data={documents.rows}
                        loading={loadingDocument}
                        page={pageDocument}
                        rowsPerPage={rowsPerPageDocument}
                        rowSelec={false}
                        selectData={null}
                        countPages={
                          documents.rows && documents.rows.length > 0
                            ? documents.rows.length
                            : 0
                        }
                        handleTableChange={(pagination) => {
                          setRowsPerPageDocument(1);
                          return setPageDocument(pagination.current);
                        }}
                        columns={columnsDocuments}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Workflow" key="5">
                  <Email emails={emails} setEmails={setEmails} />
                </TabPane>
                {/* <TabPane tab="Meta" key="5">
                  <Row gutter={8} justify="start">
                    <Col>
                      <Select
                        showSearch
                        style={{ width: 500 }}
                        defaultValue={() => {
                          if (goalSelected && goalSelected.goal) {
                            if (goalSelected.goal_type === "tempo") {
                              return moment(goalSelected.goal).format(
                                "HH:mm:ss"
                              );
                            }

                            return `${goalSelected.goal}%`;
                          }
                        }}
                        optionFilterProp="children"
                        onChange={(value) => setGoalSelected(value)}
                        onSearch={(value) => setLikeGoal(value)}
                      >
                        <Option key={0} value={null}>
                          Nenhuma meta cadastrada nesse serviço
                        </Option>
                        {listGoal && listGoal.length !== 0
                          ? listGoal.map((goal) => {
                              return (
                                <Option key={goal.id} value={goal.id}>
                                  {goal.goal_type}: {goal.goal}
                                </Option>
                              );
                            })
                          : null}
                      </Select>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        ghost
                        icon={<PlusCircleOutlined />}
                        onClick={addGoal}
                      >
                        Adicionar
                      </Button>
                    </Col>
                  </Row>
                </TabPane> */}
              </Tabs>
            </Col>
          </Row>
        </Form>
      </Spin>
      <ModalField
        openModal={openModalField}
        setOpenModal={setOpenModalField}
        fields={fieldsData}
        onFinish={handleModalFieldFinish}
        fieldValues={currentField}
        cleanFields={cleanFields}
        settingServicesID={settingService?.id}
      />
      <ModalForm
        openModal={openModalForm}
        setOpenModal={setOpenModalForm}
        setForms={setForms}
        forms={forms}
      />
    </Main>
  );
};

export default InsertOrUpdateSettingService;
