import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Row,
  Col,
  Typography,
  Space,
  Select,
  Button,
  Checkbox,
  Popconfirm
} from "antd";


import Main from "../../../Layout/Main";
import MenuComponent from "../../../../components/MenuComponent";
import { itensMenu } from "../itens-menu";
import { SelectDepen } from "./components/SelectDepen";
import CardTotal from "../../../../components/CardTotal";


function Calculator(props) {
  const { Title } = Typography;
  const [dependece, setDependece] = useState({
    "FX1": {
      "title": "PET",
      "quantity": 0,
      "amount": 0,
    },
    "FX2": {
      "title": "00 ━ 19",
      "quantity": 0,
      "amount": 0,
    },
    "FX3": {
      "title": "20 ━ 39",
      "quantity": 0,
      "amount": 0, 
    },
    "FX4": {
      "title": "40 ━ 69",
      "quantity": 0,
      "amount": 0,
    },
    "FX5": {
      "title": "70 ━ 79",
      "quantity": 0,
      "amount": 0,
    },
    "FX6": {
      "title": "80 ━ 99+",
      "quantity": 0,
      "amount": 1.87,
    },
  })
  const [company, setCompany] = useState("")
  const [plan, setPlan] = useState("")
  const [total, setTotal] = useState(0)
  const [totalDepen, setTotalDepen] = useState(0)
  const [active, setActive] = useState(false)
  const [declinio, setDeclinio] = useState(false)
  let auxiliar = { ...dependece }

  useEffect(() => {

    if (company != "" && plan != "") {
      Object.keys(prices[company][plan].values).map((key) => {
        let price = declinio ? prices[company][plan].declinio[key] : prices[company][plan].values[key]
        auxiliar[key].amount = price
        setDependece(auxiliar)
      })
      setActive(true)
    }

    calcuTotal();

  }, [company, plan, declinio])

  const calcuTotal = () => {
    let subtotal = 0
    let totaldependentes = 0
    Object.keys(dependece).map((key) => {
      subtotal += dependece[key].quantity * dependece[key].amount
      totaldependentes += dependece[key].quantity
    })

    setTotal(subtotal)
    setTotalDepen(totaldependentes)
  }

  const breadcrumb = [
    {
      title: "Central de Atendimento ao Cliente",
      path: props.modulePath,
    },
    {
      title: "Calculadora de Planos",
      path: props.modulePath + "/calculator",
    },
  ];

  const prices = {
    "Natal": {
      "Essencial1": {
        "default": 29.9,
        "values": {
          "FX1": 5,
          "FX2": 2,
          "FX3": 5.3,
          "FX4": 9.2,
          "FX5": 10.7,
          "FX6": 12.7,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 2,
          "FX3": 5.3,
          "FX4": 9.2,
          "FX5": 10.7,
          "FX6": 12.7,
        }
      },
      "Essencial2": {
        "default": 39.9,
        "values": {
          "FX1": 5,
          "FX2": 2.7,
          "FX3": 6.9,
          "FX4": 11.9,
          "FX5": 13.4,
          "FX6": 16,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 2.3,
          "FX3": 5.87,
          "FX4": 10.12,
          "FX5": 11.39,
          "FX6": 13.60,
        }
      },
      "Essencial3": {
        "default": 44.9,
        "values": {
          "FX1": 5,
          "FX2": 3.1,
          "FX3": 8,
          "FX4": 13.7,
          "FX5": 15.3,
          "FX6": 18.4,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 2.54,
          "FX3": 6.56,
          "FX4": 11.23,
          "FX5": 12.55,
          "FX6": 15.09,
        }
      },
      "Essencial4": {
        "default": 59.9,
        "values": {
          "FX1": 5,
          "FX2": 3.9,
          "FX3": 9.9,
          "FX4": 16.7,
          "FX5": 18.9,
          "FX6": 22.6,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 3.12,
          "FX3": 7.92,
          "FX4": 13.36,
          "FX5": 15.12,
          "FX6": 18.08,
        }
      },
      "Essencial5": {
        "default": 89.9,
        "values": {
          "FX1": 5,
          "FX2": 5.2,
          "FX3": 12.8,
          "FX4": 21.6,
          "FX5": 24.6,
          "FX6": 29.3,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 4.16,
          "FX3": 10.24,
          "FX4": 17.28,
          "FX5": 19.68,
          "FX6": 23.44,
        }
      },
    },
    "Joao Pessoa": {
      "Essencial1": {
        "default": 29.9,
        "values": {
          "FX1": 5,
          "FX2": 2,
          "FX3": 5.3,
          "FX4": 9.2,
          "FX5": 10.7,
          "FX6": 12.7,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 2,
          "FX3": 5.3,
          "FX4": 9.2,
          "FX5": 10.7,
          "FX6": 12.7,
        }
      },
      "Essencial2": {
        "default": 39.9,
        "values": {
          "FX1": 5,
          "FX2": 2.7,
          "FX3": 6.9,
          "FX4": 11.9,
          "FX5": 13.4,
          "FX6": 16,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 2.3,
          "FX3": 5.87,
          "FX4": 10.12,
          "FX5": 11.39,
          "FX6": 13.60,
        }
      },
      "Essencial3": {
        "default": 44.9,
        "values": {
          "FX1": 5,
          "FX2": 3.1,
          "FX3": 8,
          "FX4": 13.7,
          "FX5": 15.3,
          "FX6": 18.4,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 2.54,
          "FX3": 6.56,
          "FX4": 11.23,
          "FX5": 12.55,
          "FX6": 15.09,
        }
      },
      "Essencial4": {
        "default": 59.9,
        "values": {
          "FX1": 5,
          "FX2": 3.9,
          "FX3": 9.9,
          "FX4": 16.7,
          "FX5": 18.9,
          "FX6": 22.6,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 3.12,
          "FX3": 7.92,
          "FX4": 13.36,
          "FX5": 15.12,
          "FX6": 18.08,
        }
      },
      "Essencial5": {
        "default": 89.9,
        "values": {
          "FX1": 5,
          "FX2": 5.2,
          "FX3": 12.8,
          "FX4": 21.6,
          "FX5": 24.6,
          "FX6": 29.3,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 4.16,
          "FX3": 10.24,
          "FX4": 17.28,
          "FX5": 19.68,
          "FX6": 23.44,
        }
      },
    },
    "Guarabira": {
      "Essencial1": {
        "default": 29.9,
        "values": {
          "FX1": 5,
          "FX2": 2,
          "FX3": 5.3,
          "FX4": 9.2,
          "FX5": 10.7,
          "FX6": 12.7,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 2,
          "FX3": 5.3,
          "FX4": 9.2,
          "FX5": 10.7,
          "FX6": 12.7,
        }
      },
      "Essencial2": {
        "default": 39.9,
        "values": {
          "FX1": 5,
          "FX2": 2.7,
          "FX3": 6.9,
          "FX4": 11.9,
          "FX5": 13.4,
          "FX6": 16,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 2.3,
          "FX3": 5.87,
          "FX4": 10.12,
          "FX5": 11.39,
          "FX6": 13.60,
        }
      },
      "Essencial3": {
        "default": 44.9,
        "values": {
          "FX1": 5,
          "FX2": 3.1,
          "FX3": 8,
          "FX4": 13.7,
          "FX5": 15.3,
          "FX6": 18.4,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 2.54,
          "FX3": 6.56,
          "FX4": 11.23,
          "FX5": 12.55,
          "FX6": 15.09,
        }
      },
      "Essencial4": {
        "default": 59.9,
        "values": {
          "FX1": 5,
          "FX2": 3.9,
          "FX3": 9.9,
          "FX4": 16.7,
          "FX5": 18.9,
          "FX6": 22.6,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 3.12,
          "FX3": 7.92,
          "FX4": 13.36,
          "FX5": 15.12,
          "FX6": 18.08,
        }
      },
      "Essencial5": {
        "default": 89.9,
        "values": {
          "FX1": 5,
          "FX2": 5.2,
          "FX3": 12.8,
          "FX4": 21.6,
          "FX5": 24.6,
          "FX6": 29.3,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 4.16,
          "FX3": 10.24,
          "FX4": 17.28,
          "FX5": 19.68,
          "FX6": 23.44,
        }
      },
    },
    "Recife": {
      "Essencial1": {
        "default": 34.5,
        "values": {
          "FX1": 5,
          "FX2": 2.3,
          "FX3": 6,
          "FX4": 10.6,
          "FX5": 12.4,
          "FX6": 14.6,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 2.3,
          "FX3": 6,
          "FX4": 10.6,
          "FX5": 12.4,
          "FX6": 14.6,
        }
      },
      "Essencial2": {
        "default": 46.5,
        "values": {
          "FX1": 5,
          "FX2": 3,
          "FX3": 7.9,
          "FX4": 13.7,
          "FX5": 15.4,
          "FX6": 18.3,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 2.55,
          "FX3": 6.72,
          "FX4": 11.65,
          "FX5": 13.09,
          "FX6": 15.56,
        }
      },
      "Essencial3": {
        "default": 54.5,
        "values": {
          "FX1": 5,
          "FX2": 3.6,
          "FX3": 9.1,
          "FX4": 15.8,
          "FX5": 17.6,
          "FX6": 21.1,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 2.95,
          "FX3": 7.46,
          "FX4": 12.96,
          "FX5": 14.43,
          "FX6": 17.3,
        }
      },
      "Essencial4": {
        "default": 69.9,
        "values": {
          "FX1": 5,
          "FX2": 4.5,
          "FX3": 11.3,
          "FX4": 19.2,
          "FX5": 21.7,
          "FX6": 26,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 3.60,
          "FX3": 9.04,
          "FX4": 15.36,
          "FX5": 17.36,
          "FX6": 20.8,
        }
      },
      "Essencial5": {
        "default": 109.9,
        "values": {
          "FX1": 5,
          "FX2": 5.9,
          "FX3": 14.8,
          "FX4": 24.9,
          "FX5": 28.2,
          "FX6": 33.6,
        },
        "declinio": {
          "FX1": 5,
          "FX2": 4.72,
          "FX3": 11.84,
          "FX4": 19.92,
          "FX5": 22.56,
          "FX6": 26.88,
        }
      },
    },
  }

  const companys = [
    { value: 'Natal', label: 'Natal' },
    { value: 'Joao Pessoa', label: 'João Pessoa' },
    { value: 'Guarabira', label: 'Guarabira' },
    { value: 'Recife', label: 'Recife' },
  ]

  const plans = [
    { value: 'Essencial1', label: 'Essencial 1' },
    { value: 'Essencial2', label: 'Essencial 2' },
    { value: 'Essencial3', label: 'Essencial 3' },
    { value: 'Essencial4', label: 'Essencial 4' },
    { value: 'Essencial5', label: 'Essencial 5' },
  ]

  const totalReturn = () => {
    let complete = 0
    if (company != "" && plan != "") {
      if (total > prices[company][plan].default) {
        return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      } else {
        return prices[company][plan].default.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      }
    } else {
      return complete.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
  }

  const minimoReturn = () => {
    let complete = 0
    if (company != "" && plan != "") {
      return prices[company][plan].default.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    } else {
      return complete.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
  }

  return (
    <>
      <Helmet>
        <title>Calculadora de Planos - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensMenu}
            modulePath={props.modulePath}
            selectedMenu="4"
          />
        }
        breadcrumb={breadcrumb}
      >

        {/* Cards dos valores minimos e final */}
        <Row justify={"center"} style={{ marginBottom: "3rem" }}>
          <Col xs={24} sm={12} align="middle" >
            <Row>
              <Col span={12} style={{ paddingRight: "0.5rem" }}>
                <CardTotal
                  value={minimoReturn()}
                  color={"#003c75"}
                  title={"PREÇO MÍNIMO"}
                  loading={false}
                /></Col>
              <Col span={12} style={{ paddingLeft: "0.5rem" }}>
                <CardTotal
                  value={totalReturn()}
                  color={"#1890ff"}
                  title={"PREÇO FINAL"}
                  loading={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Select de empresas e plano */}
        <Row justify={"center"} style={{ marginBottom: "1rem" }}>
          <Col xs={24} sm={12} md={12}>
            <Row gutter={[16, 16]} justify={"space-between"}>
              <Col xs={24} sm={12}>
                <Title level={5}>Empresa:</Title>
                <Select style={{ width: "100%" }} value={company} options={companys} placeholder={"Selecione a Empresa"} onChange={
                  (value) => {
                    setCompany(value)
                  }
                } />
              </Col>
              <Col xs={24} sm={12}>
                <Title level={5}>Plano:</Title>
                <Select style={{ width: "100%" }} value={plan} options={plans} placeholder={"Selecione a Empresa"} onChange={
                  (value) => {
                    setPlan(value)
                  }
                } />
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Inpult de dependentes */}
        <Row justify={"center"} style={{ marginBottom: "1rem" }}>
          <Col xs={24} sm={12} md={12} lg style={{ borderRadius: 25 }} align="middle" >
            <Title level={4}>{totalDepen} beneficiários adicionados </Title>

            <Space size={10} direction={"vertical"} style={{ width: "100%", borderRadius: 5 }}>
              <Row style={{ justifyContent: "space-between" }}>
                <Col span={12}>
                  <Title style={{ textAlign: "left" }} level={5}>FAIXA ETÁRIA</Title>
                </Col>
                <Col span={12}>
                  <Title style={{ textAlign: "right" }} level={5}>SUBTOTAL</Title>
                </Col>
              </Row>

              {Object.keys(dependece).map((item) => {
                return (
                  <SelectDepen chave={item} dependece={dependece} setDependece={setDependece} calcuTotal={calcuTotal} active={active} />
                )
              })}
            </Space>

          </Col>
        </Row>

        <Row justify={"center"}>
          <Col xs={24} sm={12}>
            <Row justify={"space-between"}>
              <Col>
                <Checkbox checked={declinio} disabled={!active} onChange={(e) => {
                  console.log('checked = ', e.target.checked);
                  setDeclinio(e.target.checked);
                }}>
                  Declinar opção de disposição final
                </Checkbox>
              </Col>
              <Col>
                <Popconfirm
                  title="Deseja reiniciar a composição? Os beneficiários adicionados serão zerados."
                  disabled={!active}
                  onConfirm={() => {
                    setCompany("")
                    setPlan("")
                    setDeclinio(false)
                    setActive(false)
                    setDependece({
                      "FX1": {
                        "title": "PET",
                        "quantity": 0,
                        "amount": 0,
                      },
                      "FX2": {
                        "title": "00 ━ 19",
                        "quantity": 0,
                        "amount": 0,
                      },
                      "FX3": {
                        "title": "20 ━ 39",
                        "quantity": 0,
                        "amount": 0,
                      },
                      "FX4": {
                        "title": "40 ━ 69",
                        "quantity": 0,
                        "amount": 0,
                      },
                      "FX5": {
                        "title": "70 ━ 79",
                        "quantity": 0,
                        "amount": 0,
                      },
                      "FX6": {
                        "title": "80 ━ 99+",
                        "quantity": 0,
                        "amount": 0,
                      },
                    })
                  }}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Button type="primary" disabled={!active} >
                    Reiniciar
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </Col>
        </Row>
      </Main >
    </>
  );
}

export default Calculator;
