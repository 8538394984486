import React from "react";
import { Helmet } from "react-helmet";
import Main from "../../../Layout/Main";
import MenuComponent from "../../../../components/MenuComponent";
import { itensMenu } from "../itens-menu";

function PlanContractApproval(props) {

  const breadcrumb = [
    {
      title: "Comercial",
      path: props.modulePath,
    },
    {
      title: "Aprovação de Contratos de Plano",
      path: props.modulePath + "/plan-contract-approval",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Aprovação de Contratos de Plano - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensMenu}
            modulePath={props.modulePath}
            selectedMenu="1"
          />
        }
        breadcrumb={breadcrumb}
      >
      </Main >
    </>
  );
}

export default PlanContractApproval;
