import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_NOTIFICATION,
  headers: {},
});

api.interceptors.request.use(async (config) => {
  /*
    const token = await getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  */
  return config;
});

export default api;