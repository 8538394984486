import {
  Card,
  Skeleton,
} from 'antd';
import './style.css';

const Amount = (props) => {
  return (
    <Card className='card-amount' style={{ backgroundColor: props.color }}>
      <h3 style={{ color: "white", textAlign: "center", fontSize: "18px" }}>{props.title}</h3>
      {
        (props.loading)
          ?
          <div style={{ textAlign: 'center' }}>
            <Skeleton.Input size={42} style={{ width: 150 }} />
          </div>
          :
          <h4 style={{ color: "white", textAlign: "center", fontSize: "32px" }}>{props.amount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h4>
      }
    </Card>
  );
}

export default Amount;