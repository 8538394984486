export const companiesLocation = [
  {
    code_erp: "03",
    lat: -5.812757,
    long: -35.255127,
  },
  {
    code_erp: "07",
    lat:  -7.11532,
    long: -34.86332,
  },
  {
    code_erp: "05",
    lat: -8.050000,
    long: -34.900000,
  }
];