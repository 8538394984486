import React, { useEffect } from "react";
import { Row, Col, Form, Input, Button, Spin, message, Popconfirm } from "antd";

import api from "../../../../../../utils/api";
import { useMutate } from "../../../../../../utils/useMutate";

const validateMessages = {
  required: "${label} é obrigatório!",
};

const LinkForm = (props) => {
  const [form] = Form.useForm();

  const deleteLinkMutate = (params) => {
    return api.delete(`/links?linkId=${params.id}`);
  };
  const { loading: updateLoading, mutate: updateLink } = useMutate(
    updateLinkMutate,
    {
      onSuccess: () => {
        props.onFinish && props.onFinish();
        message.success("Link atualizado com sucesso!");
      },
    }
  );
  const { loading: createLinkLoading, mutate: createLink } = useMutate(
    createLinkMutate,
    {
      onSuccess: () => {
        props.onFinish && props.onFinish();
        message.success("Link criado com sucesso!");
      },
    }
  );
  const { loading: deleteLinkLoading, mutate: deleteLink } = useMutate(
    deleteLinkMutate,
    {
      onSuccess: () => {
        props.onFinish && props.onFinish();
        message.success("Link deletado com sucesso!");
      },
      onError: () => {
        message.error("Erro ao deletar link!");
      },
    }
  );

  function updateLinkMutate(params) {
    return api.put(`/links?linkId=${props.item.id}`, params);
  }

  function createLinkMutate(params) {
    return api.post(`/links?groupCode=${props.groupCode}`, params);
  }

  useEffect(() => {
    form.resetFields();
  }, [props]);

  const handleSubmit = (values) => {
    if (props.item?.id) {
      updateLink(values);
    } else {
      createLink(values);
    }
  };

  const handleDelete = () => {
    deleteLink(props.item);
  };

  return (
      <Form
        layout="vertical"
        form={form}
        name="form-product"
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        initialValues={{
          title: props.item?.title,
          href: props.item?.href,
        }}
      >
        <Row gutter={8}>
          <Col lg={24}>
            <Form.Item
              name="title"
              label="Título"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Digite o título do link" />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              name="href"
              label="Link"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Digite o endereço do link" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" align="bottom">
          <Col>
            <Button
              loading={updateLoading || createLinkLoading}
              type="primary"
              htmlType="submit"
              style={{
                marginLeft: "8px",
              }}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
  );
};

export default LinkForm;
