import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { removeToken } from "../../utils/localStoreToken";

import { CompanyApi } from "../../services/companies";

import "./styles.css";

import { Layout, Menu, Typography, Avatar, Popover, Select, Badge, TreeSelect } from "antd";

import { EditOutlined, LogoutOutlined, UserOutlined, BellOutlined } from "@ant-design/icons";

import {
  signOut,
  setCurrentCompany,
  setTenantId,
} from "../../store/ducks/signin";

import { openModalSecondary } from "../../store/ducks/modalGlobal";
import { alterUser } from "../../store/ducks/users";

import { useIsMobile } from "../../utils/isMobile";

import { awsApi } from "../../services/aws";

import { SecondaryModalApp } from "../../components/SecondaryModal";
import UpdateUser from "../../components/UpdateUser";
import { useSession } from "../../Context/SessionProvider";
import { Notifications } from "../../components/Notification";
import { useMutate } from "../../utils/useMutate";
import { updateNotificationReceiversMutation } from "../../services/notificationService";
import { useNotifications } from "../../Context/NotificationProvider";

const { Option } = Select;

function Toolbar(props) {
  const { Header } = Layout;
  const { Text } = Typography;
  const [imageUrl, setImageUrl] = useState(null);

  const isMobile = useIsMobile()
  const signin = useSelector((state) => state.signin);
  const {
    session: { user },
  } = useSession();

  const { session, signout} = useSession();
  const { userNotifications, refetchUserNotifications } = useNotifications()
  const [loadingLogout, setLoadingLogout] = useState(false);


  const dispatch = useDispatch();
  const history = useHistory();

  const {mutate: updateNotificationReceivers} = useMutate(updateNotificationReceiversMutation, {
    onSuccess: () => {
      refetchUserNotifications()

    }
  })

  //Estruturação de empresas Mãe para Filhas
  const restructureCompanies = signin.user.userCompanies?.reduce((acc, curr) => {
    const parent = curr.Hierarquia
    const parentId = acc.findIndex((e) => e.id === parent?.id)
    if (parentId === -1) {
      acc.push({
        ...parent, children: [{
          ...curr
        }]
      })
    } else {
      acc[parentId].children.push(curr)
    }
    return acc
  }, [])

  useEffect(() => {
    let isMounted = true;

    if (user.photo) {
      (async () => {
        const url = await awsApi.fetchFile(user.photo);
        if (isMounted) {
        setImageUrl(url);
        }
      })();
    }

    return () => {
      isMounted = false;
    };
  }, [user.photo]);


  async function logout() {
    setLoadingLogout(true);

    await signout();
    dispatch(signOut());
    removeToken();

    setLoadingLogout(false);
    history.push("/signin");
  }

  const content = (
    <Menu mode="vertical" id="userMenu" selectable={false}>
      <Menu.Item
        icon={<EditOutlined />}
        onClick={() => {
          dispatch(
            alterUser({
              ...signin.user,
            })
          );
          dispatch(openModalSecondary(true));
        }}
      >
        Editar perfil
      </Menu.Item>
      <Menu.Item onClick={logout} disabled={loadingLogout}  icon={<LogoutOutlined />}>
        Sair
      </Menu.Item>
    </Menu>
  );

  async function handleSelectCompany(value) {
    try {
      const company = await CompanyApi.get(value);
      dispatch(
        setTenantId(`${company.Hierarquia.code_erp},${company.code_erp}`)
      );

      dispatch(setCurrentCompany(value));
    } catch (error) {
      console.log(error);
    }
  }

  function getSylesBySituation() {
    if (
      (isMobile &&
        !window.location.href.includes("/logistics/appointment/")) ||
      (isMobile && window.location.href.includes("/logistics/appointment/"))
    )
      return "270px";
    else if (
      !isMobile &&
      window.location.href.includes("/logistics/appointment/")
    )
      return "240px";
    else return "400px";
  }

  function handleClickNotification (item) {
    if (!item.reading_date || !item.open_date) {
      const dateNow = new Date().toISOString();
      updateNotificationReceivers([
        { id: item.id, data: { open_date: dateNow, reading_date: dateNow } },
      ]);
    }
  }
  return (
    <Header

      className="site-layout-background toolbar-header"
      style={{
        padding: isMobile ? "10px" : "10px 20px 10px 20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className="containerAmbiente">
        <div>
          <Text strong>{props.moduleName.toUpperCase()}</Text>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <TreeSelect
            defaultValue={signin.user.currentCompany}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            style={{
              width: getSylesBySituation(),
              maxWidth: "100%",
            }}
            bordered={false}
            placeholder="Selecione as empresas"
            onChange={handleSelectCompany}
            treeDefaultExpandAll={true}
          >
            {
              restructureCompanies.length !== 0 ? 
              restructureCompanies.map((company) => (
                <TreeSelect.TreeNode
                  key={company.id}
                  title={company.name}
                  value={company.id}
                  disabled={true}
                >
                  {company.children.length !== 0 ? 
                    company.children.map((children) => (
                      <TreeSelect.TreeNode
                        key={children.id}
                        title={children.name}
                        value={children.id}
                      />
                    ))
                  : null 

                  }
                </TreeSelect.TreeNode>

              ))
              : null
            }
          </TreeSelect>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "1rem",
        }}
        className="adjust-sometimes "
      >
          <Popover 
            trigger="click"
            placement="bottomLeft"
            showArrow={false}
            content={
              <Notifications
              className="notification-popover"
                onClick={handleClickNotification}
                items={userNotifications || []}
              />
            }
          >
              <Badge count={userNotifications?.filter((e) => !e.reading_date).length}>
            <BellOutlined style={{ fontSize: "24px" }} />
        </Badge>
          </Popover>

        <Popover
          placement="bottomRight"
          title={
            <div>
              <span>{session.user.name}</span>
              <p>{session.user.email}</p>
            </div>
          }
          content={content}
          trigger="click"
        >
          <Avatar
            style={{
              verticalAlign: "middle",
              cursor: "pointer",
            }}
            size="default"
            className="avatar-icon"
            src={imageUrl && <img src={imageUrl} 
            alt="avatar"
            style={{ width: 32 }} />}
            icon={<UserOutlined />}
          />
        </Popover>
      </div>
      <SecondaryModalApp title={"Editar usuário"} width={500} footer={null}>
        <UpdateUser />
      </SecondaryModalApp>
    </Header>
  );
}




export default Toolbar;
