import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Router } from "react-router-dom";

/* import "antd/dist/antd.min.css";
import "antd/dist/antd.compact.less";
 */
import 'antd/dist/antd.min.css'

import { store, persistor } from "./store";
import history from "./history";
import App from "./routes";
import { SessionProvider } from "./Context/SessionProvider";
import { NotificationProvider } from "./Context/NotificationProvider";

const SRC = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <SessionProvider>
          <NotificationProvider>
          <Router history={history}>
            <App />
          </Router>
          </NotificationProvider>
        </SessionProvider>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(<SRC />, document.getElementById("root"));
