import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
import { useHistory } from "react-router";
import {
  Row,
  Col,
  Button,
  message,
  Tooltip,
  Drawer,
} from 'antd';
import {
  BarcodeOutlined,
  FullscreenOutlined,
  FormOutlined,
  FilterFilled,
  FilterOutlined,
} from '@ant-design/icons';

import { instanceApi } from '../../../../utils/apiProtheus';
import Main from "../../../Layout/Main";
import MenuComponent from "../../../../components/MenuComponent";
import StatusComponent from "../../../../components/StatusComponent";
import MainCrud from "../../../../components/MainCrud";
import { itensDrawer } from "../itens-menu.js";
import { maskPhone } from "../../../../utils/text";
import { checkControl } from "../../../../utils/access_control";
import View from '../components/View';
import FormFilter from './components/FormFilter';

const ObjectsCarrier = (props) => {

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [objects, setObjects] = useState([]);
  const [showDrawerViewer, setShowDrawerViewer] = useState(false);
  const [objectViewer, setObjectViewer] = useState({});
  const [isFiltered, setIsFiltered] = useState(false);
  const [showDrawerFilter, setShowDrawerFilter] = useState(false);

  const users = useSelector((state) => state.users);
  const signin = useSelector((state) => state.signin);

  const history = useHistory();

  let columns = [
    {
      title: "Negócio",
      dataIndex: "negocio",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return <StatusComponent title={status.title} color={status.color} description={status.description} loading={false} />;
      }
    },
    {
      title: "Código",
      dataIndex: "codigo",
    },
    {
      title: "Cliente",
      render: (object) => object.cliente.cnome,
    },
    {
      title: "Celular",
      render: (object) => object.cliente.ccelular,
    },
    {
      title: "Endereço",
      render: (object) => `${object.cliente.clogradouro} ${object.cliente.cendereco}, ${object.cliente.cnumero}, ${object.cliente.cbairro}, ${object.cliente.cmunicipio}/${object.cliente.cestado}`,
    },
    {
      title: "Ações",
      render: (object) => {
        return (
          <Row justify="space-between">
            <Col xs={12}>
              <Tooltip
                title="Visualizar objeto"
              >
                <Button
                  type="text"
                  icon={<FullscreenOutlined />}
                  onClick={() => {
                    setShowDrawerViewer(true);
                    setObjectViewer(object);
                  }
                  }
                />
              </Tooltip>
            </Col>
            {
              object.status.id === "T" || object.status.id === "D" ?
                <Col xs={12}>
                  <Tooltip
                    title="Receber objeto"
                  >
                    <Button
                      type="text"
                      icon={<FormOutlined />}
                      href={`objects/read-barcode/form-return-object?object=${object.codigo}&cliente=${object.contrato}: ${object.cliente.cnome}`}
                    />
                  </Tooltip>
                </Col>
                :
                <></>
            }
          </Row>
        )
      }
    }

  ];

  const breadcrumb = [
    {
      title: "Faturamento",
      path: props.modulePath
    },
    {
      title: "Objetos",
      path: props.modulePath + "/objects"
    }
  ];

  useEffect(() => {
    getObjects({});
  }, [page, rowsPerPage, users.user]);

  const getObjects = async (values) => {
    try {
      setLoading(true);

      const result = await instanceApi(signin.user.tenantId)
        .get("/all/RESTExpedicao/api/v1/getAll/", {
          params: {
            transportadora: signin.user.carrierId,
            limit: rowsPerPage,
            offset: page,
            ...values,
          }
        });

      setObjects(result.data);
    } catch (error) {
      message.error("Ops! Aconteceu um erro ao carregar os dados.");
    } finally {
      setLoading(false);
    }
  }

  function handleTableChange(pagination) {
    setPage(pagination.current);
  }

  const handlerFilter = async (values = {}) => {

    setIsFiltered(false);
    Object.keys(values).forEach((item) => {
      console.log(`${item}: ${values[item]}`)
      if (values[item] !== "" && values[item] !== undefined) {
        setIsFiltered(true);
      }
    });

    await setPage(1);
    getObjects(values);
  }

  return (
    <>
      <Helmet>
        <title>Objetos</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent itensMenu={itensDrawer} modulePath={props.modulePath} selectedMenu="3" />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <Row gutter={[8, 8]} justify="space-between" style={{ marginTop: 6 }}>
            <Col>
              <Button
                type="primary"
                size="middle"
                icon={<BarcodeOutlined />}
                onClick={() => history.push("/invoicing/objects/read-barcode")}
                style={{
                  ...checkControl(signin.user.profiles, "access_page_read_barcode_objects"),
                  marginLeft: "20px",
                }}
              >
                Ler Código de Barras
              </Button>
            </Col>
            <Col>
              <Tooltip placement="left" title={"Filtrar os objetos na tabela."}>
                <Button
                  type="secondary"
                  icon={
                    isFiltered ? <FilterFilled /> : <FilterOutlined />
                  }
                  onClick={() => setShowDrawerFilter(true)}
                />
              </Tooltip>
            </Col>
          </Row>
        }
      >
        <MainCrud
          columnsTable={columns}
          dataSource={objects.rows}
          loading={loading}
          page={page}
          rowsPerPage={rowsPerPage}
          countPages={objects.count}
          handleTableChange={handleTableChange}
          profiles={signin.user.profiles}
          classTag={[]}
          displayMainCrud="none"
        //handleOpenModal={handleOpenModal}
        />

        <Drawer
          title="Filtrar objetos"
          width="30%"
          onClose={() => setShowDrawerFilter(false)}
          visible={showDrawerFilter}
          getContainer={false}
        >
          <FormFilter
            handlerFilter={handlerFilter}
          />
        </Drawer>

        <Drawer
          title={
            <Row justify="space-between">
              <Col>
                Visualizar objeto
              </Col>
              <Col>
                {
                  !objectViewer ?
                    <></>
                    :
                    <StatusComponent
                      title={objectViewer?.status?.title}
                      color={objectViewer?.status?.color}
                      description={objectViewer?.status?.description}
                      loading={false}
                    />
                }
              </Col>
            </Row>}
          width="40%"
          onClose={() => setShowDrawerViewer(false)}
          visible={showDrawerViewer}
        >
          <View object={objectViewer.codigo} />
        </Drawer>
      </Main>
    </>
  );
}

export default ObjectsCarrier;