import { getRequest, postRequest, deleteRequest } from "../utils/api";

export const submissionQuestion = {
  list: async (limit, offset) =>
    await getRequest(
      `/submission/question?limit=${limit}&offset=${offset}`,
      {}
    ),

  create: async (data) => await postRequest("/submission/question", data),

  delete: async (id) => await deleteRequest(`/submission/question/${id}`, {}),
};
