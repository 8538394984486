export const itensMenu = [
  {
    key: 0,
    permission: "access_menu_home",
    url: "/",
    title: "Início",
    icon: "HomeOutlined",
  },
];

export default itensMenu;
