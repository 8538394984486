import Papa from "papaparse";

import { 
  Col, 
  message, 
  Row,
  Tooltip,
} from "antd";

import {
  QuestionCircleOutlined,
} from "@ant-design/icons";

const ImportReceiver = (props) => {

  const changeHandler = (event) => {
    
    props.setLoading(true);

    try {
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: results => addReceivers(results.data),
      });

      message.success("CSV importado com sucesso!");
      
    } catch (error) {
      message.error('Não foi possível importar o CSV.');
    } finally {
      props.setLoading(false);
    }

  };

  const addReceivers = async (csv) => {
    const data = [];
    await csv.map(receiver => {
      const findReceiver = props.receivers.find(findReceiver => findReceiver.user_code === receiver.user_code)
      
      if (!findReceiver) {
        data.push({
          ...receiver,
          active: eval(receiver.active),
          tokens: receiver.tokens.split(","),
        });
      }
    })
    
    props.setReceivers([...data,...props.receivers])
  }

  return (
    <Row gutter={[8, 8]}>
      <Col xs={24}>
        <label>Importar recebedores:</label>  <a>
          <Tooltip 
            title={
              <>
                <p>O CSV deve possuir um cabeçalho com as seguintes colunas:</p>
                <ul>
                  <li>user_code</li>
                  <li>active (true para ativo, false para inativo)</li>
                  <li>token (A coluna de tokens deve vir separados por virgula)</li>
                </ul>
              </>
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </a>
        <input
          type="file"
          name="file"
          accept=".csv"
          onChange={changeHandler}
        />
      </Col>
    </Row>
  )
}

export default ImportReceiver;