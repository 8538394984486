/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useContext
} from 'react';

import { useSelector } from "react-redux";

import {
  Spin,
  Card,
  Row,
  Col,
  Form,
  Button,
  Select,
  Input,
  InputNumber,
  notification,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import './style.css';

import Amount from '../Amount';

import { getRequest } from '../../../../../../utils/apiProtheus';

import { RemovalAnticipationFormContext } from '../../../../../../Context/removal-anticipation-form-context';
import { useFetch } from '../../../../../../utils/useFetch';
import { useDebounce } from '../../../../../../utils/useDebounce';
import _, { isArray } from 'lodash';


const getProductsQuery = async (params) => {
  const headers = {
    tenantId: params.tenantId,
    "Content-Type": "application/json",
  };

  const result = await getRequest(
    `/all/RESTPRODUTOS/produtosvenda${params.query}`,
    {
      headers,
    }
  );

  if (isArray(result)) {
    return result?.filter((item) => !item.descricao.toUpperCase().match(/UF/));
  }

  return [result]
};

const Product = () => {

  const [searchProduct, setSearchProduct] = useState("");
  const [total, setTotal] = useState(0.0);
  const [calculaTotal, setCalculaTotal] = useState(false);

  const debouceSearchProduct = useDebounce(searchProduct, 500);

  const removalAnticipationFormContext = useContext(RemovalAnticipationFormContext);

  const [removalAnticipationProductForm] = Form.useForm();

  const signin = useSelector((state) => state.signin);

  /**
   * Retorna o tipo de busca a ser feita
   * 
   * @param {string} value 
   * 
   */
  function searchType(value) {

    if (isNaN(Number(value))) {
      return 'desc'
    }
    return 'cod'
  }

  const {
    data: selectProducts,
    refetch,
    loading,
  } = useFetch(getProductsQuery, {
    autoFetch: false,
  });

  useEffect(() => {
      if (signin.user.tenantId) {
          const type = searchType(debouceSearchProduct);

          const query = _({
              [type]: debouceSearchProduct.toUpperCase() || "",
              far: true,
          })
              .pickBy(_.identity)
              .value();

          const queryString = new URLSearchParams(query);

          refetch({
              tenantId: signin.user.tenantId,
              query: `?${queryString}`,
          });
      }
  }, [signin.user.tenantId, debouceSearchProduct]);


  useEffect(() => {
    calculaValorTotal();
  }, [calculaTotal]);

  const calculaValorTotal = () => {
    var amount = 0;

    removalAnticipationProductForm.getFieldsValue().products.map(product => {
      amount += product.amount || 0;
    })

    setTotal(amount);
    setCalculaTotal(false);
  }


  const handleSelectedProducts = (key) => {
    const indexLine = (key) ? key : 0;

    const formProducts = removalAnticipationProductForm.getFieldsValue().products;

    if (formProducts.length === 0) return false;

    const index = selectProducts.findIndex(element => element.codigo === formProducts[indexLine].code)

    if (!formProducts[indexLine].hasOwnProperty('quantity'))
      formProducts[indexLine].quantity = 1;

    formProducts[indexLine].unitaryValue = selectProducts[index].valor;

    formProducts[indexLine].amount = (formProducts[indexLine].quantity * selectProducts[index].valor)
    const discount = formProducts[indexLine].discount || 0

    if (discount > 0 && discount <= 100) {
      formProducts[indexLine].amount = formProducts[indexLine].amount - (formProducts[indexLine].amount * ((formProducts[indexLine].discount || 0) / 100))
    }


    removalAnticipationProductForm.setFieldsValue({
      formProducts
    })

    setCalculaTotal(true);

  }

  const handleReturn = async () => {
    removalAnticipationFormContext.setFormResult({
      ...removalAnticipationFormContext.formResult,
      ...removalAnticipationProductForm.getFieldsValue()
    });

    removalAnticipationFormContext.setCurrent(removalAnticipationFormContext.current - 1)

  }

  const handleSubmit = async (values) => {
    removalAnticipationFormContext.setFormResult({
      ...removalAnticipationFormContext.formResult,
      products: values.products
    });

    removalAnticipationFormContext.setCurrent(removalAnticipationFormContext.current + 1)
  }

  return (
    <>
      <Form
        form={removalAnticipationProductForm}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          products: removalAnticipationFormContext.formResult.products,
        }}
      >
        <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
          <Col xs={24} sm={24} md={16} lg={18}>
            <Spin tip="Carregando..." spinning={loading}>
              <Form.List
                name="products"
                rules={[
                  {
                    validator: async (_, products) => {
                      if (!products || products.length < 1) {
                        notification.error({
                          message: "Adicione ao menos um produto",
                        });
                        return Promise.reject(
                          new Error("Adicione ao menos um produto")
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }) => (
                  <>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add({
                          code: "",
                          quantity: 1,
                          unitaryValue: 0,
                          amount: 0,
                          discount: 0,
                        })}
                        block
                        icon={<PlusOutlined />}
                      >
                        Adicionar
                      </Button>
                    </Form.Item>
                    {fields?.map(({ key, name, fieldKey, ...restField }) => {
                      return (
                        <Card key={fieldKey}>
                          <Row gutter={{ xs: 8, sm: 10, md: 24 }}>
                            <Col xs={24} sm={12} md={8} lg={11}>
                              <Form.Item
                                {...restField}
                                name={[name, "code"]}
                                key={fieldKey}
                                fieldKey={[fieldKey, "code"]}
                                label="Produto"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "O campo Código do Produto é obrigatório!",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  onChange={(value) =>
                                    handleSelectedProducts(name)
                                  }
                                  onSearch={(value) => setSearchProduct(value)}
                                >
                                  {selectProducts?.map((product) => {
                                    return (
                                      <Select.Option
                                        key={product.codigo}
                                        value={product.codigo}
                                      >
                                        {product.codigo} -{" "} {product.descricao}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={3}>
                              <Form.Item
                                {...restField}
                                name={[name, "quantity"]}
                                key={fieldKey}
                                fieldKey={[fieldKey, "quantity"]}
                                label="Quantidade"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "O campo Quantidade é obrigatório!",
                                  },
                                ]}
                              >
                                <InputNumber
                                  className="class-input"
                                  onBlur={() => handleSelectedProducts(name)}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8}  lg={3}>
                              <Form.Item
                                {...restField}
                                name={[name, "unitaryValue"]}
                                key={fieldKey}
                                fieldKey={[fieldKey, "unitaryValue"]}
                                label="Valor UN."
                              >
                                <Input
                                  prefix="R$"
                                  className="class-input"
                                  type="number"
                                  disabled
                                />
                              </Form.Item>
                            </Col>
                            <Col  xs={24} sm={12} md={8} lg={3}>
                              <Form.Item
                              {...restField}
                              name={[name, "discount"]}
                              key={fieldKey}
                              label="Desconto"
                              rules={[{
                                validator: async (_, discount) => {
                                  if (discount > 100) {
                                    return Promise.reject(
                                      new Error("O desconto não pode ser maior que 100%")
                                    );
                                  }

                                  if (discount < 0) {
                                    return Promise.reject(
                                      new Error("O desconto não pode ser menor que 0%")
                                    );
                                  }
                                }
                              }]}
                              normalize={(value) => parseInt(value, 10)}
                            >
                              <Input
                                type='number'
                                prefix="%"
                                className="class-input"
                                onChange={() => handleSelectedProducts(name)}
                                min={0}
                                max={100}
                              />
                            </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8}  lg={4}>
                              <Row gutter={{ xs: 8, sm: 12, md: 24 }}>
                                <Col sm={20}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "amount"]}
                                    key={fieldKey}
                                    fieldKey={[fieldKey, "amount"]}
                                    label="Valor Total"
                                  >
                                    <Input
                                      prefix="R$"
                                      className="class-input"
                                      type="number"
                                      disabled
                                    />
                                  </Form.Item>
                                </Col>
                                <Col className="remove-line" sm={4}>
                                  <DeleteOutlined
                                    onClick={() => {
                                      remove(name);
                                      setCalculaTotal(true);
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      );
                    })}
                  </>
                )}
              </Form.List>
            </Spin>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6}>
            <Amount
              amount={total}
              color="#1890ff"
              title="Total a Pagar:"
              loading={loading}
            />
          </Col>
        </Row>
        <Row style={{ width: "100%", marginTop: "10px" }} justify="end">
          <Col>
            <Button style={{ margin: "0 8px" }} onClick={handleReturn}>
              Voltar
            </Button>
          </Col>
          <Col>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                Avançar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Product;