import { useSelector } from "react-redux";
import Kanban from "../../../../components/Kanban";
import { itensMenu } from "../itens-menu";

const OrderService = (props) => {
  const user = useSelector((state) => state.signin.user);

  return <Kanban channel={`kanban-flower-shop-company=${user.currentCompany}`} itensMenu={itensMenu} profile="FLORICULTURA" {...props} />;
};

export default OrderService;
