export const getFileType = (fileName="") => {
  const ext = fileName.split(".").pop();
  const mimetype = {
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    pdf: "application/pdf",
  }
  return {
    ext: ext,
    mimetype: mimetype[ext]
  };
}