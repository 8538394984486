import React from "react";
import { Button, Col, Result, Row, Skeleton } from "antd";
import styles from "./styles.module.css";
import { InvalidLink } from "./InvalidLink";
import { CreditCard } from "../../../../../components/CreditCard"

export const UpdateCreditCardContent = ({
    pageStaus,
    setPageStatus,
    isValidLink,
    data,
    loading,
    handleSubmit,
    errorMessage,
}) => {
    if (pageStaus === "error")
        return (
            <Result
                status="warning"
                title="Erro ao cadastrar cartão de crédito."
                subTitle={
                    <>
                        {errorMessage &&    
                        <p>{errorMessage}</p>}
                        <p>
                            Em caso de dúvidas ou para mais informações, entre em contato com a nossa{" "}
                            <a href="https://wa.me/5508001234848">
                                Central de Atendimento.
                            </a>
                        </p>
                    </>
                }
                extra={
                    <Button
                        className={styles.button}
                        onClick={() => setPageStatus("progress")}
                        key="console"
                    >
                        Tentar novamente
                    </Button>
                }
            />
        );

    if (pageStaus === "success")
        return (
            <Result
                status="success"
                title="Cartão de crédito cadastrado com sucesso!"
                subTitle="A partir de agora a cobrança será feita de forma automática neste cartão."
            />
        );

    if (!isValidLink) return <InvalidLink message={errorMessage}/>;

    return (
        <>
            <Skeleton active loading={loading}>
                <h1 className={styles.title}>
                    Olá, {data.customer_name}. <br />
                </h1>
                <p className={styles.subtitle}>
                    Cadastre o cartão de crédito de sua preferência para o
                    pagamento recorrente do <span>{data.product_name}</span>{" "}
                    (Contrato de <span>Nº {data.contract}</span>).
                </p>
            </Skeleton>
            <Row justify="center">
                <Col>
                    <CreditCard
                        onSubmit={handleSubmit}
                        title="Informe seus dados de pagamento"
                        footerMessage=""
                        chargeValue={parseFloat(data.installment_value)}
                        loading={loading}
                    />
                </Col>
            </Row>
        </>
    );
};
