import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Row, Col, Collapse, message, Empty, Table, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

import { instanceApi } from "../../utils/apiProtheus";

const { Panel } = Collapse;

const ProtheusSales = (props) => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);

  const signin = useSelector((state) => state.signin);
  useEffect(() => {
    getSales();
  }, []);

  const getSales = async () => {
    try {
      const result = await instanceApi(signin.user.tenantId, true).get(
        `/all/RESTVENDAS/api/v1/vendas?atendimento=${props.atendimento}`
      );
      setSales(result.data);
      setLoading(false)
    } catch (error) {
      console.log(error);
      message.error(error);
    }
  };

  return (
    <Row>
      <Col xs={24}>
        {
          loading ?
          <div style={{ textAlign: 'center'}}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            <h1 style={{ color: 'gray'}}>Carregando...</h1> 
          </div> 
            : <RenderSale sales={sales} />
        }
      </Col>
    </Row>
  );
};

const RenderSale = (props) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const columns = [
    {
      title: "Código",
      dataIndex: "codigo",
      key: "codigo",
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
      key: "descricao",
    },
    {
      title: "Quantidade",
      dataIndex: "quantidade",
      key: "quantidade",
    },
  ];

  return (
    props.sales.length === 0 ? <Empty /> : 
    <Collapse accordion width="100%">
      {props.sales.map((sale) => {
        return (
          <Panel
            header={`Número da venda: ${sale.numero}`}
            key={sale.numero}
            width="100%"
          >
            <Table
              columns={columns}
              dataSource={sale.itensDaVenda}
              pagination={{
                position: ["none"], 
              }}
            />
          </Panel>
        );
      })}
    </Collapse>
  );
};

export default ProtheusSales;
