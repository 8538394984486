import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const GoalService = {
  index: async (limit, offset, company_id, likeGoal = "") => {
    return await getRequest(
      `/goals?limit=${limit}&offset=${offset}&company_id=${company_id}&likeGoal=${likeGoal}`,
      {}
    );
  },

  show: async (id) => {
    const dados = await getRequest(`goal?id=${id}`);
    return dados;
  },

  showForm: async (id) => {
    const dados = await getRequest(`goal/show/form?id=${id}`);
    return dados;
  },

  create: async (body) => {
    const dados = await postRequest("/goal", body);
    return dados;
  },

  update: async (body) => {
    const dados = await putRequest(`/goal?id=${body.id}`, body);
    return dados;
  },

  delete: async (id) => {
    const dados = await deleteRequest(`/goal?id=${id}`, {});
    return dados;
  },
};
