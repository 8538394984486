/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Drawer,
  Form,
  PageHeader,
  Table,
  Tag,
  DatePicker,
  Select,
} from "antd";
import { Helmet } from "react-helmet";
import MenuComponent from "../../../../../../components/MenuComponent";
import Main from "../../../../../Layout/Main";
import { itensDrawer } from "../../../itens-menu";
import {
  CaretDownOutlined,
  CaretRightOutlined,
  FilterOutlined,
  FilterFilled,
} from "@ant-design/icons";
import { getUserLogsQuery } from "../../../../../../services/userLogs";
import { useFetch } from "../../../../../../utils/useFetch";
import { useState } from "react";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { LogsInfo } from "./LosgInfo";
import Papa from "papaparse";

export default function UserLogs(props) {
  const user = useSelector((state) => state.users.user);
  const [filter, setFilter] = useState({
    order: "descent",
    pagination: {
      current: 1,
      pageSize: 10,
    },
    user_id: user.id,
  });
  const [form] = Form.useForm();
  const [openDrawer, setOpenDrawer] = useState(false);

  const {
    data,
    loading: userLogsLoading,
    refetch,
  } = useFetch(getUserLogsQuery, {
    autoFetch: false,
    initialParams: {
      user_id: user.id,
      limit: filter.pagination.pageSize,
      offset: 0,
    },
  });

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Usuários",
      path: props.modulePath + "/users",
    },
    {
      title: "Logs de Usuário",
      path: props.modulePath + "/users/logs",
    },
  ];

  const columns = [
    {
      title: "Data",
      dataIndex: "created_at",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      defaultSortOrder: "descend",
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text) => {
        const date = new Date(text);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      },
      filter,
    },
    {
      title: "Descrição",
      dataIndex: "description",
    },
    {
      title: "Tipo de ação",
      dataIndex: "action",
      render: (text) => {
        return <Tag color="green">{text}</Tag>;
      },
    },
  ];

  useEffect(() => {
    if (filter.order) {
      refetch({
        user_id: user.id,
        limit: filter.pagination.pageSize,
        offset: (filter.pagination.current - 1) * filter.pagination.pageSize,
        order: filter.order === "ascend" ? "ASC" : "DESC",
        ...(filter.action && { action: filter.action }),
        ...(filter.start_date &&
          filter.end_date && {
            start_date: filter.start_date,
            end_date: filter.end_date,
          }),
      });
    }
  }, [filter, user.id]);

  const formatedData = data?.rows?.map(
    ({ address_ip, device, location, ...item }, index) => {
      const details = {
        address_ip,
        device,
        location,
      };
      Object.keys(details).forEach((key) => {
        if (!details[key]) {
          delete details[key];
        }
      });
      return {
        key: index,
        ...item,
        details: isEmpty(details) ? null : details,
      };
    }
  );

  const handleTableChange = (pagination, _, sorter) => {
    setFilter({
      ...filter,
      pagination,
      ...sorter,
    });
  };

  const onFinish = (values) => {
    const [start_date, end_date] = (values.date_range || [])?.map((date) =>
      date.toISOString()
    );
    setFilter({
      ...filter,
      ...values,
      start_date,
      end_date,
    });
    setOpenDrawer(false);
  };

  const handleExport = () => {
    const csv = Papa.unparse(data?.rows);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `logs_de_atividade_webgm_${user.name.replace(" ", "_").toLowerCase()}.csv`
    );
    link.click();
  };

  return (
    <>
      <Helmet>
        <title>Logs de usuário - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="14"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
      >
        <PageHeader
          title="Logs de Usuário"
          tags={<Tag color="blue">{user?.name}</Tag>}
          extra={[
            <Button
              key="1"
              onClick={() => setOpenDrawer(true)}
              icon={
                filter.action || (filter.start_date && filter.end_date) ? (
                  <FilterFilled />
                ) : (
                  <FilterOutlined />
                )
              }
            ></Button>,
            <Button onClick={handleExport} key="2" type="primary">
              Exportar CSV
            </Button>,
          ]}
        >
          <Table
            loading={userLogsLoading}
            pagination={{
              ...filter.pagination,
              total: data?.count,
              showSizeChanger: true,
            }}
            expandable={{
              expandedRowRender: (record) => <LogsInfo {...record.details} />,
              rowExpandable: (record) => record.details !== null,
              expandIcon: ({ expanded, onExpand, record }) =>
                record.details ? (
                  expanded ? (
                    <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
                  )
                ) : null,
            }}
            onChange={handleTableChange}
            columns={columns}
            dataSource={formatedData}
          />
        </PageHeader>
        <Drawer
          onClose={() => setOpenDrawer(false)}
          open={openDrawer}
          title="Filtros"
        >
          <Form
            initialValues={{
              date_range: [],
            }}
            form={form}
            name="filter"
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item name="date_range" label="Data">
              <DatePicker.RangePicker />
            </Form.Item>
            <Form.Item name="action" label="Tipo de ação">
              <Select allowClear>
                <Select.Option value="login">Login</Select.Option>
                <Select.Option value="logout">Logout</Select.Option>
              </Select>
            </Form.Item>
            <Button htmlType="submit" type="primary" block>
              Filtrar
            </Button>
          </Form>
        </Drawer>
      </Main>
    </>
  );
}
