import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Row, Col, Steps, Divider } from "antd";

import { isMobile } from "../../../src/utils/isMobile";

import InformCpf from "./components/InformCpf";
import ContractList from "./components/ContractList";
import Negotiation from "./components/Negotiation";
import Send from "./components/Send";

import { NegotiationContext } from "../../Context/negotiation-context";
import Installments from "./components/Installments";
import api from "../../utils/api";
import { Helmet } from "react-helmet";

const { Step } = Steps;

function DuplicateBillet(props) {
  const [current, setCurrent] = useState(0);
  const [groupFinancial, setGroupFinancial] = useState(false);
  const [data, setData] = useState({
    contracts: [],
    contract: {
      //Dados do contrato
      forma_pagamento: "",
      valor_parcelas: 0,
      valor_encargos: 0,
      valor_desconto: 6,
      valor_total: 0,
    },
    paymentType: [
      {
        value: "pix",
        description: "Pix",
        maxPercent: 50,
      },
      {
        value: "boleto",
        description: "Boleto Bancário",
        maxPercent: 50,
        installments: [1, 2, 3, 4, 5, 6, 7, 8 , 9, 10, 11, 12]
      },
      {
        value: "superlink",
        description: "SuperLink",
        maxPercent: 40,
      },
    ],
  });

  const signin = useSelector((state) => state.signin);

  useEffect(() => {
    veriryUserGroup()
  }, [])

  const veriryUserGroup = async () => {
    try {
      const result = await api.get("/user-groups/veriryUserGroup", {
        params: {
          descriptionGroup: 'Permite desconto de 80% na negociação (Financeiro)',
          userId: signin.user.id,
        }
      });

      if (!result.data)
      throw "Usuário não pertence ao grupo do financeiro.";

      setGroupFinancial(true);
    } catch (error) {
      setGroupFinancial(false);
    }
  }

  const steps = [
    {
      key: 0,
      title: "Informe seu CPF",
      content: <InformCpf activeRecapcha={props.activeRecapcha} />,
    },
    {
      key: 1,
      title: "Escolha o contrato",
      content: <ContractList />,
    },
    {
      key: 2,
      title: "Visualize os boletos em aberto",
      content: <Installments />,
    },
    {
      key: 3,
      title: "Negociação",
      content: <Negotiation />,
    },
    {
      key: 4,
      title: "Enviar",
      content: <Send />,
    },
  ];

  return (
      <>
          <Helmet>
              <title>Negociação - webGM</title>
          </Helmet>
          <NegotiationContext.Provider
              value={{
                  current,
                  setCurrent,
                  data,
                  setData,
                  groupFinancial,
              }}
          >
              <Steps current={current} size="small" direction="horizontal">
                  {isMobile() ? (
                      <Step
                          key={steps[current].key}
                          title={steps[current].title}
                      />
                  ) : (
                      steps.map((item) => (
                          <Step key={item.key} title={item.title} />
                      ))
                  )}
              </Steps>
              <Divider orientation="left" />
              <Row>
                  <Col span="24">{steps[current].content}</Col>
              </Row>
          </NegotiationContext.Provider>
      </>
  );
}

export default DuplicateBillet;
