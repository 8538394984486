import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const ProductsService = {
  getProducts: async (limit, offset, active, company_id, params) => {
    return await getRequest(
      `/products?limit=${limit}&offset=${offset}&active=${active}&company_id=${company_id}${params}`,
      {}
    );
  },
  show: async (id) => {
    const dados = await getRequest(`product?id=${id}`);
    return dados;
  },
  create: async (body) => {
    const dados = await postRequest("/product", body);
    return dados;
  },
  update: async (body) => {
    const dados = await putRequest(`/product?id=${body.id}`, body);
    return dados;
  },
  delete: async (id) => {
    const dados = await deleteRequest(`/product?id=${id}`, {});
    return dados;
  },
};
