import "./styles.css";

import { itensMenu } from "./itens-menu";
import MenuCard from "../../../components/menu_card";

function HomeLogistics(props) {
  props = {
    ...props,
    breadcrumb: [
      {
        title: "Logística",
        path: props.modulePath,
      },
    ],
    itensMenu: itensMenu,
  };
  return <MenuCard {...props}/>;
}

export default HomeLogistics;
