import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const listUserApi = {
  listUser: (limit, offset, active = true, name, email, userapp=false) =>
    getRequest(
      `/users?limit=${limit}&offset=${offset}&active=${active}&name=${name}&email=${email}&userapp=${userapp}`,
      {}
    ),
};

export const listUserByProfileApi = {
  listUserByProfile: (profile, company_id) =>
    getRequest(
      "/users/profile?profile=" + profile + "&company_id=" + company_id,
      {}
    ),
};

export const addUserApi = {
  addUser: (body) => postRequest("/users", body),
};

export const listUserIdApi = {
  listUserId: (id) => getRequest("/users/" + id, {}),
};

export const updateUserApi = {
  updateUser: (body) => putRequest("/users/" + body.id, body),
};

export const deleteUserApi = {
  deleteUser: (id) => deleteRequest("/users/" + id, {}),
};

export const resetPasswordUserApi = {
  resetUser: (id) => putRequest("/reset-password?id=" + id, {}),
};

export const listFilterApi = {
  listFilter: (param) => getRequest(`/users/filter?param=${param}`, {}),
};

export const userServices = {
  getUserGroup: (params) =>
    getRequest(`/groups/users?${new URLSearchParams(params)}`),
  getAllUserGroups: ({ limit, company_id, active }) =>
    getRequest(
      `/user-groups?${new URLSearchParams({ limit, company_id, active })}`
    ),
};