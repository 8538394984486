export const Types = {
  SET_SETTING_SERVICE_BY_SECTOR: "SET_SETTING_SERVICE_BY_SECTOR",
  SET: "SET",
};

const INITIAL_STATE = {
  settingServiceBySector: [],
  settingService: {
    id: 0,
    name: "",
    description: "",
    id_sector: 0,
    is_monitoring: false,
    is_sms: false,
    is_photo: false,
    setting_service_id: 0
  }
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_SETTING_SERVICE_BY_SECTOR:
      return {
        ...state,
        settingServiceBySector: action.payload
      }

    case Types.SET:
      return {
        ...state,
        settingService: action.payload
      }

    default:
      return state;
  }
}

export function setSettingServiceBySector(data) {
  return {
    type: Types.SET_SETTING_SERVICE_BY_SECTOR,
    payload: data,
  };
}

export function setSettingService(data) {
  return {
    type: Types.SET,
    payload: data
  }
}