import { useFetch } from "../../../../../utils/useFetch";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import { useState } from "react";
import { useMutate } from "../../../../../utils/useMutate";
import {
    createProfilePaymentMutation,
    getLinkDataByTokenQuery,
} from "../../../../../services/recurrenceService";
import { UpdateCreditCardContent } from "../components/UpdateCreditCardContent";
import moment from "moment";
import { RecurrenceLayout } from "../components/RecurrenceLayout";


function UpdateCardRecurrence() {
    const { token } = useParams();
    const { mutate: createProfilePayment, loading: loadingSubmit } = useMutate(
        createProfilePaymentMutation,
        {
            onSuccess: () => {
                setPageStatus("success");
            },
            onError: (error) => {
                if (error.code === "customer_id") {
                    setErrorMessage(
                        "O link que você está tentando acessar é inválido"
                    );
                } else if (
                    error.code === "create_profile_payment_error" ||
                    error.code === "missing_params" ||
                    !error.code
                ) {
                    // Erro genérico
                    setErrorMessage(
                        "Ocorreu um erro ao cadastrar o cartão de crédito. Por favor, tente novamente mais tarde."
                    );
                } else {
                    setErrorMessage(error.message);
                }

                setPageStatus("error");
            },
        }
    );

    const [isValidLink, setIsValidLink] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    const { data: linkData, loading: loadingLinkData } = useFetch(
        getLinkDataByTokenQuery,
        {
            initialParams: {
                token,
            },
            onSuccess: (data) => {
                if (!data) setIsValidLink(false);

                const { active, used, expires_at } = data;

                if (!active) {
                    setErrorMessage(
                        "O link que você está tentando acessar não está ativo."
                    );
                    setIsValidLink(false);
                    return;
                }

                if (used) {
                    setErrorMessage(
                        "O link que você está tentando acessar já foi utilizado."
                    );
                    setIsValidLink(false);
                    return;
                }

                if (moment(expires_at).isBefore(moment())) {
                    setErrorMessage(
                        "O link que você está tentando acessar expirou."
                    );
                    setIsValidLink(false);
                    return;
                }

                setIsValidLink(true);
            },
            onError: () => {
                setErrorMessage(
                    "O link que você está tentando acessar é inválido"
                );
                setIsValidLink(false);
            },
        }
    );

    const [pageStaus, setPageStatus] = useState("progress");

    const handleSubmit = (data) => {
        createProfilePayment({
            ...data,
            token: linkData.token,
            customer_id: linkData.recurrence_customer_id,
        });
    };

    return (
        <RecurrenceLayout>
            <Spin spinning={loadingSubmit}>
                <UpdateCreditCardContent
                    data={linkData || {}}
                    isValidLink={isValidLink}
                    loading={loadingLinkData}
                    pageStaus={pageStaus}
                    setPageStatus={setPageStatus}
                    handleSubmit={handleSubmit}
                    errorMessage={errorMessage}
                />
            </Spin>
        </RecurrenceLayout>
    );
}

export default UpdateCardRecurrence;
