import moment from "moment";
import { ChatsList } from "./ChatsList";
import styles from "./contact-details.module.css";
import { useFetch } from "../../../../../utils/useFetch";
import { chatsQuery } from "../../../../../services/huggyBackupService";
import {  notification } from "antd";

/**
 * @typedef {Object} Contact
 * @property {number} id - Primary key for the user migration, not null.
 * @property {string} name - String representing the name of the user, not null.
 * @property {Date} last_seen - Date representing the last seen time of the user, not null.
 * @property {string} status - String representing the status of the user, not null.
 * @property {string} phone - String representing the phone number of the user, not null.
 * @property {?string} cpf - String representing the CPF (Cadastro de Pessoas Físicas) of the user (nullable).
 * @property {boolean} block - Boolean representing if the user is blocked, not null.
 * @property {boolean} org_block - Boolean representing if the user is organizationally blocked, not null.
 * @property {Date} created_at - Date representing creation time, not null.
 * @property {Date} updated_at - Date representing last update time, not null.
 */

/**
 *
 * @param {object} props
 * @param {Contact} props.contact
 * @param {Function} props.onOpenChat
 * @returns
 */
export function ContactDetails({ contact, onOpenChat }) {
    return (
        <div>
            <h2>Detalhes do contato</h2>
            <div className={styles.flexRow}>
                <div className={styles.detailItem}>
                    <div>
                        <span>Nome</span>
                        <p>{contact.name}</p>
                    </div>
                    <div>
                        <span>Telefone</span>
                        <p>{contact.phone}</p>
                    </div>
                    <div>
                        <span>CPF</span>
                        <p>{contact.cpf}</p>
                    </div>
                    <div>
                        <span>Status</span>
                        <p>{contact.status}</p>
                    </div>
                    <div>
                        <span>Última vez visto</span>
                        <p>{moment(contact.last_seen).format("DD/MM/YYYY")}</p>
                    </div>
                    <div>
                        <span>Bloqueado</span>
                        <p>{contact.block ? "Sim" : "Não"}</p>
                    </div>
                    <div>
                        <span>Organização</span>
                        <p>{contact.org_block ? "Bloqueado" : ""}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const ContactView = ({ contact }) => {
    const {data: chats, loading} = useFetch(chatsQuery, {
        onError: (e) => {
            notification.error({message: `Erro ao buscar chats`, description: e.MSG});
        },
        initialParams: {
            contact_id: contact.id,
        },
        initialState: [],
       })


    return (
        <div>
            <ContactDetails contact={contact} />
            <ChatsList chats={chats} loading={loading}/>
        </div>
    );
};

