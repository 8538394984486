import { itemsFunctions } from "../../pages/_modules/administrative/SettingServices/components/InsertOrUpdateSettingService/ModalField/functions_apis";
import { Select } from "antd";
import { sectorsIdURLBinding } from "./constants/moduleURL";
import api from "../../utils/api";

const { Option } = Select;

export const getUsers = async (tag_function) => {
  // var data = [];
  // console.log(tag_function);

  // console.log(tag_function);

  const key = itemsFunctions.find(
    (group) => group.key === Number(tag_function)
  );

  // console.log(key);
  const result = await api.get(`/groups/users`, {
    params: {
      groupId: key.id,
    },
  });

  return result.data.users;
};

/**
 * retorna o id de um módulo pela URL
 * @param {} url
 * @returns
 */
export const getSectorId = (url, sector_id) => {
  //possui um sector_id válido
  if (sector_id != 0 && sector_id != null && sector_id != undefined) {
    return sector_id;
  } else {
    return sectorsIdURLBinding.find((x) => url.includes(x.url)).sector_id;
  }
};
