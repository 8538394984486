const colors = {
  "Pending": { "code": "1", "status": "Pendente", "description": "Indica que o pagamento ainda está sendo processado; OBS: Boleto - Indica que o boleto não teve o status alterado pelo lojista", "color": "#888" },
  "Paid": { "code": "2", "status": "Pago", "description": "Transação capturada e o dinheiro será depositado em conta.", "color": "#43b977" },
  "Denied": { "code": "3", "status": "Negado", "description": "Transação não autorizada pelo responsável do meio de pagamento", "color": "#f21848" },
  "Expired": { "code": "4", "status": "Expirado", "description": "ransação deixa de ser válida para captura", "color": "#888" },
  "Voided": { "code": "5", "status": "Cancelado", "description": "Transação foi cancelada pelo lojista", "color": "#f21848" },
  "NotFinalized": { "code": "6", "status": "Não Finalizado	", "description": "Pagamento esperando Status - Pode indicar erro ou falha de processamento. Entre em contato com o Suporte cielo", "color": "#BD281E" },
  "Authorized": { "code": "7", "status": "Autorizado", "description": "Transação autorizada pelo emissor do cartão. Deve ser capturada para que o dinheiro seja depositado em conta", "color": "#43b977" },
  "Chargeback": { "code": "8", "status": "Chargeback", "description": "Transação cancelada pelo consumidor junto ao emissor do cartão. O Dinheiro não será depositado em conta.", "color": "#f21848" },
}

export default colors;