export const itensMenu = [
  {
    key: 0,
    permission: "access_menu_reports_os",
    function: "getCSV()",
    title: "Exportar Dados do Gestão de Serviços",
    icon: "FileTextOutlined",
  },
];

export const itensDrawer = [
  {
    key: 0,
    permission: "access_menu_home",
    url: "/",
    title: "Início",
    icon: "HomeOutlined",
  },
];