import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Modal } from "antd";
import Swal from "sweetalert2";

import { closeModal } from "../../store/ducks/modalGlobal";
import { alterItem } from "../../store/ducks/formItens";

export const ModalApp = (props) => {
  const modal = useSelector((state) => state.modalGlobal);
  const dispatch = useDispatch();

  useEffect(() => {}, [modal]);

  // remove um item dos store
  const removeItemForm = () => {
    dispatch(alterItem({}));
  };

  const messageClose = () => {
    Swal.fire({
      title: "Oops...",
      text: "Você possui alterações não salvas. Deseja realmente sair sem salvar ?",
      icon: "warning",
      showDenyButton: true,
      focusConfirm: false,
      denyButtonText: "Continuar editando",
      confirmButtonText: "Sair sem salvar",
      confirmButtonColor: "#c9c9c9",
      denyButtonColor: "#40a9ff",
    }).then((result) => {
      if (result.isConfirmed) {
        if (props.cleanStoreComponent) {
          props.cleanStoreComponent({});
        }
        dispatch(closeModal(false));
      }
    });
  };

  return (
    <Modal
      title={props.title}
      centered
      visible={modal.open_modal}
      onOk={() => dispatch(closeModal(false))}
      onCancel={() => {
        // if messageClose, show the question before close modal
        if (props.messageClose) {
          messageClose();
          return false;
        }

        dispatch(closeModal(false));
        if (props.cleanStoreComponent) {
          props.cleanStoreComponent({});
        }

        if (props.close) {
          removeItemForm();
        }
      }}
      width={props.width}
      footer={props.footer}
    >
      {props.children}
    </Modal>
  );
};
