/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

/**
 * Função para fazer requisições HTTP
 * @callback MutateFn 
 * @param {Object} params
 * @returns {Promise}
 */

/**
 * Retorna os dados da requisição
 * @typedef {Object} UseFetchReturn
 * @property {Object} data - Dados retornados da requisição
 * @property {boolean} loading - Indica se a requisição está em andamento
 * @property {Object} error - Erro retornado da requisição
 * @property {function} refetch - Função para refazer a requisição
 * @property {function} toggleAutoRefresh - Função para alternar o auto refresh
 * @property {boolean} autoRefresh - Indica se o auto refresh está ativo
 * @property {function} setData - Função para setar o estado de data
 * @property {function} reset - Função para resetar o estado de data
 */

/**
 * Hook para fazer requisições HTTP.
 * @param {MutateFn}  mutateFn - Função que faz a requisição HTTP
 * @param  options - Opções de configuração 
 * @returns {UseFetchReturn}
 * 
 */
export const useFetch = (mutateFn, { onError, onSuccess, autoFetch = true, initialParams, refreshTime, initialState }={}) => {
  const [data, setData] = useState(initialState ?? null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [paramsState, setParamsState] = useState(initialParams);
  const [firstCall, setFirstCall] = useState(true);
  const defaultRefreshTime = 90000; /* milliseconds */

  function toggleAutoRefresh() {
    setAutoRefresh(!autoRefresh);
  }

  async function fetchData(params) {
    try {
      if (params) setParamsState(params);

      setLoading(true);
      const result = await mutateFn(params || paramsState);
      setData(result);

      if (onSuccess) onSuccess(result);
      setError(null);
    } catch (e) {
      const { response } = await e;
      if (onError) {
        setData(null)
        if (response) {
          setError(response.data);
          onError(response.data);
          
        } else {
          setError(e);
          onError(e);
        }
      }
    } finally {
      if (firstCall)
        setFirstCall(false);

      setLoading(false);
    }
  }
  useEffect(() => {
    if (autoFetch) fetchData(paramsState);

    // Se autoRefresh estiver ativo e não for a primeira chamada, faz a requisição a cada refreshTime
    if (autoRefresh && !firstCall) {
      const interval = setInterval(() => {
        fetchData(paramsState);
      }, refreshTime || defaultRefreshTime);
      return () => clearInterval(interval);
    }
  }, [autoRefresh, firstCall]);

  return {
    data,
    loading,
    error,
    refetch: fetchData,
    toggleAutoRefresh,
    autoRefresh,
    setData,
    reset: () => setData(null),
  };
};
