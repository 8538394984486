import React, { useState } from "react";
import "./styles.css";

import { password } from "../../services/password";
import gMorada from "../../assets/images/gmorada-header.png";
import { Input, Button, Row, Col, Typography, Layout, Alert, Form } from "antd";
import { CloudFilled } from "@ant-design/icons";
import { isMobile } from "../../utils/isMobile";

function ForgotPassword() {
  const { Title } = Typography;
  const { Content } = Layout;
  const [form] = Form.useForm();
  const [formItemDisabled, setFormItemDisabled] = useState({});
  const [alertVisibility, setAlertVisibility] = useState("none");
  const [errorAlertVisibility, setErrorAlertVisibility] = useState("none");
  const [messageError, setMessageError] = useState("");

  const onFinish = (values) => {
    setFormItemDisabled({ disabled: "disabled" });

    password
      .forgotPassword(values)
      .then((res) => {
        setErrorAlertVisibility("none");
        form.resetFields();
        setAlertVisibility("block");
      })
      .catch((error) => {
        console.log(error);
        setFormItemDisabled({});
        setMessageError(
          `Desculpe mas não encontramos nenhum usuário com o e-mail ${values.email}`
        );
        setErrorAlertVisibility("block");
      });
  };

  return (
    <Row align="middle" justify="center" style={{ height: "100vh" }}>
      <Col
        xs={24}
        sm={16}
        md={{span:10, offset: 0}}
        xl={{ span: 8, offset: 0}}
        className={isMobile() ? "login-page-padding-form" : ""}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <CloudFilled style={{ fontSize: "50px" }} />
          <Title level={1} style={{ paddingLeft: "10px" }}>
            webGM
          </Title>
        </div>
        <Content className="form-content login-page-padding-form">
          <Form form={form} name="forgot-password-form" onFinish={onFinish}>
            <Form.Item
              name="email"
              hasFeedback
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Informe o seu e-mail corporativo",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Seu e-mail"
                {...formItemDisabled}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                shape="round"
                block
                {...formItemDisabled}
              >
                Enviar link de recuperação
              </Button>
            </Form.Item>
            <a href="/signin" className="login-page-forgot-password-link">
              Voltar ao login
            </a>
          </Form>

          <Alert
            message="Enviamos um link de recuperação de senha para o seu e-mail!"
            description="Clique no link que enviamos para redefinir sua senha. Se você não recebeu um e-mail, verifique sua pasta de Spam."
            type="success"
            showIcon
            style={{ marginTop: "10px", display: alertVisibility }}
          />

          <Alert
            message="Algo deu errado!"
            description={messageError}
            type="error"
            showIcon
            style={{ marginTop: "10px", display: errorAlertVisibility }}
          />
        </Content>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <a
            href="https://www.moradadapaz.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={gMorada} width={150} />
          </a>
        </div>

        <Row justify="center" className="login-page-footer">
          &copy; {new Date().getFullYear()} TI - Grupo Morada
        </Row>
      </Col>
    </Row>
  );
}

export default ForgotPassword;
