export const itensMenu = [
    {
        key: 0,
        permission: "access_menu_home",
        url: "/",
        title: "Início",
        icon: "HomeOutlined",
    },
    {
        key: 1,
        permission: "access_menu_leads",
        url: "/leads",
        title: "Cadastro de Leads",
        icon: "FileDoneOutlined",
    },
    {
        key: 2,
        permission: "access_attendances",
        url: "/attendances",
        title: "Monitor de Atendimentos",
        icon: "FundViewOutlined",
    },
    {
        key: 3,
        permission: "access_unified_query",
        url: "/unified/query",
        title: "Consulta Unificada",
        icon: "ConsoleSqlOutlined",
    },
    {
        key: 4,
        permission: "access_menu_calculator",
        url: "/calculator",
        title: "Calculadora de Planos",
        icon: "CalculatorOutlined",
    },
    {
        key: 5,
        permission: "access_menu_huggy_backup",
        url: "/huggy-backup",
        title: "Backup Huggy",
        icon: "CloudSyncOutlined",
    },
    {
      key: 6,
      permission: "access_customer_app_list",
      url: "/customer-app",
      title: "Usuários do App Morada",
      icon: "TeamOutlined",
    },
    {
      key: 7,
      permission: "access_offline_search",
      url: "/offline-search",
      title: "Consulta Auxiliar",
      icon: "FileSearchOutlined",
  },
];

export default itensMenu;
