import { Alert, Button, Col, Row } from "antd";
import { useMemo } from "react";

import moment from "moment";

// Verifica de o contrato pode aderir ao pagamento em recorrencia
export function canActivateRecurrence({
  company,
  contractActive,
  installments,
  recurrenceActive,
}) {
  if (company !== "05")
    return false;

  // verifica se o contrato esta inadinplente (parcelas vencidas)
  if (installments.length > 0) {
    const hasDoeInstallments = installments.some((installment) => {
      const dueDate = moment(installment.VENCORIG, "YYYY-MM-DD HH:mm").set({
        hour: 23,
        minute: 59,
        second: 59,
      });

      return dueDate.isBefore(moment());
    });

    if (hasDoeInstallments) {
      return false;
    }

    const validPaymentType = installments.some((installment) => {
      return installment.TIPO === "DP";
    });

    if (!validPaymentType) {
      return false;
    }
  }

  if (!contractActive) {
    return false;
  }

  if (recurrenceActive === true) {
    return false;
  }

  return true;
}

export const FormOfPaymentWarning = ({ contract }) => {
  const allowedRecurrence = useMemo(() => {
    return canActivateRecurrence({
      company: contract.company,
      contractActive: contract.contractActive,
      installments: contract.installments,
      recurrenceActive: contract.recurrenceActive,
      contractType: contract.contractType,
    });
  }, [contract]);

  const renderType = useMemo(() => {
    if (contract.allowedFatdig && allowedRecurrence) {
      return "both";
    }

    if (allowedRecurrence) {
      return "recurrence";
    }

    if (contract.allowedFatdig) {
      return "fatdig";
    }

    return "none";
  }, [contract, allowedRecurrence]);

  if (renderType === "none") {
    return null;
  }

  return (
    <Row className="gutter-row" gutter={12}>
      <Col span={24}>
        <Alert
          className="alert"
          message={
            <p style={{ textAlign: "left" }}>
              {renderType === "both" && (
                <>
                  Agora você tem a oportunidade de aderir ao pagamento
                  totalmente digital e em recorrência! Desfrute da comodidade e
                  rapidez que essa modalidade oferece
                </>
              )}

              {renderType === "fatdig" && (
                <>
                  Sabia que você já pode aderir à{" "}
                  <strong>Fatura Digital</strong> sem custo nenhum? Fale com a
                  gente para saber mais!
                </>
              )}
              {renderType === "recurrence" && (
                <>
                  Sabia que você já pode aderir ao{" "}
                  <strong>Pagamento em Recorrência</strong> sem custo nenhum?
                  Fale com a gente para saber mais!
                </>
              )}
            </p>
          }
          type="warning"
          action={
            <Button
              href="https://wa.me/5508001234848"
              target="_blank"
              rel="noreferrer"
              className="button-send-mail"
            >
              Solicitar agora
            </Button>
          }
        />
      </Col>
    </Row>
  );
};
