import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { message, Tag, Button, Switch, Input, Select } from "antd";
import { Helmet } from "react-helmet";
import {
  PlusOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import Main from "../../../Layout/Main";
import MainCrud from "../../../../components/MainCrud";

import { setSettingService } from "../../../../store/ducks/settingServices";

import { SettingServicesService } from "../../../../services/settingService";
import { SectorsService } from "../../../../services/sectors";

import { checkControl } from "../../../../utils/access_control";
import MenuComponent from "../../../../components/MenuComponent";
import { itensDrawer } from "../itens-menu";

const { Option } = Select;

const SettingServices = (props) => {
  const [settingServices, setSettingServices] = useState([]);
  //const [settingService, setSettingService] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [valueInputSearch, setValueInputSearch] = useState("");
  const [sectores, setSectores] = useState([]);

  const signin = useSelector((state) => state.signin);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!checked) {
      list(true);
    } else {
      list(false);
    }
  }, [page, rowsPerPage, signin.user.currentCompany]);

  useEffect(() => {
    setPage(1);
  }, [checked]);

  const list = (active) => {
    setLoading(true);

    const offset = page !== 1 ? rowsPerPage * (page - 1) : 0;
    SettingServicesService.getSettingServices(
      rowsPerPage,
      offset,
      active,
      signin.user.currentCompany
    )
      .then((response) => {
        setSettingServices(response);
        listSectores();
      })
      .catch((error) => message.error("Erro ao carregar dados!"))
      .finally(() => setLoading(false));
  };

  const breadcrumb = [
    {
      title: "Configurador",
      path: props.modulePath,
    },
    {
      title: "Configuração de Serviços",
      path: props.modulePath + "/service-management/setting-services",
    },
  ];

  async function handleTableChange(pagination) {
    setPage(pagination.current);
  }

  async function listSectores() {
    SectorsService.getSectors(10, 0, true)
      .then((response) => setSectores(response))
      .catch(() => message.error("Erro ao carregar os setores!"));
  }

  // filtrar por título
  const getColumnSearchApi = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
      <div className="divSearch">
        <Input
          style={{ marginBottom: 8 }}
          placeholder="Pesquisar..."
          value={valueInputSearch}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setValueInputSearch(e.target.value ? [e.target.value] : "");
          }}
        />

        <div>
          <Button
            type="primary"
            onClick={() => handleSearchApi(selectedKeys)}
            icon={<SearchOutlined />}
            className="buttonSearch"
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              setValueInputSearch("");
              list(true);
            }}
            icon={<DeleteOutlined />}
            className="buttonSearch"
          >
            Remover Filtro
          </Button>
        </div>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  // filtra por setor
  const getColumnSectorSearchApi = () => ({
    filterDropdown: () => (
      <div className="divSearch">
        <Select style={{ width: 120 }} onChange={handleChange}>
          <Option value="">Sem filtro</Option>
          {sectores && sectores.rows && sectores.rows.length !== 0
            ? sectores.rows.map((sector) => (
              <Option value={sector.id}>{sector.name}</Option>
            ))
            : null}
        </Select>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  function handleChange(value) {
    typeof value === "string" ? list(true) : handleSearchApi(value);
  }

  const handleSearchApi = (value) => {
    setLoading(true);

    if (typeof value[0] === "string")
      value = `&likeNameSettingService=${value}`;

    if (typeof value === "number") value = `&id_sector=${value}`;

    SettingServicesService.getSettingServices(
      10,
      0,
      true,
      signin.user.currentCompany,
      value
    )
      .then((response) => {
        setSettingServices(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error("Desculpe não foi possível realizar o filtro.");
      });
  };

  const activeSwitch = (checked) => {
    if (checked) {
      list(false);
      setChecked(true);
      message.info("Exibindo configuraçôes de serviços inativas!");
    } else {
      list(true);
      setChecked(false);
      message.info("Exibindo configuraçôes de serviços ativas!");
    }
  };

  const handleInsertOrUpdate = (values) => {
    console.log(values)
    dispatch(setSettingService(values));
    history.push("/" + props.modulePath + "/setting-services/insert-or-update");
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      ...getColumnSearchApi(),
    },
    {
      title: "Descrição",
      dataIndex: "description",
    },
    {
      title: "Setor",
      dataIndex: ["Sector", "name"],
      ...getColumnSectorSearchApi(),
    },
    {
      title: "Situação",
      dataIndex: "active",
      render: (active) => {
        let color = "";
        if (active === true) {
          color = "green";
        } else {
          color = "volcano";
        }
        return <Tag color={color}>{active ? "Ativo" : "Inativo"}</Tag>;
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Configuração de Serviços - webGM</title>
      </Helmet>
      <Main
        moduleName={props.moduleName}
        moduleMenu={
          <MenuComponent
            itensMenu={itensDrawer}
            modulePath={props.modulePath}
            selectedMenu="10"
          />
        }
        breadcrumb={breadcrumb}
        displayMenuButtons={"flex"}
        buttonHeader={
          <>
            <span
              style={checkControl(
                signin.user.profiles,
                "setting_services_inactivate"
              )}
            >
              <Switch
                onChange={activeSwitch}
                checkedChildren="Inativos"
                unCheckedChildren="Ativos"
                style={{ marginRight: "5px", marginTop: "5px" }}
              />
            </span>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              onClick={handleInsertOrUpdate}
              style={{
                ...checkControl(
                  signin.user.profiles,
                  "save_and_update_setting_services"
                ),
                marginLeft: "20px",
              }}
            >
              Novo
            </Button>
          </>
        }
      >
        <MainCrud
          columnsTable={columns}
          dataSource={settingServices.rows}
          handleOpenModal={handleInsertOrUpdate}
          loading={loading}
          page={page}
          rowsPerPage={rowsPerPage}
          countPages={settingServices.count}
          handleTableChange={handleTableChange}
          activeSwitch={activeSwitch}
          displayMainCrud="none"
          classTag={[]}
        />
      </Main>
    </>
  );
};

export default SettingServices;
