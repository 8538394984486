import "./styles.css";
import { Helmet } from "react-helmet";
import { itensMenu, itensDrawer } from "./itens-menu";
import MenuCard from "../../../components/menu_card";

function HomeAdministrative(props) {

  props = {
    ...props,
    breadcrumb: [
      {
        title: "Configurador",
        path: props.modulePath,
      },
    ],
    itensMenu: itensMenu,
    itensDrawer: itensDrawer,
  };

  return (
    <>
      <Helmet>
        <title>Configurador - webGM</title>
      </Helmet>
      <MenuCard {...props} />
    </>
  );
}

export default HomeAdministrative;
