export const staticProtheusData = {
  salesSource: [
    {
      id: '300001',
      description: 'Indicação de Amigos / Familiares'
    },
    {
      id: '300002',
      description: 'Internet (Sites ou Redes Sociais)'
    },
    {
      id: '300003',
      description: 'Propaganda (TV, Radio, Jornais)'
    },
    {
      id: '300004',
      description: 'Consultora de Vendas/Vendedora'
    },
    {
      id: '300005',
      description: 'Já era Cliente'
    },

  ],
  professionalOccupation: [
    {
      id: 1,
      description: 'Empresário'
    },
    {
      id: 2,
      description: 'Profissional Liberal'
    },
    {
      id: 3,
      description: 'Comerciante'
    },
    {
      id: 4,
      description: 'Funcionário Público'
    },
    {
      id: 5,
      description: 'Autônomo'
    },
    {
      id: 6,
      description: 'Aposentado/Pensionista'
    },
    {
      id: 7,
      description: 'Assalariado'
    },
    {
      id: 8,
      description: 'Outros'
    },
  ],
  gender: [
    {
      id: "1",
      description: 'Feminino'
    },
    {
      id: "2",
      description: 'Masculino'
    },
    {
      id: "3",
      description: 'Não se aplica'
    }
  ],
  maritalStatus: [
    {
      id: "S",
      description: 'Solteiro(A)'
    },
    {
      id: "C",
      description: 'Casado(A)'
    },
    {
      id: "V",
      description: 'Viúvo(A)'
    },
    {
      id: "M",
      description: 'União Estável'
    },
    {
      id: "D",
      description: 'Divorciado(a)'
    },
    {
      id: "Q",
      description: 'Desquitado(a)'
    },
    {
      id: 5,
      description: 'Outros'
    }
  ],
  formPaymentEntry: [
    {
      id: "R$ ",
      description: 'Dinheiro'
    },
    {
      id: "CH",
      description: 'Cheque'
    },
    {
      id: "CD",
      description: 'Cartão de Débito'
    },
    {
      id: "CC",
      description: 'Cartão de Crédito'
    },
    {
      id: "TF",
      description: 'Deposito/Transferência'
    }
  ],
  formPayment: [
    {
      id: "R$",
      description: 'Dinheiro'
    },
    {
      id: "CH",
      description: 'Cheque'
    },
    {
      id: "CD",
      description: 'Cartão de Débito'
    },
    {
      id: "CC",
      description: 'Cartão de Crédito'
    },
    {
      id: "TF",
      description: 'Deposito/Transferência'
    }
  ],
  attendanceType: [
    {
      id: "000001",
      description: 'PARTICULAR'
    },
    {
      id: "000002",
      description: 'CONVÊNIO'
    },
    {
      id: "000003",
      description: 'FINANCEIRA/SUGUROS'
    },
    {
      id: "000004",
      description: 'PLANO SEMPRE'
    },
    {
      id: "000006",
      description: 'FUNERAL USO FUTURO'
    },
    {
      id: "000007",
      description: 'PLANO OSSARIO SIMPLES'
    },
    {
      id: "000008",
      description: 'PLANO PREVER'
    }
    // {
    //   id: "900001",
    //   description: 'PARTICULAR'
    // },
    // {
    //   id: "900002",
    //   description: 'SEGURADORA'
    // },
    // {
    //   id: "900003",
    //   description: 'FUNERAL USO FUTURO'
    // },
    // {
    //   id: "900004",
    //   description: 'PLANO'
    // }
  ],
  salesChannel: [
    {
      id: "100007",
      description: 'PAP'
    },
    {
      id: "100008",
      description: 'CALL CENTER'
    },
    {
      id: "100009",
      description: 'BALCAO'
    },
    {
      id: "100010",
      description: 'STAND DE VENDAS(PDV PANTAO V)'
    },
    {
      id: "100011",
      description: 'CONSULTORES EXTERNOS'
    },
    {
      id: "100012",
      description: 'REPRESENTANTES'
    },
    {
      id: "100013",
      description: 'INTERNET/E-COMMERCE'
    },
    {
      id: "100014",
      description: 'AGENDAMENTO'
    },
    {
      id: "100099",
      description: 'OUTROS'
    }
  ],
  religion: [
    {
      id: "BUDIST",
      description: 'Budista'
    },
    {
      id: "CATOLI",
      description: 'Católico'
    },
    {
      id: "ESPIRI",
      description: 'Espírita'
    },
    {
      id: "EVANGE",
      description: 'Evangelico'
    },
    {
      id: "JUDAIC",
      description: 'Judaica'
    },
    {
      id: "LUTERA",
      description: 'Luterano'
    },
    {
      id: "MUCULM",
      description: 'Muculmano'
    },
    {
      id: "SEM",
      description: 'Sem religião'
    },
    {
      id: "TJEAOVA",
      description: 'Testemunha de Jeová',
    },
    {
      id: "UMBAND",
      description: 'Umbanda'
    },
    {
      id: "OUTRA",
      description: 'Outra'
    }
  ],
  kinShip: [
    { id: "CONJ", description: "Cônjuge" },
    { id: "MAE", description: "Mãe" },
    { id: "PAI", description: "Pai" },
    { id: "PADRA", description: "Padrasto ou Madrasta" },
    { id: "FILHO", description: "Filho" },
    { id: "FILHA", description: "Filha" },
    { id: "ENTEA", description: "Enteado(a)" },
    { id: "AMIGA", description: "Amiga(o)" },
    { id: "AVO", description: "Avô ou Avó" },
    { id: "NETO", description: "Neto(a)" },
    { id: "BISNE", description: "Bisneto(a)" },
    { id: "IRMAO", description: "Irmã(o)" },
    { id: "OUTRO", description: "Outro" }
  ],
  attendanceModality: [
    {
      id: "1",
      description: "Digital",

    },
    {
      id: "2",
      description: "Presencial",
    }
  ],
  causeOfDeath: [
    {
      id: "001",
      description: "CANCER"
    },
    {
      id: "002",
      description: "D.CARDIACA"
    },
    {
      id: "003",
      description: "D.APARELHO RESPIRATORIO"
    },
    {
      id: "004",
      description: "SEPTICEMIA"
    },
    {
      id: "005",
      description: "ACIDENTE AUTOMOBILISTICO"
    },
    {
      id: "006",
      description: "AVC"
    },
    {
      id: "007",
      description: "AIDS"
    },
    {
      id: "008",
      description: "D.RENAIS"
    },
    {
      id: "009",
      description: "AFOGAMENTO"
    },
    {
      id: "010",
      description: "ANEURISMA CEREBRAL"
    },
    {
      id: "011",
      description: "ARTERIOSCLEROSE"
    },
    {
      id: "012",
      description: "SUICIDIO"
    },
    {
      id: "013",
      description: "HOMICIDIO"
    },
    {
      id: "014",
      description: "D.HEPATICAS"
    },
    {
      id: "015",
      description: "ECLAMPSIA"
    },
    {
      id: "016",
      description: "D.GASTRICAS"
    },
    {
      id: "017",
      description: "LEUCEMIA"
    },
    {
      id: "018",
      description: "MENEGITE"
    },
    {
      id: "019",
      description: "CHOQUE-ELETRICO"
    },
    {
      id: "020",
      description: "OUTROS"
    },
    {
      id: "021",
      description: "NAO ESPECIFICADA"
    },
    {
      id: "022",
      description: "A ESCLARECER DEP DE EXAMES COMP."
    },
    {
      id: "023",
      description: "COVID-19"
    },
    {
      id: "024",
      description: "INSUFICIENCIA RESPIRATORIA"
    }
  ]
}