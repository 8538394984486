import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from 'lodash';
import {
  Row,
  Col,
  Tooltip,
  Button,
  Drawer,
  Dropdown,
  Menu,
  message,
  Spin,
  Checkbox,
} from "antd";

import {
  FilterFilled,
  FilterOutlined,
  PrinterOutlined,
  FormOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";

import Main from "../../../Layout/Main";
import MenuComponent from "../../../../components/MenuComponent";
import MainCrud from "../../../../components/MainCrud";
import StatusComponent from "../../../../components/StatusComponent";

import { instanceApi } from "../../../../utils/apiProtheus";

import { itensDrawer } from "../itens-menu";
import FormFilter from "./components/FormFilter";
import View from "../components/View";

const Objects = (props) => {
  const [loading, setLoading] = useState(false);
  const [objects, setObjects] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [showDrawerFilter, setShowDrawerFilter] = useState(false);
  const [showDrawerViewer, setShowDrawerViewer] = useState(false);
  const [tasksParams, setTasksParams] = useState({});
  const [objectViewer, setObjectViewer] = useState({});
  const [isFiltered, setIsFiltered] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [objectsSelected, setObjectsSelecteds] = useState([]);
  const [filters, setFilters] = useState({});

  const signin = useSelector((state) => state.signin);

  const breadcrumb = [
    {
      title: "Faturamento",
      path: props.modulePath,
    },
    {
      title: "Objetos",
      path: props.modulePath + "/objects",
    },
  ];

  const handlerShowSizeChange = (current, size) => {
    setRowsPerPage(size)
  }


  let columns = [
    {
      title: <Checkbox
        checked={checked}
        onChange={
          async () => {

            const newObjects = await _.map(objects.rows, item => {
              item.selected = !checked;

              return item;
            });

            setObjects({ rows: newObjects, count: objects.count });
            setChecked(!checked)
          }
        }
      />,
      width: "30px",
      render: (object) => {
        return (
          <Checkbox            
            defaultChecked={object.selected}
            onChange={(e) => handleSelectItem(e, object)}
          />
        );
      },
    },
    {
      title: "Negócio",
      dataIndex: "negocio",
    },
    {
      title: "Código",
      dataIndex: "codigo",
    },
    {
      title: "Cliente",
      render: (object) => object.cliente.cnome,
    },
    {
      title: "Celular",
      render: (object) => object.cliente.ccelular,
    },
    {
      title: "Endereço",
      render: (object) => `${object.cliente.clogradouro} ${object.cliente.cendereco}, ${object.cliente.cnumero}, ${object.cliente.cbairro}, ${object.cliente.cmunicipio}/${object.cliente.cestado}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return <StatusComponent title={status.title} color={status.color} description={status.description} loading={false} />;
      }
    },
    {
      title: "Ações",
      render: (object) => {
        return (
          <Row justify="space-between">
            <Col xs={12}>
              <Tooltip
                title="Visualizar objeto"
              >
                <Button
                  type="text"
                  icon={<FullscreenOutlined />}
                  onClick={() => {
                    setShowDrawerViewer(true);
                    setObjectViewer(object);
                  }
                  }
                />
              </Tooltip>
            </Col>
            {
              object.status.id === "T" || object.status.id === "D" ?
                <Col xs={12}>
                  <Tooltip
                    title="Receber objeto"
                  >
                    <Button
                      type="text"
                      icon={<FormOutlined />}
                      href={`objects/read-barcode/form-return-object?object=${object.codigo}&cliente=${object.contrato}: ${object.cliente.cnome}`}
                    />
                  </Tooltip>
                </Col>
                :
                <></>
            }
          </Row>
        )
      }
    }
  ];

  const handleSelectItem = async (e, object) => {
    object.selected = e.target.checked;

    setObjectPrint(object)

    const newRows = await _.map(objects.rows, item => {
      if (item.codigo === object.codigo) {
        item.selected = object.selected;
      }
      return item;
    });

    setObjects({ ...objects, rows: newRows });
  }

  const setObjectPrint = (object) => {
    const index = objectsSelected.findIndex(x => x.codigo === object.codigo);
    // Caso o objeto não esteja selecionado
    if (index < 0) {
      setObjectsSelecteds([...objectsSelected, object]);
    } else {
      // Criar um novo array sem o item a ser retirado da seleção
      const newSelecteds = objectsSelected.filter((item) => item.codigo !== object.codigo);
      setObjectsSelecteds(newSelecteds);
    }
  };

  useEffect(() => {
    getObjects();
  }, []);

  useEffect(() => {
    getObjects()
  }, [rowsPerPage, page, signin.user]);

  useEffect(() => {
    getObjects();
  }, [filters]);

  const handlerReport = async (type) => {
    setLoadingReport(true);
    try {

      if (!checked && !objectsSelected.length)
        return message.info("É necessário efetuar o filtro dos registros que deseja emitir o relatório.")

      if (checked && !Object.keys(filters).length)
        return message.info("É necessário efetuar o filtro dos registros que deseja emitir o relatório.")

      const data = {
        type,
        email: signin.user.email,
        printAll: checked,
        filters,
        rows: checked ? [] : objectsSelected
      }

      // console.log({data})
      
      const result = await instanceApi(signin.user.tenantId)
        .post("/all/RESTExpedicao/api/v1/postRelatorios", data);

      message.success("Em processamento, ao finalizar os arquivos serão gerados dentro da pasta do financeiro.");
    } catch (error) {
      message.error("Não foi possível emitir o relatório.");
    } finally {
      setLoadingReport(false);
    }
  }

  const getObjects = async () => {
    setLoading(true);
    try {

      const result = await instanceApi(signin.user.tenantId)
        .get("/all/RESTExpedicao/api/v1/getAll", {
          params: {
            limit: rowsPerPage,
            offset: page,
            ...filters,
          },
        });

      const newObjects = _.map(result.data.rows, object => {

        const filter = _.find(objectsSelected, item => item.codigo === object.codigo);

        if (filter) {
          object.selected = true;
        }

        return object;
      });

      setShowDrawerFilter(false);
      setObjects({ ...result.data, rows: newObjects });
      //setObjects(result.data);
    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  }

  function handleTableChange(pagination) {
    setPage(pagination.current);
  }

  const handlerFilter = async (values = {}) => {

    setIsFiltered(false);
    Object.keys(values).forEach((item) => {
      if (values[item] !== "" && values[item] !== undefined) {
        setIsFiltered(true);
      }
    });

    setPage(1);
    setFilters(values);

    //getObjects();
  }

  return (
    <Main
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          itensMenu={itensDrawer}
          modulePath={props.modulePath}
          selectedMenu="2"
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"flex"}
      buttonHeader={
        <Row gutter={[8, 8]} justify="space-between">
          <Col>
            <Tooltip placement="left" title={"Filtrar os objetos na tabela."}>
              <Button
                type="secondary"
                icon={
                  isFiltered ? <FilterFilled /> : <FilterOutlined />
                }
                onClick={() => setShowDrawerFilter(true)}
              />
            </Tooltip>
          </Col>
        </Row>
      }
    >
      <Spin tip="Gerando relatório..." spinning={loadingReport}>

        <MainCrud
          columnsTable={columns}
          dataSource={objects.rows?.map((item, index) => {
            return {
              ...item,
              key: index,
            };
          })}
          loading={loading}
          page={page}
          rowsPerPage={rowsPerPage}
          countPages={objects.count}
          handleTableChange= {handleTableChange}
          handlerShowSizeChange = {handlerShowSizeChange}
          profiles={signin.user.profiles}
          classTag={[]}
          displayMainCrud="none"
        />

        <Drawer
          title="Filtrar objetos"
          width="30%"
          onClose={() => setShowDrawerFilter(false)}
          visible={showDrawerFilter}
          getContainer={false}
        >
          <FormFilter
            handlerFilter={handlerFilter}
          />
        </Drawer>
        <Drawer
          title={
            <Row justify="space-between">
              <Col>
                Visualizar objeto
              </Col>
              <Col>
                {
                  !objectViewer ?
                    <></>
                    :
                    <StatusComponent
                      title={objectViewer?.status?.title}
                      color={objectViewer?.status?.color}
                      description={objectViewer?.status?.description}
                      loading={false}
                    />
                }
              </Col>
            </Row>}
          width="40%"
          onClose={() => setShowDrawerViewer(false)}
          visible={showDrawerViewer}
        >
          <View object={objectViewer.codigo} />
        </Drawer>
      </Spin>
    </Main>
  );
}

export default Objects;