import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

export const GenerateCSVLink = (props) => {
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (
      props.data.headers &&
      props.data.headers.length !== 0 &&
      props.data.data &&
      props.data.data.length !== 0
    ) {
      setHeaders(props.data.headers);
      setData(props.data.data);
    }
  }, [props.data]);

  return (
    <CSVLink headers={headers} data={data} filename={props.filename}>
      {props.name}
    </CSVLink>
  );
}
