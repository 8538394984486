import React from "react";
import "./styles.css";

import Main from "../../Layout/Main";
import MenuComponent from "../../../components/MenuComponent";
import itensMenu from "./itens-menu";

function HomeThanatopraxist(props) {
  const breadcrumb = [
    {
      title: "Tanatopraxista",
      path: props.modulePath,
    },
  ];

  return (
    <Main
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent modulePath={props.modulePath} itensMenu={itensMenu} />
      }
      breadcrumb={breadcrumb}
    >
      <h1>Home Tanatopraxista</h1>
    </Main>
  );
}

export default HomeThanatopraxist;
