import React from "react";
import "./styles.css";
import DynamicIcon from "../../utils/dynamicIcon";

import { Card } from "antd";
import { isMobile } from "../../utils/isMobile";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setCurrentModule } from "../../store/ducks/signin";
import { modalReports } from "../../store/ducks/modalGlobal";

function Cards(props, modulePath) {
  const history = useHistory();
  const dispatch = useDispatch();
  if (props.title) {
    props.name = props.title;
  }

  function handleModule(module) {
    dispatch(
      setCurrentModule({ module: module.name, id_sector: module.id_sector })
    );
  }

  const getFunctions = (fun) => {
    if (fun === "getCSV()") {
      dispatch(modalReports(true));
    }
  };

  return (
    <Card
      style={{
        width: isMobile() ? "38vw" : "10rem",
        height: isMobile() ? "38vw" : "10rem",
      }}
      className="cardButton"
      onClick={() => {
        if (props.path) {
          handleModule(props);
          history.push(`${props.path}`);
          handleModule(props);
        } else if (props.url === "/") {
          history.push(`${props.url}`);
        } else if (props.function) {
          getFunctions(props.function);
        } else {
          history.push(`/${modulePath + props.url}`);
        }
      }}
    >
      <div
        className="menu-widgets-text"
        style={{
          marginTop: "0.5rem",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <DynamicIcon name={props.icon} style={{ fontSize: 28 }} />
        <br />
        <span>{props.name}</span>
      </div>
    </Card>
  );
}

export default Cards;
