import React, { useContext } from "react";

import { Col, Layout, Row } from "antd";

import Contracts from "../Contracts";
import TableSmall from "../TableSmall";

import { columnsAttendance, columnsSepul, buttons } from "../../mocks";

import { UnifiedQueryContext } from "../../../../../../Context/unifiedQueryContext";

const { Content } = Layout;

function Home() {
  const context = useContext(UnifiedQueryContext);

  return (
    <>
      <Content className="content-unified-query">
        <Contracts />
        <Col
          xs={24}
          sm={8}
          md={8}
          lg={8}
          className="content-contract-unified-query-margin-left"
        >
          <Row>
            <TableSmall
              title="Atendimentos Funerários"
              columnsTable={columnsAttendance}
              descriptionEmpty="Nenhum atendimento funerário encontrado!"
              table="attendance"
              current={
                buttons.find((value) => value.key === "attendance").current
              }
            />
          </Row>
          <br />
          <Row>
            <TableSmall
              title="Sepultados"
              descriptionEmpty="Nenhum sepultado encontrado!"
              columnsTable={columnsSepul}
              table="sepul"
              current={buttons.find((value) => value.key === "sepul").current}
            />
          </Row>
        </Col>
      </Content>
    </>
  );
}

export default Home;
