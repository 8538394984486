export const Types = {
  ADD: "ADD",
  UPDATE: "UPDATE",
  REMOVE: "REMOVE",
  GET: "GET",
  SET_ALTERNATIVE: "SET_ALTERNATIVE",
};

const INITIAL_STATE = {
  alternativeList: [],
  alternativeListCount: 0,
  alternative: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET:
      return {
        ...state,
        alternativeList: action.payload.rows,
        alternativeListCount: action.payload.count,
      };

    case Types.ADD:
      return {
        ...state,
        alternativeList: action.payload.rows,
        alternativeListCount: action.payload.count,
      };

    case Types.UPDATE:
      return {
        ...state,
        alternativeList: action.payload.rows,
        alternativeListCount: action.payload.count,
      };

    case Types.REMOVE:
      return {
        ...state,
        alternativeList: action.payload.rows,
        alternativeListCount: action.payload.count,
      };

    case Types.SET_ALTERNATIVE:
      return {
        ...state,
        item: {
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export function listAlternative(data) {
  return {
    type: Types.GET,
    payload: data,
  };
}

export function addAlternative(data) {
  return {
    type: Types.ADD,
    payload: data,
  };
}

export function alterAlternative(data) {
  return {
    type: Types.SET_ALTERNATIVE,
    payload: data,
  };
}

export function updateAlternative(data) {
  return {
    type: Types.UPDATE,
    payload: data,
  };
}

export function deleteAlternative(data) {
  return {
    type: Types.REMOVE,
    payload: data,
  };
}
