import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Main from "../../../Layout/Main";
import { showFormApi } from "../../../../services/forms";
import loadingSVG from "../../../../assets/images/loading.svg";
import { message, Button, Row, Col, Table, Tag, Popconfirm } from "antd";
import { alterFormComponent } from "../../../../store/ducks/formComponent";
import { openModal } from "../../../../store/ducks/modalGlobal";
import { ModalApp } from "../../../../components/Modal";
import FormComponent from "../../../../components/FormComponent";
import moment from "moment";
import { submission } from "../../../../services/submissionForm";
import MenuComponent from "../../../../components/MenuComponent";
import itensMenu from "../../thanatopraxist/itens-menu";

function OccurrenceRecord(props) {
  const signin = useSelector((state) => state.signin);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState(0);
  const [checklist, setChecklist] = useState({});
  const [readonly, setReadonly] = useState(false);

  useEffect(() => {
    getChecklist();
  }, [signin.user.currentCompany]);

  const titleForm = "Registro de Ocorrências - Tanatopraxia PB";

  const breadcrumb = [
    {
      title: "Tanatopraxia",
      path: props.modulePath,
    },
    {
      title: "Passagem de plantão",
      path: props.modulePath + "/shift-change",
    },
  ];

  // busca o checklist de passagem de plantão
  const getChecklist = async () => {
    setLoading(true);

    try {
      const response = await showFormApi.showFormTitle(
        titleForm,
        signin.user.currentCompany
      );

      setChecklist(response.rows);
      setDataTable(response.response);
    } catch (e) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  // atualizar a tabela de resposta do formulário informando a confirmação de recebimento do formulário
  const confirmForm = (data) => {
    setLoading(true);
    submission
      .update(data.id, {
        is_confirm_response: true,
        confirm_date: moment(),
      })
      .then(() => message.success("Formulário confirmado com sucesso!"))
      .catch(() => message.error("Erro ao confirmar o formulário"))
      .finally(() => getChecklist());
  };

  const columns = [
    {
      title: "Respondido por",
      dataIndex: ["User", "name"],
    },
    {
      title: "Data da resposta",
      dataIndex: "createdAt",
      align: "center",
      render: (data) => moment(data).format("DD/MM/YYYY"),
    },
    {
      title: "Confirmado",
      dataIndex: "is_confirm_response",
      align: "center",
      render: (active) => {
        let color = "";
        if (active === true) {
          color = "green";
        } else {
          color = "volcano";
        }
        return <Tag color={color}>{active ? "Sim" : "Não"}</Tag>;
      },
    },
    {
      title: "Data da confirmação",
      dataIndex: "confirm_date",
      align: "center",
      render: (data) => (data ? moment(data).format("DD/MM/YYYY") : null),
    },
    {
      title: "Ações",
      align: "center",
      render: (data) => (
        <div>
          <Button
            type="primary"
            shape="round"
            disabled={data.is_confirm_response ? true : false}
            onClick={() => mountedForm(data)}
          >
            Visualizar formulário
          </Button>
          <Popconfirm
            title="Deseja confirmar o recebimento do formulário ?"
            onConfirm={() => confirmForm(data)}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              style={{ marginLeft: 5 }}
              shape="round"
              disabled={data.is_confirm_response ? true : false}
            >
              Confirmar formulário
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  // busca os dados para montar o formulário respondido
  const mountedForm = (value) => {
    setLoading(true);
    setReadonly(true);

    showFormApi
      .showFormSubmission(value.form_id, value.id)
      .then((response) => {
        /* 
          aqui como não é uma OS, não tem um setting service associado
          estou criando um objeto para que o componente de formulário monte 
          as respostas mesmo sem um setting service 
        */
        dispatch(
          alterFormComponent({
            ...response,
            submission: response,
            responseForms: {
              ServiceForms: [
                {
                  id_form: response.id,
                  Form: response,
                },
              ],
            },
          })
        );
        dispatch(openModal(true));
      })
      .catch(() =>
        message.error(
          "Oops... não foi possível validar as respostas do formulário!"
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <Main
      moduleName={props.moduleName}
      moduleMenu={
        <MenuComponent
          modulePath={props.modulePath}
          selectedMenu="0"
          itensMenu={itensMenu}
        />
      }
      breadcrumb={breadcrumb}
      displayMenuButtons={"none"}
    >
      {loading ? (
        <>
          <div className="loading-centar">
            <img src={loadingSVG} alt="loading" height={80} />
          </div>
          <h2 className="text-loading">Carregando...</h2>
        </>
      ) : (
        <>
          <Row>
            <Col>
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  setReadonly(false);
                  dispatch(alterFormComponent(checklist));
                  dispatch(openModal(true));
                }}
              >
                Preencher formulário de passagem de plantão
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <Table columns={columns} dataSource={dataTable} size="small" />
            </Col>
          </Row>
          <ModalApp
            title="Registro de Ocorrências - Tanatopraxia"
            width={1000}
            footer={null}
          >
            <FormComponent readonly={readonly} titleForm={titleForm} />
          </ModalApp>
        </>
      )}
    </Main>
  );
}

export default OccurrenceRecord;
