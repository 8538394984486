import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../utils/api";

export const listFormApi = {
  listForm: (limit, offset, active = true, company_id, param = "") =>
    getRequest(
      `/form?limit=${limit}&offset=${offset}&active=${active}&company_id=${company_id}${param}`,
      {}
    ),
};

export const addFormApi = {
  addForm: (body) => postRequest("/form", body),
  sendMailForm: (body) => postRequest("/form/send/mail/occurrence", body),
};

export const updateFormApi = {
  updateForm: (body) => putRequest("/form/" + body.id, body),
};

export const deleteFormApi = {
  deleteForm: (id) => deleteRequest("/form/" + id, {}),
};

export const showFormApi = {
  showForm: (id) => getRequest("/form/" + id, {}),

  showFormService: (id, services_id) =>
    getRequest(`/form/service/${id}/${services_id}`, {}),

  showFormTitle: (title, company_id) =>
    getRequest(`/form/title/${title}/${company_id}`, {}),

  showFormSubmission: (id, submission_id) =>
    getRequest(`/form/submission/${id}/${submission_id}`, {}),
};
