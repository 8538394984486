import React, { useState, useEffect } from "react";
import "./style.css";
import { Tooltip, Avatar, Row, Col, Tag } from "antd";
import { StopOutlined, HourglassOutlined, RightOutlined  } from "@ant-design/icons";

import { Initials } from "../../utils/text";
import { awsApi } from "../../services/aws";
import moment from "moment";
import { checkLate } from "../../utils/checkLate";
import { Justify } from "react-bootstrap-icons";

function Task(props) {

  const { isLate, timeLate } = checkLate(props.settingService.name, props.order.BaseService.unlocked_date, props.start, props.is_blocked_attendance, props.order.general_status_id, props.id_sector, props.blocked, props.product.description)

  const [avatar, setAvatar] = useState("");
  useEffect(() => {
    if (props.user && props.user.photo) {
      getImages(props.user.photo);
    }
  }, []);

  /**
   * Busca a foto do usuário
   */
  const getImages = async (photo) => {
    try {
      const resultAvatar = await awsApi.fetchFile(photo);
      setAvatar(resultAvatar);
    } catch (error) {
      setAvatar("");
    }
  };


  const StatusTaskAttendance = ({ status }) => {
    if (status === "L") {
      return (
        <Tooltip
          placement="top"
          title={`Liberado pelo atendimento às ${moment(
            props.order.BaseService.unlocked_date
          ).format("HH:mm")} de ${moment(
            props.order.BaseService.unlocked_date
          ).format("DD/MM/YYYY")}`}
        >
          <div className="is-not-blocked">
            <div style={{ width: "15px", height: "15px", backgroundColor: "green", borderRadius: "25px" }} />
            {/* <Tag color="green">Liberado</Tag> */}
          </div>
        </Tooltip>
      );
    } else if (status === "P") {
      return (
        <div className="is-blocked">
          <div style={{ width: "15px", height: "15px", backgroundColor: "red", borderRadius: "25px" }} />
          {/* <Tag color="red">Pendente</Tag> */}
        </div>
      );
    } else {
      return null;
    }
  };



  return (
    <div className="task-paper" onClick={() => props.clickTask()}>
      <Col>
        {/* Parte superior */}
        <Row style={{ padding: "0px 10px ", justifyContent: "space-between", marginTop: "10px" }}>
          <div className="task-title">{`${props.id} - ${props.name}`}</div>
        </Row>
        {/* parte do meio */}
        <Row style={{ padding: "10px 10px 0px 10px " }}>
          <Tag>
            {`${props.settingService.name.substring(0, 21)}`} <RightOutlined  /> {`${props.product.description.substring(0, 15)}`}
          </Tag>
        </Row>
        {/* parte de baixo */}
        <Row style={{ padding: "15px 10px 10px 10px ", justifyContent: "space-between" }}>
          <div>
            <Row>


              {props.settingService.name.toUpperCase().trim().includes("REMO") ? (
              <Col style={{ paddingTop: "5px", paddingRight : "8px"}}>
                <StatusTaskAttendance status={props.is_blocked_attendance} />
              </Col>
            ) : null}
              {/* props.blocked */}
              {props.blocked ? (
                <Col style={{ paddingTop: "2px", paddingRight: "5px" }}>
                  <Tooltip
                    placement="topLeft"
                    title={
                      <p>
                        Esse serviço está bloqueado até que o serviço de &nbsp;
                        <strong>
                          {props.settingService.hierarquia &&
                            props.settingService.hierarquia.name
                            ? props.settingService.hierarquia.name
                            : ""}
                        </strong>{" "}
                        &nbsp; seja executado.
                      </p>
                    }
                  >
                    <StopOutlined style={{ fontSize: "15px", color: "red" }} />
                  </Tooltip>
                </Col>
              ) : null}

                {/* isLate */}
              {isLate && (
                <Col style={{paddingTop: "2px" }}>
                  <Tooltip
                    placement="topLeft"
                    title={
                      <p>
                        OS atrasada, {timeLate} em aberto!
                      </p>
                    }
                  >
                    <HourglassOutlined style={{ fontSize: "16px", color: "blue" }} />
                  </Tooltip>
                </Col>
              )}
            </Row>
          </div>
          <div>
            {props.user && (
              <div className="footer-user">
                <Tooltip
                  placement="top"
                  title={props.user ? props.user.name : ""}
                >
                  <Avatar
                    style={{
                      verticalAlign: "middle",
                      cursor: "pointer",
                    }}
                    size="small"
                    gap="4"
                    className="spacingBetween"
                    src={avatar}
                  >
                    {Initials(props.user.name)}
                  </Avatar>
                </Tooltip>
              </div>
            )}
          </div>
        </Row >
      </Col >
    </div >
  );
}

export default Task;
