/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import { useEffect, useState } from "react";
import { getConsultantProposalsQuery } from "../../../../../services/protheusService";
import { useFetch } from "../../../../../utils/useFetch";
import { useMutate } from "../../../../../utils/useMutate";
import { useSession } from "../../../../../Context/SessionProvider";
import { createPaymentPixMutation } from "../../../../../services/paymentPix";
import { MaskedInput } from "antd-mask-input";
import { useMemo } from "react";
import { useCompany } from "..";

const expirationDates = [
  { label: "4 horas", value: 4 },
  { label: "12 horas", value: 12 },
  { label: "24 horas", value: 24 },
  { label: "48 horas", value: 48 },
];

export const CreateModal = ({ open, setOpen, onCreateSuccess }) => {
  const { session } = useSession();
  const [sendEmail, setSendEmail] = useState(false);
  const [form] = Form.useForm();
  const consultantCPF = Form.useWatch("cpfConsultant", form);
  const consultantCodigo = Form.useWatch("codigoConsultor", form);
  const [searchType, setSearchType] = useState("cpf");


  const { subsidiary_erp, company_erp } = useCompany()

  const {
    data: consultantProposals,
    error: errorConsultantProposals,
    refetch: getConsultantProposals,
    setData: setConsultantsProposal,
    loading: loadingConsultantProposals,
  } = useFetch(getConsultantProposalsQuery, {
    autoFetch: false,
    onError: (e) => {
      notification.error({
        message: "Erro ao buscar propostas do consultor",
        description: e.msg,
      });
    },
  });

  const { mutate: createPaymentPix, loading } = useMutate(
    createPaymentPixMutation,
    {
      onSuccess: (data) => {
        onCreateSuccess(data);
        notification.success({
          message: "PIX criado com sucesso",
        });

        form.resetFields();
        setSendEmail(false);
        setOpen(false);
      },
      onError: (e) => {
        notification.error({
          message: "Erro ao criar PIX",
          description: e.msg || e.message,
        });
      },
    }
  );

  const proposalHelpMessage = useMemo(() => {
    if (errorConsultantProposals || !consultantProposals) {
      return "";
    }
    if (loadingConsultantProposals) {
      return "Buscando propostas do consultor";
    }
    if (!consultantProposals?.propostas?.length) {
      return "Consultor não possui propostas sem PIX vinculado";
    }
    return "Selecione a proposta";
  }, [
    errorConsultantProposals,
    consultantProposals,
    loadingConsultantProposals,
  ]);

  const consultantHelpMessage = useMemo(() => {
    const consultantName =
      consultantProposals?.vendedor || errorConsultantProposals?.vendedor;
    if (consultantName) {
      return `Consultor: ${consultantProposals?.vendedor}`;
    }
    if (errorConsultantProposals) {
      return errorConsultantProposals.msg || "";
    }
  }, [consultantProposals, errorConsultantProposals]);

  function handleSubmit(values) {
    const proposal = consultantProposals.propostas.find(
      (proposal) => proposal.PROPOSTA === values.proposal
    );
    const payload = {
      ...values,
      cpfConsultant: consultantProposals?.cpf,
      userId: session.user.id,
      nameConsultant: consultantProposals?.vendedor,
      typeProposal: proposal?.TIPO_PROPOSTA,
      businessProposal: proposal?.NEGOCIO,
    };
    createPaymentPix({
      params: payload,
      headers: {
        tenantId: `${company_erp},${subsidiary_erp}`,
      },
    });
  }

  useEffect(() => {
    if (
      (consultantCPF && consultantCPF.length === 11) ||
      (consultantCodigo && consultantCodigo.length >= 6)
    )
      getConsultantProposals({
        params: {
          valor: consultantCPF || consultantCodigo,
          tipo: searchType,
        },
        headers: {
          tenantId: `${company_erp},${subsidiary_erp}`,
        },
      });
  }, [consultantCPF, consultantCodigo]);

  return (
    <Modal
      open={open}
      onCancel={() => {
        setConsultantsProposal(null);
        form.resetFields();
        setOpen(false);
        setSendEmail(false);
      }}
      title="Gerar PIX"
      footer={null}
      destroyOnClose={true}
      centered
    >
      <Spin tip="Gerando PIX, por favor aguarde." spinning={loading}>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item help={consultantHelpMessage} label="Consultor(a)" required>
            <Input.Group compact>
              <Select
                onChange={(value) => {
                  setSearchType(value);
                  setConsultantsProposal(null);
                }}
                value={searchType}
                style={{ width: "90px" }}
                defaultValue="cpf"
              >
                <Select.Option value="cpf">CPF</Select.Option>
                <Select.Option value="codigo">Código</Select.Option>
              </Select>
              {searchType === "cpf" ? (
                <Form.Item
                  style={{ marginBottom: "0px" }}
                  className="form-item-cpf"
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório",
                    },
                    {
                      message: "CPF inválido",
                      validateTrigger: "onSubmit",
                      validator: (_, value) => {
                        if (value?.length === 11) {
                          return Promise.resolve();
                        }
                        return Promise.reject("CPF inválido");
                      },
                    },
                  ]}
                  name="cpfConsultant"
                  normalize={(value) => {
                    return value.replace(/\D/g, "");
                  }}
                >
                  <MaskedInput
                    name="cpfConsultant"
                    mask={"000.000.000-00"}
                    onChange={(e) => {
                      setConsultantsProposal([]);
                      form.resetFields(["proposal"]);
                    }}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  style={{ marginBottom: "0px" }}
                  className="form-item-codigo"
                  name="codigoConsultor"
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      setConsultantsProposal([]);
                      form.resetFields(["proposal"]);
                    }}
                  />
                </Form.Item>
              )}
            </Input.Group>
          </Form.Item>

          <Form.Item
            help={proposalHelpMessage}
            label="Proposta:"
            rules={[
              {
                required: true,
                message: "Campo obrigatório",
              },
            ]}
            name="proposal"
          >
            <Select
              disabled={consultantProposals?.vendedor ? false : true}
              placeholder={
                loadingConsultantProposals ? "Buscando propostas..." : ""
              }
              loading={loadingConsultantProposals}
              notFoundContent={
                loadingConsultantProposals ? null : (
                  <Empty description="Nenhum registro encontrado" />
                )
              }
            >
              {consultantProposals?.propostas?.map((proposal) => (
                <Select.Option
                  key={proposal.PROPOSTA}
                  value={proposal.PROPOSTA}
                >
                  {proposal.PROPOSTA} - {proposal.TIPO_PROPOSTA} -{" "}
                  {proposal.NEGOCIO}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Row gutter={16}>
            <Col span={24} md={12}>
              <Form.Item
                name="value"
                label="Valor:"
                rules={[
                  {
                    required: true,
                    type: "number",
                    validator: (_, value) => {
                      if (value && value > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("O valor deve ser maior que 0")
                      );
                    },
                  },
                ]}
              >
                <InputNumber
                  step={"0.01"}
                  decimalSeparator=","
                  style={{ width: "100%" }}
                  addonBefore={"R$"}
                  autoCorrect="off"
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="expiration"
                label="Expira em:"
                rules={[{ required: true, message: "Campo obrigatório" }]}
              >
                <Select options={expirationDates} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Observação" name="obs">
            <Input.TextArea rows={3} />
          </Form.Item>
          <Row style={{ marginBottom: "1.5rem" }} gutter={12}>
            <Col>
              <Checkbox
                defaultChecked={false}
                onChange={(e) => setSendEmail(e.target.checked)}
              />
            </Col>
            <Col>
              <span>Enviar e-mail para cliente.</span>
            </Col>
          </Row>
          {sendEmail && (
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message:
                      "Campo obrigatório, caso não queira enviar o e-mail, desmarque a opção acima",
                  },
                ]}
                label="E-mail do(a) cliente:"
                name="email"
              >
                <Input type="email" placeholder="E-mail" />
              </Form.Item>
            </Col>
          )}

          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Gerar PIX
            </Button>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};
