import { useState, useEffect } from "react";

import { Row, Col, Typography, Input, Select, Button, message } from "antd";

import api from "../../../../../../utils/apiNotification";

const FormFilter = (props) => {
  const [title, setTitle] = useState("");
  const [applicationCode, setApplicationCode] = useState("");
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    getApplications();
  }, []);

  const getApplications = async () => {
    try {
      const result = await api.get("/applications");

      setApplications(result.data.rows);
    } catch (error) {
      message.error("Não foi possível buscar as aplicações cadastradas!");
    }
  };

  return (
    <Row>
      <Col xs={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Typography.Text>Título:</Typography.Text>
            <Input
              allowClear
              placeholder="Informe o título"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Typography.Text>Aplicação:</Typography.Text>
            <Select
              showSearch
              allowClear
              placeholder="Selecione uma aplicação"
              optionFilterProp="children"
              value={applicationCode}
              onChange={(value) => setApplicationCode(value)}
              style={{ width: "100%" }}
            >
              {applications.map((option) => (
                <Select.Option key={option.code} value={option.code}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: "16px" }} justify="end">
          <Col>
            <Button
              type="primary"
              onClick={() =>
                props.handlerFilter({
                  title,
                  application_code: applicationCode,
                })
              }
            >
              Filtrar
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FormFilter;
