import React from "react";
import "./styles.css";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { itensMenu } from "./itens-menu";

import MenuCard from "../../../components/menu_card";

function Negotiation(props) {
  const signin = useSelector((state) => state.signin);
  const history = useHistory();

  props = {
    ...props,
    breadcrumb: [
      {
        title: "Financeiro",
        path: props.modulePath,
      },
    ],
    itensMenu: itensMenu,
  };

  return <MenuCard {...props} />;
}

export default Negotiation;
