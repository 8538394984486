import { getRequest, postRequest } from "../utils/api";

export const appointmentsApi = {
  appointmentsGet: async (id_status, id_service, id_setting_service) =>
    await getRequest(
      `/appointments?id_status=${id_status}&id_service=${id_service}&id_setting_service=${id_setting_service}`,
      {}
    ),
  appointmentsPost: async (body) => await postRequest(`/appointments`, body),
  getAppointmentsStatus: async (base_service_id, id_sector) =>
    await getRequest(
      `/appointments/status?base_service_id=${base_service_id}&id_sector=${id_sector}`,
      {}
    ),
  appointmentsChangeOtherOSs: async (body) =>
    await postRequest(`/appointments/change/oss`, body),

  getAppointmentsStatusOS: async (id_service) =>
    await getRequest(
      `/appointments/status/service?id_service=${id_service}`,
      {}
    ),

  getAppointmentsChecklist: async (
    services_id,
    sector_id,
    setting_service_id
  ) =>
    await getRequest(
      `/appointments/checklist?services_id=${services_id}&sector_id=${sector_id}&setting_service_id=${setting_service_id}`,
      {}
    ),
};
