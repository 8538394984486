import { Button, Space } from "antd";
import { Telephone } from "react-bootstrap-icons";
import { WhatsAppOutlined } from "@ant-design/icons";

export const InvalidLink = ({ title = "Link inválido", message }) => {
    return (
        <Space direction="vertical" size={56}>
            <div>
                <h1>{title}</h1>
                <p>
                   {message}
                </p>
                <p>
                    Caso tenha alguma dúvida, entre em contato com a nossa
                    central de atendimento.
                </p>
            </div>
            <div>
                <h3 style={{ marginBottom: 15 }}>
                    Central de Atendimento 24h
                    <br />
                    <big>
                        <big>&nbsp;&nbsp;&nbsp;0800 123 4848</big>
                    </big>
                </h3>
                <Button
                    href="tel:08001234848"
                    className="button-download"
                    size="large"
                    style={{
                        borderRadius: 5,
                        width: "70%",
                        marginBottom: 10,
                    }}
                >
                    <Telephone />
                    &nbsp;&nbsp;<strong>Ligue agora</strong>
                </Button>
                <Button
                    href="https://wa.me/5508001234848"
                    className="button-download"
                    size="large"
                    style={{
                        borderRadius: 5,
                        width: "70%",
                        marginBottom: 10,
                    }}
                >
                    <WhatsAppOutlined />
                    &nbsp;&nbsp;<strong>Fale no WhatsApp</strong>
                </Button>
            </div>
        </Space>
    );
};
